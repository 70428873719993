import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from "antd";

import * as actions from "../actions";

import BranchHasCashPackageForm from "./BranchHasCashPackageForm";
import { checkPermission } from "lib/helper";

class EditBranchHasEmployee extends Component {
  state = {
    can_edit: checkPermission("branch_cash_packages", "can_add", false)
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage } = nextProps;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage } = this.props;

    if (alertMessage !== "" && prevProps.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }


  componentDidMount() {
    let branchId = this.props.match.params.branch_id;
    if (typeof parseInt(branchId) !== "number") return;
    this.props.loadBranchCashPackages(branchId, null, null, null);
  }

  editBranchHasCashPackage = async branchCashPackage => {
    this.props.editBranchHasCashPackage(
      this.props.match.params.branch_id,
      branchCashPackage
    );
  };

  render() {
    const {
      branchCashPackage,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <BranchHasCashPackageForm
        onSubmit={this.editBranchHasCashPackage}
        branchCashPackage={branchCashPackage}
        branchId={this.props.match.params.branch_id}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
      />
    );
  }
}

const mapStateToProps = ({ branchCashPackages, branches }) => {
  return {
    branchCashPackage: branchCashPackages.data,
    alertMessage: branchCashPackages.alertMessage,
    errorMessage: branchCashPackages.errorMessage,
    loading: branchCashPackages.loading,
    branches
  };
};

const mapDispatchToProps = {
  loadBranchCashPackages: actions.loadBranchCashPackages.request,
  editBranchHasCashPackage: actions.editBranchHasCashPackage.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBranchHasEmployee);
