import { createTypes, createAction } from "lib/action";

const LOAD_BRANCHES = createTypes("branches", "list");
const LOAD_BRANCH = createTypes("branches", "show");
const CREATE_BRANCH = createTypes("branches", "create");
const EDIT_BRANCH = createTypes("branches", "edit");
const DELETE_BRANCH = createTypes("branches", "delete");
const SET_BRANCH = createTypes("branches", "set");
const DESTROY_MANY = createTypes("branches", "destory");

const loadBranches = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_BRANCHES.REQUEST, { pagination, limit, keyword, sort }),
  success: branches => createAction(LOAD_BRANCHES.SUCCESS, { branches }),
  failure: () => createAction(LOAD_BRANCHES.FAILURE)
};

const loadBranch = {
  request: branchId => createAction(LOAD_BRANCH.REQUEST, { branchId }),
  success: branch => createAction(LOAD_BRANCH.SUCCESS, { branch }),
  failure: error => createAction(LOAD_BRANCH.FAILURE, { error })
};

const createBranch = {
  request: branch => createAction(CREATE_BRANCH.REQUEST, { branch }),
  success: branch => createAction(CREATE_BRANCH.SUCCESS, { branch }),
  failure: error => createAction(CREATE_BRANCH.FAILURE, { error })
};

const editBranch = {
  request: (id, branch, is_upload) =>
    createAction(EDIT_BRANCH.REQUEST, { id, branch, is_upload }),
  success: branch => createAction(EDIT_BRANCH.SUCCESS, { branch }),
  failure: error => createAction(EDIT_BRANCH.FAILURE, { error })
};

const deleteBranch = {
  request: id => createAction(DELETE_BRANCH.REQUEST, { id }),
  success: branch => createAction(DELETE_BRANCH.SUCCESS, { branch }),
  failure: error => createAction(DELETE_BRANCH.FAILURE, { error })
};

const setBranch = {
  request: id => createAction(SET_BRANCH.REQUEST, { id }),
  success: branch => createAction(SET_BRANCH.SUCCESS, { branch }),
  failure: error => createAction(SET_BRANCH.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_BRANCHES,
  loadBranches,
  LOAD_BRANCH,
  loadBranch,
  CREATE_BRANCH,
  createBranch,
  EDIT_BRANCH,
  editBranch,
  DELETE_BRANCH,
  deleteBranch,
  SET_BRANCH,
  setBranch,
  DESTROY_MANY,
  destroyMany
};
