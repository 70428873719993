import React, { Fragment } from "react";
import * as Component from "./components";

export default () => {
  return (
    <Fragment>
      <Component.ScheduleReports />
    </Fragment>
  );
};
