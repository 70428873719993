import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "lib/widget";

import CurrentStockReport from "./components/CurrentStockReport/View";
import DailyReport from "./components/DailyReport/View";
import EmployeesReport from "./components/EmployeesReport/View";
import ErpTransactionReport from "./components/ErpTransactionReport/View";
import GoodsReceivedReport from "./components/GoodsReceived/View";
import PhysicalCountReport from "./components/PhysicalCount/View";
import SalonUsedReport from "./components/SalonUsed/View";
import StockBenchMarkReport from "./components/StockBenchMarkReport/View";
import Transfer from "./components/Transfer/View";
import ErpCustomerReport from "./components/ErpCustomerReport";
import PaymentReport from "./components/PaymentReport/View"
import CustomerRetentionReport from "./components/CustomerRetention/View"
import StockExchangeReport from "./components/StockExchangeReport/View";

export default () => {
  return (
    <Switch>
      <PrivateRoute
        path="/report/employees"
        component={EmployeesReport}
        perm="report_employee"
      />
      <PrivateRoute
        path="/report/daily"
        component={DailyReport}
        perm="report_daily"
      />
      <PrivateRoute
        path="/report/erp_transaction"
        component={ErpTransactionReport}
        perm="report_erp_transaction"
      />
      <PrivateRoute
        path="/report/erp_customer"
        component={ErpCustomerReport}
        perm="report_erp_customer"
      />
      <PrivateRoute
        path="/report/goods_received"
        component={GoodsReceivedReport}
        perm="report_goods_received"
      />
      <PrivateRoute
        path="/report/salon_used"
        component={SalonUsedReport}
        perm="report_salon_used"
      />
      <PrivateRoute
        path="/report/physical_count"
        component={PhysicalCountReport}
        perm="report_physical_count"
      />
      <PrivateRoute
        path="/report/transfer"
        component={Transfer}
        perm="report_transfer"
      />

      <PrivateRoute
        path="/report/stock_benchmark"
        component={StockBenchMarkReport}
        perm="report_stock_benchmark"
      />
      <PrivateRoute
        path="/report/stock_exchange"
        component={StockExchangeReport}
        perm="report_stock_exchange"
      />
      <PrivateRoute
        path="/report/current_stock"
        component={CurrentStockReport}
        perm="report_current_stock"
      />
      <PrivateRoute
        path="/report/payment"
        component={PaymentReport}
        perm="report_payment"
      />
      <PrivateRoute
        path="/report/customer_retention"
        component={CustomerRetentionReport}
        perm="report_customer_retention"
      />
    </Switch>
  );
};