import {
  LOAD_BRANCH_EMPLOYEES,
  IMPORT_BRANCH_HAS_EMPLOYEE,
  DOWNLOAD_FILE,
  UPDATE_ORDER_BRANCH_HAS_EMPLOYEE,
  EDIT_BRANCH_HAS_EMPLOYEE,
  DELETE_BRANCH_HAS_EMPLOYEE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  alertMessage: "",
  errorMessage: "",
  data: [],
  totalSize: 0,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BRANCH_EMPLOYEES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case LOAD_BRANCH_EMPLOYEES.SUCCESS:
      return {
        ...state,
        data: action.payload.branchEmployees.data,
        totalSize: action.payload.branchEmployees.total_row,
        alertMessage: "",
        errorMessage: "",
        loading: false
      };
    case LOAD_BRANCH_EMPLOYEES.FAILURE:
      return { ...state, alertMessage: "", errorMessage: "", loading: false };

    case IMPORT_BRANCH_HAS_EMPLOYEE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case IMPORT_BRANCH_HAS_EMPLOYEE.SUCCESS:
      return {
        ...state,
        data: action.payload.branchEmployee.data,
        totalSize: action.payload.branchEmployee.total_row,
        alertMessage: action.payload.branchEmployee.message,
        errorMessage: "",
        loading: false
      };
    case IMPORT_BRANCH_HAS_EMPLOYEE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case UPDATE_ORDER_BRANCH_HAS_EMPLOYEE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case UPDATE_ORDER_BRANCH_HAS_EMPLOYEE.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.data.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case UPDATE_ORDER_BRANCH_HAS_EMPLOYEE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };

    case EDIT_BRANCH_HAS_EMPLOYEE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_BRANCH_HAS_EMPLOYEE.SUCCESS:
      return {
        ...state,
        data: action.payload.employees.data,
        alertMessage: action.payload.employees.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_BRANCH_HAS_EMPLOYEE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };

    case DELETE_BRANCH_HAS_EMPLOYEE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_BRANCH_HAS_EMPLOYEE.SUCCESS:
      const newBranches = state.data.filter(
        branch =>
          parseInt(branch.employee_id) !==
          parseInt(action.payload.employee.data.id) ||
          parseInt(branch.branch_id) !==
          parseInt(action.payload.employee.data.branch_id)
      );
      return {
        ...state,
        data: newBranches,
        alertMessage: action.payload.employee.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_BRANCH_HAS_EMPLOYEE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.branch_has_employee_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
