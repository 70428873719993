import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { UserOutlined } from "@ant-design/icons";
import {
  Table,
  Row,
  Col,
  Avatar,
  message,
  Spin,
  notification,
  Typography,
} from "antd";

import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  uniqueArray,
  extractKeyFromArray,
} from "lib/helper";
import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  FilterBtn,
  TagFilter,
  ImportExportBtn,
  AddBtn,
  UploadModal,
  PageTitle,
} from "lib/widget";
import Filter from "./Filter";
const { Text } = Typography;

class EmployeeList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = nextProps.employees;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (errorImport !== "") {
      return { errorImport: errorImport };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
    img: null
  };

  handleSearch = (val, filter) => {
    this.setState({ page: 1, visible: false, keyword: val.keyword, cond: val }, this.fetchData);
    this.getFilterTag(filter, val.keyword, val.phone_no);
  };

  getFilterTag = (filter, keyword, phone) => {
    let type = "",
      word = "",
      tel = "";

    if (keyword !== "") word = `${keyword}`;
    if (phone !== "") tel = `${phone}`;

    Object.keys(filter).map((key) => {
      filter[key].map((val) => {
        if (key === "type") type += (type !== "" ? ", " : "") + val.type_name;
      });
    });

    this.setState({
      typeTag: type !== "" ? `${type}` : "",
      keywordTag: word,
      phoneTag: tel,
    });

    setFilterTag({
      typeTag: type !== "" ? `${type}` : "",
      keywordTag: word,
      phoneTag: tel,
    })
  };

  fetchData = () => {
    const { page, limit, cond, sorter } = this.state
    setSorter(sorter)
    setSearch(cond)
    this.props.loadEmployees(page, limit, cond, sorter);
  }

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState({ page: current, limit: pageSize, sorter: { order, field } }, this.fetchData);
  };

  componentDidMount() {
    let filter = getFilterTag()

    if (typeof filter === 'object') {
      this.setState({ ...filter, cond: getSearch(), sorter: getSorter() }, this.fetchData)
      return
    }
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = this.props.employees;
    const prevMsg = prevProps.employees;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (errorImport !== "" && prevMsg.errorImport !== errorImport) {
      this.openNotification(errorImport);
    }
  }

  openNotification = (message) => {
    notification.error({
      message: "Import fail",
      description: message,
      duration: 0,
    });
  };

  deleteEmployee(id) {
    this.props.deleteEmployee(id);
  }

  showModalImport = () => {
    this.setState({
      visibleImport: true,
    });
  };

  handleCancel = () => {
    this.setState({ visibleImport: false });
  };

  onChooseFile = ({ file }) => {
    if (file.status !== "removed") {
      this.setState({ file, fileList: [file] });
    }
  };

  onRemove = () => this.setState({ fileList: [], file: null });

  handleUpload = () => {
    const { page, limit, keyword, file } = this.state;
    this.props.importEmployee(file, "employee", page, limit, keyword);
    this.setState({ file: null, fileList: [] });
  };

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.employees.data.filter((x) =>
        selectedRowKeys.includes(x.employee_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.employee_id)
      );
      items = uniqueArray([...removeState, ...removeData], "employee_id");
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "employee_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter((x) => x.employee_id !== id);
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { employees } = this.props;
    const { selectedRowKeys, typeTag, keywordTag, phoneTag, sorter } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const data = employees.data;

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const actionCol = [
      {
        title: "",
        key: "employee_id",
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: "left",
        render: (employees) => (
          <ListAction
            editUrl={`/employee/employees/edit/${employees.employee_id}`}
            onDelete={this.props.deleteEmployee}
            id={employees.employee_id}
            name="employee_employees"
            breakpoint={500}
          />
        ),
      },
    ];

    const mainColumns = [
      {
        title: "Image",
        width: 80,
        dataIndex: "image",
        render: (txt) => {
          return txt === ""
            ? <Avatar size={32} icon={<UserOutlined />} />
            : <Avatar size={32} src={`data:image/png;base64, ${txt}`} />
        }
      },
      {
        title: "Code",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'employee_code' ? sorter.order : false,
        width: 100,
        dataIndex: "employee_code",
      },
      {
        title: "Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'name' ? sorter.order : false,
        dataIndex: "name",
      },
      {
        title: "Phone No.",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'phone' ? sorter.order : false,
        dataIndex: "phone",
      },
      {
        title: "Type",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'type_name' ? sorter.order : false,
        dataIndex: "type_name",
      },
    ];

    const columns =
      window.innerWidth <= 500
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    return (
      <div>
        <Spin size="large" spinning={this.props.employees.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              name="employee_employees"
              component={
                <AddBtn
                  url="/employee/employees/new"
                  name="employee_employees"
                />
              }
            />
            <Row gutter={[16, 16]}>
              <DeleteBadge
                count={this.state.removeData.length}
                onClick={this.showDrawer("visibleDelete")}
              />
              <Col xs={10} sm={10} md={8} lg={6} className="import-btn-align">
                <ImportExportBtn
                  showModalImport={this.showModalImport}
                  download={this.props.download}
                  name="employee_employees"
                />
              </Col>
              <Col
                xs={3}
                sm={3}
                md={{ span: 7, offset: 3 }}
                lg={{ span: 8, offset: 6 }}
              ></Col>
              <FilterBtn showDrawer={this.showDrawer} />
              <Filter
                list={data}
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col span={20}>
                {TagFilter([keywordTag], "Keyword", "green")}
                {TagFilter([phoneTag], "Phone No.", "cyan")}
                {TagFilter([typeTag], "Type", "orange")}
              </Col>
            </Row>

            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["employee_id", "employee_code", "name"]}
            />
          </div>
          <Table
            rowKey="employee_id"
            bordered={true}
            size="small"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: this.state.limit,
              total: employees.totalSize,
              current: this.state.page,
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 1025 ? { x: 700 } : undefined}
          />
          <UploadModal
            visibleImport={this.state.visibleImport}
            handleCancel={this.handleCancel}
            loading={this.props.employees.loading}
            onChooseFile={this.onChooseFile}
            fileList={this.state.fileList}
            onRemove={this.onRemove}
            handleUpload={this.handleUpload}
            uploading={this.state.uploading}
            disabled={this.state.file ? false : true}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ employees }) => ({
  employees,
});

const mapDispatchToProps = {
  loadEmployees: actions.loadEmployees.request,
  deleteEmployee: actions.deleteEmployee.request,
  importEmployee: actions.importEmployee.request,
  download: actions.download.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
