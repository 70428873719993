import React, { Component } from "react";
import { Table, Spin } from "antd";
import { numberFormat } from "lib/helper";

export class TopTable extends Component {
  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "number",
        key: "number",
        render: (val, rec, index) => index + 1
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        key: "customer_name",
      },
      {
        title: "Barcode",
        dataIndex: "barcode",
        key: "barcode",
      },
      {
        title: "Name",
        dataIndex: "item_name",
        key: "item_name",
      },
      {
        title: "Q'ty",
        dataIndex: "quantity",
        key: "quantity",
        sorter: (a, b) => a.quantity - b.quantity,
        render: (value) => numberFormat(value, 0),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        className: "align-right",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.amount - b.amount,
        render: (value) => numberFormat(value),
      },
    ];
    return (
      <Spin spinning={this.props.loading}>
        <Table
          dataSource={this.props.data}
          columns={columns}
          pagination={false}
          size="small"
          bordered
        />
      </Spin>
    );
  }
}

TopTable.defaultProps = {
  data: [],
};

export default TopTable;
