import {
  LOAD_BRANCH_SERVICE_PACKAGES,
  IMPORT_BRANCH_HAS_SERVICE_PACKAGE,
  DOWNLOAD_FILE,
  EDIT_BRANCH_HAS_SERVICE_PACKAGE,
  DELETE_BRANCH_HAS_PACKAGE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  alertMessage: "",
  errorMessage: "",
  data: [],
  services: [],
  totalSize: 0,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BRANCH_SERVICE_PACKAGES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case LOAD_BRANCH_SERVICE_PACKAGES.SUCCESS:
      return {
        ...state,
        data: action.payload.branchServicePackages.data,
        services: action.payload.branchServicePackages.services,
        totalSize: action.payload.branchServicePackages.total_row,
        alertMessage: "",
        errorMessage: "",
        loading: false
      };
    case LOAD_BRANCH_SERVICE_PACKAGES.FAILURE:
      return { ...state, alertMessage: "", errorMessage: "", loading: false };

    case IMPORT_BRANCH_HAS_SERVICE_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case IMPORT_BRANCH_HAS_SERVICE_PACKAGE.SUCCESS:
      return {
        ...state,
        data: action.payload.branchServicePackage.data,
        services: action.payload.branchServicePackage.services,
        totalSize: action.payload.branchServicePackage.total_row,
        alertMessage: action.payload.branchServicePackage.message,
        errorMessage: "",
        loading: false
      };
    case IMPORT_BRANCH_HAS_SERVICE_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case EDIT_BRANCH_HAS_SERVICE_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_BRANCH_HAS_SERVICE_PACKAGE.SUCCESS:
      return {
        ...state,
        data: action.payload.servicepackage.data,
        alertMessage: action.payload.servicepackage.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_BRANCH_HAS_SERVICE_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };

    case DELETE_BRANCH_HAS_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_BRANCH_HAS_PACKAGE.SUCCESS:
      const newBranches = state.data.filter(
        branch =>
          parseInt(branch.branch_id) !==
          parseInt(action.payload.servicepackage.data.branch_id) ||
          parseInt(branch.service_package_id) !== parseInt(action.payload.servicepackage.data.id)
      );
      return {
        ...state,
        data: newBranches,
        alertMessage: action.payload.servicepackage.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_BRANCH_HAS_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.branch_has_service_package_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
