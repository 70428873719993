import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import NationalityForm from "./NationalityForm";

class NewNationality extends Component {
  createNationality = async nationality => {
    this.props.createNationality(nationality);
  };

  render() {
    const { nationality, alertMessage, errorMessage, loading } = this.props;

    return (
      <NationalityForm
        onSubmit={this.createNationality}
        nationality={nationality}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ nationalities }) => {
  return {
    nationality: nationalities.nationality,
    alertMessage: nationalities.alertMessage,
    errorMessage: nationalities.errorMessage,
    loading: nationalities.loading
  };
};

const mapDispatchToProps = {
  createNationality: actions.createNationality.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewNationality);
