import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import CategoryForm from "./CategoryForm";

class NewCategory extends Component {
  createCategory = async category => {
    this.props.createCategory(category.category_code, category.category_name);
  };

  render() {
    const { category, alertMessage, errorMessage, loading } = this.props;
    return (
      <CategoryForm
        onSubmit={this.createCategory}
        category={category}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ categories }) => {
  return {
    category: categories.category,
    alertMessage: categories.alertMessage,
    errorMessage: categories.errorMessage,
    loading: categories.loading
  };
};

const mapDispatchToProps = {
  createCategory: actions.createCategory.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCategory);
