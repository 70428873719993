import React, { Component } from "react";
import { connect } from "react-redux";
import { message, Spin } from "antd";

import * as actions from "../../actions";
import Form from "./Form";
import { PageTitle, ReportBtn } from "lib/widget";

const initalState = {
  branchId: [],
  startDate: null,
  endDate: null
};

class CurrentStockReport extends Component {
  constructor(props) {
    super(props);

    this.state = initalState;
    this.onKeyValueChange = this.onKeyValueChange.bind(this);
  }

  onKeyValueChange(name, value) {
    this.setState((state, props) => {
      return { ...state, [name]: value };
    });
  }

  clearFilter = () => {
    this.setState(initalState);
  };

  downloadCurrentStock = () => {
    const { branchId } = this.state;
    if (branchId.length === 0) return message.error("Please select branch");
    this.props.downloadCurrentStock(branchId);
  };

  componentDidMount() {
    this.props.loadReportBranch()
  }
  

  render() {
    return (
      <div>
        <Spin size="large" spinning={this.props.loading}>
          <PageTitle name="report_current_stock" component={null} />
          <Form
            {...this.state}
            branches={this.props.branches}
            onKeyValueChange={this.onKeyValueChange}
          />
          <ReportBtn
            onDowload={this.downloadCurrentStock}
            onClear={this.clearFilter}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ reports }) => ({
  branches: reports.branches,
  loading: reports.current_stock_loading
});

const mapDispatchToProps = {
  downloadCurrentStock: actions.downloadCurrentStock.request,
  loadReportBranch: actions.loadReportBranch.request
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentStockReport);
