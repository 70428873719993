import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";

import UserGroupForm from "./UserGroupForm";

class NewUserGroupForm extends Component {
  createUserGroup = async (permission) => {
    this.props.createUserGroup(permission);
  };

  render() {
    const {
      usergroups,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <UserGroupForm
        onSubmit={this.createUserGroup}
        usergroup={usergroups}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}
const mapStateToProps = ({ usergroups }) => {
  return {
    usergroups: usergroups.usergroup,
    alertMessage: usergroups.alertMessage,
    errorMessage: usergroups.errorMessage,
    loading: usergroups.loading
  };
};

const mapDispatchToProps = {
  createUserGroup: actions.createUserGroup.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUserGroupForm);
