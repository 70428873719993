import React from "react";
import { Switch } from "react-router-dom";

import UserGroupList from "./UserGroupList";
import NewUserGroup from "./NewUserGroup";
import EditUserGroup from "./EditUserGroup";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute path="/auth/usergroups/new" component={NewUserGroup} perm="authorization_usergroups" action="can_add"/>
    <PrivateRoute
      path="/auth/usergroups/edit/:usergroup_id"
      component={EditUserGroup}
      perm="authorization_usergroups"
    />
    <PrivateRoute path="/auth/usergroups" component={UserGroupList} perm="authorization_usergroups" />
  </Switch>
);
