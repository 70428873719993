import { createTypes, createAction } from "lib/action";

const LOAD_BRANCH_EMPLOYEES = createTypes("branchEmployees", "list");
const IMPORT_BRANCH_HAS_EMPLOYEE = createTypes("branchEmployees", "import");
const UPDATE_ORDER_BRANCH_HAS_EMPLOYEE = createTypes("branchEmployees", "order");
const DOWNLOAD_FILE = createTypes("branchEmployees", "export");
const EDIT_BRANCH_HAS_EMPLOYEE = createTypes("branchEmployees", "edit");
const DELETE_BRANCH_HAS_EMPLOYEE = createTypes("branchEmployees", "delete");
const DESTROY_MANY = createTypes("branchEmployees", "destory");

const loadBranchEmployees = {
  request: (branchId, pagination, limit, cond, sort) =>
    createAction(LOAD_BRANCH_EMPLOYEES.REQUEST, { branchId, pagination, limit, cond, sort }),
  success: branchEmployees =>
    createAction(LOAD_BRANCH_EMPLOYEES.SUCCESS, {
      branchEmployees
    }),
  failure: () => createAction(LOAD_BRANCH_EMPLOYEES.FAILURE)
};

const importBranchHasEmployee = {
  request: (file, type, pagination, limit, keyword, branchId) =>
    createAction(IMPORT_BRANCH_HAS_EMPLOYEE.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword,
      branchId
    }),
  success: branchEmployee =>
    createAction(IMPORT_BRANCH_HAS_EMPLOYEE.SUCCESS, {
      branchEmployee
    }),
  failure: error => createAction(IMPORT_BRANCH_HAS_EMPLOYEE.FAILURE, { error })
};

const editBranchHasEmployee = {
  request: (branch_id, employees) => createAction(EDIT_BRANCH_HAS_EMPLOYEE.REQUEST, { branch_id, employees }),
  success: (employees) => createAction(EDIT_BRANCH_HAS_EMPLOYEE.SUCCESS, { employees }),
  failure: error => createAction(EDIT_BRANCH_HAS_EMPLOYEE.FAILURE, { error })
}

const download = {
  request: (branch_id) => createAction(DOWNLOAD_FILE.REQUEST, { branch_id }),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const updateOrder = {
  request: (branch_id, data) =>
    createAction(UPDATE_ORDER_BRANCH_HAS_EMPLOYEE.REQUEST, { branch_id, data }),
  success: data =>
    createAction(UPDATE_ORDER_BRANCH_HAS_EMPLOYEE.SUCCESS, { data }),
  failure: error => createAction(UPDATE_ORDER_BRANCH_HAS_EMPLOYEE.FAILURE, { error })
}

const deleteBranchHasEmployee = {
  request: (id, branch_id) => createAction(DELETE_BRANCH_HAS_EMPLOYEE.REQUEST, { id, branch_id }),
  success: employee => createAction(DELETE_BRANCH_HAS_EMPLOYEE.SUCCESS, { employee }),
  failure: error => createAction(DELETE_BRANCH_HAS_EMPLOYEE.FAILURE, { error })
}

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_BRANCH_EMPLOYEES,
  loadBranchEmployees,
  IMPORT_BRANCH_HAS_EMPLOYEE,
  importBranchHasEmployee,
  DOWNLOAD_FILE,
  download,
  UPDATE_ORDER_BRANCH_HAS_EMPLOYEE,
  updateOrder,
  editBranchHasEmployee,
  EDIT_BRANCH_HAS_EMPLOYEE,
  deleteBranchHasEmployee,
  DELETE_BRANCH_HAS_EMPLOYEE,
  DESTROY_MANY,
  destroyMany
};
