import React from "react";
import { Switch } from "react-router-dom";

import UserList from "./UserList";
import NewUser from "./NewUser";
import Profile from "./Profile";
import EditUser from "./EditUser";

import { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute path="/system/users/edit/:user_id" component={EditUser} perm="system_user_users" />
    <PrivateRoute path="/system/users/new" component={NewUser} perm="system_user_users" action="can_add" />
    <PrivateRoute path="/system/users/view/:user_id" component={Profile} perm="system_user_users" checkOnlyToken={true} />
    <PrivateRoute path="/system/users" component={UserList} perm="system_user_users" />
  </Switch>
);
