import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import { loadServicePackageGroups } from "lib/select-option";

import ServicePackageForm from "./ServicePackageForm";

class NewServicePackage extends Component {
  componentDidMount() {
    this.props.loadServicePackageGroups();
  }

  createServicePackage = async (servicePackage, checkedList, actualServices) => {
    this.props.createServicePackage(servicePackage, checkedList, actualServices);
  };

  render() {
    const {
      servicePackageGroups,
      servicePackage,
      alertMessage,
      errorMessage,
      branches,
      loading
    } = this.props;

    const data = [];
    branches.map(branch => {
      const obj = {};
      obj.name = branch.branch_id;
      obj.value = true;
      return data.push(obj);
    });

    return (
      <ServicePackageForm
        onSubmit={this.createServicePackage}
        servicePackage={servicePackage}
        servicePackageGroups={servicePackageGroups}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        branches={branches}
        checkedList={data}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({
  servicePackageGroups,
  servicePackages,
  branches
}) => {
  return {
    servicePackageGroups: servicePackageGroups.data,
    alertMessage: servicePackages.alertMessage,
    errorMessage: servicePackages.errorMessage,
    servicePackage: servicePackages.package,
    branches: branches.data,
    loading: servicePackages.loading
  };
};

const mapDispatchToProps = {
  createServicePackage: actions.createServicePackage.request,
  loadServicePackageGroups: loadServicePackageGroups.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewServicePackage);
