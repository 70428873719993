import { createTypes, createAction } from "lib/action";

const LOAD_CUSTOMERS_SUMMARY = createTypes("customer_analytics", "summary");
const LOAD_CUSTOMERS_RETENTION = createTypes("customer_analytics", "retention");
const LOAD_CUSTOMERS_REVENUE = createTypes("customer_analytics", "revenue");
const LOAD_CUSTOMERS_QUANTITY = createTypes("customer_analytics", "quantity");
const LOAD_CUSTOMERS_SERVICE = createTypes("customer_analytics", "service");
const LOAD_CUSTOMERS_GROWTH = createTypes("customer_analytics", "growth");
const LOAD_CUSTOMERS_TOPSPEND = createTypes("customer_analytics", "topspend");


const loadCustomerSummary = {
  request: (branch_id) =>
    createAction(LOAD_CUSTOMERS_SUMMARY.REQUEST, {branch_id}),
  success: (summary) =>
    createAction(LOAD_CUSTOMERS_SUMMARY.SUCCESS, { summary }),
  failure: () => createAction(LOAD_CUSTOMERS_SUMMARY.FAILURE),
};

const loadCustomerRevenue = {
  request: (type, duration, gender, branch_id) =>
    createAction(LOAD_CUSTOMERS_REVENUE.REQUEST, {type, duration, gender, branch_id}),
  success: (revenue) =>
    createAction(LOAD_CUSTOMERS_REVENUE.SUCCESS, { revenue }),
  failure: () => createAction(LOAD_CUSTOMERS_REVENUE.FAILURE),
};

const loadCustomerQuantity = {
  request: (type, duration, gender, branch_id) =>
    createAction(LOAD_CUSTOMERS_QUANTITY.REQUEST, {type, duration, gender, branch_id}),
  success: (quantity) =>
    createAction(LOAD_CUSTOMERS_QUANTITY.SUCCESS, { quantity }),
  failure: () => createAction(LOAD_CUSTOMERS_QUANTITY.FAILURE),
};

const loadCustomerServiceHours = {
  request: (type, duration, gender, branch_id) =>
    createAction(LOAD_CUSTOMERS_SERVICE.REQUEST, {type, duration, gender, branch_id}),
  success: (service) =>
    createAction(LOAD_CUSTOMERS_SERVICE.SUCCESS, { service }),
  failure: () => createAction(LOAD_CUSTOMERS_SERVICE.FAILURE),
};

const loadCustomerRetention = {
  request: (type, duration, gender, branch_id) =>
    createAction(LOAD_CUSTOMERS_RETENTION.REQUEST, {type, duration, gender, branch_id}),
  success: (retention) =>
    createAction(LOAD_CUSTOMERS_RETENTION.SUCCESS, { retention }),
  failure: () => createAction(LOAD_CUSTOMERS_RETENTION.FAILURE),
};

const loadCustomerGrowthRate = {
  request: (branch_id) =>
    createAction(LOAD_CUSTOMERS_GROWTH.REQUEST, {branch_id}),
  success: (growth, growth_g) =>
    createAction(LOAD_CUSTOMERS_GROWTH.SUCCESS, { growth, growth_g }),
  failure: () => createAction(LOAD_CUSTOMERS_GROWTH.FAILURE),
};

const loadCusotmerTopSpend = {
  request: (start_date, end_date, query_type, category_id, item_id, gender, branch_id) =>
    createAction(LOAD_CUSTOMERS_TOPSPEND.REQUEST, {start_date, end_date, query_type, category_id, item_id, gender, branch_id}),
  success: (topspend) =>
    createAction(LOAD_CUSTOMERS_TOPSPEND.SUCCESS, { topspend }),
  failure: () => createAction(LOAD_CUSTOMERS_TOPSPEND.FAILURE),
};

export {
  loadCustomerSummary,
  loadCustomerRetention,
  loadCustomerRevenue,
  loadCustomerQuantity,
  loadCustomerServiceHours,
  loadCustomerGrowthRate,
  loadCusotmerTopSpend,
  LOAD_CUSTOMERS_SUMMARY,
  LOAD_CUSTOMERS_RETENTION,
  LOAD_CUSTOMERS_REVENUE,
  LOAD_CUSTOMERS_QUANTITY,
  LOAD_CUSTOMERS_SERVICE,
  LOAD_CUSTOMERS_GROWTH,
  LOAD_CUSTOMERS_TOPSPEND,
};

