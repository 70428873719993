import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, logErrorMsg, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadPermissions(action) {
  const { pagination, limit, cond, sort } = action.payload;
    try {
      const res = yield fetcher.post('permissions/allPermissions', { pagination, limit, cond, sort })
      yield put(actions.loadPermissions.success(res.data));
    } catch (error) {
      handleError(error)
      yield put(actions.loadPermissions.failure(getErrorMsg(error)));
      logErrorMsg(error)
    }
}

function* loadPermission(action) {
  try {
    const { permissionId } = action.payload;
    const res = yield fetcher.get(`permissions/${permissionId}`)
    yield put(actions.loadPermission.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadPermission.failure(getErrorMsg(error)));
    logErrorMsg(error)
  }
}

function* createPermission(action) {
  try {
    const { permission } = action.payload;
    const res = yield fetcher.post('permissions', permission)
    yield put(actions.createPermission.success(res.data));
    yield put(push("/auth/permissions"));
  } catch (error) {
    handleError(error)
    yield put(actions.createPermission.failure(getErrorMsg(error)));
    yield put(push("/auth/permissions/new"));
  }
}

function* editPermission(action) {
  const { id, permission } = action.payload;
  try {
    const res = yield fetcher.patch(`permissions/${id}`, permission)
    yield put(actions.editPermission.success(res.data));
    yield put(push("/auth/permissions"));
  } catch (error) {
    handleError(error)
    yield put(actions.editPermission.failure(getErrorMsg(error)));
    yield put(push(`/auth/permissions/edit/${id}`));
  }
}

function* deletePermission(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`permissions/${id}`)
    yield put(actions.deletePermission.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.deletePermission.failure(getErrorMsg(error)));
  }
}

function* updateOrder(action) {
  const { permission } = action.payload;
  
  try {
    yield fetcher.post(`permissions/update_order`, permission)
    window.location.reload();
  } catch (error) {
      handleError(error)
      yield put(actions.editPermission.failure(getErrorMsg(error)));
      yield put(push("/auth/permissions"));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/actualservice/type`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchPermissions() {
  yield all([
    takeEvery(actions.LOAD_PERMISSIONS.REQUEST, loadPermissions),
    takeEvery(actions.LOAD_PERMISSION.REQUEST, loadPermission),
    takeEvery(actions.CREATE_PERMISSION.REQUEST, createPermission),
    takeEvery(actions.EDIT_PERMISSION.REQUEST, editPermission),
    takeEvery(actions.DELETE_PERMISSION.REQUEST, deletePermission),
    takeEvery(actions.UPDATE_ORDER_PERMISSION.REQUEST, updateOrder),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
