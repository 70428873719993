import {
  LOAD_CUSTOMERS,
  LOAD_CUSTOMER,
  CREATE_CUSTOMER,
  EDIT_CUSTOMER,
  DELETE_CUSTOMER,
  IMPORT_CUSTOMER,
  DOWNLOAD_FILE,
  LOAD_CUSTOMER_TRANSACTION,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  customer: {
    customer_code: "",
    name: "",
    gender: "FEMALE",
    nationality_id: "",
    tel1: "",
    email: "",
    birthday: null,
    address: "",
    hair_volumn: "",
    hair_size: "",
    notes: ""
  },
  transaction: [],
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CUSTOMERS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_CUSTOMERS.SUCCESS:
      return {
        ...state,
        data: action.payload.customers.data,
        totalSize: action.payload.customers.total_row,
        customer: initialState.customer,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case LOAD_CUSTOMER.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_CUSTOMER.SUCCESS:
      return {
        ...state,
        customer: action.payload.customer.data[0],
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_CUSTOMER.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case CREATE_CUSTOMER.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.customer.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_CUSTOMER.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_CUSTOMER.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_CUSTOMER.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.customer.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_CUSTOMER.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case DELETE_CUSTOMER.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_CUSTOMER.SUCCESS:
      const newCustomers = state.data.filter(
        customer =>
          customer.customer_id !== action.payload.customer.data.customer_id
      );
      return {
        ...state,
        data: newCustomers,
        alertMessage: action.payload.customer.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_CUSTOMER.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case IMPORT_CUSTOMER.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case IMPORT_CUSTOMER.SUCCESS:
      return {
        ...state,
        data: action.payload.customer.data,
        totalSize: action.payload.customer.total_row,
        alertMessage: action.payload.customer.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case IMPORT_CUSTOMER.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",

        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case LOAD_CUSTOMER_TRANSACTION.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_CUSTOMER_TRANSACTION.SUCCESS:
      const { customers } = action.payload;
      return {
        ...state,
        transaction: customers.data,
        totalSize: customers.total_row,
        customer: customers.customer,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };

    case LOAD_CUSTOMER_TRANSACTION.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.customer_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
