import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Row, Col, Typography, Card, Spin } from "antd";
import Filter from "./Filter";
import { getIdtoArray } from "lib/helper";
import GraphAppointment from "./GraphAppointment";
import Summary from "./Summary"

import * as moment from "moment";

const { Title } = Typography;
const color = ["#55b9ed", "#e94a39"];

export class Appointment extends Component {
  state = {
    picker: "date",
    duration: moment().format("YYYY-MM-DD"),
    branch_id: [],
    visible: false,
    stylish_id: [],
  };

  percentCal = (last, week) => {
    if (last === 0) return 0;
    return ((week - last) / last) * 100;
  };

  handleFilter = (picker, date, branches, stylish_id) => {
    this.setState(
      { picker, duration: date, branch_id: branches, stylish_id },
      this.fetchData
    );
  };

  fetchData = () => {
    let { picker, duration, branch_id, stylish_id } = this.state;
    this.props.loadAppointment(picker, duration, branch_id, stylish_id);
  };

  getComparedLabel = () => {
    switch (this.state.type) {
      case "date":
      case "week":
        return "week";
      case "month":
        return "month";
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.branches.length !== prevProps.branches.length) {
      this.setState(
        { branch_id: getIdtoArray(this.props.branches, "value") },
        this.fetchData
      );
    }
  }

  render() {
    const { appoint_graph, loading, use_package, summary  } = this.props;
    return (
      <Card>
        <Spin spinning={loading}>
          <Row gutter={[16, 16]} justify="space-between" align="middle">
            <Col xs={24} md={5} lg={8}>
              <Title level={4} className="page-title">
                Service hours
              </Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="space-between" align="middle">
            <Col xs={8} lg={24}>
              <Filter
                {...this.state}
                branches={this.props.branches}
                onClick={this.toggleFilter}
                onSearch={this.handleFilter}
              />
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col xs={24} md={24} lg={24}>
              <GraphAppointment
                type={this.state.picker}
                revenue={appoint_graph}
                duration={this.state.duration}
                color={color}
              />
            </Col>
            {/* <Col xs={24} md={24} lg={8}>
              <Summary 
              usePackage={use_package} 
              summary={summary}
              serviceHour={appoint_graph} />
            </Col> */}
          </Row>
        </Spin>
      </Card>
    );
  }
}

const mapStateToProps = ({ appoint }) => ({
  appoint_graph: appoint.appoint_graph,
  use_package: appoint.use_package,
  summary:appoint.summary,
  loading: appoint.loading_appoint,
});

const mapDispatchToProps = {
  loadAppointment: actions.loadAppointment.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
