import React from "react";
import { Switch } from "react-router-dom";

import NationalityList from "./NationalityList";
import NewNationality from "./NewNationality";
import EditNationality from "./EditNationality";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute
      path="/customer/nationalities/edit/:nationality_id"
      component={EditNationality}
      perm="customer_nationalities"
    />
    <PrivateRoute path="/customer/nationalities/new" component={NewNationality} perm="customer_nationalities" action="can_add"/>
    <PrivateRoute path="/customer/nationalities" component={NationalityList} perm="customer_nationalities" />
  </Switch>
);
