import { Form, Input, Row, Col, message, Checkbox, Spin, Radio } from "antd";
import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { PageTitle, FormBtn, RequriedText, SectionDivider } from "lib/widget";
import { numberFormat, removeComma } from "lib/helper";

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  }
};

const formItemPriceLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  }
};

export default class CashPackageForm extends Component {
  state = {
    checkedList: this.props.checkedList
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage, checkedList } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (checkedList.length > 0) {
      return { checkedList: checkedList };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage, checkedList } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }

    if (checkedList.length > 0 && prevState.checkedList !== checkedList) {
      this.setState({ checkedList });
    }
  }

  onChecked = checkedValues => {
    const obj = this.state.checkedList;
    obj.map(check => {
      if (check.name.toString() === checkedValues.target.name.toString())
        return (check.value = checkedValues.target.checked);
    });
    this.setState({
      checkedList: obj
    });
  };

  isDisabled = () => {
    return this.props.cashPackage.barcode !== "" && this.props.editCode;
  };

  componentDidMount() {
    this.nameInput.focus();
  }

  render() {
    const { cashPackage, onSubmit, branches, loading } = this.props;

    const list = branches.map(branch => {
      const code = branch.branch_id;
      const check = this.state.checkedList.filter(c => {
        if (c.name === code) return c;
      });
      return (
        <Col span={12} key={branch.branch_id}>
          <Checkbox
            name={branch.branch_id.toString()}
            onChange={this.onChecked}
            checked={check[0].value}
          >
            {branch.branch_name}
          </Checkbox>
        </Col>
      );
    });

    return (
      <div>
        <Spin size="large" spinning={loading}>
          <PageTitle name="cash_package_cash_packages" component={null} />
          <Formik
            initialValues={cashPackage}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              barcode: yup.string().required("Barcode is a required field"),
              price_before_vat: yup
                .string()
                .required("Price before VAT is a required field"),
              price: yup.string().required("Price is a required field"),
              cash_package_name: yup
                .string()
                .required("Name is a required field"),
              cash: yup
                .number()
                .typeError("Cash must be a number")
                .required("Cash is a required field")
            })}
            onSubmit={values => {
              values["pricelist"] = [
                {
                  price_before_vat: removeComma(values["price_before_vat"]),
                  price: removeComma(values["price"])
                }
              ];
              onSubmit(values, this.state.checkedList);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <Form onFinish={handleSubmit} autoComplete="off">
                <fieldset disabled={!this.props.canEdit}>
                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Barcode" />}
                    validateStatus={
                      touched.barcode && errors.barcode && "error"
                    }
                    help={touched.barcode && errors.barcode}
                  >
                    <Input
                      name="barcode"
                      ref={(input) => { this.nameInput = input; }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.barcode}
                      disabled={this.isDisabled()}
                    />
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Name" />}
                    validateStatus={
                      touched.cash_package_name &&
                      errors.cash_package_name &&
                      "error"
                    }
                    help={touched.cash_package_name && errors.cash_package_name}
                  >
                    <Input
                      name="cash_package_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cash_package_name}
                    />
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Cash" />}
                    validateStatus={touched.cash && errors.cash && "error"}
                    help={touched.cash && errors.cash}
                  >
                    <Input
                      placeholder="0.00"
                      name="cash"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cash}
                      disabled={this.isDisabled()}
                    />
                  </FormItem>

                  <FormItem>
                    <Row>
                      <Col
                        xs={{ span: 10 }}
                        sm={{ span: 8 }}
                        style={{
                          paddingRight: "9px",
                          textAlign: window.innerWidth <= 500 ? "left" : "right",
                          color: "rgba(0, 0, 0, 0.85)"
                        }}
                      >
                        <RequriedText label="Display on ipad :" />
                      </Col>
                      <Col xs={{ span: 14 }} sm={{ span: 10 }}>
                        <Row>
                          <Radio.Group
                            onChange={e => {
                              setFieldValue("is_show", e.target.value);
                            }}
                            value={values.is_show}
                          >
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Radio.Group>
                        </Row>
                      </Col>
                    </Row>
                  </FormItem>

                  <SectionDivider label="Set sales price" />

                  <FormItem
                    {...formItemPriceLayout}
                    label={<RequriedText label="Price before VAT" />}
                    validateStatus={
                      touched.price_before_vat &&
                      errors.price_before_vat &&
                      "error"
                    }
                    help={touched.price_before_vat && errors.price_before_vat}
                  >
                    <Input
                      name="price_before_vat"
                      onChange={handleChange}
                      placeholder="0.00"
                      onBlur={() => {
                        setFieldValue(
                          "price_before_vat",
                          numberFormat(values.price_before_vat)
                        );
                      }}
                      value={values.price_before_vat}
                    />
                  </FormItem>
                  <FormItem
                    {...formItemPriceLayout}
                    label={<RequriedText label="Price" />}
                    validateStatus={touched.price && errors.price && "error"}
                    help={touched.price && errors.price}
                  >
                    <Input
                      name="price"
                      onChange={handleChange}
                      placeholder="0.00"
                      onBlur={() => {
                        setFieldValue("price", numberFormat(values.price));
                      }}
                      value={values.price}
                    />
                  </FormItem>

                  <SectionDivider label="Add cash package to branch" />

                  <FormItem>
                    <Row>
                      <Col
                        xs={{ span: 5 }}
                        sm={{ span: 8 }}
                        style={{
                          paddingRight: "9px",
                          textAlign: "right",
                          color: "rgba(0, 0, 0, 0.85)"
                        }}
                      >
                        <RequriedText label="Branch" />
                      </Col>
                      <Col xs={{ span: 19 }} sm={{ span: 10 }}>
                        <Row>{list}</Row>
                      </Col>
                    </Row>
                  </FormItem>

                  <FormBtn
                    url="/cash-package/packages"
                    canEdit={this.props.canEdit}
                  />
                </fieldset>
              </Form>
            )}
          />
        </Spin>
      </div>
    );
  }
}
