import { createTypes, createAction } from "lib/action";

const LOAD_EMPLOYEE_TYPES = createTypes("employeeTypes", "list");
const LOAD_EMPLOYEE_TYPE = createTypes("EmployeeType", "show");
const CREATE_EMPLOYEE_TYPE = createTypes("EmployeeType", "create");
const EDIT_EMPLOYEE_TYPE = createTypes("EmployeeType", "edit");
const DELETE_EMPLOYEE_TYPE = createTypes("EmployeeType", "delete");
const UPLOAD_FILE = createTypes("EmployeeType", "import");
const DOWNLOAD_FILE = createTypes("EmployeeType", "export");
const DESTROY_MANY = createTypes("EmployeeType", "destory");

const loadEmployeeTypes = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_EMPLOYEE_TYPES.REQUEST, { pagination, limit, keyword, sort }),
  success: employeeTypes =>
    createAction(LOAD_EMPLOYEE_TYPES.SUCCESS, { employeeTypes }),
  failure: () => createAction(LOAD_EMPLOYEE_TYPES.FAILURE)
};

const loadEmployeeType = {
  request: employeeTypeId =>
    createAction(LOAD_EMPLOYEE_TYPE.REQUEST, { employeeTypeId }),
  success: employeeType =>
    createAction(LOAD_EMPLOYEE_TYPE.SUCCESS, { employeeType }),
  failure: error => createAction(LOAD_EMPLOYEE_TYPE.FAILURE, { error })
};

const createEmployeeType = {
  request: data =>
    createAction(CREATE_EMPLOYEE_TYPE.REQUEST, {
      data
    }),
  success: employeeType =>
    createAction(CREATE_EMPLOYEE_TYPE.SUCCESS, { employeeType }),
  failure: error => createAction(CREATE_EMPLOYEE_TYPE.FAILURE, { error })
};

const editEmployeeType = {
  request: (id, name, code) =>
    createAction(EDIT_EMPLOYEE_TYPE.REQUEST, {
      id,
      name,
      code
    }),
  success: employeeType =>
    createAction(EDIT_EMPLOYEE_TYPE.SUCCESS, { employeeType }),
  failure: error => createAction(EDIT_EMPLOYEE_TYPE.FAILURE, { error })
};

const deleteEmployeeType = {
  request: id => createAction(DELETE_EMPLOYEE_TYPE.REQUEST, { id }),
  success: employeeType =>
    createAction(DELETE_EMPLOYEE_TYPE.SUCCESS, { employeeType }),
  failure: error => createAction(DELETE_EMPLOYEE_TYPE.FAILURE, { error })
};
const upload = {
  request: (file, pagination, limit, keyword) =>
    createAction(UPLOAD_FILE.REQUEST, {
      file,
      pagination,
      limit,
      keyword
    }),
  success: data => createAction(UPLOAD_FILE.SUCCESS, { data }),
  failure: error => createAction(UPLOAD_FILE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: error => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_EMPLOYEE_TYPES,
  loadEmployeeTypes,
  LOAD_EMPLOYEE_TYPE,
  loadEmployeeType,
  CREATE_EMPLOYEE_TYPE,
  createEmployeeType,
  EDIT_EMPLOYEE_TYPE,
  editEmployeeType,
  DELETE_EMPLOYEE_TYPE,
  deleteEmployeeType,
  upload,
  UPLOAD_FILE,
  download,
  DOWNLOAD_FILE,
  DESTROY_MANY,
  destroyMany
};
