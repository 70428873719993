import React, { Component, Fragment } from "react";
import { Row, Col, Card, Spin } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { numberFormat } from "lib/helper";
import styled from "styled-components";

const Number = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const Increase = styled.span`
  color: #00e815;
  padding-right: inherit;
  font-size: 12px;
  font-weight: bold;
`;

const Decrease = styled.span`
  color: #c90400;
  padding-right: inherit;
  font-size: 12px;
  font-weight: bold;
`;

export class Widget extends Component {
  handleUndefined = (val) => {
    return typeof val === "undefined" ? 0 : val.amount;
  };

  percentCal = (last, week) => {
    if (last == 0) return 0;
    if (typeof week !== "number" || typeof last !== "number") return 0;
    return ((week - last) / last) * 100;
  };

  getCardType = (value) => {

    if (typeof value.data == 'undefined' || typeof value.compared == "undefined") return []

    let revenue = value.data,
        compared = value.compared

    let se_re = this.handleUndefined(revenue.service),
        se_comp = this.handleUndefined(compared.service),
        total_rev = this.handleUndefined(revenue.total),
        total_comp = this.handleUndefined(compared.total),
        percent_rev = total_rev > 0 ? se_re * 100/total_rev : 0,
        percent_comp = total_comp > 0 ? se_comp * 100/total_comp : 0,
        percent = percent_rev - percent_comp

    const cardType = [
      {
        name: "Service Revenue",
        value: se_re,
        diff: this.percentCal(se_comp, se_re),
        classname: "blue-gradient",
      },
      {
        name: "Average Per Transaction",
        value: this.handleUndefined(revenue.pertransaction),
        diff: this.percentCal(
          this.handleUndefined(compared.pertransaction),
          this.handleUndefined(revenue.pertransaction)
        ),
        classname: "blue-gradient",
      },
      {
        name: "% From Total Revenue",
        value: percent_rev,
        diff: percent,
        classname: "orange-gradient",
      },
    ];
    return cardType;
  };

  render() {
    const { revenue, loading, comparedLabel } = this.props;
    const cardType = this.getCardType(revenue);
    return (
      <Fragment>
        {cardType.map((data, i) => (
          <Row
            key={i}
            gutter={[8, 8]}
            justify="space-between"
          >
            <Col key={data.name} span={24}>
              <Card
                style={{
                  marginBottom: 10,
                  border: "1px solid #d9d9d9"
                }}
                bodyStyle={{ padding: "14px" }}
                //className={data.classname}
              >
                <Spin spinning={loading}>
                  <Row gutter={[8, 8]} justify="end">
                    <Col span={24}>{data.name}</Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Number>{numberFormat(data.value)}</Number>
                      <br />
                      {data.diff < 0 ? (
                        <Decrease>
                          <ArrowDownOutlined />
                          {numberFormat(data.diff * -1)} %
                        </Decrease>
                      ) : (
                        <Increase>
                          <ArrowUpOutlined /> {numberFormat(data.diff)} %
                        </Increase>
                      )}
                      than last {comparedLabel}
                    </Col>
                  </Row>
                </Spin>
              </Card>
            </Col>
          </Row>
        ))}
      </Fragment>
    );
  }
}

export default Widget;
