import React, { Component } from "react";
import { Table, Spin, Input, Space, Button } from "antd";
import { numberFormat } from 'lib/helper'
import {
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";

export class DetailTable extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    page: 1,
    limit: 10,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  onChangePage = (pagination) => {
    const { current, pageSize } = pagination;
    this.setState({ page: current, limit: pageSize });
  };

  render() {
    const { data, loading } = this.props
    const dataSource = typeof data.data !== 'undefined' ? data.data : []

    const columns = [
      {
        title: "#",
        dataIndex: "",
        render: (val, rec, index) =>
        this.state.limit * (this.state.page - 1) + index + 1
      },
      {
        title: "Type",
        width: 120,
        dataIndex: "type",
      },
      {
        title: "Barcode",
        dataIndex: "barcode",
        ...this.getColumnSearchProps("barcode")
      },
      {
        title: "Name",
        dataIndex: "name",
        ...this.getColumnSearchProps("name")
      },
      {
        title: "Q'ty",
        width: 80,
        dataIndex: "quantity",
        className: "align-right",
        sorter: (a, b) => a.quantity - b.quantity,
        render: (val) => numberFormat(val)
      },
      {
        title: "Revenue",
        width: 120,
        dataIndex: "total",
        className: "align-right",
        sorter: (a, b) => a.total - b.total,
        render: (val) => numberFormat(val)
      },
      {
        title: "% of Revenue",
        width: 90,
        dataIndex: "total",
        className: "align-right",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.total - b.total,
        render: (val) => {
          var total = typeof data.total !== "undefined" ? data.total : 1,
          res = (val/total) * 100
          return `${Math.round(res * 100)/100} %`
        }
      },
    ];

    return (
      <Spin spinning={loading}>
        <Table 
          rowKey={"employee_id"}
          columns={columns} 
          dataSource={dataSource} 
          size="small" 
          bordered 
          pagination={{
            pageSize: this.state.limit,
            total: dataSource.length,
            current: this.state.page,
          }}
          onChange={this.onChangePage}
        />
      </Spin>
    );
  }
}

export default DetailTable;
