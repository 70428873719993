import React, { Fragment } from "react";
import { Tag, Typography } from "antd";
const { Text } = Typography;

const TagFilter = (data, title, color) => {
  const style = window.innerWidth > 500 ? { marginBottom: '5px', fontSize: '12px' } : { marginBottom: '3px', fontSize: '9px' }
  if (typeof data !== "object") return null;
  return data.map((val, i) => {
    if (typeof val !== "undefined" && val !== "" && val !== null) {
      let items = val.split(",");
      let tag = items.map((item, idx) => {
        return <Tag key={idx} color={color} style={style}>{item}</Tag>;
      });

      return (
        <Fragment key={i}>
          <Text  style={{ padding: '12px' }}>{ title } : </Text>
          { tag }
        </Fragment>
      )
    }
    return null;
  });
};

export default TagFilter;
