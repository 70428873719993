import React from "react";
import { Table, Button, Row, Col, Spin } from "antd";
import ReactDragListView from "react-drag-listview";
import axios from "axios";

export default class Arrangement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true
    };

    this.columns = [
      {
        title: "#",
        dataIndex: "line"
      },
      {
        title: "Code",
        dataIndex: "employee_code"
      },
      {
        title: "Name",
        dataIndex: "name"
      },
      {
        title: "Type",
        dataIndex: "type_name"
      },
      {
        title: "Operates",
        key: "operate",
        render: (text, record, index) => (
          <Button type="link" className="drag-handle" href="#">
            Drag
          </Button>
        )
      }
    ];

    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = that.state.data;
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({
          data: that.formatValue(data)
        });
      },
      handleSelector: "a"
    };
  }

  getData = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/masterdata/employees`,
        {
          branch_id: this.props.branchId,
          pagination: null,
          limit: null,
          keyword: null
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      this.setState({ data: this.formatValue(res.data.data), loading: false });
    } catch (error) {
      console.error(error);
    }
  };

  formatValue = data => {
    let items = [];
    let line = 0;
    data.map((val, i) => {
      line = i + 1;
      items.push({
        ...val,
        line: line
      });
    });
    return items;
  };

  handleCancel = () => {
    this.props.onCancel("table");
  };

  handleSave = () => {
    let item = [];
    this.state.data.map((data, i) => {
      item.push({
        employee_id: data.employee_id,
        order: data.line
      });
    });
    this.props.onUpdate(this.props.branchId, item);
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <div style={{ margin: window.innerWidth <= 500 ? 0 : 20 }}>
        <Row>
          <h2>Employees Arrangement</h2>
        </Row>
        <Row>
          <Col span={24}>
            <Spin size="large" spinning={this.state.loading}>
              <ReactDragListView {...this.dragProps}>
                <Table
                  columns={this.columns}
                  pagination={false}
                  dataSource={this.state.data}
                />
              </ReactDragListView>
            </Spin>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={10} sm={10} md={6} lg={3} style={{ margin: "35px 5px" }}>
            <Button block type="primary" onClick={this.handleSave}>
              Save
            </Button>
            </Col>
            <Col xs={10} sm={10} md={6} lg={3} style={{ margin: "35px 5px" }}>
            <Button block onClick={this.handleCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
