import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Row, Col, Select, Divider, Typography, Spin, Card } from "antd";
import { getIdtoArray, numberFormat } from "lib/helper";
import Filter from "./Filter";

import CardRevenue from "./CardRevenue";
import GraphRevenue from "./GraphRevenue";
import BranchRevenue from "./BranchRevenue";
import BranchRevenueList from "./BranchRevenueList";
import moment from "moment";

const { Text, Title } = Typography;

const { Option } = Select;

const color = ["#55b9ed", "#e94a39"];
const options = [
  { value: "all", label: "All" },
  { value: "service", label: "Service" },
  { value: "product", label: "Product" },
  { value: "packages", label: "Packages" },
  { value: "cashpackage", label: "Cash Package" },
];

export class Revenue extends Component {
  state = {
    type: "month",
    duration: moment().format('YYYY-MM'),
    branch_id: [],
    query_type: "all",
    category_id: null,
    visible: false,
  };

  percentCal = (last, week) => {
    if (last == 0) return 0;
    if (typeof week !== "number" || typeof last !== "number") return 0;
    return ((week - last) / last) * 100;
  };

  handleChange = (value) => {
    this.setState({ picker: value });
  };

  handleChangeGraph = (value) => {
    this.setState({ query_type: value }, () => {
      this.fetchGraph();
      this.fetchBranch();
    });
  };

  getComparedLabel = () => {
    switch (this.state.type) {
      case "date":
      case "week":
        return "week";
      case "month":
        return "month";
    }
  };

  fetchGraph = () => {
    const { type, duration, branch_id, query_type, category_id } = this.state;
    this.props.loadGraphRevenue(
      type,
      duration,
      branch_id,
      query_type,
      category_id
    );
  };

  fetchSummary = () => {
    const { type, duration, branch_id, query_type, category_id } = this.state;
    this.props.loadRevenueSummaries(
      type,
      duration,
      branch_id,
      query_type,
      category_id
    );
  };

  fetchBranch = () => {
    const { type, duration, branch_id, query_type, category_id } = this.state;
    this.props.loadBranchesRevenue(
      type,
      duration,
      branch_id,
      query_type,
      category_id
    );
  };

  callAllFetchData = () => {
    this.fetchSummary();
    this.fetchGraph();
    this.fetchBranch();
  };

  handleFilter = (picker, date, branches) => {
    this.setState(
      {
        type: picker,
        duration: date,
        branch_id: branches,
        visible: false,
        query_type: "all",
      },
      this.callAllFetchData
    );
  };

  getSummary = (type) => {
    const { revenue_summary } = this.props;
    let val = 0;
    let service = this.handleUndefined(revenue_summary[options[1].value], type),
      product = this.handleUndefined(revenue_summary[options[2].value], type),
      packages = this.handleUndefined(revenue_summary[options[3].value], type),
      cashpackage = this.handleUndefined(
        revenue_summary[options[4].value],
        type
      );

    switch (this.state.query_type) {
      case options[1].value:
        val = service;
        break;
      case options[2].value:
        val = product;
        break;
      case options[3].value:
        val = packages;
        break;
      case options[4].value:
        val = cashpackage;
        break;
      default:
        val = service + product + packages + cashpackage;
        break;
    }

    return numberFormat(val);
  };

  handleUndefined = (val, type) => {
    return typeof val === "undefined" ? 0 : val[type];
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.branches.length !== prevProps.branches.length) {
      this.setState(
        { branch_id: getIdtoArray(this.props.branches, "value") },
        this.callAllFetchData
      );
    }
  }

  render() {
    const {
      compared_revenue,
      revenue_summary,
      loading_revenue,
      revenue_graph,
      compared_graph,
      loading_graph,
      branches_revenue,
      branches_compared,
      loading_branch_revenue,
      branches,
    } = this.props;

    return (
      <Fragment>
        <Card>
        <Filter
          branches={branches}
          onClick={this.toggleFilter}
          visible={this.state.visible}
          onSearch={this.handleFilter}
        />

        <CardRevenue
          comparedLabel={this.getComparedLabel()}
          percentCal={this.percentCal}
          revenue={revenue_summary}
          compared={compared_revenue}
          loading={loading_revenue}
        />

        <Row gutter={[16, 16]} align="middle">
          <Col xs={12} md={3} lg={3}>
            <Text>Chart compare value :</Text>
          </Col>
          <Col xs={10} md={4} lg={4}>
            <Select
              value={this.state.query_type}
              onChange={this.handleChangeGraph}
              style={{ width: "100%" }}
            >
              {options.map((data) => (
                <Option key={data.value} value={data.value}>
                  {data.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16} lg={24}>
            <Spin spinning={loading_graph}>
              <Row>
                <Col xs={24} lg={17}>
                  <GraphRevenue
                    type={this.state.type}
                    revenue={revenue_graph}
                    compared={compared_graph}
                    color={color}
                  />
                </Col>
                <Col xs={24} lg={7}>
                  <Card size="small" style={{ width: "100%" }}>
                    <Row gutter={[8, 8]}>
                      <Col span={10}>Total before discount</Col>
                      <Col span={14} className="align-right">
                        <Text>{this.getSummary("total_amount")}</Text>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={10}>Discount</Col>
                      <Col span={14} className="align-right">
                        <Text>{this.getSummary("discount")}</Text>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={10}>Sub Total</Col>
                      <Col span={14} className="align-right">
                        <Text>{this.getSummary("amount")}</Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Spin>
          </Col>
          <Divider orientation="left">Branch Revenue</Divider>
          <Col xs={24} md={8} lg={24}>
            <Spin spinning={loading_branch_revenue}>
              <Row>
                <Col xs={24} lg={15}>
                  <BranchRevenue
                    comparedLabel={this.getComparedLabel()}
                    revenue={branches_revenue}
                    compared={branches_compared}
                    color={color}
                  />
                </Col>
                <Col xs={24} lg={9}>
                  <BranchRevenueList
                    comparedLabel={this.getComparedLabel()}
                    revenue={branches_revenue}
                    compared={branches_compared}
                    percentCal={this.percentCal}
                  />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
        </Card>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ revenue }) => ({
  compared_revenue: revenue.compared_revenue,
  revenue_summary: revenue.revenue_summary,
  loading_revenue: revenue.loading_revenue,
  revenue_graph: revenue.revenue_graph,
  compared_graph: revenue.compared_graph,
  loading_graph: revenue.loading_graph,
  branches_revenue: revenue.branches_revenue,
  branches_compared: revenue.branches_compared,
  loading_branch_revenue: revenue.loading_branch_revenue,
});

const mapDispatchToProps = {
  loadRevenueSummaries: actions.loadRevenueSummaries.request,
  loadGraphRevenue: actions.loadGraphRevenue.request,
  loadBranchesRevenue: actions.loadBranchesRevenue.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Revenue);
