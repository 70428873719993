import { LOAD_CUSTOMER_PACKAGES, DOWNLOAD_FILE } from "./actions";

const initialState = {
  message: "",
  data: [],
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CUSTOMER_PACKAGES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case LOAD_CUSTOMER_PACKAGES.SUCCESS:
      return {
        ...state,
        data: action.payload.customers.data,
        totalSize: action.payload.customers.total_row,
        alertMessage: "",
        errorMessage: "",
        loading: false
      };

    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
