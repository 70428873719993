import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import ServiceTypeForm from "./ServiceTypeForm";
import { checkPermission } from "lib/helper";

class EditServiceType extends Component {
  state = {
    can_edit: checkPermission("actual_service_types", "can_edit", false)
  };
  componentDidMount() {
    this.props.loadActualServiceType(this.props.match.params.service_type_id);
  }

  editActualServiceType = async serviceType => {
    this.props.editActualServiceType(
      this.props.match.params.service_type_id,
      serviceType.actual_service_type_code,
      serviceType.actual_service_type,
      serviceType.actual_service_type_abbr
    );
  };

  render() {
    const { serviceType, alertMessage, errorMessage, loading } = this.props;

    return (
      <ServiceTypeForm
        onSubmit={this.editActualServiceType}
        serviceType={serviceType}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ actualServiceTypes }) => {
  return {
    serviceType: actualServiceTypes.actualServiceType,
    alertMessage: actualServiceTypes.alertMessage,
    errorMessage: actualServiceTypes.errorMessage,
    loading: actualServiceTypes.loading
  };
};

const mapDispatchToProps = {
  loadActualServiceType: actions.loadActualServiceType.request,
  editActualServiceType: actions.editActualServiceType.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditServiceType);
