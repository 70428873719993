import { createTypes, createAction } from "lib/action";

const LOAD_APPOINTMENT = createTypes("appointment", "list");
const LOAD_APPOINTMENT_BRANCH = createTypes("appointment_branch", "list");

const loadAppointment = {
  request: (picker, duration, branch_id, stylish_id) =>
    createAction(LOAD_APPOINTMENT.REQUEST, { picker, duration, branch_id, stylish_id }),
  success: (revenue) =>
    createAction(LOAD_APPOINTMENT.SUCCESS, { revenue }),
  failure: () => createAction(LOAD_APPOINTMENT.FAILURE),
};

const loadBranchAppointment = {
  request: (type, duration, branch_id) =>
    createAction(LOAD_APPOINTMENT_BRANCH.REQUEST, { type, duration, branch_id }),
  success: (revenue, compared) =>
    createAction(LOAD_APPOINTMENT_BRANCH.SUCCESS, { revenue, compared }),
  failure: () => createAction(LOAD_APPOINTMENT_BRANCH.FAILURE),
};


export { 
  LOAD_APPOINTMENT,
  loadAppointment,
  LOAD_APPOINTMENT_BRANCH,
  loadBranchAppointment,
};
