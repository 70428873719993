import React, { Component } from "react";

import {  Form, Input, message, Spin } from "antd";
import { PageTitle, FormBtn, RequriedText } from "lib/widget";
import { Formik } from "formik";
import * as yup from "yup";

const FormItem = Form.Item;

export default class BrandForm extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  isDisabled = () => {
    return this.props.brand.product_brand_code !== '' && this.props.editCode
  }

  componentDidMount() {
    this.nameInput.focus(); 
  }

  render() {
    const { brand, onSubmit, loading } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      }
    };
    return (
      <div>
        <Spin size="large" spinning={loading}>
          <PageTitle name="product_brands" component={null} />
          <Formik
            initialValues={brand}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              product_brand_code: yup
                .string()
                .required("Code is a required field"),
              product_brand: yup.string().required("Name is a required field")
            })}
            onSubmit={values => onSubmit(values)}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form onFinish={handleSubmit} autoComplete="off">
                <fieldset disabled={!this.props.canEdit}>
                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Code" />}
                    validateStatus={
                      touched.product_brand_code &&
                      errors.product_brand_code &&
                      "error"
                    }
                    help={
                      touched.product_brand_code && errors.product_brand_code
                    }
                  >
                    <Input
                      name="product_brand_code"
                      ref={(input) => { this.nameInput = input; }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.product_brand_code}
                      disabled={this.isDisabled()}
                    />
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Name" />}
                    validateStatus={
                      touched.product_brand && errors.product_brand && "error"
                    }
                    help={touched.product_brand && errors.product_brand}
                  >
                    <Input
                      name="product_brand"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.product_brand}
                    />
                  </FormItem>
                  <FormBtn url="/product/brands" canEdit={this.props.canEdit} />
                </fieldset>
              </Form>
            )}
          />
        </Spin>
      </div>
    );
  }
}
