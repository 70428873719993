import {
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Checkbox,
  Spin,
  Radio,
  Steps,
  Card,
} from "antd";
import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { PageTitle, SectionDivider, RequriedText } from "lib/widget";
import { numberFormat, removeComma } from "lib/helper";
import NextPrevBtn from "./NextPrevBtn";
import ActualServiceItems from "./ActualServiceItems";

const FormItem = Form.Item;
const Option = Select.Option;

const { Step } = Steps;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 10 },
    md: { span: 12 },
    sm: { span: 12 },
  },
};

const formItemPriceLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 4 },
    sm: { span: 8 },
  },
};

const steps = [
  {
    title: "Create Service package",
    content: "First-content",
  },
  {
    title: "Add Actual service",
    content: "Second-content",
  },
  {
    title: "Done",
    content: "Last-content",
  },
];
export default class ServicePackageForm extends Component {
  servicePackageGroups = [];

  state = {
    checkedList: this.props.checkedList,
    detail: [],
    step: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage, checkedList } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (checkedList.length > 0) {
      return { checkedList: checkedList };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage, checkedList } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }

    if (checkedList.length > 0 && prevState.checkedList !== checkedList) {
      this.setState({ checkedList });
    }
  }

  componentDidMount() {
    this.nameInput.focus();
  }

  onChecked = (checkedValues) => {
    const obj = this.state.checkedList;
    obj.map((check) => {
      if (check.name.toString() === checkedValues.target.name.toString())
        check.value = checkedValues.target.checked;
    });
    this.setState({
      checkedList: obj,
    });
  };

  getPrice = (value, name) => {
    if (typeof value === "undefined") return 0;

    if (value.length > 0) {
      return value[0][name];
    }

    return 0;
  };

  isDisabled = () => {
    return this.props.servicePackage.barcode !== "" && this.props.editCode;
  };

  changeStep = (num) => () => {
    this.setState({ step: this.state.step + num });
  };

  validateValue = (value) => {
    let res = Object.keys(value).map((key) => {
      return value[key] === "" ? false : true;
    });

    return res.includes(false) ? false : true;
  };

  handleSetServiceItems = (items) => {
    this.setState({ detail: items });
  };

  render() {
    const {
      servicePackage,
      servicePackageGroups,
      branches,
      loading,
      onSubmit,
    } = this.props;

    const list = branches.map((branch) => {
      const code = branch.branch_id;
      const check = this.state.checkedList.filter((c) => {
        if (c.name === code) return c;
      });
      return (
        <Col span={12} key={branch.branch_id}>
          <Checkbox
            name={branch.branch_id.toString()}
            onChange={this.onChecked}
            checked={check[0].value}
          >
            {branch.branch_name}
          </Checkbox>
        </Col>
      );
    });

    return (
      <div>
        <Spin size="large" spinning={loading}>
          <PageTitle name="service_package_service_packages" component={null} />
          <Row gutter={[16, 32]} justify="center">
            <Col sm={18}>
              <Steps current={this.state.step}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col>
          </Row>

          <Formik
            initialValues={servicePackage}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              barcode: yup.string().required("Barcode is a required field"),
              service_package_name: yup
                .string()
                .required("Name is a required field"),
              price_before_vat: yup
                .string()
                .required("Price before VAT is a required field"),
              price: yup.string().required("Price is a required field"),
              service_package_group_id: yup.string().required("Group is a required field"),

            })}
            onSubmit={(values) => {
              values["pricelist"] = [
                {
                  price_before_vat: removeComma(values["price_before_vat"]),
                  price: removeComma(values["price"]),
                },
              ];
              this.setState({ step: 2 });
              onSubmit(values, this.state.checkedList, this.state.detail);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Fragment>
                  <Form onFinish={handleSubmit} autoComplete="off">
                    <fieldset disabled={!this.props.canEdit}>
                      <div
                        style={{ display: this.state.step === 0 ? "" : "none" }}
                      >
                        <Row gutter={[16, 32]} justify="center">
                          <Col xs={24} sm={18}>
                            <Card title="Service package detail" type="inner" >
                              <FormItem
                                {...formItemLayout}
                                label={<RequriedText label="Barcode" />}
                                validateStatus={
                                  touched.barcode && errors.barcode && "error"
                                }
                                help={touched.barcode && errors.barcode}
                              >
                                <Input
                                  name="barcode"
                                  ref={(input) => {
                                    this.nameInput = input;
                                  }}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.barcode}
                                  disabled={this.isDisabled()}
                                />
                              </FormItem>
                              <FormItem
                                {...formItemLayout}
                                label={<RequriedText label="Name" />}
                                validateStatus={
                                  touched.service_package_name &&
                                  errors.service_package_name &&
                                  "error"
                                }
                                help={
                                  touched.service_package_name &&
                                  errors.service_package_name
                                }
                              >
                                <Input
                                  name="service_package_name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.service_package_name}
                                />
                              </FormItem>
                              <FormItem
                                {...formItemLayout}
                                label={<RequriedText label="Group" />}
                                validateStatus={
                                  touched.service_package_group_id &&
                                  errors.service_package_group_id &&
                                  "error"
                                }
                                help={
                                  touched.service_package_group_id &&
                                  errors.service_package_group_id
                                }
                              >
                                <Select
                                  placeholder="Select a group"
                                  onChange={(group) =>
                                    setFieldValue(
                                      "service_package_group_id",
                                      group
                                    )
                                  }
                                  value={values.service_package_group_id}
                                  disabled={!this.props.canEdit}
                                >
                                  {servicePackageGroups.map((group) => (
                                    <Option
                                      key={group.service_package_group_id}
                                      value={group.service_package_group_id}
                                    >
                                      {group.service_package_group}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>

                              <FormItem>
                                <Row>
                                  <Col
                                    xs={{ span: 10 }}
                                    sm={{ span: 8 }}
                                    style={{
                                      paddingRight: "9px",
                                      textAlign:
                                        window.innerWidth <= 500
                                          ? "left"
                                          : "right",
                                      color: "rgba(0, 0, 0, 0.85)",
                                    }}
                                  >
                                    <RequriedText label="Display on ipad :" />
                                  </Col>
                                  <Col xs={{ span: 14 }} sm={{ span: 14 }} lg={{ span: 10 }}>
                                    <Row>
                                      <Radio.Group
                                        onChange={(e) => {
                                          setFieldValue(
                                            "is_show",
                                            e.target.value
                                          );
                                        }}
                                        value={values.is_show}
                                      >
                                        <Radio value={1}>Yes</Radio>
                                        <Radio value={0}>No</Radio>
                                      </Radio.Group>
                                    </Row>
                                  </Col>
                                </Row>
                              </FormItem>

                              <SectionDivider
                                label="Service package price"
                                sm={18}
                              />

                              <FormItem
                                {...formItemPriceLayout}
                                label={
                                  <RequriedText label="Price before VAT" />
                                }
                                validateStatus={
                                  touched.price_before_vat &&
                                  errors.price_before_vat &&
                                  "error"
                                }
                                help={
                                  touched.price_before_vat &&
                                  errors.price_before_vat
                                }
                              >
                                <Input
                                  name="price_before_vat"
                                  placeholder="0.00"
                                  onChange={handleChange}
                                  onBlur={() => {
                                    setFieldValue(
                                      "price_before_vat",
                                      numberFormat(values.price_before_vat)
                                    );
                                  }}
                                  value={values.price_before_vat}
                                />
                              </FormItem>
                              <FormItem
                                {...formItemPriceLayout}
                                label={<RequriedText label="Price" />}
                                validateStatus={
                                  touched.price && errors.price && "error"
                                }
                                help={touched.price && errors.price}
                              >
                                <Input
                                  name="price"
                                  placeholder="0.00"
                                  onChange={handleChange}
                                  onBlur={() => {
                                    setFieldValue(
                                      "price",
                                      numberFormat(values.price)
                                    );
                                  }}
                                  value={values.price}
                                />
                              </FormItem>

                              <SectionDivider
                                label="Add service package branch"
                                sm={18}
                              />

                              <FormItem>
                                <Row>
                                  <Col
                                    xs={{ span: 5 }}
                                    sm={{ span: 8 }}
                                    style={{
                                      paddingRight: "9px",
                                      textAlign: "right",
                                      color: "rgba(0, 0, 0, 0.85)",
                                    }}
                                  >
                                    <RequriedText label="Branch :" />
                                  </Col>
                                  <Col xs={{ span: 19 }} sm={{ span: 14 }} lg={{ span: 10 }}>
                                    <Row>{list}</Row>
                                  </Col>
                                </Row>
                              </FormItem>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </fieldset>
                    <div
                      style={{ display: this.state.step !== 0 ? "" : "none" }}
                    >
                      <Row gutter={[16, 32]} justify="center">
                        <Col sm={18}>
                          <ActualServiceItems
                            dataSource={values.detail}
                            onAddService={this.handleSetServiceItems}
                          />
                        </Col>
                      </Row>
                    </div>

                    <Row gutter={[16, 32]} justify="center">
                      <Col sm={18} xs={24}>
                        <NextPrevBtn
                          allowNext={this.validateValue(values)}
                          allowSave={this.state.detail.length === 0}
                          onChage={this.changeStep}
                          step={this.state.step}
                          canEdit={this.props.canEdit}
                          url="/service-package/packages"
                        />
                      </Col>
                    </Row>
                  </Form>
                </Fragment>
              );
            }}
          />
        </Spin>
      </div>
    );
  }
}
