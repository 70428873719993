import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import { message } from "antd";

import BranchHasProductForm from "./BranchHasProductForm";
import { checkPermission } from "lib/helper";

class EditBranchHasProduct extends Component {
  state = {
    can_edit: checkPermission("branch_products", "can_add", false)
  };
  
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage } = nextProps;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage } = this.props;

    if (alertMessage !== "" && prevProps.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  componentDidMount() {
    let branchId = this.props.match.params.branch_id;
    if (typeof parseInt(branchId) !== "number") return;
    this.props.loadBranchProducts(branchId, null, null, null);
  }

  editBranchHasProduct = async (branchProduct) => {
    this.props.editBranchHasProduct(
      this.props.match.params.branch_id,
      branchProduct
    );
  };

  render() {
    const {
      branchProduct,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <BranchHasProductForm
        onSubmit={this.editBranchHasProduct}
        branchProduct={branchProduct}
        branchId={this.props.match.params.branch_id}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
      />
    );
  }
}

const mapStateToProps = ({ branchProducts, branches }) => {
  return {
    branchProduct: branchProducts.data,
    alertMessage: branchProducts.alertMessage,
    errorMessage: branchProducts.errorMessage,
    loading: branchProducts.loading,
    branches
  };
};

const mapDispatchToProps = {
  loadBranchProducts: actions.loadBranchProducts.request,
  editBranchHasProduct: actions.editBranchHasProduct.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBranchHasProduct);
