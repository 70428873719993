import {
  LOAD_SALE_DATATABLE,
  LOAD_SALE_GRAPH,
  LOAD_SALE_WIDGET,
  LOAD_SALE_DETAIL,
  LOAD_SALE_GRAPH_DETAIL,
  LOAD_SALE_WIDGET_DETAIL,
} from "./actions";

const initialState = {
  loading_graph: false,
  analytic_graph: [],
  loading_datatable: false,
  analytic_datatable: [],
  loading_widget: false,
  analytic_widget: {},

  loading_graph_detail: false,
  analytic_graph_detail: [],
  loading_datatable_detail: false,
  analytic_datatable_detail: [],
  loading_widget_detail: false,
  analytic_widget_detail: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SALE_WIDGET.REQUEST:
      return {
        ...state,
        loading_widget: true,
      };
    case LOAD_SALE_WIDGET.SUCCESS:
      return {
        ...state,
        loading_widget: false,
        analytic_widget: action.payload.revenue_w,
      };
    case LOAD_SALE_WIDGET.FAILURE:
      return {
        ...state,
        loading_widget: false,
      };

    case LOAD_SALE_GRAPH.REQUEST:
      return {
        ...state,
        loading_graph: true,
      };
    case LOAD_SALE_GRAPH.SUCCESS:
      return {
        ...state,
        loading_graph: false,
        analytic_graph: action.payload.revenue_g.data,
        analytic_graph_compared: action.payload.compared_g.data,
      };
    case LOAD_SALE_GRAPH.FAILURE:
      return {
        ...state,
        loading_graph: false,
      };

    case LOAD_SALE_DATATABLE.REQUEST:
      return {
        ...state,
        loading_datatable: true,
      };
    case LOAD_SALE_DATATABLE.SUCCESS:
      return {
        ...state,
        loading_datatable: false,
        analytic_datatable: action.payload.revenue_d.data,
      };
    case LOAD_SALE_DATATABLE.FAILURE:
      return {
        ...state,
        loading_datatable: false,
      };

    ///////////////////////////////////////

    case LOAD_SALE_WIDGET_DETAIL.REQUEST:
      return {
        ...state,
        loading_widget_detail: true,
      };
    case LOAD_SALE_WIDGET_DETAIL.SUCCESS:
      return {
        ...state,
        loading_widget_detail: false,
        analytic_widget_detail: action.payload.widget_detail,
      };
    case LOAD_SALE_WIDGET_DETAIL.FAILURE:
      return {
        ...state,
        loading_widget_detail: false,
      };

    case LOAD_SALE_GRAPH_DETAIL.REQUEST:
      return {
        ...state,
        loading_graph_detail: true,
      };
    case LOAD_SALE_GRAPH_DETAIL.SUCCESS:
      return {
        ...state,
        loading_graph_detail: false,
        analytic_graph_detail: action.payload.graph_detail.data,
        analytic_graph_compared_detail: action.payload.graph_detail_c.data
      };
    case LOAD_SALE_GRAPH_DETAIL.FAILURE:
      return {
        ...state,
        loading_graph_detail: false,
      };

    case LOAD_SALE_DETAIL.REQUEST:
      return {
        ...state,
        loading_datatable_detail: true,
      };
    case LOAD_SALE_DETAIL.SUCCESS:
      return {
        ...state,
        loading_datatable_detail: false,
        analytic_datatable_detail: action.payload.tb_detail,
      };
    case LOAD_SALE_DETAIL.FAILURE:
      return {
        ...state,
        loading_datatable_detail: false,
      };

    default:
      return state;
  }
};
