import { createTypes, createAction } from "lib/action";

const LOAD_BRANCH_PRODUCTS = createTypes("branchProducts", "list");
const IMPORT_BRANCH_HAS_PRODUCT = createTypes("branchProducts", "import");
const DOWNLOAD_FILE = createTypes("branchProducts", "export");
const EDIT_BRANCH_HAS_PRODUCT = createTypes("branchProducts", "edit");
const DELETE_BRANCH_HAS_PRODUCT = createTypes("branchProducts", "delete");
const DESTROY_MANY = createTypes("branchProducts", "destory");

const loadBranchProducts = {
  request: (branchId, pagination, limit, cond, sort) =>
    createAction(LOAD_BRANCH_PRODUCTS.REQUEST, {
      branchId,
      pagination,
      limit,
      cond,
      sort
    }),
  success: branchProducts =>
    createAction(LOAD_BRANCH_PRODUCTS.SUCCESS, {
      branchProducts
    }),
  failure: error => createAction(LOAD_BRANCH_PRODUCTS.FAILURE, { error })
};

const importBranchHasProduct = {
  request: (file, type, pagination, limit, keyword, branchId) =>
    createAction(IMPORT_BRANCH_HAS_PRODUCT.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword,
      branchId
    }),
  success: branchProduct =>
    createAction(IMPORT_BRANCH_HAS_PRODUCT.SUCCESS, { branchProduct }),
  failure: error => createAction(IMPORT_BRANCH_HAS_PRODUCT.FAILURE, { error })
};

const download = {
  request: branch_id => createAction(DOWNLOAD_FILE.REQUEST, { branch_id }),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const editBranchHasProduct = {
  request: (branch_id, products) => createAction(EDIT_BRANCH_HAS_PRODUCT.REQUEST, { branch_id, products }),
  success: (products) => createAction(EDIT_BRANCH_HAS_PRODUCT.SUCCESS, { products }),
  failure: error => createAction(EDIT_BRANCH_HAS_PRODUCT.FAILURE, { error })
}

const deleteBranchHasProduct = {
  request: (id, branch_id) => createAction(DELETE_BRANCH_HAS_PRODUCT.REQUEST, { id, branch_id }),
  success: products => createAction(DELETE_BRANCH_HAS_PRODUCT.SUCCESS, { products }),
  failure: error => createAction(DELETE_BRANCH_HAS_PRODUCT.FAILURE, { error })
}

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_BRANCH_PRODUCTS,
  loadBranchProducts,
  IMPORT_BRANCH_HAS_PRODUCT,
  importBranchHasProduct,
  DOWNLOAD_FILE,
  download,
  EDIT_BRANCH_HAS_PRODUCT,
  editBranchHasProduct,
  DELETE_BRANCH_HAS_PRODUCT,
  deleteBranchHasProduct,
  DESTROY_MANY,
  destroyMany
};
