import React, { Component } from "react";
import { Switch, Router } from "react-router-dom";
import "theme/theme.less";
// import "theme/antd-override.less"

import Layout from "modules/ui/components/Layout";
import history from "lib/history";

class App extends Component {

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Layout />
        </Switch>
      </Router>
    );
  }
}

export default App;