import { createTypes, createAction } from "lib/action";

const LOAD_BRANCH_TERMINALS = createTypes("branchTerminals", "list");
const LOAD_TERMINAL = createTypes("branchTerminals", "show");
const CREATE_TERMINAL = createTypes("branchTerminals", "create");
const EDIT_TERMINAL = createTypes("branchTerminals", "edit");
const DELETE_TERMINAL = createTypes("branchTerminals", "delete");
const DESTROY_MANY = createTypes("branchTerminals", "destory");

const loadBranchTerminals = {
  request: branchId =>
    createAction(LOAD_BRANCH_TERMINALS.REQUEST, { branchId }),
  success: branchTerminals =>
    createAction(LOAD_BRANCH_TERMINALS.SUCCESS, {
      branchTerminals
    }),
  failure: error => createAction(LOAD_BRANCH_TERMINALS.FAILURE, { error })
};

const loadTerminal = {
  request: terminalId => createAction(LOAD_TERMINAL.REQUEST, { terminalId }),
  success: terminal =>
    createAction(LOAD_TERMINAL.SUCCESS, {
      terminal
    }),
  failure: error => createAction(LOAD_TERMINAL.FAILURE, { error })
};

const createTerminal = {
  request: terminal => createAction(CREATE_TERMINAL.REQUEST, { terminal }),
  success: terminal =>
    createAction(CREATE_TERMINAL.SUCCESS, {
      terminal
    }),
  failure: error => createAction(CREATE_TERMINAL.FAILURE, { error })
};

const editTerminal = {
  request: (terminalId, terminal) =>
    createAction(EDIT_TERMINAL.REQUEST, { terminalId, terminal }),
  success: terminal =>
    createAction(EDIT_TERMINAL.SUCCESS, {
      terminal
    }),
  failure: error => createAction(EDIT_TERMINAL.FAILURE, { error })
};

const deleteTerminal = {
  request: id => createAction(DELETE_TERMINAL.REQUEST, { id }),
  success: terminal => createAction(DELETE_TERMINAL.SUCCESS, { terminal }),
  failure: error => createAction(DELETE_TERMINAL.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_BRANCH_TERMINALS,
  loadBranchTerminals,
  LOAD_TERMINAL,
  loadTerminal,
  CREATE_TERMINAL,
  createTerminal,
  EDIT_TERMINAL,
  editTerminal,
  DELETE_TERMINAL,
  deleteTerminal,
  DESTROY_MANY,
  destroyMany
};
