import {
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Checkbox,
  Spin,
  Radio,
} from "antd";

import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { PercentageOutlined } from "@ant-design/icons";
import { PageTitle, FormBtn, RequriedText, SectionDivider } from "lib/widget";
import { removeComma } from "lib/helper";

import PriceList from "./PriceList";
import CostList from "./CostList";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

const formItemSmallLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

const formItemPriceLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
};

export default class ProductForm extends Component {
  state = {
    checkedList: this.props.checkedList,
    pricelist: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage, checkedList } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (checkedList.length > 0) {
      return { checkedList: checkedList };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage, checkedList, product } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }

    if (checkedList.length > 0 && prevState.checkedList !== checkedList) {
      this.setState({ checkedList });
    }
  }

  componentDidMount() {
    this.nameInput.focus();
  }

  onChecked = (checkedValues) => {
    const obj = this.state.checkedList;
    obj.map((check) => {
      if (check.name.toString() === checkedValues.target.name.toString())
        check.value = checkedValues.target.checked;
    });
    this.setState({
      checkedList: obj,
    });
  };

  isDisabled = () => {
    return this.props.product.barcode !== "" && this.props.editCode;
  };

  formatPricelist = (values) => {
    return [
      {
        bin_id: 1,
        price_before_vat: removeComma(values.retail_price_before_vat),
        price: removeComma(values.retail_price),
      },
      {
        bin_id: 2,
        price_before_vat: removeComma(values.salon_price_before_vat),
        price: removeComma(values.salon_price),
      },
    ];
  };

  checkIsHasValue = (val) => {
    return (
      val !== "" && typeof val !== "undefined" && val !== null && val !== "null"
    );
  };

  render() {
    const {
      product,
      onSubmit,
      categories,
      brands,
      units,
      branches,
      distributors,
      loading,
    } = this.props;

    const list = branches.map((branch) => {
      const code = branch.branch_id;
      const check = this.props.checkedList.filter((c) => {
        if (c.name === code) return c;
      });

      return (
        <Col key={code} span={12}>
          <Checkbox
            name={branch.branch_id.toString()}
            onChange={this.onChecked}
            checked={check[0].value}
          >
            {branch.branch_name}
          </Checkbox>
        </Col>
      );
    });
    var that = this;
    return (
      <div>
        <Spin size="large" spinning={loading}>
          <PageTitle name="product_products" component={null} />
          <Formik
            initialValues={product}
            enableReinitialize={true}
            validateOnChange={false}
            validate={(values) => {
              let errors = {};
              const {
                salon_price,
                salon_price_before_vat,
                retail_price,
                retail_price_before_vat,
                salon_cost,
                salon_cost_before_vat,
                retail_cost,
                retail_cost_before_vat,
              } = values;
              // "salon_price", "salon_price_before_vat"
              // "retail_price", "retail_price_before_vat"
              // "salon_cost", "salon_cost_before_vat"
              // "retail_cost", "retail_cost_before_vat"

              if (this.checkIsHasValue(salon_price_before_vat)) {
                if (this.checkIsHasValue(salon_price) === false) {
                  errors.salon_price = "This field is a required field";
                }
                if (this.checkIsHasValue(salon_cost_before_vat) === false) {
                  errors.salon_cost_before_vat =
                    "This field is a required field";
                }
              }

              if (this.checkIsHasValue(salon_price)) {
                if (this.checkIsHasValue(salon_price_before_vat) === false) {
                  errors.salon_price_before_vat =
                    "This field is a required field";
                }
                if (this.checkIsHasValue(salon_cost) === false) {
                  errors.salon_cost = "This field is a required field";
                }
              }

              if (this.checkIsHasValue(salon_cost_before_vat)) {
                if (this.checkIsHasValue(salon_cost) === false) {
                  errors.salon_cost = "This field is a required field";
                }
                if (this.checkIsHasValue(salon_price_before_vat) === false) {
                  errors.salon_price_before_vat =
                    "This field is a required field";
                }
              }

              if (this.checkIsHasValue(salon_cost)) {
                if (this.checkIsHasValue(salon_cost_before_vat) === false) {
                  errors.salon_cost_before_vat =
                    "This field is a required field";
                }
                if (this.checkIsHasValue(salon_price) === false) {
                  errors.salon_price = "This field is a required field";
                }
              }

              /////////////////////////////////////////////////////////////////

              if (this.checkIsHasValue(retail_price_before_vat)) {
                if (this.checkIsHasValue(retail_price) === false) {
                  errors.retail_price = "This field is a required field";
                }
                if (this.checkIsHasValue(retail_cost_before_vat) === false) {
                  errors.retail_cost_before_vat =
                    "This field is a required field";
                }
              }

              if (this.checkIsHasValue(retail_price)) {
                if (this.checkIsHasValue(retail_price_before_vat) === false) {
                  errors.retail_price_before_vat =
                    "This field is a required field";
                }
                if (this.checkIsHasValue(retail_cost) === false) {
                  errors.retail_cost = "This field is a required field";
                }
              }

              if (this.checkIsHasValue(retail_cost_before_vat)) {
                if (this.checkIsHasValue(retail_cost) === false) {
                  errors.retail_cost = "This field is a required field";
                }
                if (this.checkIsHasValue(retail_price_before_vat) === false) {
                  errors.retail_price_before_vat =
                    "This field is a required field";
                }
              }

              if (this.checkIsHasValue(retail_cost)) {
                if (this.checkIsHasValue(retail_cost_before_vat) === false) {
                  errors.retail_cost_before_vat =
                    "This field is a required field";
                }
                if (this.checkIsHasValue(retail_price) === false) {
                  errors.retail_price = "This field is a required field";
                }
              }

              return errors;
            }}
            validationSchema={yup.object().shape({
              barcode: yup.string().required("Barcode is a required field"),
              product_name: yup.string().required("Name is a required field"),
              display_name: yup.string().required("Display Name is a required field"),
              product_category_id: yup
                .string()
                .required("Category is a required field"),
              salon_minimum_stock: yup
                .number()
                .typeError("Service Minimum Stock cost must be a number")
                .required("Service Minimum Stock is a required field"),
              retail_minimum_stock: yup
                .number()
                .typeError("Retail Minimum Stock cost must be a number")
                .required("Retail Minimum Stock is a required field"),
            })}
            onSubmit={(values) => {
              values["pricelist"] = this.formatPricelist(values);
              values["salon_cost_before_vat"] = removeComma(
                values["salon_cost_before_vat"]
              );
              values["salon_cost"] = removeComma(values["salon_cost"]);
              values["retail_cost_before_vat"] = removeComma(
                values["retail_cost_before_vat"]
              );
              values["retail_cost"] = removeComma(values["retail_cost"]);
              onSubmit(values, this.state.checkedList);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form onFinish={handleSubmit} autoComplete="off">
                  <fieldset disabled={!this.props.canEdit}>
                    <FormItem
                      {...formItemLayout}
                      label={<RequriedText label="Barcode" />}
                      validateStatus={
                        touched.barcode && errors.barcode && "error"
                      }
                      help={touched.barcode && errors.barcode}
                    >
                      <Input
                        name="barcode"
                        ref={(input) => {
                          this.nameInput = input;
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.barcode}
                        disabled={this.isDisabled()}
                      />
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label={<RequriedText label="Supplier Product Name" />}
                      validateStatus={
                        touched.product_name && errors.product_name && "error"
                      }
                      help={touched.product_name && errors.product_name}
                    >
                      <Input
                        name="product_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.product_name}
                      />
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label={<RequriedText label="Display Name" />}
                      validateStatus={
                        touched.display_name && errors.display_name && "error"
                      }
                      help={touched.display_name && errors.display_name}
                    >
                      <Input
                        name="display_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.display_name}
                      />
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label={<RequriedText label="Category" />}
                      validateStatus={
                        touched.product_category_id &&
                        errors.product_category_id &&
                        "error"
                      }
                      help={
                        touched.product_category_id &&
                        errors.product_category_id
                      }
                    >
                      <Select
                        placeholder="Select a category"
                        onChange={(category) =>
                          setFieldValue("product_category_id", category)
                        }
                        value={values.product_category_id}
                        disabled={!this.props.canEdit}
                      >
                        {categories.map((category) => (
                          <Option
                            key={category.product_category_id}
                            value={category.product_category_id}
                          >
                            {category.category_name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem>
                      <Row gutter={24}>
                        <Col
                          xs={{ span: 10 }}
                          sm={{ span: 8 }}
                          style={{
                            paddingRight: "9px",
                            textAlign:
                              window.innerWidth <= 500 ? "left" : "right",
                            color: "rgba(0, 0, 0, 0.85)",
                          }}
                        >
                          <RequriedText label="Display on ipad :" />
                        </Col>
                        <Col xs={{ span: 14 }} sm={{ span: 10 }}>
                          <Row>
                            <Radio.Group
                              onChange={(e) => {
                                setFieldValue("is_show", e.target.value);
                              }}
                              value={values.is_show}
                            >
                              <Radio value={1}>Yes</Radio>
                              <Radio value={0}>No</Radio>
                            </Radio.Group>
                          </Row>
                        </Col>
                      </Row>
                    </FormItem>

                    <FormItem {...formItemLayout} label="Item code">
                      <Input
                        name="item_code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.item_code}
                      />
                    </FormItem>

                    <FormItem {...formItemSmallLayout} label="Size, Unit">
                      <Row>
                        <Col xs={12} md={8} lg={8}>
                          <Input
                            name="size"
                            style={{ width: "100%" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.size}
                          />
                        </Col>
                        <Col xs={12} md={16} lg={16}>
                          <Select
                            style={{ width: "60%", marginLeft: "8px" }}
                            onChange={(unit) => setFieldValue("uom_id", unit)}
                            value={values.uom_id}
                            disabled={!this.props.canEdit}
                          >
                            {units.map((unit) => (
                              <Option key={unit.uom_id} value={unit.uom_id}>
                                {unit.uom_name}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                      </Row>
                    </FormItem>

                    <FormItem {...formItemLayout} label="Brand">
                      <Select
                        placeholder="Select a brand"
                        onChange={(brand) =>
                          setFieldValue("product_brand_id", brand)
                        }
                        value={values.product_brand_id}
                        disabled={!this.props.canEdit}
                      >
                        {brands.map((brand) => (
                          <Option
                            key={brand.product_brand_id}
                            value={brand.product_brand_id}
                          >
                            {brand.product_brand}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem {...formItemLayout} label="Distributor">
                      <Select
                        placeholder="Select a Distributor"
                        onChange={(distributor) =>
                          setFieldValue("distributor_id", distributor)
                        }
                        value={values.distributor_id}
                        disabled={!this.props.canEdit}
                      >
                        {distributors.map((distributor) => (
                          <Option
                            key={distributor.distributor_id}
                            value={distributor.distributor_id}
                          >
                            {distributor.distributor_name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem {...formItemPriceLayout} label="Commission">
                      <Input
                        name="commission"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.commission}
                        addonAfter={<PercentageOutlined />}
                      />
                    </FormItem>

                    <SectionDivider label="Stock control" />
                    <FormItem
                      {...formItemPriceLayout}
                      label={<RequriedText label="Salon minimum stock" />}
                      validateStatus={
                        touched.salon_minimum_stock &&
                        errors.salon_minimum_stock &&
                        "error"
                      }
                      help={
                        touched.salon_minimum_stock &&
                        errors.salon_minimum_stock
                      }
                    >
                      <Input
                        name="salon_minimum_stock"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.salon_minimum_stock}
                      />
                    </FormItem>
                    <FormItem
                      {...formItemPriceLayout}
                      label={<RequriedText label="Retail minimum stock" />}
                      validateStatus={
                        touched.retail_minimum_stock &&
                        errors.retail_minimum_stock &&
                        "error"
                      }
                      help={
                        touched.retail_minimum_stock &&
                        errors.retail_minimum_stock
                      }
                    >
                      <Input
                        name="retail_minimum_stock"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.retail_minimum_stock}
                      />
                    </FormItem>

                    <SectionDivider label="Product cost" />
                    <Row justify="center" gutter={[16, 16]}>
                      <Col xs={24} sm={13}>
                        <CostList
                          retail={{
                            cost_before_vat: values.retail_cost_before_vat,
                            cost: values.retail_cost,
                            errors: {
                              cost_before_vat: errors.retail_cost_before_vat,
                              cost: errors.retail_cost,
                            },
                          }}
                          salon={{
                            cost_before_vat: values.salon_cost_before_vat,
                            cost: values.salon_cost,
                            errors: {
                              cost_before_vat: errors.salon_cost_before_vat,
                              cost: errors.salon_cost,
                            },
                          }}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </Col>
                    </Row>

                    <SectionDivider label="Product price" />

                    <Row justify="center" gutter={[16, 16]}>
                      <Col xs={24} sm={13}>
                        <PriceList
                          retail={{
                            price_before_vat: values.retail_price_before_vat,
                            price: values.retail_price,
                            errors: {
                              price_before_vat: errors.retail_price_before_vat,
                              price: errors.retail_price,
                            },
                          }}
                          salon={{
                            price_before_vat: values.salon_price_before_vat,
                            price: values.salon_price,
                            errors: {
                              price_before_vat: errors.salon_price_before_vat,
                              price: errors.salon_price,
                            },
                          }}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          data={values.pricelist}
                        />
                      </Col>
                    </Row>

                    <SectionDivider label="Add product to branch" />

                    <FormItem>
                      <Row>
                        <Col
                          xs={{ span: 5 }}
                          sm={{ span: 8 }}
                          style={{
                            paddingRight: "9px",
                            textAlign: "right",
                            color: "rgba(0, 0, 0, 0.85)",
                          }}
                        >
                          <RequriedText label="Branch :" />
                        </Col>
                        <Col xs={{ span: 19 }} sm={{ span: 10 }}>
                          <Row>{list}</Row>
                        </Col>
                      </Row>
                    </FormItem>

                    <FormBtn
                      url="/product/products"
                      canEdit={this.props.canEdit}
                    />
                  </fieldset>
                </Form>
              );
            }}
          />
        </Spin>
      </div>
    );
  }
}
