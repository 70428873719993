import React from "react";
import { Switch } from "react-router-dom";

import CategoryList from "./CategoryList";
import EditCategory from "./EditCategory";
import NewCategory from "./NewCategory";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute path="/product/categories/new" component={NewCategory} perm="product_categories" action="can_add"/>
    <PrivateRoute path="/product/categories/edit/:category_id" component={EditCategory} perm="product_categories"/>
    <PrivateRoute path="/product/categories" component={CategoryList} perm="product_categories"/>
  </Switch>
);
