import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import PackageGroupForm from "./PackageGroupForm";
import { checkPermission } from "lib/helper";

class EditPackageGroup extends Component {
  state = {
    can_edit: checkPermission("service_package_groups", "can_edit", false)
  };

  componentDidMount() {
    this.props.loadServicePackageGroup(
      this.props.match.params.service_package_group_id
    );
  }

  editServicePackageGroup = async packageGroup => {
    this.props.editServicePackageGroup(
      this.props.match.params.service_package_group_id,
      packageGroup.service_package_group_code,
      packageGroup.service_package_group
    );
  };

  render() {
    const { packageGroup, alertMessage, errorMessage, loading } = this.props;

    return (
      <PackageGroupForm
        onSubmit={this.editServicePackageGroup}
        packageGroup={packageGroup}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ servicePackageGroups }) => {
  return {
    packageGroup: servicePackageGroups.servicePackageGroup,
    alertMessage: servicePackageGroups.alertMessage,
    errorMessage: servicePackageGroups.errorMessage,
    loading: servicePackageGroups.loading
  };
};

const mapDispatchToProps = {
  loadServicePackageGroup: actions.loadServicePackageGroup.request,
  editServicePackageGroup: actions.editServicePackageGroup.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPackageGroup);
