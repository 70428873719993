import { call } from "redux-saga/effects";
import axios from "axios";
import { getToken } from "lib/helper";
const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
const goUrl = `${process.env.REACT_APP_API_GO}/api`;

const headers = {
  headers: {
    Authorization: `Bearer ${getToken("token")}`
  }
};

const uploadHeader = {
  headers: {
    Authorization: `Bearer ${getToken("token")}`,
    "Content-Type": "multipart/form-data"
  }
}

const fetchGo = {
  post: (url, params) => {
    return call(axios.post, `${goUrl}/${url}`, params, headers);
  },
  get: (url) => {
    return axios.get(`${goUrl}/${url}`, headers)
  },
  postData: (url, params) => {
    return axios.post(`${goUrl}/${url}`, params, headers)
  },
  branches: () => {
    return axios.post(`${goUrl}/branches`, {}, headers)
  }
}

const fetcher = {
  post: (url, params, type = null) => {
    return call(axios.post, `${baseUrl}/${url}`, params, type === null ? headers : uploadHeader);
  },
  get: (url) => {
    return call(axios.get, `${baseUrl}/${url}`, headers);
  },
  patch: (url, params, type = null) => {
    return call(axios.patch, `${baseUrl}/${url}`, params, type === null ? headers : uploadHeader);
  },
  delete: (url) => {
    return call(axios.delete, `${baseUrl}/${url}`, headers);
  },
  download: (params) => {
    return window.location.assign(`${baseUrl}/download/${params}`);
  },
  product: params => axios.post(`${baseUrl}/autocomplete/products`, params, headers),
  actualservice: params => axios.post(`${baseUrl}/autocomplete/actualservices`, params, headers),
  servicepackage: params => axios.post(`${baseUrl}/autocomplete/servicepackages`, params, headers),
  customer: params => axios.post(`${baseUrl}/autocomplete/customers`, params, headers),
  employee: params => axios.post(`${baseUrl}/autocomplete/employees`, params, headers),
  user: params => axios.post(`${baseUrl}/autocomplete/users`, params, headers),
  permission: params => axios.post(`${baseUrl}/autocomplete/permissions`, params, headers),
  cashpackage: params => axios.post(`${baseUrl}/autocomplete/cashpackages`, params, headers),
  transfercustomer: params => axios.post(`${baseUrl}/transfer-customer/getdata`, params, headers),
};

const TransferStock = params => {
  return axios.post(`${baseUrl}/stockbalance/transferbin`, params, headers)
}

const TransferCustomer = {
  getData: params => axios.post(`${baseUrl}/transfer-customer/getdata`, params, headers),
  getTransactions: params => axios.post(`${baseUrl}/transfer-customer/gettransactions`, params, headers),
  getServicePackage: params => axios.post(`${baseUrl}/transfer-customer/getservicepackage`, params, headers),
  updatedata: params => axios.post(`${baseUrl}/transfer-customer/updatedata`, params, headers),
}

const getErrorMsg = error => {
  const { response } = error;
  if (typeof response.data.message !== "undefined") {
    if (typeof response.data.message === 'object') {
      let msg = Object.keys(response.data.message).map(x => response.data.message[x])
      let errorMsg = ""
      if (typeof msg === 'object') {
        msg.map(data => {
          if (typeof data === 'object') {
            errorMsg = Object.keys(data).map(x => data[x])
          } else {
            errorMsg += (errorMsg === "" ? "" : " ,") + data
          }
        })
      } else {
        errorMsg = msg
      }
      return errorMsg
    }
    return response.data.message;
  }
  return error.message + " : " + response.statusText;
};

const handleError = error => {
  const { response } = error;
  if (typeof response === "undefined") {
    console.log("===Start handleError===")
    logErrorMsg(error)
    console.log("===End handleError===")
    return
  }
  if (response.status === 401 || response.status === 403) {
    localStorage.clear();
    sessionStorage.clear();
    alert(getErrorMsg(error));
    window.location.href = "/signin";
  }
};

const logErrorMsg = error => {
  console.log("errorType", typeof error);
  console.log("error", Object.assign({}, error));
  console.log("getOwnPropertyNames", Object.getOwnPropertyNames(error));
  console.log("stackProperty", Object.getOwnPropertyDescriptor(error, "stack"));
  console.log(
    "messageProperty",
    Object.getOwnPropertyDescriptor(error, "message")
  );
  console.log("stackEnumerable", error.propertyIsEnumerable("stack"));
  console.log("messageEnumerable", error.propertyIsEnumerable("message"));
  console.log("message", error.message);
};


export { fetcher, fetchGo, TransferStock, logErrorMsg, getErrorMsg, handleError, TransferCustomer };
