import React, { Component } from "react";

import { Row, Col, Typography, Spin, Badge, Card } from "antd";
import { numberFormat, capitalize, sortingObj } from "lib/helper";
import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const { Text } = Typography;
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#ff7875"];

export class GraphDetail extends Component {
  formatPiechartValue = (obj, key) => {
    let items = [];
    if (typeof obj === "undefined") return [];

    Object.keys(obj).map((k, i) => {
      let data = [],
        amt = 0,
        sort = obj[k].sort(sortingObj(key, "desc"));

      sort.map((v, i) => {
        if (i <= 2) {
          data.push({
            name: v.service_type,
            value: v[key],
          });
          return true;
        }
        amt += v[key];
      });

      if (sort.length > 3) {
        data.push({
          name: "Other",
          value: amt,
        });
      }

      items[k] = data;
    });

    return items;
  };

  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#595959"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  genGraph = (data, key, point) => {
    if (typeof data[key] === "undefined") return null;
    if (data[key].length === 0) return null;

    return (
      <div style={{ width: "100%", height: 200 }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={data[key]}
              cx="50%"
              cy="50%"
              outerRadius={60}
              fill="#55b9ed"
              label={this.renderCustomizedLabel}
            >
              {data[key].map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => numberFormat(value, point)} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  genGraphDetail = (data, key, point) => {
    if (typeof data[key] === "undefined") return null;
    if (data[key].length === 0) return null;

    let sumR = 0;

    return (
      <Row gutter={[8, 16]}>
        <Col span={24}>
          {data[key].map((v, i) => {
            sumR += parseFloat(v.value);
            return (
              <Row
                gutter={[8, 8]}
                key={i}
                style={{ marginTop: i == 0 ? "10px" : "0px" }}
              >
                <Col span={12}>
                  <Badge color={COLORS[i]} text={v.name} />
                </Col>
                <Col span={12} className="align-right">
                  {numberFormat(v.value, point)}
                </Col>
              </Row>
            );
          })}
          <Row gutter={[8, 8]} style={{ marginTop: "0px" }}>
            <Col span={12}>
              <b>Total {point === 0 ? "Job" : "Revenue"}</b>
            </Col>
            <Col span={12} className="align-right">
              <b>{numberFormat(sumR, point)}</b>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  genComponent = (name, i, dataR, dataQ) => {
    if (typeof dataR[name] === "undefined") return null;
    if (dataR[name].length === 0) return null;
    let title = capitalize(name);
    return (
      <Row key={i} gutter={[16, 16]}>
        <Col span={12}>
          <Card>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Text strong>{title} Revenue</Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{this.genGraph(dataR, name, 2)}</Col>
            </Row>
            {this.genGraphDetail(dataR, name, 2)}
          </Card>
        </Col>

        <Col span={12}>
          <Card>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Text strong>{title} Job</Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{this.genGraph(dataQ, name, 0)}</Col>
            </Row>
            {this.genGraphDetail(dataQ, name, 0)}
          </Card>
        </Col>
      </Row>
    );
  };

  render() {
    const { loading, data } = this.props;
    const itemsAmt = this.formatPiechartValue(data, "amt");
    const itemsQty = this.formatPiechartValue(data, "qty");

    return (
      <Spin spinning={loading}>
        {["service", "usepackage"].map((name, i) => {
          return this.genComponent(name, i, itemsAmt, itemsQty);
        })}
      </Spin>
    );
  }
}

export default GraphDetail;
