import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as actions from "../actions";
import { Row, Col, Card } from "antd";

const widget = [
    "Product Revenue",
    "Cost",
    "Gross Profit",
    "Quantity",
    "% From Total Revenue"
]

export class Widget extends Component {
    render() {
        return (
            <Fragment>
            { widget.map(name => {
                return <Card key={name}>{name}</Card>
            }) }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget)
