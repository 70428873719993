import React, { Component } from 'react'
import { Table, Spin } from 'antd';
import { TransferCustomer } from "lib/fetch"

export default class TransactionTable extends Component {
    state = {
        lists: [],
        loading: false
    }

    handleChange = (selectedRowKeys, selectedRows) => {
        this.props.onChange(selectedRowKeys)
    }

    componentDidUpdate(prevProps, prevState) {
        const { customerId, isShowList } = this.props

        if (!isShowList || customerId === "") {
            return
        }

        if (prevProps.isShowList != isShowList || prevProps.customerId !== customerId) {
            this.setState({ loading: true })
            TransferCustomer.getTransactions({ customer_id: customerId }).then(res => {
                this.setState({ lists: res.data.data, loading: false })
            })
        }
    }

    render() {

        const columns = [
            {
                title: 'Document ID',
                dataIndex: 'document_id',
            },
            {
                title: 'Document Date',
                dataIndex: 'document_date',
            },
            {
                title: 'Branch',
                dataIndex: 'branch_name',
            },
        ];

        return (
            <Spin spinning={this.state.loading}>
                <Table
                    size="small"
                    rowKey={(record) => record.transaction_id.toString()}
                    rowSelection={{
                        type: "checkbox",
                        onChange: this.handleChange,
                    }}
                    columns={columns}
                    dataSource={this.state.lists}
                />
            </Spin>
        )
    }
}
