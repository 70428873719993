import * as moment from "moment";

const dummyRequest = ({ file, onSuccess }) => {
  var reader = new window.FileReader();
  reader.readAsDataURL(file);
  reader.onload = (...args) => {
    onSuccess("done", file);
  };
};

const setSearch = (value) => {
  let search = typeof value === "object" ? JSON.stringify(value) : value;
  localStorage.setItem("search", search);
};
const getSearch = () => {
  let search = localStorage.getItem("search");
  try {
    JSON.parse(search);
  } catch (e) {
    return search;
  }
  return JSON.parse(search);
};

const setFilterTag = (value) => {
  localStorage.setItem("filterTag", JSON.stringify(value));
};

const getFilterTag = () => {
  let data = localStorage.getItem("filterTag");
  return JSON.parse(data);
};

const setSorter = (value) => {
  let sorter = typeof value === "object" ? JSON.stringify(value) : value;
  localStorage.setItem("sorter", sorter);
};

const getSorter = () => {
  let sorter = localStorage.getItem("sorter");
  try {
    JSON.parse(sorter);
  } catch (e) {
    return sorter;
  }
  return JSON.parse(sorter);
};

const setBranchFilter = (value) => {
  localStorage.setItem("branchFilter", JSON.stringify(value));
};

const getBranchFilter = () => {
  return JSON.parse(localStorage.getItem("branchFilter"));
};

const utcToLocalTime = (dateString) => {
  let date = moment(dateString).add(7, "hours").format("YYYY-MM-DD HH:mm:ss");
  let newDate = formatUtcToDatetime(date);
  return newDate;
};
const formatUtcToDatetime = (utc) => {
  if (utc === "") return;
  var rex = /[A-z]/g;
  return utc.replace(rex, " ").trimEnd();
};

const extractKeyFromArray = (data, keyVal = "id", unique = false) => {
  if (typeof data !== "object") return true;
  let arr = [];
  let prevKey = "";
  Object.keys(data).reduce((obj, key) => {
    let value = data[key][keyVal];
    if (unique === true && value === prevKey) return true;

    prevKey = value;
    if (keyVal === "id") {
      return arr.push(parseInt(value));
    }
    return arr.push(value);
  }, {});

  return arr;
};

const getIdtoArray = (data, keyValu = "id") => {
  let arr = [];
  Object.keys(data).reduce((obj, key) => {
    if (keyValu === "id") {
      return arr.push(parseInt(data[key][keyValu]));
    }

    return arr.push(data[key][keyValu]);
  }, {});
  return arr;
};

const groupArray = (data, key) => {
  return data.reduce(function (r, a) {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create(null));
};

const checkPermission = (name, can = "can_view", withAlert = true) => {
  let perm = getPermission();
  let idx = perm.findIndex((x) => x.permission_name === name);
  if (idx === -1) {
    if (withAlert) {
      window.location.reload();
      window.alert("You are not allowed to this module");
    }
    return false;
  } else {
    return checkAction(perm[idx][can], withAlert);
  }
};

const checkAction = (value, withAlert) => {
  if (parseInt(value) === 1) return true;
  if (withAlert) {
    window.location.reload();
    window.alert("You are not allowed to this module");
  }
  return false;
};

const getPermission = () => {
  let perm = localStorage.getItem("menu");
  return JSON.parse(perm);
};

const numberFormat = (number, decimal = 2) => {
  if (
    typeof number !== "string" &&
    typeof number !== "number" &&
    typeof number == "undefined" &&
    number === null
  )
    return;
  let value = removeComma(number);
  if (value === "" || isNaN(value)) value = 0;
  return parseFloat(removeComma(value))
    .toFixed(decimal)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const removeComma = (number) => {
  if (typeof number === "string" || typeof number === "number") {
    return number.toString().replace(/,/g, "");
  }
  return number;
};

const getToken = (name) => localStorage.getItem(name);

const toSingularNoun = (data) => {
  if (typeof data !== "string") return data;

  let suffixIES = data.substring([data.length - 3]);
  let suffixES = data.substring([data.length - 2]);
  let suffixS = data.substring([data.length - 1]);

  if (suffixIES === "ies") return data.replace(/.{3}$/g, "y");
  if (suffixES === "es") return data.replace(/.{2}$/g, "");
  if (suffixS === "s") return data.replace(/.{1}$/g, "");

  return data;
};

const uniqueArray = (data, key) => {
  const unique = [];
  data.map((x) =>
    unique.filter((a) => a[key] === x[key]).length > 0 ? null : unique.push(x)
  );
  return unique;
};

const uniqueArray2key = (data, key1, key2) => {
  const unique = [];
  data.map((x) =>
    unique.filter((a) => a[key1] === x[key1] && a[key2] === x[key2]).length > 0
      ? null
      : unique.push(x)
  );
  return unique;
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

function sortingObj(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export {
  dummyRequest,
  extractKeyFromArray,
  formatUtcToDatetime,
  getIdtoArray,
  getSearch,
  setSearch,
  groupArray,
  getPermission,
  checkPermission,
  numberFormat,
  removeComma,
  utcToLocalTime,
  getToken,
  toSingularNoun,
  uniqueArray,
  uniqueArray2key,
  setSorter,
  getSorter,
  setFilterTag,
  getFilterTag,
  setBranchFilter,
  getBranchFilter,
  capitalize,
  sortingObj,
};
