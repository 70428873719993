import {
  LOAD_REVENUE_SUMMARIES,
  LOAD_REVENUE_GRAPH,
  LOAD_REVENUE_BRANCH,
  LOAD_REVENUE_PERTRANS_SUMMARIES,
  LOAD_REVENUE_PERTRANS_GRAPH,
  LOAD_REVENUE_PERTRANS_BRANCH,
} from "./actions";

const initialState = {
  revenue_summary: {
    service: { amount: 0, discount: 0, total_amount: 0 },
    product: { amount: 0, discount: 0, total_amount: 0 },
    packages: { amount: 0, discount: 0, total_amount: 0 },
    cashpackage: { amount: 0, discount: 0, total_amount: 0 },
  },
  compared_revenue: {
    service: { amount: 0, discount: 0, total_amount: 0 },
    product: { amount: 0, discount: 0, total_amount: 0 },
    packages: { amount: 0, discount: 0, total_amount: 0 },
    cashpackage: { amount: 0, discount: 0, total_amount: 0 },
  },

  loading_revenue: false,
  revenue_graph: [],
  compared_graph: [],
  loading_graph: false,
  branches_revenue: [],
  branches_compared: [],
  loading_branch_revenue: false,

  loading_appoint: false,
  appoint_graph: [],
  appoint_compared_graph: [],
  loading_appoint_branch: false,
  appoint_branches_revenue: [],
  appoint_branches_compared: [],

  revenue_summary_t: [],
  compared_revenue_t: [],
  loading_revenue_t: false,
  revenue_graph_t: [],
  compared_graph_t: [],
  loading_graph_t: false,
  branches_revenue_t: [],
  branches_compared_t: [],
  loading_branch_revenue_t: false,

};

export default (state = initialState, action) => {
  switch (action.type) {

    case LOAD_REVENUE_SUMMARIES.REQUEST:
      return {
        ...state,
        loading_revenue: true,
      };
    case LOAD_REVENUE_SUMMARIES.SUCCESS:
      let compared_revenue =
        action.payload.compared === null ? [] : action.payload.compared.data;
      return {
        ...state,
        revenue_summary: action.payload.revenue.data,
        compared_revenue: compared_revenue,
        loading_revenue: false,
      };
    case LOAD_REVENUE_SUMMARIES.FAILURE:
      return {
        ...state,
        loading_revenue: false,
      };

    case LOAD_REVENUE_GRAPH.REQUEST:
      return {
        ...state,
        loading_graph: true,
      };
    case LOAD_REVENUE_GRAPH.SUCCESS:
      let compared_graph =
        action.payload.compared === null ? [] : action.payload.compared.data;
      return {
        ...state,
        revenue_graph: action.payload.revenue.data,
        compared_graph: compared_graph,
        loading_graph: false,
      };
    case LOAD_REVENUE_GRAPH.FAILURE:
      return {
        ...state,
        loading_graph: false,
      };

    case LOAD_REVENUE_BRANCH.REQUEST:
      return {
        ...state,
        loading_branch_revenue: true,
      };
    case LOAD_REVENUE_BRANCH.SUCCESS:
      return {
        ...state,
        branches_revenue: action.payload.revenue.data,
        branches_compared: action.payload.compared.data,
        loading_branch_revenue: false,
      };
    case LOAD_REVENUE_BRANCH.FAILURE:
      return {
        ...state,
        loading_branch_revenue: false,
      };

    case LOAD_REVENUE_PERTRANS_SUMMARIES.REQUEST:
      return {
        ...state,
        loading_revenue_t: true,
      };
    case LOAD_REVENUE_PERTRANS_SUMMARIES.SUCCESS:
      return {
        ...state,
        revenue_summary_t: action.payload.revenue_t.data,
        compared_revenue_t: action.payload.compared_t.data,
        loading_revenue_t: false,
      };
    case LOAD_REVENUE_PERTRANS_SUMMARIES.FAILURE:
      return {
        ...state,
        loading_revenue_t: false,
      };

    case LOAD_REVENUE_PERTRANS_GRAPH.REQUEST:
      return {
        ...state,
        loading_graph_t: true,
      };
    case LOAD_REVENUE_PERTRANS_GRAPH.SUCCESS:
      return {
        ...state,
        revenue_graph_t: action.payload.revenue_gt.data,
        compared_graph_t: action.payload.compared_gt.data,
        loading_graph_t: false,
      };
    case LOAD_REVENUE_PERTRANS_GRAPH.FAILURE:
      return {
        ...state,
        loading_graph_t: false,
      };

    case LOAD_REVENUE_PERTRANS_BRANCH.REQUEST:
      return {
        ...state,
        loading_branch_revenue_t: true,
      };
    case LOAD_REVENUE_PERTRANS_BRANCH.SUCCESS:
      return {
        ...state,
        branches_revenue_t: action.payload.revenue_bt.data,
        branches_compared_t: action.payload.compared_bt.data,
        loading_branch_revenue_t: false,
      };
    case LOAD_REVENUE_PERTRANS_BRANCH.FAILURE:
      return {
        ...state,
        loading_branch_revenue: false,
      };

    default:
      return state;
  }
};
