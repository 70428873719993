import React, { Component, Fragment } from "react";
import { Table, Row, Input, Form } from "antd";
import { numberFormat } from "lib/helper";
const FormItem = Form.Item;

export default class PriceList extends Component {
  state = {
    items: [
      {
        key: 1,
        bin_id: 2,
        name: "Salon",
        cost_before_vat: "",
        cost: "",
      },
      {
        key: 0,
        bin_id: 1,
        name: "Retail",
        cost_before_vat: "",
        cost: "",
      },
    ],
  };

  handleBlur = e => {
    this.props.handleBlur(e)
    this.setState({ [e.target.name] : numberFormat(e.target.value) })
  }

  componentDidUpdate(prevProps, prevState) {
    const { retail, salon } = this.props;
    const { items } = prevState;

    let retail_cost_before_vat = items[1].cost_before_vat,
      salon_cost_before_vat = items[0].cost_before_vat,
      retail_cost = items[1].cost,
      salon_cost = items[0].cost,
      isUpdate = false;

    if (retail.cost_before_vat !== prevProps.retail.cost_before_vat) {
      retail_cost_before_vat = retail.cost_before_vat;
      isUpdate = true;
    }

    if (salon.cost_before_vat !== prevProps.salon.cost_before_vat) {
      salon_cost_before_vat = salon.cost_before_vat;
      isUpdate = true;
    }

    if (retail.cost !== prevProps.retail.cost) {
      retail_cost = retail.cost;
      isUpdate = true;
    }

    if (salon.cost !== prevProps.salon.cost) {
      salon_cost = salon.cost;
      isUpdate = true;
    }

    if (isUpdate) {
      this.setState((prevState) => {
        prevState.items[1].cost_before_vat = retail_cost_before_vat;
        prevState.items[1].cost = retail_cost;
        prevState.items[0].cost_before_vat = salon_cost_before_vat;
        prevState.items[0].cost = salon_cost;
        return {
          prevState,
        };
      });

      this.props.setFieldValue(`retail_cost_before_vat`, retail_cost_before_vat);
      this.props.setFieldValue(`retail_cost`, retail_cost);
      this.props.setFieldValue(`salon_cost_before_vat`, salon_cost_before_vat);
      this.props.setFieldValue(`salon_cost`, salon_cost);
    }
  }

  render() {
    const { salon, retail } = this.props;
    const err_salon = salon.errors,
      err_retail = retail.errors

    const columns = [
      {
        title: "Type",
        width: window.innerWidth <= 1025 ? 80 : 200,
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Cost before VAT",
        dataIndex: "cost_before_vat",
        render: (text, r) => {
          return (
            <FormItem
              style={{ margin: "0px" }}
              validateStatus={
                (r.bin_id === 1
                  ? err_retail.cost_before_vat
                  : err_salon.cost_before_vat) && "error"
              }
              help={
                r.bin_id === 1
                  ? err_retail.cost_before_vat
                  : err_salon.cost_before_vat
              }
            >
              <Input
                name={
                  r.bin_id === 1
                    ? `retail_cost_before_vat`
                    : `salon_cost_before_vat`
                }
                data-id={r.bin_id}
                placeholder="0.00"
                value={r.cost_before_vat}
                onChange={this.props.handleChange}
                onBlur={(e) => {
                  let val = e.target.value !== '' ? numberFormat(e.target.value) : ''
                  this.props.setFieldValue(`${e.target.name}`, val);
                  this.props.handleBlur(e)
                }}
              />
            </FormItem>
          );
        },
      },
      {
        title: "Cost",
        dataIndex: "cost",
        key: "cost",
        render: (text, r) => {
          return (
            <FormItem
              style={{ margin: "0px" }}
              validateStatus={
                (r.bin_id === 1
                  ? err_retail.cost
                  : err_salon.cost) && "error"
              }
              help={
                r.bin_id === 1
                  ? err_retail.cost
                  : err_salon.cost
              }
            >
              <Input
                name={r.bin_id === 1 ? `retail_cost` : `salon_cost`}
                data-id={r.bin_id}
                placeholder="0.00"
                value={r.cost}
                onChange={this.props.handleChange}
                onBlur={(e) => {
                  let val = e.target.value !== '' ? numberFormat(e.target.value) : ''
                  this.props.setFieldValue(`${e.target.name}`, val);
                  this.props.handleBlur(e)
                }}
              />
            </FormItem>
          );
        },
      },
    ];
    return (
      <Fragment>
        <Row gutter={[16, 16]}>
          <Table
            size="small"
            bordered
            columns={columns}
            dataSource={this.state.items}
            pagination={false}
          />
        </Row>
      </Fragment>
    );
  }
}
