import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadBrands(action) {
  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post('product_brands/productbrands_list', { pagination, limit, keyword, sort })
    yield put(actions.loadBrands.success(res.data));
  } catch (error) {
    handleError(error)
  }
}

function* loadBrand(action) {
  try {
    const { brandId } = action.payload;
    const res = yield fetcher.get(`product_brands/${brandId}`)
    yield put(actions.loadBrand.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadBrand.failure(getErrorMsg(error)));
  }
}

function* createBrand(action) {
  try {
    const { code, name } = action.payload;
    const res = yield fetcher.post('product_brands', { product_brand_code: code, product_brand: name })
    yield put(actions.createBrand.success(res.data));
    yield put(push("/product/brands"));
  } catch (error) {
    handleError(error)
      yield put(actions.createBrand.failure(getErrorMsg(error)));
    
  }
}

function* editBrand(action) {
  const { id, code, name } = action.payload;
  try {
    const res = yield fetcher.patch(`product_brands/${id}`, { product_brand_code: code, product_brand: name })
    yield put(actions.editBrand.success(res.data));
    yield put(push("/product/brands"));
  } catch (error) {
    handleError(error)
    yield put(actions.editBrand.failure(getErrorMsg(error)));  
  }
}

function* deleteBrand(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`product_brands/${id}`)
    yield put(actions.deleteBrand.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.deleteBrand.failure(getErrorMsg(error)));
  }
}

function* upload(action) {
  try {
    const { file, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", "productBrand");
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    const res = yield fetcher.post('import/details', formData)
    yield put(actions.upload.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.upload.failure(getErrorMsg(error)));
    yield put(push("/product/brands"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post('export/export_masterdata', {type: "productBrand"})
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error)
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/product/brand`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchBrands() {
  yield all([
    takeEvery(actions.LOAD_BRANDS.REQUEST, loadBrands),
    takeEvery(actions.CREATE_BRAND.REQUEST, createBrand),
    takeEvery(actions.LOAD_BRAND.REQUEST, loadBrand),
    takeEvery(actions.EDIT_BRAND.REQUEST, editBrand),
    takeEvery(actions.DELETE_BRAND.REQUEST, deleteBrand),
    takeEvery(actions.UPLOAD_FILE.REQUEST, upload),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
