import { fork } from "redux-saga/effects";

import watchAppointment from "./components/appointment/sagas";
import watchCalendar from "./components/calendar/sagas";
import watchRevenue from "./components/revenue/sagas";
import watchTopItems from "./components/top_items/sagas";
import watchWidgets from "./components/widget/sagas";

const watchDashboard = [
  fork(watchAppointment),
  fork(watchCalendar),
  fork(watchRevenue),
  fork(watchTopItems),
  fork(watchWidgets)
]
export {
  watchDashboard
}
