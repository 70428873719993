import {
  Button,
  Form,
  Input,
  Row,
  Col,
  AutoComplete,
  message,
  Spin
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";

import * as actions from "../actions";
import { loadActualServices } from "lib/select-option";
import { PageTitle } from "lib/widget";

const FormItem = Form.Item;
var value_list = [];
var masterdata = {
  actual_service_id: "",
  barcode: "",
  name: "",
  description: "",
  quantity: ""
};

class NewPackageDetail extends Component {
  state = {
    submit: false
  };
  componentDidMount() {
    masterdata = {
      actual_service_id: "",
      barcode: "",
      name: "",
      description: "",
      quantity: ""
    };
  }

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.alertMessage !== this.props.alertMessage &&
      nextProps.alertMessage !== ""
    ) {
      message.success(nextProps.alertMessage);
    }
    if (
      nextProps.errorMessage !== "" &&
      this.props.errorMessage !== "" &&
      this.state.submit
    ) {
      message.error(nextProps.errorMessage);
      this.setState({ submit: false });
    }
  };

  createServicePackageDetail = async servicePackageDetail => {
    this.setState({ submit: true });
    this.props.createServicePackageDetail(
      this.props.match.params.service_package_id,
      servicePackageDetail
    );
  };

  onSelect(value) {
    const v = value.split(" ");

    value_list.map(actual_service => {
      if (actual_service.barcode === v[0]) {
        masterdata = {
          ...masterdata,
          actual_service_id: actual_service.actual_service_id,
          barcode: actual_service.barcode,
          name: actual_service.service_name
        };
      }
    });
  }

  render() {
    const { actualServices } = this.props;

    const children = [];
    value_list = actualServices;

    actualServices.map(service => {
      children.push(service.barcode + " " + service.service_name);
    });
    const onChange = e => {
      if (e.length >= 5) {
        this.props.loadActualServices(null, null, e);
      }
    };

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      }
    };

    return (
      <div>
        <Spin size="large" spinning={this.props.loading}>
        <PageTitle name="service_package_service_packages" component={null} />
          <Row>
            <Col span={10} offset={8} style={{ marginBottom: 26 }}>
              <AutoComplete
                style={{ width: `100%` }}
                dataSource={children}
                onSelect={this.onSelect}
                onChange={onChange}
                placeholder="Search actual service barcode or name"
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Col>
          </Row>

          <Formik
            initialValues={masterdata}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              actual_service_id: yup.number().required(),
              description: yup
                .string()
                .required("Description is a required field"),
              quantity: yup
                .number()
                .typeError("Quantity must be a number")
                .positive("Please provide positive number")
                .integer("Please provide integer")
                .required("Quantity is a required field")
            })}
            onSubmit={values => this.createServicePackageDetail(values)}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form onFinish={handleSubmit} autoComplete="off">
                <Input
                  name="actual_service_id"
                  type="hidden"
                  value={values.actual_service_id}
                />
                <FormItem
                  {...formItemLayout}
                  label="Barcode"
                  validateStatus={errors.barcode && "error"}
                  help={touched.barcode && errors.barcode}
                >
                  <Input name="barcoed" value={values.barcode} disabled />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label="actual service"
                  validateStatus={errors.name && "error"}
                  help={touched.name && errors.name}
                >
                  <Input name="name" value={values.name} disabled />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Description"
                  validateStatus={
                    touched.description && errors.description && "error"
                  }
                  help={touched.description && errors.description}
                >
                  <Input
                    placeholder="description"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label="quantity"
                  validateStatus={
                    touched.quantity && errors.quantity && "error"
                  }
                  help={touched.quantity && errors.quantity}
                >
                  <Input
                    placeholder="0"
                    name="quantity"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.quantity}
                  />
                </FormItem>
                <FormItem>
                  <Row gutter={8}>
                    <Col span={3} offset={12}>
                      <Button type="defualt" block>
                        <Link
                          to={`/service-package/packages/detail/${
                            this.props.match.params.service_package_id
                          }`}
                        >
                          Cancel
                        </Link>
                      </Button>
                    </Col>
                    <Col span={3}>
                      <Button type="primary" htmlType="submit" block>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Form>
            )}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ actualServices, servicePackages }) => {
  return {
    actualServices: actualServices.data,
    alertMessage: servicePackages.alertMessage,
    errorMessage: servicePackages.errorMessage,
    loading: servicePackages.loading
  };
};

const mapDispatchToProps = {
  createServicePackageDetail: actions.createServicePackageDetail.request,
  loadActualServices: loadActualServices.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPackageDetail);
