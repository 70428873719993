import React, { Fragment } from "react";
import { ActualServices, ServiceTypes } from "./components";

export default () => {
  return (
    <Fragment>
      <ActualServices />
      <ServiceTypes />
    </Fragment>
  );
};