import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { loadEmployeeTypes } from "modules/employee/components/employee_type/actions";
import EmployeeForm from "./EmployeeForm";
import { checkPermission } from "lib/helper";

class EditEmployee extends Component {
  state = {
    can_edit: checkPermission("employee_employees", "can_edit", false)
  };
  componentDidMount() {
    this.props.loadEmployee(this.props.match.params.employee_id);
    this.props.loadEmployeeTypes();
  }

  editEmployee = async (
    employee,
    is_booking,
    is_assistant,
    is_sales,
    is_upload,
    checkedList
  ) => {
    this.props.editEmployee(
      this.props.match.params.employee_id,
      employee,
      is_booking,
      is_assistant,
      is_sales,
      is_upload,
      checkedList
    );
  };

  render() {
    const {
      employee,
      employeeTypes,
      alertMessage,
      errorMessage,
      branches,
      branch_employees,
      loading
    } = this.props;
    // if (employee) {
    //   employee.is_booking = employee.is_booking === 0 ? false : true;
    //   employee.is_assistant = employee.is_assistant === 0 ? false : true;
    //   employee.is_sales = employee.is_sales === 0 ? false : true;
    // }

    const data = [];
    branches.map(branch => {
      const obj = {};
      obj.name = branch.branch_id;
      const checkBranch = branch_employees.filter(b => {
        if (b.branch_id === branch.branch_id) return b;
      });
      if (checkBranch.length > 0) obj.value = true;
      else obj.value = false;
      data.push(obj);
    });

    return (
      <EmployeeForm
        onSubmit={this.editEmployee}
        employee={employee}
        types={employeeTypes}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        branches={branches}
        checkedList={data}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ employees, employeeTypes, branches }) => {
  return {
    employee: employees.employee,
    employeeTypes: employeeTypes.data,
    alertMessage: employees.alertMessage,
    errorMessage: employees.errorMessage,
    branches: branches.data,
    branch_employees: employees.branch_employees,
    loading: employees.loading
  };
};

const mapDispatchToProps = {
  loadEmployee: actions.loadEmployee.request,
  editEmployee: actions.editEmployee.request,
  loadEmployeeTypes: loadEmployeeTypes.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmployee);
