import React, { Component } from "react";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";

import { Menu, Row, Col, Layout } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { loadBranches, setBranch } from "lib/select-option";
import { logout } from "modules/signin/components/auth/actions";

const SubMenu = Menu.SubMenu;
const { Header } = Layout;
const imgLogo = window.location.origin + "/11837239093706.png";

const Logo = (
  <div className="logo">
    <img src={imgLogo} alt=""/>
  </div>
);
class CustomeHeader extends Component {
  state = {
    branchId: null,
    branches: [],
  };

  componentDidMount() {
    this.props.loadBranches();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.branches !== "") {
      return { branches: nextProps.branches };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.branches !== this.props.branches) {
      if (this.props.branches.length > 0) {
        localStorage.setItem(
          "defaultBranchId",
          this.props.branches[0].branch_id
        );
      }
    }
  }

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };

  render() {
    const { collapsed, toggle, logout } = this.props;
    return (
      <Header className="site-layout-background">
        {window.innerWidth >= 500 && Logo}
        <Row justify="space-between">
          <Col sm={2} xs={4}>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                style: { fontSize: "18px" },
                onClick: toggle,
              }
            )}
          </Col>
          {window.innerWidth <= 500 && <Col xs={8}>{Logo}</Col>}
          <Col sm={4} xs={8}>
            <Menu
              onClick={this.handleClick}
              mode="horizontal"
              theme="light"
              style={{ lineHeight: "60px" }}
            >
              <SubMenu
                style={{ width: "100%", textAlign: "center" }}
                title={
                  <span className="submenu-title-wrapper">
                    <UserOutlined />
                    {localStorage.getItem("username")}
                  </span>
                }
              >
                <Menu.Item key="setting:1">
                  <Link
                    to={`/system/users/view/${localStorage.getItem("user_id")}`}
                  >
                    <span>Profile</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="setting:2">
                  <Link to="" onClick={logout}>
                    <span>Sign out</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
        </Row>
      </Header>
    );
  }
}

const mapStateToProps = ({ branches }) => ({
  branches: branches.data,
});

const mapDispatchToProps = {
  loadBranches: loadBranches.request,
  logout: logout.request,
  setBranch: setBranch.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomeHeader);
