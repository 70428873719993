import React from "react";
import { Switch, Route } from "react-router-dom";

import LoginForm from "./LoginForm";

export default () => (
  <Switch>
    <Route path="/signin" component={LoginForm} />
    <Route path="/" component={LoginForm} />
  </Switch>
);
