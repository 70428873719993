import React from "react";
import { Row, Col, Typography, Card, Divider } from "antd";
import { numberFormat, extractKeyFromArray } from "lib/helper";
const { Text } = Typography;

const getServiceHours = (serviceHour) => {
  let data = extractKeyFromArray(serviceHour, "amount");
  return data.reduce((x, y) => x + y, 0);
};

const formatTime = val => {
  let data = val.toString().split(".")
  let min = typeof data[1] === 'undefined' ? '0' : data[1] 

  let unithr = (parseInt(data[0]) > 1) ? 'hrs ' : 'hr '
  let unitmin = (parseInt(data[1]) > 1) ? 'mins ' : 'min '

  return data[0] +unithr+ min+ unitmin 
}

function Summary({ usePackage, summary, serviceHour }) {
  let hours = getServiceHours(serviceHour),
    perTrans =
      summary.total_bill > 0 ? summary.net_total / summary.total_bill : 0,
    avgHr =
      summary.total_bill > 0
        ? Math.round((hours / summary.total_bill) * 100) / 100
        : '0.0';
    let unit = (hours > 1) ? 'hrs' : 'hr'

  return (
    <Card>
      <Row gutter={[8, 8]}>
        <Col span={10}>Total service hours</Col>
        <Col span={14} className="align-right">
        <Text strong>{numberFormat(hours, 0)} {unit}</Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={10}>Transactions</Col>
        <Col span={14} className="align-right">
          <Text>{numberFormat(summary.total_bill, 0)}</Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={10}>Avg. service hours per transaction</Col>
        <Col span={14} className="align-right">
          <Text>{formatTime(avgHr)}</Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={10}>Total service</Col>
        <Col span={14} className="align-right">
          <Text>{numberFormat(summary.net_total, 2)}</Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={10}>Use packages</Col>
        <Col span={14} className="align-right">
          <Text>{numberFormat(usePackage.amount)}</Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={10}>Service revenue per transaction</Col>
        <Col span={14} className="align-right">
          <Text>{numberFormat(perTrans, 2)}</Text>
        </Col>
      </Row>
    </Card>
  );
}

Summary.defaultProps = {
  summary: {
    total_bill: 0,
    net_total: 0,
  },
  usePackage: {
    amount: 0,
  },
};
export default Summary;
