import React from "react";
import { Switch } from "react-router-dom";

import BranchHasTerminalList from "./BranchHasTerminalList";
import NewTerminal from "./NewTerminal";
import EditTerminal from "./EditTerminal";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute
      path="/branch/terminals/edit/:terminal_id"
      component={EditTerminal}
      perm="branch_terminals" action="can_edit"
    />
    <PrivateRoute path="/branch/terminals/new" component={NewTerminal} perm="branch_terminals" action="can_add"/>
    <PrivateRoute path="/branch/terminals" component={BranchHasTerminalList} perm="branch_terminals"/>
  </Switch>
);
