import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Col, Row, Radio, Checkbox, Input } from "antd";
import { loadBrands, loadCategories } from "lib/select-option";
import { DrawerFilter, AutoComplete, LabelFilter } from "lib/widget";
import {
  getSearch,
} from "lib/helper";

const options = [
  { label: "All", value: "all" },
  { label: "Yes", value: "1" },
  { label: "No", value: "0" }
];

const initialState = {
  product_id: "",
  product_brand_id: [],
  product_category_id: [],
  is_show: "all",
  keyword: "",
  hiddenBrand: "none",
  hiddenCate: "none"
};

const { Search } = Input;

class Filter extends Component {
  state = initialState;

  handleChange = (id, name) => e => {
    if (!e.target.checked) {
      this.setState(prevState => {
        return {
          [name]: prevState[name].filter(x => parseInt(x) !== parseInt(id))
        };
      });
      return;
    }

    this.setState({ [name]: [...this.state[name], id] });
  };

  handleIsshowChange = e => {
    this.setState({ is_show: e.target.value });
  };

  handleShow = name => () => {
    let value = this.state[name] === "none" ? "" : "none";
    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const { product_id, is_show, product_brand_id, product_category_id, keyword } = this.state;
    let selected = {
      brand: this.props.brands.filter(x => product_brand_id.includes(x.product_brand_id)),
      category: this.props.categories.filter(x =>
        product_category_id.includes(x.product_category_id)
      )
    };

    let params = {
      is_show,
      keyword,
      product_id,
      product_brand_id,
      product_category_id
    };

    this.props.handleSearch(params, selected);
  };

  handleClearFilter = () => {
    this.setState(initialState);
  };

  handleInputChange = e => {
    this.setState({ product_id: "", [e.target.name]: e.target.value });
  };

  handleAutoSelect = (id, data) => {
    this.setState({ product_id : id, keyword: data  })
  }

  searchAuto = (searchText) => {
    this.setState({ product_id: "", keyword: searchText  })
  }

  componentDidMount() {
    let search = getSearch()

    if (typeof search === 'object') {
      this.setState(search)
    }
    this.props.loadBrands();
    this.props.loadCategories();
  }
  render() {
    const {
      brands,
      categories,
      visible,
      handleDrawerClose,
      list
    } = this.props;
    return (
      <DrawerFilter
        onSearch={this.handleSearch}
        visible={visible}
        onClear={this.handleClearFilter}
        onClose={handleDrawerClose}
      >
        <div className="section-filter">
          <LabelFilter text="Barcode or name" />
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <AutoComplete
                keyName={['barcode', 'product_name']}
                keyId="product_id"
                searchCompnent={
                  <Search
                    value={this.state.keyword}
                    name="keyword"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                  />
                }
                type="product"
                value={this.state.keyword}
                onSelect={this.handleAutoSelect}
                onSearch={this.searchAuto}
                options={list}
              /> 
            </Col>
          </Row>
        </div>
        <div className="section-filter">
          <LabelFilter text="Display on iPad" />
          <Row>
            <Col span={24}>
              <Radio.Group
                options={options}
                onChange={this.handleIsshowChange}
                value={this.state.is_show}
              />
            </Col>
          </Row>
        </div>
        <div className="section-filter">
          <LabelFilter text="Brands" />
          <div className="checkbox-group-section">
            {typeof brands !== "undefined" &&
              brands.map((val, i) => {
                return (
                  <Row
                    gutter={[0, 8]}
                    key={i}
                    style={{ display: i > 3 ? this.state.hiddenBrand : "" }}
                  >
                    <Col span={24}>
                      <Checkbox
                        checked={this.state.product_brand_id.includes(
                          val.product_brand_id
                        )}
                        onClick={this.handleChange(
                          val.product_brand_id,
                          "product_brand_id"
                        )}
                      >
                        {val.product_brand}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
          </div>
          <Row style={{ marginTop: "10px" }}>
            <Button
              style={{ paddingLeft: "0px" }}
              type="link"
              onClick={this.handleShow("hiddenBrand")}
            >
              {this.state.hiddenBrand === "none" ? `Expand all` : `Hide`}
            </Button>
          </Row>
        </div>
        <div className="section-filter">
          <LabelFilter text="Categories" />
          <div className="checkbox-group-section">
            {typeof categories !== "undefined" &&
              categories.map((val, i) => {
                return (
                  <Row
                    key={i}
                    gutter={[0, 8]}
                    style={{ display: i > 3 ? this.state.hiddenCate : "" }}
                  >
                    <Col span={24}>
                      <Checkbox
                        checked={this.state.product_category_id.includes(
                          val.product_category_id
                        )}
                        onClick={this.handleChange(
                          val.product_category_id,
                          "product_category_id"
                        )}
                      >
                        {val.category_name}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
          </div>
          <div className="section-filter">
            <Row style={{ marginTop: "10px" }}>
              <Button
                style={{ paddingLeft: "0px" }}
                type="link"
                onClick={this.handleShow("hiddenCate")}
              >
                {this.state.hiddenCate === "none" ? `Expand all` : `Hide`}
              </Button>
            </Row>
          </div>
        </div>
      </DrawerFilter>
    );
  }
}

const mapStateToProps = ({ brands, categories }) => ({
  brands: brands.data,
  categories: categories.data
});

const mapDispatchToProps = {
  loadBrands: loadBrands.request,
  loadCategories: loadCategories.request
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
