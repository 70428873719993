import React from "react";
import { Switch } from "react-router-dom";

import EmployeeTypeList from "./EmployeeTypeList";
import NewEmployeeType from "./NewEmployeeType";
import EditEmployeeType from "./EditEmployeeType";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/employee/types/new"
      component={NewEmployeeType}
      perm="employee_types"
      action="can_add"
    />
    <PrivateRoute
      path="/employee/types/edit/:employee_type_id"
      component={EditEmployeeType}
      perm="employee_types"
      action="can_edit"
    />
    <PrivateRoute
      path="/employee/types"
      component={EmployeeTypeList}
      perm="employee_types"
    />
  </Switch>
);
