import {
  LOAD_SERVICE_PACKAGES,
  LOAD_SERVICE_PACKAGE,
  LOAD_SERVICE_PACKAGE_DETAILS,
  LOAD_SERVICE_PACKAGE_DETAIL,
  CREATE_SERVICE_PACKAGE,
  EDIT_SERVICE_PACKAGE,
  CREATE_SERVICE_PACKAGE_DETAIL,
  EDIT_SERVICE_PACKAGE_DETAIL,
  DELETE_SERVICE_PACKAGE,
  DELETE_SERVICE_PACKAGE_DETAIL,
  IMPORT_SERVICE_PACKAGE,
  DOWNLOAD_FILE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  services: [],
  package: {
    barcode: "",
    service_package_name: "",
    service_package_group_id: "",
    is_show: 1,
    price: "",
    price_before_vat: "",
    detail: []
  },
  detail: {
    actual_service_id: "",
    barcode: "",
    name: "",
    description: "",
    quantity: ""
  },
  branch_service_packages: [],
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SERVICE_PACKAGES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_SERVICE_PACKAGES.SUCCESS:
      return {
        ...state,
        data: action.payload.servicePackages.data,
        package: initialState.package,
        services: action.payload.servicePackages.services,
        totalSize: action.payload.servicePackages.total_row,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case LOAD_SERVICE_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_SERVICE_PACKAGE.SUCCESS:
      return {
        ...state,
        package: action.payload.servicePackage.data,
        alertMessage: "",
        errorMessage: "",

        branch_service_packages:
          action.payload.servicePackage.branch_service_packages,
        errorImport: "",
        loading: false
      };
    case LOAD_SERVICE_PACKAGE_DETAILS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_SERVICE_PACKAGE_DETAILS.SUCCESS:
      return {
        ...state,
        services: action.payload.servicePackageDetails.services,
        package: action.payload.servicePackageDetails.data,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case LOAD_SERVICE_PACKAGE_DETAIL.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_SERVICE_PACKAGE_DETAIL.SUCCESS:
      return {
        ...state,
        detail: action.payload.servicePackageDetail.data,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_SERVICE_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case CREATE_SERVICE_PACKAGE.SUCCESS:
      return {
        ...state,
        package: action.payload.servicePackage.data,
        alertMessage: action.payload.servicePackage.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_SERVICE_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_SERVICE_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_SERVICE_PACKAGE.SUCCESS:
      return {
        ...state,
        package: action.payload.servicePackage.data,
        alertMessage: action.payload.servicePackage.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_SERVICE_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_SERVICE_PACKAGE_DETAIL.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case CREATE_SERVICE_PACKAGE_DETAIL.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.serviceDetail.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_SERVICE_PACKAGE_DETAIL.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_SERVICE_PACKAGE_DETAIL.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_SERVICE_PACKAGE_DETAIL.SUCCESS:
      return {
        ...state,
        detail: action.payload.servicePackageDetail.data,
        alertMessage: action.payload.servicePackageDetail.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_SERVICE_PACKAGE_DETAIL.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case DELETE_SERVICE_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_SERVICE_PACKAGE.SUCCESS:
      const newServicePackages = state.data.filter(
        servicePackage =>
          servicePackage.service_package_id !==
          action.payload.servicePackage.data.service_package_id
      );
      return {
        ...state,
        data: newServicePackages,
        alertMessage: action.payload.servicePackage.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_SERVICE_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case DELETE_SERVICE_PACKAGE_DETAIL.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_SERVICE_PACKAGE_DETAIL.SUCCESS:
      const newServicePackageDetails = state.services.filter(
        serviceDetail =>
          serviceDetail.service_package_has_service_id !==
          action.payload.serviceDetail
      );
      return {
        ...state,
        services: newServicePackageDetails,
        alertMessage: "success",

        errorImport: "",
        loading: false
      };
    case DELETE_SERVICE_PACKAGE_DETAIL.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case IMPORT_SERVICE_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case IMPORT_SERVICE_PACKAGE.SUCCESS:
      return {
        ...state,
        data: action.payload.servicePackage.data,
        services: action.payload.servicePackage.services,
        totalSize: action.payload.servicePackage.total_row,
        alertMessage: action.payload.servicePackage.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case IMPORT_SERVICE_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: "",
        alertMessage: "",
        errorImport: action.payload.error,
        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.service_package_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
