import {
  LOAD_CUSTOMERS_SUMMARY,
  LOAD_CUSTOMERS_RETENTION,
  LOAD_CUSTOMERS_REVENUE,
  LOAD_CUSTOMERS_QUANTITY,
  LOAD_CUSTOMERS_SERVICE,
  LOAD_CUSTOMERS_GROWTH,
  LOAD_CUSTOMERS_TOPSPEND,
} from "./actions";

const initialState = {
  loading_summary: false,
  summary: [],

  loading_graph_revenue: false,
  revenue_graph: [],
  loading_quantity_graph: false,
  quantity_graph: [],
  loading_service_graph: false,
  service_graph: [],

  loading_retention: false,
  retention: [],

  loading_growth: false,
  graph_growth: [],
  graph_growth_gender: [],

  loading_topspend: false,
  topspend: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CUSTOMERS_SUMMARY.REQUEST:
      return {
        ...state,
        loading_summary: true,
      };
    case LOAD_CUSTOMERS_SUMMARY.SUCCESS:
      return {
        ...state,
        loading_summary: false,
        summary: action.payload.summary,
      };
    case LOAD_CUSTOMERS_SUMMARY.FAILURE:
      return {
        ...state,
        loading_summary: false,
      };

    case LOAD_CUSTOMERS_RETENTION.REQUEST:
      return {
        ...state,
        loading_retention: true,
      };
    case LOAD_CUSTOMERS_RETENTION.SUCCESS:
      return {
        ...state,
        loading_retention: false,
        retention: action.payload.retention.data,
      };
    case LOAD_CUSTOMERS_RETENTION.FAILURE:
      return {
        ...state,
        loading_retention: false,
      };

    /////////////// GRAPH ////////////////////

    case LOAD_CUSTOMERS_REVENUE.REQUEST:
      return {
        ...state,
        loading_datatable: true,
      };
    case LOAD_CUSTOMERS_REVENUE.SUCCESS:
      return {
        ...state,
        loading_datatable: false,
        revenue_graph: action.payload.revenue,
      };
    case LOAD_CUSTOMERS_REVENUE.FAILURE:
      return {
        ...state,
        loading_datatable: false,
      };

    case LOAD_CUSTOMERS_QUANTITY.REQUEST:
      return {
        ...state,
        loading_quantity_graph: true,
      };
    case LOAD_CUSTOMERS_QUANTITY.SUCCESS:
      return {
        ...state,
        loading_quantity_graph: false,
        quantity_graph: action.payload.quantity,
      };
    case LOAD_CUSTOMERS_QUANTITY.FAILURE:
      return {
        ...state,
        loading_quantity_graph: false,
      };

    case LOAD_CUSTOMERS_SERVICE.REQUEST:
      return {
        ...state,
        loading_service_graph: true,
      };
    case LOAD_CUSTOMERS_SERVICE.SUCCESS:
      return {
        ...state,
        loading_service_graph: false,
        service_graph: action.payload.service,
      };
    case LOAD_CUSTOMERS_SERVICE.FAILURE:
      return {
        ...state,
        loading_service_graph: false,
      };

    ///////////////// END GRAPH //////////////////////

    case LOAD_CUSTOMERS_GROWTH.REQUEST:
      return {
        ...state,
        loading_growth: true,
      };
    case LOAD_CUSTOMERS_GROWTH.SUCCESS:
      return {
        ...state,
        loading_growth: false,
        graph_growth: action.payload.growth.data,
        graph_gender: action.payload.growth_g.data,
      };
    case LOAD_CUSTOMERS_GROWTH.FAILURE:
      return {
        ...state,
        loading_growth: false,
      };

    case LOAD_CUSTOMERS_TOPSPEND.REQUEST:
      return {
        ...state,
        loading_topspend: true,
      };
    case LOAD_CUSTOMERS_TOPSPEND.SUCCESS:
      return {
        ...state,
        loading_topspend: false,
        topspend: action.payload.topspend.data,
      };
    case LOAD_CUSTOMERS_TOPSPEND.FAILURE:
      return {
        ...state,
        loading_topspend: false,
      };

    default:
      return state;
  }
};
