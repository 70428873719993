import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadEmployees(action) {
  try {
    const { pagination, limit, cond, sort } = action.payload;
    const res = yield fetcher.post(`employees/allEmployees`, {
      pagination,
      limit,
      cond,
      sort
    });
    yield put(actions.loadEmployees.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadEmployee(action) {
  try {
    const { employeeId } = action.payload;
    const res = yield fetcher.get(`employees/${employeeId}`);
    yield put(actions.loadEmployee.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadEmployee.failure(getErrorMsg(error)));
  }
}

function* createEmployee(action) {
  try {
    const {
      employee,
      checkedList
    } = action.payload;

    const formData = getFormData(
      employee,
      checkedList
    );
    const res = yield fetcher.post(`employees`, formData);
    yield put(actions.createEmployee.success(res.data));
    yield put(push("/employee/employees"));
  } catch (error) {
    handleError(error);
    yield put(actions.createEmployee.failure(getErrorMsg(error)));
    yield put(push("/employee/employees/new"));
  }
}

function* editEmployee(action) {
  const {
    employeeId,
    employee,
    checkedList
  } = action.payload;
  try {
    const formData = getFormData(
      employee,
      checkedList
    );
    formData.append("_method", "PATCH")
    const res = yield fetcher.post(`employees/${employeeId}`, formData, 'upload');
    yield put(actions.editEmployee.success(res.data));
    yield put(push("/employee/employees"));
  } catch (error) {
    handleError(error);
    yield put(actions.editEmployee.failure(getErrorMsg(error)));
    yield put(push(`/employee/employees/edit/${employeeId}`));
  }
}

function* deleteEmployee(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`employees/${id}`);
    yield put(actions.deleteEmployee.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteEmployee.failure(getErrorMsg(error)));
  }
}

function* importEmployee(action) {
  try {
    const { file, type, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    const res = yield fetcher.post(`import/details`, formData);
    yield put(actions.importEmployee.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importEmployee.failure(getErrorMsg(error)));
    yield put(push("/employee/employees"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/export_masterdata", { type: "employee" });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function getFormData(
  employee,
  checkedList
) {

  const formData = new FormData();
  formData.append("employee_code", employee.employee_code);
  formData.append("name", employee.name);
  formData.append("image", employee.image);
  formData.append("phone", employee.phone);
  formData.append("pin", employee.pin);
  formData.append("type_id", employee.type_id);
  formData.append("is_booking", employee.is_booking ? 1 : 0);
  formData.append("is_assistant", employee.is_assistant ? 1 : 0);
  formData.append("is_sales", employee.is_sales ? 1 : 0);
  formData.append("status", 1);
  formData.append("is_primary", 0);
  checkedList.map(check => {
    formData.append(`check_list[${check.name}]`, check.value);
  });
  return formData
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/employee`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchEmployees() {
  yield all([
    takeEvery(actions.LOAD_EMPLOYEES.REQUEST, loadEmployees),
    takeEvery(actions.LOAD_EMPLOYEE.REQUEST, loadEmployee),
    takeEvery(actions.CREATE_EMPLOYEE.REQUEST, createEmployee),
    takeEvery(actions.EDIT_EMPLOYEE.REQUEST, editEmployee),
    takeEvery(actions.DELETE_EMPLOYEE.REQUEST, deleteEmployee),
    takeEvery(actions.IMPORT_EMPLOYEE.REQUEST, importEmployee),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
