import React from "react";
import { Switch } from "react-router-dom";

import EmployeeList from "./EmployeeList";
import NewEmployee from "./NewEmployee";
import EditEmployee from "./EditEmployee";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/employee/employees/edit/:employee_id"
      component={EditEmployee}
      perm="employee_employees"
    />
    <PrivateRoute
      path="/employee/employees/new"
      component={NewEmployee}
      perm="employee_employees"
      action="can_add"
    />
    <PrivateRoute
      path="/employee/employees"
      component={EmployeeList}
      perm="employee_employees"
    />
  </Switch>
);
