import React from "react";
import { Switch } from "react-router-dom";
import NewBranch from "./NewBranch";
import BranchList from "./BranchList";
import EditBranch from "./EditBranch";
import  { PrivateRoute } from 'lib/widget'
export default () => (
  <Switch>
    <PrivateRoute path="/branch/branches/new" component={NewBranch} perm="branch_manage_branches" action="can_add"/>
    <PrivateRoute path="/branch/branches/edit/:branch_id" component={EditBranch} perm="branch_manage_branches" action="can_edit"/>
    <PrivateRoute path="/branch/branches" component={BranchList} perm="branch_manage_branches"/>
  </Switch>
);
