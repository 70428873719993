import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import UnitForm from "./UnitForm";

class NewUnit extends Component {
  createUnit = async unit => {
    this.props.createUnit(unit.uom_code, unit.uom_name);
  };

  render() {
    const { unit, alertMessage, errorMessage, loading } = this.props;
    return (
      <UnitForm
        onSubmit={this.createUnit}
        unit={unit}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ units }) => {
  return {
    unit: units.unit,
    alertMessage: units.alertMessage,
    errorMessage: units.errorMessage,
    loading: units.loading
  };
};

const mapDispatchToProps = {
  createUnit: actions.createUnit.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUnit);
