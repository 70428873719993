import { all, put, takeEvery } from "redux-saga/effects";
import { fetchGo, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadPayment(action) {
  try {
    const { type, duration, branch_id } = action.payload;
    const res = yield fetchGo.post(`transaction_payment`, { type, duration, branch_id })
    yield put(actions.loadPayment.success(res.data));
  } catch (error) {
    handleError(error);
    getErrorMsg(error)
    yield put(actions.loadPayment.failure(error));
  }
}

function* loadAppointment(action) {
  try {
    const { type, duration, branch_id } = action.payload;
    const res = yield fetchGo.post(`appointment_graph`, { type, duration, branch_id })
    yield put(actions.loadAppointment.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadRevenueSummaries(action) {
  try {
    const { type, duration, branch_id, query_type, category_id } = action.payload;
    const params = { type, duration, branch_id, query_type, category_id }
    const res = yield fetchGo.post(`revenue`, params)
    yield put(actions.loadRevenueSummaries.success(res.data));
    
  } catch (error) {
    handleError(error);
  }
}

function* loadGraphRevenue(action) {
  try {
    const { type, duration, branch_id, query_type, category_id } = action.payload;
    const params = { type, duration, branch_id, query_type, category_id }
    const res = yield fetchGo.post(`revenue_graph`, params)
    yield put(actions.loadGraphRevenue.success(res.data, null));
    
  } catch (error) {
    handleError(error);
  }
}

export default function* watchCalendar() {
  yield all([
    takeEvery(actions.LOAD_CALENDAR_REVENUE_SUMMARIES.REQUEST, loadRevenueSummaries),
    takeEvery(actions.LOAD_CALENDAR_REVENUE_GRAPH.REQUEST, loadGraphRevenue),
    takeEvery(actions.LOAD_CALENDAR_APPOINTMENT.REQUEST, loadAppointment),
    takeEvery(actions.LOAD_CALENDAR_PAYMENT.REQUEST, loadPayment),
  ]);
}
