import { createTypes, createAction } from "lib/action";

const LOAD_STYLIST_DATATABLE = createTypes("stylist_analytics", "list");
const LOAD_STYLIST_WIDGET = createTypes("stylist_analytics", "widget");

const LOAD_STYLIST_DETAIL_DATATABLE = createTypes(
  "stylist_analytics_detail",
  "list"
);
const LOAD_STYLIST_DETAIL_WIDGET = createTypes(
  "stylist_analytics_detail",
  "widget"
);

const loadStylistDataTable = {
  request: (type, duration, branch_id, category_id) => {
    return createAction(LOAD_STYLIST_DATATABLE.REQUEST, {
      type,
      duration,
      branch_id,
      category_id,
    })
  },
  success: (revenue_d) => {
    return createAction(LOAD_STYLIST_DATATABLE.SUCCESS, {
      revenue_d,
    });
  },
  failure: error => createAction(LOAD_STYLIST_DATATABLE.FAILURE, { error }),
};

const loadStylistWidgetData = {
  request: (type, duration, branch_id, category_id) =>
    createAction(LOAD_STYLIST_WIDGET.REQUEST, {
      type,
      duration,
      branch_id,
      category_id,
    }),
  success: (revenue_w) =>
    createAction(LOAD_STYLIST_WIDGET.SUCCESS, { revenue_w }),
  failure: error => createAction(LOAD_STYLIST_WIDGET.FAILURE, { error }),
};

const loadStylistDataTableDetail = {
  request: (type, duration, branch_id, sale_id, query_type) =>
    createAction(LOAD_STYLIST_DETAIL_DATATABLE.REQUEST, {
      type,
      duration,
      branch_id,
      sale_id,
      query_type,
    }),
  success: (tb_detail) => {
    return createAction(LOAD_STYLIST_DETAIL_DATATABLE.SUCCESS, { tb_detail });
  },
  failure: error => createAction(LOAD_STYLIST_DETAIL_DATATABLE.FAILURE, { error }),
};

const loadStylistWidgetDetailData = {
  request: (type, duration, branch_id, sale_id, query_type) =>
    createAction(LOAD_STYLIST_DETAIL_WIDGET.REQUEST, {
      type,
      duration,
      branch_id,
      sale_id,
      query_type,
    }),
  success: (widget_detail) =>
    createAction(LOAD_STYLIST_DETAIL_WIDGET.SUCCESS, { widget_detail }),
  failure: error => createAction(LOAD_STYLIST_DETAIL_WIDGET.FAILURE, { error }),
};

export {
  loadStylistDataTable,
  loadStylistWidgetData,
  loadStylistDataTableDetail,
  loadStylistWidgetDetailData,
  LOAD_STYLIST_DETAIL_DATATABLE,
  LOAD_STYLIST_DETAIL_WIDGET,
  LOAD_STYLIST_WIDGET,
  LOAD_STYLIST_DATATABLE,
};
