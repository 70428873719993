import React, { Component, Fragment } from 'react'
import { Form, Input, Divider, Checkbox, Select, message } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { PageTitle, FormBtn, RequriedText } from "lib/widget";
import CustomerSelect from "./CustomerSelect"
import TransactionTable from "./TransactionTable"
import ServicePackageTable from "./ServicePackageTable"

import { TransferCustomer } from "lib/fetch"


const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3, offset: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  },
  labelAlign: "left",
  style: { marginBottom: "10px" }
};


const formItemLayout2 = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  },
  labelAlign: "left",
  style: { marginBottom: "10px" }
};

const formItemLayout3 = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6, offset: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  },
  labelAlign: "left",
  style: { marginBottom: "10px" }
};

export default class form extends Component {
  state = {
    phoneNo: []
  }

  getCustomerPhoneNo = (fromId, toId) => {
    this.setState({ phoneNo: [] })
    let custId = []
    if (fromId !== "") {
      custId.push(fromId)
    }

    if (toId !== "") {
      custId.push(toId)
    }

    if (custId.length === 0) {
      return
    }

    TransferCustomer.getData({ customer_id: custId }).then(res => {
      const listKey = ["tel1", "tel2", "tel3", "tel4", "tel5"];
      let list = []
      res.data.data.map(value => {
        let filer = listKey
          .map(name => {
            return { value: value[name], label: value[name] }
          }).filter(x => x.value !== "" && x.value !== null)

        list.push(...filer)
      })

      list = [...new Set(list.map(item => item.value))].map(x => {
        return { value: x, label: x }
      })

      console.log("first", list)
      this.setState({
        phoneNo: list
      })
    })
  }

  render() {
    return (
      <Fragment>
        <PageTitle name="system_user_transfer_customer_data" component={null} />
        <Formik
          initialValues={{
            from_customer_id: "",
            from_customer_tel: "",
            from_customer_name: "",
            to_customer_id: "",
            to_customer_tel: "",
            to_customer_name: "",
            is_transfer_all: true,
            transfer_transactions: [],
            transfer_servicepackages: [],
            remain_balance: 0.0,
            cash_transfer_amt: 0.0,
            add_to_secondary: true,
            delete_after_transfer: true,
            tel1: "",
            tel2: "",
            tel3: "",
            tel4: "",
            tel5: ""
          }}
          enableReinitialize={true}
          validate={values => {
            let errors = {};
            if (!values.is_transfer_all) {
              if (values.transfer_transactions.length === 0 &&
                values.transfer_servicepackages.length === 0 &&
                values.cash_transfer_amt === 0) {
                errors.is_transfer_all = "Please select transaction or service packages or transfer amount";
              }
            }

            if (values.from_customer_id === values.to_customer_id) {
              errors.from_customer_id = "From Customer and to Customer should be different "
              errors.to_customer_id = "To Customer and from Customer should be different"
            }

            return errors;
          }}
          validationSchema={yup.object().shape({
            from_customer_id: yup
              .string()
              .required("From Customer is a required field"),
            to_customer_id: yup
              .string()
              .required("To Customer is a required field"),
            remain_balance: yup.number(),
            cash_transfer_amt: yup
              .number().min(0).max(yup.ref("remain_balance"), "Cash transfer must less than Remaining Balance"),
          })}
          onSubmit={values => {
            if (values.tel1 === "") {
              message.error("tel1 is required")
              return
            }
            this.props.onSubmit(values)
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onFinish={handleSubmit} autoComplete="off">
              <FormItem
                {...formItemLayout}
                label={<RequriedText label="From Customer" />}
                validateStatus={
                  touched.from_customer_id &&
                  errors.from_customer_id &&
                  "error"
                }
                help={
                  touched.from_customer_id &&
                  errors.from_customer_id
                }
              >
                <CustomerSelect
                  name="from_customer_id"
                  onSelect={(custId, custTel, custName, cash) => {
                    setFieldValue("from_customer_id", custId.toString())
                    setFieldValue("from_customer_tel", custTel)
                    setFieldValue("from_customer_name", custName)
                    setFieldValue("remain_balance", cash)
                    setFieldValue("tel1", "")
                    setFieldValue("tel2", "")
                    setFieldValue("tel3", "")
                    setFieldValue("tel4", "")
                    setFieldValue("tel5", "")

                    this.getCustomerPhoneNo(custId, values.to_customer_id)
                  }}
                />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="Customer Name">
                <div style={{ width: "50%", display: "inline-flex" }}>{values.from_customer_name}</div>
                <div style={{ width: "50%", display: "inline-flex" }}><span>Tel:&nbsp;&nbsp;</span> {values.from_customer_tel}</div>
              </FormItem>
              <Divider />
              <FormItem
                {...formItemLayout}
                label={<RequriedText label="To Customer" />}
                validateStatus={
                  touched.to_customer_id &&
                  errors.to_customer_id &&
                  "error"
                }
                help={
                  touched.to_customer_id &&
                  errors.to_customer_id
                }
              >
                <CustomerSelect
                  name="to_customer_id"
                  onSelect={(custId, custTel, custName) => {
                    setFieldValue("to_customer_id", custId.toString())
                    setFieldValue("to_customer_tel", custTel)
                    setFieldValue("to_customer_name", custName)
                    setFieldValue("tel1", "")
                    setFieldValue("tel2", "")
                    setFieldValue("tel3", "")
                    setFieldValue("tel4", "")
                    setFieldValue("tel5", "")
                    this.getCustomerPhoneNo(values.from_customer_id, custId)
                  }}
                />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="Customer Name">
                <div style={{ width: "50%", display: "inline-flex" }}>{values.to_customer_name}</div>
                <div style={{ width: "50%", display: "inline-flex" }}><span>Tel:&nbsp;&nbsp;</span> {values.to_customer_tel}</div>
              </FormItem>
              <Divider />
              <FormItem
                {...formItemLayout}
                validateStatus={
                  touched.is_transfer_all &&
                  errors.is_transfer_all &&
                  "error"
                }
                help={
                  touched.is_transfer_all &&
                  errors.is_transfer_all
                }
                label="Transfer All Data">
                <Checkbox
                  name="is_transfer_all"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue("transfer_transactions", [])
                      setFieldValue("transfer_servicepackages", [])
                      setFieldValue("cash_transfer_amt", 0)
                      setFieldValue("add_to_secondary", true)
                      setFieldValue("delete_after_transfer", true)
                    } else {
                      setFieldValue("add_to_secondary", false)
                      setFieldValue("delete_after_transfer", false)
                    }

                    setFieldValue("is_transfer_all", e.target.checked)
                  }}
                  checked={values.is_transfer_all}
                />
              </FormItem>
              <Divider />
              <div style={{ display: values.is_transfer_all ? "none" : "" }}>
                <FormItem
                  {...formItemLayout}
                  label="Transactions"
                  colon={false}>
                </FormItem>
                <FormItem
                  {...formItemLayout2}
                  label=" "
                  colon={false}>
                  <TransactionTable
                    data={values.transfer_transactions}
                    customerId={values.from_customer_id}
                    isShowList={!values.is_transfer_all}
                    onChange={(values) => {
                      setFieldValue("transfer_transactions", values)
                    }}
                  />
                </FormItem>
                <Divider />
                <FormItem
                  {...formItemLayout}
                  label="Service Packages"
                  colon={false}>
                </FormItem>
                <FormItem
                  {...formItemLayout2}
                  label=" "
                  colon={false}>
                  <ServicePackageTable
                    data={values.transfer_servicepackages}
                    customerId={values.from_customer_id}
                    isShowList={!values.is_transfer_all}
                    onChange={(values) => {
                      setFieldValue("transfer_servicepackages", values)
                    }} />
                </FormItem>
                <Divider />
                <FormItem
                  {...formItemLayout}
                  label="Cash Packages"
                  colon={false}>
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label="Remaining Balance"
                >
                  {values.remain_balance}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  validateStatus={
                    touched.cash_transfer_amt &&
                    errors.cash_transfer_amt &&
                    "error"
                  }
                  help={
                    touched.cash_transfer_amt &&
                    errors.cash_transfer_amt
                  }
                  label="Transfer"
                >
                  <Input
                    name="cash_transfer_amt"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cash_transfer_amt}
                    style={{ width: 150 }}
                  />
                </FormItem>
              </div>

              {["tel1", "tel2", "tel3", "tel4", "tel5"].map(name => {
                return <FormItem
                  {...formItemLayout}
                  key={name}
                  label={name}>
                  <Select
                    name={name}
                    value={values[name]}
                    onChange={(value) => {
                      setFieldValue([name], value)
                    }}
                    style={{ width: 150 }}
                    allowClear
                    options={this.state.phoneNo}
                  />
                </FormItem>
              })}

              <FormItem
                {...formItemLayout3}
                label={<RequriedText label="Delete customer after transfer" />}>
                <Checkbox
                  name="delete_after_transfer"
                  onChange={(e) => {
                    setFieldValue("delete_after_transfer", e.target.checked)
                  }}
                  checked={values.delete_after_transfer}
                >
                  Delete
                </Checkbox>
              </FormItem>

              <FormBtn
                url="/system/customer-transfer"
                canEdit={true}
              />
            </Form>
          )}
        />
      </Fragment>
    )
  }
}
