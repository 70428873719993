import { all, put, takeEvery } from "redux-saga/effects";
import { fetchGo, handleError } from "lib/fetch";
import * as actions from "./actions";
import { getComparedFilter } from "lib/go-api-helper";

function* loadDataTable(action) {
    try {
        const { type, duration, branch_id, query_type } = action.payload;
        const params = { type, duration, branch_id, query_type }
        const res = yield fetchGo.post(`sales_analytics`, params);
        yield put(actions.loadDataTable.success(res.data));
    } catch (error) {
      handleError(error);
    }
}

function* loadGraphData(action) {
  try {

    const { type, duration, branch_id, query_type } = action.payload;
    const params = { type, duration, branch_id, query_type}
    const params2 = { type, branch_id, query_type }
    params2["duration"] = getComparedFilter(type, duration)

    const [revenue, compared] = yield all([
      fetchGo.post(`sales_analytics_graph`, params),
      fetchGo.post(`sales_analytics_graph`, params2)
    ])

    yield put(actions.loadGraphData.success(revenue.data, compared.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadWidgetData(action) {
    try {
      const { type, duration, branch_id, query_type } = action.payload;
      const params = { type, duration, branch_id, query_type }
      const res = yield fetchGo.post(`sales_analytics_summary`, params);
      yield put(actions.loadWidgetData.success(res.data));
    } catch (error) {
      handleError(error);
    }
}


function* loadDataTableDetail(action) {
    try {
        const { type, duration, branch_id, sale_id, query_type } = action.payload;
        const params = { type, duration, branch_id, query_type, sale_id }
        const res = yield fetchGo.post(`sales_analytics_detail`, params);
        yield put(actions.loadDataTableDetail.success(res.data));
    } catch (error) {
      handleError(error);
    }
}

function* loadGraphDetailData(action) {
  try {
    const { type, duration, branch_id, sale_id, query_type } = action.payload;
    const params = { type, duration, branch_id, query_type, sale_id }
    const params2 = { type, branch_id, query_type, sale_id }
    params2["duration"] = getComparedFilter(type, duration)

    const [revenue, compared] = yield all([
      fetchGo.post(`sales_analytics_graph`, params),
      fetchGo.post(`sales_analytics_graph`, params2)
    ])

    yield put(actions.loadGraphDetailData.success(revenue.data, compared.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadWidgetDetailData(action) {
    try {
      const { type, duration, branch_id, sale_id, query_type } = action.payload;
      const params = { type, duration, branch_id, query_type, sale_id }
      const res = yield fetchGo.post(`sales_analytics_detail_summary`, params);
      yield put(actions.loadWidgetDetailData.success(res.data));
    } catch (error) {
      handleError(error);
    }
}

export default function* watchSaleAnalytics() {
  yield all([
    takeEvery(actions.LOAD_SALE_DATATABLE.REQUEST, loadDataTable),
    takeEvery(actions.LOAD_SALE_GRAPH.REQUEST, loadGraphData),
    takeEvery(actions.LOAD_SALE_WIDGET.REQUEST, loadWidgetData),
    takeEvery(actions.LOAD_SALE_GRAPH_DETAIL.REQUEST, loadGraphDetailData),
    takeEvery(actions.LOAD_SALE_WIDGET_DETAIL.REQUEST, loadWidgetDetailData),
    takeEvery(actions.LOAD_SALE_DETAIL.REQUEST, loadDataTableDetail),
  ]);
}
