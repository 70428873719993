import React, { Component } from "react";
import { connect } from "react-redux";
import { message, Spin } from "antd";

import * as actions from "../../actions";
import Form from "./Form";
import { PageTitle, ReportBtn } from "lib/widget";
import * as moment from "moment";

const initialState = {
  branchId: [],
  fileType: "excel",
  startDate: null,
  endDate: null,
  pickStartDate: null,
  pickEndDate: null,
};

class PaymentReport extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.onKeyValueChange = this.onKeyValueChange.bind(this);
  }

  onKeyValueChange(name, value) {
    this.setState((state, props) => {
      return { ...state, [name]: value };
    });
  }

  onDateChange = (value) => {
    this.setState({ ...this.state, ...value });
  };

  clearFilter = () => {
    this.setState(initialState);
  };

  downloadPayment = () => {
    const {
      startDate,
      endDate,
      branchId,
      pickStartDate,
      pickEndDate,
      fileType
    } = this.state;
    if (!startDate || !endDate) return message.error("Please select date");
    if (branchId.length === 0) return message.error("Please select branch");

    let start = moment(pickStartDate + " 00:00:00")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    let end = moment(pickEndDate + " 23:59:59")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    this.props.downloadPayment(
      branchId,
      start,
      end,
      pickStartDate,
      pickEndDate,
      fileType
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errorMessage !== prevState.errorMessage) {
      return { errorMessage: nextProps.errorMessage };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      message.error(this.props.errorMessage);
    }
  }

  componentDidMount() {
    this.props.loadReportBranch();
  }

  render() {
    return (
      <div>
        <Spin size="large" spinning={this.props.loading}>
          <PageTitle name="report_payment" component={null} />
          <Form
            {...this.state}
            branches={this.props.branches}
            onDateChange={this.onDateChange}
            onKeyValueChange={this.onKeyValueChange}
          />
          <ReportBtn
            onDowload={this.downloadPayment}
            onClear={this.clearFilter}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ reports }) => ({
  branches: reports.branches,
  loading: reports.payment_loading,
  errorMessage: reports.errorMessage,
});

const mapDispatchToProps = {
  downloadPayment: actions.downloadPayment.request,
  loadReportBranch: actions.loadReportBranch.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReport);
