import { all, put, takeEvery } from "redux-saga/effects";
import { fetcher, logErrorMsg, getErrorMsg, handleError } from "lib/fetch";

import * as actions from "./actions";

function* downloadDaily(action) {
  try {
    const {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate,
      fileType
    } = action.payload;
    const res = yield fetcher.post("export/daily", {
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      pick_start_date: pickStartDate,
      pick_end_date: pickEndDate,
      file_type: fileType
    });
    yield put(actions.downloadDaily.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadDaily.failure(getErrorMsg(error)));
  }
}

function* downloadStockExchange(action) {
  try {
    const {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate,
      fileType
    } = action.payload;
    const res = yield fetcher.post("export/report/stock_exchange", {
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      pick_start_date: pickStartDate,
      pick_end_date: pickEndDate,
      file_type: fileType
    });
    yield put(actions.downloadStockExchange.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadStockExchange.failure(getErrorMsg(error)));
  }
}

function* downloadCustomerRetention(action) {
  try {
    const {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate
    } = action.payload;
    const res = yield fetcher.post("export/report/customer_retention", {
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      pick_start_date: pickStartDate,
      pick_end_date: pickEndDate
    });
    yield put(actions.downloadCustomerRetention.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadDaily.failure(getErrorMsg(error)));
  }
}

function* downloadEmployee(action) {
  try {
    const {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate
    } = action.payload;
    const res = yield fetcher.post("export/employee_report", {
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      pick_start_date: pickStartDate,
      pick_end_date: pickEndDate
    });
    yield put(actions.downloadEmployee.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadEmployee.failure(error.message));
  }
}

function* downloadCurrentStock(action) {
  try {
    const { branchId } = action.payload;
    const res = yield fetcher.post("export/report/current_stock", {
      branch_id: branchId,
      start_date: "",
      end_date: "",
      pick_start_date: "",
      pick_end_date: ""
    });
    yield put(actions.downloadCurrentStock.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadCurrentStock.failure(error.message));
  }
}

function* downloadErpTransaction(action) {
  try {
    const {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate
    } = action.payload;
    const res = yield fetcher.post("export/report/erp_transaction", {
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      pick_start_date: pickStartDate,
      pick_end_date: pickEndDate
    });
    yield put(actions.downloadErpTransaction.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadErpTransaction.failure(error.message));
  }
}

function* downloadErpCustomer(action) {
  try {
    const res = yield fetcher.post("export/customer_eprformet", {});
    yield put(actions.downloadErpCustomer.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadErpCustomer.failure(error.message));
  }
}

function* downloadGoodsReceived(action) {
  try {
    const {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate,
      fileType
    } = action.payload;
    const res = yield fetcher.post("export/report/goods_received", {
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      pick_start_date: pickStartDate,
      pick_end_date: pickEndDate,
      file_type: fileType
    });
    yield put(actions.downloadGoodsReceived.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadGoodsReceived.failure(error.message));
  }
}

function* downloadSalonUsed(action) {
  try {
    const {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate,
      fileType
    } = action.payload;
    const res = yield fetcher.post("export/report/salon_used", {
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      pick_start_date: pickStartDate,
      pick_end_date: pickEndDate,
      file_type: fileType
    });
    yield put(actions.downloadSalonUsed.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadSalonUsed.failure(error.message));
  }
}

function* downloadPhysicalCount(action) {
  try {
    const {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate
    } = action.payload;
    const res = yield fetcher.post("export/report/physical_count", {
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      pick_start_date: pickStartDate,
      pick_end_date: pickEndDate
    });
    yield put(actions.downloadPhysicalCount.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadPhysicalCount.failure(error.message));
  }
}

function* downloadTransferReport(action) {
  try {
    const {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate,
      fileType
    } = action.payload;
    const res = yield fetcher.post("export/report/transfer", {
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      pick_start_date: pickStartDate,
      pick_end_date: pickEndDate,
      file_type: fileType
    });

    yield put(actions.downloadTransferReport.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadTransferReport.failure(error.message));
  }
}

function* downloadStockBenchMark(action) {
  try {
    const { branchId, fileType } = action.payload;
    const res = yield fetcher.post("export/report/stock_benchmark", {
      branch_id: branchId,
      file_type: fileType,
      start_date: "",
      end_date: ""
    });
    yield put(actions.downloadStockBenchMark.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadStockBenchMark.failure(error.message));
  }
}

function* loadReportBranch() {
  try {
    const res = yield fetcher.post("branches_user", {
      pagination: null,
      limit: null,
      keyword: null
    });
    yield put(actions.loadReportBranch.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadReportBranch.failure(getErrorMsg(error)));
    logErrorMsg(error);
  }
}

function* loadReportAllBranch() {
  try {
    const res = yield fetcher.post("branches_list", {
      pagination: null,
      limit: null,
      keyword: null
    });
    yield put(actions.loadReportAllBranch.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadReportAllBranch.failure(getErrorMsg(error)));
    logErrorMsg(error);
  }
}

function* downloadPayment(action) {
  try {
    const {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate,
      fileType
    } = action.payload;
    const res = yield fetcher.post("export/report/payment_report", {
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      pick_start_date: pickStartDate,
      pick_end_date: pickEndDate,
      file_type: fileType
    });
    yield put(actions.downloadPayment.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    logErrorMsg(error);
    yield put(actions.downloadPayment.failure(error.message));
  }
}

export default function* watchReport() {
  yield all([
    takeEvery(actions.DOWNLOAD_DAILY.REQUEST, downloadDaily),
    takeEvery(actions.DOWNLOAD_EMPLOYEE_REPORT.REQUEST, downloadEmployee),
    takeEvery(actions.DOWNLOAD_CURRENT_STOCK.REQUEST, downloadCurrentStock),
    takeEvery(
      actions.DOWNLOAD_ERP_TRANSACTION_REPORT.REQUEST,
      downloadErpTransaction
    ),
    takeEvery(actions.DOWLOAD_ERP_CUSTOMER.REQUEST, downloadErpCustomer),
    takeEvery(actions.DOWLOAD_GOODS_RECEIVED.REQUEST, downloadGoodsReceived),
    takeEvery(actions.DOWLOAD_SALON_USED.REQUEST, downloadSalonUsed),
    takeEvery(actions.DOWLOAD_PHYSICAL_COUNT.REQUEST, downloadPhysicalCount),
    takeEvery(actions.DOWLOAD_TRANSFER_REPORT.REQUEST, downloadTransferReport),
    takeEvery(actions.LOAD_REPORT_BRANCH.REQUEST, loadReportBranch),
    takeEvery(actions.LOAD_REPORT_ALLBRANCH.REQUEST, loadReportAllBranch),
    takeEvery(
      actions.DOWLOAD_STOCK_BENCHMARK_REPORT.REQUEST,
      downloadStockBenchMark
    ),
    takeEvery(actions.DOWLOAD_PAYMENT_REPORT.REQUEST, downloadPayment),
    takeEvery(actions.DOWLOAD_CUSTOMER_RETENTION_REPORT.REQUEST, downloadCustomerRetention),
    takeEvery(actions.DOWLOAD_STOCKEXCHANGE_REPORT.REQUEST, downloadStockExchange),
  ]);
}
