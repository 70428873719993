import { createTypes, createAction } from "lib/action";

const LOGIN = createTypes("login", "show");
const LOGOUT = createTypes("logout", "show");

const login = {
  request: user => createAction(LOGIN.REQUEST, { user }),
  success: user => createAction(LOGIN.SUCCESS, { user }),
  failure: error => createAction(LOGIN.FAILURE, { error })
};

const logout = {
  request: () => createAction(LOGOUT.REQUEST),
  success: () => createAction(LOGOUT.SUCCESS),
  failure: () => createAction(LOGOUT.FAILURE)
};

export { LOGIN, login, LOGOUT, logout };
