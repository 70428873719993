import React from "react";
import { Row, Col, Form, Select, Button, Input, DatePicker } from "antd";

const FormItem = Form.Item;

const branch = [
"All",
"HQ",
"ZEN",
"ISETAN",
"PARAGON",
"EMQUARTIER",
"EMPORIUM",
"ICON SIAM",
"EMBASSY",
]

const cate = [
"OTHERS",
"AVEDA",
"MILBON",
"KERASTASE",
"LEBEL",
"MEGUSHIYLU",
"MOLTOBENE",
"NAIL",
"NIOXIN",
"PER ME",
"SEBASTIAN",
"SOLUTION",
"SP",
"SPA IN TOUCH",
"WELLA",
]
const Filter = () => {
    const picker = 'month'
  return (
    <Form>
    <Row gutter={[16, 16]} align="middle">
      <Col span={5}>
        <FormItem label="Branch">
          <Select mode="multiple" defaultValue={["All"]}>
              { branch.map((data, i) => (
                  <Select.Option key={i} value={data}>{data}</Select.Option>
              )) }
          </Select>
        </FormItem>
      </Col>
      <Col span={4}>
        <FormItem label="Category">
          <Select mode="multiple" defaultValue={["All"]}>
              { cate.map((data, i) => (
                  <Select.Option key={i} value={data}>{data}</Select.Option>
              )) }
          </Select>
        </FormItem>
      </Col>
     
      <Form.Item label="Duration" style={{ paddingLeft: '8px', paddingRight: '8px' }}>
        <Input.Group compact>
          <Form.Item
            name={['address', 'province']}
            noStyle
          >
            <Select defaultValue="date" style={{ width: '40%' }}>
              <Select.Option value="date">Date</Select.Option>
              <Select.Option value="week">Week</Select.Option>
              <Select.Option value="month">month</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={['address', 'street']}
            noStyle
          >
            <DatePicker style={{ width: '60%' }}  />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Col span={3}>
        <FormItem label="Type">
          <Select defaultValue="all">
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="salon">Salon</Select.Option>
            <Select.Option value="retail">Retail</Select.Option>
          </Select>
        </FormItem>
      </Col>
      <Col span={4}>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Button type="primary" style={{ marginRight: '3px' }}>
            Submit
          </Button>
          <Button htmlType="button">
            Reset
          </Button>
        </Form.Item>
      </Col>
    </Row>
    </Form>
  );
};

export default Filter;
