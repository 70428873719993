import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "lib/widget";
import EditBranchHasProduct from "./EditBranchHasProduct";
import BranchHasProductList from "./BranchHasProductList";

export default () => (
  <Switch>
    <PrivateRoute
      path="/branch/products/edit/:branch_id"
      component={EditBranchHasProduct}
      perm="branch_products"
      action="can_add"
    />
    <PrivateRoute
      path="/branch/products"
      component={BranchHasProductList}
      perm="branch_products"
    />
  </Switch>
);
