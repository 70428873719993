import React, { Fragment, Component } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Button,
  Input,
  DatePicker,
} from "antd";
import * as moment from "moment";
import { fetchGo } from "lib/fetch";
import { AutoComplete } from "lib/widget";

const FormItem = Form.Item;
const { Search } = Input;
const { RangePicker } = DatePicker;

const initialState = {
  picker: "month",
  date: moment().format("YYYY-MM"),
  duration: moment().format("YYYY-MM"),
  gender: "all",
  option_name: "",
  item_id: 0,
  category_id: 0,
  query_type: "",
  service_type: [],
  product_cate: [],
  cashpackage: [],
  service_group: [],
  duration : null,
  start_date: null,
  end_date: null
};

const filterType = [
  { value: "service", label: "Service Type" },
  { value: "product", label: "Product Category" },
  { value: "packages", label: "Buy Package" },
  { value: "usepackage", label: "Use Package" },
  { value: "cashpackage", label: "Cash Package" },
  { value: "voucher", label: "Voicher used" },
  { value: "pointused", label: "Point used" },
];

const gender = [
  { value: "all", label: "ALL" },
  { value: "MALE", label: "MALE" },
  { value: "FEMALE", label: "FEMALE" },
  { value: "N/A", label: "N/A" },
];

class Filter extends Component {
  state = {
    ...initialState
  };

  handleResetRelateOption = () => {
    this.setState({
      option_name: "",
      keyword: "",
      item_id: 0,
      category_id: 0,
      service_type: [],
      product_cate: [],
      cashpackage: [],
      service_group: [],
    }, this.fetchOption)
  }

  handleChange = (value) => {
    this.setState({ picker: value, date: moment() });
  };

  handleChangeGender = (value) => {
    this.setState({ gender: value });
  };

  handleChangeQuery = (value) => {
    this.setState({ query_type: value }, this.handleResetRelateOption);
  };

  fetchOption = () => {
    let url = "";

    switch (this.state.query_type) {
      case "service":
      case "usepackage":
        url = "service_type";
        break;
      case "packages":
        url = "service_group";
        break;
      case "product":
        url = "product_cate";
        break;
      case "cashpackage":
        url = "cashpackage";
        break;
      default:
        break;
    }
    fetchGo.postData(url).then((res) => {
      this.setState({ [url]: res.data.data, option_name: url });
    });
  };

  handleDateChange = (value) => {
    let data = value;

    switch (this.state.picker) {
      case "date":
        data = moment(value).format("YYYY-MM-DD");
        break;
      case "week":
        data = moment(value).format("YYYY-W");
        break;
      case "month":
        data = moment(value).format("YYYY-MM");
        break;
    }
    this.setState({ date: moment(value), duration: data });
  };

  handleClear = () => {
    this.setState(initialState);
  };

  handleSearch = () => {
   if(this.props.type === "normal") {
    const { picker, duration, gender } = this.state
    this.props.onSearch(picker, duration, gender)
   } else {
    const { start_date, end_date, query_type, category_id, item_id, gender } = this.state
    this.props.onSearch(start_date, end_date, query_type, category_id, item_id, gender)
   }
  };

  handleAutoSelect = (id, data) => {
    this.setState({ keyword: data, item_id: id });
  };

  handleCategory = value => {
    this.setState({ category_id : value, item_id : 0, keyword: "" })
  }

  searchAuto = (searchText) => {
    this.setState({ keyword: searchText, item_id : 0  })
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value, item_id : 0 });
  };

  handleChangeDuration = (date, dateString) => {
    this.setState({ duration: date, start_date:dateString[0], end_date:dateString[1] })
  }

  genNormalComponent = () => {
    return (
      <Fragment>
        <Col span={7}>
          <Form.Item
            label="Duration"
            style={{ paddingLeft: "8px", paddingRight: "8px" }}
          >
            <Input.Group compact>
              <Form.Item noStyle>
                <Select
                  value={this.state.picker}
                  onChange={this.handleChange}
                  style={{ width: "40%" }}
                >
                  <Select.Option key="week" value="week">
                    Week
                  </Select.Option>
                  <Select.Option key="month" value="month">
                    month
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item noStyle>
                <div
                  style={{
                    display: this.state.picker === "week" ? "" : "none",
                  }}
                >
                  <DatePicker
                    picker="week"
                    value={moment(this.state.date)}
                    onChange={this.handleDateChange}
                  />
                </div>
                <div
                  style={{
                    display: this.state.picker === "month" ? "" : "none",
                  }}
                >
                  <DatePicker
                    picker="month"
                    value={moment(this.state.date)}
                    onChange={this.handleDateChange}
                  />
                </div>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={4}>
          <FormItem label="Gender">
            <Select
              value={this.state.gender}
              onChange={this.handleChangeGender}
            >
              {gender.map((data, i) => (
                <Select.Option key={i} value={data.value}>
                  {data.label}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>
      </Fragment>
    );
  };

  genSpendComponent = () => {
    const cate = ["service", "packages", "usepackage", "product"];
    const ignored = ["service", "voucher", "pointused"];
    const { query_type, option_name, category_id } = this.state;
    return (
      <Fragment>
        <Form.Item
          label="Duration"
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
        >
          <RangePicker value={this.state.duration} onChange={this.handleChangeDuration}/>
        </Form.Item>

        <Col span={4}>
          <FormItem label="Type">
            <Select value={query_type} onChange={this.handleChangeQuery}>
              {filterType.map((data, i) => (
                <Select.Option key={i} value={data.value}>
                  {data.label}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>

        {cate.includes(query_type) && (
          <Col span={4}>
            <FormItem label="">
              <Select
              value={category_id}
              onChange={this.handleCategory} 
              placeholder={`Select ${query_type}`}>
                {option_name !== "" &&
                  this.state[option_name].map((data, i) => (
                    <Select.Option key={i} 
                    value={data.value}>
                      {data.label}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
        )}

        {!ignored.includes(query_type) &&
          query_type !== "" && (
            <Col span={4}>
              <FormItem label="">{this.getAutoComplete()}</FormItem>
            </Col>
          )}

        <Col span={4}>
          <FormItem label="Gender">
            <Select
              value={this.state.gender}
              onChange={this.handleChangeGender}
            >
              {gender.map((data, i) => (
                <Select.Option key={i} value={data.value}>
                  {data.label}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>
      </Fragment>
    );
  };

  getAutoComplete = () => {
    const { query_type } = this.state;
    if (query_type === "product") {
      return (
        <AutoComplete
          value={this.state.keyword}
          keyName={["barcode", "product_name"]}
          keyId="product_id"
          searchCompnent={<Search 
            style={{ width: "100%" }} 
            value={this.state.keyword}
            name="keyword"
            onChange={this.handleInputChange}
            />}
          type="product"
          placeholder="Search product"
          onSelect={this.handleAutoSelect}
          options={[]}
          onSearch={this.searchAuto}
          categoryId={this.state.category_id}
        />
      );
    }

    if (query_type === "packages") {
      return (
        <AutoComplete
          value={this.state.keyword}
          placeholder="Search service packages"
          keyName={["barcode", "service_package_name"]}
          keyId="service_package_id"
          searchCompnent={<Search 
            style={{ width: "100%" }} 
            value={this.state.keyword}
            name="keyword"
            onChange={this.handleInputChange}
            />}
          type="servicepackage"
          onSelect={this.handleAutoSelect}
          options={[]}
          onSearch={this.searchAuto}
          categoryId={this.state.category_id}
        />
      );
    }

    if (["service", "usepackage"].includes(query_type)) {
      return (
        <AutoComplete
          value={this.state.keyword}
          placeholder="Search package"
          keyName={["barcode", "service_name"]}
          keyId="actual_service_id"
          searchCompnent={<Search 
            style={{ width: "100%" }} 
            value={this.state.keyword}
            name="keyword"
            onChange={this.handleInputChange}
            />}
          type="actualservice"
          onSelect={this.handleAutoSelect}
          options={[]}
          onSearch={this.searchAuto}
          categoryId={this.state.category_id}
        />
      );
    }

    if (query_type === "cashpackage") {
      return (
        <AutoComplete
          value={this.state.keyword}
          placeholder="Search cashpackage"
          keyName={["barcode", "cash_package_name"]}
          keyId="cash_package_id"
          searchCompnent={<Search 
            style={{ width: "100%" }} 
            value={this.state.keyword}
            name="keyword"
            onChange={this.handleInputChange}
            />}
          type="cashpackage"
          onSelect={this.handleAutoSelect}
          options={[]}
          onSearch={this.searchAuto}
          categoryId={this.state.category_id}
        />
      );
    }
  };

  render() {
    return (
      <Row gutter={[16, 16]} align="middle">
        {this.props.type === "normal"
          ? this.genNormalComponent()
          : this.genSpendComponent()}
        <Col span={4}>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.handleSearch}
              style={{ marginRight: "3px" }}
            >
              Filter
            </Button>
            <Button htmlType="button" onClick={this.handleClear}>Reset</Button>
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default Filter;
