import { createTypes, createAction } from "lib/action";

const LOAD_SERVICE_DATATABLE = createTypes("service_analytics", "list");
const LOAD_SERVICE_GRAPH = createTypes("service_analytics", "graph");
const LOAD_SERVICE_WIDGET = createTypes("service_analytics", "widget");

const loadDataServiceTable = {
  request: (type, duration, branch_id, category_id) =>
    createAction(LOAD_SERVICE_DATATABLE.REQUEST, {
      type,
      duration,
      branch_id,
      category_id,
    }),
  success: (revenue_d) => {
    return createAction(LOAD_SERVICE_DATATABLE.SUCCESS, { revenue_d })
  },
  failure: () => createAction(LOAD_SERVICE_DATATABLE.FAILURE),
};

const loadGraphDataService = {
  request: (type, duration, branch_id, category_id) =>
    createAction(LOAD_SERVICE_GRAPH.REQUEST, {
      type,
      duration,
      branch_id,
      category_id,
    }),
  success: (revenue_g, compared_g) =>
    createAction(LOAD_SERVICE_GRAPH.SUCCESS, { revenue_g, compared_g }),
  failure: () => createAction(LOAD_SERVICE_GRAPH.FAILURE),
};

const loadWidgetDataService = {
  request: (type, duration, branch_id, category_id) =>
    createAction(LOAD_SERVICE_WIDGET.REQUEST, {
      type,
      duration,
      branch_id,
      category_id,
    }),
  success: (revenue_w) =>
    createAction(LOAD_SERVICE_WIDGET.SUCCESS, { revenue_w }),
  failure: () => createAction(LOAD_SERVICE_WIDGET.FAILURE),
};

export {
  LOAD_SERVICE_DATATABLE,
  loadDataServiceTable,
  LOAD_SERVICE_GRAPH,
  loadGraphDataService,
  LOAD_SERVICE_WIDGET,
  loadWidgetDataService,
};
