import React from "react";
import { Switch } from "react-router-dom";

import PackageGroupList from "./PackageGroupList";
import NewPackageGroup from "./NewPackageGroup";
import EditPackageGroup from "./EditPackageGroup";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute path="/service-package/groups/new" component={NewPackageGroup} perm="service_package_groups" action="can_add"/>
    <PrivateRoute
      path="/service-package/groups/edit/:service_package_group_id"
      component={EditPackageGroup}
      perm="service_package_groups"
    />
    <PrivateRoute path="/service-package/groups" component={PackageGroupList} perm="service_package_groups" />
  </Switch>
);
