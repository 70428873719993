import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import DistributorForm from "./DistributorForm";
import { checkPermission } from "lib/helper";

class EditDistributor extends Component {
  state = {
    can_edit: checkPermission("product_distributors", "can_edit", false)
  };
  componentDidMount() {
    this.props.loadDistributor(this.props.match.params.distributor_id);
  }

  editDistributor = async distributor => {
    this.props.editDistributor(
      this.props.match.params.distributor_id,
      distributor.distributor_code,
      distributor.distributor_name
    );
  };

  render() {
    const { distributor, alertMessage, errorMessage, loading } = this.props;

    return (
      <DistributorForm
        onSubmit={this.editDistributor}
        distributor={distributor}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ distributors }) => {
  return {
    distributor: distributors.distributor,
    alertMessage: distributors.alertMessage,
    errorMessage: distributors.errorMessage,
    loading: distributors.loading
  };
};

const mapDispatchToProps = {
  loadDistributor: actions.loadDistributor.request,
  editDistributor: actions.editDistributor.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDistributor);
