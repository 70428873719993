import { Button, Form, Input, Row, Col, message, Spin } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";

import { RequriedText } from 'lib/widget'
import * as actions from "../actions";

const FormItem = Form.Item;

class Profile extends Component {
    state = {
      errorMessage: '',
      alertMessage: ''
    }
  
  componentDidMount() {
    this.props.loadUser(this.props.match.params.user_id);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  editPassword = async user => {
    this.props.editPassword(this.props.match.params.user_id, user);
  };

  render() {
    const { user } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      }
    };

    return (
      <div>
        <Spin size="large" spinning={this.props.loading}>
          <h3>User</h3>
          <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              password: yup
                .string()
                .required("Password is a required field")
                .test("len", "Must be at least 6 characters", val =>
                  val ? val.toString().length >= 6 : false
                ),
              new_password: yup
                .string()
                .required("Password is a required field")
                .test("len", "Must be at least 6 characters", val =>
                  val ? val.toString().length >= 6 : false
                ),
              confirm_password: yup
                .string()
                .required("Confirm password is a required field")
                .test(
                  "passwords-match",
                  "Password does not match the Confirm password",
                  function(value) {
                    return this.parent.new_password === value;
                  }
                )
            })}
            onSubmit={values => this.editPassword(values)}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched
            }) => (
              <Form onFinish={handleSubmit} autoComplete="off">
                <FormItem {...formItemLayout} label="Email">
                  <Input value={values.email} disabled />
                </FormItem>

                <FormItem {...formItemLayout} label={<RequriedText label="Name" />}>
                  <Input value={values.name} disabled />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Old Password"
                  validateStatus={
                    touched.password && errors.password && "error"
                  }
                  help={touched.password && errors.password}
                >
                  <Input
                    placeholder="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    type="password"
                  />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="New Password"
                  validateStatus={
                    touched.new_password && errors.new_password && "error"
                  }
                  help={touched.new_password && errors.new_password}
                >
                  <Input
                    placeholder="password"
                    name="new_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    type="password"
                  />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Confirm password"
                  validateStatus={
                    touched.confirm_password &&
                    errors.confirm_password &&
                    "error"
                  }
                  help={touched.confirm_password && errors.confirm_password}
                >
                  <Input
                    placeholder="confirm password"
                    name="confirm_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    type="password"
                  />
                </FormItem>

                <FormItem>
                  <Row gutter={8}>
                    <Col span={3} offset={12}>
                      <Button type="defualt" block>
                        <Link to="/system/users">Cancel</Link>
                      </Button>
                    </Col>
                    <Col span={3}>
                      <Button type="primary" htmlType="submit" block>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Form>
            )}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ users }) => {
  return {
    user: users.user,
    alertMessage: users.alertMessage,
    errorMessage: users.errorMessage,
    loading: users.loading
  };
};

const mapDispatchToProps = {
  loadUser: actions.loadUser.request,
  editPassword: actions.editPassword.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
