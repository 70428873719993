import React, { Component } from "react";
import { connect } from "react-redux";

import { Col, Row, Radio, Checkbox, Input } from "antd";
import { loadServicePackageGroups } from "lib/select-option";
import { DrawerFilter, AutoComplete, LabelFilter } from "lib/widget";
import {
  getSearch,
} from "lib/helper";

const options = [
  { label: "All", value: "all" },
  { label: "Yes", value: "1" },
  { label: "No", value: "0" }
];

const initialState = {
  service_package_group_id: [],
  is_show: "all",
  hiddenGroup: "",
  keyword: ""
};

const { Search } = Input

class Filter extends Component {
  state = initialState;

  handleChange = (id, name) => e => {
    if (!e.target.checked) {
      this.setState(prevState => {
        return {
          [name]: prevState[name].filter(x => parseInt(x) !== parseInt(id))
        };
      });
      return;
    }

    this.setState({ [name]: [...this.state[name], id] });
  };

  handleIsshowChange = e => {
    this.setState({ is_show: e.target.value });
  };

  handleShow = name => () => {
    let value = this.state[name] === "none" ? "" : "none";
    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const { is_show, service_package_group_id, keyword } = this.state;
    let selected = {
      group: this.props.group.filter(x => service_package_group_id.includes(x.service_package_group_id)),
    };

    let params = {
      is_show,
      keyword,
      service_package_group_id,
    };

    this.props.handleSearch(params, selected);
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAutoSelect = (id, data) => {
    this.setState({ keyword: data  })
  }

  searchAuto = (searchText) => {
    this.setState({ keyword: searchText  })
  }

  handleClearFilter = () => {
    this.setState(initialState);
  };

  componentDidMount() {
    let search = getSearch()
    if (typeof search === 'object') {
      this.setState(search)
    }
    this.props.loadServicePackageGroups();
  }
  render() {
    const { group, visible, handleDrawerClose, list } = this.props;
    return (
      <DrawerFilter
        onSearch={this.handleSearch}
        visible={visible}
        onClear={this.handleClearFilter}
        onClose={handleDrawerClose}
      >
        <div className="section-filter">
          <LabelFilter text="Barcode or name" />
          <Row gutter={[0, 16]}>
            <Col span={22}>
            <AutoComplete
                keyName={['barcode', 'service_package_name']}
                keyId="service_package_id"
                searchCompnent={
                  <Search
                    value={this.state.keyword}
                    name="keyword"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                  />
                }
                type="servicepackage"
                value={this.state.keyword}
                onSelect={this.handleAutoSelect}
                onSearch={this.searchAuto}
                options={list}
              /> 
            </Col>
          </Row>
        </div>
         <div className="section-filter">
          <LabelFilter text="Display on iPad" />
          <Row>
            <Col span={24}>
              <Radio.Group
                options={options}
                onChange={this.handleIsshowChange}
                value={this.state.is_show}
              />
            </Col>
          </Row>
         </div>
         <div className="section-filter">
          <LabelFilter text="Service groups" />
          <div className="checkbox-service_package_group_id-section-long">
          {typeof group !== "undefined" &&
            group.map((val, i) => {
              return (
                <Row
                  key={i}
                  gutter={[0, 8]}
                  style={{ display: i > 3 ? this.state.hiddenGroup : "" }}
                >
                  <Col span={24}>
                    <Checkbox
                      checked={this.state.service_package_group_id.includes(val.service_package_group_id)}
                      onClick={this.handleChange(val.service_package_group_id, "service_package_group_id")}
                    >
                      {val.service_package_group}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
            </div>
         </div>
      </DrawerFilter>
    );
  }
}

const mapStateToProps = ({ servicePackageGroups }) => ({
  group: servicePackageGroups.data,
});

const mapDispatchToProps = {
  loadServicePackageGroups: loadServicePackageGroups.request
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
