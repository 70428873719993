import React, { Component } from "react";
import { connect } from "react-redux";
import { message, Spin } from "antd";

import * as actions from "../../actions";
import Form from "./Form";
import { PageTitle, ReportBtn } from "lib/widget";

const initalState = {
  branchId: [],
  fileType: "excel",
  startDate: null,
  endDate: null
};

class StockBenchMarkReport extends Component {
  constructor(props) {
    super(props);
    this.state = initalState;
    this.onKeyValueChange = this.onKeyValueChange.bind(this);
  }

  clearFilter = () => {
    this.setState(initalState);
  };
  
  onKeyValueChange(name, value) {
    this.setState((state, props) => {
      return { ...state, [name]: value };
    });
  }

  downloadStockBenchMark = () => {
    const { branchId, fileType } = this.state;
    if (branchId.length === 0) return message.error("Please select branch");
    this.props.downloadStockBenchMark(branchId, fileType);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errorMessage !== prevState.errorMessage) {
      return { errorMessage: nextProps.errorMessage };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      message.error(this.props.errorMessage);
    }
  }

  componentDidMount() {
    this.props.loadReportBranch();
  }

  render() {
    return (
      <div>
        <PageTitle name="report_stock_benchmark" component={null} />
        <Spin size="large" spinning={this.props.loading}>
          <Form
            {...this.state}
            branches={this.props.branches}
            onKeyValueChange={this.onKeyValueChange}
          />
          <ReportBtn
            onDowload={this.downloadStockBenchMark}
            onClear={this.clearFilter}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ reports }) => ({
  branches: reports.allbranches,
  loading: reports.stock_benchmark_loading,
  errorMessage: reports.errorMessage
});

const mapDispatchToProps = {
  downloadStockBenchMark: actions.downloadStockBenchMark.request,
  loadReportBranch: actions.loadReportAllBranch.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockBenchMarkReport);
