import React, { Component } from "react";
import { Formik } from "formik";
import { Form, Input, Select, message, Spin, Radio, DatePicker, Typography, Row, Col } from "antd";
import * as yup from "yup";
import { PageTitle, FormBtn, RequriedText, SectionDivider } from "lib/widget";
import * as moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  }
};

const formItemSmallLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  }
};

export default class CustomerForm extends Component {
  genders = ["MALE", "FEMALE", "N/A"];

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  componentDidMount() {
    this.nameInput.focus(); 
  }

  isDisabled = () => {
    return this.props.customer.customer_code !== "" && this.props.editCode;
  };

  render() {
    const { customer, onSubmit, nationalities, loading } = this.props;
    return (
      <div>
        <Spin size="large" spinning={loading}>
          <PageTitle name="customer_customers" component={null} />
          <Formik
            initialValues={customer}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              name: yup.string().required("Name is a required field"),
              gender: yup.string().required("Gender is a required field"),
              nationality_id: yup
                .string()
                .required("Nationality is a required field"),
              tel1: yup.string().required("Phone is a required field"),
              email: yup.string().email("Incorect email format")
            })}
            onSubmit={values => {
              onSubmit(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <Form onFinish={handleSubmit} autoComplete="off">
                <fieldset disabled={!this.props.canEdit}>
                  <FormItem {...formItemLayout} label="Customer code">
                    <Input
                      name="customer_code"
                      ref={(input) => { this.nameInput = input; }} 
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.customer_code}
                      disabled={this.isDisabled()}
                    />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Name" />}
                    validateStatus={touched.name && errors.name && "error"}
                    help={touched.name && errors.name}
                  >
                    <Input
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Gender" />}
                    validateStatus={touched.gender && errors.gender && "error"}
                    help={touched.gender && errors.gender}
                  >
                    <Radio.Group
                      onChange={e => {
                        setFieldValue("gender", e.target.value);
                      }}
                      value={values.gender}
                    >
                      <Radio value="FEMALE">FEMALE</Radio>
                      <Radio value="MALE">MALE</Radio>
                      <Radio value="N/A">N/A</Radio>
                    </Radio.Group>
                  </FormItem>

                  <FormItem
                    {...formItemSmallLayout}
                    label={<RequriedText label="Nationality" />}
                    validateStatus={
                      touched.nationality_id && errors.nationality_id && "error"
                    }
                    help={touched.nationality_id && errors.nationality_id}
                  >
                    <Select
                      placeholder="Select a nationality"
                      onChange={nationality => {
                        setFieldValue("nationality_id", nationality);
                      }}
                      value={
                        values.nationality_id !== ""
                          ? parseInt(values.nationality_id)
                          : values.nationality_id
                      }
                      disabled={!this.props.canEdit}
                    >
                      {nationalities.map(nationality => (
                        <Option
                          key={nationality.nationality_id}
                          value={nationality.nationality_id}
                        >
                          {nationality.nationality}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>


                  <FormItem {...formItemLayout} label="Birthday">
                    <DatePicker
                      style={{ width: '50%' }}
                      name="birthday"
                      onChange={(value, dateString) => {
                        setFieldValue("birthday", value)
                      }}
                      value={values.birthday !== null && values.birthday !== "" ? moment(values.birthday, 'YYYY-MM-DD') : null}
                    />
                  </FormItem>

                  <FormItem {...formItemLayout} label="Note">
                    <TextArea
                      name="notes"
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {values.notes}
                    </TextArea>
                  </FormItem>

                  <SectionDivider label="Contact Information" />

                  <FormItem
                    {...formItemSmallLayout}
                    label={<RequriedText label="Phone no." />}
                    validateStatus={touched.tel1 && errors.tel1 && "error"}
                    help={touched.tel1 && errors.tel1}
                  >
                    <Input
                      name="tel1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel1}
                    />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Email" />}
                    validateStatus={errors.email && "error"}
                    help={touched.email && errors.email}
                  >
                    <Input
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </FormItem>

                  <FormItem {...formItemLayout} label="Address">
                  <TextArea
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {values.address}
                    </TextArea>
                  </FormItem>

                  <Row style={{ marginBottom: '20px', marginTop: '50px' }}>
                    <Col sm={9} style={{ textAlign: 'end' }}>
                      <Text type="secondary">Backup Phone no. (Optional)</Text>
                    </Col>
                  </Row>

                  <FormItem {...formItemLayout} label="Phone 2">
                    <Input
                      name="tel2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel2}
                    />
                  </FormItem>
                  <FormItem {...formItemLayout} label="Phone 3">
                    <Input
                      name="tel3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel3}
                    />
                  </FormItem>
                  <FormItem {...formItemLayout} label="Phone 4">
                    <Input
                      name="tel4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel4}
                    />
                  </FormItem>
                  <FormItem {...formItemLayout} label="Phone 5">
                    <Input
                      name="tel5"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel5}
                    />
                  </FormItem>

                  <FormBtn
                    url="/customer/customers"
                    canEdit={this.props.canEdit}
                  />
                </fieldset>
              </Form>
            )}
          />
        </Spin>
      </div>
    );
  }
}
