import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadActualServiceGroups(action) {
  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post("servicegroups/servicegroups_list", {
      pagination,
      limit,
      keyword,
      sort
    });
    yield put(actions.loadActualServiceGroups.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadActualServiceGroup(action) {
  try {
    const { serviceGroupId } = action.payload;
    const res = yield fetcher.get(`servicegroups/${serviceGroupId}`);
    yield put(actions.loadActualServiceGroup.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* createActualServiceGroup(action) {
  try {
    const { code, name, clientId } = action.payload;
    const res = yield fetcher.post("servicegroups", {
      actual_service_group_code: code,
      actual_service_group: name,
      client_id: clientId
    });
    yield put(actions.createActualServiceGroup.success(res.data));
    yield put(push("/actual-service/groups"));
  } catch (error) {
    handleError(error);
    yield put(actions.createActualServiceGroup.failure(getErrorMsg(error)));
  }
}

function* editActualServiceGroup(action) {
  try {
    const { id, code, name, clientId } = action.payload;
    const res = yield fetcher.patch(`servicegroups/${id}`, {
      actual_service_group_code: code,
      actual_service_group: name,
      client_id: clientId
    });
    yield put(actions.editActualServiceGroup.success(res.data));
    yield put(push("/actual-service/groups"));
  } catch (error) {
    handleError(error);
    yield put(actions.editActualServiceGroup.failure(getErrorMsg(error)));
  }
}

function* deleteActualServiceGroup(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`servicegroups/${id}`);
    yield put(actions.deleteActualServiceGroup.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteActualServiceGroup.failure(getErrorMsg(error)));
  }
}

function* upload(action) {
  try {
    const { file, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", "actualServiceGroup");
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    const res = yield fetcher.post("import/details", formData);
    yield put(actions.upload.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.upload.failure(getErrorMsg(error)));
    yield put(push("/actual-service/groups"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/export_masterdata", { type: "actualServiceGroup" });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

export default function* watchActualServiceGroups() {
  yield all([
    takeEvery(
      actions.LOAD_ACTUAL_SERVICE_GROUPS.REQUEST,
      loadActualServiceGroups
    ),
    takeEvery(
      actions.LOAD_ACTUAL_SERVICE_GROUP.REQUEST,
      loadActualServiceGroup
    ),
    takeEvery(
      actions.CREATE_ACTUAL_SERVICE_GROUP.REQUEST,
      createActualServiceGroup
    ),
    takeEvery(
      actions.EDIT_ACTUAL_SERVICE_GROUP.REQUEST,
      editActualServiceGroup
    ),
    takeEvery(
      actions.DELETE_ACTUAL_SERVICE_GROUP.REQUEST,
      deleteActualServiceGroup
    ),
    takeEvery(actions.UPLOAD_FILE.REQUEST, upload),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download)
  ]);
}
