import React, { Fragment } from "react";
import { Col, Row, Typography, Divider } from "antd";
import { getPageTitle, getActionMenu, getBreadcrumbTitle } from 'lib/pagefn'
import Pluralize from 'pluralize';

const { Title } = Typography;

const PageTitle = ({ title, component, name }) => {
  let url = window.location.pathname
  
  let page = getBreadcrumbTitle(url.split("/"))
  let action = getActionMenu(url, name)
  let value = action === '' ? getPageTitle(name) : Pluralize.singular(getPageTitle(name))
  let pageTitle = `${action} ${value}`
  let rx = new RegExp(page.title, 'g')

  if (value.match(rx) === null) {
    
      pageTitle = `${action} ${value}`

      if (page.title === 'Report') {
        pageTitle = `${action} ${value} ${page.title}`
      }

      if (page.title === 'Branch') {
        pageTitle = `${action} ${page.title}  ${value}`
      }
  }

  if (url.match(/detail/g)) pageTitle = `${pageTitle} Detail`

  return (
    <Fragment>
      <Row>
        <Col span={12}>
          <Title level={4} className="page-title">
            { pageTitle.trim() }
          </Title>
        </Col>
        <Col xs={{ span: 8, offset: 4 }} sm={{ span: 8, offset: 4 }} md={{ span: 4, offset: 8 }} lg={{ span: 3, offset: 9 }}>
            { component }
        </Col>
      </Row>
      <Divider style={{ marginBottom: '20px', marginTop: '10px' }}/>
    </Fragment>
  );
};

export default PageTitle;
