import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadBranchEmployees(action) {
  try {
    const { branchId, pagination, limit, cond, sort } = action.payload;
    const res = yield fetcher.post("masterdata/employees", {
      branch_id: branchId,
      pagination,
      limit,
      cond,
      sort
    });
    yield put(actions.loadBranchEmployees.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* importBranchHasEmployee(action) {
  try {
    const { file, type, pagination, limit, keyword, branchId } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    formData.append("branch_id", branchId);

    const res = yield fetcher.post("import/branch_has", formData);
    yield put(actions.importBranchHasEmployee.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importBranchHasEmployee.failure(getErrorMsg(error)));
    yield put(push("/branch/employees"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/branch_has/employee", {
      branch_id: action.payload.branch_id
    });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function* updateOrder(action) {
  const { branch_id, data } = action.payload;
  try {
    yield fetcher.post(`branch_has/arrange/employee/${branch_id}`, data);
    window.location.reload();
  } catch (error) {
    handleError(error);
    yield put(push("/branch/employees"));
  }
}

function* editBranchHasEmployee(action) {
  try {
    const { branch_id, employees } = action.payload;
    const res = yield fetcher.post(
      `branch_has/create/employee/${branch_id}`,
      employees
    );
    yield put(actions.editBranchHasEmployee.success(res.data));
    yield put(push("/branch/employees"));
  } catch (error) {
    handleError(error);
    yield put(actions.editBranchHasEmployee.failure(getErrorMsg(error)));
    yield put(push("/branch/employees/new"));
  }
}

function* deleteBranchHasEmployee(action) {
  try {
    const { id, branch_id } = action.payload;
    const res = yield fetcher.delete(
      `branch_has/delete/employee/${branch_id}/${id}`
    );
    yield put(actions.deleteBranchHasEmployee.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteBranchHasEmployee.failure(getErrorMsg(error)));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/branch_has/employee`, {
      id: data
    });
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchBranchEmployees() {
  yield all([
    takeEvery(actions.LOAD_BRANCH_EMPLOYEES.REQUEST, loadBranchEmployees),
    takeEvery(
      actions.IMPORT_BRANCH_HAS_EMPLOYEE.REQUEST,
      importBranchHasEmployee
    ),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.UPDATE_ORDER_BRANCH_HAS_EMPLOYEE.REQUEST, updateOrder),
    takeEvery(actions.EDIT_BRANCH_HAS_EMPLOYEE.REQUEST, editBranchHasEmployee),
    takeEvery(
      actions.DELETE_BRANCH_HAS_EMPLOYEE.REQUEST,
      deleteBranchHasEmployee
    ),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
