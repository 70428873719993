import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

import { Row, Col, Card, Tabs, Typography } from "antd";
import * as moment from "moment";
import TopItem from "./TopItem";
import Filter from "./Filter"
const { TabPane } = Tabs;
const { Title } = Typography

const topList = [
  { value: "customer", label: "Customers" },
  { value: "service", label: "Services" },
  { value: "product", label: "Products" },
  { value: "stylish", label: "Stylist" },
  { value: "emp", label: "Sales" },
];

const tabList = [
  { value: "month", name: "Month" },  
  { value: "week", name: "Week" },
  { value: "date", name: "Day" },
];

export class TopItemLayout extends Component {
  state = {
    type: "month",
    duration: "2020-02", //moment().utc().format('YYYY-MM'),
    branch_id: [],
    query_type: "all",
    category_id: null,
    visible: false,
    change_monthly: true
  };

  percentCal = (last, week) => {
    if (last == 0) return 0;
    if (typeof week !== "number" || typeof last !== "number") return 0;
    return ((week - last) / last) * 100;
  };

  fetchData = () => {
    const { type, duration, branch_id, query_type, category_id } = this.state;
    this.props.loadTopItems(type, duration, branch_id, query_type, category_id);
  };

  handleFilter = (branchId) => {
    this.setState({ branch_id: branchId }, this.fetchData)
  }

  handleChangeTabs = (key) => {
    let { duration, branch_id, query_type, category_id } = this.state;
    switch (key) {
      case "date":
        duration = moment("2020-02-02").utc().format("YYYY-MM-DD");
        break;
      case "week":
        duration = moment("2020-02-02").utc().format("YYYY-W");
        break;
      case "month":
        duration = moment("2020-02-02").utc().format("YYYY-MM");
        break;
      default:
        break;
    }

    this.props.loadTopItems(key, duration, branch_id, query_type, category_id);
  };


  componentDidUpdate(prevProps) {
    if (prevProps.branchesId !== this.props.branchesId) {
      this.setState({ branch_id: this.props.branchesId }, this.fetchData);
    }
  }

  render() {
    const { top_items, loading } = this.props;

    return (
      <Fragment>
        <Row gutter={[36, 16]}>
          <Col span={24}>
            <Card>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
          <Col xs={24} md={5} lg={8}>
            <Title level={4} className="page-title">
              Top 10 by category
            </Title>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          <Col xs={8} lg={24}>
            <Filter
              {...this.state}
              branches={this.props.branches}
              onSearch={this.handleFilter}
            />
          </Col>
        </Row>
              <Tabs
                defaultActiveKey="month"
                tabPosition="left"
                onChange={this.handleChangeTabs}
              >
                {tabList.map((data, i) => {
                  return (
                    <TabPane tab={data.name} key={data.value}>
                      <Row gutter={[24, 16]}>
                        <div className="scrolling-wrapper-flexbox">
                          {topList.map((name, k) => (
                            <TopItem
                              key={k}
                              order={k}
                              loading={loading}
                              title={name.label}
                              type={data.name}
                              branches={this.props.branches}
                              data={top_items[name.value]}
                            />
                          ))}
                        </div>
                      </Row>
                    </TabPane>
                  );
                })}
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ topitem }) => ({
  top_items: {
    service: topitem.service,
    product: topitem.product,
    stylish: topitem.stylish,
    emp: topitem.emp,
    customer: topitem.customer,
  },
  loading: topitem.loading
});

const mapDispatchToProps = {
  loadTopItems: actions.loadTopItems.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopItemLayout);
