import { createTypes, createAction } from "lib/action";

const LOAD_BRANCH_CASH_PACKAGES = createTypes("branchCashPackages", "list");
const IMPORT_BRANCH_HAS_CASH_PACKAGE = createTypes(
  "branchCashPackages",
  "import"
);
const DOWNLOAD_FILE = createTypes("branchCashPackages", "export");
const EDIT_BRANCH_HAS_CASH_PACKAGE = createTypes("branchCashPackages", "edit");
const DELETE_BRANCH_HAS_CASH_PACKAGE = createTypes("branchCashPackages", "delete");
const DESTROY_MANY = createTypes("branchCashPackages", "destory");

const loadBranchCashPackages = {
  request: (branchId, pagination, limit, keyword, sort) =>
    createAction(LOAD_BRANCH_CASH_PACKAGES.REQUEST, {
      branchId,
      pagination,
      limit,
      keyword,
      sort
    }),
  success: branchCashPackages =>
    createAction(LOAD_BRANCH_CASH_PACKAGES.SUCCESS, {
      branchCashPackages
    }),
  failure: error => createAction(LOAD_BRANCH_CASH_PACKAGES.FAILURE, { error })
};

const importBranchHasCashPackage = {
  request: (file, type, pagination, limit, keyword, branchId) =>
    createAction(IMPORT_BRANCH_HAS_CASH_PACKAGE.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword,
      branchId
    }),
  success: branchCashPackage =>
    createAction(IMPORT_BRANCH_HAS_CASH_PACKAGE.SUCCESS, {
      branchCashPackage
    }),
  failure: error =>
    createAction(IMPORT_BRANCH_HAS_CASH_PACKAGE.FAILURE, { error })
};

const download = {
  request: branch_id => createAction(DOWNLOAD_FILE.REQUEST, { branch_id }),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const editBranchHasCashPackage = {
  request: (branch_id, cashpackage) => createAction(EDIT_BRANCH_HAS_CASH_PACKAGE.REQUEST, { branch_id, cashpackage }),
  success: (cashpackage) => createAction(EDIT_BRANCH_HAS_CASH_PACKAGE.SUCCESS, { cashpackage }),
  failure: error => createAction(EDIT_BRANCH_HAS_CASH_PACKAGE.FAILURE, { error })
}

const deleteBranchHasCashPackage = {
  request: (id, branch_id) => createAction(DELETE_BRANCH_HAS_CASH_PACKAGE.REQUEST, { id, branch_id }),
  success: cash => createAction(DELETE_BRANCH_HAS_CASH_PACKAGE.SUCCESS, { cash }),
  failure: error => createAction(DELETE_BRANCH_HAS_CASH_PACKAGE.FAILURE, { error })
}

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_BRANCH_CASH_PACKAGES,
  loadBranchCashPackages,
  IMPORT_BRANCH_HAS_CASH_PACKAGE,
  importBranchHasCashPackage,
  DOWNLOAD_FILE,
  download,
  EDIT_BRANCH_HAS_CASH_PACKAGE,
  editBranchHasCashPackage,
  DELETE_BRANCH_HAS_CASH_PACKAGE,
  deleteBranchHasCashPackage,
  DESTROY_MANY,
  destroyMany
};
