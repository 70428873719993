import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadCashPackages(action) {
  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post(`cashpackages/allCashPackages`, {
      pagination,
      limit,
      keyword,
      sort
    });
    yield put(actions.loadCashPackages.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadCashPackage(action) {
  try {
    const { cashPackageId } = action.payload;
    const res = yield fetcher.get(`cashpackages/${cashPackageId}`);
    yield put(actions.loadCashPackage.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadCashPackage.failure(getErrorMsg(error)));
  }
}

function* createCashPackage(action) {
  try {
    const { cashPackage, checkedList } = action.payload;
    const formData = getFormData(cashPackage, checkedList)
    const res = yield fetcher.post(`cashpackages`, formData);
    yield put(actions.createCashPackage.success(res.data));
    yield put(push("/cash-package/packages"));
  } catch (error) {
    handleError(error);
    yield put(actions.createCashPackage.failure(getErrorMsg(error)));
    yield put(push("/cash-package/packages/new"));
  }
}

function* editCashPackage(action) {
  const { id, cashPackage, checkedList } = action.payload;
  try {
    const formData = getFormData(cashPackage, checkedList)
    const res = yield fetcher.patch(`cashpackages/${id}`, formData);
    yield put(actions.editCashPackage.success(res.data));
    yield put(push("/cash-package/packages"));
  } catch (error) {
    handleError(error);
    yield put(actions.editCashPackage.failure(getErrorMsg(error)));
    yield put(push(`/cash-package/packages/edit/${id}`));
  }
}

function* deleteCashPackage(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`cashpackages/${id}`);
    yield put(actions.deleteCashPackage.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteCashPackage.failure(getErrorMsg(error)));
  }
}

function* importCashPackage(action) {
  try {
    const { file, type, pagination, limit, keyword, adjustPrice } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    formData.append("adjustPrice", adjustPrice);
    if (keyword) formData.append("keyword", keyword);

    const res = yield fetcher.post(`import/cashpackages`, formData);
    yield put(actions.importCashPackage.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importCashPackage.failure(getErrorMsg(error)));
    yield put(push("/cash-package/packages"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post(`export/export_masterdata`, { type: "cashPackages" });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function getFormData(product, checkedList) {

  let allow = [
    "barcode",
    "cash_package_name",
    "cash",
    "is_show",
    "pricelist"
  ];

  let products = {}
  Object.keys(product).map(x => {
    if(allow.includes(x)){
      products[x] = product[x]
    }
  })

  let check_list = {}
  checkedList.map(check => {
    let key = `${check.name}`;
    check_list[key] = check.value;
  });
  
  return {...products, check_list};
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/cashpackage`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchCashPackages() {
  yield all([
    takeEvery(actions.LOAD_CASH_PACKAGES.REQUEST, loadCashPackages),
    takeEvery(actions.LOAD_CASH_PACKAGE.REQUEST, loadCashPackage),
    takeEvery(actions.CREATE_CASH_PACKAGE.REQUEST, createCashPackage),
    takeEvery(actions.EDIT_CASH_PACKAGE.REQUEST, editCashPackage),
    takeEvery(actions.DELETE_CASH_PACKAGE.REQUEST, deleteCashPackage),
    takeEvery(actions.IMPORT_CASH_PACKAGE.REQUEST, importCashPackage),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
