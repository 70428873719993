import { fork } from "redux-saga/effects";

import watchBranches from "./components/branch/sagas";
import watchBranchCashPackages from "./components/branch_cash_package/sagas";
import watchBranchCategories from "./components/branch_category/sagas";
import watchBranchEmployees from "./components/branch_employee/sagas";
import watchBranchProducts from "./components/branch_product/sagas";
import watchBranchActualServices from "./components/branch_service/sagas";
import watchBranchServicePackages from "./components/branch_service_package/sagas";
import watchBranchTerminals from "./components/branch_terminal/sagas";

const watchBranch = [
  fork(watchBranches),
  fork(watchBranchCashPackages),
  fork(watchBranchCategories),
  fork(watchBranchEmployees),
  fork(watchBranchProducts),
  fork(watchBranchActualServices),
  fork(watchBranchServicePackages),
  fork(watchBranchTerminals),
];

export { watchBranch };
