import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Badge,
  Checkbox,
  Col,
  message,
  Modal,
  notification,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";

import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  numberFormat,
  uniqueArray,
  extractKeyFromArray,
} from "lib/helper";

import {
  AddBtn,
  DeleteBadge,
  DeleteListModal,
  FilterBtn,
  TagFilter,
  ImportExportBtn,
  ListAction,
  PageTitle,
  UploadModal,
} from "lib/widget";

import Filter from "./Filter";

const { Text } = Typography;
const { confirm } = Modal;

class ProductList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = nextProps.products;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (errorImport !== "") {
      return { errorImport: errorImport };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    cond: "",
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
    adjustPrice: false,
    selectedRowKeys: [],
  };

  fetchData = () => {
    const { page, limit, cond, sorter } = this.state;
    setSorter(sorter)
    setSearch(cond)
    this.props.loadProducts(page, limit, cond, sorter);
  };

  handleSearch = (val, filter) => {
    this.setState(
      { page: 1, visible: false, keyword: val.keyword, cond: val },
      this.fetchData
    );
    this.getFilterTag(filter, val.is_show, val.keyword);
  };

  getFilterTag = (filter, isShow, keyword) => {
    let brand = "",
      category = "",
      is_show = "",
      word = "";

    if (isShow === "0") is_show = "Not display on iPad";
    if (isShow === "1") is_show = "Display on iPad";

    if (keyword !== "") word = `${keyword}`;

    Object.keys(filter).map((key) => {
      filter[key].map((val) => {
        if (key === "brand")
          brand += (brand !== "" ? ", " : "") + val.product_brand;
        if (key === "category")
          category += (category !== "" ? ", " : "") + val.category_name;
      });
    });

    this.setState({
      brandTag: brand !== "" ? `${brand}` : "",
      cateTag: category !== "" ? `${category}` : "",
      isShowTag: is_show,
      keywordTag: word,
    });

    setFilterTag({
      brandTag: brand !== "" ? `${brand}` : "",
      cateTag: category !== "" ? `${category}` : "",
      isShowTag: is_show,
      keywordTag: word,
    })
  };

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;

    this.setState(
      { page: current, limit: pageSize, sorter: { order, field } },
      this.fetchData
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = this.props.products;
    const prevMsg = prevProps.products;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (errorImport !== "" && prevMsg.errorImport !== errorImport) {
      this.openNotification(errorImport);
    }
  }

  componentDidMount() {
    let filter = getFilterTag()

    if (typeof filter === 'object') {
      this.setState({ ...filter, cond: getSearch(), sorter: getSorter() }, this.fetchData)
      return
    }
    this.fetchData()
  }

  showModalImport = () => {
    this.setState({
      visibleImport: true,
    });
  };

  handleCancel = () => {
    this.setState({ visibleImport: false });
  };

  onChooseFile = ({ file }) => {
    if (file.status !== "removed") {
      this.setState({ file, fileList: [file] });
    }
  };

  onRemove = () => {
    this.setState({ fileList: [], file: null });
  };

  handleUpload = () => {
    if (this.state.adjustPrice) {
      this.showConfirm();
    } else {
      this.uploadProduct();
    }
  };

  uploadProduct = () => {
    const { pagination, limit, keyword, file, adjustPrice } = this.state;
    this.props.importProduct(
      file,
      "product",
      pagination,
      limit,
      keyword,
      adjustPrice
    );
    this.setState({ file: null, fileList: [] });
  };

  onCheckboxUploadChange = (e) => {
    if (e.target.checked) {
      this.setState({ adjustPrice: true });
    } else {
      this.setState({ adjustPrice: false });
    }
  };

  showConfirm = () => {
    let that = this;
    confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Do you Want to update these master data (including price) ?",
      onOk() {
        that.uploadProduct();
      },
      onCancel() {
        that.setState({ adjustPrice: false });
      },
    });
  };

  openNotification = (message) => {
    notification.error({
      message: "Import fail",
      description: message,
      duration: 0,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.products.data.filter((x) =>
        selectedRowKeys.includes(x.product_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.product_id)
      );
      items = uniqueArray([...removeState, ...removeData], "product_id");
    }

    this.setState({ selectedRowKeys, removeData: items });
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter((x) => x.product_id !== id);
    this.setState({ selectedRowKeys: item, removeData });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "product_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  render() {
    const { products } = this.props;
    const { selectedRowKeys, sorter } = this.state;
    const { brandTag, cateTag, isShowTag, keywordTag } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const actionCol = [
      {
        title: "",
        key: "product_id",
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: "left",
        render: (products) => (
          <ListAction
            editUrl={`/product/products/edit/${products.product_id}`}
            onDelete={this.props.deleteProduct}
            id={products.product_id}
            name="product_products"
          />
        ),
      },
    ];

    const mainColumns = [
      {
        title: "Barcode",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'barcode' ? sorter.order : false,
        width: 140,
        dataIndex: "barcode",
        render: (text, record) => {
          if (record.is_show === 1) {
            return <Badge status="success" text={text} />;
          } else {
            return <Badge status="default" text={text} />;
          }
        },
      },
      {
        title: "Supplier Product Name",
        width: 280,
        sortOrder: sorter !== null && sorter.field === 'product_name' ? sorter.order : false,
        sorter: true,
        dataIndex: "product_name",
      },
      {
        title: "Display Name",
        width: 280,
        sortOrder: sorter !== null && sorter.field === 'display_name' ? sorter.order : false,
        sorter: true,
        dataIndex: "display_name",
      },
      {
        title: "Category",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'category_name' ? sorter.order : false,
        dataIndex: "category_name",
      },
    ];

    const productCol = [
      {
        title: "Brand",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'product_brand' ? sorter.order : false,
        dataIndex: "product_brand",
      },
      {
        title: "Unit",
        dataIndex: "uom_name",
      },
      {
        title: "Salon price",
        dataIndex: "salon_price",
        className: "align-right",
        render: (text) => (text !== "") ? numberFormat(text) : "",
      },
      {
        title: "Retail price",
        dataIndex: "retail_price",
        className: "align-right",
        render: (text) => (text !== "") ? numberFormat(text) : "",
      },
    ];

    const columns =
      window.innerWidth <= 1025
        ? [...actionCol, ...mainColumns, ...productCol]
        : [...numberCol, ...mainColumns, ...productCol, ...actionCol];

    return (
      <div>
        <Spin size="large" spinning={this.props.products.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              title="Products"
              name="product_products"
              component={
                <AddBtn url="/product/products/new" name="product_products" />
              }
            />

            <Row gutter={[16, 16]}>
              <DeleteBadge
                count={this.state.removeData.length}
                onClick={this.showDrawer("visibleDelete")}
              />
              <Col xs={10} sm={10} md={8} lg={6} className="import-btn-align">
                <ImportExportBtn
                  showModalImport={this.showModalImport}
                  download={this.props.download}
                  name="product_products"
                />
              </Col>
              <Col
                xs={3}
                sm={3}
                md={{ span: 7, offset: 3 }}
                lg={{ span: 8, offset: 6 }}
              ></Col>
              <FilterBtn showDrawer={this.showDrawer} />
              <Filter
                list={products.data}
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col xs={19} sm={19} md={21} lg={22}>
                {TagFilter([keywordTag], "Keyword", "green")}
                {TagFilter([isShowTag], "Status", "cyan")}
                {TagFilter([brandTag], "Brands", "orange")}
                {TagFilter([cateTag], "Category", "blue")}
              </Col>
            </Row>

            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["product_id", "barcode", "product_name"]}
            />
          </div>

          <Row gutter={[8, 16]} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={12}>
              <Badge
                className="remark-text-badge"
                status="success"
                text="Display on iPad"
              />{" "}
              &nbsp;&nbsp;
              <Badge
                className="remark-text-badge"
                status="default"
                text="Not display on iPad"
              />
            </Col>
          </Row>
          <Table
            rowKey={"product_id"}
            bordered={true}
            size="small"
            columns={columns}
            rowSelection={rowSelection}
            dataSource={products.data}
            pagination={{
              pageSize: this.state.limit,
              total: products.totalSize,
              current: this.state.page,
              position: ["bottomCenter"],
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 1025 ? { x: 1000 } : undefined}
          />

          <UploadModal
            visibleImport={this.state.visibleImport}
            handleCancel={this.handleCancel}
            loading={this.props.products.loading}
            onChooseFile={this.onChooseFile}
            fileList={this.state.fileList}
            onRemove={this.onRemove}
            handleUpload={this.handleUpload}
            uploading={this.state.uploading}
            disabled={this.state.file ? false : true}
            spacialComp={
              <Checkbox
                onChange={this.onCheckboxUploadChange}
                checked={this.state.adjustPrice === true}
              >
                Including price
              </Checkbox>
            }
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ products }) => ({
  products,
});

const mapDispatchToProps = {
  loadProducts: actions.loadProducts.request,
  deleteProduct: actions.deleteProduct.request,
  importProduct: actions.importProduct.request,
  download: actions.download.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
