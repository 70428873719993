import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Card,
  Spin,
  List,
  Avatar,
  Checkbox,
  Radio,
} from "antd";
import styled from "styled-components";
import * as moment from "moment";
import { numberFormat } from "lib/helper";

import OneImg from "../../../../../theme/img/one.png";
import TwoImg from "../../../../../theme/img/two.png";
import ThreeImg from "../../../../../theme/img/three.png";
import FourImg from "../../../../../theme/img/four.png";
import FiveImg from "../../../../../theme/img/five.png";
import SixImg from "../../../../../theme/img/six.png";
import SevenImg from "../../../../../theme/img/seven.png";
import GightImg from "../../../../../theme/img/eight.png";
import NineImg from "../../../../../theme/img/nine.png";
import TenImg from "../../../../../theme/img/ten.png";

const Image = styled.img`
  height: 15px;
  border-radius: 21px;
  background: #f2f7ff;
  box-shadow:  6px 6px 17px #dfe3eb, 
               -6px -6px 17px #ffffff;a
`;

const CustomeParagraph = styled.p`
  margin-bottom: 2px;
`;

const H5 = styled.span`
  font-size: 14px;
  color: rgba(54, 54, 54, 0.85);
  margin-bottom: 0.5em;
  font-weight: 600;
  line-height: 1.4;
`;

const AmountStyle = styled.span`
font-size: 16px;
font-weight: 600;
`

const cardStyle = {
  borderRadius: "10px",
  background: "#f1f3f6",
  boxShadow: "6px 6px 12px #e5e7ea, -6px -6px 12px #fdffff",
};

const plainOptions = ["Amount", "Quantity"];

const { Text } = Typography;
const IMAGES = [OneImg, TwoImg, ThreeImg, FourImg, FiveImg, SixImg, SevenImg, GightImg, NineImg, TenImg];

export class TopItem extends Component {
  state = {
    showValue: "Amount",
  };
  handleChange = (e) => {
    this.setState({ showValue: e.target.value });
  };
  render() {
    const { title, type, data, loading, order } = this.props;
    return (
      <Col xs={24} sm={12} md={12} lg={8}>
        <Card bordered={false} bodyStyle={{ background: "#f2f5f7" }}>
          <Spin spinning={loading}>
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <CustomeParagraph>
                  <H5>
                    {title} of the {type}
                  </H5>
                </CustomeParagraph>
                <Text type="secondary">{moment().utc().format("LL")}</Text>
              </Col>
            </Row>
            
            <Row justify="end" gutter={[16, 8]}>
              <Col span={18} className="align-right">
                { order !== 0 && 
                <Radio.Group
                  options={plainOptions}
                  value={this.state.showValue}
                  onChange={this.handleChange}
                />
                }
              </Col>
            </Row>
            
            <Row
              style={{ width: "100%", height: "240px", overflowY: "scroll" }}
            >
              <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, i) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        typeof IMAGES[i] !== "undefined" && (
                          <Avatar src={IMAGES[i]}></Avatar>
                        )
                      }
                      title={`${item.label}`}
                      description={
                        this.state.showValue === "Amount"
                          ? <AmountStyle>{numberFormat(item.amount)}</AmountStyle>
                          : <AmountStyle>{numberFormat(item.quantity, 0)}</AmountStyle>
                      }
                    />
                  </List.Item>
                )}
              />
              </Col>
            </Row>
            {/* {typeof data !== "undefined" &&
              data.map((val, i) => (
                <Row gutter={[24, 16]} align="middle">
                  <Col span={24}>
                    <Card
                      key={i}
                      style={{ ...cardStyle }}
                      bodyStyle={{ padding: "10px" }}
                      bordered={false}
                    >
                      <Row gutter={[8, 16]} align="middle">
                        <Col lg={2}>
                          <Image src={IMAGES[i]} />
                        </Col>
                        <Col lg={10}>
                          <Text style={{ fontSize: "11px" }}>{val.label}</Text>
                        </Col>
                        <Col lg={12}>
                          <div
                            style={{
                              fontSize: "large",
                              textAlign: "right",
                              fontWeight: "bold",
                              color: "#696969",
                            }}
                          >
                            {numberFormat(val.amount)}
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              ))} */}
          </Spin>
        </Card>
      </Col>
    );
  }
}

export default TopItem;
