import React from "react";
import { Switch } from "react-router-dom";

import AnalyticLayout from "./AnalyticLayout";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/analytics"
      component={AnalyticLayout}
      perm="employee_employees"
    />
  </Switch>
);
