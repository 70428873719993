import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadDistributors(action) {
  
  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post('distributors/distributors_list', { pagination, limit, keyword, sort })
    yield put(actions.loadDistributors.success(res.data));
  } catch (error) {
    handleError(error)
  }
}

function* loadDistributor(action) {
  try {
    const { distributorId } = action.payload;
    const res = yield fetcher.get(`distributors/${distributorId}`)
    yield put(actions.loadDistributor.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadDistributor.failure(getErrorMsg(error)));
  }
}

function* createDistributor(action) {
  try {
    const { code, name } = action.payload;
    const res = yield fetcher.post('distributors', { distributor_code: code, distributor_name: name })
    yield put(actions.createDistributor.success(res.data));
    yield put(push("/product/distributors"));
  } catch (error) {
    handleError(error)
    yield put(actions.createDistributor.failure(getErrorMsg(error)));
  }
}

function* editDistributor(action) {
  const { id, code, name } = action.payload;

  try {
    const res = yield fetcher.patch(`distributors/${id}`, { distributor_code: code, distributor_name: name })
    yield put(actions.editDistributor.success(res.data));
    yield put(push("/product/distributors"));
  } catch (error) {
    handleError(error)
    yield put(actions.editDistributor.failure(getErrorMsg(error)));
  }
}

function* deleteDistributor(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`distributors/${id}`)
    yield put(actions.deleteDistributor.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.deleteDistributor.failure(getErrorMsg(error)));
  }
}
function* upload(action) {
  try {
    const { file, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", "distributor");
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    const res = yield fetcher.post('import/details', formData)
    yield put(actions.upload.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.upload.failure(getErrorMsg(error)));
    yield put(push("/product/distributors"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post('export/export_masterdata', { type: "distributor" })
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error)
    yield put(actions.upload.failure(getErrorMsg(error)));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/product/distributor`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchDistributors() {
  yield all([
    takeEvery(actions.LOAD_DISTRIBUTORS.REQUEST, loadDistributors),
    takeEvery(actions.CREATE_DISTRIBUTOR.REQUEST, createDistributor),
    takeEvery(actions.LOAD_DISTRIBUTOR.REQUEST, loadDistributor),
    takeEvery(actions.EDIT_DISTRIBUTOR.REQUEST, editDistributor),
    takeEvery(actions.DELETE_DISTRIBUTOR.REQUEST, deleteDistributor),
    takeEvery(actions.UPLOAD_FILE.REQUEST, upload),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
