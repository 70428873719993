import { createTypes, createAction } from "lib/action";

const LOAD_ACTUAL_SERVICE_TYPES = createTypes("actualServiceTypes", "list");
const LOAD_ACTUAL_SERVICE_TYPE = createTypes("actualServiceType", "show");
const CREATE_ACTUAL_SERVICE_TYPE = createTypes("actualServiceType", "create");
const EDIT_ACTUAL_SERVICE_TYPE = createTypes("actualServiceType", "edit");
const DELETE_ACTUAL_SERVICE_TYPE = createTypes("actualServiceType", "delete");
const UPLOAD_FILE = createTypes("actualServiceTypes", "import");
const DOWNLOAD_FILE = createTypes("actualServiceTypes", "export");
const DESTROY_MANY = createTypes("actualServiceTypes", "destory");

const loadActualServiceTypes = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_ACTUAL_SERVICE_TYPES.REQUEST, {
      pagination,
      limit,
      keyword,
      sort
    }),
  success: actualServiceTypes =>
    createAction(LOAD_ACTUAL_SERVICE_TYPES.SUCCESS, { actualServiceTypes }),
  failure: (error) => createAction(LOAD_ACTUAL_SERVICE_TYPES.FAILURE, { error })
};

const loadActualServiceType = {
  request: serviceTypeId =>
    createAction(LOAD_ACTUAL_SERVICE_TYPE.REQUEST, { serviceTypeId }),
  success: actualServiceType =>
    createAction(LOAD_ACTUAL_SERVICE_TYPE.SUCCESS, { actualServiceType }),
  failure: (error) => createAction(LOAD_ACTUAL_SERVICE_TYPE.FAILURE, { error })
};

const createActualServiceType = {
  request: (code, name, abbr) =>
    createAction(CREATE_ACTUAL_SERVICE_TYPE.REQUEST, { code, name, abbr }),
  success: actualServiceType =>
    createAction(CREATE_ACTUAL_SERVICE_TYPE.SUCCESS, { actualServiceType }),
  failure: error => createAction(CREATE_ACTUAL_SERVICE_TYPE.FAILURE, { error })
};

const editActualServiceType = {
  request: (id, code, name, abbr) =>
    createAction(EDIT_ACTUAL_SERVICE_TYPE.REQUEST, {
      id,
      code,
      name,
      abbr
    }),
  success: actualServiceType =>
    createAction(EDIT_ACTUAL_SERVICE_TYPE.SUCCESS, { actualServiceType }),
  failure: error => createAction(EDIT_ACTUAL_SERVICE_TYPE.FAILURE, { error })
};

const deleteActualServiceType = {
  request: id => createAction(DELETE_ACTUAL_SERVICE_TYPE.REQUEST, { id }),
  success: actualServiceType =>
    createAction(DELETE_ACTUAL_SERVICE_TYPE.SUCCESS, { actualServiceType }),
  failure: error => createAction(DELETE_ACTUAL_SERVICE_TYPE.FAILURE, { error })
};

const upload = {
  request: (file, pagination, limit, keyword) =>
    createAction(UPLOAD_FILE.REQUEST, {
      file,
      pagination,
      limit,
      keyword
    }),
  success: data => createAction(UPLOAD_FILE.SUCCESS, { data }),
  failure: error => createAction(UPLOAD_FILE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: error => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_ACTUAL_SERVICE_TYPES,
  loadActualServiceTypes,
  LOAD_ACTUAL_SERVICE_TYPE,
  loadActualServiceType,
  CREATE_ACTUAL_SERVICE_TYPE,
  createActualServiceType,
  EDIT_ACTUAL_SERVICE_TYPE,
  editActualServiceType,
  DELETE_ACTUAL_SERVICE_TYPE,
  deleteActualServiceType,
  upload,
  UPLOAD_FILE,
  download,
  DOWNLOAD_FILE,
  DESTROY_MANY,
  destroyMany
};
