import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, logErrorMsg, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadProducts(action) {
  try {
    const { pagination, limit, cond, sort } = action.payload;
    let params = { pagination, limit, cond, sort }

    const res = yield fetcher.post("products/allProducts", params)

    yield put(actions.loadProducts.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadProducts.failure(getErrorMsg(error)));
    logErrorMsg(error);
  }
}

function* loadProduct(action) {
  try {
    const { productId } = action.payload;
    const res = yield fetcher.get(`products/${productId}`);
    yield put(actions.loadProduct.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadProduct.failure(getErrorMsg(error)));
    logErrorMsg(error);
  }
}

function* createProduct(action) {
  try {
    const { product, checkedList } = action.payload;
    const formData = getFormData(product, checkedList);
    const res = yield fetcher.post("products", formData);
    yield put(actions.createProduct.success(res.data));
    yield put(push("/product/products"));
  } catch (error) {
    handleError(error);
    yield put(actions.createProduct.failure(getErrorMsg(error)));
    yield put(push("/product/products/new"));
  }
}

function* editProduct(action) {
  const { id, product, checkedList } = action.payload;
  try {
    const formData = getFormData(product, checkedList);
    const res = yield fetcher.patch(`products/${id}`, formData);
    yield put(actions.editProduct.success(res.data));
    yield put(push("/product/products"));
  } catch (error) {
    handleError(error);
    yield put(actions.editProduct.failure(getErrorMsg(error)));
    yield put(push(`/product/products/edit/${id}`));
  }
}

function* deleteProduct(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`products/${id}`);
    yield put(actions.deleteProduct.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteProduct.failure(getErrorMsg(error)));
  }
}

function* importProduct(action) {
  try {
    const {
      file,
      type,
      pagination,
      limit,
      keyword,
      adjustPrice
    } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("adjustPrice", adjustPrice);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);

    const res = yield fetcher.post("import/products", formData);
    yield put(actions.importProduct.success(res.data));
  } catch (error) {
    logErrorMsg(error)
    handleError(error);
    yield put(actions.importProduct.failure(getErrorMsg(error)));
    yield put(push("/product/products"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/product_masterdata", {});
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function getFormData(product, checkedList) {
  let allow = [
    "barcode",
    "product_name",
    "product_category_id",
    "product_brand_id",
    "uom_id",
    "item_code",
    "size",
    "distributor_id",
    "retail_cost_before_vat",
    "retail_cost",
    "salon_cost_before_vat",
    "salon_cost",
    "pricelist",
    "is_show",
    "commission",
    'salon_minimum_stock',
    'retail_minimum_stock',
    'display_name'
  ];

  let products = {};
  Object.keys(product).map(x => {
    if (allow.includes(x)) {
      products[x] = product[x];
    }
  });

  let check_list = {};
  checkedList.map(check => {
    let key = `${check.name}`;
    check_list[key] = check.value;
  });

  return { ...products, check_list };
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/product`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
    logErrorMsg(error)
  }
}

export default function* watchProducts() {
  yield all([
    takeEvery(actions.LOAD_PRODUCTS.REQUEST, loadProducts),
    takeEvery(actions.LOAD_PRODUCT.REQUEST, loadProduct),
    takeEvery(actions.CREATE_PRODUCT.REQUEST, createProduct),
    takeEvery(actions.EDIT_PRODUCT.REQUEST, editProduct),
    takeEvery(actions.DELETE_PRODUCT.REQUEST, deleteProduct),
    takeEvery(actions.IMPORT_PRODUCT.REQUEST, importProduct),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
