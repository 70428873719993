import React from "react";
import { Button, Row, Col } from "antd";

const ReportBtn = ({ onDowload, onClear }) => {
  return (
    <Row justify="center" gutter={[16, 16]}>
      <Col sm={3}>
        <Button type="primary" block onClick={onDowload}>
          Download
        </Button>
      </Col>
      <Col sm={3}>
        <Button block onClick={onClear}>
          Clear
        </Button>
      </Col>
    </Row>
  );
};

export default ReportBtn;
