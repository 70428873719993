import React from "react";
import { dummyRequest } from 'lib/helper'
import {
    Button,
    Row,
    Col,
    Upload,
    Spin,
    Modal
  } from "antd";

import {
  UploadOutlined
} from "@ant-design/icons";

const UploadModal = (props) => {
  return (
    <Modal
    visible={props.visibleImport}
    title="Import"
    onCancel={props.handleCancel}
    footer={false}
  >
    <Spin size="large" spinning={props.loading}>
      <Row style={{ marginBottom: 16 }}>
        <Col xs={22} sm={22} md={22} lg={22}>
          <Upload
            name="group"
            customRequest={dummyRequest}
            headers={{
              authorization: "authorization-text"
            }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={props.onChooseFile}
            fileList={props.fileList}
            onRemove={props.onRemove}
          >
            <Button
              type="default"
              icon={<UploadOutlined />}
              style={{ width: "250px" }}
            >
              Select File
            </Button>
          </Upload>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}>
          <Button
            type="primary"
            shape="circle"
            icon={<UploadOutlined />}
            onClick={props.handleUpload}
            loading={props.uploading}
            disabled={props.disabled}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 16 }}>
          <Col xs={22} sm={22} md={22} lg={22}>
              { props.spacialComp }
          </Col>
      </Row> 
    </Spin>
  </Modal>
  );
};

export default UploadModal;
