import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadUnits(action) {
  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post('uoms/uoms_list', { pagination, limit, keyword, sort })
    yield put(actions.loadUnits.success(res.data));
  } catch (error) {
    handleError(error)
  }
}

function* loadUnit(action) {
  try {
    const { unitId } = action.payload;
    const res = yield fetcher.get(`uoms/${unitId}`)
    yield put(actions.loadUnit.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadUnit.failure(getErrorMsg(error)));
  }
}

function* createUnit(action) {
  try {
    const { code, name, clientId } = action.payload;
    const res = yield fetcher.post('uoms', { uom_code: code, uom_name: name, client_id: clientId })
    yield put(actions.createUnit.success(res.data));
    yield put(push("/product/units"));
  } catch (error) {
    handleError(error)
    yield put(actions.createUnit.failure(getErrorMsg(error)));
  }
}

function* editUnit(action) {
  const { id, code, name, clientId } = action.payload;
  try {
    const res = yield fetcher.patch(`uoms/${id}`, { uom_code: code, uom_name: name, client_id: clientId })
    yield put(actions.editUnit.success(res.data));
    yield put(push(`/product/units/${res.data.data.uom_id}`));
  } catch (error) {
    handleError(error)
    yield put(actions.editUnit.failure(getErrorMsg(error)));
  }
}

function* deleteUnit(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`uoms/${id}`)
    yield put(actions.deleteUnit.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.deleteUnit.failure(getErrorMsg(error)));
  }
}

function* upload(action) {
  try {
    const { file, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", "uom");
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    const res = yield fetcher.post('import/details', formData)
    yield put(actions.upload.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.upload.failure(getErrorMsg(error)));
    yield put(push("/product/units"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post('export/export_masterdata', { type: "uom" })
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error)
    yield put(actions.upload.failure(getErrorMsg(error)));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/product/unit`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchUnits() {
  yield all([
    takeEvery(actions.LOAD_UNITS.REQUEST, loadUnits),
    takeEvery(actions.LOAD_UNIT.REQUEST, loadUnit),
    takeEvery(actions.CREATE_UNIT.REQUEST, createUnit),
    takeEvery(actions.EDIT_UNIT.REQUEST, editUnit),
    takeEvery(actions.DELETE_UNIT.REQUEST, deleteUnit),
    takeEvery(actions.UPLOAD_FILE.REQUEST, upload),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
