import React from "react";
import { Switch } from "react-router-dom";

import BranchHasServicePackageList from "./BranchHasServicePackageList";
import EditBranchHasServicePackage from "./EditBranchHasServicePackage";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/branch/service-packages/edit/:branch_id"
      component={EditBranchHasServicePackage}
      perm="branch_service_packages"
      action="can_add"
    />
    <PrivateRoute
      path="/branch/service-packages"
      component={BranchHasServicePackageList}
      perm="branch_service_packages"
    />
  </Switch>
);
