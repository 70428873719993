import { all, put, takeEvery } from "redux-saga/effects";
import { fetchGo, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadTopItems(action) {
  try {
    const { type, duration, branch_id, query_type } = action.payload;
    const params = { type, duration, branch_id, query_type }

    const [service, product, stylish, emp, customer] = yield all([
      fetchGo.post(`top_service_analytics`, params),
      fetchGo.post(`top_product_analytics`, params),
      fetchGo.post(`top_stylist_analytics`, params),
      fetchGo.post(`top_employee_analytics`, params),
      fetchGo.post(`top_spending_analytics`, params)
    ])

    yield put(actions.loadTopItems.success(service.data, product.data, stylish.data, emp.data, customer.data));
  } catch (error) {
    handleError(error);
  }
}

export default function* watchTopItems() {
  yield all([
    takeEvery(actions.LOAD_TOPITEM.REQUEST, loadTopItems),
  ]);
}
