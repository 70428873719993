import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";

import PermissionForm from "./PermissionForm";
import { checkPermission } from "lib/helper";

class EditPermission extends Component {
  state = {
    can_edit: checkPermission("authorization_permissions", "can_edit", false)
  };
  componentDidMount() {
    this.props.loadPermission(this.props.match.params.permission_id);
  }

  editPermission = async (permission) => {
    this.props.editPermission(
      this.props.match.params.permission_id,
      permission
    );
  };

  render() {
    const {
      permission,
      alertMessage,
      errorMessage,
      loading
    } = this.props;


    return (
      <PermissionForm
        onSubmit={this.editPermission}
        permission={permission}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        readOnlyGroup={true}
        canEdit={this.state.can_edit}
      />
    );
  }
}

const mapStateToProps = ({ permissions, branches }) => {
  return {
    permission: permissions.permission,
    alertMessage: permissions.alertMessage,
    errorMessage: permissions.errorMessage,
    loading: permissions.loading
  };
};

const mapDispatchToProps = {
  loadPermission: actions.loadPermission.request,
  editPermission: actions.editPermission.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPermission);
