import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { loadUserGroups } from "modules/authen/components/usergroup/actions";
import { loadEmployees } from "modules/employee/components/employee/actions";
import UserForm from "./UserForm";

class NewUser extends Component {
  componentDidMount() {
    this.props.loadUserGroups();
    this.props.loadEmployees();
  }

  createUser = async (user, checkedList, is_manager) => {
    this.props.createUser(user, checkedList, is_manager);
  };

  render() {
    const {
      branches,
      user,
      usergroups,
      alertMessage,
      errorMessage,
      loading,
      employees
    } = this.props;

    const data = [];
    branches.map(branch => {
      const obj = {};
      obj.name = branch.branch_id;
      obj.value = false;
      return data.push(obj);
    });

    return (
      <UserForm
        onSubmit={this.createUser}
        user={user}
        usergroups={usergroups}
        branches={branches}
        checkedList={data}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        employees={employees}
      />
    );
  }
}

const mapStateToProps = ({ users, branches, usergroups, employees }) => {
  return {
    user: users.user,
    branches: branches.data,
    usergroups: usergroups.data,
    alertMessage: users.alertMessage,
    errorMessage: users.errorMessage,
    loading: users.loading,
    employees: employees.data
  };
};

const mapDispatchToProps = {
  createUser: actions.createUser.request,
  loadUserGroups: loadUserGroups.request,
  loadEmployees: loadEmployees.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUser);
