import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Col, Card, Row } from "antd";

import CustomerGrowth from "./CustomerGrowth";

export class CustomerGrowthRateLayout extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.branches !== this.props.branches) {
      this.props.loadCustomerGrowthRate(this.props.branches);
    }
  }

  componentDidMount() {
    if (this.props.branches.length > 0) {
      this.props.loadCustomerGrowthRate(this.props.branches);
    }
  }

  render() {
    const { loading_growth, graph_growth, graph_gender } = this.props

    return (
      <Card title="Customer growth rate">
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <CustomerGrowth 
            data={graph_growth}
            genderData={graph_gender} 
            loading={loading_growth}/>
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = ({ customer_analytic }) => ({
  loading_growth: customer_analytic.loading_growth,
  graph_growth: customer_analytic.graph_growth,
  graph_gender: customer_analytic.graph_gender,
});

const mapDispatchToProps = {
  loadCustomerGrowthRate: actions.loadCustomerGrowthRate.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerGrowthRateLayout);
