import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadCategories(action) {
  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post('categories/allCategories', { pagination, limit, keyword, sort })
    yield put(actions.loadCategories.success(res.data));
  } catch (error) {
    handleError(error)
  }
}

function* loadCategory(action) {
  try {
    const { categoryId } = action.payload;
    const res = yield fetcher.get(`categories/${categoryId}`)
    yield put(actions.loadCategory.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadCategory.failure(getErrorMsg(error)));
  }
}

function* createCategory(action) {
  try {
    const { code, name } = action.payload;
    const res = yield fetcher.post('categories', { category_code: code, category_name: name })
    yield put(actions.createCategory.success(res.data));
    yield put(push("/product/categories"));
  } catch (error) {
    handleError(error)
    yield put(actions.createCategory.failure(getErrorMsg(error)));
  }
}

function* editCategory(action) {
  const { id, code, name } = action.payload;
  try {
    const res = yield fetcher.patch(`categories/${id}`, { category_code: code, category_name: name })
    yield put(actions.editCategory.success(res.data));
    yield put(push("/product/categories"));
  } catch (error) {
    handleError(error)
    yield put(actions.editCategory.failure(getErrorMsg(error)));
  }
}

function* deleteCategory(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`categories/${id}`)
    yield put(actions.deleteCategory.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.deleteCategory.failure(getErrorMsg(error)));
  }
}

function* upload(action) {
  try {
    const { file, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", "productCategory");
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    const res = yield fetcher.post('import/details', formData)
    yield put(actions.upload.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.upload.failure(getErrorMsg(error)));
    yield put(push("/product/categories"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post('export/export_masterdata', { type: "productCategory" })
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error)
    yield put(actions.upload.failure(getErrorMsg(error)));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/product/category`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchCategories() {
  yield all([
    takeEvery(actions.LOAD_CATEGORIES.REQUEST, loadCategories),
    takeEvery(actions.CREATE_CATEGORY.REQUEST, createCategory),
    takeEvery(actions.LOAD_CATEGORY.REQUEST, loadCategory),
    takeEvery(actions.EDIT_CATEGORY.REQUEST, editCategory),
    takeEvery(actions.DELETE_CATEGORY.REQUEST, deleteCategory),
    takeEvery(actions.UPLOAD_FILE.REQUEST, upload),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
