import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import BrandForm from "./BrandForm";

class NewBrand extends Component {
  createBrand = async brand => {
    this.props.createBrand(brand.product_brand_code, brand.product_brand, 1);
  };

  render() {
    const { brand, alertMessage, errorMessage, loading } = this.props;

    return (
      <BrandForm
        onSubmit={this.createBrand}
        brand={brand}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ brands }) => {
  return {
    brand: brands.brand,
    alertMessage: brands.alertMessage,
    errorMessage: brands.errorMessage,
    loading: brands.loading
  };
};

const mapDispatchToProps = {
  createBrand: actions.createBrand.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBrand);
