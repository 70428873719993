import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadActualServiceTypes(action) {
  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post("servicetypes/servicetypes_list", {
      pagination,
      limit,
      keyword,
      sort
    });
    yield put(actions.loadActualServiceTypes.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadActualServiceType(action) {
  try {
    const { serviceTypeId } = action.payload;
    const res = yield fetcher.get(`servicetypes/${serviceTypeId}`);
    yield put(actions.loadActualServiceType.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* createActualServiceType(action) {
  try {
    const { code, name, abbr } = action.payload;
    const res = yield fetcher.post("servicetypes", {
      actual_service_type_code: code,
      actual_service_type: name,
      actual_service_type_abbr: abbr
    });
    yield put(actions.createActualServiceType.success(res.data));
    yield put(push("/actual-service/types"));
  } catch (error) {
    handleError(error);
    yield put(actions.createActualServiceType.failure(getErrorMsg(error)));
  }
}

function* editActualServiceType(action) {
  try {
    const { id, code, name, abbr } = action.payload;
    const res = yield fetcher.patch(`servicetypes/${id}`, {
      actual_service_type_code: code,
      actual_service_type: name,
      actual_service_type_abbr: abbr
    });
    yield put(actions.editActualServiceType.success(res.data));
    yield put(push("/actual-service/types"));
  } catch (error) {
    handleError(error);
    yield put(actions.editActualServiceType.failure(getErrorMsg(error)));
  }
}

function* deleteActualServiceType(action) {
  try {
    const { id } = action.payload;
    yield fetcher.delete(`servicetypes/${id}`);
    yield put(actions.deleteActualServiceType.success(id));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteActualServiceType.failure(getErrorMsg(error)));
  }
}

function* upload(action) {
  try {
    const { file, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", "actualServiceType");
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    const res = yield fetcher.post("import/details", formData);
    yield put(actions.upload.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.upload.failure(getErrorMsg(error)));
    yield put(push("/actual-service/types"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/export_masterdata", { type: "actualServiceType" });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/actualservice/type`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchActualServiceTypes() {
  yield all([
    takeEvery(
      actions.LOAD_ACTUAL_SERVICE_TYPES.REQUEST,
      loadActualServiceTypes
    ),
    takeEvery(actions.LOAD_ACTUAL_SERVICE_TYPE.REQUEST, loadActualServiceType),
    takeEvery(
      actions.CREATE_ACTUAL_SERVICE_TYPE.REQUEST,
      createActualServiceType
    ),
    takeEvery(actions.EDIT_ACTUAL_SERVICE_TYPE.REQUEST, editActualServiceType),
    takeEvery(
      actions.DELETE_ACTUAL_SERVICE_TYPE.REQUEST,
      deleteActualServiceType
    ),
    takeEvery(actions.UPLOAD_FILE.REQUEST, upload),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
