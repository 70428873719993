const createTypes = (resource, action) =>
  ["REQUEST", "SUCCESS", "FAILURE"].reduce(
    (result, type) => ({
      ...result,
      [type]: `${resource.toUpperCase()}_${action.toUpperCase()}_${type}`
    }),
    {}
  );

const createAction = (type, payload = {}) => ({
  type,
  payload
});

export { createTypes, createAction };
