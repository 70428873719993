import { all, put, takeEvery } from "redux-saga/effects";
import { fetchGo, handleError, getErrorMsg } from "lib/fetch";

import * as moment from "moment";
import * as actions from "./actions";

function* loadCustomerSummary(action) {
    try {
        const { branch_id } = action.payload;
        const params = { branch_id }
        params.type = "month"
        params.duration = moment().format('YYYY-MM')

        const res = yield fetchGo.post(`customer_analytics_summary`, params);
        yield put(actions.loadCustomerSummary.success(res.data));
    } catch (error) {
      handleError(error);
      yield put(actions.loadCustomerSummary.failure(getErrorMsg(error)));
    }
}

function* loadCustomerRetention(action) {
  try {
      const { type, duration, branch_id, gender } = action.payload;
      const params = { type, duration, branch_id, gender }
      const res = yield fetchGo.post(`customer_analytics_retention`, params);
      yield put(actions.loadCustomerRetention.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadCustomerRetention.failure(getErrorMsg(error)));
  }
}

function* loadCustomerRevenue(action) {
  try {
      const { type, duration, branch_id, gender } = action.payload;
      const params = { type, duration, branch_id, gender }
      const res = yield fetchGo.post(`customer_analytics_graph_revenue`, params);
      yield put(actions.loadCustomerRevenue.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadCustomerRevenue.failure(getErrorMsg(error)));
  }
}

function* loadCustomerQuantity(action) {
  try {
      const { type, duration, branch_id, gender } = action.payload;
      const params = { type, duration, branch_id, gender }
      const res = yield fetchGo.post(`customer_analytics_graph_customer`, params);
      yield put(actions.loadCustomerQuantity.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadCustomerQuantity.failure(getErrorMsg(error)));
  }
}

function* loadCustomerServiceHours(action) {
  try {
      const { type, duration, branch_id, gender } = action.payload;
      const params = { type, duration, branch_id, gender }
      const res = yield fetchGo.post(`customer_analytics_graph_service`, params);
      yield put(actions.loadCustomerServiceHours.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadCustomerServiceHours.failure(getErrorMsg(error)));
  }
}

function* loadCustomerGrowthRate(action) {
  try {
    const { branch_id } = action.payload;

    const [growth, gender] = yield all([
      fetchGo.post(`customer_analytics_growth`, { branch_id }),
      fetchGo.post(`customer_analytics_growth`, { branch_id, gender : "all" })
    ])

    yield put(actions.loadCustomerGrowthRate.success(growth.data, gender.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadCustomerGrowthRate.failure(getErrorMsg(error)));
  }
}

function* loadCusotmerTopSpend(action) {
  try {
      const { start_date, end_date, query_type, category_id, item_id, gender, branch_id } = action.payload;
      const params = { start_date, end_date, query_type, category_id, item_id, gender, branch_id }
      const res = yield fetchGo.post(`customer_analytics_topbycategory`, params);
      yield put(actions.loadCusotmerTopSpend.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadCusotmerTopSpend.failure(getErrorMsg(error)));
  }
}

export default function* watchCustomerAnalytics() {
  yield all([
    takeEvery(actions.LOAD_CUSTOMERS_SUMMARY.REQUEST, loadCustomerSummary),
    takeEvery(actions.LOAD_CUSTOMERS_RETENTION.REQUEST, loadCustomerRetention),
    takeEvery(actions.LOAD_CUSTOMERS_REVENUE.REQUEST, loadCustomerRevenue),
    takeEvery(actions.LOAD_CUSTOMERS_QUANTITY.REQUEST, loadCustomerQuantity),
    takeEvery(actions.LOAD_CUSTOMERS_SERVICE.REQUEST, loadCustomerServiceHours),
    takeEvery(actions.LOAD_CUSTOMERS_GROWTH.REQUEST, loadCustomerGrowthRate),
    takeEvery(actions.LOAD_CUSTOMERS_TOPSPEND.REQUEST, loadCusotmerTopSpend),
  ]);
}
