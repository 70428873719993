import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Menu } from "antd";
import {
  SnippetsOutlined,
  ClockCircleOutlined,
  ShoppingCartOutlined,
  ScissorOutlined,
  StarOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  ContactsOutlined,
  ShopOutlined,
  LockOutlined,
  UserOutlined,
  FileOutlined
} from "@ant-design/icons";

import { groupArray, getPermission, getToken } from "lib/helper";
import { getUrl } from 'lib/pagefn'

const SubMenu = Menu.SubMenu;

const getMenu = () => {
  let menu = groupArray(getPermission(), "main_menu");
  return Object.keys(menu).map(key =>
    subMenuComponent(key, menu[key])
  );
};

const getIcon = groupName => {
  switch (groupName) {
    case "Report":
      return <SnippetsOutlined />;
    case "Schedule Report":
      return <ClockCircleOutlined />;
    case "Product":
      return <ShoppingCartOutlined />;
    case "Actual Services":
      return <ScissorOutlined />;
    case "Service Package":
      return <StarOutlined />;
    case "Cash Packages":
      return <DollarCircleOutlined />;
    case "Employees":
      return <TeamOutlined />;
    case "Customer":
      return <ContactsOutlined />;
    case "Branch":
      return <ShopOutlined />;
    case "Authentication":
      return <LockOutlined />;
    case "System User":
      return <UserOutlined />;
    default:
      return <FileOutlined />;
  }
};

const subMenuComponent = (name, data) => {
  if (name === "Price List" ) return
  return (
    <SubMenu
      key={name}
      title={
        <span>
           { getIcon(name) }
          <span>{name}</span>
        </span>
      }
    >
      {data.map((val, i) => menuItemComponent(val, i))}
    </SubMenu>
  );
};

const menuItemComponent = (data, key) => {
  let url = getUrl(data.permission_name);
  return (
    <Menu.Item key={url}>
      <span>{data.sub_menu}</span>
      <Link to={`/${url}`} />
    </Menu.Item>
  );
};

export default () => {
  const [selectedMenu, setSelectedMenu] = useState(0)
  const [subSelectedMenu, setSubSelectedMenu] = useState(0)

  const onChangeMenu = (key) => {
    setSelectedMenu(key);
    setSubSelectedMenu(key)
    localStorage.setItem("selectedMenu", key);
    localStorage.setItem("search", null);
    localStorage.setItem("sorter", null);
    localStorage.setItem("filterTag", null);
    localStorage.setItem("branchFilter", null);
  }
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[selectedMenu]}
      defaultOpenKeys={[subSelectedMenu]}
      onClick={menu => onChangeMenu(menu.key)}
    >
      {getToken("token") === null ? null : getMenu()}
    </Menu>
  );
};
