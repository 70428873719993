import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Avatar,
  Checkbox,
  message,
  Spin,
} from "antd";

import { PageTitle, FormBtn, RequriedText, SectionDivider } from "lib/widget";
import { UserOutlined } from "@ant-design/icons";

const FormItem = Form.Item;
const Option = Select.Option;

export default class BranchForm extends Component {
  state = {
    file: {},
    url: "",
    checkedList: this.props.checkedList,
    is_upload: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage, checkedList } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (checkedList.length > 0) {
      return { checkedList: checkedList };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage, checkedList } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }

    if (checkedList.length > 0 && prevState.checkedList !== checkedList) {
      this.setState({ checkedList });
    }
  }

  onChecked = (checkedValues) => {
    const obj = this.state.checkedList;
    obj.map((check) => {
      if (check.name.toString() === checkedValues.target.name.toString())
        check.value = checkedValues.target.checked;
    });
    this.setState({
      checkedList: obj,
    });
  };

  isDisabled = () => {
    return this.props.employee.employee_code !== "" && this.props.editCode;
  };

  componentDidMount() {
    this.nameInput.focus();
  }

  render() {
    const { employee, types, onSubmit, branches, loading } = this.props;

    const list = branches.map((branch) => {
      const code = branch.branch_id;
      const check = this.state.checkedList.filter((c) => {
        if (c.name === code) return c;
      });
      return (
        <Col span={12} key={branch.branch_id}>
          <Checkbox
            name={branch.branch_id.toString()}
            onChange={this.onChecked}
            checked={check[0].value}
          >
            {branch.branch_name}
          </Checkbox>
        </Col>
      );
    });

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    const formItemLayout2 = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div>
        <Spin size="large" spinning={loading}>
          <PageTitle name="employee_employees" component={null} />
          <Formik
            initialValues={employee}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              employee_code: yup.string().required("Code is a required field"),
              name: yup.string().required("Name is a required field"),
              phone: yup.string().required("Phone is a required field"),
              pin: yup
                .string()
                .matches(/\d/, "Pin must be a number")
                .matches(/^(\d{4})$/, "Must be exactly 4 digit")
                .required("Pin is a required field"),
              type_id: yup.string().required("Type is a required field"),
            })}
            onSubmit={(values) => onSubmit(values, this.state.checkedList)}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form onFinish={handleSubmit} autoComplete="off">
                  <fieldset disabled={!this.props.canEdit}>
                    <Row style={{ marginBottom: "20px" }} justify="center">
                      <Col
                        xs={12}
                        sm={12}
                        md={{ span: 4 }}
                        lg={{ span: 2, offset: 2 }}
                      >
                        { (values.image !== '' && this.state.is_upload === false) ? 
                        <Avatar size={80} src={`data:image/png;base64, ${values.image}`} /> : 
                        <Avatar
                          size={80}
                          icon={<UserOutlined />}
                          src={this.state.url ? this.state.url : ""}
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "5px",
                          }}
                        />
                        }
                        <input
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={(file) => {
                            if (file.target.files[0]) {
                              setFieldValue("image", file.target.files[0]);
                              this.setState({
                                is_upload: true,
                                url: URL.createObjectURL(file.target.files[0]),
                              });
                            } else {
                              setFieldValue("image", "");
                              this.setState({
                                is_upload: true,
                                url: "",
                              });
                            }
                          }}
                          onBlur={handleBlur}
                          className="form-control input-sm col-sm-4"
                          style={{ color: "gray" }}
                        /> 
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8}>
                        <FormItem
                          {...formItemLayout2}
                          label={<RequriedText label="Code" />}
                          validateStatus={
                            touched.employee_code &&
                            errors.employee_code &&
                            "error"
                          }
                          help={touched.employee_code && errors.employee_code}
                        >
                          <Input
                            ref={(input) => {
                              this.nameInput = input;
                            }}
                            name="employee_code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.employee_code}
                            disabled={this.isDisabled()}
                          />
                        </FormItem>
                        <FormItem
                          {...formItemLayout2}
                          label={<RequriedText label="Name" />}
                          validateStatus={
                            touched.name && errors.name && "error"
                          }
                          help={touched.name && errors.name}
                        >
                          <Input
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                        </FormItem>
                      </Col>
                    </Row>

                    <FormItem
                      {...formItemLayout}
                      label={<RequriedText label="Phone No." />}
                      validateStatus={touched.phone && errors.phone && "error"}
                      help={touched.phone && errors.phone}
                    >
                      <Input
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      />
                    </FormItem>
                    <FormItem
                      {...formItemLayout}
                      label={<RequriedText label="Pin" />}
                      validateStatus={touched.pin && errors.pin && "error"}
                      help={touched.pin && errors.pin}
                    >
                      <Input
                        name="pin"
                        placeholder="e.g. 0000"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pin}
                        autoComplete="new-password"
                      />
                    </FormItem>
                    <FormItem
                      {...formItemLayout}
                      label={<RequriedText label="Type" />}
                      validateStatus={
                        touched.type_id && errors.type_id && "error"
                      }
                      help={touched.type_id && errors.type_id}
                    >
                      <Select
                        placeholder="Select type"
                        onChange={(type) => setFieldValue("type_id", type)}
                        value={values.type_id}
                        disabled={!this.props.canEdit}
                      >
                        {types.map((type) => (
                          <Option
                            key={type.employee_type_id}
                            value={type.employee_type_id}
                          >
                            {type.type_name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                    <FormItem>
                      <Row>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 8 }}
                          style={{
                            paddingRight: "9px",
                            textAlign:
                            window.innerWidth <= 500 ? "left" : "right",
                            color: "rgba(0, 0, 0, 0.85)",
                            paddingBottom: window.innerWidth <= 500 ? "20px" : "0px",
                          }}
                        >
                          iPad display :
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 10 }}>
                          <Row>
                            <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                              <Checkbox
                                name="is_booking"
                                onChange={(e) =>
                                  setFieldValue("is_booking", e.target.checked)
                                }
                                checked={values.is_booking}
                              >
                                Show in Booking
                              </Checkbox>
                            </Col>
                            <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                              <Checkbox
                                name="is_assistant"
                                onChange={(e) =>
                                  setFieldValue(
                                    "is_assistant",
                                    e.target.checked
                                  )
                                }
                                checked={values.is_assistant}
                              >
                                Show in Assistant
                              </Checkbox>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                              <Checkbox
                                name="is_sales"
                                onChange={(e) =>
                                  setFieldValue("is_sales", e.target.checked)
                                }
                                checked={values.is_sales}
                              >
                                Show in Sales
                              </Checkbox>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </FormItem>

                    <SectionDivider label="Add Employee to branch" />

                    <FormItem>
                      <Row>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 8 }}
                          style={{
                            paddingRight: "9px",
                            textAlign: window.innerWidth <= 500 ? "left" : "right",
                            color: "rgba(0, 0, 0, 0.85)",
                            paddingBottom: window.innerWidth <= 500 ? "20px" : "0px",
                          }}
                        >
                          Branch :
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 10 }}>
                          <Row>{list}</Row>
                        </Col>
                      </Row>
                    </FormItem>

                    <FormBtn
                      url="/employee/employees"
                      canEdit={this.props.canEdit}
                    />
                  </fieldset>
                </Form>
              );
            }}
          />
        </Spin>
      </div>
    );
  }
}
