import { all, put, call, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { getErrorMsg } from "lib/fetch";

import * as actions from "./actions";

const Host = process.env.REACT_APP_API_URL;

function* login(action) {
  try {
    const { user } = action.payload;

    const res = yield call(axios.post, `${Host}/api/login`, {
      email: user.email,
      password: user.password
    });
    localStorage.setItem("token", res.data.token);

    const decoded = jwt_decode(res.data.token);
    localStorage.setItem("clientId", decoded.client_id);
    localStorage.setItem("username", decoded.name);
    localStorage.setItem("user_id", decoded.user_id);
    localStorage.setItem("menu", JSON.stringify(res.data.permission));
    localStorage.setItem('search', "")
    sessionStorage.clear();
    window.location.href = "/home"
  } catch (error) {
    yield put(actions.login.failure(getErrorMsg(error)));
    yield put(push("/signin"));
  }
}

function* logout(action) {
  try {
    yield call(
      axios.post,
      `${Host}/api/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    // localStorage.removeItem("token");
    localStorage.clear();
    sessionStorage.clear();
    yield put(push("/signin"));
  } catch (error) {
    sessionStorage.setItem("errMessage", error.response.data.message);
  }
}

export default function* watchAuth() {
  yield all([
    takeEvery(actions.LOGIN.REQUEST, login),
    takeEvery(actions.LOGOUT.REQUEST, logout)
  ]);
}
