import {
  LOAD_BRANCHES,
  LOAD_BRANCH,
  CREATE_BRANCH,
  EDIT_BRANCH,
  DELETE_BRANCH,
  SET_BRANCH,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  branch: {
    branch_code: "",
    branch_name: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    address5: "",
    address6: "",
    tax_id: "",
    logo: "",
    phone: "",
    fax: "",
    email: "",
    printer_type: "",
    printer_ip_address: "",
    qr_code: "",
    qr_description: "",
    free_text1: "",
    free_text2: "",
    free_text3: ""
  },
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  currentBranch: "",
  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BRANCH.REQUEST:
      return { ...state, currentBranch: action.payload.id };
    case LOAD_BRANCHES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_BRANCHES.SUCCESS:
      return {
        ...state,
        message: action.payload.branches.message,
        data: action.payload.branches.data,
        alertMessage: "",
        errorMessage: "",
        totalSize: action.payload.branches.total_row,

        errorImport: "",
        loading: false
      };
    case LOAD_BRANCH.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_BRANCH.SUCCESS:
      return {
        ...state,
        message: action.payload.branch.message,
        branch: action.payload.branch.data,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case LOAD_BRANCH.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };

    case CREATE_BRANCH.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case CREATE_BRANCH.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.branch.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_BRANCH.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_BRANCH.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_BRANCH.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.branch.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_BRANCH.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        errorDeleteMessage: ""
      };
    case DELETE_BRANCH.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_BRANCH.SUCCESS:
      const newBranches = state.data.filter(
        branch => branch.branch_id !== action.payload.branch.data.branch_id
      );
      return {
        ...state,
        data: newBranches,
        alertMessage: action.payload.branch.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_BRANCH.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.branch_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
