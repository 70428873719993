import {
  LOAD_BRANCH_TERMINALS,
  LOAD_TERMINAL,
  CREATE_TERMINAL,
  EDIT_TERMINAL,
  DELETE_TERMINAL,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  terminal: {
    terminal_code: "",
    terminal_name: "",
    register_id: "",
    branch_id: ""
  },
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BRANCH_TERMINALS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };
    case LOAD_BRANCH_TERMINALS.SUCCESS:
      return {
        ...state,
        data: action.payload.branchTerminals.data,
        totalSize: action.payload.branchTerminals.total_row,
        alertMessage: "",
        errorMessage: "",
        terminal: initialState.terminal,

        loading: false
      };
    case LOAD_BRANCH_TERMINALS.FAILURE:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: false
      };

    case LOAD_TERMINAL.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };
    case LOAD_TERMINAL.SUCCESS:
      return {
        ...state,
        terminal: action.payload.terminal.data,
        alertMessage: "",
        errorMessage: "",

        loading: false
      };
    case LOAD_TERMINAL.FAILURE:
      return {
        ...state,
        alertMessage: "",
        errorMessage: action.payload.error,

        loading: false
      };
    case CREATE_TERMINAL.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };
    case CREATE_TERMINAL.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.terminal.message,
        errorMessage: "",

        loading: false
      };
    case CREATE_TERMINAL.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        loading: false
      };
    case EDIT_TERMINAL.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };
    case EDIT_TERMINAL.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.terminal.message,
        errorMessage: "",

        loading: false
      };
    case EDIT_TERMINAL.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        loading: false
      };
    case DELETE_TERMINAL.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };
    case DELETE_TERMINAL.SUCCESS:
      const newTerminals = state.data.filter(
        terminal =>
          terminal.terminal_id !== action.payload.terminal.data.terminal_id
      );
      return {
        ...state,
        data: newTerminals,
        alertMessage: action.payload.terminal.message,
        totalSize: state.totalSize - 1,
        errorMessage: "",

        loading: false
      };
    case DELETE_TERMINAL.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.terminal_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };
    default:
      return state;
  }
};
