import { LOGIN } from "./actions";

const initialState = {
  message: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.REQUEST:
      return { ...state, message: "", loading: true };
    case LOGIN.SUCCESS:
    case LOGIN.FAILURE:
      return { message: action.payload.error, loading: false };
    default:
      return state;
  }
};
