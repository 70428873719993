import React, { Component, Fragment } from "react";
import { Drawer, Table, Col, Row, Descriptions, Typography } from "antd";
import { numberFormat, groupArray } from "lib/helper";

const { Text } = Typography;

const columns = [
  "Item",
  "Stylist",
  "Assistant / Sale",
  "Price",
  "Quantity",
  "Discount",
  "Sub Total",
];

export default class HistoryDrawer extends Component {
  documentDetail = (data, customer) => {
    return (
      <Fragment>
        <Descriptions
          title=""
          layout="vertical"
          //bordered
          size="small"
          column={4}
        >
          <Descriptions.Item label="Document ID">
            {data.document_id}
          </Descriptions.Item>
          <Descriptions.Item label="Document Date">
            {data.document_date}
          </Descriptions.Item>
          <Descriptions.Item label="Branch">
            {data.branch_name}
          </Descriptions.Item>
          <Descriptions.Item label="Cashier Name">
            {data.cashier_name}
          </Descriptions.Item>

          <Descriptions.Item label="Booking Time">
            {data.booking_time}
          </Descriptions.Item>
          <Descriptions.Item label="Stylish">{data.stylist}</Descriptions.Item>
          <Descriptions.Item label="Booking Type">
            {data.booking_type}
          </Descriptions.Item>
          <Descriptions.Item label="Service Duration">
            {data.duration}
          </Descriptions.Item>
        </Descriptions>
      </Fragment>
    );
  };

  headerComponent = () => {
    return (
      <thead>
        <tr>
          {columns.map((title, i) => {
            return (
              <th key={i} className={i > 2 ? "align-right" : ""}>
                {title}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  bodyComponent = (items, dataSource, data) => {
    return (
      <Fragment>
        {items.length > 0 &&
          Object.keys(dataSource).map((key, i) => {
            return (
              <Fragment>
                <tbody key={i}>
                  <tr>
                    <td
                      colSpan={7}
                      style={{ backgroundColor: "rgb(255, 228, 228)" }}
                    >
                      {this.getTitle(data, key)}
                    </td>
                  </tr>
                  {dataSource[key].map((data, idx) => {
                    return (
                      <tr key={idx} style={{ verticalAlign: "baseline" }}>
                        <td className="border-dot">
                          {data.barcode}
                          <br />
                          {data.service_name}
                        </td>
                        <td className="border-dot">{data.stylist}</td>
                        <td className="border-dot">{data.assistant}</td>
                        <td className="border-dot align-right">
                          {numberFormat(data.price)}
                        </td>
                        <td className="border-dot align-right">
                          {data.quantity}
                        </td>
                        <td className="border-dot align-right">
                          {numberFormat(data.discount)}
                          <br />
                          {data.discount_reason !== "" &&
                            ` reason :  ${data.discount_reason}  `}
                        </td>
                        <td className="border-dot align-right">
                          {numberFormat(data.sub_total)}
                        </td>
                      </tr>
                    );
                  })}
                  {this.footerComponent(data, key)}
                </tbody>
              </Fragment>
            );
          })}
      </Fragment>
    );
  };

  getTitle = (data, key) => {
    let amt = 0,
      title = key;
    switch (key) {
      case "Cash Package":
        amt = data.buy_cash_package;
        break;
      case "Package":
        amt = data.buy_packages;
        break;
      case "Product":
        amt = data.retails;
        title = "Retail";
        break;
      case "Service":
        amt = data.services;
        break;
      case "Use Package":
        amt = data.use_packages;
        break;
      default:
        break;
    }

    return (
      <Row justify="space-between">
        <Col span="6">
          <b>{title}</b>
        </Col>
        <Col span="6" className="align-right">
          <b>{amt}</b>
        </Col>
      </Row>
    );
  };

  footerComponent = (data, key) => {
    if (key === "Product") {
      return this.retailFooter(data);
    }

    if (key === "Service") {
      return this.serviceFooter(data);
    }

    if (key === "Package") {
      return this.buyPackageFooter(data);
    }

    if (key === "Cash Package") {
      return this.cashPackageFooter(data);
    }
  };

  serviceFooter = (data) => {
    return (
      <Fragment>
        <tr>
          <td colSpan={3} style={{ border: "none" }}>
            Service On-Top Discount Reason :{" "}
            {data.service_on_top_discount_reason}
          </td>
          <td colSpan={2} className="align-right" style={{ border: "none" }}>
            Before Discount
          </td>
          <td colSpan={2} className="align-right">
            {numberFormat(data.total_service_before_discount)}
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="align-right" style={{ border: "none" }}>
            On-Top Discount
          </td>
          <td colSpan={2} className="align-right">
            {numberFormat(data.service_on_top_discount_amount)}
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="align-right" style={{ border: "none" }}>
            Serivce Total
          </td>
          <td colSpan={2} style={{}} className="align-right">
            {numberFormat(data.total_service_after_discount)}
          </td>
        </tr>
      </Fragment>
    );
  };

  retailFooter = (data) => {
    return (
      <Fragment>
        <tr>
          <td colSpan={3} style={{ border: "none" }}>
            Retail On-Top Discount Reason : {data.retail_on_top_discount_reason}
          </td>
          <td colSpan={2} className="align-right" style={{ border: "none" }}>
            Before Discount
          </td>
          <td colSpan={2} className="align-right">
            {numberFormat(data.total_retail_before_discount)}
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="align-right" style={{ border: "none" }}>
            On-Top Discount
          </td>
          <td colSpan={2} className="align-right">
            {numberFormat(data.retail_on_top_discount_amount)}
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="align-right" style={{ border: "none" }}>
            Retail Total
          </td>
          <td colSpan={2} style={{}} className="align-right">
            {numberFormat(data.total_retail_after_discount)}
          </td>
        </tr>
      </Fragment>
    );
  };

  buyPackageFooter = (data) => {
    return (
      <Fragment>
        <tr>
          <td colSpan={5} className="align-right" style={{ border: "none" }}>
            Package Total
          </td>
          <td colSpan={2} className="align-right">
            {numberFormat(data.buy_package_total)}
          </td>
        </tr>
      </Fragment>
    );
  };

  cashPackageFooter = (data) => {
    return (
      <Fragment>
        <tr>
          <td colSpan={5} className="align-right" style={{ border: "none" }}>
            Total
          </td>
          <td colSpan={2} style={{}} className="align-right">
            {numberFormat(data.buy_cash_packages_total)}
          </td>
        </tr>
      </Fragment>
    );
  };

  paymentDetail = (data) => {
    const columns = [
      {
        title: "Type",
        dataIndex: "payment_type",
      },
      {
        title: "Amount",
        dataIndex: "payment_amount",
        className: "align-right",
        render: (val) => {
          return numberFormat(val);
        },
      },
    ];

    return (
      <Fragment>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Text className="ant-descriptions-title">Payments</Text>
          </Col>
        </Row>

        {typeof data.payment !== "undefined" && (
          <Table
            columns={columns}
            dataSource={data.payment}
            pagination={false}
            bordered
            size="small"
          />
        )}
      </Fragment>
    );
  };

  summaryDetail = (data) => {
    return (
      <Fragment>
        <Descriptions
          title="Summary"
          layout="vertical"
          bordered
          size="small"
          column={4}
        >
          <Descriptions.Item label="Notes" span={4}>
            {data.notes}
          </Descriptions.Item>
          <Descriptions.Item label="Grand Total">
            {numberFormat(data.grand_total)}
          </Descriptions.Item>
          <Descriptions.Item label="Receive">
            {numberFormat(data.receive)}
          </Descriptions.Item>
          <Descriptions.Item label="Change">
            {numberFormat(data.change)}
          </Descriptions.Item>
          <Descriptions.Item label="Get">{data.point} Point</Descriptions.Item>
        </Descriptions>
      </Fragment>
    );
  };

  render() {
    const { items, data, customer } = this.props;
    const dataSource = items.length > 0 ? groupArray(items, "type") : [];

    return (
      <Drawer
        width={window.innerWidth <= 1025 ? window.innerWidth : 850}
        title={`Transaction Details`}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>{this.documentDetail(data, customer)}</Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={24}>
            <table className="table-customer-transaction">
              {this.headerComponent()}
              {this.bodyComponent(items, dataSource, data)}
            </table>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={24}>{this.paymentDetail(data)}</Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={24}>{this.summaryDetail(data)}</Col>
        </Row>
      </Drawer>
    );
  }
}
