import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadBranchCategories(action) {
  try {
    const { branchId, pagination, limit, keyword, sort } = action.payload;
    const res = yield fetcher.post("masterdata/categories", {
      branch_id: branchId,
      pagination,
      limit,
      keyword,
      sort
    });
    yield put(actions.loadBranchCategories.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* editBranchHasCategory(action) {
  try {
    const { branch_id, categories } = action.payload;
    const res = yield fetcher.post(
      `branch_has/create/category/${branch_id}`,
      categories
    );
    yield put(actions.editBranchHasCategory.success(res.data));
    yield put(push("/branch/categories"));
  } catch (error) {
    handleError(error);
    yield put(actions.editBranchHasCategory.failure(getErrorMsg(error)));
    yield put(push("/branch/categories"));
  }
}

function* deleteBranchHasCategory(action) {
  try {
    const { id, branch_id } = action.payload;
    const res = yield fetcher.delete(`branch_has/delete/category/${branch_id}/${id}`)
    yield put(actions.deleteBranchHasCategory.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteBranchHasCategory.failure(getErrorMsg(error)));
  }
}

function* importBranchHasCategory(action) {
  try {
    const { file, pagination, limit, keyword, branchId } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", "productCategory");
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    formData.append("branch_id", branchId);
    const res = yield fetcher.post('import/details', formData)
    yield put(actions.importBranchHasCategory.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importBranchHasCategory.failure(getErrorMsg(error)));
    yield put(push("/branch/categories"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post(`export/branch_has/category`, { branch_id: action.payload.branch_id })
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/branch_has/category`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchBranchCategories() {
  yield all([
    takeEvery(actions.LOAD_BRANCH_CATEGORIES.REQUEST, loadBranchCategories),
    takeEvery(
      actions.IMPORT_BRANCH_HAS_CATEGORY.REQUEST,
      importBranchHasCategory
    ),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.EDIT_BRANCH_HAS_CATEGORY.REQUEST, editBranchHasCategory),
    takeEvery(
      actions.DELETE_BRANCH_HAS_CATEGORY.REQUEST,
      deleteBranchHasCategory
    ),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
