import { fork } from "redux-saga/effects";

import watchDistributors from "./components/distributor/sagas";
import watchProducts from "./components/product/sagas";
import watchBrands from "./components/product_brand/sagas";
import watchCategories from "./components/product_category/sagas";
import watchUnits from "./components/product_unit/sagas";

const watchProduct = [
  fork(watchDistributors),
  fork(watchProducts),
  fork(watchBrands),
  fork(watchCategories),
  fork(watchUnits)
];

export { watchProduct };
