import React from "react";
import { Breadcrumb } from "antd";
import { getBreadcrumbTitle, getActionMenu } from 'lib/pagefn'
import { HomeOutlined } from "@ant-design/icons";

const Navigator = () => {
  const url = window.location.pathname;

  let page = getBreadcrumbTitle(url.split("/")),
    actions = getActionMenu(url);

  if (url.match(/detail/g)) {
    let detailUrl = url.replace(/\/new/g, '')
    return (
      <Breadcrumb separator=">" style={{ margin: "24px 16px 0px 16px" }}>
        <Breadcrumb.Item>
        <HomeOutlined />
          &nbsp;&nbsp;{page.title}
        </Breadcrumb.Item>
        <Breadcrumb.Item href={`/${page.subtitle.url}`}>{page.subtitle.title}</Breadcrumb.Item>
        <Breadcrumb.Item href={`${detailUrl}`}>Detail</Breadcrumb.Item>
        {actions !== "" && <Breadcrumb.Item>{actions}</Breadcrumb.Item>}
      </Breadcrumb>
    );
  }
  return (
    <Breadcrumb separator=">" style={{ margin: "24px 16px 0px 16px" }}>
      <Breadcrumb.Item>
      <HomeOutlined />
        &nbsp;&nbsp;{page.title}
      </Breadcrumb.Item>
      <Breadcrumb.Item href={`/${page.subtitle.url}`}>{page.subtitle.title}</Breadcrumb.Item>
      {actions !== "" && <Breadcrumb.Item>{actions}</Breadcrumb.Item>}
    </Breadcrumb>
  );
};

export default Navigator;
