import React, { Component } from 'react'
import { Table, Spin } from 'antd';
import { TransferCustomer } from "lib/fetch"

export default class ServicePackageTable extends Component {
    state = {
        lists: [],
        loading: false
    }

    handleChange = (selectedRowKeys, selectedRows) => {
        this.props.onChange(selectedRowKeys)
    }

    componentDidUpdate(prevProps, prevState) {
        const { customerId, isShowList } = this.props
        if (!isShowList || customerId === "") {
            return
        }

        if (prevProps.isShowList != isShowList || prevProps.customerId !== customerId) {
            this.setState({ loading: true })
            TransferCustomer.getServicePackage({ customer_id: customerId }).then(res => {
                this.setState({ lists: res.data.data, loading: false })
            })
        }
    }

    render() {

        const columns = [
            {
                title: 'Barcode',
                dataIndex: 'barcode',
            },
            {
                title: 'Package Name',
                dataIndex: 'service_package_name',
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
            },
        ];

        return (
            <Spin spinning={this.state.loading}>
                <Table
                    size="small"
                    rowKey={(record) => record.customer_has_package_id.toString()}
                    rowSelection={{
                        type: "checkbox",
                        onChange: this.handleChange,
                    }}
                    columns={columns}
                    dataSource={this.state.lists}
                />
            </Spin>
        )
    }
}

