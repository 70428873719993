import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  setBranchFilter,
  getBranchFilter,
  uniqueArray,
  extractKeyFromArray,
} from "lib/helper";
import {
  ImportExportBtn,
  UploadModal,
  AddBtn,
  ListAction,
  DeleteListModal,
  DeleteBadge,
  FilterBtn,
  TagFilter,
  PageTitle,
} from "lib/widget";

import {
  Table,
  Row,
  Col,
  message,
  Spin,
  notification,
  Badge,
  Typography,
} from "antd";

import BranchSelect from "../../BranchSelect";
import Filter from "./Filter";

const { Text } = Typography;

class BranchHasServiceList extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage, errorMessage } = nextProps.branchActualServices;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
    branchId: null,
    cond: "",
  };

  fetchData = () => {
    const { branchId, page, limit, sorter, cond } = this.state;
    setSorter(sorter);
    setSearch(cond);
    this.props.loadBranchActualServices(
      branchId,
      page,
      limit,
      cond,
      sorter
    );
  };

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState(
      { page: current, limit: pageSize, sorter: { order, field } },
      this.fetchData
    );
  };

  handleSearch = (val, filter) => {
    this.setState({ page: 1, visible: false, keyword: val.keyword, cond: val }, this.fetchData);
    this.getFilterTag(filter, val.is_show, val.keyword);
  };

  getFilterTag = (filter, isShow, keyword) => {
    let type = "",
      is_show = "",
      word = "";

    if (isShow === "0") is_show = "Not display on iPad";
    if (isShow === "1") is_show = "Display on iPad";

    if (keyword !== "") word = `${keyword}`;

    Object.keys(filter).map((key) => {
      filter[key].map((val) => {
        if (key === "type")
          type += (type !== "" ? ", " : "") + val.actual_service_type;
      });
    });

    this.setState({
      typeTag: type !== "" ? `${type}` : type,
      isShowTag: is_show,
      keywordTag: word,
    });

    setFilterTag({
      typeTag: type !== "" ? `${type}` : type,
      isShowTag: is_show,
      keywordTag: word,
    })
  };

  componentDidMount() {
    let filter = getFilterTag();

    if (typeof filter === "object") {
      let branchId = getBranchFilter() !== null ? getBranchFilter() : [parseInt(localStorage.getItem("defaultBranchId"))]
      this.setState(
        {
          ...filter,
          cond: getSearch(),
          sorter: getSorter(),
          branchId: branchId,
        },
        this.fetchData
      );
    } else {
      this.setState(
        {
          keyword: getSearch(),
          branchId: [parseInt(localStorage.getItem("defaultBranchId"))],
        },
        this.fetchData
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage } = this.props.branchActualServices;
    const prevMsg = prevProps.branchActualServices;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      this.openNotification(errorMessage);
    }
  }

  openNotification = (message) => {
    notification.error({
      message: "Import fail",
      description: message,
      duration: 0,
    });
  };

  showModalImport = () => {
    this.setState({
      visibleImport: true,
    });
  };

  handleCancel = () => {
    this.setState({ visibleImport: false });
  };

  onChooseFile = ({ file }) => {
    if (file.status !== "removed") {
      this.setState({ file, fileList: [file] });
    }
  };

  onRemove = () => this.setState({ fileList: [], file: null });

  handleUpload = () => {
    const { page, limit, keyword, file } = this.state;
    this.props.importBranchHasActualService(
      file,
      "branchHasService",
      page,
      limit,
      keyword,
      this.state.branchId
    );
    this.setState({ file: null, fileList: [] });
  };

  handleDelete = (id, branch_id) => () => {
    this.props.deleteBranchHasService(id, branch_id);
  };

  handleDownload = () => {
    this.props.download(this.state.branchId);
  };

  handleChangeBranch = (value) => {
    setBranchFilter(value)
    if (value.length > 0) {
      this.setState({ branchId: value }, this.fetchData);
    } else {
      this.setState({ branchId: value });
    }
  };

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.branchActualServices.data.filter((x) =>
        selectedRowKeys.includes(x.branch_has_actualservice_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.branch_has_actualservice_id)
      );
      items = uniqueArray(
        [...removeState, ...removeData],
        "branch_has_actualservice_id"
      );
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "branch_has_actualservice_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter(
      (x) => x.branch_has_actualservice_id !== id
    );
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { selectedRowKeys, keywordTag, isShowTag, typeTag, sorter } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const { branchActualServices } = this.props;
    let disabled = false;
    let disabledAdd = true;
    let source = branchActualServices.data;
    let totalSize = this.props.branchActualServices.totalSize;

    if (this.state.branchId !== null) {
      disabled = this.state.branchId.length === 0 ? true : false;
      disabledAdd = this.state.branchId.length === 1 ? false : true;
      if (this.state.branchId.length === 0) {
        source = [];
        totalSize = 0;
      }
    }

    const actionCol = [
      {
        title: "",
        className: "column-action",
        width: window.innerWidth <= 1025 ? 40 : 105,
        fixed: "left",
        render: (data) => (
          <ListAction
            editUrl={false}
            onDelete={this.handleDelete(data.actual_service_id, data.branch_id)}
            id={data.actual_service_id}
            name="branch_actual_services"
          />
        ),
      },
    ];

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const mainColumns = [
      {
        title: "Branch Name",
        width: 150,
        dataIndex: "branch_name",
      },
      {
        title: "Barcode",
        width: 150,
        sorter: true,
        dataIndex: "barcode",
        sortOrder: sorter !== null && sorter.field === 'barcode' ? sorter.order : false,
        render: (text, record) => {
          if (record.is_show === 1) {
            return <Badge status="success" text={text} />;
          } else {
            return <Badge status="default" text={text} />;
          }
        },
      },
      {
        title: "Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'service_name' ? sorter.order : false,
        dataIndex: "service_name",
      },
      {
        title: "Type",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'actual_service_type' ? sorter.order : false,
        dataIndex: "actual_service_type",
      },
    ];

    const columns =
      window.innerWidth <= 1025
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    const actionComp = (
      <Fragment>
        <DeleteBadge
          count={this.state.removeData.length}
          onClick={this.showDrawer("visibleDelete")}
        />
        <Col xs={13} sm={13} md={8} lg={6} className="import-btn-align">
          <ImportExportBtn
            disabledDownload={disabled}
            showModalImport={this.showModalImport}
            download={this.handleDownload}
            name="branch_actual_services"
          />
        </Col>
      </Fragment>
    );

    return (
      <div>
        <Spin size="large" spinning={this.props.branchActualServices.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              name="branch_actual_services"
              component={
                <AddBtn
                  url={`/branch/actual-services/edit/${this.state.branchId}`}
                  name="branch_actual_services"
                  disabled={disabledAdd}
                />
              }
            />
            <Row gutter={[16, 16]}>
              {window.innerWidth > 500 && actionComp}
              <Col
                xs={24}
                sm={24}
                md={{ span: 7, offset: 3 }}
                lg={{ span: 6, offset: 8 }}
              >
                <BranchSelect
                  branchId={this.state.branchId}
                  branches={this.props.branches}
                  onChange={this.handleChangeBranch}
                />
              </Col>
              {window.innerWidth <= 500 && actionComp}
              <FilterBtn showDrawer={this.showDrawer} />
              <Filter
                list={source}
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col xs={19} sm={19} md={21} lg={22}>
                {TagFilter([keywordTag], "Keyword", "green")}
                {TagFilter([isShowTag], "Status", "cyan")}
                {TagFilter([typeTag], "Type", "orange")}
              </Col>
            </Row>

            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={[
                "branch_has_actualservice_id",
                "branch_name",
                "service_name",
              ]}
            />
          </div>
          <Table
            rowKey={(record) => record.branch_has_actualservice_id}
            bordered={true}
            size="small"
            columns={columns}
            rowSelection={rowSelection}
            dataSource={source}
            pagination={{
              pageSize: this.state.limit,
              total: totalSize,
              current: this.state.page,
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 500 ? { x: 1000 } : undefined}
          />

          <UploadModal
            visibleImport={this.state.visibleImport}
            handleCancel={this.handleCancel}
            loading={this.props.branchActualServices.loading}
            onChooseFile={this.onChooseFile}
            fileList={this.state.fileList}
            onRemove={this.onRemove}
            handleUpload={this.handleUpload}
            uploading={this.state.uploading}
            disabled={this.state.file ? false : true}
          />
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = ({ branchActualServices, branches }) => ({
  branchActualServices,
  branches: branches.data,
});

const mapDispatchToProps = {
  loadBranchActualServices: actions.loadBranchActualServices.request,
  importBranchHasActualService: actions.importBranchHasActualService.request,
  download: actions.download.request,
  deleteBranchHasService: actions.deleteBranchHasService.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchHasServiceList);
