import React, { useState, useEffect } from "react";
import { AutoComplete as AntAutoComplete, Spin } from "antd";
import { fetcher } from "lib/fetch";
import debounce from "lodash/debounce";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 21, left: "91%" }} spin />;

const AutoComplete = ({
  options,
  searchCompnent,
  onSelect,
  onSearch,
  type,
  value,
  keyName,
  width = 200,
  splitValue = true,
  keyId,
  placeholder,
  categoryId,
}) => {
  const [option, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatVal = (option) => {
    let valueOption = [];
    option.map((data) => {
      let label =
        keyName[0] === keyName[1]
          ? data[keyName[1]]
          : `${data[keyName[0]]} - ${data[keyName[1]]}`;
      return valueOption.push({ value: data[keyId].toString(), label: label, all: data });
    });
    return valueOption;
  };

  const handleSearch = (searchText) => {
    let filter = options.filter((x) => {
      let rx = new RegExp(searchText, "gi");
      return x[keyName[0]].match(rx) || x[keyName[1]].match(rx);
    });

    if (filter.length > 0) {
      setOptions(!searchText ? [] : formatVal(filter));
    } else {
      setLoading(true);
      delaySearchData(searchText);
    }
  };

  const delaySearchData = debounce(async (searchText) => {
    let params = { keyword: searchText };
    if (categoryId != null && categoryId > 0) {
      params.category_id = categoryId;
    }
    fetcher[type](params).then((res) => {
      setLoading(false);
      setOptions(formatVal(res.data.data));
    });
  }, 750);

  const handleSelect = (value, label) => {
    if (typeof label.label !== "undefined") {
      if (splitValue) {
        let val = label.label.split(" - ");
        let opt = option.find(x => x.value == value)
        let all = {}
        if (typeof opt !== "undefined") {
          all = opt.all
        }
        onSelect(value, val.length === 1 ? val[0] : val[1], all);
      } else {
        onSelect(value, label.label, label);
      }
    }
  };

  const onChange = (value, label) => {
    if (typeof onSearch === "function") {
      if (typeof label.label !== "undefined") {
        if (splitValue) {
          let val = label.label.split(" - ");
          onSearch(val.length === 1 ? val[0] : val[1]);
        } else {
          onSearch(value, label.label);
        }
      }
    }
  };

  useEffect(() => {
    setOptions()
  }, [categoryId])

  return (
    <Spin spinning={loading} indicator={antIcon}>
      <AntAutoComplete
        value={value}
        placeholder={placeholder}
        dropdownMatchSelectWidth={300}
        options={option}
        style={{
          width: width,
        }}
        onSelect={handleSelect}
        onSearch={handleSearch}
        onChange={onChange}
      >
        {searchCompnent}
      </AntAutoComplete>
    </Spin>
  );
};

AutoComplete.defaultProps = {
  placeholder: "",
  categoryId: null,
};

export default AutoComplete;

