import { Form, Input, Row, Col, message, Checkbox, Spin } from "antd";
import React, { PureComponent } from "react";
import { Formik } from "formik";
import * as yup from "yup";

import UserTransfer from "./UserTransfer";
import PermissionTable from "./PermissionTable";
import { getIdtoArray } from "lib/helper";
import SelectPermGroup from "./SelectPermGroup";

import { PageTitle, FormBtn, RequriedText } from "lib/widget";

const FormItem = Form.Item;

export default class UserGroupForm extends PureComponent {
  state = {
    permGroup: "",
    removeGroup: [],
    isAll: null
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage } = nextProps;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage } = this.props;

    if (alertMessage !== "" && prevProps.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  componentDidMount() {
    this.nameInput.focus(); 
  }


  onChecked = (name, setFieldValue) => e => {
    if (e.target.checked) setFieldValue(name, 1);
    else setFieldValue(name, -1);
  };

  checkTransferComponent = (
    values,
    setFieldValue,
  ) => {
    return (
      <Col span={24}>
        <UserTransfer setFieldValue={setFieldValue} users={values.users} />
      </Col>
    );
  };

  createFormValidation = () => {
    return yup.object().shape({
      usergroup_name: yup.string().required("Name is a required field")
    });
  };

  validValue(value, name, data) {
    if (typeof data[name] !== "undefined") {
      return data[name];
    }
    return value;
  }

  getPermissionValue = data => {
    let choosePerm = Object.keys(data)
      .filter(key => key.match(/can/g))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    delete data["permissions"];

    let res = Object.assign({}, choosePerm);
    if (typeof data["permission_table_state"] !== "undefined") {
      res = Object.assign({}, data["permission_table_state"], choosePerm);
      delete data["permission_table_state"];
    }

    return res;
  };

  onSelectChange = value => {
    this.setState({ permGroup: value });
  };

  render() {
    const { usergroup, onSubmit, loading } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      }
    };

    return (
      <div>
        <Spin size="large" spinning={loading}>
          <PageTitle name="authorization_usergroups" component={null} />
          <Formik
            initialValues={usergroup}
            enableReinitialize={true}
            validationSchema={this.createFormValidation()}
            onSubmit={values => {
              if (typeof values["group_users"] !== "undefined")
                values["users"] = values["group_users"];
              else values["users"] = getIdtoArray(values["users"], "client_id");

              values["permission"] = this.getPermissionValue(values);
              onSubmit(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => {
              return (
                <Form onFinish={handleSubmit} autoComplete="off">
                  <fieldset disabled={!this.props.canEdit}>
                    <FormItem
                      {...formItemLayout}
                      label={<RequriedText label="Name"/>}
                      validateStatus={
                        touched.usergroup_name &&
                        errors.usergroup_name &&
                        "error"
                      }
                      help={touched.usergroup_name && errors.usergroup_name}
                    >
                      <Input
                        ref={(input) => { this.nameInput = input; }} 
                        name="usergroup_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.usergroup_name}
                      />
                    </FormItem>
                    <FormItem {...formItemLayout} label={<RequriedText label="Code" />}>
                      <Input
                        name="usergroup_code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.usergroup_code}
                      />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Description">
                      <Input
                        placeholder="Description"
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                    </FormItem>

                    <FormItem>
                      <Row>
                        <Col
                          xs={{ span: 5 }}
                          sm={{ span: 8 }}
                          style={{
                            paddingRight: "9px",
                            textAlign: window.innerWidth <= 500 ? "left" : "right",
                            color: "rgba(0, 0, 0, 0.85)"
                          }}
                        >
                          Status :
                        </Col>
                        <Col xs={{ span: 14 }} sm={{ span: 10 }}>
                          <Row>
                            <Checkbox
                              name="active"
                              checked={values.active === 1}
                              value={values.active}
                              onChange={this.onChecked("active", setFieldValue)}
                            >
                              Active
                            </Checkbox>
                          </Row>
                        </Col>
                      </Row>
                    </FormItem>

                    <FormItem
                      validateStatus={touched.users && errors.users && "error"}
                      help={touched.users && errors.users}
                    >
                      <Row>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          style={{ color: "rgba(0, 0, 0, 0.85)" }}
                        >
                          <RequriedText label="Client:" />
                        </Col>
                      </Row>
                    </FormItem>
                    <FormItem>
                      <Col xs={{ span: 24 }} sm={{ span: 16, offset: 6 }}>
                        {this.checkTransferComponent(
                          values,
                          handleChange,
                          setFieldValue,
                          touched,
                          errors
                        )}
                      </Col>
                    </FormItem>

                    <FormItem
                      validateStatus={
                        touched.permission && errors.permission && "error"
                      }
                      help={touched.permission && errors.permission}
                    >
                      <Row>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 8 }}
                          style={{ color: "rgba(0, 0, 0, 0.85)" }}
                        >
                          <RequriedText label="Permission:" />
                        </Col>
                      </Row>
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label="Select From Permission Groups"
                    >
                      <SelectPermGroup
                        onChange={this.onSelectChange}
                        selectGroup={values.permissions}
                      />
                    </FormItem>

                    <FormItem>
                      <PermissionTable
                        setFieldValue={setFieldValue}
                        permGroup={this.state.permGroup}
                        removeGroup={this.state.removeGroup}
                        groupPermission={values.permissions}
                        userGroupId={values.usergroup_id}
                      />
                    </FormItem>

                    <FormBtn
                      url="/auth/usergroups"
                      canEdit={this.props.canEdit}
                    />
                  </fieldset>
                </Form>
              );
            }}
          />
        </Spin>
      </div>
    );
  }
}
