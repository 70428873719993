import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";

import BranchForm from "./BranchForm";

const branch = {
  branch_code: "",
  branch_name: "",
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  address5: "",
  address6: "",
  tax_id: "",
  logo: "",
  phone: "",
  fax: "",
  email: "",
  printer_type: "",
  printer_ip_address: "",
  qr_code: "",
  qr_description: "",
  free_text1: "",
  free_text2: "",
  free_text3: ""
};

class NewBranch extends Component {
  createBranch = async (branch, is_upload) => {
    this.props.createBranch(branch);
  };

  render() {
    const { alertMessage, errorMessage, loading } = this.props;
    return (
      <BranchForm
        onSubmit={this.createBranch}
        branch={branch}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ branches }) => {
  return {
    alertMessage: branches.alertMessage,
    errorMessage: branches.errorMessage,
    loading: branches.loading
  };
};

const mapDispatchToProps = {
  createBranch: actions.createBranch.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBranch);
