import {
  LOAD_USERGROUPS,
  LOAD_USERGROUP,
  CREATE_USERGROUP,
  EDIT_USERGROUP,
  DELETE_USERGROUP,
  DESTROY_MANY
} from "./actions";

const initialState = {
  data: [],
  usergroup: {
    usergroup_id: "",
    usergroup_name: "",
    usergroup_code: "",
    description: "",
    active: 1,
    permissions: [],
    users: []
  },
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERGROUPS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case LOAD_USERGROUPS.SUCCESS:
      return {
        ...state,
        data: action.payload.usergroups.data,
        totalSize: action.payload.usergroups.total_row,
        alertMessage: "",
        errorMessage: "",
        usergroup: initialState.usergroup,
        loading: false
      };
    case LOAD_USERGROUPS.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };

    case LOAD_USERGROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case LOAD_USERGROUP.SUCCESS:
      return {
        ...state,
        usergroup: action.payload.usergroup.data,
        alertMessage: "",
        errorMessage: "",
        loading: false
      };
    case LOAD_USERGROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };

    case CREATE_USERGROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case CREATE_USERGROUP.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.usergroups.message,
        errorMessage: "",
        loading: false
      };
    case CREATE_USERGROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };

    case EDIT_USERGROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case EDIT_USERGROUP.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.usergroup.message,
        errorMessage: "",
        loading: false
      };
    case EDIT_USERGROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };

    case DELETE_USERGROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DELETE_USERGROUP.SUCCESS:
      const newCashPackages = state.data.filter(
        usergroup =>
          usergroup.usergroup_id !== action.payload.usergroup.data.usergroup_id
      );
      return {
        ...state,
        data: newCashPackages,
        alertMessage: action.payload.usergroup.message,
        totalSize: state.totalSize - 1,
        loading: false
      };
    case DELETE_USERGROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.usergroup_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
