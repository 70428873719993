import { all, put, takeEvery } from "redux-saga/effects";
import { fetcher, logErrorMsg, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadCustomerPackages(action) {
  try {
    const { pagination, limit, cond, sort } = action.payload;
    const res = yield fetcher.post("masterdata/customer_has_packages", {
      pagination,
      limit,
      cond,
      sort
    });
    yield put(actions.loadCustomerPackages.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadCustomerPackages.failure(getErrorMsg(error)));
    logErrorMsg(error);
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/export_masterdata", {type : 'customerPackages'});
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

export default function* watchCustomerPackage() {
  yield all([
    takeEvery(actions.LOAD_CUSTOMER_PACKAGES.REQUEST, loadCustomerPackages),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download)
  ]);
}
