import { Form, Input, Select, Spin, message } from "antd";
import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { PageTitle, FormBtn, RequriedText } from "lib/widget";

const FormItem = Form.Item;
const Option = Select.Option;

export default class TerminalForm extends Component {

  componentDidMount() {
    this.nameInput.focus();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  isDisabled = () => {
    return this.props.terminal.terminal_code !== '' && this.props.editCode
  }

  render() {
    const { terminal, onSubmit, branches } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      }
    };

    return (
      <Spin size="large" spinning={this.props.loading}>
        <PageTitle name="branch_terminals" component={null} />
        <Formik
          initialValues={terminal}
          enableReinitialize={true}
          validationSchema={yup.object().shape({
            terminal_code: yup.string().required("Code is a required field"),
            terminal_name: yup.string().required("Name is a required field"),
            branch_id: yup.string().required("Branch is a required field")
          })}
          onSubmit={values => onSubmit(values)}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => (
            <Form onFinish={handleSubmit} autoComplete="off">
              <fieldset disabled={!this.props.canEdit}>
                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Terminal code" />}
                  validateStatus={
                    touched.terminal_code && errors.terminal_code && "error"
                  }
                  help={touched.terminal_code && errors.terminal_code}
                >
                  <Input
                    name="terminal_code"
                    ref={(input) => { this.nameInput = input; }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.terminal_code}
                    disabled={this.isDisabled()}
                  />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Terminal name" />}
                  validateStatus={
                    touched.terminal_name && errors.terminal_name && "error"
                  }
                  help={touched.terminal_name && errors.terminal_name}
                >
                  <Input
                    name="terminal_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.terminal_name}
                  />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label="Register ID"
                  validateStatus={
                    touched.register_id && errors.register_id && "error"
                  }
                  help={touched.register_id && errors.register_id}
                >
                  <Input
                    name="register_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.register_id}
                  />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Branch" />}
                  validateStatus={
                    touched.branch_id && errors.branch_id && "error"
                  }
                  help={touched.branch_id && errors.branch_id}
                >
                  <Select
                    placeholder="Select a branch"
                    onChange={branch => setFieldValue("branch_id", branch)}
                    value={values.branch_id}
                    disabled={this.isDisabled()}
                  >
                    {branches.map(branch => (
                      <Option value={branch.branch_id}>
                        {branch.branch_name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormBtn url="/branch/terminals" canEdit={this.props.canEdit} />
              </fieldset>
            </Form>
          )}
        />
      </Spin>
    );
  }
}
