import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Col, Card, Spin, Row } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { numberFormat } from "lib/helper";
import styled from "styled-components";

const Number = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const Increase = styled.span`
  color: #00e815;
  padding-right: inherit;
  font-size: 12px;
  font-weight: bold;
`;

const Decrease = styled.span`
  color: #c90400;
  padding-right: inherit;
  font-size: 12px;
  font-weight: bold;
`;

export class Widget extends Component {
  handleUndefined = (val, key) => {
    return typeof val === "undefined" ? 0 : val[key];
  };

  getCardType = (data) => {
    let total = this.handleUndefined(data.total, "data"),
      total_c = this.handleUndefined(data.total, "compared"),
      newcus = this.handleUndefined(data.new_customer, "data"),
      newcus_c = this.handleUndefined(data.new_customer, "compared"),
      revenue = this.handleUndefined(data.revenue, "data"),
      revenue_c = this.handleUndefined(data.revenue, "compared"),
      avg = this.handleUndefined(data.average, "data"),
      avg_c = this.handleUndefined(data.average, "compared");

    const cardType = [
      {
        name: "Total customers",
        value: numberFormat(total, 0),
        diff: total_c,
        classname: "blue-gradient",
      },
      {
        name: "New customers of the month",
        value: numberFormat(newcus, 0),
        diff: newcus_c,
        classname: "blue-gradient",
      },
      {
        name: "Revenue",
        value: numberFormat(revenue),
        diff: revenue_c,
        classname: "blue-gradient",
      },
      {
        name: "Avg. revenue per customer",
        value: numberFormat(avg),
        diff: avg_c,
        classname: "orange-gradient",
      },
    ];
    return cardType;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.branches !== this.props.branches) {
      this.props.loadCustomerSummary(this.props.branches);
    }
  }

  componentDidMount() {
    if (this.props.branches.length > 0) {
      this.props.loadCustomerSummary(this.props.branches);
    }
  }
  render() {
    const { summary, loading_summary } = this.props;
    const cardType = this.getCardType(summary);

    return (
      <Fragment>
        {cardType.map((data, i) => (
          <Col key={data.name} span={5}>
            <Card
              style={{
                marginBottom: 10,
                border: "1px solid #d9d9d9",
              }}
              bodyStyle={{ padding: "14px" }}
            >
              <Spin spinning={loading_summary}>
                <Row gutter={[8, 8]} justify="end">
                  <Col span={24}>{data.name}</Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Number>{data.value}</Number>
                    <br />
                    {data.diff < 0 ? (
                      <Decrease>
                        <ArrowDownOutlined />
                        {numberFormat(data.diff * -1)} %
                      </Decrease>
                    ) : (
                      <Increase>
                        <ArrowUpOutlined /> {numberFormat(data.diff)} %
                      </Increase>
                    )}
                    than last month
                  </Col>
                </Row>
              </Spin>
            </Card>
          </Col>
        ))}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ customer_analytic }) => ({
  loading_summary: customer_analytic.loading_summary,
  summary: customer_analytic.summary,
});

const mapDispatchToProps = {
  loadCustomerSummary: actions.loadCustomerSummary.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
