import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  setBranchFilter,
  getBranchFilter,
  uniqueArray2key,
  extractKeyFromArray,
  checkPermission
} from "lib/helper";

import {
  ImportExportBtn,
  UploadModal,
  AddBtn,
  ListAction,
  DeleteListModal,
  DeleteBadge,
  FilterBtn,
  TagFilter,
  PageTitle,
} from "lib/widget";

import {
  Table,
  Row,
  Col,
  message,
  Spin,
  notification,
  Badge,
  Typography,
  Checkbox,
  Divider,
  Button
} from "antd";
import { CaretDownFilled } from "@ant-design/icons";

import BranchSelect from "../../BranchSelect";
import Filter from "./Filter";
import { Fragment } from "react";
import StockModal from "./StockModal"
import { TransferStock, getErrorMsg } from "lib/fetch";

const { Text } = Typography;
class BranchHasProductList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage, errorMessage } = nextProps.branchProducts;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    cond: "",
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
    visibleStock: false,
    selectData: [],
    branchId: [parseInt(localStorage.getItem("defaultBranchId"))],
  };

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState(
      { page: current, limit: pageSize, sorter: { order, field } },
      this.fetchData
    );
  };

  fetchData = () => {
    const { branchId, page, limit, sorter, cond } = this.state;
    setSorter(sorter);
    setSearch(cond);
    this.props.loadBranchProducts(branchId, page, limit, cond, sorter);
  };

  handleChangeBranch = (value) => {
    setBranchFilter(value)
    if (value.length > 0) {
      this.setState({ branchId: value }, this.fetchData);
    } else {
      this.setState({ branchId: value });
    }
  };

  handleSearch = (val, filter) => {
    this.setState(
      { page: 1, visible: false, keyword: val.keyword, cond: val },
      this.fetchData
    );
    this.getFilterTag(filter, val.is_show, val.keyword);
  };

  getFilterTag = (filter, isShow, keyword) => {
    let brand = "",
      category = "",
      is_show = "",
      word = "";

    if (isShow === "0") is_show = "Not display on iPad";
    if (isShow === "1") is_show = "Display on iPad";

    if (keyword !== "") word = `${keyword}`;

    Object.keys(filter).map((key) => {
      filter[key].map((val) => {
        if (key === "brand")
          brand += (brand !== "" ? ", " : "") + val.product_brand;
        if (key === "category")
          category += (category !== "" ? ", " : "") + val.category_name;
      });
    });

    this.setState({
      brandTag: brand !== "" ? `${brand}` : "",
      cateTag: category !== "" ? `${category}` : "",
      isShowTag: is_show,
      keywordTag: word,
    });

    setFilterTag({
      brandTag: brand !== "" ? `${brand}` : "",
      cateTag: category !== "" ? `${category}` : "",
      isShowTag: is_show,
      keywordTag: word,
    })
  };

  filterOnlyLowStock = (e) => {
    let { cond } = this.state;
    if (e.target.checked) {
      if (typeof cond === "object" && cond !== null) cond["low_stock"] = true;
      else cond = { low_stock: true };
    } else {
      if (typeof cond === "object") cond["low_stock"] = false;
      else cond = { low_stock: false };
    }
    this.setState({ cond: cond }, this.fetchData);
  };

  componentDidMount() {
    let filter = getFilterTag();
    if (typeof filter === "object") {
      let branchId = getBranchFilter() !== null ? getBranchFilter() : [parseInt(localStorage.getItem("defaultBranchId"))]
      this.setState(
        {
          ...filter,
          cond: getSearch(),
          sorter: getSorter(),
          branchId: branchId,
        },
        this.fetchData
      );
    } else {
      this.setState(
        {
          keyword: getSearch(),
          branchId: [parseInt(localStorage.getItem("defaultBranchId"))],
        },
        this.fetchData
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage } = this.props.branchProducts;
    const prevMsg = prevProps.branchProducts;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false, removeData: [] });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      this.openNotification(errorMessage);
    }
  }

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.branchProducts.data.filter((x) =>
        selectedRowKeys.includes(`${x.branch_id}_${x.product_id}`)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(`${x.branch_id}_${x.product_id}`)
      );
      items = uniqueArray2key(
        [...removeState, ...removeData],
        "branch_id",
        "product_id"
      );
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  openNotification = (message) => {
    notification.error({
      message: "Import fail",
      description: message,
      duration: 0,
    });
  };

  onChooseFile = ({ file }) => {
    if (file.status !== "removed") {
      this.setState({ file, fileList: [file] });
    }
  };

  showModalImport = () => {
    this.setState({
      visibleImport: true,
    });
  };

  handleCancel = () => {
    this.setState({ visibleImport: false });
  };

  onRemove = () => this.setState({ fileList: [], file: null });

  handleUpload = () => {
    const { page, limit, keyword, file } = this.state;
    this.props.importBranchHasProduct(
      file,
      "branchHasProduct",
      page,
      limit,
      keyword,
      this.state.branchId
    );
    this.setState({ file: null, fileList: [] });
  };

  handleDelete = (id, branch_id) => () => {
    console.log("id", id, branch_id)
    this.props.deleteBranchHasProduct(id, branch_id);
  };

  handleDownload = () => {
    this.props.download(this.state.branchId);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "branch_has_product_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter(
      (x) => x.branch_has_product_id !== id
    );
    this.setState({ selectedRowKeys: item, removeData });
  };

  showModalStock = (data) => {
    this.setState({
      visibleStock: true,
      selectData: data
    });
  };

  viewBtn = (data) => {
    return (
      <Fragment>
        <Button style={{ padding: "0px" }} type="link" onClick={() => this.showModalStock(data)}>
          Exchange
        </Button>
        {window.innerWidth > 500 && <Divider type="vertical" />}
      </Fragment>
    );
  };

  handleCloseStock = () => {
    this.setState({ visibleStock: false, selectData: [] })
  }

  handleSaveStock = (value) => {
    value.created_by = localStorage.getItem("user_id")
    TransferStock(value).then(res => {
      this.handleCloseStock()
      if (res.data.message === "success") {
        message.success("Update stock success");
        this.fetchData()
      } else {
        message.error("Update stock fail");
      }
    }).catch(res => {
      message.error(getErrorMsg(res));
    })
  }

  render() {
    const {
      selectedRowKeys,
      keywordTag,
      isShowTag,
      brandTag,
      cateTag,
      sorter
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const { branchProducts } = this.props;
    let check_edit = checkPermission("branch_products", "can_edit", false);
    let check_delete = checkPermission("branch_products", "can_delete", false);

    let actionCol = [
      {
        title: "",
        className: "column-action",
        width: window.innerWidth <= 1025 ? 40 : 140,
        fixed: "left",
        render: (data) => {
          let spacialBtn = this.viewBtn(data)
          return <ListAction
            editUrl={false}
            onDelete={this.handleDelete(data.product_id, data.branch_id)}
            id={`${data.branch_id}_${data.product_id}`}
            name="branch_products"
            spacial={check_edit ? spacialBtn : false}
          />
        },
      },
    ];

    if (!check_edit && !check_delete) {
      actionCol = []
    }

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const mainColumns = [
      {
        title: "Branch Name",
        width: 100,
        dataIndex: "branch_name",
      },
      {
        title: "Barcode",
        width: 120,
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'barcode' ? sorter.order : false,
        dataIndex: "barcode",
        render: (text, record) => {
          if (record.is_show === 1) {
            return <Badge status="success" text={text} />;
          } else {
            return <Badge status="default" text={text} />;
          }
        },
      },
      {
        title: "Name",
        width: 240,
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'product_name' ? sorter.order : false,
        dataIndex: "product_name",
      },
      {
        title: "Category",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'category_name' ? sorter.order : false,
        dataIndex: "category_name",
      },
      {
        title: "Min salon stock",
        dataIndex: "salon_minimum_stock",
        className: "align-right",
      },
      {
        title: "Min retail stock",
        dataIndex: "retail_minimum_stock",
        className: "align-right",
      },
      {
        title: "Salon stock",
        dataIndex: "salon_stock",
        className: "align-right",
        render: (text, record) => {
          if (record.salon_stock < record.salon_minimum_stock) {
            return (
              <Fragment>
                {text}{" "}
                <CaretDownFilled
                  style={{ color: "red", fontSize: "smaller" }}
                />
              </Fragment>
            );
          } else {
            return text;
          }
        },
      },
      {
        title: "Retail stock",
        dataIndex: "retail_stock",
        className: "align-right",
        render: (text, record) => {
          if (record.retail_stock < record.retail_minimum_stock) {
            return (
              <Fragment>
                {text}{" "}
                <CaretDownFilled
                  style={{ color: "red", fontSize: "smaller" }}
                />{" "}
              </Fragment>
            );
          } else {
            return text;
          }
        },
      },
    ];

    let columns =
      window.innerWidth <= 1025
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    let disabled = false;
    let disabledAdd = true;
    let source = branchProducts.data;
    let totalSize = this.props.branchProducts.totalSize;

    if (this.state.branchId !== null) {
      disabled = this.state.branchId.length === 0 ? true : false;
      disabledAdd = this.state.branchId.length === 1 ? false : true;
      if (this.state.branchId.length === 0) {
        source = [];
        totalSize = 0;
      }
    }

    const actionComp = (
      <Fragment>
        <DeleteBadge
          count={this.state.removeData.length}
          onClick={this.showDrawer("visibleDelete")}
        />
        <Col xs={13} sm={13} md={8} lg={6} className="import-btn-align">
          <ImportExportBtn
            showModalImport={this.showModalImport}
            download={this.handleDownload}
            disabledDownload={disabled}
            name="branch_products"
          />
        </Col>
      </Fragment>
    );

    return (
      <div>
        <Spin size="large" spinning={this.props.branchProducts.loading}>
          <div className="list-layout">
            <PageTitle
              name="branch_products"
              component={
                <AddBtn
                  url={`/branch/products/edit/${this.state.branchId}`}
                  name="branch_products"
                  disabled={disabledAdd}
                />
              }
            />
            <Row gutter={[16, 16]}>
              {window.innerWidth > 500 && actionComp}
              <Col
                xs={24}
                sm={24}
                md={{ span: 7, offset: 3 }}
                lg={{ span: 6, offset: 8 }}
              >
                <BranchSelect
                  branchId={this.state.branchId}
                  branches={this.props.branches}
                  onChange={this.handleChangeBranch}
                />
              </Col>
              {window.innerWidth <= 500 && actionComp}
              <FilterBtn showDrawer={this.showDrawer} />
              <Filter
                list={source}
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col xs={19} sm={19} md={21} lg={22}>
                {TagFilter([keywordTag], "Keyword", "green")}
                {TagFilter([isShowTag], "Status", "cyan")}
                {TagFilter([brandTag], "Brands", "orange")}
                {TagFilter([cateTag], "Category", "blue")}
              </Col>
            </Row>

            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["branch_has_product_id", "branch_name", "barcode"]}
            />
          </div>
          <Row
            gutter={[8, 16]}
            justify="space-between"
            style={{ marginTop: "10px" }}
          >
            <Col xs={24} sm={24} md={12}>
              <Badge
                className="remark-text-badge"
                status="success"
                text="Display on iPad"
              />{" "}
              &nbsp;&nbsp;
              <Badge
                className="remark-text-badge"
                status="default"
                text="Not display on iPad"
              />
            </Col>
            <Col lg={6} md={8} xs={24} className="align-right">
              <Checkbox onChange={this.filterOnlyLowStock}>
                Only lower than minimum stock
              </Checkbox>
            </Col>
          </Row>
          <Table
            rowKey={(record) => `${record.branch_id}_${record.product_id}`}
            columns={columns}
            rowSelection={rowSelection}
            bordered={true}
            size="small"
            dataSource={source}
            pagination={{
              pageSize: this.state.limit,
              total: totalSize,
              current: this.state.page,
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 1025 ? { x: 1000 } : undefined}
          />
          <UploadModal
            visibleImport={this.state.visibleImport}
            handleCancel={this.handleCancel}
            loading={this.props.branchProducts.loading}
            onChooseFile={this.onChooseFile}
            fileList={this.state.fileList}
            onRemove={this.onRemove}
            handleUpload={this.handleUpload}
            uploading={this.state.uploading}
            disabled={this.state.file ? false : true}
          />
        </Spin>
        <StockModal
          visible={this.state.visibleStock}
          onClose={this.handleCloseStock}
          onSave={this.handleSaveStock}
          data={this.state.selectData}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ branchProducts, branches }) => ({
  branchProducts,
  branches: branches.data,
});

const mapDispatchToProps = {
  loadBranchProducts: actions.loadBranchProducts.request,
  importBranchHasProduct: actions.importBranchHasProduct.request,
  deleteBranchHasProduct: actions.deleteBranchHasProduct.request,
  download: actions.download.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchHasProductList);
