import {
  LOAD_ACTUAL_SERVICE_GROUPS,
  DELETE_ACTUAL_SERVICE_GROUP,
  LOAD_ACTUAL_SERVICE_GROUP,
  CREATE_ACTUAL_SERVICE_GROUP,
  EDIT_ACTUAL_SERVICE_GROUP,
  UPLOAD_FILE,
  DOWNLOAD_FILE
} from "./actions";

const initialState = {
  message: "",
  data: [],
  actualServiceGroup: {
    actual_service_group_code: "",
    actual_service_group: ""
  },
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,
  
  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ACTUAL_SERVICE_GROUPS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        
        errorImport: "",
        loading: true
      };

    case LOAD_ACTUAL_SERVICE_GROUPS.SUCCESS:
      return {
        ...state,
        data: action.payload.actualServiceGroups.data,
        totalSize: action.payload.actualServiceGroups.total_row,
        alertMessage: "",
        errorMessage: "",
        actualServiceGroup: initialState.actualServiceGroup,
        
        errorImport: "",
        loading: false
      };
    case LOAD_ACTUAL_SERVICE_GROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        
        errorImport: "",
        loading: true
      };

    case LOAD_ACTUAL_SERVICE_GROUP.SUCCESS:
      return {
        ...state,
        actualServiceGroup: action.payload.actualServiceGroup.data,
        alertMessage: "",
        errorMessage: "",
        
        errorImport: "",
        loading: false
      };
    case CREATE_ACTUAL_SERVICE_GROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        
        errorImport: "",
        loading: true
      };

    case CREATE_ACTUAL_SERVICE_GROUP.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.actualServiceGroup.message,
        errorMessage: "",
        
        errorImport: "",
        loading: false
      };
    case CREATE_ACTUAL_SERVICE_GROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        
        errorImport: "",
        loading: false
      };
    case EDIT_ACTUAL_SERVICE_GROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        
        errorImport: "",
        loading: true
      };

    case EDIT_ACTUAL_SERVICE_GROUP.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.actualServiceGroup.message,
        errorMessage: "",
        
        errorImport: "",
        loading: false
      };
    case EDIT_ACTUAL_SERVICE_GROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        
        errorImport: "",
        loading: false
      };
    case DELETE_ACTUAL_SERVICE_GROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        
        errorImport: "",
        loading: true
      };

    case DELETE_ACTUAL_SERVICE_GROUP.SUCCESS:
      const newServiceGroups = state.data.filter(
        group =>
          group.actual_service_group_id !==
          action.payload.actualServiceGroup.data.actual_service_group_id
      );
      return {
        ...state,
        data: newServiceGroups,
        alertMessage: action.payload.actualServiceGroup.message,
        totalSize: state.totalSize - 1,
        
        errorImport: "",
        loading: false
      };
    case DELETE_ACTUAL_SERVICE_GROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        
        errorImport: "",
        loading: true
      };
    case UPLOAD_FILE.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        totalSize: action.payload.data.total_row,
        alertMessage: action.payload.data.message,
        errorMessage: "",
        
        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",
        
        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        
        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
