import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import {
  Table,
  Row,
  Col,
  message,
  Spin,
  notification,
  Checkbox,
  Modal,
  Badge,
  Typography,
} from "antd";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  uniqueArray,
  extractKeyFromArray,
  numberFormat,
} from "lib/helper";
import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  FilterBtn,
  TagFilter,
  ImportExportBtn,
  AddBtn,
  UploadModal,
  PageTitle,
} from "lib/widget";

import Filter from "./Filter";

const { confirm } = Modal;
const { Text } = Typography;

class ActualServiceList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      alertMessage,
      errorMessage,
      errorImport,
    } = nextProps.actualServices;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (errorImport !== "") {
      return { errorImport: errorImport };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
    adjustPrice: false,
    cond: "",
  };

  fetchData = () => {
    const { page, limit, cond, sorter } = this.state;
    setSorter(sorter)
    setSearch(cond)
    this.props.loadActualServices(page, limit, cond, sorter);
  };

  handleSearch = (val, filter) => {
    this.setState({ page: 1, visible: false, keyword: val.keyword, cond: val }, this.fetchData);
    this.getFilterTag(filter, val.is_show, val.keyword);
  };

  getFilterTag = (filter, isShow, keyword) => {
    let type = "",
      is_show = "",
      word = "";

    if (isShow === "0") is_show = "Not display on iPad";
    if (isShow === "1") is_show = "Display on iPad";

    if (keyword !== "") word = `${keyword}`;

    Object.keys(filter).map((key) => {
      filter[key].map((val) => {
        if (key === "type")
          type += (type !== "" ? ", " : "") + val.actual_service_type;
      });
    });

    this.setState({
      typeTag: type !== "" ? `${type}` : type,
      isShowTag: is_show,
      keywordTag: word,
    });

    setFilterTag({
      typeTag: type !== "" ? `${type}` : type,
      isShowTag: is_show,
      keywordTag: word,
    })
  };

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState({ page: current, limit: pageSize, sorter: { order, field } }, this.fetchData);
  };

  componentDidMount() {
    let filter = getFilterTag()

    if (typeof filter === 'object') {
      this.setState({ ...filter, cond: getSearch(), sorter: getSorter() }, this.fetchData)
      return 
    } 
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      alertMessage,
      errorMessage,
      errorImport,
    } = this.props.actualServices;
    const prevMsg = prevProps.actualServices;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (errorImport !== "" && prevMsg.errorImport !== errorImport) {
      this.openNotification(errorImport);
    }
  }

  openNotification = (message) => {
    notification.error({
      message: "Import fail",
      description: message,
      duration: 0,
    });
  };

  deleteActualService(id) {
    this.props.deleteActualService(id);
  }

  showModalImport = () => {
    this.setState({
      visibleImport: true,
    });
  };

  onCheckboxUploadChange = (e) => {
    if (e.target.checked) {
      this.setState({ adjustPrice: true });
    } else {
      this.setState({ adjustPrice: false });
    }
  };

  showConfirm = () => {
    let that = this;
    confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Do you Want to update these master data (including price) ?",
      onOk() {
        that.uploadData();
      },
      onCancel() {
        that.setState({ adjustPrice: false });
      },
    });
  };

  handleUpload = () => {
    if (this.state.adjustPrice) {
      this.showConfirm();
    } else {
      this.uploadData();
    }
  };

  uploadData = () => {
    const { pagination, limit, keyword, file, adjustPrice } = this.state;
    this.props.importActualService(
      file,
      "service",
      pagination,
      limit,
      keyword,
      adjustPrice
    );
    this.setState({ file: null, fileList: [] });
  };

  handleCancel = () => {
    this.setState({ visibleImport: false });
  };

  onChooseFile = ({ file }) => {
    if (file.status !== "removed") {
      this.setState({ file, fileList: [file] });
    }
  };

  onRemove = () => {
    this.setState({ fileList: [], file: null });
  };

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.actualServices.data.filter((x) =>
        selectedRowKeys.includes(x.actual_service_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.actual_service_id)
      );
      items = uniqueArray([...removeState, ...removeData], "actual_service_id");
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "actual_service_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter(
      (x) => x.actual_service_id !== id
    );
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { actualServices } = this.props;
    const { selectedRowKeys, sorter } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const actionCol = [
      {
        title: "",
        key: "actual_service_id",
        className: "column-action",
        width: window.innerWidth <= 1025 ? 40 : 105,
        fixed: "left",
        render: (actualServices) => (
          <ListAction
            editUrl={`/actual-service/services/edit/${actualServices.actual_service_id}`}
            onDelete={this.props.deleteActualService}
            id={actualServices.actual_service_id}
            name="actual_service_actual_services"
          />
        ),
      },
    ];

    const mainColumns = [
      {
        title: "Barcode",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'barcode' ? sorter.order : false,
        dataIndex: "barcode",
        width: 150,
        render: (text, record) => {
          if (record.is_show === 1) {
            return <Badge status="success" text={text} />;
          } else {
            return <Badge status="default" text={text} />;
          }
        },
      },
      {
        title: "Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'service_name' ? sorter.order : false,
        dataIndex: "service_name",
      },
      {
        title: "Type",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'actual_service_type' ? sorter.order : false,
        dataIndex: "actual_service_type",
      },
      {
        title: "Price",
        dataIndex: "price",
        className: "align-right",
        width: 130,
        render: (text) => numberFormat(text),
      },
    ];

    const columns =
      window.innerWidth <= 1025
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    const { isShowTag, typeTag, keywordTag } = this.state;

    return (
      <div>
        <Spin size="large" spinning={this.props.actualServices.loading}>
          <div className="list-layout">
            <PageTitle
              name="actual_service_actual_services"
              component={
                <AddBtn
                  url="/actual-service/services/new"
                  name="actual_service_actual_services"
                />
              }
            />
            <Row gutter={[16, 16]}>
              <DeleteBadge
                count={this.state.removeData.length}
                onClick={this.showDrawer("visibleDelete")}
              />
              <Col xs={10} sm={10} md={8} lg={6} className="import-btn-align">
                <ImportExportBtn
                  showModalImport={this.showModalImport}
                  download={this.props.download}
                  name="actual_service_actual_services"
                />
              </Col>
              <Col
                xs={3}
                sm={3}
                md={{ span: 7, offset: 3 }}
                lg={{ span: 8, offset: 6 }}
              ></Col>
              <FilterBtn showDrawer={this.showDrawer} />
              <Filter
                list={actualServices.data}
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col xs={19} sm={19} md={21} lg={22}>
                {TagFilter([keywordTag], "Keyword", "green")}
                {TagFilter([isShowTag], "Status", "cyan")}
                {TagFilter([typeTag], "Type", "orange")}
              </Col>
            </Row>

            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["actual_service_id", "barcode", "service_name"]}
            />
          </div>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={12}>
              <Badge
                className="remark-text-badge"
                status="success"
                text="Display on iPad"
              />{" "}
              &nbsp;&nbsp;
              <Badge
                className="remark-text-badge"
                status="default"
                text="Not display on iPad"
              />
            </Col>
          </Row>
          <Table
            rowKey="actual_service_id"
            rowSelection={rowSelection}
            bordered={true}
            size="small"
            columns={columns}
            dataSource={actualServices.data}
            pagination={{
              pageSize: this.state.limit,
              total: this.props.actualServices.totalSize,
              current: this.state.page,
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 1025 ? { x: 700 } : undefined}
          />
          <UploadModal
            visibleImport={this.state.visibleImport}
            handleCancel={this.handleCancel}
            loading={this.props.actualServices.loading}
            onChooseFile={this.onChooseFile}
            fileList={this.state.fileList}
            onRemove={this.onRemove}
            handleUpload={this.handleUpload}
            uploading={this.state.uploading}
            disabled={this.state.file ? false : true}
            spacialComp={
              <Checkbox
                onChange={this.onCheckboxUploadChange}
                checked={this.state.adjustPrice === true}
              >
                Including price
              </Checkbox>
            }
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ actualServices }) => ({
  actualServices,
});

const mapDispatchToProps = {
  loadActualServices: actions.loadActualServices.request,
  deleteActualService: actions.deleteActualService.request,
  importActualService: actions.importActualService.request,
  download: actions.download.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActualServiceList);
