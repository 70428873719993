import { createTypes, createAction } from "lib/action";

const LOAD_SERVICE_PACKAGE_GROUPS = createTypes("servicePackageGroups", "list");
const LOAD_SERVICE_PACKAGE_GROUP = createTypes("ServicePackageGroup", "show");
const CREATE_SERVICE_PACKAGE_GROUP = createTypes(
  "ServicePackageGroup",
  "create"
);
const EDIT_SERVICE_PACKAGE_GROUP = createTypes("ServicePackageGroup", "edit");
const DELETE_SERVICE_PACKAGE_GROUP = createTypes(
  "ServicePackageGroup",
  "delete"
);
const UPLOAD_FILE = createTypes("ServicePackageGroup", "import");
const DOWNLOAD_FILE = createTypes("ServicePackageGroup", "export");
const DESTROY_MANY = createTypes("ServicePackageGroup", "destory");

const loadServicePackageGroups = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_SERVICE_PACKAGE_GROUPS.REQUEST, {
      pagination,
      limit,
      keyword,
      sort
    }),
  success: servicePackageGroups =>
    createAction(LOAD_SERVICE_PACKAGE_GROUPS.SUCCESS, { servicePackageGroups }),
  failure: (error) => createAction(LOAD_SERVICE_PACKAGE_GROUPS.FAILURE, { error })
};

const loadServicePackageGroup = {
  request: packageGroupId =>
    createAction(LOAD_SERVICE_PACKAGE_GROUP.REQUEST, { packageGroupId }),
  success: servicePackageGroup =>
    createAction(LOAD_SERVICE_PACKAGE_GROUP.SUCCESS, { servicePackageGroup }),
  failure: error => createAction(LOAD_SERVICE_PACKAGE_GROUP.FAILURE, { error })
};

const createServicePackageGroup = {
  request: (code, name) =>
    createAction(CREATE_SERVICE_PACKAGE_GROUP.REQUEST, {
      code,
      name
    }),
  success: servicePackageGroup =>
    createAction(CREATE_SERVICE_PACKAGE_GROUP.SUCCESS, { servicePackageGroup }),
  failure: error =>
    createAction(CREATE_SERVICE_PACKAGE_GROUP.FAILURE, { error })
};

const editServicePackageGroup = {
  request: (id, code, name) =>
    createAction(EDIT_SERVICE_PACKAGE_GROUP.REQUEST, {
      id,
      code,
      name
    }),
  success: servicePackageGroup =>
    createAction(EDIT_SERVICE_PACKAGE_GROUP.SUCCESS, { servicePackageGroup }),
  failure: error => createAction(EDIT_SERVICE_PACKAGE_GROUP.FAILURE, { error })
};

const deleteServicePackageGroup = {
  request: id => createAction(DELETE_SERVICE_PACKAGE_GROUP.REQUEST, { id }),
  success: servicePackageGroup =>
    createAction(DELETE_SERVICE_PACKAGE_GROUP.SUCCESS, { servicePackageGroup }),
  failure: error =>
    createAction(DELETE_SERVICE_PACKAGE_GROUP.FAILURE, { error })
};
const upload = {
  request: (file, pagination, limit, keyword) =>
    createAction(UPLOAD_FILE.REQUEST, {
      file,
      pagination,
      limit,
      keyword
    }),
  success: data => createAction(UPLOAD_FILE.SUCCESS, { data }),
  failure: error => createAction(UPLOAD_FILE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: error => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_SERVICE_PACKAGE_GROUPS,
  loadServicePackageGroups,
  LOAD_SERVICE_PACKAGE_GROUP,
  loadServicePackageGroup,
  CREATE_SERVICE_PACKAGE_GROUP,
  createServicePackageGroup,
  EDIT_SERVICE_PACKAGE_GROUP,
  editServicePackageGroup,
  DELETE_SERVICE_PACKAGE_GROUP,
  deleteServicePackageGroup,
  upload,
  UPLOAD_FILE,
  download,
  DOWNLOAD_FILE,
  DESTROY_MANY,
  destroyMany
};
