import React, { Component } from "react";
import { Select, Typography, Col, Row } from "antd";
import { extractKeyFromArray , } from 'lib/helper'

const { Option } = Select;
const { Text } = Typography;

class BranchSelect extends Component {
  state = {
    branchId: [],
    isAll: null
  };

  // getBranchvalue = (branches) => {
  
  //   let idx = branches.findIndex(x => parseInt(x.branch_id) === parseInt(this.props.branchId))
  //   if (idx !== -1) {
  //     return branches[idx].branch_id
  //   }
  //   return ''
  // }

  onBranchChange = (value, option) => {
    let branchId = value;
    let branches = this.props.branches

    if (value.includes('all')) {
        this.setState({ isAll: false })
        branchId = extractKeyFromArray (branches, "branch_id");
    } else {
        this.setState({ isAll: true })
    }

    if (value.includes('none')) {
        branchId = []
        this.setState({ isAll: null })
    }

    this.props.onChange(branchId);
    this.setState({ branchId: branchId })
    return branchId
  };


  getAllBranchOpt = () => {
    let branches = [
      { branch_id: this.state.isAll === false ? "none" : "all", 
        branch_name: this.state.isAll === false ? "Clear All Branches" : "All Branches" 
      },
      ...this.props.branches
    ];
    return branches;
  };

  render() {
    const branches =
      typeof this.props.branches !== "object" ? [] : this.getAllBranchOpt();;
    return (
      <Row>
        <Col xs={6} sm={6} md={6} lg={6}>
          <Text>Branch : </Text>
        </Col>
        <Col xs={18} sm={18} md={18} lg={18}>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            maxTagCount={1}
            placeholder="Please select branch"
            onChange={this.onBranchChange}
            value={this.props.branchId}
            disabled={this.props.disabled}
          >
            {branches.map(branch => {
              return (
                <Option key={branch.branch_id} value={branch.branch_id}>
                  {branch.branch_name}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
    );
  }
}

export default BranchSelect;
