import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Layout } from "antd";

import Header from "./Header";
import Upcoming from "./Upcoming";
import NotFound from "./NotFound";
import SideBar from "./SideBar";

import Analytic from "modules/analytic/components/Analytic";
import ActualServices from "modules/actual-service/ActualServices";
import Authentications from "modules/authen/Authentications";
import Branches from "modules/branch/Branches";
import CashPackages from "modules/cash-package/CashPackages";
import Customers from "modules/customers/Customers";
import Dashboard from "modules/dashboard/components/Dashboard";
import Employees from "modules/employee/Employees";
import Products from "modules/products/Products";
import Reports from "modules/report/Reports";
import Schedules from "modules/schedule/Schedules";
import ServicePackages from "modules/service-package/ServicePackages";
import Signins from "modules/signin/Signins";
import Systems from "modules/system/Systems";

import { getToken } from "lib/helper";
import { Navigator } from "lib/widget";

const { Sider, Content } = Layout;
export default class extends Component {
  state = {
    collapsed: true,
    sidebarSize: window.innerWidth <= 780 ? 0 : 80,
    minHeight: window.innerHeight,
    selectedMenu: localStorage.getItem("selectedMenu"),
    subSelectedMenu: localStorage.getItem("selectedMenu"),
  };

  toggle = () => {
    var sidebarSize = 200;
    if (!this.state.collapsed) {
      sidebarSize = window.innerWidth <= 780 ? 0 : 80;
    }
    this.setState({
      collapsed: !this.state.collapsed,
      sidebarSize,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = (e) => {
    this.setState({ windowHeight: window.innerHeight });
  };

  getLayout = () => {
    let url = window.location.pathname;
    if (url.match(/dashboard/g)) {
      return <Route path="/dashboard" component={Dashboard} />;
    } else if (url.match(/analytics/g)) {
      return <Route path="/analytics" component={Analytic} />;
    } else {
      return <Content className="site-layout-background-content">
        <Switch>
          <Route path="/actual-service" component={ActualServices} />
          <Route path="/auth" component={Authentications} />
          <Route path="/branch" component={Branches} />
          <Route path="/cash-package" component={CashPackages} />
          <Route path="/customer" component={Customers} />
          <Route path="/employee" component={Employees} />
          <Route path="/product" component={Products} />
          <Route path="/report" component={Reports} />
          <Route path="/schedule-report" component={Schedules} />
          <Route path="/service-package" component={ServicePackages} />
          <Route path="/system" component={Systems} />

          <Route path="/upcoming" component={Upcoming} />
          <Route path="/home" component={Upcoming} />
          <Route path="/*" component={NotFound} />
        </Switch>
      </Content>;
    }
  };

  render() {
    let url = window.location.protocol + "//" + window.location.host + "/";
    if (
      window.location.pathname.match(/signin/g) ||
      window.location.href === url
    ) {
      return <Route path="/" component={Signins} />;
    }

    if (getToken("token") === null) {
      return <Redirect to="/" />;
    }

    return (
      <Layout>
        <Header collapsed={this.state.collapsed} toggle={this.toggle} />
        <Layout className="site-layout">
          <Sider
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            collapsedWidth={this.state.sidebarSize}
          >
            <SideBar />
          </Sider>
          <Layout className="site-layout-body">
            <Navigator />
            {this.getLayout()}
          </Layout>
        </Layout>
      </Layout>
    );
  }
}
