import { createTypes, createAction } from "lib/action";

const LOAD_CATEGORIES = createTypes("categories", "list");
const CREATE_CATEGORY = createTypes("categories", "create");
const LOAD_CATEGORY = createTypes("categories", "show");
const EDIT_CATEGORY = createTypes("categories", "edit");
const DELETE_CATEGORY = createTypes("categories", "delete");
const UPLOAD_FILE = createTypes("categories", "import");
const DOWNLOAD_FILE = createTypes("categories", "export");
const DESTROY_MANY = createTypes("categories", "destory");

const loadCategories = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_CATEGORIES.REQUEST, { pagination, limit, keyword, sort }),
  success: categories => createAction(LOAD_CATEGORIES.SUCCESS, { categories }),
  failure: () => createAction(LOAD_CATEGORIES.FAILURE)
};

const loadCategory = {
  request: categoryId => createAction(LOAD_CATEGORY.REQUEST, { categoryId }),
  success: category => createAction(LOAD_CATEGORY.SUCCESS, { category }),
  failure: error => createAction(LOAD_CATEGORY.FAILURE, { error })
};

const createCategory = {
  request: (code, name) =>
    createAction(CREATE_CATEGORY.REQUEST, { code, name }),
  success: category => createAction(CREATE_CATEGORY.SUCCESS, { category }),
  failure: error => createAction(CREATE_CATEGORY.FAILURE, { error })
};

const editCategory = {
  request: (id, code, name) =>
    createAction(EDIT_CATEGORY.REQUEST, { id, code, name }),
  success: category => createAction(EDIT_CATEGORY.SUCCESS, { category }),
  failure: error => createAction(EDIT_CATEGORY.FAILURE, { error })
};

const deleteCategory = {
  request: id => createAction(DELETE_CATEGORY.REQUEST, { id }),
  success: category => createAction(DELETE_CATEGORY.SUCCESS, { category }),
  failure: error => createAction(DELETE_CATEGORY.FAILURE, { error })
};

const upload = {
  request: (file, pagination, limit, keyword) =>
    createAction(UPLOAD_FILE.REQUEST, {
      file,
      pagination,
      limit,
      keyword
    }),
  success: data => createAction(UPLOAD_FILE.SUCCESS, { data }),
  failure: error => createAction(UPLOAD_FILE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: error => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_CATEGORIES,
  loadCategories,
  CREATE_CATEGORY,
  createCategory,
  LOAD_CATEGORY,
  loadCategory,
  EDIT_CATEGORY,
  editCategory,
  DELETE_CATEGORY,
  deleteCategory,
  upload,
  UPLOAD_FILE,
  download,
  DOWNLOAD_FILE,
  DESTROY_MANY,
  destroyMany
};
