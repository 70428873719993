import {
  LOAD_BRANCH_PRODUCTS,
  IMPORT_BRANCH_HAS_PRODUCT,
  DOWNLOAD_FILE,
  EDIT_BRANCH_HAS_PRODUCT,
  DELETE_BRANCH_HAS_PRODUCT,
  DESTROY_MANY
} from "./actions";

const initialState = {
  alertMessage: "",
  errorMessage: "",
  data: [],
  totalSize: 0,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BRANCH_PRODUCTS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case LOAD_BRANCH_PRODUCTS.SUCCESS:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        data: action.payload.branchProducts.data,
        totalSize: action.payload.branchProducts.total_row,
        loading: false
      };
    case LOAD_BRANCH_PRODUCTS.FAILURE:
      return { ...state, alertMessage: "", errorMessage: "", loading: false };
    case IMPORT_BRANCH_HAS_PRODUCT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case IMPORT_BRANCH_HAS_PRODUCT.SUCCESS:
      return {
        ...state,
        data: action.payload.branchProduct.data,
        totalSize: action.payload.branchProduct.total_row,
        alertMessage: action.payload.branchProduct.message,
        errorMessage: "",
        loading: false
      };
    case IMPORT_BRANCH_HAS_PRODUCT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case EDIT_BRANCH_HAS_PRODUCT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_BRANCH_HAS_PRODUCT.SUCCESS:
      return {
        ...state,
        data: action.payload.products.data,
        alertMessage: action.payload.products.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_BRANCH_HAS_PRODUCT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };

    case DELETE_BRANCH_HAS_PRODUCT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_BRANCH_HAS_PRODUCT.SUCCESS:
      console.log("action.payload.products.data", action.payload.products.data)
      const newBranches = state.data.filter(
        branch =>
          parseInt(branch.product_id) !== parseInt(action.payload.products.data.id) ||
          parseInt(branch.branch_id) !== parseInt(action.payload.products.data.branch_id)
      );

      return {
        ...state,
        data: newBranches,
        alertMessage: action.payload.products.message,
        totalSize: state.totalSize - 1,
        errorImport: "",
        loading: false
      };
    case DELETE_BRANCH_HAS_PRODUCT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.branch_has_product_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
