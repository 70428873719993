import { all, put, takeEvery } from "redux-saga/effects";
import { fetchGo, getErrorMsg, handleError } from "lib/fetch";
import { getComparedFilter } from "lib/go-api-helper";
import * as actions from "./actions";

function* loadYearsRevenue(action) {
  try {
    const { type, duration, branch_id } = action.payload;
    const params = { type, duration, branch_id };
    const res = yield fetchGo.post(`revenue`, params);
    yield put(actions.loadYearsRevenue.success(res.data, null));
  } catch (error) {
    handleError(error);
  }
}

function* loadAppointment(action) {
  try {
    const { type, duration, branch_id } = action.payload;
    const params = { type, duration, branch_id };
    const params2 = { type, branch_id };

    params2["duration"] = getComparedFilter(type, duration);

    const [revenue, compared] = yield all([
      fetchGo.post(`appointment_graph`, params),
      fetchGo.post(`appointment_graph`, params2),
    ]);

    yield put(actions.loadAppointment.success(revenue.data, compared.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadRevenueSummaries(action) {
  try {
    const {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
    } = action.payload;
    const params = { type, duration, branch_id, query_type, category_id };
    const params2 = { type, branch_id, query_type, category_id };

    params2["duration"] = getComparedFilter(type, duration);

    const [revenue, compared] = yield all([
      fetchGo.post(`revenue`, params),
      fetchGo.post(`revenue`, params2),
    ]);

    yield put(
      actions.loadRevenueSummaries.success(revenue.data, compared.data)
    );
  } catch (error) {
    handleError(error);
  }
}

function* loadRevenuePTSummaries(action) {
  try {
    const {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
    } = action.payload;
    const params = { type, duration, branch_id, query_type, category_id };
    const params2 = { type, branch_id, query_type, category_id };

    params2["duration"] = getComparedFilter(type, duration);

    const [revenue, compared] = yield all([
      fetchGo.post(`revenue_per_trans`, params),
      fetchGo.post(`revenue_per_trans`, params2),
    ]);

    yield put(
      actions.loadRevenuePTSummaries.success(revenue.data, compared.data)
    );
  } catch (error) {
    handleError(error);
  }
}

export default function* watchWidgets() {
  yield all([
    takeEvery(
      actions.LOAD_WIDGET_REVENUE_SUMMARIES.REQUEST,
      loadRevenueSummaries
    ),
    takeEvery(actions.LOAD_WIDGET_APPOINTMENT.REQUEST, loadAppointment),
    takeEvery(actions.LOAD_WIDGET_YEAR_REVENUE.REQUEST, loadYearsRevenue),
    takeEvery(actions.LOAD_WIDGET_REVENUE_PT_SUMMARIES.REQUEST, loadRevenuePTSummaries),
  ]);
}
