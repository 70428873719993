import React, { Component, Fragment } from "react";

import { Row, Col, Typography, Spin, Badge, Card } from "antd";
import { numberFormat, capitalize } from "lib/helper";
import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const { Text } = Typography;
const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

export class GraphDetail extends Component {
  formatPiechartRevenue = (obj) => {
    let data = [];
    if (typeof obj === "undefined") return [];
    Object.keys(obj).map((key, i) => {
      console.log("key", key);
      data.push({
        name: key,
        value: obj[key].revenue.amt,
        label: capitalize(key),
      });
    });
    return Object.assign([], data).reverse();
  };

  formatPiechartQuantity = (obj) => {
    let data = [];
    if (typeof obj === "undefined") return [];
    Object.keys(obj).map((key, i) => {
      data.push({
        name: key,
        value: obj[key].revenue.qty,
        label: capitalize(key),
      });
    });

    return Object.assign([], data).reverse();
  };

  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#595959"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  render() {
    const { loading_pie, pie } = this.props;

    const pieRevenue = this.formatPiechartRevenue(pie);
    const pieQuantity = this.formatPiechartQuantity(pie);
    let sumR = 0;
    let sumQ = 0;
    return (
      <Spin spinning={loading_pie}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Text strong>Revenue</Text>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div style={{ width: "100%", height: 200 }}>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          isAnimationActive={false}
                          data={pieRevenue}
                          cx="50%"
                          cy="50%"
                          outerRadius={60}
                          fill="#55b9ed"
                          label={this.renderCustomizedLabel}
                        >
                          {pieRevenue.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index]}>
                              {entry.name}
                            </Cell>
                          ))}
                        </Pie>
                        <Tooltip formatter={(value) => numberFormat(value)} />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {pieRevenue.map((v, i) => {
                    sumR += parseFloat(v.value);
                    return (
                      <Row
                        gutter={[8, 8]}
                        key={i}
                        style={{ marginTop: i == 0 ? "40px" : "0px" }}
                      >
                        <Col span={12}>
                          <Badge color={COLORS[i]} text={v.label} />
                        </Col>
                        <Col span={12} className="align-right">
                          {numberFormat(v.value)}
                        </Col>
                      </Row>
                    );
                  })}
                  <Row gutter={[8, 8]} style={{ marginTop: "0px" }}>
                    <Col span={12}>
                      <b>Total revenue</b>
                    </Col>
                    <Col span={12} className="align-right">
                      <b>{numberFormat(sumR)}</b>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Text strong>Quantity</Text>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div style={{ width: "100%", height: 200 }}>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          isAnimationActive={false}
                          data={pieQuantity}
                          cx="50%"
                          cy="50%"
                          outerRadius={60}
                          fill="#55b9ed"
                          label={this.renderCustomizedLabel}
                        >
                          {pieQuantity.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index]} />
                          ))}
                        </Pie>
                        <Tooltip formatter={(value) => numberFormat(value)} />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {pieQuantity.map((v, i) => {
                    sumQ += parseInt(v.value);
                    return (
                      <Row
                        gutter={[8, 8]}
                        key={i}
                        style={{ marginTop: i == 0 ? "40px" : "0px" }}
                      >
                        <Col span={12}>
                          <Badge color={COLORS[i]} text={v.label} />
                        </Col>
                        <Col span={12} className="align-right">
                          {numberFormat(v.value, 0)}
                        </Col>
                      </Row>
                    );
                  })}
                  <Row gutter={[8, 8]} style={{ marginTop: "0px" }}>
                    <Col span={12}>
                      <b>Total quantity</b>
                    </Col>
                    <Col span={12} className="align-right">
                      <b>{sumQ}</b>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default GraphDetail;
