import React, { Fragment } from 'react'
import * as Component from './components'

export default () => {
    return (
        <Fragment>
            <Component.Users></Component.Users>
            <Component.CustomerTransfer></Component.CustomerTransfer>
        </Fragment>
    )
}