import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadActualServices(action) {
  try {
    const { pagination, limit, cond, sort } = action.payload;
    const res = yield fetcher.post('services/allServices', { pagination, limit, cond, sort })
    yield put(actions.loadActualServices.success(res.data));
  } catch (error) {
    handleError(error)
  }
}

function* loadActualService(action) {
  try {
    const { actualServiceId } = action.payload;
    const res = yield fetcher.get(`services/${actualServiceId}`)
    yield put(actions.loadActualService.success(res.data));
  } catch (error) {
    handleError(error)
  }
}

function* createActualService(action) {
  try {
    const { actualService, checkedList } = action.payload;
    const formData = getFormData(actualService, checkedList);
    const res = yield fetcher.post('services', formData)
    yield put(actions.createActualService.success(res.data));
    yield put(push("/actual-service/services"));
  } catch (error) {
    handleError(error)
    yield put(actions.createActualService.failure(getErrorMsg(error)));
  }
}

function* editActualService(action) {
  try {
    const { id, actualService, checkedList } = action.payload;
    const formData = getFormData(actualService, checkedList);
    const res = yield fetcher.patch(`services/${id}`, formData)
    yield put(actions.editActualService.success(res.data));
    yield put(push("/actual-service/services"));
  } catch (error) {
    handleError(error)
    yield put(actions.editActualService.failure(getErrorMsg(error)));
  }
}

function* deleteActualService(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`services/${id}`);
    yield put(actions.deleteActualService.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteActualService.failure(getErrorMsg(error)));
  }
}

function* importActualService(action) {
  try {
    const { file, type, pagination, limit, keyword, adjustPrice } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    formData.append("adjustPrice", adjustPrice);
    if (keyword) formData.append("keyword", keyword);
    const res = yield fetcher.post("import/actualservices", formData);
    yield put(actions.importActualService.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importActualService.failure(getErrorMsg(error)));
    yield put(push("/actual-service/services"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/export_masterdata", { type: "actualService" });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function getFormData(product, checkedList) {
  let allow = [
    "barcode",
    "service_name",
    "actual_service_group_id",
    "actual_service_type_id",
    "pricelist",
    "is_show"
  ];

  let products = {}
  Object.keys(product).map(x => {
    if(allow.includes(x)){
      products[x] = product[x]
    }
  })

  let check_list = {}
  checkedList.map(check => {
    let key = `${check.name}`;
    check_list[key] = check.value;
  });
  

  return {...products, check_list};
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/actualservice`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchActualServices() {
  yield all([
    takeEvery(actions.LOAD_ACTUAL_SERVICES.REQUEST, loadActualServices),
    takeEvery(actions.LOAD_ACTUAL_SERVICE.REQUEST, loadActualService),
    takeEvery(actions.CREATE_ACTUAL_SERVICE.REQUEST, createActualService),
    takeEvery(actions.EDIT_ACTUAL_SERVICE.REQUEST, editActualService),
    takeEvery(actions.DELETE_ACTUAL_SERVICE.REQUEST, deleteActualService),
    takeEvery(actions.IMPORT_ACTUAL_SERVICE.REQUEST, importActualService),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
