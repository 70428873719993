import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { checkPermission, getToken } from 'lib/helper'

const PrivateRoute = ({ component: Component, ...rest }) => {
    if (typeof rest.checkOnlyToken !== "undefined") {
        return <Route {...rest} render={props => (
            getToken('token') !== null
                ? <Component {...props} />
                : <Redirect to="/signin" />
        )} />
    }

    return <Route {...rest} render={props => (
        getToken('token') !== null && checkPermission(rest.perm, rest.action)
            ? <Component {...props} />
            : <Redirect to="/signin" />
    )} />
}

export default PrivateRoute