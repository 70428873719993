import {
  LOAD_ACTUAL_SERVICE_TYPES,
  LOAD_ACTUAL_SERVICE_TYPE,
  CREATE_ACTUAL_SERVICE_TYPE,
  EDIT_ACTUAL_SERVICE_TYPE,
  DELETE_ACTUAL_SERVICE_TYPE,
  UPLOAD_FILE,
  DOWNLOAD_FILE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  actualServiceType: {
    actual_service_type_code: "",
    actual_service_type: "",
    actual_service_type_abbr: ""
  },
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ACTUAL_SERVICE_TYPES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_ACTUAL_SERVICE_TYPES.SUCCESS:
      return {
        ...state,
        data: action.payload.actualServiceTypes.data,
        totalSize: action.payload.actualServiceTypes.total_row,
        alertMessage: "",
        errorMessage: "",
        actualServiceType: initialState.actualServiceType,

        errorImport: "",
        loading: false
      };
    case LOAD_ACTUAL_SERVICE_TYPE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_ACTUAL_SERVICE_TYPE.SUCCESS:
      return {
        ...state,
        actualServiceType: action.payload.actualServiceType.data,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_ACTUAL_SERVICE_TYPE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case CREATE_ACTUAL_SERVICE_TYPE.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.actualServiceType.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_ACTUAL_SERVICE_TYPE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_ACTUAL_SERVICE_TYPE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_ACTUAL_SERVICE_TYPE.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.actualServiceType.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_ACTUAL_SERVICE_TYPE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case DELETE_ACTUAL_SERVICE_TYPE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_ACTUAL_SERVICE_TYPE.SUCCESS:
      const newServiceGroups = state.data.filter(
        group =>
          group.actual_service_type_id !== action.payload.actualServiceType
      );
      return {
        ...state,
        data: newServiceGroups,
        alertMessage: "success",
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_ACTUAL_SERVICE_TYPE.FAILURE:
      return {
        ...state,
        alertMessage: "",
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case UPLOAD_FILE.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        totalSize: action.payload.data.total_row,
        alertMessage: action.payload.data.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",

        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.actual_service_type_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
