import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Card } from "antd";
import Filter from "./Filter";
import TopTable from "./TopTable";

export class TopSpend extends Component {
  handleSearch = (
    start_date,
    end_date,
    query_type,
    category_id,
    item_id,
    gender
  ) => {
    this.props.loadCusotmerTopSpend(
      start_date,
      end_date,
      query_type,
      category_id,
      parseInt(item_id),
      gender,
      this.props.branches
    );
  };

  render() {
    const { loading_topspend, topspend } = this.props;
    return (
      <Card title="Top 10 spend by category">
        <Filter type="spend" onSearch={this.handleSearch} />
        <TopTable type="spend" data={topspend} loading={loading_topspend} />
      </Card>
    );
  }
}

const mapStateToProps = ({ customer_analytic }) => ({
  loading_topspend: customer_analytic.loading_topspend,
  topspend: customer_analytic.topspend,
});

const mapDispatchToProps = {
  loadCusotmerTopSpend: actions.loadCusotmerTopSpend.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopSpend);
