import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadUsers(action) {
  try {
    const { pagination, limit, cond, sort } = action.payload;
    const res = yield fetcher.post(`users/list`, {
      pagination,
      limit,
      cond,
      sort
    });
    yield put(actions.loadUsers.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadUser(action) {
  try {
    const { userId } = action.payload;
    const res = yield fetcher.get(`users/${userId}`);
    yield put(actions.loadUser.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* createUser(action) {
  try {
    const { user, checkedList, isManager } = action.payload;
    const formData = getFormData(user, checkedList, isManager)
    const res = yield fetcher.post(`users`, formData);
    yield put(actions.createUser.success(res.data));
    yield put(push("/system/users"));
  } catch (error) {
    handleError(error);
    yield put(actions.createUser.failure(getErrorMsg(error)));
  }
}

function* editUser(action) {
  try {
    const { id, user, checkedList, isManager } = action.payload;
    const formData = getFormData(user, checkedList, isManager)
    const res = yield fetcher.patch(`users/${id}`, formData);
    yield put(actions.editUser.success(res.data));
    yield put(push("/system/users"));
  } catch (error) {
    handleError(error);
    yield put(actions.editUser.failure(getErrorMsg(error)));
  }
}

function* deleteUser(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`users/${id}`);
    yield put(actions.deleteUser.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteUser.failure(getErrorMsg(error)));
  }
}

function* editPassword(action) {
  try {
    const { id, user } = action.payload;

    const res = yield fetcher.post(`users/changepassword`, {
      user_id: id,
      email: user.email,
      password: user.password,
      new_password: user.new_password
    });

    localStorage.clear();
    sessionStorage.clear();
    yield put(actions.editPassword.success(res.data));
    yield put(push("/system/users"));
  } catch (error) {
    handleError(error);
    yield put(actions.editPassword.failure(getErrorMsg(error)));
  }
}

function getFormData(user, checkedList, isManager) {
  let allow = [
    "email",
    "password",
    "name",
    "usergroup_id",
    "employee_id",
  ];

  let users = {}
  Object.keys(user).map(x => {
    if(allow.includes(x)){
      if (x === 'employee_id') {
        user.employee_id =  user.employee_id ? user.employee_id : null
      }
      users[x] = user[x]
    }
  })

  users.is_manager = isManager ? 1 : 0

  let check_list = {}
  checkedList.map(check => {
    let key = `${check.name}`;
    check_list[key] = check.value;
  });
  

  return {...users, check_list};
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/user`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchUsers() {
  yield all([
    takeEvery(actions.LOAD_USERS.REQUEST, loadUsers),
    takeEvery(actions.LOAD_USER.REQUEST, loadUser),
    takeEvery(actions.CREATE_USER.REQUEST, createUser),
    takeEvery(actions.EDIT_USER.REQUEST, editUser),
    takeEvery(actions.DELETE_USER.REQUEST, deleteUser),
    takeEvery(actions.EDIT_PASSWORD.REQUEST, editPassword),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
