import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import UnitForm from "./UnitForm";
import { checkPermission } from "lib/helper";

class EditUnit extends Component {
  state = {
    can_edit: checkPermission("product_units", "can_edit", false)
  };

  componentDidMount() {
    this.props.loadUnit(this.props.match.params.unit_id);
  }

  editUnit = async unit => {
    this.props.editUnit(
      this.props.match.params.unit_id,
      unit.uom_code,
      unit.uom_name
    );
  };

  render() {
    const { unit, alertMessage, errorMessage, loading } = this.props;

    return (
      <UnitForm
        onSubmit={this.editUnit}
        unit={unit}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ units }) => {
  return {
    unit: units.unit,
    alertMessage: units.alertMessage,
    errorMessage: units.errorMessage,
    loading: units.loading
  };
};

const mapDispatchToProps = {
  loadUnit: actions.loadUnit.request,
  editUnit: actions.editUnit.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUnit);
