import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  message,
  Spin
} from "antd";
import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { PageTitle, FormBtn, RequriedText } from "lib/widget";

const FormItem = Form.Item;
const Option = Select.Option;

export default class UserEditForm extends Component {
  state = {
    checkedList: this.props.checkedList,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage, checkedList } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (checkedList.length > 0) {
      return { checkedList: checkedList }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage, checkedList } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }

    if (checkedList.length > 0 && prevState.checkedList !== checkedList) {
      this.setState({ checkedList })
    }
  }

  onChecked = checkedValues => {
    const obj = this.state.checkedList;
    obj.map(check => {
      if (check.name.toString() === checkedValues.target.name.toString())
        check.value = checkedValues.target.checked;
    });
    this.setState({
      checkedList: obj
    });
  };

  onCheckedManager = value => {
    this.setState({ is_manager: value.target.checked });
    if (!value.target.checked) 
    {
      const obj = this.state.checkedList;
      obj.map(check => {
        check.value = false;
      });
      this.setState({
        checkedList: obj,
      });
    }
  };

  componentDidMount() {
    this.nameInput.focus(); 
  }

  render() {
    const { user, onSubmit, usergroups, branches, employees } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      }
    };

    const list = branches.map(branch => {
      const code = branch.branch_id;
      const check = this.state.checkedList.filter(c => {
        if (c.name === code) return c;
      });
      return (
        <Col key={branch.branch_id} span={12}>
          <Checkbox
            name={branch.branch_id.toString()}
            onChange={this.onChecked}
            checked={check[0].value}
          >
            {branch.branch_name}
          </Checkbox>
        </Col>
      );
    });

    return (
      <div>
        <Spin size="large" spinning={this.props.loading}>
        <PageTitle name="system_user_users" component={null} />
          <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .email("Incorect email format")
                .required("email is a required field"),
              name: yup.string().required("Name is a required field"),
              usergroup_id: yup.string().required("User group is a required field")
            })}
            onSubmit={values => {
              onSubmit(values, this.state.checkedList, this.state.is_manager);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <Form onFinish={handleSubmit} autoComplete="off">
                <fieldset disabled={!this.props.canEdit}>
                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Email" />}
                  validateStatus={touched.email && errors.email && "error"}
                  help={touched.email && errors.email}
                >
                  <Input
                    name="email"
                    ref={(input) => { this.nameInput = input; }} 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    autoComplete="off"
                    disabled={true}
                  />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Name" />}
                  validateStatus={touched.name && errors.name && "error"}
                  help={touched.name && errors.name}
                >
                  <Input
                    
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    autoComplete="off"
                  />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="User Group" />}
                  validateStatus={touched.usergroup_id && errors.usergroup_id && "error"}
                  help={touched.usergroup_id && errors.usergroup_id}
                >
                  <Select
                    placeholder="Select a usergroup"
                    onChange={usergroup => setFieldValue("usergroup_id", usergroup)}
                    value={values.usergroup_id}
                    disabled={!this.props.canEdit}
                  >
                    {usergroups.map(usergroup => (
                      <Option key={usergroup.usergroup_id} value={usergroup.usergroup_id}>{usergroup.usergroup_name}</Option>
                    ))}
                  </Select>
                </FormItem>
                

                <FormItem
                  {...formItemLayout}
                  label="Link to Employee"
                  validateStatus={
                    touched.employee_id && errors.employee_id && "error"
                  }
                  help={touched.employee_id && errors.employee_id}
                >
                  <Select
                    showSearch
                    placeholder="Select a employee"
                    optionFilterProp="children"
                    disabled={!this.props.canEdit}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={employee =>
                      setFieldValue("employee_id", employee)
                    }
                    value={values.employee_id}
                  >
                    <Option value={null}>---- Clear Data ----</Option>
                    {employees.map(employee => (
                      <Option key={employee.employee_id} value={employee.employee_id}>
                        {employee.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem>
                  <Row>
                    <Col
                      xs={{ span: 5 }}
                      sm={{ span: 8 }}
                      style={{
                        paddingRight: "9px",
                        textAlign: window.innerWidth <= 500 ? "left" : "right",
                        color: "rgba(0, 0, 0, 0.85)"
                      }}
                    >
                      Manager :
                    </Col>
                    <Col xs={{ span: 19 }} sm={{ span: 10 }}>
                      <Row>
                        <Checkbox
                          onChange={(e) => {
                            setFieldValue('is_manager', e.target.checked)
                            this.onCheckedManager(e)
                          }}
                          checked={values.is_manager}
                        />
                      </Row>
                    </Col>
                  </Row>
                </FormItem>

                <FormItem style={{ display: values.is_manager ? "block" : "none" }}>
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 8 }}
                      style={{
                        paddingRight: "9px",
                        textAlign: window.innerWidth <= 500 ? "left" : "right",
                        color: "rgba(0, 0, 0, 0.85)",
                        paddingBottom: window.innerWidth <= 500 ? "20px" : "0px",
                      }}
                    >
                      Branch :
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 10 }}>
                      <Row>{list}</Row>
                    </Col>
                  </Row>
                </FormItem>

                <FormBtn url="/system/users" canEdit={this.props.canEdit} />
                </fieldset>
              </Form>
            )}
          />
        </Spin>
      </div>
    );
  }
}
