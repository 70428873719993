import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import {
  uniqueArray,
  extractKeyFromArray,
  getSearch,
  setSearch,
  getSorter,
  setSorter,
} from "lib/helper";
import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  ImportExportBtn,
  AddBtn,
  UploadModal,
  PageTitle,
} from "lib/widget";

import { Table, Input, Row, Col, message, Spin, notification } from "antd";

const Search = Input.Search;

class ServiceTypeList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      alertMessage,
      errorMessage,
      errorImport,
    } = nextProps.actualServiceTypes;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (errorImport !== "") {
      return { errorImport: errorImport };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    visibleImport: false,
    file: null,
    fileList: [],
    uploading: false,
  };

  fetchData = () => {
    const { page, limit, keyword, sorter } = this.state
    setSearch(keyword)
    setSorter(sorter)
    this.props.loadActualServiceTypes(page, limit, keyword, sorter);
  }

  onSearch(value) {
    this.setState({ keyword: value, page: 1 }, this.fetchData);
  }

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState({ page: current, limit: pageSize, sorter: { order, field } }, this.fetchData);
  };

  componentDidMount() {
    this.setState({ keyword: getSearch(), sorter: getSorter() }, this.fetchData);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      alertMessage,
      errorMessage,
      errorImport,
    } = this.props.actualServiceTypes;
    const prevMsg = prevProps.actualServiceTypes;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (errorImport !== "" && prevMsg.errorImport !== errorImport) {
      this.openNotification(errorImport);
    }
  }

  openNotification = (message) => {
    notification.error({
      message: "Import fail",
      description: message,
      duration: 0,
    });
  };

  deleteActualServiceType(id) {
    this.props.deleteActualServiceType(id);
  }

  showModalImport = () => {
    this.setState({
      visibleImport: true,
    });
  };

  handleCancel = () => {
    this.setState({ visibleImport: false });
  };

  onChooseFile = ({ file }) => {
    if (file.status !== "removed") {
      this.setState({ file, fileList: [file] });
    }
  };

  onRemove = () => {
    this.setState({ fileList: [], file: null });
  };

  handleUpload = () => {
    const { page, limit, keyword, file } = this.state;
    this.props.upload(file, page, limit, keyword);
    this.setState({ file: null, fileList: [] });
  };

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.actualServiceTypes.data.filter((x) =>
        selectedRowKeys.includes(x.actual_service_type_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.actual_service_type_id)
      );
      items = uniqueArray(
        [...removeState, ...removeData],
        "actual_service_type_id"
      );
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "actual_service_type_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter(
      (x) => x.actual_service_type_id !== id
    );
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { actualServiceTypes } = this.props;
    const { selectedRowKeys, sorter } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const actionCol = [
      {
        title: "",
        key: "actual_service_type_id",
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: "left",
        render: (actualServiceTypes) => (
          <ListAction
            editUrl={`/actual-service/types/edit/${actualServiceTypes.actual_service_type_id}`}
            onDelete={this.props.deleteActualServiceType}
            id={actualServiceTypes.actual_service_type_id}
            name="actual_service_types"
            breakpoint={500}
          />
        ),
      },
    ];

    const mainColumns = [
      {
        title: "Code",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'actual_service_type_code' ? sorter.order : false,
        dataIndex: "actual_service_type_code",
        width: 150,
      },
      {
        title: "Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'actual_service_type' ? sorter.order : false,
        dataIndex: "actual_service_type",
      },
      {
        title: "Abbr.",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'actual_service_type_abbr' ? sorter.order : false,
        dataIndex: "actual_service_type_abbr",
        width: 150,
      },
    ];

    const columns =
      window.innerWidth <= 500
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    return (
      <div>
        <Spin size="large" spinning={this.props.actualServiceTypes.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              name="actual_service_types"
              component={
                <AddBtn
                  url="/actual-service/types/new"
                  name="actual_service_types"
                />
              }
            />
            <Row gutter={[16, 16]}>
              <DeleteBadge
                count={this.state.removeData.length}
                onClick={this.showDrawer("visibleDelete")}
              />
              <Col xs={8} sm={8} md={8} lg={6} className="import-btn-align">
                <ImportExportBtn
                  showModalImport={this.showModalImport}
                  download={this.props.download}
                  name="actual_service_types"
                />
              </Col>
              <Col
                xs={13}
                sm={13}
                md={{ span: 8, offset: 6 }}
                lg={{ span: 8, offset: 9 }}
              >
                <Search
                  defaultValue={getSearch()}
                  placeholder="Search for code, abbr. or name"
                  onSearch={(value) => this.onSearch(value)}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={[
                "actual_service_type_id",
                "actual_service_type_code",
                "actual_service_type",
              ]}
            />
          </div>
          <Table
            rowKey="actual_service_type_id"
            rowSelection={rowSelection}
            bordered={true}
            size="small"
            columns={columns}
            dataSource={actualServiceTypes.data}
            pagination={{
              pageSize: this.state.limit,
              total: this.props.actualServiceTypes.totalSize,
              current: this.state.page,
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 1025 ? { x: 600 } : undefined}
          />
          <UploadModal
            visibleImport={this.state.visibleImport}
            handleCancel={this.handleCancel}
            loading={this.props.actualServiceTypes.loading}
            onChooseFile={this.onChooseFile}
            fileList={this.state.fileList}
            onRemove={this.onRemove}
            handleUpload={this.handleUpload}
            uploading={this.state.uploading}
            disabled={this.state.file ? false : true}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ actualServiceTypes }) => ({
  actualServiceTypes,
});

const mapDispatchToProps = {
  loadActualServiceTypes: actions.loadActualServiceTypes.request,
  deleteActualServiceType: actions.deleteActualServiceType.request,
  download: actions.download.request,
  upload: actions.upload.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTypeList);
