import React from "react";
import { Switch } from "react-router-dom";

import BranchHasServiceList from "./BranchHasServiceList";
import EditBranchHasServices from "./EditBranchHasServices";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/branch/actual-services/edit/:branch_id"
      component={EditBranchHasServices}
      perm="branch_actual_services"
      action="can_add"
    />
    <PrivateRoute
      path="/branch/actual-services"
      component={BranchHasServiceList}
      perm="branch_actual_services"
    />
  </Switch>
);
