import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  setBranchFilter,
  getBranchFilter,
  uniqueArray,
  extractKeyFromArray,
} from "lib/helper";

import {
  ImportExportBtn,
  UploadModal,
  ArrangementBtn,
  AddBtn,
  ListAction,
  DeleteListModal,
  DeleteBadge,
  FilterBtn,
  TagFilter,
  PageTitle,
} from "lib/widget";

import Arrangement from "./Arrangement";
import { UserOutlined } from "@ant-design/icons";
import BranchSelect from "../../BranchSelect";
import {
  Table,
  Row,
  Col,
  Avatar,
  message,
  Spin,
  notification,
  Typography,
} from "antd";

import Filter from "./Filter";
const { Text } = Typography;

class BranchHasEmployeeList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage, errorMessage } = nextProps.branchEmployees;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
    view: "table",
    branchId: null,
  };

  fetchData = () => {
    const { branchId, page, limit, sorter, cond } = this.state;
    setSorter(sorter);
    setSearch(cond);
    this.props.loadBranchEmployees(
      branchId,
      page,
      limit,
      cond,
      sorter
    );
  };

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState(
      { page: current, limit: pageSize, sorter: { order, field } },
      this.fetchData
    );
  };

  handleSearch = (val, filter) => {
    this.setState({ page: 1, visible: false, keyword: val.keyword, cond: val }, this.fetchData);
    this.getFilterTag(filter, val.keyword, val.phone_no);
  };

  getFilterTag = (filter, keyword, phone) => {
    let type = "",
      word = "",
      tel = "";

    if (keyword !== "") word = `${keyword}`;
    if (phone !== "") tel = `${phone}`;

    Object.keys(filter).map((key) => {
      filter[key].map((val) => {
        if (key === "type") type += (type !== "" ? ", " : "") + val.type_name;
      });
    });

    this.setState({
      typeTag: type !== "" ? `${type}` : "",
      keywordTag: word,
      phoneTag: tel,
    });

    setFilterTag({
      typeTag: type !== "" ? `${type}` : "",
      keywordTag: word,
      phoneTag: tel,
    })
  };

  componentDidMount() {
    let filter = getFilterTag();

    if (typeof filter === "object") {
      let branchId = getBranchFilter() !== null ? getBranchFilter() : [parseInt(localStorage.getItem("defaultBranchId"))]
      this.setState(
        {
          ...filter,
          cond: getSearch(),
          sorter: getSorter(),
          branchId: branchId,
        },
        this.fetchData
      );
    } else {
      this.setState(
        {
          keyword: getSearch(),
          branchId: [parseInt(localStorage.getItem("defaultBranchId"))],
        },
        this.fetchData
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage } = this.props.branchEmployees;
    const prevMsg = prevProps.branchEmployees;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      this.openNotification(errorMessage);
    }
  }

  openNotification = (message) => {
    notification.error({
      message: "Import fail",
      description: message,
      duration: 0,
    });
  };

  showModalImport = () => {
    this.setState({
      visibleImport: true,
    });
  };

  handleCancel = () => {
    this.setState({ visibleImport: false });
  };

  onChooseFile = ({ file }) => {
    if (file.status !== "removed") {
      this.setState({ file, fileList: [file] });
    }
  };

  onRemove = () => this.setState({ fileList: [], file: null });

  handleUpload = () => {
    const { page, limit, keyword, file } = this.state;
    this.props.importBranchHasEmployee(
      file,
      "branchHasEmployee",
      page,
      limit,
      keyword,
      this.state.branchId
    );
    this.setState({ file: null, fileList: [] });
  };

  handleChangeView = (name) => {
    this.setState({ view: name });
  };

  handleDelete = (id, branch_id) => () => {
    this.props.deleteBranchHasEmployee(id, branch_id);
  };

  handleDownload = () => {
    this.props.download(this.state.branchId);
  };

  displayAddBtn = (display, disabledAdd) => {
    return display === "" ? (
      <AddBtn
        url={`/branch/employees/edit/${this.state.branchId}`}
        name="branch_employees"
        disabled={disabledAdd}
      />
    ) : (
      ""
    );
  };

  handleChangeBranch = (value) => {
    setBranchFilter(value)
    if (value.length > 0) {
      this.setState({ branchId: value }, this.fetchData);
    } else {
      this.setState({ branchId: value });
    }
  };

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.branchEmployees.data.filter((x) =>
        selectedRowKeys.includes(x.branch_has_employee_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.branch_has_employee_id)
      );
      items = uniqueArray(
        [...removeState, ...removeData],
        "branch_has_employee_id"
      );
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "branch_has_employee_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter(
      (x) => x.branch_has_employee_id !== id
    );
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { branchEmployees } = this.props;
    const { selectedRowKeys, keywordTag, phoneTag, typeTag, sorter } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const actionCol = [
      {
        title: "",
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: "left",
        render: (data) => (
          <ListAction
            editUrl={false}
            onDelete={this.handleDelete(data.employee_id, data.branch_id)}
            id={data.branch_has_employee_id}
            name="branch_employees"
            breakpoint={500}
          />
        ),
      },
    ]

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1
      },
    ]

    const mainColumns = [
      {
        title: "Branch Name",
        dataIndex: "branch_name",
      },
      {
        title: "Image",
        dataIndex: "image",
        width: 80,
        render: (txt) => {
          return txt === ""
            ? <Avatar size={32} icon={<UserOutlined />} />
            : <Avatar size={32} src={`data:image/png;base64, ${txt}`} />
        }
      },
      {
        title: "Code",
        dataIndex: "employee_code",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'employee_code' ? sorter.order : false,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'name' ? sorter.order : false,
      },
      {
        title: "Type",
        dataIndex: "type_name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'type_name' ? sorter.order : false,
      },
      {
        title: "Order",
        dataIndex: "order",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'order' ? sorter.order : false,
        width: 80
      },
    ];

    const columns = window.innerWidth <= 500 ? [...actionCol, ...mainColumns] : [...numberCol, ...mainColumns, ...actionCol]

    let disabled = false;
    let disabledAdd = true;
    let disabledArr = true;

    let source = branchEmployees.data;
    let totalSize = this.props.branchEmployees.totalSize;

    if (this.state.branchId !== null) {
      if (this.state.branchId.length === 1) {
        disabledAdd = false;
        disabledArr = false;
      }

      if (this.state.branchId.length === 0) {
        source = [];
        totalSize = 0;
        disabled = true;
      }
    }

    const display = this.state.view !== "table" ? "none" : "";

    const ActionComp = (
      <Fragment>
        <DeleteBadge
          count={this.state.removeData.length}
          onClick={this.showDrawer("visibleDelete")}
        />
        <Col xs={14} sm={14} md={10} lg={9}>
          <ArrangementBtn
            name="branch_employees"
            disabled={disabledArr}
            onClick={this.handleChangeView}
            branchId={this.state.branchId}
            display={display}
          />
          {display === "" && (
            <ImportExportBtn
              disabledDownload={disabled}
              showModalImport={this.showModalImport}
              download={this.handleDownload}
              name="branch_employees"
              breakpoint={800}
            />
          )}
        </Col>
      </Fragment>
    );

    return (
      <div>
        <Spin size="large" spinning={this.props.branchEmployees.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              name="branch_employees"
              component={this.displayAddBtn(display, disabledAdd)}
            />
            <Row gutter={[16, 16]}>
              {window.innerWidth > 500 && ActionComp}
              <Col xs={24} md={{ span: 8 }} sm={24} lg={{ span: 6, offset: 5 }}>
                <BranchSelect
                  branchId={this.state.branchId}
                  branches={this.props.branches}
                  onChange={this.handleChangeBranch}
                />
              </Col>
              {window.innerWidth <= 500 && ActionComp}
              <FilterBtn showDrawer={this.showDrawer} col={7} />
              <Filter
                list={source}
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col span={20}>
                {TagFilter([keywordTag], "Keyword", "green")}
                {TagFilter([phoneTag], "Phone No.", "cyan")}
                {TagFilter([typeTag], "Type", "orange")}
              </Col>
            </Row>

            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["branch_has_employee_id", "employee_code", "name"]}
            />
          </div>
          {this.state.view === "table" ? (
            <Table
              rowKey={(record) => record.branch_has_employee_id}
              columns={columns}
              rowSelection={rowSelection}
              bordered={true}
              size="small"
              dataSource={source}
              pagination={{
                pageSize: this.state.limit,
                total: totalSize,
                current: this.state.page,
              }}
              onChange={this.onChangePage}
              scroll={window.innerWidth <= 500 ? { x: 800 } : undefined}
            />
          ) : (
            <Arrangement
              dataSource={branchEmployees.data}
              onUpdate={this.props.updateOrder}
              onCancel={this.handleChangeView}
              branchId={this.state.branchId}
            />
          )}

          <UploadModal
            visibleImport={this.state.visibleImport}
            handleCancel={this.handleCancel}
            loading={this.props.branchEmployees.loading}
            onChooseFile={this.onChooseFile}
            fileList={this.state.fileList}
            onRemove={this.onRemove}
            handleUpload={this.handleUpload}
            uploading={this.state.uploading}
            disabled={this.state.file ? false : true}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ branchEmployees, branches }) => ({
  branchEmployees,
  branches: branches.data,
});

const mapDispatchToProps = {
  importBranchHasEmployee: actions.importBranchHasEmployee.request,
  download: actions.download.request,
  updateOrder: actions.updateOrder.request,
  deleteBranchHasEmployee: actions.deleteBranchHasEmployee.request,
  loadBranchEmployees: actions.loadBranchEmployees.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchHasEmployeeList);
