import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Col, Button, Badge } from "antd";


const DeleteBadge = ({ count, onClick }) => {
  return (
    <Col xs={3} sm={3} md={2} lg={1}>
      { count > 0 ?
     <Badge count={count}>
        <Button  icon={<DeleteOutlined />} onClick={onClick}/>
      </Badge> :
     <Button  icon={<DeleteOutlined />} disabled={true}/>
    }
    </Col>
  );
};

export default DeleteBadge;
