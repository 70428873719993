import { createTypes, createAction } from "lib/action";

const DOWNLOAD_DAILY = createTypes("daily", "download");
const DOWNLOAD_EMPLOYEE_REPORT = createTypes("employeeReport", "download");
const DOWNLOAD_CURRENT_STOCK = createTypes("currentStock", "download");
const DOWNLOAD_STOCK = createTypes("stock", "download");
const DOWNLOAD_ERP_TRANSACTION_REPORT = createTypes(
  "erp_transaction_report",
  "download"
);
const DOWLOAD_ERP_CUSTOMER = createTypes("erp_customer", "download");
const DOWLOAD_GOODS_RECEIVED = createTypes("goods_received_report", "download");
const DOWLOAD_SALON_USED = createTypes("salon_used_report", "download");
const DOWLOAD_PHYSICAL_COUNT = createTypes("physical_count_report", "download");
const DOWLOAD_TRANSFER_REPORT = createTypes("transfer_report", "download");
const DOWLOAD_STOCK_BENCHMARK_REPORT = createTypes("stock_benchmark", "download");
const DOWLOAD_PAYMENT_REPORT = createTypes("payment_report", "download");
const DOWLOAD_CUSTOMER_RETENTION_REPORT = createTypes("customer_retention_report", "download");

const LOAD_REPORT_BRANCH = createTypes("load_report_branch", "list");
const LOAD_REPORT_ALLBRANCH = createTypes("load_report_allbranch", "list");

const DOWLOAD_STOCKEXCHANGE_REPORT = createTypes("downloadStockExchange", "download")

const loadReportBranch = {
  request: () => createAction(LOAD_REPORT_BRANCH.REQUEST),
  success: data => createAction(LOAD_REPORT_BRANCH.SUCCESS, { data }),
  failure: error => createAction(LOAD_REPORT_BRANCH.FAILURE, { error })
}

const loadReportAllBranch = {
  request: () => createAction(LOAD_REPORT_ALLBRANCH.REQUEST),
  success: data => createAction(LOAD_REPORT_ALLBRANCH.SUCCESS, { data }),
  failure: error => createAction(LOAD_REPORT_ALLBRANCH.FAILURE, { error })
}

const downloadEmployee = {
  request: (branchId, startDate, endDate, pickStartDate, pickEndDate) =>
    createAction(DOWNLOAD_EMPLOYEE_REPORT.REQUEST, {
      branchId,
      startDate,
      endDate, pickStartDate, pickEndDate
    }),
  success: employeeReport =>
    createAction(DOWNLOAD_EMPLOYEE_REPORT.SUCCESS, { employeeReport }),
  failure: error => createAction(DOWNLOAD_EMPLOYEE_REPORT.FAILURE, { error })
};

const downloadDaily = {
  request: (branchId, startDate, endDate, pickStartDate, pickEndDate, fileType) =>
    createAction(DOWNLOAD_DAILY.REQUEST, { branchId, startDate, endDate, pickStartDate, pickEndDate, fileType }),
  success: daily => createAction(DOWNLOAD_DAILY.SUCCESS, { daily }),
  failure: error => createAction(DOWNLOAD_DAILY.FAILURE, { error })
};

const downloadStockExchange = {
  request: (branchId, startDate, endDate, pickStartDate, pickEndDate, fileType) =>
    createAction(DOWLOAD_STOCKEXCHANGE_REPORT.REQUEST, { branchId, startDate, endDate, pickStartDate, pickEndDate, fileType }),
  success: daily => createAction(DOWLOAD_STOCKEXCHANGE_REPORT.SUCCESS, { daily }),
  failure: error => createAction(DOWLOAD_STOCKEXCHANGE_REPORT.FAILURE, { error })
}

const downloadCustomerRetention = {
  request: (branchId, startDate, endDate, pickStartDate, pickEndDate) =>
    createAction(DOWLOAD_CUSTOMER_RETENTION_REPORT.REQUEST, { branchId, startDate, endDate, pickStartDate, pickEndDate }),
  success: daily => createAction(DOWLOAD_CUSTOMER_RETENTION_REPORT.SUCCESS, { daily }),
  failure: error => createAction(DOWLOAD_CUSTOMER_RETENTION_REPORT.FAILURE, { error })
};

const downloadCurrentStock = {
  request: branchId =>
    createAction(DOWNLOAD_CURRENT_STOCK.REQUEST, { branchId }),
  success: stock => createAction(DOWNLOAD_CURRENT_STOCK.SUCCESS, { stock }),
  failure: error => createAction(DOWNLOAD_CURRENT_STOCK.FAILURE, { error })
};

const downloadStock = {
  request: (branchId, startDate, endDate, type) =>
    createAction(DOWNLOAD_STOCK.REQUEST, {
      branchId,
      startDate,
      endDate,
      type
    }),
  success: stock => createAction(DOWNLOAD_STOCK.SUCCESS, { stock }),
  failure: error => createAction(DOWNLOAD_STOCK.FAILURE, { error })
};

const downloadStockBenchMark = {
  request: (branchId, fileType) =>
    createAction(DOWLOAD_STOCK_BENCHMARK_REPORT.REQUEST, {
      branchId, fileType
    }),
  success: stock => createAction(DOWLOAD_STOCK_BENCHMARK_REPORT.SUCCESS, { stock }),
  failure: error => createAction(DOWLOAD_STOCK_BENCHMARK_REPORT.FAILURE, { error })
};

const downloadErpTransaction = {
  request: (branchId, startDate, endDate, pickStartDate, pickEndDate) =>
    createAction(DOWNLOAD_ERP_TRANSACTION_REPORT.REQUEST, {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate
    }),
  success: report =>
    createAction(DOWNLOAD_ERP_TRANSACTION_REPORT.SUCCESS, { report }),
  failure: error => createAction(DOWNLOAD_ERP_TRANSACTION_REPORT.FAILURE, { error })
};

const downloadErpCustomer = {
  request: (branchId) =>
    createAction(DOWLOAD_ERP_CUSTOMER.REQUEST, { branchId }),
  success: report =>
    createAction(DOWLOAD_ERP_CUSTOMER.SUCCESS, { report }),
  failure: error => createAction(DOWLOAD_ERP_CUSTOMER.FAILURE, { error })
};

const downloadGoodsReceived = {
  request: (branchId, startDate, endDate, pickStartDate, pickEndDate, fileType) =>
    createAction(DOWLOAD_GOODS_RECEIVED.REQUEST, {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate,
      fileType
    }),
  success: report =>
    createAction(DOWLOAD_GOODS_RECEIVED.SUCCESS, { report }),
  failure: error => createAction(DOWLOAD_GOODS_RECEIVED.FAILURE, { error })
};


const downloadSalonUsed = {
  request: (branchId, startDate, endDate, pickStartDate, pickEndDate, fileType) =>
    createAction(DOWLOAD_SALON_USED.REQUEST, {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate,
      fileType
    }),
  success: report =>
    createAction(DOWLOAD_SALON_USED.SUCCESS, { report }),
  failure: error => createAction(DOWLOAD_SALON_USED.FAILURE, { error })
};

const downloadPhysicalCount = {
  request: (branchId, startDate, endDate, pickStartDate, pickEndDate) =>
    createAction(DOWLOAD_PHYSICAL_COUNT.REQUEST, {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate
    }),
  success: report =>
    createAction(DOWLOAD_PHYSICAL_COUNT.SUCCESS, { report }),
  failure: error => createAction(DOWLOAD_PHYSICAL_COUNT.FAILURE, { error })
};

const downloadTransferReport = {
  request: (branchId, startDate, endDate, pickStartDate, pickEndDate, fileType) =>
    createAction(DOWLOAD_TRANSFER_REPORT.REQUEST, {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate,
      fileType
    }),
  success: report =>
    createAction(DOWLOAD_TRANSFER_REPORT.SUCCESS, { report }),
  failure: error => createAction(DOWLOAD_TRANSFER_REPORT.FAILURE, { error })
};

const downloadPayment = {
  request: (branchId, startDate, endDate, pickStartDate, pickEndDate, fileType) =>
    createAction(DOWLOAD_PAYMENT_REPORT.REQUEST, {
      branchId,
      startDate,
      endDate,
      pickStartDate,
      pickEndDate,
      fileType
    }),
  success: report =>
    createAction(DOWLOAD_PAYMENT_REPORT.SUCCESS, { report }),
  failure: error => createAction(DOWLOAD_PAYMENT_REPORT.FAILURE, { error })
}

export {
  DOWNLOAD_DAILY,
  downloadDaily,
  DOWNLOAD_CURRENT_STOCK,
  downloadCurrentStock,
  DOWNLOAD_EMPLOYEE_REPORT,
  downloadEmployee,
  DOWNLOAD_STOCK,
  downloadStock,
  DOWNLOAD_ERP_TRANSACTION_REPORT,
  downloadErpTransaction,
  DOWLOAD_ERP_CUSTOMER,
  downloadErpCustomer,
  DOWLOAD_GOODS_RECEIVED,
  downloadGoodsReceived,
  DOWLOAD_SALON_USED,
  downloadSalonUsed,
  DOWLOAD_PHYSICAL_COUNT,
  downloadPhysicalCount,
  DOWLOAD_TRANSFER_REPORT,
  downloadTransferReport,
  DOWLOAD_STOCK_BENCHMARK_REPORT,
  downloadStockBenchMark,
  LOAD_REPORT_BRANCH,
  loadReportBranch,
  DOWLOAD_PAYMENT_REPORT,
  downloadPayment,
  DOWLOAD_CUSTOMER_RETENTION_REPORT,
  downloadCustomerRetention,
  loadReportAllBranch,
  LOAD_REPORT_ALLBRANCH,
  DOWLOAD_STOCKEXCHANGE_REPORT,
  downloadStockExchange
};