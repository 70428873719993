import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError, logErrorMsg } from 'lib/fetch'
import * as actions from "./actions";


function* loadServicePackageGroups(action) {
  const { pagination, limit, keyword,  sort } = action.payload;
    try {
      const res = yield fetcher.post('packagegroups/packagegroups_list', { pagination, limit, keyword, sort })
      yield put(actions.loadServicePackageGroups.success(res.data));
    } catch (error) {
      handleError(error)
    }
}

function* loadServicePackageGroup(action) {
  const { packageGroupId } = action.payload;
  try {
    const res = yield fetcher.get(`packagegroups/${packageGroupId}`)
    yield put(actions.loadServicePackageGroup.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadServicePackageGroup.failure(getErrorMsg(error)));
    logErrorMsg(error)
  }
}

function* createServicePackageGroup(action) {
  try {
    const { code, name } = action.payload;
    const res = yield fetcher.post('packagegroups', {
      service_package_group_code: code,
      service_package_group: name
    })
    yield put(actions.createServicePackageGroup.success(res.data));
    yield put(push("/service-package/groups"));
  } catch (error) {
    handleError(error)
    yield put(actions.createServicePackageGroup.failure(getErrorMsg(error)));
    yield put(push("/service-package/groups/new"));
  }
}

function* editServicePackageGroup(action) {
  const { id, code, name } = action.payload;
  try {
    const res = yield fetcher.patch(`packagegroups/${id}`, {
      service_package_group_code: code,
      service_package_group: name
    })
    yield put(actions.editServicePackageGroup.success(res.data));
    yield put(push("/service-package/groups"));
  } catch (error) {
    handleError(error)
    yield put(actions.editServicePackageGroup.failure(getErrorMsg(error)));
    yield put(push(`/service-package/groups/edit/${id}`));
  }
}

function* deleteServicePackageGroup(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`packagegroups/${id}`)
    yield put(actions.deleteServicePackageGroup.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.deleteServicePackageGroup.failure(getErrorMsg(error)));
  }
}

function* upload(action) {
  try {
    const { file, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", "servicePackageGroup");
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    const res = yield fetcher.post('import/details', formData, 'upload')
    yield put(actions.upload.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.upload.failure(getErrorMsg(error)));
    yield put(push("/service-package/groups"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/export_masterdata", { type: "servicePackageGroup" });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
    yield put(actions.upload.failure(getErrorMsg(error)));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/servicepackage/group`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchServicePackageGroups() {
  yield all([
    takeEvery(
      actions.LOAD_SERVICE_PACKAGE_GROUPS.REQUEST,
      loadServicePackageGroups
    ),
    takeEvery(
      actions.LOAD_SERVICE_PACKAGE_GROUP.REQUEST,
      loadServicePackageGroup
    ),
    takeEvery(
      actions.CREATE_SERVICE_PACKAGE_GROUP.REQUEST,
      createServicePackageGroup
    ),
    takeEvery(
      actions.EDIT_SERVICE_PACKAGE_GROUP.REQUEST,
      editServicePackageGroup
    ),
    takeEvery(
      actions.DELETE_SERVICE_PACKAGE_GROUP.REQUEST,
      deleteServicePackageGroup
    ),
    takeEvery(actions.UPLOAD_FILE.REQUEST, upload),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
