import { createTypes, createAction } from "lib/action";

const LOAD_SERVICE_PACKAGES = createTypes("servicePackages", "list");
const LOAD_SERVICE_PACKAGE = createTypes("servicePackages", "show");
const CREATE_SERVICE_PACKAGE = createTypes("servicePackages", "create");
const EDIT_SERVICE_PACKAGE = createTypes("servicePackages", "edit");
const DELETE_SERVICE_PACKAGE = createTypes("servicePackages", "delete");
const DESTROY_MANY = createTypes("servicePackages", "destory");

const LOAD_SERVICE_PACKAGE_DETAILS = createTypes(
  "servicePackageDetails",
  "list"
);
const LOAD_SERVICE_PACKAGE_DETAIL = createTypes(
  "servicePackageDetails",
  "show"
);
const CREATE_SERVICE_PACKAGE_DETAIL = createTypes(
  "servicePackageDetails",
  "create"
);
const EDIT_SERVICE_PACKAGE_DETAIL = createTypes(
  "servicePackageDetails",
  "edit"
);
const DELETE_SERVICE_PACKAGE_DETAIL = createTypes(
  "servicePackageDetails",
  "delete"
);
const IMPORT_SERVICE_PACKAGE = createTypes("servicePackages", "import");
const DOWNLOAD_FILE = createTypes("servicePackages", "export");

const loadServicePackages = {
  request: (pagination, limit, cond, sort) =>
    createAction(LOAD_SERVICE_PACKAGES.REQUEST, {
      pagination,
      limit,
      cond,
      sort
    }),
  success: servicePackages =>
    createAction(LOAD_SERVICE_PACKAGES.SUCCESS, { servicePackages }),
  failure: (error) => createAction(LOAD_SERVICE_PACKAGES.FAILURE, { error })
};

const loadServicePackage = {
  request: servicePackageId =>
    createAction(LOAD_SERVICE_PACKAGE.REQUEST, {
      servicePackageId
    }),
  success: servicePackage =>
    createAction(LOAD_SERVICE_PACKAGE.SUCCESS, { servicePackage }),
  failure: error => createAction(LOAD_SERVICE_PACKAGE.FAILURE, { error })
};

const createServicePackage = {
  request: (servicePackage, checkedList, actualservice) =>
    createAction(CREATE_SERVICE_PACKAGE.REQUEST, {
      servicePackage,
      checkedList,
      actualservice
    }),
  success: servicePackage =>
    createAction(CREATE_SERVICE_PACKAGE.SUCCESS, { servicePackage }),
  failure: error => createAction(CREATE_SERVICE_PACKAGE.FAILURE, { error })
};

const editServicePackage = {
  request: (id, servicePackage, checkedList) =>
    createAction(EDIT_SERVICE_PACKAGE.REQUEST, {
      id,
      servicePackage,
      checkedList
    }),
  success: servicePackage =>
    createAction(EDIT_SERVICE_PACKAGE.SUCCESS, {
      servicePackage
    }),
  failure: error => createAction(EDIT_SERVICE_PACKAGE.FAILURE, { error })
};

const loadServicePackageDetails = {
  request: servicePackageId =>
    createAction(LOAD_SERVICE_PACKAGE_DETAILS.REQUEST, { servicePackageId }),
  success: servicePackageDetails =>
    createAction(LOAD_SERVICE_PACKAGE_DETAILS.SUCCESS, {
      servicePackageDetails
    }),
  failure: () => createAction(LOAD_SERVICE_PACKAGE_DETAILS.FAILURE)
};

const loadServicePackageDetail = {
  request: serviceId =>
    createAction(LOAD_SERVICE_PACKAGE_DETAIL.REQUEST, { serviceId }),
  success: servicePackageDetail =>
    createAction(LOAD_SERVICE_PACKAGE_DETAIL.SUCCESS, { servicePackageDetail }),
  failure: error => createAction(LOAD_SERVICE_PACKAGE_DETAIL.FAILURE, { error })
};

const createServicePackageDetail = {
  request: (servicePackageId, serviceDetail) =>
    createAction(CREATE_SERVICE_PACKAGE_DETAIL.REQUEST, {
      servicePackageId,
      serviceDetail
    }),
  success: serviceDetail =>
    createAction(CREATE_SERVICE_PACKAGE_DETAIL.SUCCESS, { serviceDetail }),
  failure: error =>
    createAction(CREATE_SERVICE_PACKAGE_DETAIL.FAILURE, { error })
};

const editServicePackageDetail = {
  request: (id, servicePackageDetail) =>
    createAction(EDIT_SERVICE_PACKAGE_DETAIL.REQUEST, {
      id,
      servicePackageDetail
    }),
  success: servicePackageDetail =>
    createAction(EDIT_SERVICE_PACKAGE_DETAIL.SUCCESS, {
      servicePackageDetail
    }),
  failure: error => createAction(EDIT_SERVICE_PACKAGE_DETAIL.FAILURE, { error })
};

const deleteServicePackage = {
  request: id => createAction(DELETE_SERVICE_PACKAGE.REQUEST, { id }),
  success: servicePackage =>
    createAction(DELETE_SERVICE_PACKAGE.SUCCESS, { servicePackage }),
  failure: error => createAction(DELETE_SERVICE_PACKAGE.FAILURE, { error })
};

const deleteServicePackageDetail = {
  request: id => createAction(DELETE_SERVICE_PACKAGE_DETAIL.REQUEST, { id }),
  success: serviceDetail =>
    createAction(DELETE_SERVICE_PACKAGE_DETAIL.SUCCESS, { serviceDetail }),
  failure: error =>
    createAction(DELETE_SERVICE_PACKAGE_DETAIL.FAILURE, { error })
};

const importServicePackage = {
  request: (file, type, pagination, limit, keyword, adjustPrice) =>
    createAction(IMPORT_SERVICE_PACKAGE.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword,
      adjustPrice
    }),
  success: servicePackage =>
    createAction(IMPORT_SERVICE_PACKAGE.SUCCESS, { servicePackage }),
  failure: error => createAction(IMPORT_SERVICE_PACKAGE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_SERVICE_PACKAGES,
  loadServicePackages,
  LOAD_SERVICE_PACKAGE,
  loadServicePackage,
  LOAD_SERVICE_PACKAGE_DETAILS,
  loadServicePackageDetails,
  LOAD_SERVICE_PACKAGE_DETAIL,
  loadServicePackageDetail,
  CREATE_SERVICE_PACKAGE,
  createServicePackage,
  CREATE_SERVICE_PACKAGE_DETAIL,
  createServicePackageDetail,
  EDIT_SERVICE_PACKAGE,
  editServicePackage,
  EDIT_SERVICE_PACKAGE_DETAIL,
  editServicePackageDetail,
  DELETE_SERVICE_PACKAGE,
  deleteServicePackage,
  DELETE_SERVICE_PACKAGE_DETAIL,
  deleteServicePackageDetail,
  IMPORT_SERVICE_PACKAGE,
  importServicePackage,
  DOWNLOAD_FILE,
  download,
  DESTROY_MANY,
  destroyMany
};
