import React, { PureComponent } from "react";
import { Select } from "antd";
import axios from "axios";
import { extractKeyFromArray , } from "lib/helper";

export class SelectPermGroup extends PureComponent {
  state = {
    groups: [],
    bkGroups: [],
    selected: [],
    isAll: null
  };

  onGroupChange = (value, option) => {
    let groupId = value;
    let selected = [];
    let groups = this.state.groups;

    if (value.includes("all")) {
      this.setState({ isAll: false });
      groupId = extractKeyFromArray (groups, "permission_group_id");
      selected = groupId;
    } else if (value.includes("none")) {
      groupId = [];
      selected = [];
      this.setState({ isAll: null });
    } else {
      this.setState({ isAll: true });
      selected = value.map(val => {
        let idx = groups.findIndex(
          x =>
            x.permission_group_id.toString() === val || x.permission_group_id === val
        );
        return (idx > -1) ? groups[idx].permission_group_id : '';
      });
    }

    this.props.onChange(selected);
    this.setState({ selected: groupId, groups: this.state.bkGroups });
  };

  getAllGroupOpt = data => {
    let groups = [
      {
        permission_group_id: this.state.isAll === false ? "none" : "all",
        display_name:
          this.state.isAll === false ? "Clear All Groups" : "All Groups"
      },
      ...data
    ];
    return groups;
  };

  getData = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/permissions/groups`,
        {
          pagination: null,
          limit: null,
          keyword: null
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      this.setState({ groups: res.data.data, bkGroups: res.data.data });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.getData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectGroup !== prevState.selected) {
      return { selectGroup: nextProps.selectGroup };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectGroup !== prevProps.selectGroup &&
      typeof this.props.selectGroup !== "undefined"
    ) {
      let selected = extractKeyFromArray (
        this.props.selectGroup,
        "permission_group_id",
        true
      );
      this.setState({
        selected: selected,
        isAll: selected.length > 0 ? false : true
      });
      this.props.onChange(selected);
    }
  }

  handleSearch = value => {
    let rx = new RegExp(value, 'i')
    let data = this.state.bkGroups.filter(x => x.display_name.match(rx))
    this.setState({ groups: data })
  }

  render() {
    return (
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Please select"
        value={this.state.selected}
        onChange={this.onGroupChange}
        filterOption={false}
        onSearch={this.handleSearch}
      >
        {this.getAllGroupOpt(this.state.groups).map((val, i) => (
          <Select.Option key={val.permission_group_id} value={val.permission_group_id}>
            {val.display_name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default SelectPermGroup;
