import { createTypes, createAction } from "lib/action";

const LOAD_BRANCH_CATEGORIES = createTypes("branchCategories", "list");
const EDIT_BRANCH_HAS_CATEGORY = createTypes("branchCategories", "edit");
const DELETE_BRANCH_HAS_CATEGORY = createTypes("branchCategories", "delete");

const IMPORT_BRANCH_HAS_CATEGORY = createTypes("branchCategories", "import");
const DOWNLOAD_FILE = createTypes("branchCategories", "export");
const DESTROY_MANY = createTypes("branchCategories", "destory");

const loadBranchCategories = {
  request: (branchId, pagination, limit, keyword, sort) =>
    createAction(LOAD_BRANCH_CATEGORIES.REQUEST, { branchId, pagination, limit, keyword, sort }),
  success: branchCategories =>
    createAction(LOAD_BRANCH_CATEGORIES.SUCCESS, {
      branchCategories
    }),
  failure: () => createAction(LOAD_BRANCH_CATEGORIES.FAILURE)
};

const editBranchHasCategory = {
  request: (branch_id, categories) => createAction(EDIT_BRANCH_HAS_CATEGORY.REQUEST, { branch_id, categories }),
  success: (categories) => createAction(EDIT_BRANCH_HAS_CATEGORY.SUCCESS, { categories }),
  failure: error => createAction(EDIT_BRANCH_HAS_CATEGORY.FAILURE, { error })
}

const deleteBranchHasCategory = {
  request: (id, branch_id) => createAction(DELETE_BRANCH_HAS_CATEGORY.REQUEST, { id, branch_id }),
  success: category => createAction(DELETE_BRANCH_HAS_CATEGORY.SUCCESS, { category }),
  failure: error => createAction(DELETE_BRANCH_HAS_CATEGORY.FAILURE, { error })
}

const importBranchHasCategory = {
  request: (file, type, pagination, limit, keyword, branchId) =>
    createAction(IMPORT_BRANCH_HAS_CATEGORY.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword,
      branchId
    }),
  success: branchCategory =>
    createAction(IMPORT_BRANCH_HAS_CATEGORY.SUCCESS, {
      branchCategory
    }),
  failure: error => createAction(IMPORT_BRANCH_HAS_CATEGORY.FAILURE, { error })
};

const download = {
  request: branch_id => createAction(DOWNLOAD_FILE.REQUEST, { branch_id }),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_BRANCH_CATEGORIES,
  IMPORT_BRANCH_HAS_CATEGORY,
  DOWNLOAD_FILE,
  EDIT_BRANCH_HAS_CATEGORY,
  DELETE_BRANCH_HAS_CATEGORY,
  loadBranchCategories,
  importBranchHasCategory,
  download,
  editBranchHasCategory,
  deleteBranchHasCategory,
  DESTROY_MANY,
  destroyMany
};
