import { createTypes, createAction } from "lib/action";

const LOAD_BRANCH_ACTUAL_SERVICES = createTypes("branchActualServices", "list");
const IMPORT_BRANCH_HAS_ACTUAL_SERVICE = createTypes(
  "branchActualServices",
  "import"
);
const DOWNLOAD_FILE = createTypes("branchActualServices", "export");
const EDIT_BRANCH_HAS_ACTUAL_SERVICE = createTypes("branchActualServices", "edit");
const DELETE_BRANCH_HAS_SERVICE = createTypes("branchActualServices", "delete");
const DESTROY_MANY = createTypes("branchActualServices", "destory");

const loadBranchActualServices = {
  request: (branchId, pagination, limit, cond, sort) =>
    createAction(LOAD_BRANCH_ACTUAL_SERVICES.REQUEST, { branchId, pagination, limit, cond, sort }),
  success: branchActualServices =>
    createAction(LOAD_BRANCH_ACTUAL_SERVICES.SUCCESS, { branchActualServices }),
  failure: error => createAction(LOAD_BRANCH_ACTUAL_SERVICES.FAILURE, { error })
};

const importBranchHasActualService = {
  request: (file, type, pagination, limit, keyword, branchId) =>
    createAction(IMPORT_BRANCH_HAS_ACTUAL_SERVICE.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword,
      branchId
    }),
  success: branchActualService =>
    createAction(IMPORT_BRANCH_HAS_ACTUAL_SERVICE.SUCCESS, {
      branchActualService
    }),
  failure: error =>
    createAction(IMPORT_BRANCH_HAS_ACTUAL_SERVICE.FAILURE, { error })
};

const download = {
  request: branch_id => createAction(DOWNLOAD_FILE.REQUEST, { branch_id }),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const editBranchHasActualService = {
  request: (branch_id, services) => createAction(EDIT_BRANCH_HAS_ACTUAL_SERVICE.REQUEST, { branch_id, services }),
  success: (services) => createAction(EDIT_BRANCH_HAS_ACTUAL_SERVICE.SUCCESS, { services }),
  failure: error => createAction(EDIT_BRANCH_HAS_ACTUAL_SERVICE.FAILURE, { error })
}

const deleteBranchHasService = {
  request: (id, branch_id) => createAction(DELETE_BRANCH_HAS_SERVICE.REQUEST, { id, branch_id }),
  success: service => createAction(DELETE_BRANCH_HAS_SERVICE.SUCCESS, { service }),
  failure: error => createAction(DELETE_BRANCH_HAS_SERVICE.FAILURE, { error })
}

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_BRANCH_ACTUAL_SERVICES,
  loadBranchActualServices,
  IMPORT_BRANCH_HAS_ACTUAL_SERVICE,
  importBranchHasActualService,
  DOWNLOAD_FILE,
  download,
  EDIT_BRANCH_HAS_ACTUAL_SERVICE,
  editBranchHasActualService,
  DELETE_BRANCH_HAS_SERVICE,
  deleteBranchHasService,
  DESTROY_MANY,
  destroyMany
};
