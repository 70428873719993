import { Form, Input, message, Spin, InputNumber } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import * as actions from "../actions";
import { checkPermission } from "lib/helper";
import { PageTitle, FormBtn } from "lib/widget";

const FormItem = Form.Item;

class EditPackageDetail extends Component {
  componentDidMount() {
    this.props.loadServicePackageDetail(
      this.props.match.params.service_package_detail_id
    );
  }

  editServicePackageDetail = async servicePackageDetail => {
    this.props.editServicePackageDetail(
      this.props.match.params.service_package_detail_id,
      servicePackageDetail
    );
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.alertMessage !== this.props.alertMessage &&
      nextProps.alertMessage !== ""
    ) {
      message.success(nextProps.alertMessage);
    }
    if (nextProps.errorMessage !== "") {
      message.error(nextProps.errorMessage);
    }
  };

  render() {
    const { servicePackageDetail } = this.props;
    const disabled = checkPermission(
      "service_package_service_packages",
      "can_edit",
      false
    );
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      }
    };

    return (
      <div>
        <Spin size="large" spinning={this.props.loading}>
          <PageTitle name="service_package_service_packages" component={null} />
          <Formik
            initialValues={servicePackageDetail}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              actual_service_id: yup.number().required(),
              description: yup
                .string()
                .required("Description is a required field")
            })}
            onSubmit={values => this.editServicePackageDetail(values)}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form onFinish={handleSubmit} autoComplete="off">
                <fieldset disabled={!disabled}>
                  <Input
                    name="actual_service_id"
                    value={values.actual_service_id}
                    type="hidden"
                  />
                  <Input
                    name="service_package_id"
                    value={values.service_package_id}
                    type="hidden"
                  />
                  <FormItem {...formItemLayout} label="Barcode">
                    <Input name="barcoed" value={values.barcode} disabled />
                  </FormItem>
                  <FormItem {...formItemLayout} label="Actual service">
                    <Input
                      name="service_name"
                      value={values.service_name}
                      disabled
                    />
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label="Quantity"
                    validateStatus={errors.quantity && "error"}
                    help={touched.quantity && errors.quantity}
                  >
                    <InputNumber
                      placeholder="0"
                      name="quantity"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.quantity}
                      disabled
                    />
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label="Description"
                    validateStatus={errors.description && "error"}
                    help={touched.description && errors.description}
                  >
                    <Input
                      placeholder="description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                  </FormItem>
                  <FormBtn
                    url="/service-package/packages"
                    canEdit={disabled}
                  />
                </fieldset>
              </Form>
            )}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ servicePackages }) => {
  return {
    servicePackageDetail: servicePackages.detail,
    alertMessage: servicePackages.alertMessage,
    errorMessage: servicePackages.errorMessage,
    loading: servicePackages.loading
  };
};

const mapDispatchToProps = {
  editServicePackageDetail: actions.editServicePackageDetail.request,
  loadServicePackageDetail: actions.loadServicePackageDetail.request
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPackageDetail);
