import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Row, Col, Button, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import Widget from "./Widget";
import StylistTable from "./StylistTable";
import Filter from "./Filter";

import * as moment from "moment";

import { getIdtoArray } from "lib/helper";
import { fetchGo } from "lib/fetch";
import { getComparedFilter } from "lib/go-api-helper";

export class Layout extends Component {
  state = {
    picker: "month",
    duration: moment().format("YYYY-MM"),
    branch_id: [],
    category_id: [],
    export_loading: false,
  };

  handleSearch = (picker, duration, branch_id, category_id) => {
    this.setState(
      {
        picker,
        duration,
        branch_id,
        category_id,
      },
      this.fetchData
    );
  };

  getComparedLabel = () => {
    switch (this.state.picker) {
      case "date":
      case "week":
        return "week";
      case "month":
        return "month";
    }
  };

  handleExportExcel = () => {
    const { picker, duration, branch_id, category_id } = this.state;
    this.setState({ export_loading: true });
    fetchGo
      .postData(`stylish_analytics_export`, {
        type: picker,
        duration,
        branch_id,
        category_id,
      })
      .then((res) => {
        window.location.assign(res.data.result);
        this.setState({ export_loading: false });
      });
  };

  fetchData = () => {
    let { picker, duration, branch_id, category_id } = this.state;
    if (branch_id.length === 0) return;

    if (picker == "week") duration = getComparedFilter(picker, duration);
    this.props.loadDataTable(picker, duration, branch_id, category_id);
    this.props.loadWidgetData(picker, duration, branch_id, category_id);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.branches.length !== prevProps.branches.length) {
      this.setState(
        { branch_id: getIdtoArray(this.props.branches, "value") },
        this.fetchData
      );
    }
  }

  componentDidMount() {
    this.setState(
      { branch_id: getIdtoArray(this.props.branches, "value") },
      this.fetchData
    );
  }

  render() {
    const {
      loading_datatable,
      analytic_datatable,
      loading_widget,
      analytic_widget,
      branches,
    } = this.props;

    const { picker, duration, branch_id, export_loading } = this.state;

    return (
      <Fragment>
        <Spin spinning={export_loading}>
          <Filter onSearch={this.handleSearch} branches={branches} />
          <Row gutter={[8, 16]}>
            <Col lg={24}>
              <Widget
                loading={loading_widget}
                data={analytic_widget}
                comparedLabel={this.getComparedLabel()}
              />
            </Col>
          </Row>
          <Row gutter={[8, 16]} justify="end">
            <Col span={2} className="align-right">
              <Button
                disabled={this.state.view === "group"}
                onClick={this.handleExportExcel}
                icon={<UploadOutlined />}
              >
                Export
              </Button>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}>
              <StylistTable
                comparedLabel={this.getComparedLabel()}
                loading_datatable={loading_datatable}
                analytic_datatable={analytic_datatable}
                type={picker}
                duration={duration}
                branch_id={branch_id}
              />
            </Col>
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ stylist_analytic }) => ({
  loading_datatable: stylist_analytic.loading_datatable,
  analytic_datatable: stylist_analytic.analytic_datatable,
  loading_widget: stylist_analytic.loading_widget,
  analytic_widget: stylist_analytic.analytic_widget,
});

const mapDispatchToProps = {
  loadDataTable: actions.loadStylistDataTable.request,
  loadWidgetData: actions.loadStylistWidgetData.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
