import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadBranchTerminals(action) {
  try {
    const { branchId } = action.payload;
    const res = yield fetcher.post('terminals/terminals_list', { branch_id: branchId })
    yield put(actions.loadBranchTerminals.success(res.data));
  } catch (error) {
    handleError(error)
  }
}

function* loadTerminal(action) {
  try {
    const { terminalId } = action.payload;
    const res = yield fetcher.get(`terminals/${terminalId}`)
    yield put(actions.loadTerminal.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadTerminal.failure(getErrorMsg(error)));
  }
}

function* createTerminal(action) {
  try {
    const { terminal } = action.payload;
    const res = yield fetcher.post('terminals', {
      terminal_code: terminal.terminal_code,
      terminal_name: terminal.terminal_name,
      register_id: terminal.register_id,
      branch_id: terminal.branch_id,
      is_active: 1,
      is_paid: 1,
      type: "main"
    })
    yield put(actions.createTerminal.success(res.data));
    yield put(push("/branch/terminals"));
  } catch (error) {
    handleError(error)
    yield put(actions.createTerminal.failure(getErrorMsg(error)));
    yield put(push("/branch/terminals/new"));
  }
}

function* editTerminal(action) {
  try {
    const { terminalId, terminal } = action.payload;
    const res = yield fetcher.patch(`terminals/${terminalId}`, {
      terminal_code: terminal.terminal_code,
      terminal_name: terminal.terminal_name,
      register_id: terminal.register_id,
      branch_id: terminal.branch_id,
    })
    yield put(actions.editTerminal.success(res.data));
    yield put(push("/branch/terminals"));
  } catch (error) {
    handleError(error)
    yield put(actions.editTerminal.failure(getErrorMsg(error)));
  }
}

function* deleteTerminal(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`terminals/${id}`)
    yield put(actions.deleteTerminal.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.deleteTerminal.failure(getErrorMsg(error)));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/terminal`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchBranchTerminals() {
  yield all([
    takeEvery(actions.LOAD_BRANCH_TERMINALS.REQUEST, loadBranchTerminals),
    takeEvery(actions.LOAD_TERMINAL.REQUEST, loadTerminal),
    takeEvery(actions.CREATE_TERMINAL.REQUEST, createTerminal),
    takeEvery(actions.EDIT_TERMINAL.REQUEST, editTerminal),
    takeEvery(actions.DELETE_TERMINAL.REQUEST, deleteTerminal),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany),
  ]);
}
