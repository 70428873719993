import React, { Component } from "react";
import { Row, Col, Typography, Tabs, Layout } from "antd";
import { fetchGo } from 'lib/fetch'

import CustomerLayout from "./customer/components/Layout";
import ProductLayout from "./Product/Components/Layout";
import ServiceLayout from "./service/components/Layout";
import StylistLayout from "./stylist/components/Layout";
import SalesLayout from "./sales/components/Layout"
import { getIdtoArray } from "lib/helper";

const { Title } = Typography;
const { TabPane } = Tabs;
const { Content } = Layout;

export class AnalyticLayout extends Component {
  state = {
    picker: "date",
    limit: 10,
    page: 1,
    visible: false,
  };

  state = {
    branches: [],
    branch_ids: []
  }

  componentDidMount() {
    fetchGo.branches().then(res => {
      this.setState({ 
        branches: res.data.data,
        branch_ids: getIdtoArray(res.data.data, "value")
       })
    })
  }

  render() {
    const { branches, branch_ids } = this.state;
    return (
      <Content className="site-layout-background-content">
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          <Col xs={24} md={5} lg={8}>
            <Title level={4} className="page-title">
              Data Analytics
            </Title>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Tabs type="card" defaultActiveKey="1" style={{ marginBottom: 32 }}>
              <TabPane tab="Services" key="1">
                <ServiceLayout branches={branches}/>
              </TabPane>
              <TabPane tab="Products" key="2">
                <ProductLayout />
              </TabPane>
              <TabPane tab="Stylist" key="3">
                <StylistLayout branches={branches}/>
              </TabPane>
              <TabPane tab="Sales" key="4">
                <SalesLayout branches={branches}/>
              </TabPane>
              <TabPane tab="Customers" key="5">
                <CustomerLayout branches={branch_ids}/>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Content>
    );
  }
}

export default AnalyticLayout;
