import { combineReducers } from "redux";
import { actualservice } from 'modules/actual-service/reducer'
import { authentication } from 'modules/authen/reducer'
import { auth } from "modules/signin/reducer"
import { branch } from "modules/branch/reducer"
import { cashpackage } from 'modules/cash-package/reducer'
import { customer } from 'modules/customers/reducer'
import { employee } from 'modules/employee/reducer'
import { product } from 'modules/products/reducer'
import { schedule } from 'modules/schedule/reducer'
import { servicepackage } from 'modules/service-package/reducer'
import { system } from 'modules/system/reducer'

import reports from 'modules/report/reducer'
import { dashboard } from 'modules/dashboard/reducer'
import { analytic } from 'modules/analytic/reducer'

export default combineReducers({
  ...analytic,
  ...actualservice,
  ...authentication,
  ...auth,
  ...branch,
  ...cashpackage,
  ...customer,
  ...employee,
  ...product,
  ...schedule,
  ...servicepackage,
  ...system,
  reports,
  ...dashboard
})