import React from "react";
import { Switch } from "react-router-dom";

import BranchHasEmployeeList from "./BranchHasEmployeeList";
import EditBranchHasEmployee from "./EditBranchHasEmployee";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/branch/employees/edit/:branch_id"
      component={EditBranchHasEmployee}
      perm="branch_employees"
      action="can_add"
    />
    <PrivateRoute
      path="/branch/employees"
      component={BranchHasEmployeeList}
      perm="branch_employees"
    />
  </Switch>
);
