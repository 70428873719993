import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import * as actions from "../actions";

import BranchHasServicesForm from "./BranchHasServicesForm";
import { checkPermission } from "lib/helper";

class EditBranchHasServices extends Component {
  state = {
    can_edit: checkPermission("branch_actual_services", "can_add", false)
  };
  
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage } = nextProps;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage } = this.props;

    if (alertMessage !== "" && prevProps.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }


  componentDidMount() {
    let branchId = this.props.match.params.branch_id;
    if (typeof parseInt(branchId) !== "number") return;
    this.props.loadBranchActualServices(branchId, null, null, null);
  }

  editBranchHasActualService = async (branchActualService) => {
    this.props.editBranchHasActualService(
      this.props.match.params.branch_id,
      branchActualService
    );
  };

  render() {
    const {
      branchActualService,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <BranchHasServicesForm
        onSubmit={this.editBranchHasActualService}
        branchActualService={branchActualService}
        branchId={this.props.match.params.branch_id}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
      />
    );
  }
}

const mapStateToProps = ({ branchActualServices, branches }) => {
  return {
    branchActualService: branchActualServices.data,
    alertMessage: branchActualServices.alertMessage,
    errorMessage: branchActualServices.errorMessage,
    loading: branchActualServices.loading,
    branches
  };
};

const mapDispatchToProps = {
  loadBranchActualServices: actions.loadBranchActualServices.request,
  editBranchHasActualService: actions.editBranchHasActualService.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBranchHasServices);
