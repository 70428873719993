import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";

import BranchForm from "./BranchForm";
import { checkPermission } from "lib/helper";

class EditBranch extends Component {
  state = {
    can_edit: checkPermission("branch_manage_branches", "can_edit", false)
  };

  componentDidMount() {
    this.props.loadBranch(this.props.match.params.branch_id);
  }

  editBranch = async (branch, is_upload) => {
    this.props.editBranch(this.props.match.params.branch_id, branch, is_upload);
  };

  render() {
    const { branch, alertMessage, errorMessage, loading } = this.props;
    console.log(loading);
    return (
      <BranchForm
        onSubmit={this.editBranch}
        branch={branch}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
      />
    );
  }
}

const mapStateToProps = ({ branches }) => {
  return {
    branch: branches.branch,
    alertMessage: branches.alertMessage,
    errorMessage: branches.errorMessage,
    loading: branches.loading
  };
};

const mapDispatchToProps = {
  loadBranch: actions.loadBranch.request,
  editBranch: actions.editBranch.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBranch);
