import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";

import UserGroupForm from "./UserGroupForm";
import { checkPermission } from "lib/helper";

class EditUserGroup extends Component {
  state = {
    can_edit: checkPermission("authorization_usergroups", "can_edit", false)
  };
  componentDidMount() {
    this.props.loadUserGroup(this.props.match.params.usergroup_id);
  }

  editUserGroup = async (usergroup) => {
    this.props.editUserGroup(
      this.props.match.params.usergroup_id,
      usergroup
    );
  };

  render() {
    const {
      usergroup,
      alertMessage,
      errorMessage,
      loading
    } = this.props;


    return (
      <UserGroupForm
        onSubmit={this.editUserGroup}
        usergroup={usergroup}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
      />
    );
  }
}

const mapStateToProps = ({ usergroups }) => {
  return {
    usergroup: usergroups.usergroup,
    alertMessage: usergroups.alertMessage,
    errorMessage: usergroups.errorMessage,
    loading: usergroups.loading
  };
};

const mapDispatchToProps = {
  loadUserGroup: actions.loadUserGroup.request,
  editUserGroup: actions.editUserGroup.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserGroup);
