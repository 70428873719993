import React, { Component, Fragment } from 'react'
import { TransferCustomer, getErrorMsg } from "lib/fetch"
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "./Form"

const antIcon = <LoadingOutlined style={{ fontSize: 21, left: "91%" }} spin />;

export class index extends Component {
    state = {
        loading: false,
    }

    handleSubmit = async value => {
        try {
            this.setState({ loading: true })
            const res = await TransferCustomer.updatedata(value)
            if (res.data.message == "success") {
                message.success("Success")
                this.setState({ loading: false })
                setTimeout(() => {
                    window.location.reload()
                }, 200);
            }
        } catch (error) {
            message.error(getErrorMsg(error))
        }
    }

    render() {
        return (
            <Fragment>
                <Spin spinning={this.state.loading} indicator={antIcon}>
                    <Form onSubmit={this.handleSubmit} ></Form>
                </Spin>
            </Fragment>
        )
    }
}

export default index