import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Row, Col, Button, Radio } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import Widget from "./Widget";
import Graph from "./Graph";
import ServiceTable from "./ServiceTable";
import Filter from "./Filter";
import * as moment from "moment";

import { getIdtoArray } from "lib/helper";
import { fetchGo } from "lib/fetch"
import { branch } from "modules/branch/reducer";

const COLOR = ["#55b9ed", "#e94a39"];

export class Layout extends Component {
  state = {
    picker: "month",
    duration: moment().format("YYYY-MM"),
    branch_id: [],
    category_id: 0,
    view: 'group'
  };

  fetchData = () => {
    const { picker, duration, branch_id, category_id } = this.state;
    if (branch_id.length > 0) {
      this.props.loadDataTable(picker, duration, branch_id, category_id);
      this.props.loadGraphData(picker, duration, branch_id, category_id);
      this.props.loadWidgetData(picker, duration, branch_id, category_id);
    }
  };

  handleSearch = (picker, duration, branch_id, category_id) => {
    this.setState(
      {
        picker,
        duration,
        branch_id,
        category_id,
      },
      this.fetchData
    );
  };

  getComparedLabel = () => {
    switch (this.state.picker) {
      case "date":
      case "week":
        return "week";
      case "month":
        return "month";
    }
  };

  handleViewChange = e => {
    this.setState({ view : e.target.value })
  }

  handleExportExcel = () => {
    const { picker, duration, branch_id, category_id } = this.state
    fetchGo.postData(`service_analytics_export`, {
      type : picker, duration, branch_id, category_id
    }).then(res => {
      window.location.assign(res.data.result)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.branches.length !== prevProps.branches.length) {
      this.setState(
        { branch_id: getIdtoArray(this.props.branches, "value") },
        this.fetchData
      );
    }
  }

  componentDidMount() {
    this.setState(
      { branch_id: getIdtoArray(this.props.branches, "value") },
      this.fetchData
    );
  }
  

  render() {
    const {
      loading_graph,
      analytic_graph,
      analytic_graph_compared,
      loading_datatable,
      analytic_datatable,
      loading_widget,
      analytic_widget,
      branches,
    } = this.props;

    return (
      <Fragment>
        <Filter onSearch={this.handleSearch} branches={branches} />
        <Row gutter={[8, 16]}>
          <Col lg={5}>
            <Widget
              loading={loading_widget}
              revenue={analytic_widget}
              comparedLabel={this.getComparedLabel()}
            />
          </Col>
          <Col lg={19}>
            <Graph
              type={this.state.picker}
              color={COLOR}
              loading_graph={loading_graph}
              revenue={analytic_graph}
              compared={analytic_graph_compared}
              comparedLabel={this.getComparedLabel()}
            />
          </Col>
        </Row>
        <Row gutter={[8, 16]} justify="end">
          <Col span={4} className="align-right">
          <Radio.Group onChange={this.handleViewChange} value={this.state.view}>
            <Radio.Button value="group">Group by type</Radio.Button>
            <Radio.Button value="all">Show All</Radio.Button>
          </Radio.Group>
          </Col>
          <Col span={2} className="align-right">
            <Button disabled={this.state.view === 'group'} 
            onClick={this.handleExportExcel}
            icon={<UploadOutlined />}>Export</Button>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <ServiceTable
              view={this.state.view}
              comparedLabel={this.getComparedLabel()}
              loading_datatable={loading_datatable}
              analytic_datatable={analytic_datatable}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ service_analytic }) => ({
  loading_graph: service_analytic.loading_graph,
  analytic_graph: service_analytic.analytic_graph,
  analytic_graph_compared: service_analytic.analytic_graph_compared,
  loading_datatable: service_analytic.loading_datatable,
  analytic_datatable: service_analytic.analytic_datatable,
  loading_widget: service_analytic.loading_widget,
  analytic_widget: service_analytic.analytic_widget,
});

const mapDispatchToProps = {
  loadDataTable: actions.loadDataServiceTable.request,
  loadGraphData: actions.loadGraphDataService.request,
  loadWidgetData: actions.loadWidgetDataService.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
