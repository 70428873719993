import React from 'react'
import { Col, Button } from 'antd'
import { FilterOutlined } from "@ant-design/icons";

const FilterBtn = ({ showDrawer, col = 8 }) => {
    return (
        <Col xs={col} sm={col} md={4} lg={3}>
        <Button block icon={<FilterOutlined />} onClick={showDrawer('visible')}>
          Filter
        </Button>
      </Col>
    )
}

export default FilterBtn
