import { fork } from "redux-saga/effects";

import actualServices from "./components/actual_service/sagas";
import actualServiceGroups from "./components/actual_service_group/sagas";
import actualServiceTypes from "./components/actual_service_type/sagas";

const watchActualService = [
  fork(actualServices),
  fork(actualServiceGroups),
  fork(actualServiceTypes)
];

export { watchActualService };
