import { Form, Input, message, Spin } from "antd";
import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { PageTitle, FormBtn, RequriedText } from "lib/widget";

const FormItem = Form.Item;

export default class ServiceGroupForm extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }
    return null;
  }

  componentDidMount() {
    this.nameInput.focus(); 
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  isDisabled = () => {
    return this.props.serviceGroup.actual_service_group_code !== '' && this.props.editCode
  }

  render() {
    const { serviceGroup, onSubmit } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      }
    };

    return (
      <div>
        <Spin size="large" spinning={this.props.loading}>
          <PageTitle name="actual_service_groups" component={null} />
          <Formik
            initialValues={serviceGroup}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              actual_service_group_code: yup
                .string()
                .required("Code is a required field"),
              actual_service_group: yup
                .string()
                .required("Name is a required field")
            })}
            onSubmit={values => onSubmit(values)}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onFinish={handleSubmit} autoComplete="off">
                <fieldset disabled={!this.props.canEdit}>
                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Code" />}
                    validateStatus={
                      touched.actual_service_group_code &&
                      errors.actual_service_group_code &&
                      "error"
                    }
                    help={
                      touched.actual_service_group_code &&
                      errors.actual_service_group_code
                    }
                  >
                    <Input
                      ref={(input) => { this.nameInput = input; }} 
                      name="actual_service_group_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.actual_service_group_code}
                      disabled={this.isDisabled()}
                    />
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Name" />}
                    validateStatus={
                      touched.actual_service_group &&
                      errors.actual_service_group &&
                      "error"
                    }
                    help={
                      touched.actual_service_group &&
                      errors.actual_service_group
                    }
                  >
                    <Input
                      
                      name="actual_service_group"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.actual_service_group}
                    />
                  </FormItem>

                  <FormBtn
                    url="/actual-service/groups"
                    canEdit={this.props.canEdit}
                  />
                </fieldset>
              </Form>
            )}
          />
        </Spin>
      </div>
    );
  }
}
