import { all, put, takeEvery } from "redux-saga/effects";
import { fetchGo, handleError } from "lib/fetch";
import { getComparedFilter } from 'lib/go-api-helper'
import * as actions from "./actions";

function* loadAppointment(action) {
  const { picker, duration, branch_id } = action.payload;
  const params = { type: picker, duration, branch_id }
    try {
      const res = yield fetchGo.post(`appointment_graph`, params)
      yield put(actions.loadAppointment.success(res.data));
  
    } catch (error) {
      handleError(error);
    }
}

function* loadBranchAppointment(action) {
  try {
    const { type, duration, branch_id } = action.payload;
    const params = { type, duration, branch_id }
    const params2 = { type, branch_id }

    params2["duration"] = getComparedFilter(type, duration)

    const [revenue, compared] = yield all([
      fetchGo.post(`appointment_branch`, params),
      fetchGo.post(`appointment_branch`, params2)
    ])

    yield put(actions.loadBranchAppointment.success(revenue.data, compared.data));
  } catch (error) {
    handleError(error);
  }
}

export default function* watchAppointment() {
  yield all([
    takeEvery(actions.LOAD_APPOINTMENT.REQUEST, loadAppointment),
    takeEvery(actions.LOAD_APPOINTMENT_BRANCH.REQUEST, loadBranchAppointment),
  ]);
}
