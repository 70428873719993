import { createTypes, createAction } from "lib/action";

const LOAD_ACTUAL_SERVICE_GROUPS = createTypes("actualServiceGroups", "list");
const LOAD_ACTUAL_SERVICE_GROUP = createTypes("actualServiceGroup", "show");
const CREATE_ACTUAL_SERVICE_GROUP = createTypes("actualServiceGroup", "create");
const EDIT_ACTUAL_SERVICE_GROUP = createTypes("actualServiceGroup", "edit");
const DELETE_ACTUAL_SERVICE_GROUP = createTypes("actualServiceGroup", "delete");
const UPLOAD_FILE = createTypes("actualServiceGroups", "import");
const DOWNLOAD_FILE = createTypes("actualServiceGroups", "export");

const loadActualServiceGroups = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_ACTUAL_SERVICE_GROUPS.REQUEST, {
      pagination,
      limit,
      keyword,
      sort
    }),
  success: actualServiceGroups =>
    createAction(LOAD_ACTUAL_SERVICE_GROUPS.SUCCESS, { actualServiceGroups }),
  failure: (error) => createAction(LOAD_ACTUAL_SERVICE_GROUPS.FAILUREl, { error })
};

const loadActualServiceGroup = {
  request: serviceGroupId =>
    createAction(LOAD_ACTUAL_SERVICE_GROUP.REQUEST, { serviceGroupId }),
  success: actualServiceGroup =>
    createAction(LOAD_ACTUAL_SERVICE_GROUP.SUCCESS, { actualServiceGroup }),
  failure: (error) => createAction(LOAD_ACTUAL_SERVICE_GROUP.FAILURE, { error })
};

const createActualServiceGroup = {
  request: (code, name) =>
    createAction(CREATE_ACTUAL_SERVICE_GROUP.REQUEST, { code, name }),
  success: actualServiceGroup =>
    createAction(CREATE_ACTUAL_SERVICE_GROUP.SUCCESS, { actualServiceGroup }),
  failure: error => createAction(CREATE_ACTUAL_SERVICE_GROUP.FAILURE, { error })
};

const editActualServiceGroup = {
  request: (id, code, name) =>
    createAction(EDIT_ACTUAL_SERVICE_GROUP.REQUEST, {
      id,
      code,
      name
    }),
  success: actualServiceGroup =>
    createAction(EDIT_ACTUAL_SERVICE_GROUP.SUCCESS, { actualServiceGroup }),
  failure: error => createAction(EDIT_ACTUAL_SERVICE_GROUP.FAILURE, { error })
};

const deleteActualServiceGroup = {
  request: id => createAction(DELETE_ACTUAL_SERVICE_GROUP.REQUEST, { id }),
  success: actualServiceGroup =>
    createAction(DELETE_ACTUAL_SERVICE_GROUP.SUCCESS, { actualServiceGroup }),
  failure: error => createAction(DELETE_ACTUAL_SERVICE_GROUP.FAILURE, { error })
};

const upload = {
  request: (file, pagination, limit, keyword) =>
    createAction(UPLOAD_FILE.REQUEST, {
      file,
      pagination,
      limit,
      keyword
    }),
  success: data => createAction(UPLOAD_FILE.SUCCESS, { data }),
  failure: error => createAction(UPLOAD_FILE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: error => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

export {
  LOAD_ACTUAL_SERVICE_GROUPS,
  loadActualServiceGroups,
  LOAD_ACTUAL_SERVICE_GROUP,
  loadActualServiceGroup,
  CREATE_ACTUAL_SERVICE_GROUP,
  createActualServiceGroup,
  EDIT_ACTUAL_SERVICE_GROUP,
  editActualServiceGroup,
  deleteActualServiceGroup,
  DELETE_ACTUAL_SERVICE_GROUP,
  upload,
  UPLOAD_FILE,
  download,
  DOWNLOAD_FILE
};
