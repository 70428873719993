import React from "react";
import { Switch } from "react-router-dom";

import BranchHasCategoryList from "./BranchHasCategoryList";
import EditBranchHasCategory from "./EditBranchHasCategory";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/branch/categories/edit/:branch_id"
      component={EditBranchHasCategory}
      perm="branch_product_categories"
      action="can_add"
    />
    <PrivateRoute
      path="/branch/categories"
      component={BranchHasCategoryList}
      perm="branch_product_categories"
    />
  </Switch>
);
