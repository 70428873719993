import {
  LOAD_ACTUAL_SERVICES,
  LOAD_ACTUAL_SERVICE,
  CREATE_ACTUAL_SERVICE,
  EDIT_ACTUAL_SERVICE,
  DELETE_ACTUAL_SERVICE,
  IMPORT_ACTUAL_SERVICE,
  DOWNLOAD_FILE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  actualService: {
    barcode: "",
    service_name: "",
    actual_service_type_id: "",
    actual_service_group_id: "",
    is_show: 1,
    price: "",
    price_before_vat: ""
  },
  branch_services: [],
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ACTUAL_SERVICES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_ACTUAL_SERVICES.SUCCESS:
      return {
        ...state,
        data: action.payload.actualServices.data,
        totalSize: action.payload.actualServices.total_row,
        alertMessage: "",
        errorMessage: "",
        actualService: initialState.actualService,

        errorImport: "",
        loading: false
      };
    case LOAD_ACTUAL_SERVICE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_ACTUAL_SERVICE.SUCCESS:
      return {
        ...state,
        actualService: action.payload.actualService.data,
        branch_services: action.payload.actualService.branch_services,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_ACTUAL_SERVICE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case CREATE_ACTUAL_SERVICE.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.actualService.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_ACTUAL_SERVICE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_ACTUAL_SERVICE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_ACTUAL_SERVICE.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.actualService.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_ACTUAL_SERVICE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case DELETE_ACTUAL_SERVICE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_ACTUAL_SERVICE.SUCCESS:
      const newServices = state.data.filter(
        service =>
          service.actual_service_id !==
          action.payload.actualService.data.actual_service_id
      );
      return {
        ...state,
        data: newServices,
        alertMessage: action.payload.actualService.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_ACTUAL_SERVICE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case IMPORT_ACTUAL_SERVICE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case IMPORT_ACTUAL_SERVICE.SUCCESS:
      return {
        ...state,
        data: action.payload.actualService.data,
        totalSize: action.payload.actualService.total_row,
        alertMessage: action.payload.actualService.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case IMPORT_ACTUAL_SERVICE.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",

        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.actual_service_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
