import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  uniqueArray,
  extractKeyFromArray,
  utcToLocalTime,
} from "lib/helper";
import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  FilterBtn,
  TagFilter,
  ImportExportBtn,
  AddBtn,
  UploadModal,
  PageTitle,
} from "lib/widget";

import {
  Table,
  Row,
  Col,
  message,
  Spin,
  notification,
  Divider,
  Typography,
} from "antd";

import Filter from "./Filter";

const { Text } = Typography;

class CustomerList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = nextProps.customers;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (errorImport !== "") {
      return { errorImport: errorImport };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
  };

  fetchData = () => {
    const { page, limit, cond, sorter } = this.state;
    setSorter(sorter);
    setSearch(cond);
    this.props.loadCustomers(page, limit, cond, sorter);
  };

  handleSearch = (val, filter) => {
    this.setState(
      { page: 1, visible: false, keyword: val.keyword, cond: val },
      this.fetchData
    );
    this.getFilterTag(filter, val.keyword, val.tel1);
  };

  getFilterTag = (filter, keyword, tel) => {
    let gender = "",
      word = "",
      nationality = "",
      tel1 = "";
    if (keyword !== "") word = `${keyword}`;
    if (tel !== "") tel1 = tel;
    Object.keys(filter).map((key) => {
      filter[key].map((val) => {
        if (key === "gender") gender += (gender !== "" ? ", " : "") + val.label;
        if (key === "nationality")
          nationality += (nationality !== "" ? ", " : "") + val.nationality;
      });
    });

    this.setState({
      genderTag: gender !== "" ? `${gender}` : "",
      keywordTag: word,
      TelTag: tel1,
      nationTag: nationality !== "" ? `${nationality}` : "",
    });

    setFilterTag({
      genderTag: gender !== "" ? `${gender}` : "",
      keywordTag: word,
      TelTag: tel1,
      nationTag: nationality !== "" ? `${nationality}` : "",
    });
  };

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState(
      { page: current, limit: pageSize, sorter: { order, field } },
      this.fetchData
    );
  };

  componentDidMount() {
    let filter = getFilterTag();

    if (typeof filter === "object") {
      this.setState(
        { ...filter, cond: getSearch(), sorter: getSorter() },
        this.fetchData
      );
      return;
    }
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = this.props.customers;
    const prevMsg = prevProps.customers;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (errorImport !== "" && prevMsg.errorImport !== errorImport) {
      this.openNotification(errorImport);
    }
  }

  openNotification = (message) => {
    notification.error({
      message: "Import fail",
      description: message,
      duration: 0,
    });
  };

  deleteCustomer = async (id) => {
    this.props.deleteCustomer(id);
  };

  showModalImport = () => {
    this.setState({
      visibleImport: true,
    });
  };

  handleCancel = () => {
    this.setState({ visibleImport: false });
  };

  onChooseFile = ({ file }) => {
    if (file.status !== "removed") {
      this.setState({ file, fileList: [file] });
    }
  };

  onRemove = () => this.setState({ fileList: [], file: null });

  handleUpload = () => {
    const { page, limit, keyword, file } = this.state;
    this.props.importCustomer(file, "customer", page, limit, keyword);
    this.setState({ file: null, fileList: [] });
  };

  viewBtn = (id) => {
    return (
      <Fragment>
        <Link to={`/customer/customers/history/${id}`}>History</Link>
        {window.innerWidth > 500 && <Divider type="vertical" />}
      </Fragment>
    );
  };

  customerDetail = (data) => {
    return (
      <Fragment>
        <Row style={{ marginBottom: 10, marginTop: 16 }}>
          <Text strong>Customer Detail</Text>
        </Row>
        <Row style={{ marginBottom: 5 }}>
          <Col xs={6} md={4} lg={4}>
            Hair volumn : {data.hair_volumn}
          </Col>
          <Col xs={6} md={4} lg={4}>
            Hair size : {data.hair_size}
          </Col>
          <Col xs={12} md={4} lg={4}>
            Birthday : {data.birthday}
          </Col>
          <Col xs={24} md={12} lg={12}>
            Email : {data.email}
          </Col>
        </Row>
        <Row style={{ marginBottom: 5 }}>
          <Col span={24}>Address : {data.address}</Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>Note : {data.notes}</Col>
        </Row>
      </Fragment>
    );
  };

  expandedRowRender = (record) => {
    const columns = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) => index + 1,
      },
      {
        title: "Barcode",
        dataIndex: "barcode",
      },
      {
        title: "Package Name",
        dataIndex: "service_package_name",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        className: "align-right",
      },
      {
        title: "Purchase Date",
        dataIndex: "purchase_date",
        render: (data) => {
          if (data !== "null" && data !== "" && typeof data !== "undefined") {
            return utcToLocalTime(data);
          }
          return data;
        },
      },
      {
        title: "Expired Date",
        dataIndex: "expiration_date",
        render: (data) => {
          if (
            data !== "null" &&
            data !== "" &&
            typeof data !== "undefined" &&
            data !== null
          ) {
            return utcToLocalTime(data);
          }
          return data;
        },
      },
    ];

    return (
      <Fragment>
        {this.customerDetail(record)}
        <Row style={{ marginBottom: 30, marginTop: 16 }}>
          <Text strong>Customer Packages</Text>
        </Row>
        <Row>
          <Table
            rowKey="customer_has_package_id"
            bordered={true}
            size="small"
            columns={columns}
            dataSource={record.detail}
            pagination={false}
            scroll={window.innerWidth <= 500 ? { x: 800 } : undefined}
          />
        </Row>
      </Fragment>
    );
  };

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.customers.data.filter((x) =>
        selectedRowKeys.includes(x.customer_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.customer_id)
      );
      items = uniqueArray([...removeState, ...removeData], "customer_id");
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "customer_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter((x) => x.customer_id !== id);
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { customers } = this.props;
    const {
      selectedRowKeys,
      genderTag,
      keywordTag,
      nationTag,
      TelTag,
      sorter
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const actionCol = [
      {
        title: "",
        key: customers.customer_id,
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 170,
        fixed: "left",
        render: (customers) => (
          <ListAction
            spacial={this.viewBtn(customers.customer_id)}
            editUrl={`/customer/customers/edit/${customers.customer_id}`}
            onDelete={this.props.deleteCustomer}
            id={customers.customer_id}
            name="customer_customers"
            breakpoint={500}
          />
        ),
      },
    ];

    const mainColumns = [
      {
        title: "Code",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'customer_code' ? sorter.order : false,
        width: 100,
        dataIndex: "customer_code",
      },
      {
        title: "Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'name' ? sorter.order : false,
        dataIndex: "name",
      },
      {
        title: "Gender",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'gender' ? sorter.order : false,
        dataIndex: "gender",
      },
      {
        title: "Nationality",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'nationality' ? sorter.order : false,
        dataIndex: "nationality",
      },
      {
        title: "Tel",
        dataIndex: "tel1",
      },
    ];

    const columns =
      window.innerWidth <= 500
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    return (
      <div>
        <Spin size="large" spinning={this.props.customers.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              name="customer_customers"
              component={
                <AddBtn
                  url="/customer/customers/new"
                  name="customer_customers"
                />
              }
            />
            <Row gutter={[16, 16]}>
              <DeleteBadge
                count={this.state.removeData.length}
                onClick={this.showDrawer("visibleDelete")}
              />
              <Col xs={10} sm={10} md={8} lg={6} className="import-btn-align">
                <ImportExportBtn
                  showModalImport={this.showModalImport}
                  download={this.props.download}
                  name="customer_customers"
                />
              </Col>
              <Col
                xs={3}
                sm={3}
                md={{ span: 7, offset: 3 }}
                lg={{ span: 8, offset: 6 }}
              ></Col>
              <FilterBtn showDrawer={this.showDrawer} />
              <Filter
                list={customers.data}
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col xs={19} sm={19} md={21} lg={22}>
                {TagFilter([keywordTag], "Keyword", "green")}
                {TagFilter([TelTag], "Keyword", "orange")}
                {TagFilter([genderTag], "Gender", "cyan")}
                {TagFilter([nationTag], "Nationality", "orange")}
              </Col>
            </Row>

            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["customer_id", "name", "tel1"]}
            />
          </div>
          <Table
            rowKey="customer_id"
            bordered={true}
            size="small"
            columns={columns}
            dataSource={customers.data}
            rowSelection={rowSelection}
            expandedRowRender={(record) => this.expandedRowRender(record)}
            pagination={{
              pageSize: this.state.limit,
              total: this.props.customers.totalSize,
              current: this.state.page,
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 500 ? { x: 600 } : undefined}
          />
          <UploadModal
            visibleImport={this.state.visibleImport}
            handleCancel={this.handleCancel}
            loading={this.props.customers.loading}
            onChooseFile={this.onChooseFile}
            fileList={this.state.fileList}
            onRemove={this.onRemove}
            handleUpload={this.handleUpload}
            uploading={this.state.uploading}
            disabled={this.state.file ? false : true}
          />
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = ({ customers }) => ({
  customers,
});

const mapDispatchToProps = {
  loadCustomers: actions.loadCustomers.request,
  deleteCustomer: actions.deleteCustomer.request,
  importCustomer: actions.importCustomer.request,
  download: actions.download.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
