import React from "react";
import { Switch } from "react-router-dom";

import ProductList from "./ProductList";
import NewProduct from "./NewProduct";
import EditProduct from "./EditProduct";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute path="/product/products/new" component={NewProduct} perm="product_products" action="can_add"/>
    <PrivateRoute path="/product/products/edit/:product_id" component={EditProduct} perm="product_products"/>
    <PrivateRoute path="/product/products" component={ProductList} perm="product_products"/>
  </Switch>
);
