import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import {
  loadCategories,
  loadUnits,
  loadBrands,
  loadDistributors
} from "lib/select-option";

import ProductForm from "./ProductForm";

class NewProduct extends Component {
  componentDidMount() {
    this.props.loadCategories();
    this.props.loadUnits();
    this.props.loadBrands();
    this.props.loadDistributors(null, null, null);
  }

  createProduct = async (product, checkedList) => {
    this.props.createProduct(product, checkedList);
  };

  render() {
    const {
      product,
      categories,
      units,
      brands,
      distributors,
      alertMessage,
      errorMessage,
      branches,
      loading
    } = this.props;

    const data = [];
    branches.map(branch => {
      const obj = {};
      obj.name = branch.branch_id;
      obj.value = true;
      return data.push(obj);
    });

    return (
      <ProductForm
        onSubmit={this.createProduct}
        product={product}
        categories={categories}
        units={units}
        brands={brands}
        distributors={distributors}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        branches={branches}
        checkedList={data}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({
  products,
  categories,
  units,
  brands,
  branches,
  distributors
}) => {
  return {
    product: products.product,
    categories: categories.data,
    units: units.data,
    brands: brands.data,
    alertMessage: products.alertMessage,
    errorMessage: products.errorMessage,
    branches: branches.data,
    distributors: distributors.data,
    loading: products.loading
  };
};

const mapDispatchToProps = {
  createProduct: actions.createProduct.request,
  loadCategories: loadCategories.request,
  loadUnits: loadUnits.request,
  loadBrands: loadBrands.request,
  loadDistributors: loadDistributors.request
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProduct);
