import { all, put, takeEvery } from "redux-saga/effects";
import { fetchGo, handleError } from "lib/fetch";
import { getComparedFilter } from 'lib/go-api-helper'
import * as actions from "./actions";

function* loadDataServiceTable(action) {
    try {
        const { type, duration, branch_id, category_id } = action.payload;
        const params = { type, duration, branch_id, category_id }

        const res = yield fetchGo.post(`service_analytics`, params);  
        yield put(actions.loadDataServiceTable.success(res.data));
  
    } catch (error) {
      handleError(error);
    }
}

function* loadGraphDataService(action) {
  try {
    const { type, duration, branch_id, category_id } = action.payload;
    const params = { type, duration, branch_id, category_id, query_type : "service"}
    const params2 = { type, branch_id, category_id, query_type : "service" }
    params2["duration"] = getComparedFilter(type, duration)

    const [revenue, compared] = yield all([
      fetchGo.post(`revenue_graph`, params),
      fetchGo.post(`revenue_graph`, params2)
    ])

    yield put(actions.loadGraphDataService.success(revenue.data, compared.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadWidgetDataService(action) {
    try {
      const { type, duration, branch_id, category_id } = action.payload;
      const params = { type, duration, branch_id, category_id }

      const res = yield fetchGo.post(`service_analytics_summary`, params);  
      yield put(actions.loadWidgetDataService.success(res.data));
    } catch (error) {
      handleError(error);
    }
  }

export default function* watchServiceAnalytics() {
  yield all([
    takeEvery(actions.LOAD_SERVICE_DATATABLE.REQUEST, loadDataServiceTable),
    takeEvery(actions.LOAD_SERVICE_GRAPH.REQUEST, loadGraphDataService),
    takeEvery(actions.LOAD_SERVICE_WIDGET.REQUEST, loadWidgetDataService)
  ]);
}
