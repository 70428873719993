import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadBranchServicePackages(action) {
  try {
    const { branchId, pagination, limit, cond, sort } = action.payload;
    const res = yield fetcher.post("masterdata/servicepackages", {
      branch_id: branchId,
      pagination,
      limit,
      cond,
      sort
    });
    yield put(actions.loadBranchServicePackages.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* editBranchHasServicePackages(action) {
  try {
    const { branch_id, servicepackage } = action.payload;
    const res = yield fetcher.post(
      `branch_has/create/servicePackage/${branch_id}`,
      servicepackage
    );
    yield put(actions.editBranchHasServicePackages.success(res.data));
    yield put(push("/branch/service-packages"));
  } catch (error) {
    handleError(error);
    yield put(actions.editBranchHasServicePackages.failure(getErrorMsg(error)));
    yield put(push("/branch/service-packages"));
  }
}

function* deleteBranchHasServicePackage(action) {
  try {
    const { id, branch_id } = action.payload;
    const res = yield fetcher.delete(
      `branch_has/delete/servicePackage/${branch_id}/${id}`
    );
    yield put(actions.deleteBranchHasServicePackage.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(
      actions.deleteBranchHasServicePackage.failure(getErrorMsg(error))
    );
  }
}

function* importBranchHasServicePackage(action) {
  try {
    const { file, type, pagination, limit, keyword, branchId } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    formData.append("branch_id", branchId);
    const res = yield fetcher.post("import/branch_has", formData);
    yield put(actions.importBranchHasServicePackage.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importBranchHasServicePackage.failure(getErrorMsg(error)));
    yield put(push("/branch/service-packages"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/branch_has/servicePackage", {
      branch_id: action.payload.branch_id
    });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/branch_has/servicePackage`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchBranchServicePackages() {
  yield all([
    takeEvery(
      actions.LOAD_BRANCH_SERVICE_PACKAGES.REQUEST,
      loadBranchServicePackages
    ),
    takeEvery(
      actions.IMPORT_BRANCH_HAS_SERVICE_PACKAGE.REQUEST,
      importBranchHasServicePackage
    ),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(
      actions.EDIT_BRANCH_HAS_SERVICE_PACKAGE.REQUEST,
      editBranchHasServicePackages
    ),
    takeEvery(
      actions.DELETE_BRANCH_HAS_PACKAGE.REQUEST,
      deleteBranchHasServicePackage
    ),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany),
  ]);
}
