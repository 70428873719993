import React from "react";
import { Switch } from "react-router-dom";

import ServiceTypeList from "./ServiceTypeList";
import NewServiceType from "./NewServiceType";
import EditServiceType from "./EditServiceType";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/actual-service/types/new"
      component={NewServiceType}
      perm="actual_service_types"
      action="can_add"
    />
    <PrivateRoute
      path="/actual-service/types/edit/:service_type_id"
      component={EditServiceType}
      perm="actual_service_types"
    />
    <PrivateRoute
      path="/actual-service/types"
      component={ServiceTypeList}
      perm="actual_service_types"
    />
  </Switch>
);
