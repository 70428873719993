import { getPermission, checkPermission } from "lib/helper";

const getUrl = name => {
  if (name === "") return "not-found";
  switch (name) {
    case "report_daily":
      return "report/daily";
    case "report_employee":
      return "report/employees";
    case "report_erp_transaction":
      return "report/erp_transaction";
    case "report_erp_customer":
      return "report/erp_customer";
    case "report_goods_received":
      return "report/goods_received";
    case "report_salon_used":
      return "report/salon_used";
    case "report_physical_count":
      return "report/physical_count";
    case "report_transfer":
      return "report/transfer";
    case "report_stock_benchmark":
      return "report/stock_benchmark";
    case "report_current_stock":
      return "report/current_stock";
    case "report_payment":
      return "report/payment";
    case "report_customer_retention":
      return "report/customer_retention";

    case "report_stock_exchange":
      return "report/stock_exchange";

    case "schedule_report_schedule_report":
      return "schedule-report";

    case "price_list_price_lists":
      return "pricelists";

    case "product_products":
      return "product/products";
    case "product_brands":
      return "product/brands";
    case "product_categories":
      return "product/categories";
    case "product_units":
      return "product/units";
    case "product_distributors":
      return "product/distributors";

    case "actual_service_actual_services":
      return "actual-service/services";
    case "actual_service_types":
      return "actual-service/types";
    case "actual_service_groups":
      return "actual-service/groups";

    case "service_package_service_packages":
      return "service-package/packages";
    case "service_package_groups":
      return "service-package/groups";

    case "cash_package_cash_packages":
      return "cash-package/packages";

    case "employee_employees":
      return "employee/employees";
    case "employee_types":
      return "employee/types";

    case "customer_customers":
      return "customer/customers";
    case "customer_nationalities":
      return "customer/nationalities";
    case "customer_packages":
      return "customer/packages";

    case "branch_manage_branches":
      return "branch/branches";
    case "branch_products":
      return "branch/products";
    case "branch_actual_services":
      return "branch/actual-services";
    case "branch_service_packages":
      return "branch/service-packages";
    case "branch_cash_packages":
      return "branch/cash-packages";
    case "branch_employees":
      return "branch/employees";
    case "branch_terminals":
      return "branch/terminals";
    case "branch_product_categories":
      return "branch/categories";

    case "authorization_permissions":
      return "auth/permissions";
    case "authorization_usergroups":
      return "auth/usergroups";

    case "system_user_users":
      return "system/users";

    case "system_user_transfer_customer_data":
      return "system/transfer-customer";

    default:
      return "upcoming_features";
  }
};

const getPageTitle = name => {
  if (name === "") return name;
  let perm = getPermission();
  let res = perm.find(val => val.permission_name === name);
  return typeof res !== "undefined" ? res.sub_menu : "";
};

const getBreadcrumbTitle = url => {
  let name = url[1];
  let path = url[2];
  let title = "",
    subtitle = { title: "", url: "" };
  switch (name) {
    case "report":
      title = "Report";
      subtitle = subMenuReport(path);
      break;
    case "schedule-report":
      title = "Schedule Report";
      subtitle = subMenuSchedule(path);
      break;
    case "pricelist":
      title = "Price List";
      subtitle = subMenuPriceList(path);
      break;
    case "product":
      title = "Product";
      subtitle = subMenuProduct(path);
      break;
    case "actual-service":
      title = "Actual Services";
      subtitle = subMenuActualService(path);
      break;
    case "service-package":
      title = "Service Package";
      subtitle = subMenuServicePackage(path);
      break;
    case "cash-package":
      title = "Cash Packages";
      subtitle = subMenuCashPackage(path);
      break;
    case "employee":
      title = "Employees";
      subtitle = subMenuEmployee(path);
      break;
    case "customer":
      title = "Customer";
      subtitle = subMenuCustomer(path);
      break;
    case "branch":
      title = "Branch";
      subtitle = subMenuBranch(path);
      break;
    case "auth":
      title = "Authentication";
      subtitle = subMenuAuth(path);
      break;
    case "system":
      title = "System User";
      subtitle = subMenuSystem(path);
      break;
    case "dashboard":
      title = "Dashboard";
      subtitle = subMenuDashboard(path);
      break;
    case "upcoming_features":
      title = "Upcoming Features";
      subtitle = { title: "", url: "" };
      break;
    default:
      title = "";
      subtitle = { title: "", url: "" };
      break;
  }

  return { title, subtitle };
};

const getSubMenuValue = permName => {
  return { title: getPageTitle(permName), url: getUrl(permName) };
};

const subMenuDashboard = url => {
  let permName = "";
  switch (url) {
    case "dashboard":
      permName = "dashboard_dashboard";
      break;
    case "analytics":
      permName = "dashboard_analytics";
      break;
    default:
      break;
  }
  return getSubMenuValue(permName);
}

const subMenuActualService = url => {
  let permName = "";
  switch (url) {
    case "services":
      permName = "actual_service_actual_services";
      break;
    case "types":
      permName = "actual_service_types";
      break;
    case "groups":
      permName = "actual_service_groups";
      break;
    default:
      break;
  }
  return getSubMenuValue(permName);
};
const subMenuAuth = url => {
  let permName = "";
  switch (url) {
    case "permissions":
      permName = "authorization_permissions";
      break;
    case "usergroups":
      permName = "authorization_usergroups";
      break;
    default:
      break;
  }
  return getSubMenuValue(permName);
};

const subMenuBranch = url => {
  let permName = "";
  switch (url) {
    case "branches":
      permName = "branch_manage_branches";
      break;
    case "products":
      permName = "branch_products";
      break;
    case "actual-services":
      permName = "branch_actual_services";
      break;
    case "service-packages":
      permName = "branch_service_packages";
      break;
    case "cash-packages":
      permName = "branch_cash_packages";
      break;
    case "employees":
      permName = "branch_employees";
      break;
    case "terminals":
      permName = "branch_terminals";
      break;
    case "categories":
      permName = "branch_product_categories";
      break;
    default:
      break;
  }
  return getSubMenuValue(permName);
};

const subMenuCashPackage = url => {
  return getSubMenuValue("cash_package_cash_packages");
};

const subMenuCustomer = url => {
  let permName = "";
  switch (url) {
    case "customers":
      permName = "customer_customers";
      break;
    case "nationalities":
      permName = "customer_nationalities";
      break;
    case "packages":
      permName = "customer_packages";
      break;
    default:
      break;
  }

  return getSubMenuValue(permName);
};

const subMenuEmployee = url => {
  let permName = "";
  switch (url) {
    case "employees":
      permName = "employee_employees";
      break;
    case "types":
      permName = "employee_types";
      break;
    default:
      break;
  }
  return getSubMenuValue(permName);
};

const subMenuPriceList = url => {
  return getSubMenuValue("price_list_price_lists");
};

const subMenuProduct = url => {
  let permName = "";
  switch (url) {
    case "products":
      permName = "product_products";
      break;
    case "brands":
      permName = "product_brands";
      break;
    case "categories":
      permName = "product_categories";
      break;
    case "units":
      permName = "product_units";
      break;
    case "distributors":
      permName = "product_distributors";
      break;
    default:
      break;
  }
  return getSubMenuValue(permName);
};

const subMenuReport = url => {
  let permName = "";
  switch (url) {
    case "daily":
      permName = "report_daily";
      break;
    case "employees":
      permName = "report_employee";
      break;
    case "erp_transaction":
      permName = "report_erp_transaction";
      break;
    case "erp_customer":
      permName = "report_erp_customer";
      break;
    case "goods_received":
      permName = "report_goods_received";
      break;
    case "salon_used":
      permName = "report_salon_used";
      break;
    case "physical_count":
      permName = "report_physical_count";
      break;
    case "transfer":
      permName = "report_transfer";
      break;
    case "stock_benchmark":
      permName = "report_stock_benchmark";
      break;
    case "current_stock":
      permName = "report_current_stock";
      break;
    case "payment":
      permName = "report_payment";
      break;
    default:
      break;
  }
  return getSubMenuValue(permName);
};

const subMenuSchedule = url => {
  return getSubMenuValue("schedule_report_schedule_report");
};

const subMenuServicePackage = url => {
  let permName = "";
  switch (url) {
    case "services":
    case "packages":
      permName = "service_package_service_packages";
      break;
    case "groups":
      permName = "service_package_groups";
      break;
    default:
      break;
  }
  return getSubMenuValue(permName);
};

const subMenuSystem = url => {
  return getSubMenuValue("system_user_users");
};

const getActionMenu = (url, name) => {
  if (url.match(/new/g)) {
    return "Create";
  }
  if (url.match(/edit/g)) {
    let canEdit = checkPermission(name, "can_edit", false);
    return canEdit === true ? "Edit" : "";
  }

  if (url.match(/history/g)) {
    return "Transactions";
  }

  return "";
};

export { getUrl, getPageTitle, getBreadcrumbTitle, getActionMenu };
