import React from "react";
import { Switch } from "react-router-dom";

import ScheduleReportList from "./ScheduleReportList";
import NewScheduleReport from "./NewScheduleReport";
import EditScheduleReport from "./EditScheduleReport";
import  { PrivateRoute } from 'lib/widget'

const ScheduleReports = () => {
  return (
    <Switch>
      <PrivateRoute path="/schedule-report/new" component={NewScheduleReport} perm="schedule_report_schedule_report" action="can_add"/>
      <PrivateRoute path="/schedule-report/edit/:id" component={EditScheduleReport} perm="schedule_report_schedule_report" />
      <PrivateRoute path="/schedule-report" component={ScheduleReportList} perm="schedule_report_schedule_report" />
    </Switch>
  );
};

export default ScheduleReports;
