import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadBranchActualServices(action) {
  try {
    const { branchId, pagination, limit, cond, sort } = action.payload;
    const res = yield fetcher.post("masterdata/actualservices", {
      branch_id: branchId,
      pagination,
      limit,
      cond,
      sort
    });
    yield put(actions.loadBranchActualServices.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadBranchActualServices.failure(getErrorMsg(error)));
    yield put(push("/branch/actual-services"));
  }
}

function* editBranchHasActualService(action) {
  const { branch_id, services } = action.payload;
  try {
    const res = yield fetcher.post(
      `branch_has/create/actualService/${branch_id}`,
      services
    );
    yield put(actions.editBranchHasActualService.success(res.data));
    yield put(push("/branch/actual-services"));
  } catch (error) {
    handleError(error);
    yield put(actions.editBranchHasActualService.failure(getErrorMsg(error)));
    yield put(push("/branch/actual-services/new"));
  }
}

function* deleteBranchHasService(action) {
  try {
    const { id, branch_id } = action.payload;
    const res = yield fetcher.delete(
      `branch_has/delete/actualService/${branch_id}/${id}`
    );
    yield put(actions.deleteBranchHasService.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteBranchHasService.failure(getErrorMsg(error)));
  }
}

function* importBranchHasActualService(action) {
  try {
    const { file, type, pagination, limit, keyword, branchId } = action.payload;
    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    formData.append("branch_id", branchId);
    const res = yield fetcher.post("import/branch_has", formData);
    yield put(actions.importBranchHasActualService.success(res.data));
  } catch (error) {
    yield put(actions.importBranchHasActualService.failure(getErrorMsg(error)));
    yield put(push("/branch/actual-services"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/branch_has/actualService", {
      branch_id: action.payload.branch_id
    });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/branch_has/actualService`, {
      id: data
    });
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchBranchActualServices() {
  yield all([
    takeEvery(
      actions.LOAD_BRANCH_ACTUAL_SERVICES.REQUEST,
      loadBranchActualServices
    ),
    takeEvery(
      actions.IMPORT_BRANCH_HAS_ACTUAL_SERVICE.REQUEST,
      importBranchHasActualService
    ),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(
      actions.EDIT_BRANCH_HAS_ACTUAL_SERVICE.REQUEST,
      editBranchHasActualService
    ),
    takeEvery(
      actions.DELETE_BRANCH_HAS_SERVICE.REQUEST,
      deleteBranchHasService
    ),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
