import { createTypes, createAction } from "lib/action";

const LOAD_PERMISSIONS = createTypes("permissions", "list");
const LOAD_PERMISSION = createTypes("permissions", "show");
const CREATE_PERMISSION = createTypes("permissions", "create");
const EDIT_PERMISSION = createTypes("permissions", "edit");
const DELETE_PERMISSION = createTypes("permissions", "delete");
const UPDATE_ORDER_PERMISSION = createTypes("permissions", "update_order");
const DESTROY_MANY = createTypes("permissions", "destory");

const loadPermissions = {
  request: (pagination, limit, cond, sort) =>
    createAction(LOAD_PERMISSIONS.REQUEST, { pagination, limit, cond, sort }),
  success: permissions =>
    createAction(LOAD_PERMISSIONS.SUCCESS, { permissions }),
  failure: error => createAction(LOAD_PERMISSIONS.FAILURE, { error })
};

const loadPermission = {
  request: permissionId =>
    createAction(LOAD_PERMISSION.REQUEST, { permissionId }),
  success: permission =>
    createAction(LOAD_PERMISSION.SUCCESS, { permission }),
  failure: error => createAction(LOAD_PERMISSION.FAILURE, { error })
};

const createPermission = {
  request: (permission) =>
    createAction(CREATE_PERMISSION.REQUEST, { permission }),
  success: permission =>
    createAction(CREATE_PERMISSION.SUCCESS, { permission }),
  failure: error => createAction(CREATE_PERMISSION.FAILURE, { error })
};

const editPermission = {
  request: (id, permission) =>
    createAction(EDIT_PERMISSION.REQUEST, { id, permission }),
  success: permission =>
    createAction(EDIT_PERMISSION.SUCCESS, { permission }),
  failure: error => createAction(EDIT_PERMISSION.FAILURE, { error })
};

const updateOrder = {
  request: (permission) =>
    createAction(UPDATE_ORDER_PERMISSION.REQUEST, { permission }),
  success: permission =>
    createAction(UPDATE_ORDER_PERMISSION.SUCCESS, { permission }),
  failure: error => createAction(UPDATE_ORDER_PERMISSION.FAILURE, { error })
}

const deletePermission = {
  request: id => createAction(DELETE_PERMISSION.REQUEST, { id }),
  success: permission =>
    createAction(DELETE_PERMISSION.SUCCESS, { permission }),
  failure: error => createAction(DELETE_PERMISSION.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}


export {
  LOAD_PERMISSIONS,
  loadPermissions,
  LOAD_PERMISSION,
  loadPermission,
  CREATE_PERMISSION,
  createPermission,
  EDIT_PERMISSION,
  editPermission,
  DELETE_PERMISSION,
  deletePermission,
  UPDATE_ORDER_PERMISSION,
  updateOrder,
  DESTROY_MANY,
  destroyMany
};
