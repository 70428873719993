import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Popconfirm, Divider, Menu, Dropdown, Modal } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { checkPermission } from "lib/helper";
import styled from "styled-components";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const Button = styled.a`
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
`;

const ListAction = ({
  editUrl,
  editTitle = "Edit",
  onDelete,
  id,
  name,
  spacial = false,
  breakpoint = 1360
}) => {
  let check_edit = checkPermission(name, "can_edit", false);
  let check_delete = checkPermission(name, "can_delete", false);

  const DeleteComp = (
    <Popconfirm
      title="Are you sure？"
      okText="Yes"
      cancelText="No"
      onConfirm={() => {
        onDelete(id);
      }}
    >
      {check_delete && <Button>Delete</Button>}
    </Popconfirm>
  );

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure delete this data?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDelete(id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const menu = (
    <Menu>
      {spacial !== false && <Menu.Item>{spacial}</Menu.Item>}

      {check_edit && editUrl !== false && (
        <Menu.Item>
          <Link to={editUrl}>{editTitle}</Link>
        </Menu.Item>
      )}

      {!check_edit && editUrl !== false && (
        <Menu.Item>
          <Link to={editUrl}>View</Link>
        </Menu.Item>
      )}

      {check_delete && onDelete !== false && (
        <Menu.Item>
          <Button onClick={showDeleteConfirm} type="link">
              Delete
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );

  const Desktop = (
    <Fragment>
      {spacial !== false && <Fragment>{spacial}</Fragment>}
      {check_edit && editUrl !== false && (
        <Fragment>
          <Link to={editUrl}>{editTitle}</Link>
          {getDivider(check_edit, check_delete, "&&")}
        </Fragment>
      )}
      {!check_edit && editUrl !== false && (
        <Fragment>
          <Link to={editUrl}>View</Link>
          {getDivider(true, check_delete, "&&")}
        </Fragment>
      )}
      { check_delete && onDelete !== false && DeleteComp }
    </Fragment>
  );

  const Mobile = (
    <Dropdown overlay={menu}>
      <Button className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <MoreOutlined />
      </Button>
    </Dropdown>
  );

  return (
    <Fragment>{window.innerWidth >= breakpoint ?  Desktop  :  Mobile  }</Fragment>
  );
};

const getDivider = (check_edit, check_delete, symbol) => {
  if (symbol === "||") {
    if (check_edit === true || check_delete === true)
      return <Divider type="vertical" />;
  } else {
    if (check_edit === true && check_delete === true)
      return <Divider type="vertical" />;
  }
  return "";
};

export default ListAction;
