import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import DistributorForm from "./DistributorForm";

class NewDistributor extends Component {
  createDistributor = async distributor => {
    this.props.createDistributor(
      distributor.distributor_code,
      distributor.distributor_name
    );
  };

  render() {
    const { distributor, alertMessage, errorMessage, loading } = this.props;

    return (
      <DistributorForm
        onSubmit={this.createDistributor}
        distributor={distributor}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ distributors }) => {
  return {
    distributor: distributors.distributor,
    alertMessage: distributors.alertMessage,
    errorMessage: distributors.errorMessage,
    loading: distributors.loading
  };
};

const mapDispatchToProps = {
  createDistributor: actions.createDistributor.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDistributor);
