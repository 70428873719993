import branches from "./components/branch/reducer";
import branchCashPackages from "./components/branch_cash_package/reducer";
import branchCategories from "./components/branch_category/reducer";
import branchEmployees from "./components/branch_employee/reducer";
import branchProducts from "./components/branch_product/reducer";
import branchActualServices from "./components/branch_service/reducer";
import branchServicePackages from "./components/branch_service_package/reducer";
import branchTerminals from "./components/branch_terminal/reducer";

const branch = {
  branches,
  branchCashPackages,
  branchCategories,
  branchEmployees,
  branchProducts,
  branchActualServices,
  branchServicePackages,
  branchTerminals
};

export { branch };
