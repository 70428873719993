import React from "react";
import { Switch } from "react-router-dom";

import ServicePackageList from "./ServicePackageList";
import NewServicePackage from "./NewServicePackage";
import EditServicePackage from "./EditServicePackage";
import EditPackageDetail from "./EditPackageDetail";
import ServicePackageDetailList from "./ServicePackageDetailList";
import NewPackageDetail from "./NewPackageDetail";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute
      path="/service-package/packages/detail/edit/:service_package_detail_id"
      component={EditPackageDetail}
      perm="service_package_service_packages" 
    />
    <PrivateRoute
      path="/service-package/packages/detail/new/:service_package_id"
      component={NewPackageDetail}
      perm="service_package_service_packages" action="can_add"
    />
    <PrivateRoute
      path="/service-package/packages/detail/:service_package_id"
      component={ServicePackageDetailList}
      perm="service_package_service_packages"
    />
    <PrivateRoute
      path="/service-package/packages/edit/:service_package_id"
      component={EditServicePackage}
      perm="service_package_service_packages" 
    />
    <PrivateRoute path="/service-package/packages/new" component={NewServicePackage} perm="service_package_service_packages" action="can_add"/>
    <PrivateRoute path="/service-package/packages" component={ServicePackageList} perm="service_package_service_packages"/>
  </Switch>
);
