import { createTypes, createAction } from "lib/action";

const LOAD_NATIONALITIES = createTypes("Nationalities", "list");
const LOAD_NATIONALITY = createTypes("Nationality", "show");
const CREATE_NATIONALITY = createTypes("Nationality", "create");
const EDIT_NATIONALITY = createTypes("Nationality", "edit");
const DELETE_NATIONALITY = createTypes("Nationality", "delete");
const UPLOAD_FILE = createTypes("Nationality", "import");
const DOWNLOAD_FILE = createTypes("Nationality", "export");
const DESTROY_MANY = createTypes("Nationality", "destory");

const loadNationalities = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_NATIONALITIES.REQUEST, { pagination, limit, keyword, sort }),
  success: nationalities =>
    createAction(LOAD_NATIONALITIES.SUCCESS, { nationalities }),
  failure: () => createAction(LOAD_NATIONALITIES.FAILURE)
};

const loadNationality = {
  request: nationalityId =>
    createAction(LOAD_NATIONALITY.REQUEST, { nationalityId }),
  success: nationality =>
    createAction(LOAD_NATIONALITY.SUCCESS, { nationality }),
  failure: error => createAction(LOAD_NATIONALITY.FAILURE, { error })
};

const createNationality = {
  request: (nationality) =>
    createAction(CREATE_NATIONALITY.REQUEST, {
      nationality
    }),
  success: nationality =>
    createAction(CREATE_NATIONALITY.SUCCESS, { nationality }),
  failure: () => createAction(CREATE_NATIONALITY.FAILURE)
};

const editNationality = {
  request: (id, nationality) =>
    createAction(EDIT_NATIONALITY.REQUEST, {
      id,
      nationality
    }),
  success: nationality =>
    createAction(EDIT_NATIONALITY.SUCCESS, { nationality }),
  failure: () => createAction(EDIT_NATIONALITY.FAILURE)
};

const deleteNationality = {
  request: id => createAction(DELETE_NATIONALITY.REQUEST, { id }),
  success: nationality =>
    createAction(DELETE_NATIONALITY.SUCCESS, { nationality }),
  failure: error => createAction(DELETE_NATIONALITY.FAILURE, { error })
};

const upload = {
  request: (file, pagination, limit, keyword) =>
    createAction(UPLOAD_FILE.REQUEST, {
      file,
      pagination,
      limit,
      keyword
    }),
  success: data => createAction(UPLOAD_FILE.SUCCESS, { data }),
  failure: error => createAction(UPLOAD_FILE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: error => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_NATIONALITIES,
  loadNationalities,
  LOAD_NATIONALITY,
  loadNationality,
  CREATE_NATIONALITY,
  createNationality,
  EDIT_NATIONALITY,
  editNationality,
  DELETE_NATIONALITY,
  deleteNationality,
  upload,
  UPLOAD_FILE,
  download,
  DOWNLOAD_FILE,
  DESTROY_MANY,
  destroyMany
};
