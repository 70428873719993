import React, { Component, Fragment } from "react";
import { Row, Col, Card, Spin } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { numberFormat } from "lib/helper";
import styled from "styled-components";

const Number = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const Increase = styled.span`
  color: #00e815;
  padding-right: inherit;
  font-size: 12px;
  font-weight: bold;
`;

const Decrease = styled.span`
  color: #c90400;
  padding-right: inherit;
  font-size: 12px;
  font-weight: bold;
`;

export class Widget extends Component {
  handleUndefined = (val, key) => {
    return typeof val === "undefined" ? 0 : val[key];
  };

  getCardType = (revenue) => {
    let rev = revenue.revenue,
      avg = revenue.average,
      cnt = revenue.count_sales,
      total = revenue.total

    let se_re = this.handleUndefined(rev, "revenue"),
      se_comp = this.handleUndefined(rev, "compared"),
      total_r = this.handleUndefined(total, "revenue"),
      percent_c = this.handleUndefined(total, "compared")

    const cardType = [
      {
        name: "Sales Revenue",
        value: se_re,
        diff: se_comp,
        classname: "blue-gradient",
      },
      {
        name: "% From Total Revenue",
        value: total_r,
        diff: percent_c,
        classname: "blue-gradient",
      },
    ];
    return cardType;
  };

  comparedPart = (data, comparedLabel) => (
    <Fragment>
      <br />
      {data.diff < 0 ? (
        <Decrease>
          <ArrowDownOutlined />
          {numberFormat(data.diff * -1)} %
        </Decrease>
      ) : (
        <Increase>
          <ArrowUpOutlined /> {numberFormat(data.diff)} %
        </Increase>
      )}
      than last {comparedLabel}
    </Fragment>
  );

  render() {
    const { revenue, loading, comparedLabel } = this.props;
    const cardType = this.getCardType(revenue);
    return (
      <Fragment>
        
        {cardType.map((data, i) => (
          <Row gutter={[8, 8]} justify="space-between" key={i}>
            <Col span={24}>
              <Card
                style={{
                  marginBottom: 10,
                  border: "1px solid #d9d9d9",
                }}
                bodyStyle={{ padding: "14px" }}
              >
                <Spin spinning={loading}>
                  <Row gutter={[8, 8]} justify="end">
                    <Col span={24}>{data.name}</Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Number>{numberFormat(data.value)}</Number>
                     {this.comparedPart(data, comparedLabel)}
                    </Col>
                  </Row>
                </Spin>
              </Card>
            </Col>
             </Row>
        ))}
      </Fragment>
    );
  }
}

Widget.defaultProps = {
  revenue: {
    revenue: {},
    average: {},
  },
};

export default Widget;
