import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import { all, put, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";

function* loadServicePackages(action) {
  try {
    const { pagination, limit, cond, sort } = action.payload;
    const res = yield fetcher.post(`servicepackages/allServicePackages`, {
      pagination,
      limit,
      cond,
      sort
    });
    yield put(actions.loadServicePackages.success(res.data));
  } catch (error) {
    getErrorMsg(error);
  }
}

function* loadServicePackage(action) {
  try {
    const { servicePackageId } = action.payload;
    const res = yield fetcher.get(`servicepackages/${servicePackageId}`);
    yield put(actions.loadServicePackage.success(res.data));
  } catch (error) {
    getErrorMsg(error);
    yield put(actions.loadServicePackage.failure(getErrorMsg(error)));
  }
}

function* createServicePackage(action) {
  try {
    const { servicePackage, checkedList, actualservice } = action.payload;
    console.log(actualservice)
    const formData = getFormData(servicePackage, checkedList);
    const res = yield fetcher.post(`servicepackages`, { ...formData, actualservice});
    yield put(actions.createServicePackage.success(res.data));
    yield put(
      push(
        `/service-package/packages`
      )
    );
  } catch (error) {
    handleError(error);
    yield put(actions.createServicePackage.failure(getErrorMsg(error)));
    yield put(push("/service-package/packages/new"));
  }
}

function* editServicePackage(action) {
  const { id, servicePackage, checkedList } = action.payload;
  try {
    const formData = getFormData(servicePackage, checkedList);
    const res = yield fetcher.patch(`servicepackages/${id}`, formData);
    yield put(actions.editServicePackage.success(res.data));
    yield put(push("/service-package/packages"));
  } catch (error) {
    handleError(error);
    yield put(actions.editServicePackage.failure(getErrorMsg(error)));
    yield put(push(`/service-package/packages/edit/${id}`));
  }
}

function* loadServicePackageDetails(action) {
  try {
    const { servicePackageId } = action.payload;
    const res = yield fetcher.get(
      `servicepackages/getPackageHasServices/${servicePackageId}`
    );
    yield put(actions.loadServicePackageDetails.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadServicePackageDetail(action) {
  try {
    const { serviceId } = action.payload;
    const res = yield fetcher.get(`servicepackages/getService/${serviceId}`);
    yield put(actions.loadServicePackageDetail.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadServicePackageDetail.failure(getErrorMsg(error)));
  }
}

function* createServicePackageDetail(action) {
  const { servicePackageId, serviceDetail } = action.payload;
  try {
    const res = yield fetcher.post(`servicepackages/addServiceToPackage`, {
      service_package_id: servicePackageId,
      actual_service_id: serviceDetail.actual_service_id,
      description: serviceDetail.description,
      quantity: serviceDetail.quantity
    });
    yield put(actions.createServicePackageDetail.success(res.data));
    yield put(push(`/service-package/packages/detail/${servicePackageId}`));
  } catch (error) {
    handleError(error);
    yield put(actions.createServicePackageDetail.failure(getErrorMsg(error)));
    yield put(push(`/service-package/packages/detail/new/${servicePackageId}`));
  }
}

function* editServicePackageDetail(action) {
  const { id, servicePackageDetail } = action.payload;
  try {
    const res = yield fetcher.post(`servicepackages/updateServiceQuantity`, {
      service_package_id: servicePackageDetail.service_package_id,
      actual_service_id: servicePackageDetail.actual_service_id,
      quantity: servicePackageDetail.quantity,
      description: servicePackageDetail.description
    });
    yield put(actions.editServicePackageDetail.success(res.data));
    yield put(push("/service-package/packages"));
  } catch (error) {
    handleError(error);
    yield put(actions.editServicePackageDetail.failure(getErrorMsg(error)));
    yield put(push(`/service-package/packages/detail/edit/${id}`));
  }
}

function* deleteServicePackage(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`servicepackages/${id}`);
    yield put(actions.deleteServicePackage.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteServicePackage.failure(getErrorMsg(error)));
  }
}

function* deleteServicePackageDetail(action) {
  try {
    const { id } = action.payload;
    yield fetcher.get(`servicepackages/deleteDetail/${id}`);
    yield put(actions.deleteServicePackageDetail.success(id));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteServicePackageDetail.failure(getErrorMsg(error)));
  }
}

function* importServicePackage(action) {
  try {
    const { file, type, pagination, limit, keyword, adjustPrice } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    formData.append("adjustPrice", adjustPrice);
    if (keyword) formData.append("keyword", keyword);

    const res = yield fetcher.post(`import/servicepackages`, formData);
    yield put(actions.importServicePackage.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importServicePackage.failure(getErrorMsg(error)));
    yield put(push("/service-package/packages"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post(`export/export_masterdata`, { type: "servicePackages" });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function getFormData(product, checkedList) {
  let allow = ["barcode", "service_package_name", "service_package_group_id", "pricelist", "is_show"];

  let products = {};
  Object.keys(product).map(x => {
    if (allow.includes(x)) {
      products[x] = product[x];
    }
  });

  let check_list = {};
  checkedList.map(check => {
    let key = `${check.name}`;
    check_list[key] = check.value;
  });

  return { ...products, check_list };
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/servicepackage`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchServicePackages() {
  yield all([
    takeEvery(actions.LOAD_SERVICE_PACKAGES.REQUEST, loadServicePackages),
    takeEvery(actions.LOAD_SERVICE_PACKAGE.REQUEST, loadServicePackage),
    takeEvery(actions.CREATE_SERVICE_PACKAGE.REQUEST, createServicePackage),
    takeEvery(actions.EDIT_SERVICE_PACKAGE.REQUEST, editServicePackage),
    takeEvery(
      actions.LOAD_SERVICE_PACKAGE_DETAILS.REQUEST,
      loadServicePackageDetails
    ),
    takeEvery(
      actions.LOAD_SERVICE_PACKAGE_DETAIL.REQUEST,
      loadServicePackageDetail
    ),
    takeEvery(
      actions.CREATE_SERVICE_PACKAGE_DETAIL.REQUEST,
      createServicePackageDetail
    ),
    takeEvery(
      actions.EDIT_SERVICE_PACKAGE_DETAIL.REQUEST,
      editServicePackageDetail
    ),
    takeEvery(actions.DELETE_SERVICE_PACKAGE.REQUEST, deleteServicePackage),
    takeEvery(
      actions.DELETE_SERVICE_PACKAGE_DETAIL.REQUEST,
      deleteServicePackageDetail
    ),
    takeEvery(actions.IMPORT_SERVICE_PACKAGE.REQUEST, importServicePackage),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
