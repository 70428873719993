import React from "react";
import { Switch } from "react-router-dom";

import CustomerList from "./CustomerList";
import NewCustomer from "./NewCustomer";
import EditCustomer from "./EditCustomer";
import CustomerHistory from "./CustomerHistory";

import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/customer/customers/new"
      component={NewCustomer}
      perm="customer_customers"
      action="can_add"
    />
    <PrivateRoute
      path="/customer/customers/edit/:customer_id"
      component={EditCustomer}
      perm="customer_customers"
    />
    <PrivateRoute
      path="/customer/customers/history/:customer_id"
      component={CustomerHistory}
      perm="customer_customers"
    />
    <PrivateRoute
      path="/customer/customers"
      component={CustomerList}
      perm="customer_customers"
    />
  </Switch>
);
