import React, { PureComponent } from "react";
import { Table, Checkbox, Input, Button } from "antd";
import axios from "axios";
import { extractKeyFromArray , groupArray } from "lib/helper";
import { SearchOutlined } from "@ant-design/icons";

export class PermissionTable extends PureComponent {
  state = {
    searchText: "",
    searchedColumn: "",
    data: [],
    permissions: [],
    groupPermission: [],
    prevPermGroup: this.props.permGroup,
    groupCnt: 0
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => text
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      ...this.state,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ ...this.state, searchText: "" });
  };

  getData = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/permissions/allPermissions`,
        {
          pagination: null,
          limit: null,
          keyword: null
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      this.setState({
        data: this.genTableValue(res.data.data),
        permissions: res.data.data,
        groupCnt: Object.keys(groupArray(res.data.data, 'permission_group')).length
      });
    } catch (error) {
      console.error(error);
    }
  };

  genTableValue = data => {
    let items = [];
    data.map((val, i) => {
      return items.push({
        permission_id: val.permission_id,
        display_name: val.display_name,
        permission_group: val.permission_group,
        can_view: val.view_permission,
        can_add: val.add_permission,
        can_edit: val.edit_permission,
        can_delete: val.delete_permission,
        can_import: val.import_permission,
        can_export: val.export_permission,
        can_other_1: val.other_1_permission,
        other_1_name: val.other_1_name,
        can_other_2: val.other_2_permission,
        other_2_name: val.other_2_name
      });
    });

    return items;
  };

  formatSelectValue = selected => {
    let perm = selected.map(val => {
      return Object.keys(val)
        .filter(key => key.match(/_permission/g))
        .reduce((obj, key) => {
          let fieldName = key.replace(/_permission/g, "");
          let name = `can_${fieldName}`;
          let keyname = `${val.permission_id}-${name}`;
          obj[keyname] = 1;

          return obj;
        }, {});
    });

    return Object.assign({}, ...perm);
  };

  checkAllPerm = permGroup => {
    let group = this.props.permGroup;
    let lastValue = group[group.length - 1];
    let filter = [];
    let selected = [];

    if (permGroup !== "") {
      if (group.length > this.state.prevPermGroup.length) {
        if (group.length === this.state.groupCnt) {
          filter = this.state.permissions.filter(x =>
            group.includes(x.permission_group_id)
          );
        } else {
          filter = this.state.permissions.filter(
            x => x.permission_group_id === lastValue
          );
        }
      }

      selected = this.formatSelectValue(filter);
    }

    this.setState(
      prevState => {
        if (group.length === 0) {
          Object.keys(prevState)
            .filter(key => key.match(/-can/g))
            .map(name => {
              return prevState[name] = -1;
            });
        } else if (group.length < this.state.prevPermGroup.length) {
          
          let removeGroup = this.state.prevPermGroup.filter(
            x => !group.includes(x)
          );
          let filter = prevState.permissions.filter(x =>
            removeGroup.includes(x.permission_group_id)
          );
          let removeId = extractKeyFromArray (filter, "permission_id");

          Object.keys(prevState)
            .filter(key => key.match(/-can/g))
            .map(name => {
              let id = parseInt(name.split("-")[0]);
              if (removeId.includes(id)) {
                return prevState[name] = -1;
              }
            });
        }

        return {
          ...prevState,
          ...selected,
          prevPermGroup: group
        };
      },
      () => {
        let data = Object.assign({}, this.state);
        let removeKey = [
          "searchText",
          "searchedColumn",
          "data",
          "permissions",
          "groupPermission",
          "prevPermGroup",
          "permGroup",
          "groupCnt"
        ];

        removeKey.map(name => delete data[name]);

        this.props.setFieldValue(`permission_table_state`, data);
      }
    );
  };

  formatShowValue = perm => {
    let values = perm.map(val => {
      return Object.keys(val)
        .filter(key => key.match(/can/g))
        .reduce((obj, key) => {
          let keyname = `${val.permission_id}-${key}`;
          obj[keyname] = val[key] === 1 ? 1 : -1;
          return obj;
        }, {});
    });

    this.setState(prevState => {
      return {
        ...prevState,
        ...Object.assign({}, ...values),
        groupPermission: perm
      };
    });
  };

  onCheckboxChange = (id, name) => e => {
    let el = e.target;
    let val = el.checked ? 1 : -1;

    this.setState({ [`${id}-${name}`]: val });
    this.props.setFieldValue(`${id}-${name}`, val);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.groupPermission !== prevState.groupPermission &&
      typeof nextProps.groupPermission === "object" &&
      typeof prevState.groupPermission === "object"
    ) {
      return { groupPermission: nextProps.groupPermission };
    }

    if (
      nextProps.permGroup !== prevState.permGroup &&
      typeof nextProps.permGroup === "object"
    ) {
      return { permGroup: nextProps.permGroup };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.groupPermission !== this.props.groupPermission) {
      if (typeof this.props.groupPermission !== "undefined") {
        this.formatShowValue(this.props.groupPermission);
      }
      return;
    }

    if (prevProps.permGroup !== this.props.permGroup) {
        let data = (this.props.userGroupId !== '') ? prevProps.permGroup : this.props.permGroup
        this.checkAllPerm(data);
    }
  }

  componentDidMount() {
    this.getData();
  }

  getRowComponent = (value, data, keyName, label = "") => {
    if (value === 1)
      return (
        <Checkbox
          checked={this.state[`${data.permission_id}-${keyName}`] === 1}
          onChange={this.onCheckboxChange(data.permission_id, keyName)}
        >
          {label}
        </Checkbox>
      );
    else return "";
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "display_name",
        width: 270,
        ...this.getColumnSearchProps("display_name")
      },
      {
        title: "Group",
        dataIndex: "permission_group",
        width: 130,
        ...this.getColumnSearchProps("permission_group")
      },
      {
        title: "View",
        dataIndex: "can_view",
        className: "align-center",
        width: 80,
        render: (value, data) =>
          this.getRowComponent(value, data, "can_view", "")
      },
      {
        title: "Create",
        dataIndex: "can_add",
        className: "align-center",
        width: 80,
        render: (value, data) =>
          this.getRowComponent(value, data, "can_add", "")
      },
      {
        title: "Edit",
        dataIndex: "can_edit",
        className: "align-center",
        width: 80,
        render: (value, data) =>
          this.getRowComponent(value, data, "can_edit", "")
      },
      {
        title: "Delete",
        dataIndex: "can_delete",
        className: "align-center",
        width: 80,
        render: (value, data) =>
          this.getRowComponent(value, data, "can_delete", "")
      },
      {
        title: "Export",
        dataIndex: "can_export",
        className: "align-center",
        width: 80,
        render: (value, data) =>
          this.getRowComponent(value, data, "can_export", "")
      },
      {
        title: "Import",
        dataIndex: "can_import",
        className: "align-center",
        width: 80,
        render: (value, data) =>
          this.getRowComponent(value, data, "can_import", "")
      },
      {
        title: "Other 1",
        dataIndex: "can_other_1",
        className: "align-center",
        width: 135,
        render: (value, data) =>
          this.getRowComponent(value, data, "can_other_1", data.other_1_name)
      },
      {
        title: "Other 2",
        dataIndex: "can_other_2",
        className: "align-center",
        width: 135,
        render: (value, data) =>
          this.getRowComponent(value, data, "can_other_2", data.other_2_name)
      }
    ];

    return (
      <Table
        rowKey={"permission_id"}
        columns={columns}
        dataSource={this.state.data}
        pagination={false}
        scroll={{ y: 240 }}
      />
    );
  }
}

export default PermissionTable;
