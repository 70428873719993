import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import { loadNationalities } from "modules/customers/components/nationality/actions";

import CustomerForm from "./CustomerForm";

class NewCustomer extends Component {
  componentDidMount() {
    const clientId = 1;
    this.props.loadNationalities(clientId);
  }

  createCustomer = async customer => {
    this.props.createCustomer(customer);
  };

  render() {
    const {
      nationalities,
      alertMessage,
      errorMessage,
      customer,
      loading
    } = this.props;

    return (
      <CustomerForm
        onSubmit={this.createCustomer}
        customer={customer}
        nationalities={nationalities}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ nationalities, customers }) => {
  return {
    nationalities: nationalities.data,
    alertMessage: customers.alertMessage,
    errorMessage: customers.errorMessage,
    customer: customers.customer,
    loading: customers.loading
  };
};

const mapDispatchToProps = {
  createCustomer: actions.createCustomer.request,
  loadNationalities: loadNationalities.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCustomer);
