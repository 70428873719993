import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

import { Table, Row, Col, Spin, message, Typography } from "antd";

import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  uniqueArray,
  extractKeyFromArray
} from "lib/helper";
import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  FilterBtn,
  TagFilter,
  AddBtn,
  PageTitle
} from "lib/widget";

import Filter from "./Filter";

const { Text } = Typography;

class UserList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = nextProps.users;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (errorImport !== "") {
      return { errorImport: errorImport };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
    rowSelection: []
  };

  fetchData = () => {
    const { page, limit, cond, sorter } = this.state
    setSorter(sorter)
    setSearch(cond)
    this.props.loadUsers(page, limit, cond, sorter)
  }

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter
    const { current, pageSize } = pagination
    this.setState({ page: current, limit: pageSize, sorter: { order, field } }, this.fetchData);
  }

  handleSearch = (val, filter) => {
    this.setState({ page: 1, visible: false, keyword: val.keyword, cond: val }, this.fetchData);
    this.getFilterTag(filter, val.keyword);
  };

  getFilterTag = (filter, keyword) => {
    let group = "",
      word = "";
    if (keyword !== "") word = `${keyword}`;

    Object.keys(filter).map(key => {
      filter[key].map(val => {
        if (key === "userGroup")
          group += (group !== "" ? ", " : "") + val.usergroup_name;
      });
    });

    this.setState({
      groupTag: (group !== "") ? `${group}` : "",
      keywordTag: word
    });

    setFilterTag({
      groupTag: (group !== "") ? `${group}` : "",
      keywordTag: word
    })
  };

  componentDidMount() {
    let filter = getFilterTag()

    if (typeof filter === 'object') {
      this.setState({ ...filter, cond: getSearch(), sorter: getSorter() }, this.fetchData)
      return
    }
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = this.props.users;
    const prevMsg = prevProps.users;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (errorImport !== "" && prevMsg.errorImport !== errorImport) {
      this.openNotification(errorImport);
    }
  }

  deleteUser(id) {
    this.props.deleteUser(id);
  }

  onSelectChange = selectedRowKeys => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.users.data.filter(x =>
        selectedRowKeys.includes(x.user_id)
      );

      let removeState = this.state.removeData.filter(x =>
        selectedRowKeys.includes(x.user_id)
      );
      items = uniqueArray([...removeState, ...removeData], "user_id");
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = name => () => {
    this.setState({
      [name]: false
    });
  };

  showDrawer = name => () => {
    this.setState({
      [name]: true
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "user_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = id => {
    let item = this.state.selectedRowKeys.filter(x => id !== x);
    let removeData = this.state.removeData.filter(x => x.user_id !== id);
    this.setState({ selectedRowKeys: item, removeData });
  };

  doNothing = () => { }

  render() {
    const { users } = this.props;
    const { selectedRowKeys, groupTag, keywordTag, sorter } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const actionCol = [
      {
        title: "",
        key: users.user_id,
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: 'left',
        render: users => {
          let logInUserId = localStorage.getItem("user_id")
          let isSuper = (users.usergroup_name.toLowerCase() == "su")

          let editUrl = `/system/users/edit/${users.user_id}`
          let deleteFunc = this.props.deleteUser

          if (isSuper && (parseInt(users.user_id) !== parseInt(logInUserId))) {
            deleteFunc = false
            editUrl = false
          }

          return <ListAction
            editUrl={editUrl}
            onDelete={deleteFunc}
            id={users.user_id}
            name="system_user_users"
            breakpoint={500}
          />
        }
      }
    ]

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1
      },
    ]

    const mainColumns = [
      {
        title: "Email",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'email' ? sorter.order : false,
        width: 200,
        dataIndex: "email",
      },
      {
        title: "Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'name' ? sorter.order : false,
        dataIndex: "name"
      },
      {
        title: "User group",
        sorter: true,
        dataIndex: "usergroup_name"
      },
    ];

    const columns = window.innerWidth <= 500 ? [...actionCol, ...mainColumns] : [...numberCol, ...mainColumns, ...actionCol]

    return (
      <div>
        <Spin size="large" spinning={this.props.users.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              name="system_user_users"
              component={
                <AddBtn url="/system/users/new" name="system_user_users" />
              }
            />
            <Row gutter={[16, 16]}>
              <DeleteBadge
                count={this.state.removeData.length}
                onClick={this.showDrawer("visibleDelete")}
              />
              <Col xs={13} sm={24} md={{ span: 8, offset: 10 }} lg={{ span: 8, offset: 12 }}>
              </Col>
              <FilterBtn showDrawer={this.showDrawer} />
              <Filter
                list={users.data}
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col xs={19} sm={19} md={21} lg={22}>
                {TagFilter([keywordTag], 'Keyword', 'green')}
                {TagFilter([groupTag], 'User group', 'cyan')}
              </Col>
            </Row>
            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["user_id", "name", "email"]}
            />
          </div>
          <Table
            rowKey="user_id"
            bordered={true}
            size="small"
            columns={columns}
            rowSelection={rowSelection}
            dataSource={users.data}
            pagination={{
              pageSize: this.state.limit,
              total: users.totalSize,
              current: this.state.page
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 500 ? { x: 800 } : undefined}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ users }) => ({
  users
});

const mapDispatchToProps = {
  loadUsers: actions.loadUsers.request,
  deleteUser: actions.deleteUser.request,
  destroyMany: actions.destroyMany.request
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
