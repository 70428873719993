import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, logErrorMsg, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadUserGroups(action) {
  try {
    const { pagination, limit, keyword, sorter } = action.payload;
    const res = yield fetcher.post("usergroups/allUsergroups", {
      pagination,
      limit,
      keyword,
      sorter
    });
    yield put(actions.loadUserGroups.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadUserGroups.failure(getErrorMsg(error)));
    logErrorMsg(error);
  }
}

function* loadUserGroup(action) {
  try {
    const { usergroupId } = action.payload;
    const res = yield fetcher.get(`usergroups/${usergroupId}`);
    yield put(actions.loadUserGroup.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadUserGroup.failure(getErrorMsg(error)));
  }
}

function* createUserGroup(action) {
  try {
    const { usergroup } = action.payload;
    const res = yield fetcher.post("usergroups", usergroup);
    yield put(actions.createUserGroup.success(res.data));
    yield put(push("/auth/usergroups"));
  } catch (error) {
    handleError(error);
    yield put(actions.createUserGroup.failure(getErrorMsg(error)));
    yield put(push("/auth/usergroups/new"));
  }
}

function* editUserGroup(action) {
  const { id, usergroup } = action.payload;
  try {
    const res = yield fetcher.patch(`usergroups/${id}`, usergroup);
    yield put(actions.editUserGroup.success(res.data));
    yield put(push("/auth/usergroups"));
  } catch (error) {
    handleError(error);
    yield put(actions.editUserGroup.failure(getErrorMsg(error)));
    yield put(push(`/auth/usergroups/edit/${id}`));
  }
}

function* deleteUserGroup(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`usergroups/${id}`);
    yield put(actions.deleteUserGroup.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteUserGroup.failure(getErrorMsg(error)));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/usergroup`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchUserGroups() {
  yield all([
    takeEvery(actions.LOAD_USERGROUPS.REQUEST, loadUserGroups),
    takeEvery(actions.LOAD_USERGROUP.REQUEST, loadUserGroup),
    takeEvery(actions.CREATE_USERGROUP.REQUEST, createUserGroup),
    takeEvery(actions.EDIT_USERGROUP.REQUEST, editUserGroup),
    takeEvery(actions.DELETE_USERGROUP.REQUEST, deleteUserGroup),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
