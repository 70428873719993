import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadEmployeeTypes(action) {
  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post(`employeetypes/employeetypes_list`, {
      pagination,
      limit,
      keyword,
      sort
    });
    yield put(actions.loadEmployeeTypes.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadEmployeeType(action) {
  try {
    const { employeeTypeId } = action.payload;
    const res = yield fetcher.get(`employeetypes/${employeeTypeId}`);
    yield put(actions.loadEmployeeType.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadEmployeeType.failure(getErrorMsg(error)));
  }
}

function* createEmployeeType(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`employeetypes`, data);

    yield put(actions.createEmployeeType.success(res.data));
    yield put(push("/employee/types"));
  } catch (error) {
    handleError(error);
    yield put(actions.createEmployeeType.failure(getErrorMsg(error)));
    yield put(push("/employee/types/new"));
  }
}

function* editEmployeeType(action) {
  const { id, name, code } = action.payload;
  try {
    const res = yield fetcher.patch(`employeetypes/${id}`, {
      type_name: name,
      employee_type_code: code
    });

    yield put(actions.editEmployeeType.success(res.data));
    yield put(push("/employee/types"));
  } catch (error) {
    getErrorMsg(error);
    yield put(actions.editEmployeeType.failure(getErrorMsg(error)));
    yield put(push(`/employee/types/edit/${id}`));
  }
}
function* deleteEmployeeType(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`employeetypes/${id}`);
    yield put(actions.deleteEmployeeType.success(res.data));
  } catch (error) {
    getErrorMsg(error);
    yield put(actions.deleteEmployeeType.failure(getErrorMsg(error)));
  }
}

function* upload(action) {
  try {
    const { file, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", "employeeType");
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);

    const res = yield fetcher.post(`import/details`, formData, "upload");
    yield put(actions.upload.success(res.data));
  } catch (error) {
    getErrorMsg(error);
    yield put(actions.upload.failure(getErrorMsg(error)));
    yield put(push("/employee/types"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post(`export/export_masterdata`, {
      type: "employeeType"
    });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    getErrorMsg(error);
    yield put(actions.upload.failure(getErrorMsg(error).data));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/employee/type`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchEmployeeTypes() {
  yield all([
    takeEvery(actions.LOAD_EMPLOYEE_TYPES.REQUEST, loadEmployeeTypes),
    takeEvery(actions.LOAD_EMPLOYEE_TYPE.REQUEST, loadEmployeeType),
    takeEvery(actions.CREATE_EMPLOYEE_TYPE.REQUEST, createEmployeeType),
    takeEvery(actions.EDIT_EMPLOYEE_TYPE.REQUEST, editEmployeeType),
    takeEvery(actions.DELETE_EMPLOYEE_TYPE.REQUEST, deleteEmployeeType),
    takeEvery(actions.UPLOAD_FILE.REQUEST, upload),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
