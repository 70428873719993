import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import * as actions from "../actions";
import { Form, Icon, Input, Button, Checkbox, message, Spin } from "antd";

const FormItem = Form.Item;
const imgLogo = window.location.origin + "/iTunesArtwork@2x.png";

class LoginForm extends Component {
    state = {
        message: ""
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.message !== "") {
            return { message: nextProps.message };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.message !== "") {
            message.error(this.props.message);
        }
    }

    login = async user => {
        this.props.login(user);
    };

    componentDidMount() {
        localStorage.clear();
        sessionStorage.clear();
        this.nameInput.focus();
    }

    render() {
        return (
            <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={yup.object().shape({
                    email: yup
                        .string()
                        .email()
                        .required("Email is a required field"),
                    password: yup.string().required("Password is a required field")
                })}
                onSubmit={values => this.login(values)}
                render={({
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit
                }) => (
                    <Form
                        onFinish={handleSubmit}
                        className="login-form"
                        style={{ maxWidth: 300, margin: "auto" }}
                    >
                        <Spin size="large" spinning={this.props.loading}>
                            <div
                                style={{ padding: "10px", textAlign: "center", height: "auto" }}
                            >
                                <img src={imgLogo} style={{ width: "140px" }} alt="" />
                            </div>
                            <FormItem
                                validateStatus={touched.email && errors.email && "error"}
                                help={touched.email && errors.email}
                            >
                                <Input
                                    prefix={
                                        <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                                    }
                                    placeholder="Email"
                                    ref={(input) => { this.nameInput = input; }}
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormItem>
                            <FormItem
                                validateStatus={touched.password && errors.password && "error"}
                                help={touched.password && errors.password}
                            >
                                <Input
                                    prefix={
                                        <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                                    }
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormItem>
                            <FormItem offset={8} span={8}>
                                <Checkbox>Remember me</Checkbox>
                            </FormItem>
                            <FormItem>
                                <Button type="primary" block htmlType="submit">
                                    Log in
              </Button>
                            </FormItem>
                        </Spin>
                    </Form>
                )}
            />
        );
    }
}

const mapStateToProps = ({ signin }) => ({
    message: signin.message,
    loading: signin.loading
});

const mapDispatchToProps = {
    login: actions.login.request
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
