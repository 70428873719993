import { fork } from "redux-saga/effects";

import watchServiceAnalytics from "./components/service/sagas";
import watchSaleAnalytics from "./components/sales/sagas";
import watchStylistAnalytics from "./components/stylist/sagas";
import watchCustomerAnalytics from "./components/customer/sagas";

const watchAnalytics = [
  fork(watchServiceAnalytics),
  fork(watchSaleAnalytics),
  fork(watchStylistAnalytics),
  fork(watchCustomerAnalytics)
]
export {
  watchAnalytics
}
