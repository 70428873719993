import React, { PureComponent } from "react";
import { Form, Row, Col, Spin, Transfer } from "antd";

import { Formik } from "formik";
import axios from "axios";
import { getIdtoArray } from "lib/helper";
import debounce from "lodash/debounce";
import { PageTitle, FormBtn } from "lib/widget";

const FormItem = Form.Item;

export default class BranchHasServicePackageForm extends PureComponent {
  state = {
    data: [],
    branchServicePackage: [],
    targetKeys: [],
    addKey: [],
    removeKey: [],
       removeData: [],
   visible: false,
visibleDelete: false,
    loading: true
  };

  getData = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/branch_has/get_data/servicePackage/${this.props.branchId}`,
        {
          limit: 300,
          keyword: this.state.keyword
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );

      this.setState(prevState => {
        let data = this.formatTransferValue(res.data.data);
        return {
          data: this.uniqueArray([...prevState.data, ...data]),
          loading: false
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  formatTransferValue = data => {
    let items = [];
    data.map((val, i) => {
      items.push({
        key: val.service_package_id,
        title: `${val.barcode} - ${val.service_package_name}`,
        service_package_id: val.service_package_id
      });
    });
    return items;
  };

  uniqueArray = data => {
    const unique = [];
    data.map(x =>
      unique.filter(a => a.key === x.key).length > 0 ? null : unique.push(x)
    );
    return unique;
  };

  delaySearchData = debounce(async value => {
    this.setState({ keyword: value, loading: true }, this.getData);
  }, 750);

  handleChange = (targetKeys, direction, moveKeys) => {
    if (direction === "left") {
      this.setState(prevState => {
        return {
          targetKeys,
          removeKey: [...prevState.removeKey, ...moveKeys],
          addKey: this.removeArrayKey("addKey", moveKeys)
        };
      });
    } else {
      this.setState(prevState => {
        return {
          targetKeys,
          addKey: [...prevState.addKey, ...moveKeys],
          removeKey: this.removeArrayKey("removeKey", moveKeys)
        };
      });
    }
  };

  removeArrayKey = (mode, moveKeys) => {
    return this.state[mode].filter(x => !moveKeys.includes(x));
  };

  handleSearch = (side, value) => {
    if (side === "left") {
      this.delaySearchData(value);
    }
  };

  getActualService = values => {
    this.setState(prevState => {
      let data = this.formatTransferValue(values);
      return {
        data: this.uniqueArray([...prevState.data, ...data]),
        targetKeys: getIdtoArray(data, "service_package_id"),
        branchServicePackage: values
      };
    });
  };

  componentDidMount() {
    this.getData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.branchServicePackage !== prevState.branchServicePackage) {
      return { branchServicePackage: nextProps.branchServicePackage };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.branchServicePackage !== this.props.branchServicePackage) {
      this.getActualService(this.props.branchServicePackage);
    }
  }

  render() {
    const { onSubmit, loading } = this.props;
    return (
      <div>
        <Spin size="large" spinning={this.state.loading || loading}>
          <PageTitle name="branch_service_packages" component={null} />
          <Formik
            enableReinitialize={true}
            initialValues={{}}
            onSubmit={values => {
              values["remove_id"] = this.state.removeKey;
              values["add_id"] = this.state.addKey;
              onSubmit(values);
            }}
            render={({ handleSubmit }) => {
              return (
                <Form onFinish={handleSubmit} autoComplete="off">
                  <fieldset disabled={!this.props.canEdit}>
                    <FormItem>
                      <Row>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          style={{ color: "rgba(0, 0, 0, 0.85)" }}
                        >
                          Service Packages :
                        </Col>
                      </Row>
                    </FormItem>
                    <Row justify="center">
                      <Col xs={24} sm={24} md={24} lg={19}>
                        <Transfer
                          rowKey={record => record.key}
                          titles={[
                            "Service Packages",
                            "Branch Service Packages"
                          ]}
                          dataSource={this.state.data}
                          showSearch
                          targetKeys={this.state.targetKeys}
                          onSearch={this.handleSearch}
                          onChange={(value, direction, moveKeys) => {
                            this.handleChange(value, direction, moveKeys);
                          }}
                          render={item => item.title}
                          listStyle={{
                            width: 300,
                            height: 300,
                            marginBottom: '20px'
                          }}
                        />
                      </Col>
                    </Row>

                    <FormBtn
                      url="/branch/service-packages"
                      canEdit={this.props.canEdit}
                    />
                  </fieldset>
                </Form>
              );
            }}
          />
        </Spin>
      </div>
    );
  }
}
