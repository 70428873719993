import { all, fork } from "redux-saga/effects";
import { watchActualService } from 'modules/actual-service/sagas'
import { WatchAuthentication } from 'modules/authen/sagas'
import { watchAuth } from 'modules/signin/sagas'
import { watchBranch } from 'modules/branch/sagas'
import { watchCashPackage } from 'modules/cash-package/sagas'
import { watchCustomer } from 'modules/customers/sagas'
import { watchEmployee } from 'modules/employee/sagas'
import { watchProduct } from 'modules/products/sagas'
import { watchSchedule } from 'modules/schedule/sagas'
import { watchServicePackage } from 'modules/service-package/sagas'
import { watchSystem } from 'modules/system/sagas'

import watchReports from 'modules/report/sagas'
import { watchDashboard } from 'modules/dashboard/sagas'
import { watchAnalytics } from 'modules/analytic/sagas'

export default function* rootSagas() {
    yield all([
        ...watchActualService,
        ...WatchAuthentication,
        ...watchAuth,
        ...watchBranch,
        ...watchCashPackage,
        ...watchCustomer,
        ...watchEmployee,
        ...watchProduct,
        ...watchSchedule,
        ...watchServicePackage,
        ...watchSystem,
        fork(watchReports),
        ...watchDashboard,
        ...watchAnalytics
    ])
}
