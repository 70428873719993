import { createTypes, createAction } from "lib/action";

const LOAD_WIDGET_REVENUE_SUMMARIES = createTypes("summary_revenue_widget", "list");
const LOAD_WIDGET_REVENUE_PT_SUMMARIES = createTypes("summary_pt_revenue_widget", "list");
const LOAD_WIDGET_APPOINTMENT = createTypes("appointment_widget", "list");
const LOAD_WIDGET_YEAR_REVENUE = createTypes("year_revenue_widget", "list")

const loadYearsRevenue = {
  request: (
    type,
    duration,
    branch_id
  ) =>
    createAction(LOAD_WIDGET_YEAR_REVENUE.REQUEST, {
      type,
      duration,
      branch_id
    }),
  success: (revenue) =>
    createAction(LOAD_WIDGET_YEAR_REVENUE.SUCCESS, { revenue }),
  failure: () => createAction(LOAD_WIDGET_YEAR_REVENUE.FAILURE),
};

const loadAppointment = {
  request: (type, duration, branch_id) =>
    createAction(LOAD_WIDGET_APPOINTMENT.REQUEST, { type, duration, branch_id }),
  success: (revenue, compared) =>
    createAction(LOAD_WIDGET_APPOINTMENT.SUCCESS, { revenue, compared }),
  failure: () => createAction(LOAD_WIDGET_APPOINTMENT.FAILURE),
};

const loadRevenueSummaries = {
  request: (
    type,
    duration,
    branch_id,
    query_type,
    category_id,
    withCompared = true
  ) =>
    createAction(LOAD_WIDGET_REVENUE_SUMMARIES.REQUEST, {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
      withCompared
    }),
  success: (revenue, compared) =>
    createAction(LOAD_WIDGET_REVENUE_SUMMARIES.SUCCESS, { revenue, compared }),
  failure: () => createAction(LOAD_WIDGET_REVENUE_SUMMARIES.FAILURE),
};

const loadRevenuePTSummaries = {
  request: (
    type,
    duration,
    branch_id,
    query_type,
    category_id,
  ) =>
    createAction(LOAD_WIDGET_REVENUE_PT_SUMMARIES.REQUEST, {
      type,
      duration,
      branch_id,
      query_type,
      category_id
    }),
  success: (revenue_t, compared_t) =>
    createAction(LOAD_WIDGET_REVENUE_PT_SUMMARIES.SUCCESS, { revenue_t, compared_t }),
  failure: () => createAction(LOAD_WIDGET_REVENUE_PT_SUMMARIES.FAILURE),
};

export { 
  LOAD_WIDGET_REVENUE_SUMMARIES, 
  loadRevenueSummaries,
  LOAD_WIDGET_REVENUE_PT_SUMMARIES, 
  loadRevenuePTSummaries,
  LOAD_WIDGET_APPOINTMENT,
  loadAppointment,
  LOAD_WIDGET_YEAR_REVENUE,
  loadYearsRevenue
};
