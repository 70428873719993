import { createTypes, createAction } from "lib/action";

const LOAD_UNITS = createTypes("units", "list");
const LOAD_UNIT = createTypes("units", "show");
const CREATE_UNIT = createTypes("unit", "create");
const EDIT_UNIT = createTypes("unit", "edit");
const DELETE_UNIT = createTypes("unit", "delete");
const UPLOAD_FILE = createTypes("unit", "import");
const DOWNLOAD_FILE = createTypes("unit", "export");
const DESTROY_MANY = createTypes("unit", "destory");

const loadUnits = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_UNITS.REQUEST, { pagination, limit, keyword, sort }),
  success: units => createAction(LOAD_UNITS.SUCCESS, { units }),
  failure: () => createAction(LOAD_UNITS.FAILURE)
};

const loadUnit = {
  request: unitId => createAction(LOAD_UNIT.REQUEST, { unitId }),
  success: unit => createAction(LOAD_UNIT.SUCCESS, { unit }),
  failure: error => createAction(LOAD_UNIT.FAILURE, { error })
};

const createUnit = {
  request: (code, name) => createAction(CREATE_UNIT.REQUEST, { code, name }),
  success: unit => createAction(CREATE_UNIT.SUCCESS, { unit }),
  failure: error => createAction(CREATE_UNIT.FAILURE, { error })
};

const editUnit = {
  request: (id, code, name) =>
    createAction(EDIT_UNIT.REQUEST, { id, code, name }),
  success: unit => createAction(EDIT_UNIT.SUCCESS, { unit }),
  failure: error => createAction(EDIT_UNIT.FAILURE, { error })
};

const deleteUnit = {
  request: id => createAction(DELETE_UNIT.REQUEST, { id }),
  success: unit => createAction(DELETE_UNIT.SUCCESS, { unit }),
  failure: error => createAction(DELETE_UNIT.FAILURE, { error })
};

const upload = {
  request: (file, pagination, limit, keyword) =>
    createAction(UPLOAD_FILE.REQUEST, {
      file,
      pagination,
      limit,
      keyword
    }),
  success: data => createAction(UPLOAD_FILE.SUCCESS, { data }),
  failure: error => createAction(UPLOAD_FILE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: error => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_UNITS,
  loadUnits,
  LOAD_UNIT,
  loadUnit,
  CREATE_UNIT,
  createUnit,
  EDIT_UNIT,
  editUnit,
  DELETE_UNIT,
  deleteUnit,
  upload,
  UPLOAD_FILE,
  download,
  DOWNLOAD_FILE,
  DESTROY_MANY,
  destroyMany
};
