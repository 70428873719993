import {
  LOAD_SERVICE_PACKAGE_GROUPS,
  DELETE_SERVICE_PACKAGE_GROUP,
  UPLOAD_FILE,
  DOWNLOAD_FILE,
  LOAD_SERVICE_PACKAGE_GROUP,
  CREATE_SERVICE_PACKAGE_GROUP,
  EDIT_SERVICE_PACKAGE_GROUP,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  servicePackageGroup: {
    service_package_group_code: "",
    service_package_group: ""
  },
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SERVICE_PACKAGE_GROUPS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_SERVICE_PACKAGE_GROUPS.SUCCESS:
      return {
        ...state,
        data: action.payload.servicePackageGroups.data,
        totalSize: action.payload.servicePackageGroups.total_row,
        alertMessage: "",
        errorMessage: "",
        servicePackageGroup: initialState.servicePackageGroup,

        errorImport: "",
        loading: false
      };
    case LOAD_SERVICE_PACKAGE_GROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_SERVICE_PACKAGE_GROUP.SUCCESS:
      return {
        ...state,
        servicePackageGroup: action.payload.servicePackageGroup.data,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case LOAD_SERVICE_PACKAGE_GROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_SERVICE_PACKAGE_GROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case CREATE_SERVICE_PACKAGE_GROUP.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.servicePackageGroup.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_SERVICE_PACKAGE_GROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_SERVICE_PACKAGE_GROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_SERVICE_PACKAGE_GROUP.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.servicePackageGroup.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_SERVICE_PACKAGE_GROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case DELETE_SERVICE_PACKAGE_GROUP.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_SERVICE_PACKAGE_GROUP.SUCCESS:
      const newServicePackageGroups = state.data.filter(
        servicePackageGroup =>
          servicePackageGroup.service_package_group_id !==
          action.payload.servicePackageGroup.data.service_package_group_id
      );
      return {
        ...state,
        data: newServicePackageGroups,
        alertMessage: action.payload.servicePackageGroup.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_SERVICE_PACKAGE_GROUP.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case UPLOAD_FILE.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        totalSize: action.payload.data.total_row,
        alertMessage: action.payload.data.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",

        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.service_package_group_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };
    default:
      return state;
  }
};
