import React, { Component, Fragment } from "react";
import { Table, Row, Input, Form } from "antd";
import { numberFormat } from "lib/helper";

const FormItem = Form.Item;

export default class PriceList extends Component {
  state = {
    items: [
      {
        key: 1,
        bin_id: 2,
        name: "Salon",
        price_before_vat: "",
        price: "",
      },
      {
        key: 0,
        bin_id: 1,
        name: "Retail",
        price_before_vat: "",
        price: "",
      },
    ],
  };

  componentDidUpdate(prevProps, prevState) {
    const { retail, salon } = this.props;
    const { items } = prevState;

    let retail_price_before_vat = items[1].price_before_vat,
      salon_price_before_vat = items[0].price_before_vat,
      retail_price = items[1].price,
      salon_price = items[0].price,
      isUpdate = false;

    if (retail.price_before_vat !== prevProps.retail.price_before_vat) {
      retail_price_before_vat = retail.price_before_vat;
      isUpdate = true;
    }

    if (salon.price_before_vat !== prevProps.salon.price_before_vat) {
      salon_price_before_vat = salon.price_before_vat;
      isUpdate = true;
    }

    if (retail.price !== prevProps.retail.price) {
      retail_price = retail.price;
      isUpdate = true;
    }

    if (salon.price !== prevProps.salon.price) {
      salon_price = salon.price;
      isUpdate = true;
    }

    if (isUpdate) {
      this.setState((prevState) => {
        prevState.items[1].price_before_vat = retail_price_before_vat;
        prevState.items[1].price = retail_price;
        prevState.items[0].price_before_vat = salon_price_before_vat;
        prevState.items[0].price = salon_price;
        return {
          prevState,
        };
      });

      this.props.setFieldValue(`retail_price_before_vat`, retail_price_before_vat);
      this.props.setFieldValue(`retail_price`, retail_price);
      this.props.setFieldValue(`salon_price_before_vat`, salon_price_before_vat);
      this.props.setFieldValue(`salon_price`, salon_price);
    }
  }

  render() {
    const { salon, retail } = this.props;
    const err_salon = salon.errors,
      err_retail = retail.errors;

    const columns = [
      {
        title: "Type",
        width: window.innerWidth <= 1025 ? 80 : 200,
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Price before VAT",
        dataIndex: "price_before_vat",
        render: (text, r) => {
          return (
            <FormItem
              style={{ margin: "0px" }}
              validateStatus={
                (r.bin_id === 1
                  ? err_retail.price_before_vat
                  : err_salon.price_before_vat) && "error"
              }
              help={
                r.bin_id === 1
                  ? err_retail.price_before_vat
                  : err_salon.price_before_vat
              }
            >
              <Input
                name={
                  r.bin_id === 1
                    ? `retail_price_before_vat`
                    : `salon_price_before_vat`
                }
                data-id={r.bin_id}
                placeholder="0.00"
                value={r.price_before_vat}
                onChange={this.props.handleChange}
                onBlur={(e) => {
                  let val = e.target.value !== '' ? numberFormat(e.target.value) : ''
                  this.props.setFieldValue(`${e.target.name}`, val);
                  this.props.handleBlur(e)
                }}
              />
            </FormItem>
          );
        },
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (text, r) => {
          return (
            <FormItem
              style={{ margin: "0px" }}
              validateStatus={
                (r.bin_id === 1
                  ? err_retail.price
                  : err_salon.price) && "error"
              }
              help={
                r.bin_id === 1
                  ? err_retail.price
                  : err_salon.price
              }
            >
              <Input
                name={r.bin_id === 1 ? `retail_price` : `salon_price`}
                data-id={r.bin_id}
                placeholder="0.00"
                value={r.price}
                onChange={this.props.handleChange}
                onBlur={(e) => {
                  let val = e.target.value !== '' ? numberFormat(e.target.value) : ''
                  this.props.setFieldValue(`${e.target.name}`, val);
                  this.props.handleBlur(e)
                }}
              />
            </FormItem>
          );
        },
      },
    ];
    return (
      <Fragment>
        <Row gutter={[16, 16]}>
          <Table
            size="small"
            bordered
            columns={columns}
            dataSource={this.state.items}
            pagination={false}
          />
        </Row>
      </Fragment>
    );
  }
}
