import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  uniqueArray,
  extractKeyFromArray,
  numberFormat,
} from "lib/helper";
import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  FilterBtn,
  TagFilter,
  ImportExportBtn,
  AddBtn,
  UploadModal,
  PageTitle,
} from "lib/widget";

import {
  Table,
  Row,
  Col,
  message,
  Spin,
  notification,
  Checkbox,
  Modal,
  Badge,
  Typography,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Filter from "./Filter";

const { confirm } = Modal;
const { Text } = Typography;

class ServicePackageList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      alertMessage,
      errorMessage,
      errorImport,
    } = nextProps.servicePackages;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (errorImport !== "") {
      return { errorImport: errorImport };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
    adjustPrice: false,
    cond: "",
  };

  fetchData = () => {
    const { loadServicePackages } = this.props;
    const { page, limit, cond, sorter } = this.state;
    setSorter(sorter)
    setSearch(cond)
    loadServicePackages(page, limit, cond, sorter);
  }

  handleSearch = (val, filter) => {
    this.setState({ page: 1, visible: false, keyword: val.keyword, cond: val }, this.fetchData);
    this.getFilterTag(filter, val.is_show, val.keyword);
  };

  getFilterTag = (filter, isShow, keyword) => {
    let group = "",
      is_show = "",
      word = "";

    if (parseInt(isShow) === 0) is_show = "Not display on iPad";
    if (parseInt(isShow) === 1) is_show = "Display on iPad";
    if (keyword !== "") word = `${keyword}`;

    Object.keys(filter).map((key) => {
      if (typeof filter[key] !== 'object') return true
      filter[key].map((val) => {
        if (key === "group")
          group += (group !== "" ? ", " : "") + val.service_package_group;
      });
    });

    this.setState({
      groupTag: group !== "" ? `${group}` : "",
      isShowTag: is_show,
      keywordTag: word,
    });

    setFilterTag({
      groupTag: group !== "" ? `${group}` : "",
      isShowTag: is_show,
      keywordTag: word,
    })
  };

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState({ page: current, limit: pageSize, sorter: { order, field } }, this.fetchData);
  };


  componentDidMount() {
    let filter = getFilterTag()

    if (typeof filter === 'object') {
      this.setState({ ...filter, cond: getSearch(), sorter: getSorter() }, this.fetchData)
      return
    }
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      alertMessage,
      errorMessage,
      errorImport,
    } = this.props.servicePackages;
    const prevMsg = prevProps.servicePackages;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (errorImport !== "" && prevMsg.errorImport !== errorImport) {
      this.openNotification(errorImport);
    }
  }

  openNotification = (message) => {
    notification.error({
      message: "Import fail",
      description: message,
      duration: 0,
    });
  };

  showModalImport = () => {
    this.setState({
      visibleImport: true,
    });
  };

  onCheckboxUploadChange = (e) => {
    if (e.target.checked) {
      this.setState({ adjustPrice: true });
    } else {
      this.setState({ adjustPrice: false });
    }
  };

  showConfirm = () => {
    let that = this;
    confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Do you Want to update these master data (including price) ?",
      onOk() {
        that.uploadData();
      },
      onCancel() {
        that.setState({ adjustPrice: false });
      },
    });
  };

  handleUpload = () => {
    if (this.state.adjustPrice) {
      this.showConfirm();
    } else {
      this.uploadData();
    }
  };

  uploadData = () => {
    const { pagination, limit, keyword, file, adjustPrice } = this.state;
    this.props.importServicePackage(
      file,
      "servicepackage",
      pagination,
      limit,
      keyword,
      adjustPrice
    );
    this.setState({ file: null, fileList: [] });
  };

  handleCancel = () => {
    this.setState({ visibleImport: false });
  };

  onChooseFile = ({ file }) => {
    if (file.status !== "removed") {
      this.setState({ file, fileList: [file] });
    }
  };

  onRemove = () => this.setState({ fileList: [], file: null });

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.servicePackages.data.filter((x) =>
        selectedRowKeys.includes(x.service_package_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.service_package_id)
      );
      items = uniqueArray(
        [...removeState, ...removeData],
        "service_package_id"
      );
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "service_package_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter(
      (x) => x.service_package_id !== id
    );
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { servicePackages } = this.props;
    const { selectedRowKeys, groupTag, isShowTag, keywordTag, sorter } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const expandedRowRender = (id) => {
      const services = servicePackages.services;
      const actionCol = [
        {
          title: "",
          key: "action",
          className: "column-action",
          width: window.innerWidth <= 500 ? 40 : 105,
          fixed: "left",
          render: (services) => (
            <ListAction
              editUrl={`/service-package/packages/detail/edit/${services.service_package_has_service_id}`}
              onDelete={false}
              id={services.service_package_has_service_id}
              name="service_package_service_packages"
            />
          ),
        },
      ]

      const numberCol = [
        {
          title: "#",
          key: "index",
          className: "align-center",
          width: 50,
          render: (text, record, index) =>
            this.state.limit * (this.state.page - 1) + index + 1,
        },
      ];

      const mainColumns = [
        {
          title: "Description",
          dataIndex: "description",
        },
        {
          title: "Actual service",
          dataIndex: "service_name",
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
        }
      ];

      const columns =
        window.innerWidth <= 1025
          ? [...actionCol, ...mainColumns]
          : [...numberCol, ...mainColumns, ...actionCol];

      const serviceData = services.filter(
        (service) => service.service_package_id === id
      );

      return (
        <Table
          rowKey="service_package_id"
          bordered={true}
          size="small"
          columns={columns}
          dataSource={serviceData}
          pagination={false}
          scroll={window.innerWidth <= 1025 ? { x: 1000 } : undefined}
        />
      );
    };

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const actionCol = [
      {
        title: "",
        key: "service_package_id",
        className: "column-action",
        width: window.innerWidth <= 1025 ? 40 : 105,
        fixed: "left",
        render: (servicePackages) => (
          <ListAction
            editUrl={`/service-package/packages/edit/${servicePackages.service_package_id}`}
            onDelete={this.props.deleteServicePackage}
            id={servicePackages.service_package_id}
            name="service_package_service_packages"
          />
        ),
      },
    ];

    const mainColumns = [
      {
        title: "Barcode",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'barcode' ? sorter.order : false,
        dataIndex: "barcode",
        width: 150,
        render: (text, record) => {
          if (record.is_show === 1) {
            return <Badge status="success" text={text} />;
          } else {
            return <Badge status="default" text={text} />;
          }
        },
      },
      {
        title: "Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'service_package_name' ? sorter.order : false,
        width: 250,
        dataIndex: "service_package_name",
      },
      {
        title: "Group",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'service_package_group' ? sorter.order : false,
        dataIndex: "service_package_group",
      },
      {
        title: "Actual Service",
        sorter: false,
        sortOrder: sorter !== null && sorter.field === 'service_package_id' ? sorter.order : false,
        dataIndex: "service_package_id",
        render: (text, record) => {
          let data = servicePackages.services.filter(
            (service) => service.service_package_id === text
          );

          return typeof data !== "undefined" && typeof data[0] !== "undefined"
            ? data[0].service_name
            : "";
        },
      },
      {
        title: "Price",
        width: 120,
        dataIndex: "price",
        className: "align-right",
        render: (text) => numberFormat(text),
      },
    ];

    const columns =
      window.innerWidth <= 1025
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    return (
      <div>
        <Spin size="large" spinning={this.props.servicePackages.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              name="service_package_service_packages"
              component={
                <AddBtn
                  url="/service-package/packages/new"
                  name="service_package_service_packages"
                />
              }
            />
            <Row gutter={[16, 16]}>
              <DeleteBadge
                count={this.state.removeData.length}
                onClick={this.showDrawer("visibleDelete")}
              />
              <Col xs={10} sm={10} md={8} lg={6} className="import-btn-align">
                <ImportExportBtn
                  showModalImport={this.showModalImport}
                  download={this.props.download}
                  name="service_package_service_packages"
                />
              </Col>
              <Col
                xs={3}
                sm={3}
                md={{ span: 7, offset: 3 }}
                lg={{ span: 8, offset: 6 }}
              ></Col>
              <FilterBtn showDrawer={this.showDrawer} />
              <Filter
                list={servicePackages.data}
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col span={20}>
                {TagFilter([keywordTag], "Keyword", "green")}
                {TagFilter([isShowTag], "Status", "cyan")}
                {TagFilter([groupTag], "Group", "orange")}
              </Col>
            </Row>

            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={[
                "service_package_id",
                "barcode",
                "service_package_name",
              ]}
            />
          </div>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={12}>
              <Badge
                className="remark-text-badge"
                status="success"
                text="Display on iPad"
              />{" "}
              &nbsp;&nbsp;
              <Badge
                className="remark-text-badge"
                status="default"
                text="Not display on iPad"
              />
            </Col>
          </Row>
          <Table
            rowKey="service_package_id"
            className="components-table-demo-nested"
            bordered={true}
            size="small"
            columns={columns}
            dataSource={servicePackages.data}
            rowSelection={rowSelection}
            expandedRowRender={(record) =>
              expandedRowRender(record.service_package_id)
            }
            pagination={{
              pageSize: this.state.limit,
              total: servicePackages.totalSize,
              current: this.state.page,
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 1025 ? { x: 1000 } : undefined}
          />
          <UploadModal
            visibleImport={this.state.visibleImport}
            handleCancel={this.handleCancel}
            loading={this.props.servicePackages.loading}
            onChooseFile={this.onChooseFile}
            fileList={this.state.fileList}
            onRemove={this.onRemove}
            handleUpload={this.handleUpload}
            uploading={this.state.uploading}
            disabled={this.state.file ? false : true}
            spacialComp={
              <Checkbox
                onChange={this.onCheckboxUploadChange}
                checked={this.state.adjustPrice === true}
              >
                Including price
              </Checkbox>
            }
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ servicePackages }) => ({
  servicePackages,
});

const mapDispatchToProps = {
  loadServicePackages: actions.loadServicePackages.request,
  deleteServicePackage: actions.deleteServicePackage.request,
  deleteServicePackageDetail: actions.deleteServicePackageDetail.request,
  importServicePackage: actions.importServicePackage.request,
  download: actions.download.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePackageList);
