import React, { Fragment } from "react";
import { Row, Col, Typography } from "antd";
import { numberFormat } from "lib/helper";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import styled from "styled-components";

const { Text } = Typography;

const DecreaseSmall = styled.span`
  color: red;
  padding: inherit;
  font-size: 9px;
`;

const IncreaseSmall = styled.span`
  color: green;
  padding: inherit;
  font-size: 9px;
`;

const formatData = (revenue, compared) => {
  const main = revenue.length >= compared.length ? revenue : compared;
  let newData = [];
  let sumRev = 0;
  let sumComp = 0;

  Object.keys(main).map((key) => {
    let rev = typeof revenue[key] !== "undefined" ? revenue[key]["amount"] : 0;
    let comp =
      typeof compared[key] !== "undefined" ? compared[key]["amount"] : 0;
    sumRev += rev;
    sumComp += comp;
    return newData.push({
      label: main[key]["label"],
      revenue: rev,
      compared: comp,
    });
  });

  return { data: newData, sumRev, sumComp };
};

function BranchRevenueList({
  revenue,
  compared,
  percentCal,
  comparedLabel
}) {
  const sumaryList = formatData(revenue, compared);
  let percent = percentCal(sumaryList.sumComp, sumaryList.sumRev)
  return (
    <Fragment>
      <Row gutter={[8, 16]}>
        <Col xs={8} md={{ span: 8, offset: 4 }} className="align-right">
          Last {comparedLabel}.
        </Col>
        <Col xs={10} md={7} className="align-right">
          This {comparedLabel}.
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xs={6} md={6}>
          <Text strong>TOTAL</Text>
        </Col>
        <Col xs={8} md={6} className="align-right">
          <Text strong>{numberFormat(sumaryList.sumComp)}</Text>
        </Col>
        <Col xs={10} md={7} className="align-right">
          <Text strong>{numberFormat(sumaryList.sumRev)}</Text>
        </Col>
        <Col xs={24} md={4} className="align-right">
          {percent < 0 ? (
            <DecreaseSmall>
              {numberFormat(percent * -1)} %
              <ArrowDownOutlined />
            </DecreaseSmall>
          ) : (
            <IncreaseSmall>
              {numberFormat(percent)} %
              <ArrowUpOutlined />
            </IncreaseSmall>
          )}
        </Col>
      </Row>

      {sumaryList.data.map((data, i) => {
        let percent = percentCal(data.compared, data.revenue);
        return (
          <Row key={i} gutter={[8, 8]}>
            <Col xs={6} md={6}>
              {data.label}
            </Col>
            <Col xs={8} md={6} className="align-right">
              {numberFormat(data.compared)}
            </Col>
            <Col xs={10} md={7} className="align-right">
              {numberFormat(data.revenue)}
            </Col>
            <Col xs={24} md={4} className="align-right">
              {percent < 0 ? (
                <DecreaseSmall>
                  {numberFormat(percent * -1)} %
                  <ArrowDownOutlined />
                </DecreaseSmall>
              ) : (
                <IncreaseSmall>
                  {numberFormat(percent)} %
                  <ArrowUpOutlined />
                </IncreaseSmall>
              )}
            </Col>
          </Row>
        );
      })}
    </Fragment>
  );
}

export default BranchRevenueList;
