import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import {
  uniqueArray,
  extractKeyFromArray,
  getSearch,
  setSearch,
  getSorter,
  setSorter,
} from "lib/helper";

import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  AddBtn,
  PageTitle,
} from "lib/widget";

import { Table, Input, Row, Col, message, Spin } from "antd";

const Search = Input.Search;

class BranchList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = nextProps.branches;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (errorImport !== "") {
      return { errorImport: errorImport };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    keyword: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
  };

  fetchData = () => {
    const { page, limit, keyword, sorter } = this.state
    setSearch(keyword)
    setSorter(sorter)
    this.props.loadBranches(page, limit, keyword, sorter);
  }

  onSearch(value) {
    this.setState({ keyword: value, page: 1 }, this.fetchData);
  }

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState({ page: current, limit: pageSize, sorter: { order, field } }, this.fetchData);
  };

  componentDidMount() {
    this.setState({ keyword: getSearch(), sorter: getSorter() }, this.fetchData)
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = this.props.branches;
    const prevMsg = prevProps.branches;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (errorImport !== "" && prevMsg.errorImport !== errorImport) {
      this.openNotification(errorImport);
    }
  }

  deleteBranch = async (id) => {
    this.props.deleteBranch(id);
  };

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.branches.data.filter((x) =>
        selectedRowKeys.includes(x.branch_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.branch_id)
      );
      items = uniqueArray([...removeState, ...removeData], "branch_id");
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "branch_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter((x) => x.branch_id !== id);
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { branches } = this.props;
    const { selectedRowKeys, sorter } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const actionCol = [
      {
        title: "",
        key: "branch_id",
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: "left",
        render: (branches) => (
          <ListAction
            editUrl={`/branch/branches/edit/${branches.branch_id}`}
            onDelete={this.props.deleteBranch}
            id={branches.branch_id}
            name="branch_manage_branches"
            breakpoint={500}
          />
        ),
      },
    ];

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const mainColumns = [
      {
        title: "Code",
        width: 100,
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'branch_code' ? sorter.order : false,
        dataIndex: "branch_code",
      },
      {
        title: "Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'branch_name' ? sorter.order : false,
        dataIndex: "branch_name",
      },
      {
        title: "Printer",
        dataIndex: "printer_type",
      },
      {
        title: "Printer IP Address",
        dataIndex: "printer_ip_address",
      },
    ];

    const columns =
      window.innerWidth <= 500
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    return (
      <div>
        <Spin size="large" spinning={this.props.branches.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              name="branch_manage_branches"
              component={
                <AddBtn
                  url="/branch/branches/new"
                  name="branch_manage_branches"
                />
              }
            />
            <Row gutter={[16, 16]} justify="space-between">
              <DeleteBadge
                count={this.state.removeData.length}
                onClick={this.showDrawer("visibleDelete")}
              />
              <Col xs={20} sm={20} md={8} lg={8}>
                <Search
                  defaultValue={getSearch()}
                  placeholder="Search for code or name"
                  onSearch={(value) => this.onSearch(value)}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["branch_id", "branch_code", "branch_name"]}
            />
          </div>
          <Table
            rowKey="branch_id"
            bordered={true}
            size="small"
            columns={columns}
            rowSelection={rowSelection}
            dataSource={branches.data}
            pagination={{
              pageSize: this.state.limit,
              total: this.props.branches.totalSize,
              current: this.state.page,
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 500 ? { x: 500 } : undefined}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ branches }) => ({
  branches,
});

const mapDispatchToProps = {
  loadBranches: actions.loadBranches.request,
  deleteBranch: actions.deleteBranch.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchList);
