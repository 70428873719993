import {
  LOAD_USERS,
  LOAD_USER,
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  EDIT_PASSWORD,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  user: {
    email: "",
    name: "",
    password: "",
    role_id: "",
    is_manager: false,
    employee_id: ""
  },
  branch_users: [],
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };

    case LOAD_USERS.SUCCESS:
      return {
        ...state,
        data: action.payload.users.data,
        user: {
          email: "",
          name: "",
          password: "",
          role_id: "",
          is_manager: false,
          employee_id: ""
        },
        totalSize: action.payload.users.total_row,
        alertMessage: "",
        errorMessage: "",

        loading: false
      };
    case LOAD_USER.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };

    case LOAD_USER.SUCCESS:
      return {
        ...state,
        user: action.payload.user.data,
        branch_users: action.payload.user.branch_users,
        alertMessage: "",
        errorMessage: "",

        loading: false
      };
    case LOAD_USER.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        loading: false
      };
    case CREATE_USER.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };

    case CREATE_USER.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.user.message,
        errorMessage: "",

        loading: false
      };
    case CREATE_USER.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        loading: false
      };
    case EDIT_USER.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };

    case EDIT_USER.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.user.message,
        errorMessage: "",

        loading: false
      };
    case EDIT_USER.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        loading: false
      };
    case DELETE_USER.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };

    case DELETE_USER.SUCCESS:
      const newUsers = state.data.filter(
        users => users.user_id !== action.payload.user.data.user_id
      );
      return {
        ...state,
        data: newUsers,
        alertMessage: action.payload.user.message,
        totalSize: state.totalSize - 1,

        loading: false
      };
    case DELETE_USER.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };
    case EDIT_PASSWORD.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        loading: true
      };

    case EDIT_PASSWORD.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.user.message,
        errorMessage: "",

        loading: false
      };
    case EDIT_PASSWORD.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.user_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
