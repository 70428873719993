import React, { Component } from "react";
import { connect } from "react-redux";

import { Col, Row, Radio, Input, Checkbox } from "antd";
import { loadActualServiceTypes } from "lib/select-option";
import { DrawerFilter, AutoComplete, LabelFilter } from "lib/widget";
import {
  getSearch,
} from "lib/helper";

const Search = Input.Search;

const options = [
  { label: "All", value: "all" },
  { label: "Yes", value: "1" },
  { label: "No", value: "0" }
];

const initialState = {
  actual_service_type_id: [],
  is_show: "all",
  hiddenType: "",
  keyword: '',
  actual_service_id: ""
};

class Filter extends Component {
  state = initialState;

  handleChange = (id, name) => e => {
    if (!e.target.checked) {
      this.setState(prevState => {
        return {
          [name]: prevState[name].filter(x => parseInt(x) !== parseInt(id))
        };
      });
      return;
    }

    this.setState({ [name]: [...this.state[name], id] });
  };

  handleIsshowChange = e => {
    this.setState({ is_show: e.target.value });
  };

  handleShow = name => () => {
    let value = this.state[name] === "none" ? "" : "none";
    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const { actual_service_id, is_show, actual_service_type_id, keyword } = this.state;

    let selected = {
      type: this.props.type.filter(x =>
        actual_service_type_id.includes(x.actual_service_type_id)
      )
    };

    let params = {
      is_show,
      keyword,
      actual_service_id,
      actual_service_type_id,
    }

    this.props.handleSearch(params, selected);
  };

  handleInputChange = e => {
    console.log('handleInputChange', e.target.value)
    this.setState({ actual_service_id: "", [e.target.name]: e.target.value });
  };

  handleAutoSelect = (id, data) => {
    console.log('handleAutoSelect', data)
    this.setState({ actual_service_id: id, keyword: data  })
  }

  searchAuto = (searchText) => {
    console.log('searchAuto', searchText)
    this.setState({ actual_service_id: "", keyword: searchText  })
  }

  handleClearFilter = () => {
    this.setState(initialState);
  };

  componentDidMount() {
    let search = getSearch()

    if (typeof search === 'object') {
      this.setState(search)
    }
    this.props.loadActualServiceTypes();
  }
  render() {
    const { type, visible, handleDrawerClose, list } = this.props;
    return (
      <DrawerFilter
        onSearch={this.handleSearch}
        visible={visible}
        onClear={this.handleClearFilter}
        onClose={handleDrawerClose}
      >
        <div className="section-filter">
          <LabelFilter text="Barcode or name" />
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <AutoComplete
                keyName={['barcode', 'service_name']}
                keyId="actual_service_id"
                searchCompnent={
                  <Search
                    value={this.state.keyword}
                    name="keyword"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                  />
                }
                type="actualservice"
                value={this.state.keyword}
                onSelect={this.handleAutoSelect}
                onSearch={this.searchAuto}
                options={list}
              /> 
            </Col>
          </Row>
        </div>
        <div className="section-filter">
          <LabelFilter text="Display on iPad" />
          <Row>
            <Col span={24}>
              <Radio.Group
                options={options}
                onChange={this.handleIsshowChange}
                value={this.state.is_show}
              />
            </Col>
          </Row>
        </div>
        <div className="section-filter">
          <LabelFilter text="Actual service type" />
          <div className="checkbox-group-section-long">
          {typeof type !== "undefined" &&
            type.map((val, i) => {
              return (
                <Row
                  key={i}
                  gutter={[0, 8]}
                  style={{ display: i > 6 ? this.state.hiddenType : "" }}
                >
                  <Col span={24}>
                    <Checkbox
                      checked={this.state.actual_service_type_id.includes(val.actual_service_type_id)}
                      onClick={this.handleChange(val.actual_service_type_id, "actual_service_type_id")}
                    >
                      {val.actual_service_type}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
            </div>
        </div>
      </DrawerFilter>
    );
  }
}

const mapStateToProps = ({ actualServiceTypes }) => ({
  type: actualServiceTypes.data,
});

const mapDispatchToProps = {
  loadActualServiceTypes: loadActualServiceTypes.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
