import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  uniqueArray,
  extractKeyFromArray,
} from "lib/helper";
import { Table, Row, Col, message, Spin, Typography } from "antd";

import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  FilterBtn,
  TagFilter,
  AddBtn,
  PageTitle,
  ArrangementBtn,
} from "lib/widget";
import OrderPerm from "./OrderPerm";
import Filter from "./Filter";

const { Text } = Typography;

class PermissionList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage, errorMessage } = nextProps;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }
    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
    view: "table",
  };

  fetchData = () => {
    const { page, limit, cond, sorter } = this.state;
    setSorter(sorter);
    setSearch(cond);
    this.props.loadPermissions(page, limit, cond, sorter);
  };

  handleSearch = (val, filter) => {
    this.setState(
      { page: 1, visible: false, keyword: val.keyword, cond: val },
      this.fetchData
    );
    this.getFilterTag(filter, val.keyword);
  };

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState(
      { page: current, limit: pageSize, sorter: { order, field } },
      this.fetchData
    );
  };

  getFilterTag = (filter, keyword) => {
    let group = "",
      word = "";
    if (keyword !== "") word = `${keyword}`;

    Object.keys(filter).map((key) => {
      filter[key].map((val) => {
        if (key === "group")
          group += (group !== "" ? ", " : "") + val.display_name;
      });
    });

    this.setState({
      groupTag: group !== "" ? `${group}` : "",
      keywordTag: word,
    });

    setFilterTag({
      groupTag: group !== "" ? `${group}` : "",
      keywordTag: word,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage } = this.props;
    if (alertMessage !== "" && prevProps.alertMessage !== alertMessage) message.success(alertMessage);
    if (errorMessage !== "" && prevProps.errorMessage !== errorMessage) message.error(errorMessage);
  }

  componentDidMount() {
    let filter = getFilterTag()

    if (typeof filter === 'object') {
      this.setState({ ...filter, cond: getSearch(), sorter: getSorter() }, this.fetchData)
      return 
    } 
    this.fetchData()
  }

  deletePermission(id) {
    this.props.deletePermission(id);
  }

  handleChangeView = (name) => {
    this.setState({ view: name });
  };

  displayAddBtn = (display) => {
    return display === "" ? (
      <AddBtn url="/auth/permissions/new" name="authorization_permissions" />
    ) : (
      ""
    );
  };

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.permissions.data.filter((x) =>
        selectedRowKeys.includes(x.permission_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.permission_id)
      );
      items = uniqueArray([...removeState, ...removeData], "permission_id");
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "permission_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter(
      (x) => x.permission_id !== id
    );
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { permissions } = this.props;
    const { selectedRowKeys, groupTag, keywordTag, sorter } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const actionCol = [
      {
        title: "",
        key: "permission_id",
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: "left",
        render: (permissions) => (
          <ListAction
            editUrl={`/auth/permissions/edit/${permissions.permission_id}`}
            onDelete={this.props.deletePermission}
            id={permissions.permission_id}
            name="authorization_permissions"
            breakpoint={500}
          />
        ),
      },
    ];

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const mainColumns = [
      {
        title: "Display Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'display_name' ? sorter.order : false,
        dataIndex: "display_name",
      },
      {
        title: "Permission Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'permission_name' ? sorter.order : false,
        dataIndex: "permission_name",
      },
      {
        title: "Group",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'permission_group' ? sorter.order : false,
        dataIndex: "permission_group",
      },
    ];

    const columns =
      window.innerWidth <= 500
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    const display = this.state.view !== "table" ? "none" : "";
    return (
      <div>
        <Spin size="large" spinning={this.props.permissions.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              title="Permissions"
              name="authorization_permissions"
              component={this.displayAddBtn(display)}
            />
            <Row gutter={[16, 16]}>
              <DeleteBadge
                count={this.state.removeData.length}
                onClick={this.showDrawer("visibleDelete")}
              />
              <Col xs={5} sm={5} md={8} lg={6}>
                <ArrangementBtn
                  name="authorization_permissions"
                  onClick={this.handleChangeView}
                  display={display}
                />
              </Col>
              <Col
                xs={{ span: 3, offset: 4 }}
                sm={{ span: 4, offset: 3 }}
                md={{ span: 7, offset: 3 }}
                lg={{ span: 8, offset: 6 }}
              ></Col>
              <FilterBtn showDrawer={this.showDrawer} />
              <Filter
                list={permissions.data}
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col xs={19} sm={19} md={21} lg={22}>
                {TagFilter([keywordTag], "Keyword", "green")}
                {TagFilter([groupTag], "Group", "cyan")}
              </Col>
            </Row>
            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["permission_id", "display_name", "permission_group"]}
            />
          </div>
          {this.state.view === "table" ? (
            <Table
              rowKey="permission_id"
              columns={columns}
              rowSelection={rowSelection}
              bordered={true}
              size="small"
              dataSource={permissions.data}
              pagination={{
                pageSize: this.state.limit,
                total: permissions.totalSize,
                current: this.state.page,
              }}
              onChange={this.onChangePage}
              scroll={window.innerWidth <= 500 ? { x: 500 } : undefined}
            />
          ) : (
            <OrderPerm
              dataSource={permissions.data}
              onUpdate={this.props.updateOrder}
              onCancel={this.handleChangeView}
            />
          )}
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = ({ permissions }) => ({
  permissions,
  errorMessage: permissions.errorMessage,
  alertMessage: permissions.alertMessage,
});

const mapDispatchToProps = {
  loadPermissions: actions.loadPermissions.request,
  deletePermission: actions.deletePermission.request,
  updateOrder: actions.updateOrder.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionList);
