import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, logErrorMsg, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadScheduleReports(action) {

  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post('schedule_reports/list', { pagination, limit, keyword, sort })
    yield put(actions.loadScheduleReports.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadScheduleReports.failure(getErrorMsg(error)));
    logErrorMsg(error)
  }
}

function* loadScheduleReport(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.get(`schedule_reports/${id}`)
    yield put(actions.loadScheduleReport.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadScheduleReport.failure(getErrorMsg(error)));
    logErrorMsg(error)
  }
}

function* createScheduleReport(action) {
  try {
    const { name, report, time, subject, to, cc, parameter } = action.payload;
    const res = yield fetcher.post('schedule_reports', { name, report, time, subject, to, cc, parameter })
    yield put(actions.createScheduleReport.success(res.data));
    yield put(push("/schedule-report"));
  } catch (error) {
    handleError(error)
    yield put(actions.createScheduleReport.failure(getErrorMsg(error)));
    logErrorMsg(error)
  }
}

function* editScheduleReport(action) {
  try {
    const {
      id,
      name,
      report,
      time,
      subject,
      to,
      cc,
      parameter
    } = action.payload;
    const res = yield fetcher.patch(`schedule_reports/${id}`, 
    { name, report, time, subject, to, cc, parameter }
    )
    yield put(actions.editScheduleReport.success(res.data));
    yield put(push("/schedule-report"));
  } catch (error) {
    handleError(error)
    yield put(actions.editScheduleReport.failure(getErrorMsg(error)));
    logErrorMsg(error)
  }
}

function* deleteScheduleReport(action) {
  try {
    const { id } = action.payload;
    yield fetcher.delete(`schedule_reports/${id}`)
    yield put(actions.deleteScheduleReport.success(id));
  } catch (error) {
    handleError(error)
    yield put(actions.deleteScheduleReport.failure(getErrorMsg(error)));
    logErrorMsg(error)
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/schedule_report`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
    logErrorMsg(error)
  }
}

export default function* watchScheduleReports() {
  yield all([
    takeEvery(actions.LOAD_SCHEDULE_REPORTS.REQUEST, loadScheduleReports),
    takeEvery(actions.LOAD_SCHEDULE_REPORT.REQUEST, loadScheduleReport),
    takeEvery(actions.CREATE_SCHEDULE_REPORT.REQUEST, createScheduleReport),
    takeEvery(actions.EDIT_SCHEDULE_REPORT.REQUEST, editScheduleReport),
    takeEvery(actions.DELETE_SCHEDULE_REPORT.REQUEST, deleteScheduleReport),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
