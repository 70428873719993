import { all, put, takeEvery } from "redux-saga/effects";
import { fetchGo, handleError, logErrorMsg } from "lib/fetch";
import * as actions from "./actions";

function* loadStylistDataTable(action) {
  try {
    const { type, duration, branch_id } = action.payload;
    const params = { type, duration, branch_id };
    const res = yield fetchGo.post(`stylish_analytics`, params);
    yield put(actions.loadStylistDataTable.success(res.data));
  } catch (error) {
    logErrorMsg(error)
    handleError(error);
    yield put(actions.loadStylistDataTable.failure(error));
  }
}

function* loadStylistWidgetData(action) {
  try {
    const { type, duration, branch_id } = action.payload;
    const params = { type, duration, branch_id };
    const res = yield fetchGo.post(`stylish_analytics_summary`, params);
    yield put(actions.loadStylistWidgetData.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadStylistWidgetData.failure(error));
  }
}

function* loadStylistWidgetDetailData(action) {
  try {
    const { type, duration, branch_id, category_id, sale_id } = action.payload;
    const params = { type, duration, branch_id, category_id, sale_id };
    const res = yield fetchGo.post(`stylish_analytics_detail_summary`, params);
    yield put(actions.loadStylistWidgetDetailData.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadStylistWidgetDetailData.failure(error));
  }
}

function* loadStylistDataTableDetail(action) {
  try {
    const { type, duration, branch_id, category_id, sale_id } = action.payload;
    const params = { type, duration, branch_id, category_id, sale_id };
    const res = yield fetchGo.post(`stylish_analytics_detail`, params);
    yield put(actions.loadStylistDataTableDetail.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadStylistDataTableDetail.failure(error));
  }
}

export default function* watchStylistAnalytics() {
  yield all([
    takeEvery(
      actions.LOAD_STYLIST_DETAIL_DATATABLE.REQUEST,
      loadStylistDataTableDetail
    ),
    takeEvery(
      actions.LOAD_STYLIST_DETAIL_WIDGET.REQUEST,
      loadStylistWidgetDetailData
    ),
    takeEvery(actions.LOAD_STYLIST_WIDGET.REQUEST, loadStylistWidgetData),
    takeEvery(
      actions.LOAD_STYLIST_DATATABLE.REQUEST,
      loadStylistDataTable
    ),
  ]);
}
