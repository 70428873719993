import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import { getSearch, uniqueArray, extractKeyFromArray, setSearch } from "lib/helper";
import {
  ListAction, DeleteListModal,
  ImportExportBtn,
  AddBtn,
  UploadModal,
  PageTitle
} from "lib/widget";

import { Table, Input, Row, Col, message, Spin, notification } from "antd";

const Search = Input.Search;

class ServiceGroupList extends Component {
  state = {
    page: 1,
    limit: 20,
    removeData: [],
    visible: false,
    visibleDelete: false,
    visibleImport: false,
    file: null,
    fileList: [],
    uploading: false
  };

  componentDidMount() {
    const { page, limit } = this.state;
    const { loadActualServiceGroups } = this.props;
    loadActualServiceGroups(page, limit, getSearch());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = nextProps.actualServiceGroups
    if (alertMessage !== "") {
      return { alertMessage: alertMessage }
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage }
    }

    if (errorImport !== "") {
      return { errorImport: errorImport }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage, errorImport } = this.props.actualServiceGroups
    const prevMsg = prevProps.actualServiceGroups

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (errorImport !== "" && prevMsg.errorImport !== errorImport) {
      this.openNotification(errorImport);
    }
  }

  openNotification = message => {
    notification.error({
      message: "Import fail",
      description: message,
      duration: 0
    });
  };

  deleteActualServiceGroup(id) {
    this.props.deleteActualServiceGroup(id);
  }

  onSearch(value) {
    this.props.loadActualServiceGroups(1, this.state.limit, value);
    setSearch(value);
    this.setState({ keyword: value, page: 1 });
  }

  onChangePage({ pageSize, current }) {
    this.props.loadActualServiceGroups(current, pageSize, this.state.keyword);
    this.setState({ page: current, limit: pageSize });
  }

  showModalImport = () => {
    this.setState({
      visibleImport: true
    });
  };

  handleCancel = () => {
    this.setState({ visibleImport: false });
  };

  onChooseFile = ({ file }) => {
    if (file.status !== 'removed') {
      this.setState({ file, fileList: [file] });
    }
  };

  onRemove = () => {
    this.setState({ fileList: [], file: null });
  };

  handleUpload = () => {
    const { page, limit, keyword, file } = this.state;
    this.props.upload(file, page, limit, keyword);
    this.setState({ file: null, fileList: [] });
  };

  onSelectChange = selectedRowKeys => {
    let items = selectedRowKeys

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.actualServiceGroups.data.filter(x =>
        selectedRowKeys.includes(x.actual_service_group_id)
      );

      let removeState = this.state.removeData.filter(x =>
        selectedRowKeys.includes(x.actual_service_group_id)
      );
      items = uniqueArray([...removeState, ...removeData], "actual_service_group_id")
    }

    this.setState(
      { selectedRowKeys, removeData: items },
      this.showDrawer
    );
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true
    });
  };

  handleRemove = () => {
    this.props.destroyMany(extractKeyFromArray(this.state.removeData, "id"));
    this.setState({ selectedRowKeys: [], removeData: [], visibleDelete: false });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = id => {
    let item = this.state.selectedRowKeys.filter(x => id !== x);
    let removeData = this.state.removeData.filter(x => x.actual_service_group_id !== id);
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { actualServiceGroups } = this.props;
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const columns = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) => index + 1
      },
      {
        title: "Code",
        dataIndex: "actual_service_group_code",
      },
      {
        title: "Name",
        dataIndex: "actual_service_group",
      },
      {
        title: "",

        key: "actual_service_group_id",
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: 'left',
        render: actualServiceGroups => (
          <ListAction
            editUrl={`/actual-service/groups/edit/${actualServiceGroups.actual_service_group_id}`}
            onDelete={this.props.deleteActualServiceGroup}
            id={actualServiceGroups.actual_service_group_id}
            name="actual_service_groups"
          />
        )
      }
    ];

    return (
      <div>
        <Spin size="large" spinning={this.props.actualServiceGroups.loading}>
          <div className="list-layout">
            <PageTitle
              name="actual_service_groups"
              component={
                <AddBtn
                  url="/actual-service/groups/new"
                  name="actual_service_groups"
                />
              }
            />
            <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={24} md={8} lg={8}>
                <Search
                  defaultValue={getSearch()}
                  placeholder="input search text"
                  onSearch={value => this.onSearch(value)}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={10} sm={10} md={8} lg={6} className="import-btn-align">
                <ImportExportBtn
                  showModalImport={this.showModalImport}
                  download={this.props.download}
                  name="actual_service_groups"
                />
              </Col>
            </Row>

            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visible}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={['actual_service_group_id', 'actual_service_group_code', 'actual_service_group']}
            />
          </div>
          <Table
            rowKey="actual_service_group_id"
            rowSelection={rowSelection}
            bordered={true}
            size="small"
            columns={columns}
            dataSource={actualServiceGroups.data}
            pagination={{
              pageSize: this.state.limit,
              total: this.props.actualServiceGroups.totalSize,
              current: this.state.page
            }}
            onChange={value => this.onChangePage(value)}
          />

          <UploadModal
            visibleImport={this.state.visibleImport}
            handleCancel={this.handleCancel}
            loading={this.props.actualServiceGroups.loading}
            onChooseFile={this.onChooseFile}
            fileList={this.state.fileList}
            onRemove={this.onRemove}
            handleUpload={this.handleUpload}
            uploading={this.state.uploading}
            disabled={this.state.file ? false : true}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ actualServiceGroups }) => ({
  actualServiceGroups
});

const mapDispatchToProps = {
  loadActualServiceGroups: actions.loadActualServiceGroups.request,
  deleteActualServiceGroup: actions.deleteActualServiceGroup.request,
  download: actions.download.request,
  upload: actions.upload.request
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceGroupList);
