import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";

import CashPackageForm from "./CashPackageForm";

class NewCashPackage extends Component {
  createCashPackage = async (cashPackage, checkedList) => {
    this.props.createCashPackage(cashPackage, checkedList);
  };

  render() {
    const {
      cashPackage,
      alertMessage,
      errorMessage,
      branches,
      loading
    } = this.props;

    const data = [];
    branches.map(branch => {
      const obj = {};
      obj.name = branch.branch_id;
      obj.value = true;
      return data.push(obj);
    });

    return (
      <CashPackageForm
        onSubmit={this.createCashPackage}
        cashPackage={cashPackage}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        branches={branches}
        checkedList={data}
        loading={loading}
        canEdit={true}
      />
    );
  }
}
const mapStateToProps = ({ cashPackages, branches }) => {
  return {
    cashPackage: cashPackages.cashPackage,
    alertMessage: cashPackages.alertMessage,
    errorMessage: cashPackages.errorMessage,
    branches: branches.data,
    loading: cashPackages.loading
  };
};

const mapDispatchToProps = {
  createCashPackage: actions.createCashPackage.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCashPackage);
