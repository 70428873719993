import { createTypes, createAction } from "lib/action";

const LOAD_BRANCH_SERVICE_PACKAGES = createTypes(
  "branchServicePackages",
  "list"
);
const IMPORT_BRANCH_HAS_SERVICE_PACKAGE = createTypes(
  "branchServicePackages",
  "import"
);
const DOWNLOAD_FILE = createTypes("branchServicePackages", "export");
const EDIT_BRANCH_HAS_SERVICE_PACKAGE = createTypes("branchServicePackages", "edit");
const DELETE_BRANCH_HAS_PACKAGE = createTypes("branchServicePackages", "delete");
const DESTROY_MANY = createTypes("branchServicePackages", "destory");

const loadBranchServicePackages = {
  request: (branchId, pagination, limit, cond, sort) =>
    createAction(LOAD_BRANCH_SERVICE_PACKAGES.REQUEST, {
      branchId,
      pagination,
      limit,
      cond,
      sort
    }),
  success: branchServicePackages =>
    createAction(LOAD_BRANCH_SERVICE_PACKAGES.SUCCESS, {
      branchServicePackages
    }),
  failure: error =>
    createAction(LOAD_BRANCH_SERVICE_PACKAGES.FAILURE, { error })
};

const importBranchHasServicePackage = {
  request: (file, type, pagination, limit, keyword, branchId) =>
    createAction(IMPORT_BRANCH_HAS_SERVICE_PACKAGE.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword,
      branchId
    }),
  success: branchServicePackage =>
    createAction(IMPORT_BRANCH_HAS_SERVICE_PACKAGE.SUCCESS, {
      branchServicePackage
    }),
  failure: error =>
    createAction(IMPORT_BRANCH_HAS_SERVICE_PACKAGE.FAILURE, { error })
};

const download = {
  request: (branch_id) => createAction(DOWNLOAD_FILE.REQUEST, { branch_id }),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const editBranchHasServicePackages = {
  request: (branch_id, servicepackage) => createAction(EDIT_BRANCH_HAS_SERVICE_PACKAGE.REQUEST, { branch_id, servicepackage }),
  success: (servicepackage) => createAction(EDIT_BRANCH_HAS_SERVICE_PACKAGE.SUCCESS, { servicepackage }),
  failure: error => createAction(EDIT_BRANCH_HAS_SERVICE_PACKAGE.FAILURE, { error })
}

const deleteBranchHasServicePackage = {
  request: (id, branch_id) => createAction(DELETE_BRANCH_HAS_PACKAGE.REQUEST, { id, branch_id }),
  success: servicepackage => createAction(DELETE_BRANCH_HAS_PACKAGE.SUCCESS, { servicepackage }),
  failure: error => createAction(DELETE_BRANCH_HAS_PACKAGE.FAILURE, { error })
}

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_BRANCH_SERVICE_PACKAGES,
  loadBranchServicePackages,
  IMPORT_BRANCH_HAS_SERVICE_PACKAGE,
  importBranchHasServicePackage,
  DOWNLOAD_FILE,
  download,
  EDIT_BRANCH_HAS_SERVICE_PACKAGE,
  editBranchHasServicePackages,
  DELETE_BRANCH_HAS_PACKAGE,
  deleteBranchHasServicePackage,
  DESTROY_MANY,
  destroyMany
};
