import { createTypes, createAction } from "lib/action";

const LOAD_USERS = createTypes("users", "list");
const LOAD_USER = createTypes("users", "show");
const CREATE_USER = createTypes("users", "create");
const EDIT_USER = createTypes("users", "edit");
const EDIT_PASSWORD = createTypes("users", "editpassword");
const DELETE_USER = createTypes("users", "delete");
const DESTROY_MANY = createTypes("users", "destory");

const loadUsers = {
  request: (pagination, limit, cond, sort) =>
    createAction(LOAD_USERS.REQUEST, { pagination, limit, cond, sort }),
  success: users => createAction(LOAD_USERS.SUCCESS, { users }),
  failure: () => createAction(LOAD_USERS.FAILURE)
};

const loadUser = {
  request: userId => createAction(LOAD_USER.REQUEST, { userId }),
  success: user => createAction(LOAD_USER.SUCCESS, { user }),
  failure: error => createAction(LOAD_USER.FAILURE, { error })
};

const createUser = {
  request: (user, checkedList, isManager) =>
    createAction(CREATE_USER.REQUEST, { user, checkedList, isManager }),
  success: user => createAction(CREATE_USER.SUCCESS, { user }),
  failure: error => createAction(CREATE_USER.FAILURE, { error })
};

const editUser = {
  request: (id, user, checkedList, isManager) =>
    createAction(EDIT_USER.REQUEST, { id, user, checkedList, isManager }),
  success: user => createAction(EDIT_USER.SUCCESS, { user }),
  failure: error => createAction(EDIT_USER.FAILURE, { error })
};

const deleteUser = {
  request: id => createAction(DELETE_USER.REQUEST, { id }),
  success: user => createAction(DELETE_USER.SUCCESS, { user }),
  failure: error => createAction(DELETE_USER.FAILURE, { error })
};

const editPassword = {
  request: (id, user) => createAction(EDIT_PASSWORD.REQUEST, { id, user }),
  success: user => createAction(EDIT_PASSWORD.SUCCESS, { user }),
  failure: error => createAction(EDIT_PASSWORD.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_USERS,
  loadUsers,
  LOAD_USER,
  loadUser,
  CREATE_USER,
  createUser,
  EDIT_USER,
  editUser,
  DELETE_USER,
  deleteUser,
  EDIT_PASSWORD,
  editPassword,
  DESTROY_MANY,
  destroyMany
};
