import { createTypes, createAction } from "lib/action";

const LOAD_CUSTOMERS = createTypes("customers", "list");
const LOAD_CUSTOMER = createTypes("customers", "show");
const LOAD_CUSTOMER_TRANSACTION = createTypes("customers", "transaction");
const CREATE_CUSTOMER = createTypes("customers", "create");
const EDIT_CUSTOMER = createTypes("customers", "edit");
const DELETE_CUSTOMER = createTypes("customers", "delete");
const IMPORT_CUSTOMER = createTypes("customers", "import");
const DOWNLOAD_FILE = createTypes("customers", "export");
const DESTROY_MANY = createTypes("customers", "destory");

const loadCustomers = {
  request: (pagination, limit, cond, sort) =>
    createAction(LOAD_CUSTOMERS.REQUEST, { pagination, limit, cond, sort }),
  success: customers => createAction(LOAD_CUSTOMERS.SUCCESS, { customers }),
  failure: () => createAction(LOAD_CUSTOMERS.FAILURE)
};

const loadTransactions = {
  request: (customer_id, pagination, limit, keyword) =>
    createAction(LOAD_CUSTOMER_TRANSACTION.REQUEST, { customer_id, pagination, limit, keyword }),
  success: customers => createAction(LOAD_CUSTOMER_TRANSACTION.SUCCESS, { customers }),
  failure: () => createAction(LOAD_CUSTOMER_TRANSACTION.FAILURE)
}

const loadCustomer = {
  request: customerId => createAction(LOAD_CUSTOMER.REQUEST, { customerId }),
  success: customer => createAction(LOAD_CUSTOMER.SUCCESS, { customer }),
  failure: () => createAction(LOAD_CUSTOMER.FAILURE)
};

const createCustomer = {
  request: customer => createAction(CREATE_CUSTOMER.REQUEST, { customer }),
  success: customer => createAction(CREATE_CUSTOMER.SUCCESS, { customer }),
  failure: error => createAction(CREATE_CUSTOMER.FAILURE, { error })
};

const editCustomer = {
  request: (id, customer) =>
    createAction(EDIT_CUSTOMER.REQUEST, { id, customer }),
  success: customer => createAction(EDIT_CUSTOMER.SUCCESS, { customer }),
  failure: error => createAction(EDIT_CUSTOMER.FAILURE, { error })
};

const deleteCustomer = {
  request: id => createAction(DELETE_CUSTOMER.REQUEST, { id }),
  success: customer => createAction(DELETE_CUSTOMER.SUCCESS, { customer }),
  failure: error => createAction(DELETE_CUSTOMER.FAILURE, { error })
};

const importCustomer = {
  request: (file, type, pagination, limit, keyword) =>
    createAction(IMPORT_CUSTOMER.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword
    }),
  success: customer => createAction(IMPORT_CUSTOMER.SUCCESS, { customer }),
  failure: error => createAction(IMPORT_CUSTOMER.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}


export {
  LOAD_CUSTOMERS,
  loadCustomers,
  LOAD_CUSTOMER,
  loadCustomer,
  CREATE_CUSTOMER,
  createCustomer,
  EDIT_CUSTOMER,
  editCustomer,
  DELETE_CUSTOMER,
  deleteCustomer,
  IMPORT_CUSTOMER,
  importCustomer,
  DOWNLOAD_FILE,
  download,
  LOAD_CUSTOMER_TRANSACTION,
  loadTransactions,
  DESTROY_MANY,
  destroyMany
};
