import { fork } from "redux-saga/effects";

import watchCustomers from "./components/customer/sagas";
import watchCustomerPackage from "./components/customer-package/sagas";
import watchNationalities from "./components/nationality/sagas";

const watchCustomer = [
  fork(watchCustomers),
  fork(watchCustomerPackage),
  fork(watchNationalities)
];

export { watchCustomer };
