import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import {
  Button,
  Row,
  Col,
  Spin
} from "antd";

class ErpCustomerReport extends Component {
  state = {
    startDate: "2019-05-01",
    endDate: "2020-01-01"
  };

  componentDidMount() {}

  downloadErpCustomer= () => {
    const branchId = this.props.branches.currentBranch;
    this.props.downloadErpCustomer(branchId);
  };

//   onChange = (date, dateString) => {
//     this.setState({ startDate: dateString[0] });
//     this.setState({ endDate: dateString[1] });
//   };

  render() {
    return (
      <div>
        <Spin size="large" spinning={this.props.reports.erp_cus_loading}>
          <div style={{ marginBottom: 16 }}>
            <Row justify="end">
              <Col span={4} offset={12}>
                <Button
                  type="primary"
                  block
                  ghost
                  onClick={this.downloadErpCustomer}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ branches, reports }) => ({ branches, reports });

const mapDispatchToProps = {
    downloadErpCustomer: actions.downloadErpCustomer.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErpCustomerReport);
