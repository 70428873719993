import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Button } from "antd";
import { numberFormat } from 'lib/helper'

import DetailModal from './DetailModal'

const data = [
  { barcode: "018084014516", product_name : "NUTRIPLENISH? LEAVE IN CONDITIONER", amount: 127125.00, qty:87, cost: 900.00},
  { barcode: "018084015810", product_name : "NUTRIPLENISH? MULTI-USE HAIR OIL", amount: 107055.00, qty:56, cost: 1170.00},
  { barcode: "4954835290876", product_name : "MILBON RESTORATIVE BLOWOUT PRIMER FINE HAIR 120ML",amount: 76850.00, qty:53, cost: 725.46},
  { barcode: "4954835137249", product_name : "MILBON RESTORATIVE TREATMENT 500",amount: 75400.00, qty:26, cost: 1449.85},
  { barcode: "4954835137232", product_name : "MILBON RESTORATIVE TREATMENT 200",amount:  69600.00, qty:48, cost: 725.46},
  { barcode: "4954835114240", product_name : "MILBON RESTORATIVE SHAMPOO 500",amount: 66500.00, qty:35, cost: 950.16},
  { barcode: "4015600167943", product_name : "NXH SYS2 CLEANSER 1000ML",amount: 64310.00, qty:36, cost: 1743.99},
  { barcode: "018084977347", product_name : "INVATI ADV SCALP REVITAL 150ML/5FLOZ",amount: 60120.00, qty:17, cost: 2160.00},
  { barcode: "018084001936", product_name : "SAP MOSS WEIGHTLESS HYDRATION SHAMPOO 400ML",amount: 52700.00, qty:27, cost: 1200.01},
  { barcode: "018084014325", product_name : "NUTRIPLENISH? SHAMPOO LIGHT MOISTURE",amount: 51310.00, qty:37, cost: 840.00},
];

export class ProductTable extends Component {
  state = {
    visible : false
  }
  handleCloseModal = val => {
    this.setState({ visible : val })
  }

  handleOpenModal = () => {
    this.setState({ visible: true })
  }
  render() {

    const columns = [
      {
        title: "#",
        dataIndex: "barcode",
        render: (val, rec, idx) => idx + 1
      },
      {
        title: "Barcode",
        dataIndex: "barcode",
      },
      {
        title: "Name",
        dataIndex: "product_name",
      },
      {
        title: "Q'ty",
        width: 120,
        dataIndex: "qty",
        className: "align-right",
        sorter: (a, b) => a.age - b.age,
      },
      {
        title: "Total Revenue",
        width: 120,
        dataIndex: "amount",
        className: "align-right",
        sorter: (a, b) => a.age - b.age,
        render: (val) => numberFormat(val)
      },
      {
        title: "Total Cost",
        width: 120,
        dataIndex: "cost",
        className: "align-right",
        sorter: (a, b) => a.age - b.age,
        render: (val, rec) => numberFormat(val * rec.qty)
      },
      {
        title: "Gross Profit",
        width: 120,
        className: "align-right",
        dataIndex: "amount",
        sorter: (a, b) => a.age - b.age,
        render: (val, rec) => numberFormat(val - (rec.cost * rec.qty))
      },
      {
        title: " % of product revenue",
        width: 120,
        className: "align-right",
        dataIndex: "amount",
        sorter: (a, b) => a.age - b.age,
        render: (val, rec) => `${Math.round((val * 100)/750970)} %`
      },
      // {
      //   title: '',
      //   key: 'action',
      //   className: "align-center",
      //   width: 80,
      //   render: (text, record) => (
      //     <span>
      //       <Button size="small" type="link" onClick={this.handleOpenModal}>Detail</Button>
      //     </span>
      //   ),
      // },
    ];

    return (
      <div>
        <Table columns={columns} dataSource={data} size="small" bordered />
        <DetailModal visible={this.state.visible} onCancel={this.handleCloseModal}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTable);
