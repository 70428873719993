import React from "react";
import { Switch } from "react-router-dom";

import DistributorList from "./DistributorList";
import EditDistributor from "./EditDistributor";
import NewDistributor from "./NewDistributor";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute path="/product/distributors/new" component={NewDistributor} perm="product_distributors" action="can_add" />
    <PrivateRoute
      path="/product/distributors/edit/:distributor_id"
      component={EditDistributor}
      perm="product_distributors"
    />
    <PrivateRoute path="/product/distributors" component={DistributorList} perm="product_distributors"/>
  </Switch>
);
