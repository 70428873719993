import React from "react";
import Chart from "react-google-charts";
import { Empty } from "antd";


const formatData = (data) => {
  const column = [
    { type: "string", id: "Name" },
    { type: "date", id: "Start" },
    { type: "date", id: "End" },
  ];

  const res = [];
  data.map((v) => {
    v.start_time = new Date(v.start_time);
    v.submit_time = new Date(v.submit_time);
    return res.push(Object.values(v));
  });

  return [column, ...res];
};

const GraphAppointment = ({ revenue, duration }) => {
  const data = formatData(revenue);

  return revenue.length === 0 ? (
    <Empty />
  ) : (
    <Chart
      width={"100%"}
      height={`400px`}
      chartType="Timeline"
      loader={<div>Loading Chart</div>}
      data={data}
      options={{
        timeline: {
          singleColor: "#8d8",
        },
        hAxis: {
          format: "kk:mm",
          minValue: new Date(duration + " 09:00:00"),
          maxValue: new Date(duration + " 23:00:00"),
        },
      }}
      rootProps={{ "data-testid": "6" }}
    />
  );
};

export default GraphAppointment;
