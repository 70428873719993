import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import PackageGroupForm from "./PackageGroupForm";

class NewPackageGroup extends Component {
  createServicePackageGroup = async packageGroup => {
    this.props.createServicePackageGroup(
      packageGroup.service_package_group_code,
      packageGroup.service_package_group,
      1
    );
  };

  render() {
    const { packageGroup, alertMessage, errorMessage, loading } = this.props;

    return (
      <PackageGroupForm
        onSubmit={this.createServicePackageGroup}
        packageGroup={packageGroup}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ servicePackageGroups }) => {
  return {
    packageGroup: servicePackageGroups.servicePackageGroup,
    alertMessage: servicePackageGroups.alertMessage,
    errorMessage: servicePackageGroups.errorMessage,
    loading: servicePackageGroups.loading
  };
};

const mapDispatchToProps = {
  createServicePackageGroup: actions.createServicePackageGroup.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPackageGroup);
