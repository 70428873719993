import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import {
  getSearch,
  setSearch,
  getSorter,
  setSorter,
  setFilterTag,
  getFilterTag,
  utcToLocalTime,
} from "lib/helper";
import { ImportExportBtn, PageTitle, FilterBtn, TagFilter } from "lib/widget";

import { Table, Row, Col, message, Spin, Typography } from "antd";
import Filter from "./Filter";

const { Text } = Typography;

class CustomerPackageList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage } = nextProps.customerPackages;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    currentId: 0,
  };

  fetchData = () => {
    const { page, limit, cond, sorter } = this.state;
    setSorter(sorter);
    setSearch(cond);
    this.props.loadCustomerPackages(page, limit, cond, sorter);
  };

  handleSearch = (val) => {
    this.setState({ page: 1, visible: false, keyword: val.keyword, cond: val }, this.fetchData);
    this.getFilterTag(val);
  };

  getFilterTag = (val) => {
    let code = "",
      name = "",
      purchase = "",
      expiration = "";

    const {
      barcode,
      service_package_name,
      purchase_date,
      expiration_date,
    } = val;

    if (barcode !== "") code = `${barcode}`;

    if (service_package_name !== "") name = `${service_package_name}`;

    if (purchase_date.start !== "" && purchase_date.start !== null)
      purchase = `${purchase_date.start} - ${purchase_date.end}`;

    if (expiration_date.start !== "" && expiration_date.start !== null)
      expiration = `${expiration_date.start} - ${expiration_date.end}`;

    this.setState({
      barcodeTag: code,
      nameTag: name,
      purchaseTag: purchase,
      expiredTag: expiration,
    });

    setFilterTag({
      barcodeTag: code,
      nameTag: name,
      purchaseTag: purchase,
      expiredTag: expiration,
    })
  };

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState({ page: current, limit: pageSize, sorter: { order, field } }, this.fetchData);
  };

  componentDidMount() {
    let filter = getFilterTag();

    if (typeof filter === "object") {
      this.setState(
        { ...filter, cond: getSearch(), sorter: getSorter() },
        this.fetchData
      );
      return;
    }
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage } = this.props.customerPackages;
    const prevMsg = prevProps.customerPackages;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  render() {
    const { customerPackages } = this.props;
    const { barcodeTag, nameTag, purchaseTag, expiredTag, sorter } = this.state;

    const columns = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        fixed: "left",
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
      {
        title: "Branch Name",
        sorter: true,
        dataIndex: "branch_name",
        sortOrder: sorter !== null && sorter.field === 'branch_name' ? sorter.order : false,
        fixed: "left",
      },
      {
        title: "Customer Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'customer_name' ? sorter.order : false,
        width: 200,
        dataIndex: "customer_name",
        fixed: "left",
      },
      {
        title: "Phone No.",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'tel1' ? sorter.order : false,
        dataIndex: "tel1",
      },
      {
        title: "Barcode",
        dataIndex: "barcode",
      },
      {
        title: "Package Name",
        width: 250,
        dataIndex: "service_package_name",
      },
      {
        title: "Quantity",
        width: 80,
        dataIndex: "quantity",
        className: "align-right",
      },
      {
        title: "Purchase Date",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'purchase_date' ? sorter.order : false,
        dataIndex: "purchase_date",
        render: (data) => {
          if (data !== "null" && data !== "" && typeof data !== "undefined") {
            return utcToLocalTime(data);
          }
          return data;
        },
      },
      {
        title: "Activate Date",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'activation_date' ? sorter.order : false,
        dataIndex: "activation_date",
        render: (data) => {
          if (data !== "null" && data !== "" && typeof data !== "undefined") {
            return utcToLocalTime(data);
          }
          return data;
        },
      },
      {
        title: "Expired Date",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'expiration_date' ? sorter.order : false,
        dataIndex: "expiration_date",
        render: (data) => {
          if (
            data !== "null" &&
            data !== "" &&
            typeof data !== "undefined" &&
            data !== null
          ) {
            return utcToLocalTime(data);
          }
          return data;
        },
      },
    ];

    return (
      <div>
        <Spin size="large" spinning={this.props.customerPackages.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle name="customer_packages" component={null} />
            <Row gutter={[16, 16]}>
              <Col xs={10} sm={10} md={8} lg={6}>
                <ImportExportBtn
                  showModalImport={false}
                  download={this.props.download}
                  name="customer_packages"
                />
              </Col>
              <Col
                xs={6}
                sm={6}
                md={{ span: 8, offset: 4 }}
                lg={{ span: 8, offset: 7 }}
              ></Col>
              <FilterBtn showDrawer={this.showDrawer} />
              <Filter
                handleSearch={this.handleSearch}
                visible={this.state.visible}
                handleDrawerClose={this.handleDrawerClose}
              />
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={5} sm={5} md={3} lg={2}>
                <Text className="filter-text">Filters by : </Text>
              </Col>
              <Col xs={19} sm={19} md={21} lg={22}>
                {TagFilter([barcodeTag], "Barcode", "green")}
                {TagFilter([nameTag], "Service package name", "cyan")}
                {TagFilter([purchaseTag], "Purchase date", "orange")}
                {TagFilter([expiredTag], "Expired date", "blue")}
              </Col>
            </Row>
          </div>
          <Table
            rowKey="customer_has_package_id"
            bordered={true}
            size="small"
            columns={columns}
            dataSource={customerPackages.data}
            pagination={{
              pageSize: this.state.limit,
              total: customerPackages.totalSize,
              current: this.state.page,
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 1025 ? { x: 1200 } : undefined}
          />
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = ({ customerPackages }) => ({
  customerPackages,
});

const mapDispatchToProps = {
  loadCustomerPackages: actions.loadCustomerPackages.request,
  download: actions.download.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerPackageList);
