import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Row, Col, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import Widget from "./Widget";
import GraphRevenue from "./Graph";
import EmployeeTable from "./EmployeeTable";
import Filter from "./Filter";
import * as moment from "moment";

import { getIdtoArray } from "lib/helper";
import { fetchGo } from "lib/fetch";

const COLOR = ["#55b9ed", "#e94a39"];

export class Layout extends Component {
  state = {
    picker: "month",
    duration: moment().format("YYYY-MM"),
    branch_id: [],
    view: "group",
    query_type: "",
  };

  fetchData = () => {
    const { picker, duration, branch_id, query_type } = this.state;
    this.props.loadDataTable(picker, duration, branch_id, query_type);
    this.props.loadGraphData(picker, duration, branch_id, query_type);
    this.props.loadWidgetData(picker, duration, branch_id, query_type);
  };

  handleSearch = (picker, duration, branch_id, query_type) => {
    this.setState(
      {
        picker,
        duration,
        branch_id,
        query_type,
      },
      this.fetchData
    );
  };

  getComparedLabel = () => {
    switch (this.state.picker) {
      case "date":
      case "week":
        return "week";
      case "month":
        return "month";
    }
  };

  handleExportExcel = () => {
    const { picker, duration, branch_id, query_type } = this.state;
    fetchGo
      .postData(`sales_analytics_export`, {
        type: picker,
        duration,
        branch_id,
        query_type,
      })
      .then((res) => {
        window.location.assign(res.data.result);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.branches.length !== prevProps.branches.length) {
      this.setState(
        { branch_id: getIdtoArray(this.props.branches, "value") },
        this.fetchData
      );
    }
  }

  componentDidMount() {
    this.setState(
      { branch_id: getIdtoArray(this.props.branches, "value") },
      this.fetchData
    );
  }

  render() {
    const {
      branches,
      loading_graph,
      analytic_graph,
      analytic_graph_compared,
      loading_datatable,
      analytic_datatable,
      loading_widget,
      analytic_widget,
    } = this.props;

    const { picker, duration, branch_id, query_type } = this.state;

    return (
      <Fragment>
        <Filter onSearch={this.handleSearch} branches={branches} />
        <Row gutter={[8, 16]}>
          <Col lg={5}>
            <Widget
              loading={loading_widget}
              revenue={analytic_widget}
              comparedLabel={this.getComparedLabel()}
            />
          </Col>
          <Col lg={19}>
            <GraphRevenue
              type={this.state.picker}
              color={COLOR}
              loading={loading_graph}
              revenue={analytic_graph}
              compared={analytic_graph_compared}
              comparedLabel={this.getComparedLabel()}
            />
          </Col>
        </Row>
        <Row gutter={[8, 16]} justify="end">
          <Col span={2} className="align-right">
            <Button onClick={this.handleExportExcel} icon={<UploadOutlined />}>
              Export
            </Button>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <EmployeeTable
              comparedLabel={this.getComparedLabel()}
              loading_datatable={loading_datatable}
              analytic_datatable={analytic_datatable}
              type={picker}
              duration={duration}
              branch_id={branch_id}
              query_type={query_type}
              color={COLOR}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ sale_analytic }) => ({
  loading_graph: sale_analytic.loading_graph,
  analytic_graph: sale_analytic.analytic_graph,
  analytic_graph_compared: sale_analytic.analytic_graph_compared,
  loading_datatable: sale_analytic.loading_datatable,
  analytic_datatable: sale_analytic.analytic_datatable,
  loading_widget: sale_analytic.loading_widget,
  analytic_widget: sale_analytic.analytic_widget.data,
});

const mapDispatchToProps = {
  loadDataTable: actions.loadDataTable.request,
  loadGraphData: actions.loadGraphData.request,
  loadWidgetData: actions.loadWidgetData.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
