import React, { PureComponent } from "react";
import { Transfer } from 'antd';
import axios from "axios";
import { getIdtoArray } from 'lib/helper'

export default class UserTransfer extends PureComponent {
  state = {
    data: [],
    targetKeys: [],
  };

  componentDidMount() {
    this.getData()
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.users !== prevState.users && typeof nextProps.users[0] !== 'undefined') {
      return { users: nextProps.users }
    }
    return null
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.users !== this.props.users && typeof this.props.users[0] !== 'undefined') {
        if (typeof this.props.users[0] === 'object') {
          this.setState({
            targetKeys: getIdtoArray(this.props.users, 'client_id')
          })
        }
    }
  }

  getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/clients`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      this.setState({ data: this.formatTransferValue(res.data.data) });
    } catch (error) {
      console.error(error);
    }
  };

  formatTransferValue = data => {
    let items = []
    data.map((val, i) => {
      items.push({
        key: val.client_id,
        title: val.client_name,
        client_id: val.client_id
      })
    })

    return items
  }

  filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  handleChange = targetKeys => {
    this.setState({ targetKeys });
    this.props.setFieldValue('group_users', targetKeys)
  };

  getWidthComp = () => {
    if (window.innerWidth <= 500) {
      return 150
    } else if (window.innerWidth <= 800) {
      return 210
    } else {
      return 300
    }

  }

  render() {
    return (
      <Transfer
        dataSource={this.state.data}
        showSearch
        filterOption={this.filterOption}
        targetKeys={this.state.targetKeys}
        onChange={this.handleChange}
        render={item => item.title}
        listStyle={{
            width: this.getWidthComp(),
            height: 300,
          }}
      />
    );
  }
}