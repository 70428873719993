import { createTypes, createAction } from "lib/action";

const LOAD_DISTRIBUTORS = createTypes("distributors", "list");
const CREATE_DISTRIBUTOR = createTypes("distributors", "create");
const LOAD_DISTRIBUTOR = createTypes("distributors", "show");
const EDIT_DISTRIBUTOR = createTypes("distributors", "edit");
const DELETE_DISTRIBUTOR = createTypes("distributors", "delete");
const UPLOAD_FILE = createTypes("distributors", "import");
const DOWNLOAD_FILE = createTypes("distributors", "export");
const DESTROY_MANY = createTypes("distributors", "destory");

const loadDistributors = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_DISTRIBUTORS.REQUEST, { pagination, limit, keyword, sort }),
  success: distributors =>
    createAction(LOAD_DISTRIBUTORS.SUCCESS, { distributors }),
  failure: error => createAction(LOAD_DISTRIBUTORS.FAILURE, { error })
};

const loadDistributor = {
  request: distributorId =>
    createAction(LOAD_DISTRIBUTOR.REQUEST, { distributorId }),
  success: distributor =>
    createAction(LOAD_DISTRIBUTOR.SUCCESS, { distributor }),
  failure: error => createAction(LOAD_DISTRIBUTOR.FAILURE, { error })
};

const createDistributor = {
  request: (code, name) =>
    createAction(CREATE_DISTRIBUTOR.REQUEST, { code, name }),
  success: distributor =>
    createAction(CREATE_DISTRIBUTOR.SUCCESS, { distributor }),
  failure: error => createAction(CREATE_DISTRIBUTOR.FAILURE, { error })
};

const editDistributor = {
  request: (id, code, name) =>
    createAction(EDIT_DISTRIBUTOR.REQUEST, { id, code, name }),
  success: distributor =>
    createAction(EDIT_DISTRIBUTOR.SUCCESS, { distributor }),
  failure: error => createAction(EDIT_DISTRIBUTOR.FAILURE, { error })
};

const deleteDistributor = {
  request: id => createAction(DELETE_DISTRIBUTOR.REQUEST, { id }),
  success: distributor =>
    createAction(DELETE_DISTRIBUTOR.SUCCESS, { distributor }),
  failure: error => createAction(DELETE_DISTRIBUTOR.FAILURE, { error })
};

const upload = {
  request: (file, pagination, limit, keyword) =>
    createAction(UPLOAD_FILE.REQUEST, {
      file,
      pagination,
      limit,
      keyword
    }),
  success: data => createAction(UPLOAD_FILE.SUCCESS, { data }),
  failure: error => createAction(UPLOAD_FILE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: error => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_DISTRIBUTORS,
  loadDistributors,
  CREATE_DISTRIBUTOR,
  createDistributor,
  LOAD_DISTRIBUTOR,
  loadDistributor,
  EDIT_DISTRIBUTOR,
  editDistributor,
  DELETE_DISTRIBUTOR,
  deleteDistributor,
  upload,
  UPLOAD_FILE,
  download,
  DOWNLOAD_FILE,
  DESTROY_MANY,
  destroyMany
};
