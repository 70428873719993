import React from 'react'
import { Row, Col, Divider } from 'antd'
 

const SectionDivider = ({ label, sm=20 }) => {
    return (
     <Row justify="center" gutter={[16, 16]}>
        <Col xs={24} sm={sm}>
            <Divider orientation="left">{ label }</Divider>
        </Col>
      </Row>
    )
}

export default SectionDivider
