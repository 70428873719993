import {
  LOAD_WIDGET_REVENUE_SUMMARIES,
  LOAD_WIDGET_APPOINTMENT,
  LOAD_WIDGET_YEAR_REVENUE,
  LOAD_WIDGET_REVENUE_PT_SUMMARIES,
} from "./actions";

const initialState = {
  message: "",
  loading: false,
  branches: [],
  revenue_summary: {
    service: { amount: 0, discount: 0, total_amount: 0 },
    product: { amount: 0, discount: 0, total_amount: 0 },
    packages: { amount: 0, discount: 0, total_amount: 0 },
    cashpackage: { amount: 0, discount: 0, total_amount: 0 },
  },
  compared_revenue: {
    service: { amount: 0, discount: 0, total_amount: 0 },
    product: { amount: 0, discount: 0, total_amount: 0 },
    packages: { amount: 0, discount: 0, total_amount: 0 },
    cashpackage: { amount: 0, discount: 0, total_amount: 0 },
  },
  revenue_summary_t: {
    service: { amount: 0, discount: 0, total_amount: 0 },
    product: { amount: 0, discount: 0, total_amount: 0 },
    packages: { amount: 0, discount: 0, total_amount: 0 },
    cashpackage: { amount: 0, discount: 0, total_amount: 0 },
  },
  compared_revenue_t: {
    service: { amount: 0, discount: 0, total_amount: 0 },
    product: { amount: 0, discount: 0, total_amount: 0 },
    packages: { amount: 0, discount: 0, total_amount: 0 },
    cashpackage: { amount: 0, discount: 0, total_amount: 0 },
  },
  loading_revenue: false,
  loading_appoint: false,
  appoint_graph: [],
  appoint_compared_graph: [],
  year_revenue: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_WIDGET_YEAR_REVENUE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_WIDGET_YEAR_REVENUE.SUCCESS:
      return {
        ...state,
        year_revenue: action.payload.revenue.data,
      };
    case LOAD_WIDGET_YEAR_REVENUE.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case LOAD_WIDGET_REVENUE_SUMMARIES.REQUEST:
      return {
        ...state,
        loading_revenue: true,
      };
    case LOAD_WIDGET_REVENUE_SUMMARIES.SUCCESS:
      let compared_revenue =
        action.payload.compared === null ? [] : action.payload.compared.data;
      return {
        ...state,
        revenue_summary: action.payload.revenue.data,
        compared_revenue: compared_revenue,
        loading_revenue: false,
      };
    case LOAD_WIDGET_REVENUE_SUMMARIES.FAILURE:
      return {
        ...state,
        loading_revenue: false,
      };

    case LOAD_WIDGET_REVENUE_PT_SUMMARIES.REQUEST:
      return {
        ...state,
        loading_revenue: true,
      };
    case LOAD_WIDGET_REVENUE_PT_SUMMARIES.SUCCESS:
      let compared_revenue_t =
        action.payload.compared_t === null ? [] : action.payload.compared_t.data;
      return {
        ...state,
        revenue_summary_t: action.payload.revenue_t.data,
        compared_revenue_t: compared_revenue_t,
        loading_revenue: false,
      };
    case LOAD_WIDGET_REVENUE_PT_SUMMARIES.FAILURE:
      return {
        ...state,
        loading_revenue: false,
      };

    case LOAD_WIDGET_APPOINTMENT.REQUEST:
      return {
        ...state,
        loading_appoint: true,
      };
    case LOAD_WIDGET_APPOINTMENT.SUCCESS:
      return {
        ...state,
        appoint_graph: action.payload.revenue.data,
        appoint_compared_graph: action.payload.compared.data,
        loading_appoint: false,
      };
    case LOAD_WIDGET_APPOINTMENT.FAILURE:
      return {
        ...state,
        loading_appoint: false,
      };

    default:
      return state;
  }
};
