import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from 'antd'
import * as actions from "../actions";

import BranchHasCategoryForm from "./BranchHasCategoryForm";
import { checkPermission } from "lib/helper";

class EditBranchHasCategory extends Component {
  state = {
    can_edit: checkPermission("branch_product_categories", "can_add", false)
  };
  
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage } = nextProps;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage } = this.props;

    if (alertMessage !== "" && prevProps.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }


  componentDidMount() {
    let branchId = this.props.match.params.branch_id;
    if (typeof parseInt(branchId) !== "number") return;
    this.props.loadBranchCategories(branchId, null, null, null);
  }

  editBranchHasCategory = async (branchCategory) => {
    this.props.editBranchHasCategory(
      this.props.match.params.branch_id,
      branchCategory
    );
  };

  render() {
    const {
      branchCategory,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <BranchHasCategoryForm
        onSubmit={this.editBranchHasCategory}
        branchCategory={branchCategory}
        branchId={this.props.match.params.branch_id}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
      />
    );
  }
}

const mapStateToProps = ({ branchCategories, branches }) => {
  return {
    branchCategory: branchCategories.data,
    alertMessage: branchCategories.alertMessage,
    errorMessage: branchCategories.errorMessage,
    loading: branchCategories.loading,
    branches
  };
};

const mapDispatchToProps = {
  loadBranchCategories: actions.loadBranchCategories.request,
  editBranchHasCategory: actions.editBranchHasCategory.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBranchHasCategory);
