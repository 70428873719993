import { Form, Input, Select, Row, Col, message, Spin } from "antd";
import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import NoImg from "../../../../../theme/img/noimage.jpg";
import { PageTitle, FormBtn, RequriedText, SectionDivider } from "lib/widget";

const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  }
};

const formItemPriceLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  }
};
export default class BranchForm extends Component {
  state = {
    file: {},
    url: "",
    is_upload: false
  };

  printerTypes = [
    "iSAPPOS",
    "EPOS2_TM_M10",
    "EPOS2_TM_M30",
    "EPOS2_TM_P20",
    "EPOS2_TM_P60",
    "EPOS2_TM_P60II",
    "EPOS2_TM_P80",
    "EPOS2_TM_T20",
    "EPOS2_TM_T60",
    "EPOS2_TM_T70",
    "EPOS2_TM_T81",
    "EPOS2_TM_T82",
    "EPOS2_TM_T83",
    "EPOS2_TM_T88",
    "EPOS2_TM_T90",
    "EPOS2_TM_T90KP",
    "EPOS2_TM_U220",
    "EPOS2_TM_U330",
    "EPOS2_TM_L90",
    "EPOS2_TM_H6000"
  ];

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  componentDidMount() {
    this.nameInput.focus(); 
  }

  render() {
    const { branch, onSubmit } = this.props;

    return (
      <Spin size="large" spinning={this.props.loading}>
        <PageTitle name="branch_manage_branches" component={null} />
        <Formik
          initialValues={branch}
          enableReinitialize={true}
          validationSchema={yup.object().shape({
            branch_code: yup.string().required("Code is a required field"),
            branch_name: yup.string().required("Name is a required field")
          })}
          onSubmit={values => {
            onSubmit(values, this.state.is_upload);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => (
            <Form onFinish={handleSubmit} autoComplete="off">
              <fieldset disabled={!this.props.canEdit}>
                <Row justify="center">
                  <Col xs={15} sm={15} md={8} lg={8}>
                    <div className="clearfix" style={{ textAlign: "center" }}>
                      <label>
                        <br />
                        { values.logo !== '' && this.state.url === '' ?
                        <img
                        alt=""
                        src={`data:image/png;base64,${values.logo}`}
                        className="preview-img"
                        style={{
                          height: "150px",
                          width: "auto",
                          maxWidth: "450px"
                        }}
                      /> : 
                      <img
                      alt=""
                      src={this.state.url ? this.state.url : NoImg}
                      className="preview-img"
                      style={{
                        height: "150px",
                        width: "auto",
                        maxWidth: "450px"
                      }}
                    />
                      }
                      </label>
                    </div>
                    <br />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={6} >
                    <div>
                      <input
                        name="logo"
                        type="file"
                        accept="image/*"
                        onChange={file => {
                          if (file.target.files[0]) {
                            setFieldValue("logo", file.target.files[0]);
                            this.setState({
                              is_upload: true,
                              url: URL.createObjectURL(file.target.files[0])
                            });
                          } else {
                            setFieldValue("logo", "");
                            this.setState({
                              is_upload: true,
                              url: ""
                            });
                          }
                        }}
                        onBlur={handleBlur}
                        className="form-control col-lg-4"
                        style={{ marginLeft: "auto" }}
                      />
                    </div>
                  </Col>
                </Row>
                <br />

                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Code" />}
                  validateStatus={
                    touched.branch_code && errors.branch_code && "error"
                  }
                  help={touched.branch_code && errors.branch_code}
                >
                  <Input
                    ref={(input) => { this.nameInput = input; }} 
                    name="branch_code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.branch_code}
                    disabled={branch.branch_code ? true : false}
                  />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Name" />}
                  validateStatus={
                    touched.branch_name && errors.branch_name && "error"
                  }
                  help={touched.branch_name && errors.branch_name}
                >
                  <Input
                    
                    name="branch_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.branch_name}
                  />
                </FormItem>

                <FormItem {...formItemPriceLayout} label="TAX">
                  <Input
                    name="tax_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tax_id}
                  />
                </FormItem>

                <FormItem {...formItemPriceLayout} label="Printer Type">
                  <Select
                    placeholder="Select a printer type"
                    onChange={printerType =>
                      setFieldValue("printer_type", printerType)
                    }
                    value={values.printer_type}
                    disabled={!this.props.canEdit}
                  >
                    <OptGroup label="EPSON">
                      <Option value="EPOS2_TM_M10">EPOS2_TM_M10</Option>
                      <Option value="EPOS2_TM_M30">EPOS2_TM_M30</Option>
                      <Option value="EPOS2_TM_P20">EPOS2_TM_P20</Option>
                      <Option value="EPOS2_TM_P60">EPOS2_TM_P60</Option>
                      <Option value="EPOS2_TM_P60II">EPOS2_TM_P60II</Option>
                      <Option value="EPOS2_TM_P80">EPOS2_TM_P80</Option>
                      <Option value="EPOS2_TM_T20">EPOS2_TM_T20</Option>
                      <Option value="EPOS2_TM_T60">EPOS2_TM_T60</Option>
                      <Option value="EPOS2_TM_T70">EPOS2_TM_T70</Option>
                      <Option value="EPOS2_TM_T81">EPOS2_TM_T81</Option>
                      <Option value="EPOS2_TM_T82">EPOS2_TM_T82</Option>
                      <Option value="EPOS2_TM_T83">EPOS2_TM_T83</Option>
                      <Option value="EPOS2_TM_T88">EPOS2_TM_T88</Option>
                      <Option value="EPOS2_TM_T90">EPOS2_TM_T90</Option>
                      <Option value="EPOS2_TM_T90KP">EPOS2_TM_T90KP</Option>
                      <Option value="EPOS2_TM_U220">EPOS2_TM_U220</Option>
                      <Option value="EPOS2_TM_U330">EPOS2_TM_U330</Option>
                      <Option value="EPOS2_TM_L90">EPOS2_TM_L90</Option>
                      <Option value="EPOS2_TM_H6000">EPOS2_TM_H6000</Option>
                    </OptGroup>
                  </Select>
                </FormItem>
                <FormItem {...formItemPriceLayout} label="Printer IP Address">
                  <Input
                    placeholder="e.g. 192.168.1.112"
                    name="printer_ip_address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.printer_ip_address}
                  />
                </FormItem>

                <SectionDivider label="Contact Information" />

                <FormItem {...formItemPriceLayout} label="Phone no">
                  <Input
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                </FormItem>
                <FormItem {...formItemPriceLayout} label="FAX">
                  <Input
                    name="fax"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fax}
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Email">
                  <Input
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </FormItem>

                <FormItem {...formItemLayout} label="QR Code link">
                  <Input
                    name="qr_code"
                    placeholder="e.g. http://line.me/ti/p/@shopshop"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.qr_code}
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="QR description">
                  <Input
                    name="qr_description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.qr_description}
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Address">
                  <Input
                    name="address1"
                    placeholder="e.g. 27th Floor, Lumpini Tower"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address1}
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Address 2">
                  <Input
                    name="address2"
                    placeholder="Tungmahamek, sathorn"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address2}
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Address 3">
                  <Input
                    name="address3"
                    placeholder="Bangkok, 10120"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address3}
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Address 4">
                  <Input
                    name="address4"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address4}
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Address 5">
                  <Input
                    name="address5"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address5}
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Address 6">
                  <Input
                    name="address6"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address6}
                  />
                </FormItem>

                <SectionDivider label="Other Information" />

                <FormItem {...formItemLayout} label="Free text">
                  <Input
                    name="free_text1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.free_text1}
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Free text">
                  <Input
                    name="free_text2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.free_text2}
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Free text">
                  <Input
                    name="free_text3"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.free_text3}
                  />
                </FormItem>
                <FormBtn url="/branch/branches" canEdit={this.props.canEdit} />
              </fieldset>
            </Form>
          )}
        />
      </Spin>
    );
  }
}
