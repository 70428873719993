import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { checkPermission } from 'lib/helper'

const AddBtn = ({ url, name, disabled = false }) => {
  let check_add = checkPermission(name, "can_add", false)
  return check_add ? <Button disabled={disabled} 
  type="primary" block>
    <Link to={url}>Add</Link>
  </Button> : <div /> 
  
};

export default AddBtn;