import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import ServiceGroupForm from "./ServiceGroupForm";

class NewServiceGroup extends Component {
  createActualServiceGroup = async serviceGroup => {
    this.props.createActualServiceGroup(
      serviceGroup.actual_service_group_code,
      serviceGroup.actual_service_group
    );
  };

  render() {
    const {
      actualServiceGroup,
      alertMessage,
      errorMessage,
      loading
    } = this.props;
    return (
      <ServiceGroupForm
        onSubmit={this.createActualServiceGroup}
        serviceGroup={actualServiceGroup}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ actualServiceGroups }) => {
  return {
    actualServiceGroup: actualServiceGroups.actualServiceGroup,
    alertMessage: actualServiceGroups.alertMessage,
    errorMessage: actualServiceGroups.errorMessage,
    loading: actualServiceGroups.loading
  };
};

const mapDispatchToProps = {
  createActualServiceGroup: actions.createActualServiceGroup.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewServiceGroup);
