import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { loadNationalities } from "modules/customers/components/nationality/actions";

import CustomerForm from "./CustomerForm";
import { checkPermission } from "lib/helper";

class EditCustomer extends Component {
  state = {
    can_edit: checkPermission("customer_customers", "can_edit", false)
  };

  componentDidMount() {
    this.props.loadCustomer(this.props.match.params.customer_id);
    this.props.loadNationalities(1);
  }

  editCustomer = async customer => {
    this.props.editCustomer(this.props.match.params.customer_id, customer);
  };

  render() {
    const {
      customer,
      nationalities,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <CustomerForm
        onSubmit={this.editCustomer}
        customer={customer}
        nationalities={nationalities}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ customers, nationalities }) => {
  return {
    customer: customers.customer,
    nationalities: nationalities.data,
    alertMessage: customers.alertMessage,
    errorMessage: customers.errorMessage,
    loading: customers.loading
  };
};

const mapDispatchToProps = {
  loadCustomer: actions.loadCustomer.request,
  editCustomer: actions.editCustomer.request,
  loadNationalities: loadNationalities.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomer);
