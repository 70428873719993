import React, { Component, Fragment } from "react";
import { Row, Col, Typography } from "antd";

import { getIdtoArray } from "lib/helper";

import RevenueLayout from "./revenue/components/Revenue";
import TopItemLayout from "./top_items/components/Layout";
import Appointment from "./appointment/components/Appointment";

const { Title } = Typography;

export class DashboardLayout extends Component {
  state = {
    branch_id: [],
  };

  percentCal = (last, week) => {
    if (last == 0) return 0;
    if (typeof week !== "number" || typeof last !== "number") return 0;
    return ((week - last) / last) * 100;
  };

  handleBranchChange = (value) => {
    this.setState({ branch_id: value });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.branches !== this.props.branches) {
      const branchesId = getIdtoArray(this.props.branches, "value");
      this.setState({ branch_id: branchesId });
    }
  }

  render() {
    return (
      <Fragment>
        <Row
          gutter={[16, 16]}
          align="bottom"
          justify="space-between"
          style={{ marginTop: "10px" }}
        >
          <Col span={4}>
            <Title level={3}>Dashboard</Title>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <RevenueLayout
              branches={this.props.branches}
              branchesId={this.state.branch_id}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>

            <Appointment branches={this.props.branches} />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <TopItemLayout branchesId={this.state.branch_id} branches={this.props.branches}/>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default DashboardLayout;
