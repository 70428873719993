import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { numberFormat } from "lib/helper";

const formatData = (revenue, compared) => {
  const main = revenue.length >= compared.length ? revenue : compared;
  let newData = [];

  Object.keys(main).map((key) => {
    let rev = typeof revenue[key] !== "undefined" ? revenue[key]["amount"] : 0;
    let comp =
      typeof compared[key] !== "undefined" ? compared[key]["amount"] : 0;
    return newData.push({
      label: main[key]["label"],
      revenue: rev,
      compared: comp,
    });
  });

  return newData;
};

function BranchRevenue({ comparedLabel, revenue, compared, color }) {
  const data = formatData(revenue, compared);
  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" />
          <YAxis tickFormatter={(value) => numberFormat(value)}/>
          <Tooltip formatter={(value) => numberFormat(value) }/>
          <Legend />
          <Bar dataKey="compared" barSize={20} name={`Last ${comparedLabel}`} fill={color[1]} />
          <Bar dataKey="revenue" barSize={20} name={`This ${comparedLabel}`} fill={color[0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BranchRevenue;
