import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

import { Row, Col, Card, Spin, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Filter from "./Filter";
import * as moment from "moment";
import { fetchGo } from "lib/fetch";
import { extractKeyFromArray } from "lib/helper";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

class CustomerRetention extends PureComponent {
  state = {
    type: "month",
    duration: moment().format("YYYY-MM"),
    gender: "all",
    key: 1,
    loading_export: false,
  };

  handleSearch = (type, duration, gender) => {
    this.setState({ type, duration, gender }, this.fetchData);
  };

  fetchData = () => {
    const { type, duration, gender } = this.state;
    this.props.loadCustomerRetention(
      type,
      duration,
      gender,
      this.props.branches
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.branches !== this.props.branches) {
      const { type, duration, gender } = this.state;
      this.props.loadCustomerRetention(
        type,
        duration,
        gender,
        this.props.branches
      );
    }
  }

  componentDidMount() {
    if (this.props.branches.length > 0) {
      const { type, duration, gender } = this.state;
      this.props.loadCustomerRetention(
        type,
        duration,
        gender,
        this.props.branches
      );
    }
  }

  getLabel = (value) => {
    switch (this.state.type) {
      case "date":
        return `${value}:00`;
      case "month":
        return value;
      case "week":
        return days[value];
    }
    return value;
  };

  formatGraphData = (data) => {
    let items = [];
    let res = extractKeyFromArray(data, "value");

    let sort = res.sort(function (a, b) {
      return a - b;
    });
    let prev = "",
      sum = 0;

    sort.map((val) => {
      if (prev !== "" && prev !== val) {
        items.push({
          label: prev,
          value: sum,
        });
        sum = 0;
      }
      sum += 1;
      prev = val;
    });

    items.push({
      label: prev,
      value: sum,
    });

    return items;
  };

  handleExportExcel = () => {
    const { type, duration, gender } = this.state;
    let branch_id = this.props.branches;
    this.setState({ loading_export: true });
    fetchGo
      .postData(`customer_analytics_export`, {
        type,
        duration,
        gender,
        branch_id,
      })
      .then((res) => {
        window.location.assign(res.data.result);
        this.setState({ loading_export: false });
      });
  };

  render() {
    const { loading_retention, retention } = this.props;
    const data = this.formatGraphData(retention);
    return (
      <Card title="Customer Retention">
        <Spin spinning={this.state.loading_export}>
          <Row gutter={[8, 16]}>
            <Col span={24}>
              <Filter type="normal" onSearch={this.handleSearch} />
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={2} className="align-right">
              <Button
                onClick={this.handleExportExcel}
                icon={<UploadOutlined />}
              >
                Export
              </Button>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}>
              <Spin spinning={loading_retention}>
                <div style={{ width: "100%", height: 400 }}>
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={data}
                      barGap={0}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 15,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="label">
                        <Label
                          value="Return within (months)"
                          offset={-10}
                          position="insideBottomRight"
                        />
                      </XAxis>
                      <YAxis
                        label={{
                          value: "Number of customers",
                          angle: -90,
                          position: "insideLeft",
                        }}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar
                        name="Amount"
                        dataKey="value"
                        fill="#8884d8"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Spin>
            </Col>
          </Row>
        </Spin>
      </Card>
    );
  }
}

const mapStateToProps = ({ customer_analytic }) => ({
  loading_retention: customer_analytic.loading_retention,
  retention: customer_analytic.retention,
});

const mapDispatchToProps = {
  loadCustomerRetention: actions.loadCustomerRetention.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRetention);
