import appoint from "./components/appointment/reducer";
import calendar from "./components/calendar/reducer";
import revenue from "./components/revenue/reducer";
import topitem from "./components/top_items/reducer";
import widgetdashboard from "./components/widget/reducer";

const dashboard = {
  appoint,
  calendar,
  revenue,
  topitem,
  widgetdashboard,
};
export { dashboard };
