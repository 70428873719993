import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import ScheduleReportForm from "./ScheduleReportForm";
import { checkPermission } from "lib/helper";

class EditScheduleReport extends Component {
  state = {
    can_edit : checkPermission("schedule_report_schedule_report", "can_edit", false)
  }

  componentDidMount() {
    this.props.loadScheduleReport(this.props.match.params.id);
  }

  editScheduleReport = async scheduleReport => {
    this.props.editScheduleReport(
      this.props.match.params.id,
      scheduleReport.name,
      scheduleReport.report,
      scheduleReport.time,
      scheduleReport.subject,
      scheduleReport.to,
      scheduleReport.cc,
      scheduleReport.parameter
    );
  };

  render() {
    return (
      <ScheduleReportForm
        onSubmit={this.editScheduleReport}
        scheduleReport={this.props.scheduleReport}
        alertMessage={this.props.alertMessage}
        errorMessage={this.props.errorMessage}
        loading={this.props.loading}
        canEdit={this.state.can_edit}
      ></ScheduleReportForm>
    );
  }
}

const mapStateToProps = ({ scheduleReports }) => {
  return {
    scheduleReport: scheduleReports.scheduleReport,
    alertMessage: scheduleReports.alertMessage,
    errorMessage: scheduleReports.errorMessage,
    loading: scheduleReports.loading
  };
};

const mapDispatchToProps = {
  loadScheduleReport: actions.loadScheduleReport.request,
  editScheduleReport: actions.editScheduleReport.request
};

export default connect(mapStateToProps, mapDispatchToProps)(EditScheduleReport);
