import {
  LOAD_PERMISSIONS,
  LOAD_PERMISSION,
  CREATE_PERMISSION,
  EDIT_PERMISSION,
  DELETE_PERMISSION,
  UPDATE_ORDER_PERMISSION,
  DESTROY_MANY
} from "./actions";

const initialState = {
  data: [],
  permission: {
    permission_id: "",
    permission_name: "",
    display_name: "",
    permission_group_id: "",
    description: "",
    active: 1,
    view_permission: 1,
    add_permission: -1,
    edit_permission: -1,
    delete_permission: -1,
    import_permission: -1,
    export_permission: -1
  },
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PERMISSIONS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case LOAD_PERMISSIONS.SUCCESS:
      return {
        ...state,
        permission: initialState.permission,
        data: action.payload.permissions.data,
        totalSize: action.payload.permissions.total_row,
        alertMessage: "",
        errorMessage: "",
        loading: false
      };
    case LOAD_PERMISSIONS.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    case LOAD_PERMISSION.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case LOAD_PERMISSION.SUCCESS:
      return {
        ...state,
        permission: action.payload.permission.data,
        alertMessage: "",
        errorMessage: "",
        loading: false
      };
    case LOAD_PERMISSION.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };
    case CREATE_PERMISSION.REQUEST:
      return {
        ...state,
        permission: initialState.permission,
        alertMessage: "",
        errorImport: "",
        loading: true
      };

    case CREATE_PERMISSION.SUCCESS:
      return {
        ...state,
        permission: initialState.permission,
        alertMessage: action.payload.permission.message,
        errorMessage: "",
        loading: false
      };
    case CREATE_PERMISSION.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };
    case EDIT_PERMISSION.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case EDIT_PERMISSION.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.permission.message,
        errorMessage: "",
        loading: false
      };
    case EDIT_PERMISSION.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };
    case DELETE_PERMISSION.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DELETE_PERMISSION.SUCCESS:
      const newCashPackages = state.data.filter(
        permission =>
          permission.permission_id !==
          action.payload.permission.data.permission_id
      );
      return {
        ...state,
        data: newCashPackages,
        alertMessage: action.payload.permission.message,
        totalSize: state.totalSize - 1,
        loading: false
      };
    case DELETE_PERMISSION.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    case UPDATE_ORDER_PERMISSION.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case UPDATE_ORDER_PERMISSION.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.permission.message,
        errorMessage: "",
        loading: false
      };
    case UPDATE_ORDER_PERMISSION.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.permission_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
