import React, { Component, Fragment } from "react";
import { Table, Button, Spin, Input, Space } from "antd";
import { numberFormat } from "lib/helper";
import {
  CaretDownFilled,
  CaretUpFilled,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import DetailModal from "./DetailModal";

export class StylistTable extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    page: 1,
    limit: 30,
    visible: false,
    saleId: null,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleCloseModal = (val) => {
    this.setState({ visible: val, saleId: null });
  };

  handleOpenModal = (saleId) => () => {
    console.log("saleId", saleId)
    this.setState({ visible: true, saleId: saleId });
  };

  onChangePage = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    this.setState({ page: current, limit: pageSize });
  };

  render() {
    const {
      comparedLabel,
      loading_datatable,
      analytic_datatable,
      type,
      duration,
      branch_id,
      category_id,
    } = this.props;

    const columns = [
      {
        title: "#",
        width: 40,
        dataIndex: "",
        render: (val, rec, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
      {
        title: "Code",
        width: 120,
        dataIndex: "code",
        ...this.getColumnSearchProps("code"),
      },
      {
        title: "Name",
        dataIndex: "name",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Job",
        width: 80,
        dataIndex: "job",
        className: "align-right",
        sorter: (a, b) => a.job - b.job,
        render: (val) => numberFormat(val, 0),
      },
      {
        title: "Service Hours",
        width: 120,
        dataIndex: "service_hours",
        className: "align-right",
        sorter: (a, b) => a.working_minute - b.working_minute,
      },
      {
        title: "Total Revenue",
        width: 120,
        dataIndex: "total",
        className: "align-right",
        sorter: (a, b) => a.total - b.total,
        render: (val) => numberFormat(val),
      },
      {
        title: "Avg. Per Transaction",
        width: 120,
        dataIndex: "average",
        className: "align-right",
        sorter: (a, b) => a.average - b.average,
        render: (val, rec) => numberFormat(val),
      },
      {
        title: "Avg. Hours Per Transaction",
        width: 130,
        dataIndex: "avg_hours",
        className: "align-right",
        sorter: (a, b) => a.avg_minute - b.avg_minute,
      },
      {
        title: " % Of Service Revenue",
        width: 120,
        className: "align-right",
        dataIndex: "percent",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.percent - b.percent,
        render: (val) => `${val} %`,
      },
      {
        title: `Compared To Last ${comparedLabel}`,
        width: 120,
        className: "align-right",
        dataIndex: "compared",
        sorter: (a, b) => a.compared - b.compared,
        render: (val) => {
          if (val < 0) {
            return (
              <Fragment>
                {`${Math.round(val * 100) / 100} %`}
                <CaretDownFilled
                  style={{ color: "red", fontSize: "smaller" }}
                />
              </Fragment>
            );
          } else if (val > 0) {
            return (
              <Fragment>
                {`${Math.round(val * 100) / 100} %`}
                <CaretUpFilled
                  style={{ color: "green", fontSize: "smaller" }}
                />
              </Fragment>
            );
          } else {
            return `${Math.round(val * 100) / 100} %`;
          }
        },
      },
      {
        title: "",
        key: "action",
        dataIndex: "employee_id",
        className: "align-center",
        width: 80,
        render: (val, record) => (
          <span>
            <Button
              size="small"
              type="link"
              onClick={this.handleOpenModal(val)}
            >
              Detail
            </Button>
          </span>
        ),
      },
    ];

    return (
      <Spin spinning={loading_datatable}>
        <Table
          rowKey={"employee_id"}
          columns={columns}
          dataSource={analytic_datatable}
          size="small"
          bordered
          pagination={{
            pageSize: this.state.limit,
            total: analytic_datatable.length,
            current: this.state.page,
          }}
          onChange={this.onChangePage}
        />
        <DetailModal
          visible={this.state.visible}
          saleId={this.state.saleId}
          onCancel={this.handleCloseModal}
          type={type}
          comparedLabel={comparedLabel}
          duration={duration}
          branch_id={branch_id}
          category_id={category_id}
        />
      </Spin>
    );
  }
}

export default StylistTable;
