import React from "react";
import { Switch } from "react-router-dom";

import ActualServiceList from "./ActualServiceList";
import NewActualService from "./NewActualService";
import EditActualService from "./EditActualService";

import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/actual-service/services/new"
      component={NewActualService}
      perm="actual_service_actual_services"
      action="can_add"
    />
    <PrivateRoute
      path="/actual-service/services/edit/:actual_service_id"
      component={EditActualService}
      perm="actual_service_actual_services"
    />
    <PrivateRoute
      path="/actual-service/services"
      component={ActualServiceList}
      perm="actual_service_actual_services"
    />
  </Switch>
);
