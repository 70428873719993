import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import * as actions from "../actions";
import BranchHasServicePackageForm from "./BranchHasServicePackageForm";
import { checkPermission } from "lib/helper";

class EditBranchHasServicePackage extends Component {
  state = {
    can_edit: checkPermission("branch_service_packages", "can_add", false)
  };
  
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage } = nextProps;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage } = this.props;

    if (alertMessage !== "" && prevProps.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  componentDidMount() {
    let branchId = this.props.match.params.branch_id;
    if (typeof parseInt(branchId) !== "number") return;
    this.props.loadBranchServicePackages(branchId, null, null, null);
  }

  editBranchHasServicePackages = async (branchServicePackage) => {
    this.props.editBranchHasServicePackages(
      this.props.match.params.branch_id,
      branchServicePackage
    );
  };

  render() {
    const {
      branchServicePackage,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <BranchHasServicePackageForm
        onSubmit={this.editBranchHasServicePackages}
        branchServicePackage={branchServicePackage}
        branchId={this.props.match.params.branch_id}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
      />
    );
  }
}

const mapStateToProps = ({ branchServicePackages, branches }) => {
  return {
    branchServicePackage: branchServicePackages.data,
    alertMessage: branchServicePackages.alertMessage,
    errorMessage: branchServicePackages.errorMessage,
    loading: branchServicePackages.loading,
    branches
  };
};

const mapDispatchToProps = {
  loadBranchServicePackages: actions.loadBranchServicePackages.request,
  editBranchHasServicePackages: actions.editBranchHasServicePackages.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBranchHasServicePackage);
