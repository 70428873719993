import React from "react";
import { Switch } from "react-router-dom";

import Layout from "./Layout";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/dashboard"
      component={Layout}
      perm="employee_employees"
    />
  </Switch>
);
