import React from "react";
import { Switch } from "react-router-dom";

import BranchHasCashPackageList from "./BranchHasCashPackageList";
import EditBranchHasCashPackage from "./EditBranchHasCashPackage";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/branch/cash-packages/edit/:branch_id"
      component={EditBranchHasCashPackage}
      perm="branch_cash_packages"
      action="can_add"
    />
    <PrivateRoute
      path="/branch/cash-packages"
      component={BranchHasCashPackageList}
      perm="branch_cash_packages"
    />
  </Switch>
);
