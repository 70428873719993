import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadBranches(action) {
  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post(
      `branches_user`,
      {
        pagination,
        limit,
        keyword,
        sort
      }
    );
    yield put(actions.loadBranches.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadBranches.failure(getErrorMsg(error)));
  }
}

function* loadBranch(action) {
  try {
    const { branchId } = action.payload;
    const res = yield fetcher.get(`branches/${branchId}`);
    yield put(actions.loadBranch.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadBranch.failure(getErrorMsg(error)));
  }
}

function* createBranch(action) {
  try {
    const { branch } = action.payload;

    const formData = new FormData();
    formData.append("logo", branch.logo);
    formData.append("branch_code", branch.branch_code);
    formData.append("branch_name", branch.branch_name);
    formData.append("address1", branch.address1);
    formData.append("address2", branch.address2);
    formData.append("address3", branch.address3);
    formData.append("address4", branch.address4);
    formData.append("address5", branch.address5);
    formData.append("address6", branch.address6);
    formData.append("tax_id", branch.tax_id);
    formData.append("phone", branch.phone);
    formData.append("fax", branch.fax);
    formData.append("email", branch.email);
    formData.append("printer_type", branch.printer_type);
    formData.append("printer_ip_address", branch.printer_ip_address);
    formData.append("qr_code", branch.qr_code);
    formData.append("qr_description", branch.qr_description);
    formData.append("free_text1", branch.free_text1);
    formData.append("free_text2", branch.free_text2);
    formData.append("free_text3", branch.free_text3);
    const res = yield fetcher.post(
      `branches`,
      formData,
      'upload'
    );
    yield put(actions.createBranch.success(res.data));
    yield put(push("/branch/branches"));
  } catch (error) {
    handleError(error)
    yield put(actions.createBranch.failure(getErrorMsg(error)));
  }
}

function* editBranch(action) {
  try {
    const { id, branch, is_upload } = action.payload;
    const formData = new FormData();
    formData.append("logo", branch.logo);
    formData.append("branch_code", branch.branch_code);
    formData.append("branch_name", branch.branch_name);
    formData.append("address1", branch.address1);
    formData.append("address2", branch.address2);
    formData.append("address3", branch.address3);
    formData.append("address4", branch.address4);
    formData.append("address5", branch.address5);
    formData.append("address6", branch.address6);
    formData.append("tax_id", branch.tax_id);
    formData.append("phone", branch.phone);
    formData.append("fax", branch.fax);
    formData.append("email", branch.email);
    formData.append("printer_type", branch.printer_type);
    formData.append("printer_ip_address", branch.printer_ip_address);
    formData.append("qr_code", branch.qr_code);
    formData.append("qr_description", branch.qr_description);
    formData.append("free_text1", branch.free_text1);
    formData.append("free_text2", branch.free_text2);
    formData.append("free_text3", branch.free_text3);
    formData.append("is_upload", is_upload);
    formData.append("_method", "PATCH"); //for patch method

    const res = yield fetcher.post(
      `branches/${id}`,
      formData,
      'upload'
    );
    yield put(actions.editBranch.success(res.data));
    yield put(push("/branch/branches"));
  } catch (error) {
    handleError(error)
    yield put(actions.editBranch.failure(getErrorMsg(error)));
  }
}

function* deleteBranch(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`branches/${id}`);
    yield put(actions.deleteBranch.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.deleteBranch.failure(getErrorMsg(error)));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/branch`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchBranches() {
  yield all([
    takeEvery(actions.LOAD_BRANCHES.REQUEST, loadBranches),
    takeEvery(actions.LOAD_BRANCH.REQUEST, loadBranch),
    takeEvery(actions.CREATE_BRANCH.REQUEST, createBranch),
    takeEvery(actions.EDIT_BRANCH.REQUEST, editBranch),
    takeEvery(actions.DELETE_BRANCH.REQUEST, deleteBranch),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
