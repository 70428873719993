import React, { Component } from "react";
import { Form, Select } from "antd";
import { extractKeyFromArray , } from "lib/helper";

const { Option } = Select;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  }
};

class ReportForm extends Component {
  state = {
    branchId: [],
    isAll: null
  };

  onBranchChange = (value, option) => {
    let branchId = value;
    let branches = this.props.branches

    if (value.includes('all')) {
        this.setState({ isAll: false })
        branchId = extractKeyFromArray (branches, "branch_id");
    } else {
        this.setState({ isAll: true })
    }

    if (value.includes('none')) {
        branchId = []
        this.setState({ isAll: null })
    }

    this.props.onKeyValueChange("branchId", branchId);
    return branchId
  };

  getAllBranchOpt = () => {
    let branches = [
      { branch_id: this.state.isAll === false ? "none" : "all", 
        branch_name: this.state.isAll === false ? "Clear All Branches" : "All Branches" 
      },
      ...this.props.branches
    ];
    return branches;
  };

  getCurrentType = type => {
    let val = this.props.branches.find(
      item => parseInt(item.branch_id) === parseInt(type)
    );
    if (typeof val === "undefined") return;
    return [{ value: val.branch_id, label: val.branch_name }];
  };

  getBranchIdFromProps = value => {
    if (typeof value !== "undefined") {
      if (typeof value === 'object') {
        return value
      } else {
        return [value]
      }
    }
    return []
  };

  componentDidMount() {
    this.setState({
      branchId: this.getBranchIdFromProps(this.props.branchValue)
    });
  }

  render() {

    const layout = this.props.formItemLayout
      ? this.props.formItemLayout
      : formItemLayout;

    const branches =
      typeof this.props.branches !== "object" ? [] : this.getAllBranchOpt();

    return (
        <Form>
          <FormItem label="Branch" {...layout}>
              <Select
                mode="multiple"
                style={{ width: "100%", marginTop: 7 }}
                placeholder="Please select"
                onChange={this.onBranchChange}
                value={this.props.branchId}
                disabled={this.props.disabled}
              >
                {branches.map(branch => {
                  return (
                    <Option key={branch.branch_id} value={branch.branch_id}>
                      {branch.branch_name}
                    </Option>
                  );
                })}
              </Select>
          </FormItem>
        </Form>
    );
  }
}

export default ReportForm;
