import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { loadUserGroups, loadEmployees } from "lib/select-option";
import UserEditForm from "./UserEditForm";
import { checkPermission } from "lib/helper";

class EditUser extends Component {
  state = {
    can_edit: checkPermission("system_user_users", "can_edit", false)
  };

  componentDidMount() {
    this.props.loadUser(this.props.match.params.user_id);
    this.props.loadUserGroups();
    this.props.loadEmployees();
  }

  editUser = async (branch, checkedList, isManager) => {
    this.props.editUser(
      this.props.match.params.user_id,
      branch,
      checkedList,
      isManager
    );
  };

  render() {
    const {
      user,
      branches,
      alertMessage,
      errorMessage,
      branch_users,
      usergroups,
      loading,
      employees
    } = this.props;

    const data = [];
    branches.map(branch => {
      const obj = {};
      obj.name = branch.branch_id;
      const checkBranch = branch_users.filter(b => {
        if (b.branch_id === branch.branch_id) return b;
      });
      if (checkBranch.length > 0) obj.value = true;
      else obj.value = false;
      return data.push(obj);
    });

    user.is_manager = user.is_manager === 0 ? false : true;

    return (
      <UserEditForm
        onSubmit={this.editUser}
        user={user}
        usergroups={usergroups}
        branches={branches}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        checkedList={data}
        loading={loading}
        employees={employees}
        canEdit={this.state.can_edit}
      />
    );
  }
}

const mapStateToProps = ({ users, usergroups, branches, employees }) => {
  return {
    user: users.user,
    usergroups: usergroups.data,
    alertMessage: users.alertMessage,
    errorMessage: users.errorMessage,
    branches: branches.data,
    branch_users: users.branch_users,
    loading: users.loading,
    employees: employees.data
  };
};

const mapDispatchToProps = {
  loadUser: actions.loadUser.request,
  editUser: actions.editUser.request,
  loadUserGroups: loadUserGroups.request,
  loadEmployees: loadEmployees.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser);
