import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import { loadEmployeeTypes } from "modules/employee/components/employee_type/actions";
import EmployeeForm from "./EmployeeForm";

class NewEmployee extends Component {
  componentDidMount() {
    this.props.loadEmployeeTypes();
  }

  createEmployee = async (
    employee,
    is_booking,
    is_assistant,
    is_sales,
    is_upload,
    checkedList
  ) => {
    console.log(employee);
    console.log(is_booking);
    console.log(is_assistant);
    console.log(is_sales);
    console.log(checkedList);
    this.props.createEmployee(
      employee,
      is_booking,
      is_assistant,
      is_sales,
      is_upload,
      checkedList
    );
  };

  render() {
    const {
      employeeTypes,
      employee,
      alertMessage,
      errorMessage,
      branches,
      loading
    } = this.props;

    const data = [];
    branches.map(branch => {
      const obj = {};
      obj.name = branch.branch_id;
      obj.value = false;
      data.push(obj);
    });

    return (
      <EmployeeForm
        onSubmit={this.createEmployee}
        employee={employee}
        types={employeeTypes}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        branches={branches}
        checkedList={data}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ employeeTypes, employees, branches }) => {
  return {
    employeeTypes: employeeTypes.data,
    employee: employees.employee,
    alertMessage: employees.alertMessage,
    errorMessage: employees.errorMessage,
    branches: branches.data,
    loading: employees.loading
  };
};

const mapDispatchToProps = {
  createEmployee: actions.createEmployee.request,
  loadEmployeeTypes: loadEmployeeTypes.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewEmployee);
