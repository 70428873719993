import React, { Component, Fragment } from "react";
import { Row, Col, Card, Spin } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { numberFormat } from "lib/helper";
import styled from "styled-components";

const Number = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const Increase = styled.span`
  color: #00e815;
  padding-right: inherit;
  font-size: 12px;
  font-weight: bold;
`;

const Decrease = styled.span`
  color: #c90400;
  padding-right: inherit;
  font-size: 12px;
  font-weight: bold;
`;

export class Widget extends Component {
  handleUndefined = (val, key) => {
    return typeof val === "undefined" ? 0 : val[key];
  };

  percentCal = (last, week) => {
    if (last == 0) return 0;
    if (typeof week !== "number" || typeof last !== "number") return 0;
    return ((week/last) - 1) * 100;
  };

  getCardType = (data) => {
    let SaleCnt = this.handleUndefined(data.countSales, "has_value"),
        SaleTotal = this.handleUndefined(data.countSales, "total"),
        revenue = this.handleUndefined(data.revenue, "revenue"),
        revenue_c = this.handleUndefined(data.revenue, "compared"),
        avg = this.handleUndefined(data.average, "revenue"),
        avg_c = this.handleUndefined(data.average, "compared"),
        total = this.handleUndefined(data.PercentRevenue, "revenue"),
        total_c = this.handleUndefined(data.PercentRevenue, "compared"),
        sevice_hour = this.handleUndefined(data.workingHour, "working"),
        sevice_hour_c = this.handleUndefined(data.workingHour, "compared"),
        avg_hour = this.handleUndefined(data.avgHour, "working"),
        avg_hour_c = this.handleUndefined(data.avgHour, "compared")

    const cardType = [
      {
        name: "Number Of Stylist",
        value: `${SaleCnt}/${SaleTotal}`,
        diff: this.percentCal(SaleTotal, SaleCnt),
        classname: "blue-gradient",
      },
      {
        name: "Stylist Revenue",
        value: numberFormat(revenue),
        diff: revenue_c,
        classname: "blue-gradient",
      },
      {
        name: "Avg. Revenue Per Stylist",
        value: numberFormat(avg),
        diff: avg_c,
        classname: "blue-gradient",
      },
      {
        name: "% From Total Revenue",
        value: numberFormat(total),
        diff: total_c,
        classname: "orange-gradient",
      },
      {
        name: "Total Service Hours",
        value: sevice_hour,
        diff: sevice_hour_c,
        classname: "blue-gradient",
      },
      {
        name: "Avg. Service Hours Per Stylist",
        value: avg_hour,
        diff: avg_hour_c,
        classname: "blue-gradient",
      },
    ];
    return cardType;
  };

  render() {
    const { data, loading, comparedLabel } = this.props;
    const cardType = this.getCardType(data);
    return (
      <Fragment>
        <Row gutter={[8, 8]} justify="space-between">
          {cardType.map((data, i) => (
            <Col key={data.name} span={4}>
              <Card
                style={{
                  marginBottom: 10,
                  border: "1px solid #d9d9d9",
                }}
                bodyStyle={{ padding: "14px" }}
              >
                <Spin spinning={loading}>
                  <Row gutter={[8, 8]} justify="end">
                    <Col span={24}>{data.name}</Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Number>{data.value}</Number>
                      <br />
                      {data.diff < 0 ? (
                        <Decrease>
                          <ArrowDownOutlined />
                          {numberFormat(data.diff * -1)} %
                        </Decrease>
                      ) : (
                        <Increase>
                          <ArrowUpOutlined /> {numberFormat(data.diff)} %
                        </Increase>
                      )}
                      than last {comparedLabel}
                    </Col>
                  </Row>
                </Spin>
              </Card>
            </Col>
          ))}
        </Row>
      </Fragment>
    );
  }
}

export default Widget;
