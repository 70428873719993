import { createTypes, createAction } from "lib/action";

const LOAD_CASH_PACKAGES = createTypes("cashPackages", "list");
const LOAD_CASH_PACKAGE = createTypes("cashPackages", "show");
const CREATE_CASH_PACKAGE = createTypes("cashPackages", "create");
const EDIT_CASH_PACKAGE = createTypes("cashPackages", "edit");
const DELETE_CASH_PACKAGE = createTypes("cashPackages", "delete");
const IMPORT_CASH_PACKAGE = createTypes("cashPackages", "import");
const DOWNLOAD_FILE = createTypes("cashPackages", "export");
const DESTROY_MANY = createTypes("cashPackages", "destory");

const loadCashPackages = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_CASH_PACKAGES.REQUEST, { pagination, limit, keyword, sort }),
  success: cashPackages =>
    createAction(LOAD_CASH_PACKAGES.SUCCESS, { cashPackages }),
  failure: () => createAction(LOAD_CASH_PACKAGES.FAILURE)
};

const loadCashPackage = {
  request: cashPackageId =>
    createAction(LOAD_CASH_PACKAGE.REQUEST, { cashPackageId }),
  success: cashPackage =>
    createAction(LOAD_CASH_PACKAGE.SUCCESS, { cashPackage }),
  failure: error => createAction(LOAD_CASH_PACKAGE.FAILURE, { error })
};

const createCashPackage = {
  request: (cashPackage, checkedList) =>
    createAction(CREATE_CASH_PACKAGE.REQUEST, { cashPackage, checkedList }),
  success: cashPackage =>
    createAction(CREATE_CASH_PACKAGE.SUCCESS, { cashPackage }),
  failure: error => createAction(CREATE_CASH_PACKAGE.FAILURE, { error })
};

const editCashPackage = {
  request: (id, cashPackage, checkedList) =>
    createAction(EDIT_CASH_PACKAGE.REQUEST, { id, cashPackage, checkedList }),
  success: cashPackage =>
    createAction(EDIT_CASH_PACKAGE.SUCCESS, { cashPackage }),
  failure: error => createAction(EDIT_CASH_PACKAGE.FAILURE, { error })
};

const deleteCashPackage = {
  request: id => createAction(DELETE_CASH_PACKAGE.REQUEST, { id }),
  success: cashPackage =>
    createAction(DELETE_CASH_PACKAGE.SUCCESS, { cashPackage }),
  failure: error => createAction(DELETE_CASH_PACKAGE.FAILURE, { error })
};

const importCashPackage = {
  request: (file, type, pagination, limit, keyword, adjustPrice) =>
    createAction(IMPORT_CASH_PACKAGE.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword,
      adjustPrice
    }),
  success: cashPackage =>
    createAction(IMPORT_CASH_PACKAGE.SUCCESS, { cashPackage }),
  failure: error => createAction(IMPORT_CASH_PACKAGE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_CASH_PACKAGES,
  loadCashPackages,
  LOAD_CASH_PACKAGE,
  loadCashPackage,
  CREATE_CASH_PACKAGE,
  createCashPackage,
  EDIT_CASH_PACKAGE,
  editCashPackage,
  DELETE_CASH_PACKAGE,
  deleteCashPackage,
  IMPORT_CASH_PACKAGE,
  importCashPackage,
  DOWNLOAD_FILE,
  download,
  DESTROY_MANY,
  destroyMany
};
