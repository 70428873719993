import React, { Fragment } from "react";
import { Drawer, Form, Button, Row } from "antd";


const DrawerFilter = props => {
  return (
    <Drawer
      title="Filters"
      width={250}
      onClose={props.onClose('visible')}
      visible={props.visible}
      bodyStyle={{
        paddingBottom: "80px",
        paddingTop: "3px",
        paddingRight: "3px"
      }}
      footer={
        <Button onClick={props.onSearch} block>
          Apply filters
        </Button>
      }
    >
      <Form layout="vertical" hideRequiredMark>
        <Fragment>
          <Row justify="end">
            <Button type="link" onClick={props.onClear}>clear filters</Button>
          </Row>
        </Fragment>
        { props.children }
      </Form>
    </Drawer>
  );
};

export default DrawerFilter;
