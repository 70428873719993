import React from "react";
import { Switch } from "react-router-dom";

import PermissionList from "./PermissionList";
import NewPermission from "./NewPermission";
import EditPermission from "./EditPermission";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute path="/auth/permissions/new" component={NewPermission} perm="authorization_permissions" action="can_add"/>
    <PrivateRoute
      path="/auth/permissions/edit/:permission_id"
      component={EditPermission}
      perm="authorization_permissions" action="can_edit"
    />
    <PrivateRoute path="/auth/permissions" component={PermissionList} perm="authorization_permissions" action="can_add"/>
  </Switch>
);
