import React from "react";
import { Tree, Button, Row, Col } from "antd";
import { groupArray } from "lib/helper";
import axios from "axios";
const { TreeNode } = Tree;

export default class OrderPerm extends React.Component {
  state = {
    items: []
  };

  onDrop = info => {
    // Move child node
    const dropKey = info.node.props.eventKey.split("-")[1];
    const dragKey = info.dragNode.props.eventKey.split("-")[1];
    const dropPos = info.node.props.pos;
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const keyName = info.dragNode.props.eventKey.split("-")[0];

    let checkPos = dropPos.split("-");

    if (dropPosition === 0) return;
    if (typeof dragKey === "undefined" || typeof dropKey === "undefined") return;
    if (
      parseInt(checkPos[1]) !== Object.keys(this.state.items).indexOf(keyName)
    )
      return;

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (parseInt(item.permission_id) === parseInt(key)) {
          return callback(item, index, arr);
        }
      });
    };
    const data = this.state.items[keyName];

    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    let ar;
    let i;

    loop(data, dropKey, (item, index, arr) => {
      ar = arr;
      i = index;
    });

    if (dropPosition === -1) {
      if (typeof ar !== 'undefined') ar.splice(i, 0, dragObj);
    } else {
      if (typeof ar !== 'undefined') ar.splice(i + 1, 0, dragObj);
    }

    let items = this.state.items;
    items[keyName] = data;
    this.setState({
      items: items
    });
  };

  formatData = data => {
    let newData = groupArray(data, "permission_group");
    this.setState({
      dataSource: data,
      items: newData
    });
  };

  handleSave = () => {
    let item = [];
    let cnt = 0;
    Object.keys(this.state.items).map(name => {
      return this.state.items[name].map((data, i) => {
        cnt = i + 1;
        return item.push({
          permission_id: data.permission_id,
          order: cnt
        });
      });
    });
    this.props.onUpdate(item);
  };

  getData = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/permissions/allPermissions`,
        {
          pagination: null,
          limit: null,
          keyword: null
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      this.formatData(res.data.data)
    } catch (error) {
      console.error(error);
    }
  };

  onChange = e => {
    this.setState({ arrange: e.target.value });
  };

  handleCancel = () => {
    this.props.onCancel('table')
  }

  componentDidMount() {
    this.getData()
  }
  

  render() {
    const loop = group =>
      Object.keys(group).map((name, i) => {
        if (group[name].length > 0) {
          return (
            <TreeNode key={i} title={name}>
              {group[name].map(val => {
                return (
                  <TreeNode
                    key={`${val.permission_group}-${val.permission_id}`}
                    title={val.display_name}
                  />
                );
              })}
            </TreeNode>
          );
        }
        return
      });

    return (
      <div style={{ margin: 20 }}>
        <Row>
          <h2>Permissions Arrangement</h2>
        </Row>
        <Row>
        <Tree
          className="draggable-tree"
          draggable
          blockNode
          checkStrictly={true}
          onDrop={this.onDrop}
        >
          {loop(this.state.items)}
        </Tree>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={this.handleSave}>
              Save
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={this.handleCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
