import { all, put, takeEvery } from "redux-saga/effects";
import { fetchGo, handleError } from "lib/fetch";
import { getComparedFilter } from 'lib/go-api-helper'
import * as actions from "./actions";

function* loadRevenueSummaries(action) {
  try {
    const { type, duration, branch_id, query_type, category_id, withCompared } = action.payload;
    const params = { type, duration, branch_id, query_type, category_id }
    if (withCompared) {
      const params2 = { type, branch_id, query_type, category_id }

      params2["duration"] = getComparedFilter(type, duration)
  
      const [revenue, compared] = yield all([
        fetchGo.post(`revenue`, params),
        fetchGo.post(`revenue`, params2)
      ])
  
      yield put(actions.loadRevenueSummaries.success(revenue.data, compared.data));
    } else {
      const res = yield fetchGo.post(`revenue`, params)
      yield put(actions.loadRevenueSummaries.success(res.data, null));
    }
  } catch (error) {
    handleError(error);
  }
}

function* loadGraphRevenue(action) {
  try {
    const { type, duration, branch_id, query_type, category_id, withCompared } = action.payload;
    const params = { type, duration, branch_id, query_type, category_id }
    if (withCompared) {
      const params2 = { type, branch_id, query_type, category_id }

      params2["duration"] = getComparedFilter(type, duration)
  
      const [revenue, compared] = yield all([
        fetchGo.post(`revenue_graph`, params),
        fetchGo.post(`revenue_graph`, params2)
      ])
  
      yield put(actions.loadGraphRevenue.success(revenue.data, compared.data));
    } else {
      const res = yield fetchGo.post(`revenue_graph`, params)
      yield put(actions.loadGraphRevenue.success(res.data, null));
    }

  } catch (error) {
    handleError(error);
  }
}

function* loadBranchesRevenue(action) {
  try {
    const { type, duration, branch_id, query_type, category_id } = action.payload;
    const params = { type, duration, branch_id, query_type, category_id }
    const params2 = { type, branch_id, query_type, category_id }

    params2["duration"] = getComparedFilter(type, duration)

    const [revenue, compared] = yield all([
      fetchGo.post(`revenue_branch`, params),
      fetchGo.post(`revenue_branch`, params2)
    ])

    yield put(actions.loadBranchesRevenue.success(revenue.data, compared.data));
  } catch (error) {
    handleError(error);
  }
}


function* loadRevenuePerTransSummaries(action) {
  try {
    const { type, duration, branch_id, query_type, category_id } = action.payload;
    const params = { type, duration, branch_id, query_type, category_id }
    const params2 = { type, branch_id, query_type, category_id }

    params2["duration"] = getComparedFilter(type, duration)

    const [revenue, compared] = yield all([
      fetchGo.post(`revenue_per_trans`, params),
      fetchGo.post(`revenue_per_trans`, params2)
    ])

    yield put(actions.loadRevenuePerTransSummaries.success(revenue.data, compared.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadGraphRevenuePerTrans(action) {
  try {
    const { type, duration, branch_id, query_type, category_id } = action.payload;
    const params = { type, duration, branch_id, query_type, category_id }
    const params2 = { type, branch_id, query_type, category_id }

    params2["duration"] = getComparedFilter(type, duration)

    const [revenue, compared] = yield all([
      fetchGo.post(`revenue_per_trans_graph`, params),
      fetchGo.post(`revenue_per_trans_graph`, params2)
    ])

    yield put(actions.loadGraphRevenuePerTrans.success(revenue.data, compared.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadBranchesRevenuePerTrans(action) {
  try {
    const { type, duration, branch_id, query_type, category_id } = action.payload;
    const params = { type, duration, branch_id, query_type, category_id }
    const params2 = { type, branch_id, query_type, category_id }

    params2["duration"] = getComparedFilter(type, duration)

    const [revenue, compared] = yield all([
      fetchGo.post(`revenue_per_trans_branch`, params),
      fetchGo.post(`revenue_per_trans_branch`, params2)
    ])

    yield put(actions.loadBranchesRevenuePerTrans.success(revenue.data, compared.data));
  } catch (error) {
    handleError(error);
  }
}

export default function* watchDashboard() {
  yield all([
    takeEvery(actions.LOAD_REVENUE_SUMMARIES.REQUEST, loadRevenueSummaries),
    takeEvery(actions.LOAD_REVENUE_GRAPH.REQUEST, loadGraphRevenue),
    takeEvery(actions.LOAD_REVENUE_BRANCH.REQUEST, loadBranchesRevenue),

    takeEvery(actions.LOAD_REVENUE_PERTRANS_SUMMARIES.REQUEST, loadRevenuePerTransSummaries),
    takeEvery(actions.LOAD_REVENUE_PERTRANS_GRAPH.REQUEST, loadGraphRevenuePerTrans),
    takeEvery(actions.LOAD_REVENUE_PERTRANS_BRANCH.REQUEST, loadBranchesRevenuePerTrans)
  ]);
}
