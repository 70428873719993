import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";

import { Form, Input, Select, Row, Col, message, Checkbox, Spin } from "antd";

import { PageTitle, FormBtn, RequriedText } from "lib/widget";

const FormItem = Form.Item;

const actionList = [
  { value: "view_permission", label: "View" },
  { value: "add_permission", label: "Create" },
  { value: "edit_permission", label: "Edit" },
  { value: "delete_permission", label: "Delete" },
  { value: "import_permission", label: "Import" },
  { value: "export_permission", label: "Export" },
  { value: "other_1_permission", value2: "other_1_name", label: "Other 1" },
  { value: "other_2_permission", value2: "other_2_name", label: "Other 2" },
];

const formCheckboxLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export default class PermissionForm extends Component {
  state = {
    permGroup: [],
  };

  getData = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/permissions/groups`,
        {
          pagination: null,
          limit: null,
          keyword: null,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.setState({ permGroup: res.data.data });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.getData();
    this.nameInput.focus();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage } = nextProps;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage } = this.props;

    if (alertMessage !== "" && prevProps.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  onChecked = (name, setFieldValue) => (e) => {
    if (e.target.checked) setFieldValue(name, 1);
    else setFieldValue(name, -1);
  };

  checkBoxComponent = (
    values,
    handleChange,
    setFieldValue,
    touched,
    errors
  ) => {
    return actionList.map((data, i) => {
      if (data.value.match(/other/g)) {
        return (
          <Fragment>
            <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 4 }}>
              <FormItem {...formCheckboxLayout}  style={{ marginBottom: '10px' }}>
                <Checkbox
                  name={data.value}
                  onChange={this.onChecked(data.value, setFieldValue)}
                  checked={values[data.value] === 1}
                >
                  {data.label}
                </Checkbox>
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span:16 }} lg={{ span: 20 }} >
              <FormItem
                {...formCheckboxLayout}
                style={{ marginBottom: '10px' }}
                validateStatus={
                  touched[data.value2] && errors[data.value2] && "error"
                }
                help={touched[data.value2] && errors[data.value2]}
              >
                <Input
                  placeholder={`e.g. Print`}
                  name={data.value2}
                  value={values[data.value2]}
                  onChange={handleChange}
                />
              </FormItem>
            </Col>
          </Fragment>
        );
      } else {
        return (
          <Col
            key={i}
            span={data.value === "export_permission" ? 18 : 6}
            style={{ marginBottom: i > 3 && "14px" }}
          >
            <Checkbox
              name={data.value}
              onChange={this.onChecked(data.value, setFieldValue)}
              checked={values[data.value] === 1}
            >
              {data.label}
            </Checkbox>
          </Col>
        );
      }
    });
  };

  crateFormValidation = () => {
    return yup.object().shape({
      display_name: yup.string().required("Display name is a required field"),
      permission_group_id: yup
        .string()
        .required("Permission Group is a required field"),
      other_1_permission: yup.number().nullable(),
      other_2_permission: yup.number().nullable(),
      other_1_name: yup
        .string()
        .nullable()
        .when("other_1_permission", {
          is: 1,
          then: yup.string().required("Please enter permission name"),
        }),
      other_2_name: yup
        .string()
        .nullable()
        .when("other_2_permission", {
          is: 1,
          then: yup.string().required("Please enter permission name"),
        }),
    });
  };

  getCurrentGroup = (id) =>
    this.state.permGroup.find((item) => item.permission_group_id === id);

  formatPermName = (val) => {
    let newName = "";
    let group = this.getCurrentGroup(val.permission_group_id);
    if (typeof group === "undefined") return newName;

    if (
      val.display_name !== "" &&
      val.permission_group_id !== "" &&
      typeof this.state.permGroup !== "undefined"
    ) {
      let name = group.permission_group_name.toLowerCase().replace(" ", "_");
      let dis = val.display_name.toLowerCase().replace(" ", "_");
      newName = name + "_" + dis;
    }

    return newName;
  };

  render() {
    const { permission, onSubmit, loading } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    return (
      <div>
        <Spin size="large" spinning={loading}>
          <PageTitle
            title="Permissions"
            name="authorization_permissions"
            component={null}
          />
          <Formik
            initialValues={permission}
            enableReinitialize={true}
            validationSchema={this.crateFormValidation()}
            onSubmit={(values) => {
              if (values.permission_id === "")
                values.permission_name = this.formatPermName(values);
              onSubmit(values);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form onFinish={handleSubmit} autoComplete="off">
                  <fieldset disabled={!this.props.canEdit}>
                    <FormItem
                      {...formItemLayout}
                      label={<RequriedText label="Permission Group" />}
                      validateStatus={
                        touched.permission_group_id &&
                        errors.permission_group_id &&
                        "error"
                      }
                      help={
                        touched.permission_group_id &&
                        errors.permission_group_id
                      }
                    >
                      <Select
                        placeholder="Select Permission Group"
                        ref={(input) => {
                          this.nameInput = input;
                        }}
                        onChange={(value) => {
                          setFieldValue("permission_group_id", value);
                        }}
                        value={values.permission_group_id}
                        disabled={
                          this.props.readOnlyGroup || !this.props.canEdit
                        }
                      >
                        {this.state.permGroup.map((val) => {
                          return (
                            <Select.Option
                              key={val.permission_group_id}
                              value={val.permission_group_id}
                            >
                              {val.display_name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                    <FormItem
                      {...formItemLayout}
                      label={<RequriedText label="Display Name" />}
                      validateStatus={
                        touched.display_name && errors.display_name && "error"
                      }
                      help={touched.display_name && errors.display_name}
                    >
                      <Input
                        placeholder="e.g. Permission"
                        name="display_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.display_name}
                      />
                    </FormItem>
                    <FormItem
                      {...formItemLayout}
                      label="Name"
                      validateStatus={
                        touched.permission_name &&
                        errors.permission_name &&
                        "error"
                      }
                      help={touched.permission_name && errors.permission_name}
                    >
                      <Input
                        name="permission_name"
                        readOnly
                        value={
                          this.props.readOnlyGroup
                            ? values.permission_name
                            : this.formatPermName(values, setFieldValue)
                        }
                      />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Description">
                      <Input
                        placeholder="Description"
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                    </FormItem>

                    <FormItem>
                      <Row>
                        <Col
                          xs={{ span: 5 }}
                          sm={{ span: 8 }}
                          style={{
                            paddingRight: "9px",
                            textAlign:
                              window.innerWidth <= 500 ? "left" : "right",
                            color: "rgba(0, 0, 0, 0.85)",
                          }}
                        >
                          Status :
                        </Col>
                        <Col xs={{ span: 14 }} sm={{ span: 10 }}>
                          <Row>
                            <Checkbox
                              name="active"
                              checked={values.active === 1}
                              value={values.active}
                              onChange={this.onChecked("active", setFieldValue)}
                            >
                              Active
                            </Checkbox>
                          </Row>
                        </Col>
                      </Row>
                    </FormItem>

                    <FormItem>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 8 }}
                          style={{
                            paddingRight: "9px",
                            textAlign:
                              window.innerWidth <= 500 ? "left" : "right",
                            color: "rgba(0, 0, 0, 0.85)",
                          }}
                        >
                          <RequriedText label="Action :" />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 10 }}>
                          <Row gutter={[8, 8]} align="center">
                            {this.checkBoxComponent(
                              values,
                              handleChange,
                              setFieldValue,
                              touched,
                              errors
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </FormItem>

                    <FormBtn
                      url="/auth/permissions"
                      canEdit={this.props.canEdit}
                    />
                  </fieldset>
                </Form>
              );
            }}
          />
        </Spin>
      </div>
    );
  }
}
