import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../actions";

import {
  Table,
  Button,
  Row,
  Col,
  Popconfirm,
  Spin
} from "antd";

class ServicePackageDetailList extends Component {
  componentDidMount() {
    this.props.loadServicePackageDetails(
      this.props.match.params.service_package_id
    );
  }

  deleteServicePackageDetail(id) {
    this.props.deleteServicePackageDetail(id);
  }

  render() {
    const { servicePackages } = this.props;

    const data = servicePackages.services;
    const columns = [
      {
        title: "Barcode",
        dataIndex: "barcode",
        defaultSortOrder: "descend"
      },
      {
        title: "Name",
        dataIndex: "service_name"
      },
      {
        title: "Description",
        dataIndex: "description"
      },
      {
        title: "Quantity",
        dataIndex: "quantity"
      },
      {
        title: "",
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: 'left',
        render: data => (
          <span>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                this.deleteServicePackageDetail(
                  data.service_package_has_service_id
                );
              }}
            >
              <Button type="link" href="javascript:;">Delete</Button>
            </Popconfirm>
          </span>
        )
      }
    ];

    var button = (
      <Button type="primary" block="true" disabled>
        <Link to={"/service-package/packages"}>Finish</Link>
      </Button>
    );
    if (servicePackages.services.length > 0) {
      button = (
        <Button type="primary" block="true">
          <Link to={"/service-package/packages"}>Finish</Link>
        </Button>
      );
    }

    return (
      <div>
        <Spin size="large" spinning={this.props.servicePackages.loading}>
          <div style={{ marginBottom: 16 }}>
            <Row>
              <Col span={4} offset={20}>
                <Button type="primary" block="true">
                  <Link
                    to={`/service-package/packages/detail/new/${
                      this.props.match.params.service_package_id
                    }`}
                  >
                    Add Actual service
                  </Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Table
              className="components-table-demo-nested"
              columns={columns}
              dataSource={data}
              size="small"
              pagination={false}
            />
          </div>
          <Row>
            <Col span={4} offset={20}>
              {button}
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ servicePackages }) => ({
  servicePackages
});

const mapDispatchToProps = {
  loadServicePackageDetails: actions.loadServicePackageDetails.request,
  deleteServicePackageDetail: actions.deleteServicePackageDetail.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicePackageDetailList);
