import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Input,
  message,
  Select,
  Spin,
  TimePicker
} from "antd";

import moment from "moment";
import { Formik } from "formik";
import * as yup from "yup";

import { PageTitle, FormBtn, RequriedText } from "lib/widget";

import DailyReportForm from "modules/report/components/DailyReport/Form";
import EmployeesReportForm from "modules/report/components/EmployeesReport/Form";
import ErpTransactionReportForm from "modules/report/components/ErpTransactionReport/Form";
import CurrentStockReportForm from "modules/report/components/CurrentStockReport/Form";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  }
};

const reportTypes = [
  { name: "Daily", value: "daily" },
  { name: "Employee", value: "employee" },
  { name: "ERP Transaction", value: "erp_transaction" },
  { name: "Current Stock", value: "current_stock" }
];

class ScheduleReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportType: null
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.alertMessage !== "") {
        return { alertMessage : nextProps.alertMessage }
    }

    if (nextProps.errorMessage !== "") {
      return { errorMessage : nextProps.errorMessage } 
    }
    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.alertMessage !== "") {
      message.success(this.props.alertMessage);
    }

    if (this.props.errorMessage !== "") {
      message.error(this.props.errorMessage);
    }
  }

  onReportChange = value => {
    this.setState((state, props) => {
      return { ...state, reportType: value };
    });
  };

  renderParamForm = (onParamChange, values) => {
    let type = this.state.reportType;
    let Comp = null;
    let branchValue = [];

    if (
      typeof this.props.scheduleReport.parameter !== "undefined" &&
      this.state.reportType === null
    ) {
      type = this.props.scheduleReport.report;
      branchValue = JSON.parse(this.props.scheduleReport.parameter)["branchId"];
    }

    if (typeof values.branchId !== 'undefined') {
      branchValue = values.branchId
    }

    const allProps = {
      onKeyValueChange: onParamChange,
      branches: this.props.branches.data,
      formItemLayout: formItemLayout,
      isScheduleReport: true,
      branchId: branchValue
    };

    switch (type) {
      case "daily":
        Comp = <DailyReportForm {...allProps} disabled={!this.props.canEdit} />;
        break;

      case "employee":
        Comp = (
          <EmployeesReportForm {...allProps} disabled={!this.props.canEdit} />
        );
        break;

      case "erp_transaction":
        Comp = (
          <ErpTransactionReportForm
            {...allProps}
            disabled={!this.props.canEdit}
          />
        );
        break;

      case "current_stock":
        Comp = (
          <CurrentStockReportForm
            {...allProps}
            disabled={!this.props.canEdit}
          />
        );
        break;

      default:
        break;
    }

    return Comp;
  };

  removeKey = reportParams => {
    Object.keys(this.props.scheduleReport).map(key => delete reportParams[key]);
    return true;
  };

  componentDidMount() {
    this.nameInput.focus(); 
  }

  render() {
    const timeFormat = "HH:mm";

    return (
      <div>
        <Spin size="large" spinning={this.props.loading}>
          <PageTitle
            title="Scheduled Reports"
            name="schedule_report_schedule_report"
            component={null}
          />
          <Formik
            initialValues={this.props.scheduleReport}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              name: yup.string().required("Schedule Name is a required field"),
              report: yup.string().required("Report Type is a required field"),
              time: yup.string().required("Sending Time is a required field"),
              subject: yup.string().required("Subject is a required field"),
              to: yup.string().required("To is a required field")
            })}
            onSubmit={({
              name,
              report,
              time,
              subject,
              to,
              cc,
              ...reportParams
            }) => {
              let timeStr = time;
              let params = reportParams.parameter
              this.removeKey(reportParams)

              if (typeof timeStr !== "string") {
                timeStr = timeStr.utc().format(timeFormat);
              }
              let parameter = JSON.stringify(reportParams);

              if (!reportParams.hasOwnProperty('branchId')) {
                parameter = params
              }
              let branch = JSON.parse(parameter)
              if (branch.branchId.length === 0) {
                message.error("Please select branch")
                return
              }

              return this.props.onSubmit({
                name,
                report,
                time: timeStr,
                subject,
                to,
                cc,
                parameter
              });
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => {
              const onParamChange = (name, value) => {
                setFieldValue(name, value);
              };

              return (
                <Form onFinish={handleSubmit} autoComplete="off">
                  <fieldset disabled={!this.props.canEdit}>
                    <FormItem
                      label={<RequriedText label="Schedule Name" />}
                      {...formItemLayout}
                      validateStatus={touched.name && errors.name && "error"}
                      help={touched.name && errors.name}
                    >
                      <Input
                        name="name"
                        ref={(input) => { this.nameInput = input; }} 
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      ></Input>
                    </FormItem>
                    <FormItem
                      label={<RequriedText label="Sending Time" />}
                      {...formItemLayout}
                      validateStatus={touched.time && errors.time && "error"}
                      help={touched.time && errors.time}
                    >
                      <TimePicker
                        name="time"
                        style={{ width: "100%" }}
                        value={
                          values.time.length === 0
                            ? ""
                            : moment(values.time, timeFormat)
                        }
                        minuteStep={10}
                        disabled={!this.props.canEdit}
                        format={timeFormat}
                        onChange={value => setFieldValue("time", value)}
                      />
                    </FormItem>
                    <FormItem
                      label={<RequriedText label="Subject" />}
                      {...formItemLayout}
                      validateStatus={
                        touched.subject && errors.subject && "error"
                      }
                      help={touched.subject && errors.subject}
                    >
                      <Input
                        name="subject"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.subject}
                      ></Input>
                    </FormItem>
                    <FormItem
                      label={<RequriedText label="To" />}
                      {...formItemLayout}
                      validateStatus={touched.to && errors.to && "error"}
                      help={touched.to && errors.to}
                    >
                      <Input
                        name="to"
                        placeholder="e.g. email@gmail.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.to}
                      ></Input>
                    </FormItem>
                    <FormItem
                      label="CC"
                      {...formItemLayout}
                      validateStatus={touched.cc && errors.cc && "error"}
                      help={touched.cc && errors.cc}
                    >
                      <Input
                        name="cc"
                        placeholder="e.g. cc@gmail.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cc}
                      ></Input>
                    </FormItem>

                    <FormItem
                      label={<RequriedText label="Report Type" />}
                      {...formItemLayout}
                      validateStatus={
                        touched.report && errors.report && "error"
                      }
                      help={touched.report && errors.report}
                    >
                      <Select
                        onChange={value => {
                          this.onReportChange(value);
                          setFieldValue("report", value);
                        }}
                        disabled={!this.props.canEdit}
                        value={values.report}
                      >
                        {reportTypes.map(r => (
                          <Option key={r.value} value={r.value}>{r.name}</Option>
                        ))}
                      </Select>
                    </FormItem>

                    {this.renderParamForm(onParamChange, values)}

                    <FormBtn
                      url="/schedule-report"
                      canEdit={this.props.canEdit}
                    />

                  </fieldset>
                </Form>
              );
            }}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ branches }) => ({
  branches
});

export default connect(mapStateToProps)(ScheduleReportForm);
