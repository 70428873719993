import React, { Component } from "react";
import { connect } from "react-redux";
import { Drawer, Row, Col, Card } from "antd";
import * as actions from "../actions";
import Widget from "./Widget";
import Graph from "./Graph";
import DetailTable from "./DetailTable";

export class DetailModal extends Component {
  handleCancel = () => {
    this.props.onCancel(false);
  };
  render() {
    return (
      <Drawer
        title="NUTRIPLENISH? LEAVE IN CONDITIONER"
        placement="right"
        width={800}
        onClose={this.handleCancel}
        visible={this.props.visible}
      >
        <Row gutter={[8, 8]} justify="end">
        <Col span={6}>
            <Card>revenue</Card>
          </Col>
          <Col span={6}>
            <Card>% from total revenue</Card>
          </Col>
          <Col span={6}>
            <Card>% from product revenue</Card>
          </Col>
        </Row>
        <Row>
          <Col lg={24}>
            <Graph />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DetailTable />
          </Col>
        </Row>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailModal);
