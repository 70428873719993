import React, { Component } from "react";
import { connect } from "react-redux";

import { Col, Row, Input, Checkbox, Typography } from "antd";
import { loadEmployeeTypes } from "lib/select-option";
import { DrawerFilter, AutoComplete, LabelFilter } from "lib/widget";
import {
  getSearch,
} from "lib/helper";

const initialState = {
  type_id: [],
  phone_no: "",
  is_show: "all",
  hiddenType: "",
  keyword: "",
  employee_id: ""
};

const { Text } = Typography;
const { Search } = Input

class Filter extends Component {
  state = initialState;

  handleChange = (id, name) => e => {
    if (!e.target.checked) {
      this.setState(prevState => {
        return {
          [name]: prevState[name].filter(x => parseInt(x) !== parseInt(id))
        };
      });
      return;
    }

    this.setState({ [name]: [...this.state[name], id] });
  };

  handleShow = name => () => {
    let value = this.state[name] === "none" ? "" : "none";
    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const { phone_no, type_id, keyword, employee_id } = this.state;
    let selected = {
      type: this.props.types.filter(x => type_id.includes(x.employee_type_id)),
    };
    let params = {
      keyword,
      phone_no,
      type_id,
      employee_id
    };

    this.props.handleSearch(params, selected);
  };

  handleClearFilter = () => {
    this.setState(initialState);
  };

  handleInputChange = e => {
    this.setState({ employee_id: "", [e.target.name]: e.target.value });
  };

  handleAutoSelect = (id, data) => {
    this.setState({ employee_id: id, keyword: data  })
  }

  searchAuto = (searchText) => {
    this.setState({ employee_id: "", keyword: searchText  })
  }

  componentDidMount() {
    let search = getSearch()

    if (typeof search === 'object') {
      this.setState(search)
    }
    this.props.loadEmployeeTypes();
  }
  render() {
    const { types, visible, handleDrawerClose, list } = this.props;
    return (
      <DrawerFilter
        onSearch={this.handleSearch}
        visible={visible}
        onClear={this.handleClearFilter}
        onClose={handleDrawerClose}
      >
        <div className="section-filter">
          <LabelFilter text="Code or name" />
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <AutoComplete
                keyName={['employee_code', 'name']}
                keyId="employee_id"
                searchCompnent={
                  <Search
                    value={this.state.keyword}
                    name="keyword"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                  />
                }
                type="employee"
                value={this.state.keyword}
                onSelect={this.handleAutoSelect}
                onSearch={this.searchAuto}
                options={list}
              />
            </Col>
          </Row>
        </div>
        <div className="section-filter">
          <Row gutter={[0, 8]}>
            <Col span={22}>
              <Text strong>Phone no.</Text>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <Input
                name="phone_no"
                value={this.state.phone_no}
                onBlur={this.handleInputChange}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
        </div>
        <div className="section-filter">
          <Row gutter={[0, 8]}>
            <Col span={22}>
              <Text strong>Employee types</Text>
            </Col>
          </Row>
          <div className="checkbox-group-section-long">
          {typeof types !== "undefined" &&
            types.map((val, i) => {
              return (
                <Row
                  key={i}
                  gutter={[0, 8]}
                >
                  <Col span={24}>
                    <Checkbox
                      checked={this.state.type_id.includes(val.employee_type_id)}
                      onClick={this.handleChange(val.employee_type_id, "type_id")}
                    >
                      {val.type_name}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
            </div>
        </div>
      </DrawerFilter>
    );
  }
}

const mapStateToProps = ({ employeeTypes }) => ({
  types: employeeTypes.data
});

const mapDispatchToProps = {
  loadEmployeeTypes: loadEmployeeTypes.request
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
