import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, Form } from "antd";

const FormItem = Form.Item;

const NextPrevBtn = ({ onChage, step, canEdit, url, allowNext, allowSave }) => {
  const text = step >= 1 && canEdit ? "Save" : "Next";
  return (
    <Fragment>
      <FormItem>
        <Row gutter={8} justify="space-between">
          <Col xs={{ span: 6 }} md={{ span: 4 }} lg={{ span: 3 }}>
            <Button type="defualt" block>
              <Link to={url}>Cancel</Link>
            </Button>
          </Col>
          <Col xs={{ span: 13 }} md={{ span: 8 }} lg={{ span: 6 }}>
            <Row gutter={[6, 6]}>
                <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <Button style={{ display : step > 0 ? '' : 'none' }} type="defualt" block onClick={onChage(-1)}>
                    Prev
                  </Button>
                </Col>
              <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Button type="primary" 
                  htmlType={step >= 2 ? "submit" : "button"}
                  onClick={onChage(1)} block disabled={step >= 1 ? allowSave: !allowNext}>
                  {text}
                </Button>
              </Col>
              </Row> 
          </Col>
         
        </Row>
      </FormItem>
    </Fragment>
  );
};

export default NextPrevBtn;
