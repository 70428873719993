import { createTypes, createAction } from "lib/action";

const LOAD_SCHEDULE_REPORTS = createTypes("schedule_reports", "list");
const LOAD_SCHEDULE_REPORT = createTypes("schedule_report", "show");
const CREATE_SCHEDULE_REPORT = createTypes("schedule_reports", "create");
const EDIT_SCHEDULE_REPORT = createTypes("schedule_reports", "edit");
const DELETE_SCHEDULE_REPORT = createTypes("schedule_reports", "delete");
const DESTROY_MANY = createTypes("schedule_reports", "destory");

const loadScheduleReports = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_SCHEDULE_REPORTS.REQUEST, { pagination, limit, keyword, sort }),
  success: scheduleReports =>
    createAction(LOAD_SCHEDULE_REPORTS.SUCCESS, { scheduleReports }),
  failure: error => createAction(LOAD_SCHEDULE_REPORTS.FAILURE, { error })
};

const loadScheduleReport = {
  request: id => createAction(LOAD_SCHEDULE_REPORT.REQUEST, { id }),
  success: scheduleReport =>
    createAction(LOAD_SCHEDULE_REPORT.SUCCESS, { scheduleReport }),
  failure: error => createAction(LOAD_SCHEDULE_REPORT.FAILURE, { error })
};

const createScheduleReport = {
  request: (name, report, time, subject, to, cc, parameter) =>
    createAction(CREATE_SCHEDULE_REPORT.REQUEST, {
      name,
      report,
      time,
      subject,
      to,
      cc,
      parameter
    }),
  success: scheduleReport =>
    createAction(CREATE_SCHEDULE_REPORT.SUCCESS, { scheduleReport }),
  failure: error => createAction(CREATE_SCHEDULE_REPORT.FAILURE, { error })
};

const editScheduleReport = {
  request: (id, name, report, time, subject, to, cc, parameter) =>
    createAction(EDIT_SCHEDULE_REPORT.REQUEST, {
      id,
      name,
      report,
      time,
      subject,
      to,
      cc,
      parameter
    }),
  success: scheduleReport =>
    createAction(EDIT_SCHEDULE_REPORT.SUCCESS, { scheduleReport }),
  failure: error => createAction(EDIT_SCHEDULE_REPORT.FAILURE, { error })
};

const deleteScheduleReport = {
  request: id => createAction(DELETE_SCHEDULE_REPORT.REQUEST, { id }),
  success: scheduleReport =>
    createAction(DELETE_SCHEDULE_REPORT.SUCCESS, { scheduleReport }),
  failure: error => createAction(DELETE_SCHEDULE_REPORT.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_SCHEDULE_REPORTS,
  loadScheduleReports,
  LOAD_SCHEDULE_REPORT,
  loadScheduleReport,
  CREATE_SCHEDULE_REPORT,
  createScheduleReport,
  EDIT_SCHEDULE_REPORT,
  editScheduleReport,
  DELETE_SCHEDULE_REPORT,
  deleteScheduleReport,
  DESTROY_MANY,
  destroyMany
};
