import React, { Component } from "react";
import { Form, Input, message, Spin } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { PageTitle, FormBtn, RequriedText } from "lib/widget";

const FormItem = Form.Item;

export default class CategoryForm extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  isDisabled = () => {
    return this.props.category.category_code !== '' && this.props.editCode
  }

  componentDidMount() {
    this.nameInput.focus(); 
  }
  render() {
    const { category, onSubmit, loading } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      }
    };
    return (
      <div>
        <Spin size="large" spinning={loading}>
          <PageTitle name="product_categories" component={null} />
          <Formik
            initialValues={category}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              category_code: yup.string().required("Code is a required field"),
              category_name: yup.string().required("Name is a required field")
            })}
            onSubmit={values => onSubmit(values)}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form onFinish={handleSubmit} autoComplete="off">
                <fieldset disabled={!this.props.canEdit}>
                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Code" />}
                    validateStatus={errors.category_code && "error"}
                    help={touched.category_code && errors.category_code}
                  >
                    <Input
                      name="category_code"
                      ref={(input) => { this.nameInput = input; }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.category_code}
                      disabled={this.isDisabled()}
                    />
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label={<RequriedText label="Name" />}
                    validateStatus={errors.category_name && "error"}
                    help={touched.category_name && errors.category_name}
                  >
                    <Input
                      name="category_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.category_name}
                    />
                  </FormItem>
                  <FormBtn
                    url="/product/categories"
                    canEdit={this.props.canEdit}
                  />
                </fieldset>
              </Form>
            )}
          />
        </Spin>
      </div>
    );
  }
}
