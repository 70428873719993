import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, Input, Button, Form, Row, Col, message } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { AutoComplete } from "lib/widget";

const EditableContext = React.createContext();
const { Search } = Input;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const initialState = {
  barcode: "",
  service_name: "",
  description: "",
  quantity: "",
  actual_service_id: ""
};

export default class ActualServiceItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      dataSource: [],
    };
  }

  handleDelete = (key) => () => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    }, () => this.props.onAddService(this.state.dataSource));
  };

  handleAdd = () => {
    const {
      count,
      dataSource,
      barcode,
      service_name,
      description,
      quantity,
      actual_service_id
    } = this.state;
    const newData = {
      key: dataSource.length + 1,
      barcode,
      service_name,
      description,
      quantity,
      actual_service_id
    };

    if (isNaN(quantity)) {
      message.error("quantity must be number")
      return
    }

    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
      ...initialState,
    }, this.props.onAddService([...dataSource, newData]));
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    if (isNaN(item.quantity)) {
      message.error("quantity must be number")
      return
    }
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      dataSource: newData,
    });
  };

  handleInputChange = (e) => {
    let value = e.target.value
    this.setState({ [e.target.name]: value });
  };

  handleAutoSelect = (id, data) => {
    let val = data.split(" - ");
    this.setState({ barcode: val[0], service_name: val[1], actual_service_id: id });
  };

  handleAutoChange = () => {

  }

  render() {
    const { dataSource } = this.state;
    const columnsName = [
      {
        title: "Barcode",
        dataIndex: "barcode",
        width: 152,
      },
      {
        title: "Actual service",
        width: 262,
        dataIndex: "service_name",
      },
      {
        title: "Description",
        width: 372,
        dataIndex: "description",
        editable: false,
      },
      {
        title: "Quantity",
        width: 60,
        dataIndex: "quantity",
      },
      {
        title: "",
        className: "align-center",
        dataIndex: "key",
        render: (r) => {
          return <DeleteOutlined onClick={this.handleDelete(r)} />;
        },
      },
    ];
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = columnsName.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const AddRow = (
      <Row gutter={[8, { xs: 8, sm: 8, lg: 0 }]} align="middle">
        <Col lg={4}>
          <Form.Item
            style={{
              margin: 0,
            }}
          >
            <AutoComplete
              keyName={["barcode", "service_name"]}
              searchCompnent={
                <Search
                  value={this.state.barcode}
                  placeholder="Barcode or name"
                  name="barcode"
                  onChange={this.handleInputChange}
                  style={{ width: "100%" }}
                />
              }
              type="actualservice"
              keyId="actual_service_id"
              value={this.state.barcode}
              onSelect={this.handleAutoSelect}
              onSearch={this.handleAutoChange}
              options={[]}
              width="100%"
              splitValue={false}
            />
          </Form.Item>
        </Col>
        <Col lg={7} sm={12} xs={24}>
          <Form.Item
            style={{
              margin: 0,
            }}
          >
            <Input
              name="service_name"
              value={this.state.service_name}
              readOnly
            />
          </Form.Item>
        </Col>
        <Col lg={10} sm={12} xs={24}>
          <Form.Item
            style={{
              margin: 0,
            }}
          >
            <Input
              name="description"
              value={this.state.description}
              onChange={this.handleInputChange}
              placeholder="Service's description"
            />
          </Form.Item>
        </Col>
        <Col lg={2} sm={10} xs={24}>
          <Form.Item
            style={{
              margin: 0,
            }}
          >
            <Input
              name="quantity"
              value={this.state.quantity}
              onChange={this.handleInputChange}
              placeholder="0"
            />
          </Form.Item>
        </Col>
        <Col lg={1} sm={2} xs={24}>
          <Button
            onClick={this.handleAdd}
            type="primary"
            disabled={
              this.state.barcode === "" ||
              this.state.quantity === "" ||
              this.state.description === ""
            }
            icon={<PlusOutlined />}
          ></Button>
        </Col>
      </Row>
    );

    return (
      <div>
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          size="small"
          footer={() => {
            return AddRow;
          }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={window.innerWidth <= 1025 ? { x: 500 } : undefined}
        />
      </div>
    );
  }
}
