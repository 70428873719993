import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

import { Col, Input, Row, Table, message, Spin } from "antd";
import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  AddBtn,
  PageTitle
} from "lib/widget";

import { uniqueArray, extractKeyFromArray , } from "lib/helper";

const Search = Input.Search;

class ScheduleReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 20,
      removeData: [],
      visible: false,
      visibleDelete: false,
      scheduleReports: [],
      alertMessage: "",
      errorMessage: ""
    };

    this.deleteScheduleReport = this.deleteScheduleReport.bind(this);
  }

  deleteScheduleReport(id) {
    this.props.deleteScheduleReport(id);
  }

  handleSearch = value => {
    this.props.loadScheduleReports(1, 30, value);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.alertMessage !== "") {
      return { alertMessage: nextProps.alertMessage };
    }

    if (nextProps.errorMessage !== "") {
      return { errorMessage: nextProps.errorMessage };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.alertMessage !== "") {
      message.success(this.props.alertMessage);
    }

    if (this.props.errorMessage !== "") {
      message.error(this.props.errorMessage);
    }
  }

  componentDidMount() {
    const { page, limit, keyword } = this.state;
    this.props.loadScheduleReports(page, limit, keyword);
  }

  onSelectChange = selectedRowKeys => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.scheduleReports.data.filter(x =>
        selectedRowKeys.includes(x.id)
      );

      let removeState = this.state.removeData.filter(x =>
        selectedRowKeys.includes(x.id)
      );
      items = uniqueArray([...removeState, ...removeData], "id");
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = name => () => {
    this.setState({
      [name]: false
    });
  };

  showDrawer = name => () => {
    this.setState({
      [name]: true
    });
  };

  handleRemove = () => {
    this.props.destroyMany(extractKeyFromArray (this.state.removeData, "id"));
    this.setState({ selectedRowKeys: [], removeData: [], visibleDelete: false });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = id => {
    let item = this.state.selectedRowKeys.filter(x => id !== x);
    let removeData = this.state.removeData.filter(x => x.id !== id);
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };


    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1
      },
    ]

    const actionCol = [
      {
        title: "",
        key: this.props.scheduleReports,
        className: "column-action",
        width: window.innerWidth <= 1024 ? 40 : 105,
        fixed: 'left',
        render: scheduleReports => (
          <ListAction
            editUrl={`/schedule-report/edit/${scheduleReports.id}`}
            onDelete={this.props.deleteScheduleReport}
            id={scheduleReports.id}
            name="schedule_report_schedule_report"
          />
        )
      }
    ]

    const mainColumns = [
      {
        title: "Schedule name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Report type",
        dataIndex: "report",
        key: "report"
      },
      {
        title: "TO",
        dataIndex: "to",
        key: "to",
        render: receivers => {
          if (receivers.length > 45)
            return <span>{receivers.substr(0, 45) + "..."}</span>;
          else return <span>{receivers}</span>;
        },
        width: 400
      },
      {
        title: "CC",
        dataIndex: "cc",
        key: "cc",
        render: receivers => {
          if (receivers && receivers.length > 45)
            return <span>{receivers.substr(0, 45) + "..."}</span>;
          else return <span>{receivers}</span>;
        },
        width: 200
      },
    ];

    const columns = window.innerWidth <= 1024 ? [...actionCol, ...mainColumns] : [...numberCol, ...mainColumns, ...actionCol]

    return (
      <Spin size="large" spinning={this.props.loading}>
        <div style={{ marginBottom: 16 }}>
          <PageTitle
            title="Scheduled Reports"
            name="schedule_report_schedule_report"
            component={
              <AddBtn
                url="/schedule-report/new"
                name="schedule_report_schedule_report"
              />
            }
          />
          <Row gutter={[16, 16]} justify="space-between">
            <DeleteBadge
              count={this.state.removeData.length}
              onClick={this.showDrawer("visibleDelete")}
            />
            <Col xs={19} sm={19} md={8} lg={8}>
              <Search
                placeholder="Search for Schedule name, Report type"
                onSearch={value => this.handleSearch(value)}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <DeleteListModal
            onClose={this.handleDrawerClose}
            visible={this.state.visibleDelete}
            data={this.state.removeData}
            onRemove={this.handleRemove}
            removeItem={this.handleRemoveItem}
            removeAllItem={this.handleRemoveAllItem}
            displayKey={["id", "name", "report"]}
          />
        </div>
        <Table
          rowKey="id"
          rowSelection={rowSelection}
          bordered={true}
          size="small"
          dataSource={this.props.scheduleReports.data}
          columns={columns}
          scroll={window.innerWidth <= 1024 ? { x: 1000 } : undefined}
        />
      </Spin>
    );
  }
}

const mapStateToProps = ({ scheduleReports }) => ({
  scheduleReports,
  loading: scheduleReports.loading,
  alertMessage: scheduleReports.alertMessage,
  errorMessage: scheduleReports.errorMessage
});

const mapDispatchToProps = {
  loadScheduleReports: actions.loadScheduleReports.request,
  deleteScheduleReport: actions.deleteScheduleReport.request,
  destroyMany: actions.destroyMany.request
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleReportList);
