import React, { Component } from "react";
import { Row, Col, Card, Spin } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { numberFormat, extractKeyFromArray } from "lib/helper";

const list = ["Regular customer", "Walk in customer"];

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export class CustomerGraphAnalyze extends Component {
  getLabel = (value) => {
    switch (this.props.type) {
      case "date":
        return `${value}:00`;
      case "month":
        return value;
      case "week":
        return days[value];
    }
    return value;
  };

  formatGraphData = (data) => {
    let items = [];
    if (typeof data["regular"] !== "undefined") {
      data["regular"].map((val, i) => {
        return items.push({
          label: this.getLabel(val.label),
          regular: val.amount,
          walkin: data["walkin"][i].amount,
        });
      });
    }
    return items;
  };

  formatHour = (data) => {
    let h = Math.floor(data / 60);
    let m = data % 60;
    let str = h + "h " + m + "m";
    if (h === 0) {
      str = m + "m";
    }

    return str;
  };

  getSummary = (key, data) => {
    let sum = 0;
    if (key === 0) {
      if (typeof this.props.data["regular"] !== "undefined") {
        let value = extractKeyFromArray(data, "regular");
        sum = value.reduce((a, b) => a + b, 0);
      }
    }

    if (key === 1) {
      if (typeof this.props.data["walkin"] !== "undefined") {
        let value = extractKeyFromArray(data, "walkin");
        sum = value.reduce((a, b) => a + b, 0);
      }
    }

    return this.formatDataByType(sum);
  };

  formatDataByType = (sum) => {
    let result = sum;
    switch (this.props.graph) {
      case "revenue":
        result = numberFormat(sum);
        break;
      case "quantity":
        result = numberFormat(sum, 0);
        break;
      case "service":
        result = this.formatHour(sum);
        break;
      default:
        break;
    }
    return result;
  };
  render() {
    const data = this.formatGraphData(this.props.data);

    return (
      <Spin spinning={this.props.loading}>
        <Row gutter={[8, 8]}>
          <Col span={4}>
            {list.map((name, i) => (
              <Card
                style={{
                  marginBottom: 10,
                  border: "1px solid #d9d9d9",
                }}
                bodyStyle={{ padding: "14px" }}
              >
                <Row gutter={[8, 8]} justify="end">
                  <Col span={24}>{name}</Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <b>{this.getSummary(i, data)}</b>
                  </Col>
                </Row>
              </Card>
            ))}
          </Col>
          <Col span={20}>
            <div style={{ width: "100%", height: 300 }}>
              <ResponsiveContainer>
                <LineChart
                  width="800"
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="label" />
                  <YAxis
                    tickFormatter={(value) => this.formatDataByType(value)}
                  />
                  <Tooltip
                    formatter={(value) => this.formatDataByType(value)}
                  />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="regular"
                    stroke="#55b9ed"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="walkin" stroke="#e94a39" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default CustomerGraphAnalyze;
