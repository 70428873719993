import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";

import { Table, Row, message, Spin, Col } from "antd";

import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  AddBtn,
  PageTitle,
} from "lib/widget";

import {
  uniqueArray,
  extractKeyFromArray,
  setBranchFilter,
  getBranchFilter,
} from "lib/helper";

import BranchSelect from "../../BranchSelect";
import { loadBranches } from "lib/select-option";

class BranchHasTerminalList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      alertMessage,
      errorMessage,
      errorImport,
    } = nextProps.branchTerminals;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }

    if (errorImport !== "") {
      return { errorImport: errorImport };
    }

    return null;
  }

  state = {
    branchId: [parseInt(localStorage.getItem("defaultBranchId"))],
    removeData: [],
    page: 1,
    limit: 20,
    visible: false,
    visibleDelete: false,
    rowSelection: [],
  };

  handleChangeBranch = (value) => {
    setBranchFilter(value);
    if (value.length > 0) {
      this.setState({ branchId: value }, this.fetchBranchTerminals);
    } else {
      this.setState({ branchId: value });
    }
  };

  fetchBranchTerminals = () => {
    const { loadBranchTerminals } = this.props;

    loadBranchTerminals(this.state.branchId);
  };

  componentDidMount() {
    let branchId =
      getBranchFilter() !== null
        ? getBranchFilter()
        : [parseInt(localStorage.getItem("defaultBranchId"))];
    this.setState(
      {
        branchId: branchId,
      },
      this.fetchBranchTerminals
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      alertMessage,
      errorMessage,
      errorImport,
    } = this.props.branchTerminals;
    const prevMsg = prevProps.branchTerminals;

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      this.setState({ visibleImport: false });
      message.success(alertMessage);
    }

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (errorImport !== "" && prevMsg.errorImport !== errorImport) {
      this.openNotification(errorImport);
    }
  }

  deleteTerminal(id) {
    this.props.deleteTerminal(id);
  }

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.branchTerminals.data.filter((x) =>
        selectedRowKeys.includes(x.terminal_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.terminal_id)
      );
      items = uniqueArray([...removeState, ...removeData], "terminal_id");
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "terminal_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter((x) => x.terminal_id !== id);
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const { branchTerminals } = this.props;

    const actionCol = [
      {
        title: "",
        key: "terminal_id",
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: "left",
        render: (branchTerminals) => (
          <ListAction
            editUrl={`/branch/terminals/edit/${branchTerminals.terminal_id}`}
            onDelete={this.props.deleteTerminal}
            id={branchTerminals.terminal_id}
            name="branch_terminals"
            breakpoint={500}
          />
        ),
      },
    ];

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const mainColumns = [
      {
        title: "Branch Name",
        width: 100,
        dataIndex: "branch_name",
      },
      {
        title: "Code",
        width: 100,
        dataIndex: "terminal_code",
      },
      {
        title: "Name",
        dataIndex: "terminal_name",
      },
      {
        title: "Purchase Date",
        dataIndex: "purchase_date",
      },
      {
        title: "Expire Date",
        dataIndex: "expire_date",
      },
    ];

    const columns =
      window.innerWidth <= 500
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    const Delete = (
      <DeleteBadge
        count={this.state.removeData.length}
        onClick={this.showDrawer("visibleDelete")}
      />
    );

    return (
      <div>
        <Spin size="large" spinning={this.props.branchTerminals.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              name="branch_terminals"
              component={
                <AddBtn url="/branch/terminals/new" name="branch_terminals" />
              }
            />
            <Row gutter={[16, 16]} justify="space-between">
              {window.innerWidth > 500 && Delete}
              <Col xs={24} sm={24} md={10} lg={8}>
                <BranchSelect
                  branchId={this.state.branchId}
                  branches={this.props.branches}
                  onChange={this.handleChangeBranch}
                />
              </Col>
              {window.innerWidth <= 500 && Delete}
            </Row>

            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["terminal_id", "terminal_code", "terminal_name"]}
            />
          </div>
          <Table
            rowKey="terminal_id"
            bordered={true}
            size="small"
            columns={columns}
            rowSelection={rowSelection}
            dataSource={branchTerminals.data}
            scroll={window.innerWidth <= 500 ? { x: 700 } : undefined}
          />
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = ({ branchTerminals, branches }) => ({
  branchTerminals,
  branches: branches.data,
});

const mapDispatchToProps = {
  loadBranchTerminals: actions.loadBranchTerminals.request,
  deleteTerminal: actions.deleteTerminal.request,
  loadBranches: loadBranches.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchHasTerminalList);
