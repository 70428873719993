import {
  LOAD_SERVICE_DATATABLE,
  LOAD_SERVICE_GRAPH,
  LOAD_SERVICE_WIDGET,
} from "./actions";

const initialState = {
  loading_graph: false,
  analytic_graph: [],
  analytic_graph_compared: [],
  loading_datatable: false,
  analytic_datatable: [],
  analytic_datatable_compared: [],
  loading_widget: false,
  analytic_widget: {},
  analytic_widget_compared: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SERVICE_WIDGET.REQUEST:
      return {
        ...state,
        loading_widget: true,
      };
    case LOAD_SERVICE_WIDGET.SUCCESS:
      return {
        ...state,
        loading_widget: false,
        analytic_widget: action.payload.revenue_w,
      };
    case LOAD_SERVICE_WIDGET.FAILURE:
      return {
        ...state,
        loading_widget: false,
      };

    case LOAD_SERVICE_GRAPH.REQUEST:
      return {
        ...state,
        loading_graph: true,
      };
    case LOAD_SERVICE_GRAPH.SUCCESS:
      return {
        ...state,
        loading_graph: false,
        analytic_graph: action.payload.revenue_g.data,
        analytic_graph_compared: action.payload.compared_g.data,
      };
    case LOAD_SERVICE_GRAPH.FAILURE:
      return {
        ...state,
        loading_graph: false,
      };

    case LOAD_SERVICE_DATATABLE.REQUEST:
      return {
        ...state,
        loading_datatable: true,
      };
    case LOAD_SERVICE_DATATABLE.SUCCESS:
      return {
        ...state,
        loading_datatable: false,
        analytic_datatable: action.payload.revenue_d.data,
      };
    case LOAD_SERVICE_DATATABLE.FAILURE:
      return {
        ...state,
        loading_datatable: false,
      };

    default:
      return state;
  }
};
