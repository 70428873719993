import React, { Component } from "react";
import { Row, Col, Select, Button, Form, message } from "antd";
import { getIdtoArray, extractKeyFromArray } from "lib/helper";

const FormItem = Form.Item;

const initialState = {
  isAll: null,
  branches: [],
};

class Filter extends Component {
  state = initialState

  onBranchChange = (value, option) => {
    let branchId = value;
    let branches = this.props.branches;

    if (value.includes("all")) {
      this.setState({ isAll: false });
      branchId = extractKeyFromArray(branches, "value");
    } else {
      this.setState({ isAll: true });
    }

    if (value.includes("none")) {
      branchId = [];
      this.setState({ isAll: null });
    }

    this.setState({ branches: branchId });
    return branchId;
  };

  getAllBranchOpt = () => {
    let branches = [
      {
        value: this.state.isAll === false ? "none" : "all",
        label:
          this.state.isAll === false ? "Clear All Branches" : "All Branches",
      },
      ...this.props.branches,
    ];
    return branches;
  };

  handleClear = () => {
    this.setState(initialState);
  };

  handleSearch = () => {
    const { branches } = this.state;
    if (branches.length === 0) {
      message.warning('Please select branch');
      return false
    }
    this.props.onSearch(branches);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.branches.length !== prevProps.branches.length) {
      this.setState({ branches: getIdtoArray(this.props.branches, "value") });
    }
  }

  render() {
    const branches =
    typeof this.props.branches !== "object" ? [] : this.getAllBranchOpt();;

    return (
      <Form style={{ marginTop: "20px" }}>
        <Row gutter={[16, 16]} align="middle">
          <Col span={7}>
            <FormItem label="Branch">
              <Select
                mode="multiple"
                maxTagCount={3}
                onChange={this.onBranchChange}
                value={this.state.branches}
              >
                {branches.map((data, i) => (
                  <Select.Option key={i} value={data.value}>
                    {data.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={4}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                onClick={this.handleSearch}
                style={{ marginRight: "3px" }}
              >
                Submit
              </Button>
              <Button onClick={this.handleClear} htmlType="button">
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

Filter.defaultProps = {
  branches: [],
};

export default Filter;
