import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import ServiceTypeForm from "./ServiceTypeForm";

class NewServiceType extends Component {
  createActualServiceType = async serviceType => {
    this.props.createActualServiceType(
      serviceType.actual_service_type_code,
      serviceType.actual_service_type,
      serviceType.actual_service_type_abbr
    );
  };

  render() {
    const { serviceType, alertMessage, errorMessage, loading } = this.props;

    return (
      <ServiceTypeForm
        onSubmit={this.createActualServiceType}
        serviceType={serviceType}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}
const mapStateToProps = ({ actualServiceTypes }) => {
  return {
    serviceType: actualServiceTypes.actualServiceType,
    alertMessage: actualServiceTypes.alertMessage,
    errorMessage: actualServiceTypes.errorMessage,
    loading: actualServiceTypes.loading
  };
};

const mapDispatchToProps = {
  createActualServiceType: actions.createActualServiceType.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewServiceType);
