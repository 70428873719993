import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

import { Table, Row, Col, Spin, Typography, Input, Button } from "antd";

import { PageTitle } from "lib/widget";
import { numberFormat } from "lib/helper";
import HistoryDrawer from "./HistoryDrawer";

const { Text } = Typography;
const Search = Input.Search;

class CustomerHistory extends Component {
  state = {
    page: 1,
    limit: 20,
    removeData: [],
    visible: false,
    data: [],
    transaction: []
  };
  onChangePage({ pageSize, current }) {
    this.props.loadHistory(
      this.props.match.params.customer_id,
      current,
      pageSize,
      this.state.keyword
    );
    this.setState({ page: current, limit: pageSize });
  }

  onSearch(value) {
    this.props.loadHistory(
      this.props.match.params.customer_id,
      this.state.page,
      this.state.totalSize,
      value
    );

    this.setState({ keyword: value, page: 1 });
  }

  showDrawer = id => () => {
    let customerData = this.props.transaction.filter(
      x => parseInt(x.transaction_id) === parseInt(id)
    );

    this.setState({
      visible: true,
      data: customerData[0],
      transaction: customerData[0].detail
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  componentDidMount() {
    const { page, limit } = this.state;
    this.props.loadHistory(
      this.props.match.params.customer_id,
      page,
      limit,
      ""
    );
  }

  render() {
    const { transaction, loading, customer } = this.props;

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1
      },
    ]

    const actionCol = [
      {
        title: "",
        dataIndex: "transaction_id",
        className: "align-center",
        fixed: 'left',
        render: number => (
          <Button type="link" onClick={this.showDrawer(number)} key={`a-${number}`}>
            View
          </Button>
        )
      }
    ]

    const mainColumns = [
      {
        title: "Document ID",
        dataIndex: "document_id"
      },
      {
        title: "Document Date",
        dataIndex: "document_date"
      },
      {
        title: "Branch",
        dataIndex: "branch_name"
      },
      {
        title: "Stylist",
        dataIndex: "stylist"
      },
      {
        title: "Payment Type",
        dataIndex: "payment_type"
      },
      {
        title: "Receive",
        dataIndex: "receive",
        className: "align-right",
        render: number => numberFormat(number)
      },
      {
        title: "Change",
        dataIndex: "change",
        className: "align-right",
        render: number => numberFormat(number)
      },
      {
        title: "Grand Total",
        dataIndex: "grand_total",
        className: "align-right",
        render: number => numberFormat(number)
      },
      {
        title: "Points",
        dataIndex: "point",
        className: "align-right",
        render: number => numberFormat(number, 0)
      }
    ];

    const columns = window.innerWidth <= 1025 ? [...actionCol, ...mainColumns] : [...numberCol, ...mainColumns, ...actionCol]

    return (
      <Spin size="large" spinning={loading}>
        <div style={{ marginBottom: 16 }}>
          <PageTitle title="Customers Transactions" component={null} />
          <Row style={{ marginBottom: 10 }}>
            <Text strong>Customer Information</Text>
          </Row>
          <Row gutter={[8,8]}>
            <Col xs={24} sm={24} md={9} lg={9}>Customer Name : {customer.name}</Col>
            <Col xs={24} sm={24} md={9} lg={5}>Gender : {customer.gender}</Col>
            <Col xs={24} sm={24} md={9} lg={5}>Nationality : {customer.nationality}</Col>
            <Col xs={24} sm={24} md={9} lg={5}>Phone : {customer.tel1}</Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col span={24}>Customer Note : {customer.notes}</Col>
          </Row>
          <Row style={{ marginBottom: 16 }}>
            <Col xs={24} sm={24} md={8} lg={8}>
              <Search
                placeholder="Search Document ID"
                onSearch={value => this.onSearch(value)}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row>
            <Table
              rowKey="transaction_id"
              bordered={true}
              size="small"
              columns={columns}
              dataSource={transaction}
              pagination={{
                pageSize: this.state.limit,
                total: this.props.totalSize,
                current: this.state.page
              }}
              onChange={value => this.onChangePage(value)}
              scroll={window.innerWidth <= 1025 ? { x: 1000 } : undefined}
            />
          </Row>
          <HistoryDrawer
            data={this.state.data}
            customer={customer}
            items={this.state.transaction}
            onClose={this.onClose}
            visible={this.state.visible}
          />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = ({ customers }) => {
  return {
    transaction: customers.transaction,
    totalSize: customers.totalSize,
    customer: customers.customer,
    loading: customers.loading
  };
};

const mapDispatchToProps = {
  loadHistory: actions.loadTransactions.request
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerHistory);
