import React, { Component } from "react";
import {
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import { Spin } from "antd";
import { numberFormat } from "lib/helper";

const month = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

export class CustomerGrowth extends Component {
  formatData = (data, data2) => {
    let items = [];
    if (typeof data === "undefined" || typeof data2 === "undefined") return [];
    if (typeof data["female"] !== "undefined") {
      data["female"].map((val, i) => {

        return items.push({
          label: month[parseInt(val.label) - 1],
          total: data2[i].amount,
          female: val.amount,
          male: data["male"][i].amount,
          other: data["other"][i].amount,
        });
      });
    }
    return items;
  };
  render() {
    const data = this.formatData(this.props.genderData, this.props.data);
    return (
      <Spin spinning={this.props.loading}>
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer>
            <ComposedChart
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 30,
                bottom: 5,
              }}
            >
              <XAxis dataKey="label" />
              <YAxis tickFormatter={(value) => numberFormat(value, 0)} />
              <Tooltip formatter={(value) => numberFormat(value, 0)} />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="female" fill="#e94a39" />
              <Bar dataKey="male" fill="#55b9ed" />
              <Bar dataKey="other" fill="#cc7bff" />
              <Line
                type="monotone"
                dataKey="total"
                stroke="#ff7300"
                activeDot={{ r: 8 }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Spin>
    );
  }
}

export default CustomerGrowth;
