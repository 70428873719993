import { createTypes, createAction } from "lib/action";

const LOAD_TOPITEM = createTypes("topitem", "list")


const loadTopItems = {
  request: (type, duration, branch_id, query_type) =>
    createAction(LOAD_TOPITEM.REQUEST, { type, duration, branch_id, query_type }),
  success: (service, product, stylish, emp, customer) =>
    createAction(LOAD_TOPITEM.SUCCESS, { service, product, stylish, emp, customer }),
  failure: () => createAction(LOAD_TOPITEM.FAILURE),
};

export { 
  LOAD_TOPITEM,
  loadTopItems
};
