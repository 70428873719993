import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Row, Col, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import Widget from './Widget'
import Graph from './Graph'
import ProductDetail from './ProductTable'
import Filter from './Filter'

export class Layout extends Component {
  render() {
    return (
      <Fragment>
        <Filter />
        <Row gutter={[8,16]}>
            <Col lg={5}>
                <Widget />
            </Col>
            <Col lg={19}>
                <Graph />
            </Col>
        </Row>
        <Row gutter={[8,16]} justify="end">
            <Col span={2} className="align-right">
            <Button  icon={<UploadOutlined />}>Export</Button>
            </Col>
        </Row>
        <Row gutter={[8,16]}>
            <Col span={24}>
                <ProductDetail />
            </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
