import React, { Component, Fragment } from "react";
import { Table, Spin, Input, Button, Space } from "antd";
import { numberFormat } from "lib/helper";
import {
  CaretDownFilled,
  CaretUpFilled,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

export class ServiceTable extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    page: 1,
    limit: 30,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleCloseModal = (val) => {
    this.setState({ visible: val });
  };

  handleOpenModal = () => {
    this.setState({ visible: true });
  };

  compareValue = (data1, view) => {
    if(typeof data1 === 'undefined' ) return []
    if(typeof data1.service === 'undefined' || typeof data1.service_type === 'undefined') return []
    let data = view === "all" ? data1.service : data1.service_type;

    return data;
  };

  onChangePage = (pagination) => {
    const { current, pageSize } = pagination;
    this.setState({ page: current, limit: pageSize });
  };

  expandedRowRender = (record) => {
    const columns = [
      {
        title: "#",
        dataIndex: "",
        render: (val, rec, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
      {
        title: "Barcode",
        dataIndex: "barcode",
        ...this.getColumnSearchProps("barcode"),
      },
      {
        title: "Service name",
        dataIndex: "service_name",
        ...this.getColumnSearchProps("service_name"),
      },
      {
        title: "Service type",
        dataIndex: "actual_service_type",
      },
      {
        title: "Q'ty",
        width: 120,
        dataIndex: "quantity",
        className: "align-right",
        sorter: (a, b) => a.quantity - b.quantity,
        render: (val) => numberFormat(val, 0),
      },
      {
        title: "Total Revenue",
        width: 120,
        dataIndex: "sub_total",
        className: "align-right",
        sorter: (a, b) => a.sub_total - b.sub_total,
        render: (val) => numberFormat(val),
      },
      {
        title: "Avg.",
        width: 120,
        dataIndex: "averange",
        className: "align-right",
        sorter: (a, b) => a.averange - b.averange,
        render: (val, rec) => numberFormat(val),
      },
      {
        title: " % of service revenue",
        width: 120,
        className: "align-right",
        dataIndex: "percent",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.percent - b.percent,
        render: (val) => `${Math.round(val * 100) / 100} %`,
      },
      {
        title: `compared to last ${this.props.comparedLabel}`,
        width: 120,
        className: "align-right",
        dataIndex: "compared",
        sorter: (a, b) => a.compared - b.compared,
        render: (val) => {
          if (val < 0) {
            return (
              <Fragment>
                {`${Math.round(val * 100) / 100} %`}
                <CaretDownFilled
                  style={{ color: "red", fontSize: "smaller" }}
                />
              </Fragment>
            );
          } else if (val > 0) {
            return (
              <Fragment>
                {`${Math.round(val * 100) / 100} %`}
                <CaretUpFilled
                  style={{ color: "green", fontSize: "smaller" }}
                />
              </Fragment>
            );
          } else {
            return `${Math.round(val * 100) / 100} %`;
          }
        },
      },
    ];

    const data = this.data.filter(
      (x) => x.actual_service_type_id === record.actual_service_type_id
    );

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  render() {
    const {
      loading_datatable,
      analytic_datatable,
      comparedLabel,
      view,
    } = this.props;

    const data = this.compareValue(
      analytic_datatable,
      view
    );

    this.data = this.compareValue(
      analytic_datatable,
      "all"
    );

    const columns = [
      {
        title: "#",
        dataIndex: "",
        render: (val, rec, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
      {
        title: "Service type",
        dataIndex: "actual_service_type",
        ...this.getColumnSearchProps("actual_service_type"),
      },
      {
        title: "Q'ty",
        width: 120,
        dataIndex: "quantity",
        className: "align-right",
        sorter: (a, b) => a.quantity - b.quantity,
        render: (val) => numberFormat(val, 0),
      },
      {
        title: "Total Revenue",
        width: 120,
        dataIndex: "sub_total",
        className: "align-right",
        sorter: (a, b) => a.sub_total - b.sub_total,
        render: (val) => numberFormat(val),
      },
      {
        title: "Avg.",
        width: 120,
        dataIndex: "averange",
        className: "align-right",
        sorter: (a, b) => a.averange - b.averange,
        render: (val, rec) => numberFormat(Math.round(val * 100) / 100),
      },
      {
        title: " % of service revenue",
        width: 120,
        className: "align-right",
        dataIndex: "percent",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.percent - b.percent,
        render: (val) => `${Math.round(val * 100) / 100} %`,
      },
      {
        title: `compared to last ${comparedLabel}`,
        width: 120,
        className: "align-right",
        dataIndex: "compared",
        sorter: (a, b) => a.compared - b.compared,
        render: (val) => {
          if (val < 0) {
            return (
              <Fragment>
                {`${Math.round(val * 100) / 100} %`}
                <CaretDownFilled
                  style={{ color: "red", fontSize: "smaller" }}
                />
              </Fragment>
            );
          } else if (val > 0) {
            return (
              <Fragment>
                {`${Math.round(val * 100) / 100} %`}
                <CaretUpFilled
                  style={{ color: "green", fontSize: "smaller" }}
                />
              </Fragment>
            );
          } else {
            return `${Math.round(val * 100) / 100} %`;
          }
        },
      },
    ];

    if (view === "all") {
      const col2 = [
        {
          title: "Barcode",
          dataIndex: "barcode",
          ...this.getColumnSearchProps("barcode"),
        },
        {
          title: "Service name",
          dataIndex: "service_name",
          ...this.getColumnSearchProps("service_name"),
        },
      ];

      columns.splice(1, 0, ...col2);
    }

    const rowKey =
      view === "all"
        ? "actual_service_id"
        : "actual_service_type_id";

    const options = {
      rowKey: rowKey,
      columns: columns,
      dataSource: data,
      size: "small",
      bordered: true,
      pagination: {
        pageSize: this.state.limit,
        total: data.length,
        current: this.state.page,
      },
      onChange: this.onChangePage,
      expandedRowRender: view === 'all' ? false : (record) => this.expandedRowRender(record)
    };

    return (
      <Spin spinning={loading_datatable}>
        <Table {...options} />
      </Spin>
    );
  }
}

export default ServiceTable;
