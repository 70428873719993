import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { loadServicePackageGroups } from "lib/select-option";

import ServicePackageEditForm from "./ServicePackageEditForm";
import { checkPermission } from "lib/helper";

class EditServicePackage extends Component {
  state = {
    can_edit: checkPermission("service_package_service_packages", "can_edit", false)
  };

  componentDidMount() {
    this.props.loadServicePackage(this.props.match.params.service_package_id);
    this.props.loadServicePackageGroups();
  }

  editServicePackage = async (servicePackage, checkedList) => {
    this.props.editServicePackage(
      this.props.match.params.service_package_id,
      servicePackage,
      checkedList
    );
  };

  render() {
    const {
      servicePackage,
      servicePackageGroups,
      alertMessage,
      errorMessage,
      branches,
      branch_service_packages,
      loading
    } = this.props;

    const data = [];
    branches.map(branch => {
      const obj = {};
      obj.name = branch.branch_id;
      const checkBranch = branch_service_packages.filter(b => {
        if (b.branch_id === branch.branch_id) return b;
      });
      if (checkBranch.length > 0) obj.value = true;
      else obj.value = false;
      return data.push(obj);
    });

    return (
      <ServicePackageEditForm
        onSubmit={this.editServicePackage}
        servicePackage={servicePackage}
        servicePackageGroups={servicePackageGroups}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        branches={branches}
        checkedList={data}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({
  servicePackages,
  servicePackageGroups,
  branches
}) => {
  return {
    servicePackage: servicePackages.package,
    servicePackageGroups: servicePackageGroups.data,
    alertMessage: servicePackages.alertMessage,
    errorMessage: servicePackages.errorMessage,
    branches: branches.data,
    branch_service_packages: servicePackages.branch_service_packages,
    loading: servicePackages.loading
  };
};

const mapDispatchToProps = {
  loadServicePackage: actions.loadServicePackage.request,
  editServicePackage: actions.editServicePackage.request,
  loadServicePackageGroups: loadServicePackageGroups.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditServicePackage);
