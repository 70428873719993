import React, { Component, Fragment } from "react";
import { Row, Col } from "antd";
import TopSpend from "./TopSpend";

import Widget from "./Widget";
import CustomerGraphLayout from "./CustomerGraphLayout";
import CustomerGrowthRateLayout from "./CustomerGrowthRateLayout";
import CustomerRetention from "./CustomerRetention"

export class CustomerLayout extends Component {
  state = {
    picker: "date",
    limit: 10,
    page: 1,
    visible: false,
  };

  render() {
    return (
      <Fragment>
        <Row gutter={[8, 16]} justify="space-between">
            <Widget branches={this.props.branches}/>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <CustomerGraphLayout branches={this.props.branches}/>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <CustomerRetention branches={this.props.branches}/>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
        <Col span={24}>
          <CustomerGrowthRateLayout branches={this.props.branches}/>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <TopSpend branches={this.props.branches}/>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default CustomerLayout;
