import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";

import PermissionForm from "./PermissionForm";

class NewPermission extends Component {
  createPermission = async (permission) => {
    this.props.createPermission(permission);
  };

  render() {
    const {
      permission,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <PermissionForm
        onSubmit={this.createPermission}
        permission={permission}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        readOnlyGroup={false}
        canEdit={true}
      />
    );
  }
}
const mapStateToProps = ({ permissions, branches }) => {
  return {
    permission: permissions.permission,
    alertMessage: permissions.alertMessage,
    errorMessage: permissions.errorMessage,
    loading: permissions.loading
  };
};

const mapDispatchToProps = {
  createPermission: actions.createPermission.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPermission);
