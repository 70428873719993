import {
  LOAD_TOPITEM,
} from "./actions";

const initialState = {
  message: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TOPITEM.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_TOPITEM.SUCCESS:
      return {
        ...state,
        service: action.payload.service.data, 
        product: action.payload.product.data, 
        stylish: action.payload.stylish.data, 
        emp: action.payload.emp.data, 
        customer: action.payload.customer.data,
        loading: false,
      };
    case LOAD_TOPITEM.FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
