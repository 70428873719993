import React, { Component, Fragment } from "react";
import { Layout as LayoutAnt } from "antd";
import { fetchGo } from 'lib/fetch'
import DashboardLayout from './DashboardLayout'

const { Content } = LayoutAnt;

class Layout extends Component {
  state = {
    branches: []
  }
  componentDidMount() {
    fetchGo.branches().then(res => {
      this.setState({ branches: res.data.data })
    })
  }

  render() {
    const { branches } = this.state;
    return (
      <Fragment>
        <DashboardLayout branches={branches} />
      </Fragment>
    );
  }
}

export default Layout;
