import {
  LOAD_SCHEDULE_REPORTS,
  LOAD_SCHEDULE_REPORT,
  CREATE_SCHEDULE_REPORT,
  EDIT_SCHEDULE_REPORT,
  DELETE_SCHEDULE_REPORT,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  scheduleReport: {
    name: "",
    report: "",
    time: "",
    subject: "",
    to: "",
    cc: ""
  },
  alertMessage: "",
  errorMessage: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SCHEDULE_REPORTS.REQUEST:
      return {
        ...state,
        scheduleReport: initialState.scheduleReport,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case LOAD_SCHEDULE_REPORTS.SUCCESS:
      return {
        ...state,
        data: action.payload.scheduleReports.data,
        loading: false
      };

    case LOAD_SCHEDULE_REPORT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case LOAD_SCHEDULE_REPORT.SUCCESS:
      return {
        ...state,
        scheduleReport: action.payload.scheduleReport.data,
        loading: false
      };

    case CREATE_SCHEDULE_REPORT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case CREATE_SCHEDULE_REPORT.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.scheduleReport.message,
        errorMessage: "",
        loading: false
      };

    case CREATE_SCHEDULE_REPORT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };

    case EDIT_SCHEDULE_REPORT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case EDIT_SCHEDULE_REPORT.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.scheduleReport.message,
        errorMessage: "",
        loading: false
      };

    case EDIT_SCHEDULE_REPORT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };

    case DELETE_SCHEDULE_REPORT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DELETE_SCHEDULE_REPORT.SUCCESS:
      const { scheduleReport } = action.payload
      return {
        ...state,
        data: state.data.filter(val => val.id !== scheduleReport),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DELETE_SCHEDULE_REPORT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

      case DESTROY_MANY.REQUEST:
        return {
          ...state,
          alertMessage: "",
          errorMessage: "",
          loading: true
        };
  
      case DESTROY_MANY.SUCCESS:
        const { data } = action.payload.data
        return {
          ...state,
          data: state.data.filter(val => !data.includes(val.id)),
          alertMessage: "success",
          totalSize: state.totalSize - 1,
          loading: false
        };
  
      case DESTROY_MANY.FAILURE:
        return {
          ...state,
          errorMessage: action.payload.error,
          loading: false
        };

    default:
      return state;
  }
};
