import React, { PureComponent } from "react";
import { Modal, Select, Row, Col, Input, Divider } from "antd";
import { numberFormat } from "lib/helper";

const Option = Select.Option;

export default class StockModal extends PureComponent {
  state = {
    bin_id: 1,
    quantity: null,
    validate: "",
  };

  handleOk = () => {
    const { data } = this.props;
    let value = {
      branch_id: data.branch_id,
      bin_id: this.state.bin_id,
      product_id: data.product_id,
      quantity: this.state.quantity,
    };
    this.handleClear();
    this.props.onSave(value);
  };

  handleClear = () => {
    this.setState({
      bin_id: 1,
      quantity: null,
      validate: "",
    });
  };

  handleCancel = () => {
    this.handleClear();
    this.props.onClose();
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeSelect = (val) => {
    this.setState({ bin_id: val });
  };

  handleValidate = () => {
    const { data } = this.props;
    const qty = parseInt(this.state.quantity);

    if (this.state.bin_id === 1) {
      if (qty > data.retail_stock) {
        this.setState({ validate: "Retail stock not enough to transfer !!" });
        return;
      }
    }

    if (this.state.bin_id === 2) {
      if (qty > data.salon_stock) {
        this.setState({ validate: "Salon stock not enough to transfer !!" });
        return;
      }
    }

    this.setState({ validate: "" });
    return;
  };

  getResults = (type) => {
    const { bin_id, quantity } = this.state
    const { data } = this.props;
    let retail = parseInt(data.retail_stock),
        salon = parseInt(data.salon_stock),
        qty = parseInt(quantity)

    if (type === "retail") {
      if(bin_id === 1) {
        return numberFormat(retail - qty, 0)
      } else {
        return numberFormat(retail + qty, 0)
      }
    }

    if (type === "salon") {
      if(bin_id === 2) {
        return numberFormat(salon - qty, 0)
      } else {
        return numberFormat(salon + qty, 0)
      }
    }
  }

  render() {
    const { visible, data } = this.props;
    return (
      <Modal
        title="Stock Management"
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        okButtonProps={{ disabled: this.state.validate !== "" }}
      >
        <Row gutter={[8, 24]}>
          <Col span={8}>Stock available</Col>
          <Col span={8}>Salon : {data.salon_stock}</Col>
          <Col span={8}>Retail : {data.retail_stock}</Col>
        </Row>
        <Row gutter={[8, 24]}>
          <Col span={8}>Exchange from</Col>
          <Col span={16}>
            <Select
              name="bin_id"
              value={this.state.bin_id}
              style={{ width: 200 }}
              onChange={this.handleChangeSelect}
              onBlur={this.handleValidate}
            >
              <Option value={1}>Retail to salon</Option>
              <Option value={2}>Salon to retail</Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={8}>Quantity</Col>
          <Col span={6}>
            <Input
              type="number"
              name="quantity"
              value={this.state.quantity}
              onBlur={this.handleValidate}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <span style={{ color: "red" }}>{this.state.validate}</span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[8, 16]}>
          <Col span={8}>Result</Col>
          <Col span={8}>Salon : {this.getResults("salon")}</Col>
          <Col span={8}>Retail : {this.getResults("retail")}</Col>
        </Row>
      </Modal>
    );
  }
}
