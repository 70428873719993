import {
  LOAD_CASH_PACKAGES,
  LOAD_CASH_PACKAGE,
  CREATE_CASH_PACKAGE,
  EDIT_CASH_PACKAGE,
  DELETE_CASH_PACKAGE,
  IMPORT_CASH_PACKAGE,
  DOWNLOAD_FILE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  cashPackage: {
    barcode: "",
    cash_package_name: "",
    cash: "",
    is_show: 1,
    price: "",
    price_before_vat: ""
  },
  branch_cash_packages: [],
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CASH_PACKAGES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case LOAD_CASH_PACKAGES.SUCCESS:
      return {
        ...state,
        data: action.payload.cashPackages.data,
        totalSize: action.payload.cashPackages.total_row,
        alertMessage: "",
        errorMessage: "",
        cashPackage: initialState.cashPackage,

        errorImport: "",
        loading: false
      };
    case LOAD_CASH_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case LOAD_CASH_PACKAGE.SUCCESS:
      return {
        ...state,
        cashPackage: action.payload.cashPackage.data,
        alertMessage: "",
        errorMessage: "",

        branch_cash_packages: action.payload.cashPackage.branch_cash_packages,
        errorImport: "",
        loading: false
      };
    case LOAD_CASH_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_CASH_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case CREATE_CASH_PACKAGE.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.cashPackage.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_CASH_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_CASH_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_CASH_PACKAGE.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.cashPackage.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_CASH_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case DELETE_CASH_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_CASH_PACKAGE.SUCCESS:
      const newCashPackages = state.data.filter(
        cashPackage =>
          cashPackage.cash_package_id !==
          action.payload.cashPackage.data.cash_package_id
      );
      return {
        ...state,
        data: newCashPackages,
        alertMessage: action.payload.cashPackage.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_CASH_PACKAGE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case IMPORT_CASH_PACKAGE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case IMPORT_CASH_PACKAGE.SUCCESS:
      return {
        ...state,
        data: action.payload.cashPackage.data,
        totalSize: action.payload.cashPackage.total_row,
        alertMessage: action.payload.cashPackage.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case IMPORT_CASH_PACKAGE.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",

        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.cash_package_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
