import { createTypes, createAction } from "lib/action";

const LOAD_REVENUE_SUMMARIES = createTypes("summary_revenue", "list");
const LOAD_REVENUE_GRAPH = createTypes("graph_revenue", "list");
const LOAD_REVENUE_BRANCH = createTypes("branch_revenue", "list");

const LOAD_REVENUE_PERTRANS_SUMMARIES = createTypes("summary_revenue_pertrans", "list")
const LOAD_REVENUE_PERTRANS_GRAPH = createTypes("graph_revenue_pertrans", "list")
const LOAD_REVENUE_PERTRANS_BRANCH = createTypes("branch_revenue_pertrans", "list")

const loadRevenueSummaries = {
  request: (
    type,
    duration,
    branch_id,
    query_type,
    category_id,
    withCompared = true
  ) =>
    createAction(LOAD_REVENUE_SUMMARIES.REQUEST, {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
      withCompared
    }),
  success: (revenue, compared) =>
    createAction(LOAD_REVENUE_SUMMARIES.SUCCESS, { revenue, compared }),
  failure: () => createAction(LOAD_REVENUE_SUMMARIES.FAILURE),
};

const loadGraphRevenue = {
  request: (
    type,
    duration,
    branch_id,
    query_type,
    category_id,
    withCompared = true
  ) =>
    createAction(LOAD_REVENUE_GRAPH.REQUEST, {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
      withCompared
    }),
  success: (revenue, compared) =>
    createAction(LOAD_REVENUE_GRAPH.SUCCESS, { revenue, compared }),
  failure: () => createAction(LOAD_REVENUE_GRAPH.FAILURE),
};

const loadBranchesRevenue = {
  request: (
    type,
    duration,
    branch_id,
    query_type,
    category_id,
  ) =>
    createAction(LOAD_REVENUE_BRANCH.REQUEST, {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
    }),
  success: (revenue, compared) =>
    createAction(LOAD_REVENUE_BRANCH.SUCCESS, { revenue, compared }),
  failure: () => createAction(LOAD_REVENUE_BRANCH.FAILURE),
};

/// REVENUE PER TRANSACTION ///

const loadRevenuePerTransSummaries = {
  request: (
    type,
    duration,
    branch_id,
    query_type,
    category_id,
  ) =>
    createAction(LOAD_REVENUE_PERTRANS_SUMMARIES.REQUEST, {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
    }),
  success: (revenue_t, compared_t) =>
    createAction(LOAD_REVENUE_PERTRANS_SUMMARIES.SUCCESS, { revenue_t, compared_t }),
  failure: () => createAction(LOAD_REVENUE_PERTRANS_SUMMARIES.FAILURE),
};

const loadGraphRevenuePerTrans = {
  request: (
    type,
    duration,
    branch_id,
    query_type,
    category_id,
  ) =>
    createAction(LOAD_REVENUE_PERTRANS_GRAPH.REQUEST, {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
    }),
  success: (revenue_gt, compared_gt) =>
    createAction(LOAD_REVENUE_PERTRANS_GRAPH.SUCCESS, { revenue_gt, compared_gt }),
  failure: () => createAction(LOAD_REVENUE_PERTRANS_GRAPH.FAILURE),
};

const loadBranchesRevenuePerTrans = {
  request: (
    type,
    duration,
    branch_id,
    query_type,
    category_id,
  ) =>
    createAction(LOAD_REVENUE_PERTRANS_BRANCH.REQUEST, {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
    }),
  success: (revenue_bt, compared_bt) =>
    createAction(LOAD_REVENUE_PERTRANS_BRANCH.SUCCESS, { revenue_bt, compared_bt }),
  failure: () => createAction(LOAD_REVENUE_PERTRANS_BRANCH.FAILURE),
};

export { 
  LOAD_REVENUE_SUMMARIES, 
  loadRevenueSummaries,
  LOAD_REVENUE_GRAPH,
  loadGraphRevenue,
  LOAD_REVENUE_BRANCH,
  loadBranchesRevenue,

  LOAD_REVENUE_PERTRANS_BRANCH,
  loadRevenuePerTransSummaries,
  LOAD_REVENUE_PERTRANS_GRAPH,
  loadGraphRevenuePerTrans,
  LOAD_REVENUE_PERTRANS_SUMMARIES,
  loadBranchesRevenuePerTrans,

};
