import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import ServiceGroupForm from "./ServiceGroupForm";
import { checkPermission } from "lib/helper";

class EditServiceGroup extends Component {
  state = {
    can_edit: checkPermission("actual_service_groups", "can_edit", false)
  };
  componentDidMount() {
    this.props.loadActualServiceGroup(this.props.match.params.service_group_id);
  }

  editActualServiceGroup = async serviceGRoup => {
    this.props.editActualServiceGroup(
      this.props.match.params.service_group_id,
      serviceGRoup.actual_service_group_code,
      serviceGRoup.actual_service_group
    );
  };

  render() {
    const {
      actualServiceGroup,
      alertMessage,
      errorMessage,
      loading
    } = this.props;
    return (
      <ServiceGroupForm
        onSubmit={this.editActualServiceGroup}
        serviceGroup={actualServiceGroup}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ actualServiceGroups }) => {
  return {
    actualServiceGroup: actualServiceGroups.actualServiceGroup,
    alertMessage: actualServiceGroups.alertMessage,
    errorMessage: actualServiceGroups.errorMessage,
    loading: actualServiceGroups.loading
  };
};

const mapDispatchToProps = {
  loadActualServiceGroup: actions.loadActualServiceGroup.request,
  editActualServiceGroup: actions.editActualServiceGroup.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditServiceGroup);
