import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import * as actions from "../actions";
import { loadActualServiceTypes } from "modules/actual-service/components/actual_service_type/actions";

import ActualServiceForm from "./ActualServiceForm";
import { checkPermission } from "lib/helper";

const { confirm } = Modal;

class EditActualService extends Component {
  state = {
    can_edit: checkPermission(
      "actual_service_actual_services",
      "can_edit",
      false
    ),
    branch: [],
  };

  componentDidMount() {
    this.props.loadActualService(this.props.match.params.actual_service_id);
    this.props.loadActualServiceTypes();
  }

  checkAlert = (checkedList) => {
    let mustAlert = false;
    this.props.branch_services.map((val) => {
      checkedList.map((data) => {
        if (data.name === val.branch_id && data.value === false) {
          mustAlert = true;
          return false;
        }
      });
    });

    return mustAlert;
  };

  showConfirm = (ActualService, checkedList) => {
    let that = this;
    confirm({
      title: "Do you want to unchecked these branches?",
      icon: <ExclamationCircleOutlined />,
      content: "If you uncheck branches, the package that related to this service will be removed from branches that unchecked automatically.",
      onOk() {
        that.props.editActualService(
          that.props.match.params.actual_service_id,
          ActualService,
          checkedList
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  editActualService = async (ActualService, checkedList) => {
    let alerts = this.checkAlert(checkedList);
    if (alerts) {
      this.showConfirm(ActualService, checkedList);
    } else {
      this.props.editActualService(
        this.props.match.params.actual_service_id,
        ActualService,
        checkedList
      );
    }
  };

  render() {
    const {
      actualService,
      actualServiceTypes,
      alertMessage,
      errorMessage,
      branches,
      branch_services,
    } = this.props;

    const data = [];
    branches.map((branch) => {
      const obj = {};
      obj.name = branch.branch_id;
      const checkBranch = branch_services.filter((b) => {
        if (b.branch_id === branch.branch_id) return b;
      });
      if (checkBranch.length > 0) obj.value = true;
      else obj.value = false;
      return data.push(obj);
    });

    return (
      <ActualServiceForm
        onSubmit={this.editActualService}
        actualService={actualService}
        actualServiceTypes={actualServiceTypes}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        branches={branches}
        checkedList={data}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({
  actualServices,
  actualServiceTypes,
  branches,
}) => {
  return {
    actualService: actualServices.actualService,
    actualServiceTypes: actualServiceTypes.data,
    alertMessage: actualServices.alertMessage,
    errorMessage: actualServices.errorMessage,
    branches: branches.data,
    branch_services: actualServices.branch_services,
  };
};

const mapDispatchToProps = {
  loadActualService: actions.loadActualService.request,
  editActualService: actions.editActualService.request,
  loadActualServiceTypes: loadActualServiceTypes.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditActualService);
