import React from "react";
import { Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { extractKeyFromArray , } from "lib/helper";
const { confirm } = Modal;

const DeleteListModal = props => {
  const showConfirm = () => {
    let that = props;
    confirm({
      title: "Do you want to delete these items?",
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content:
        "When clicked the OK button, these items will be deleted from database",
      onOk() {
        let id = extractKeyFromArray (that.data, "product_id");
        that.onRemove(id);
      },
      onCancel() {}
    });
  };

  const removeItem = id => () => {
    props.removeItem(id);
  };

  const { displayKey } = props;
  return (
    <Modal
      title="Delete Lists"
      closable={false}
      visible={props.visible}
      okButtonProps={{ disabled: props.data.length === 0 }}
      okText={`Delete ${ props.data.length } items`}
      cancelText="Close"
      onOk={showConfirm}
      onCancel={props.onClose("visibleDelete")}
      bodyStyle={{ height: "250px", overflowY: "scroll" }}
    >
      <table className="delete-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Barcode</th>
            <th>Name</th>
            <th><Button onClick={props.removeAllItem} disabled={props.data.length === 0}>Undo all</Button></th>
          </tr>
        </thead>
        <tbody>
          { props.data.map((item, i) => {
          return <tr key={i}>
            <td>{i+1}</td>
            <td>{item[displayKey[1]]}</td>
            <td>{item[displayKey[2]]}</td>
            <td><Button type="link" onClick={removeItem(item[displayKey[0]])}>Undo</Button></td>
          </tr>
          }) }
        </tbody>
      </table>
      {/* <List
        itemLayout="horizontal"
        dataSource={props.data}
        renderItem={item => (
          <List.Item
            actions={[
              <Button type="link" onClick={removeItem(item[displayKey[0]])}>Undo</Button>
            ]}
          >
            <List.Item.Meta
              title={item[displayKey[1]]}
              description={item[displayKey[2]]}
            />
          </List.Item>
        )}
      /> */}
    </Modal>
  );
};

export default DeleteListModal;
