import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "antd";
import { numberFormat } from 'lib/helper'

const data = [
    { branch: "EMBASSY" , amount: 79275.00, qty: 54, cost: 900.00 },
    { branch: "EMQUARTIER" , amount: 19125.00, qty: 13, cost: 900.00 },
    { branch: "ICONSIAM" , amount: 18975.00, qty: 13, cost: 900.00 },
    { branch: "HQ" , amount: 9750.00, qty: 7, cost: 900.00 },
];

export class DetailTable extends Component {
  render() {

    const columns = [
      {
        title: "#",
        dataIndex: "",
        render: (val, rec, idx) => idx + 1
      },
      {
        title: "Branch",
        dataIndex: "branch",
      },
      {
        title: "Q'ty",
        width: 120,
        dataIndex: "qty",
        className: "align-right",
        sorter: (a, b) => a.age - b.age,
      },
      {
        title: "Cost",
        width: 120,
        dataIndex: "cost",
        className: "align-right",
        sorter: (a, b) => a.age - b.age,
        render: (val, rec) => numberFormat(val * rec.qty)
      },
      {
        title: "Revenue",
        width: 120,
        dataIndex: "amount",
        className: "align-right",
        sorter: (a, b) => a.age - b.age,
        render: (val) => numberFormat(val)
      },
      {
        title: "Gross Profit",
        width: 120,
        className: "align-right",
        dataIndex: "amount",
        sorter: (a, b) => a.age - b.age,
        render: (val, rec) => numberFormat(val - (rec.cost * rec.qty))
      },
    ];

    return (
      <div>
        <Table columns={columns} dataSource={data} size="small" bordered />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTable);
