import {
  LOAD_BRANCH_CATEGORIES,
  IMPORT_BRANCH_HAS_CATEGORY,
  DOWNLOAD_FILE,
  EDIT_BRANCH_HAS_CATEGORY,
  DELETE_BRANCH_HAS_CATEGORY,
  DESTROY_MANY
} from "./actions";

const initialState = {
  alertMessage: "",
  errorMessage: "",
  data: [],
  totalSize: 0,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BRANCH_CATEGORIES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case LOAD_BRANCH_CATEGORIES.SUCCESS:
      return {
        ...state,
        data: action.payload.branchCategories.data,
        totalSize: action.payload.branchCategories.total_row,
        alertMessage: "",
        errorMessage: "",
        loading: false
      };
    case LOAD_BRANCH_CATEGORIES.FAILURE:
      return { ...state, alertMessage: "", errorMessage: "", loading: false };

    case IMPORT_BRANCH_HAS_CATEGORY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case IMPORT_BRANCH_HAS_CATEGORY.SUCCESS:
      return {
        ...state,
        data: action.payload.branchCategory.data,
        totalSize: action.payload.branchCategory.total_row,
        alertMessage: action.payload.branchCategory.message,
        errorMessage: "",
        loading: false
      };
    case IMPORT_BRANCH_HAS_CATEGORY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case EDIT_BRANCH_HAS_CATEGORY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_BRANCH_HAS_CATEGORY.SUCCESS:
      return {
        ...state,
        data: action.payload.categories.data,
        alertMessage: action.payload.categories.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_BRANCH_HAS_CATEGORY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };

    case DELETE_BRANCH_HAS_CATEGORY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_BRANCH_HAS_CATEGORY.SUCCESS:
      // console.log("action.payload", action.payload)
      const { branch_id, id } = action.payload.category.data

      const newBranches = state.data.filter(
        x => !(parseInt(x.branch_id) === parseInt(branch_id) && parseInt(x.product_category_id) === parseInt(id))
      );
      return {
        ...state,
        data: newBranches,
        alertMessage: action.payload.category.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_BRANCH_HAS_CATEGORY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.branch_has_category_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };
    default:
      return state;
  }
};
