import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import BrandForm from "./BrandForm";
import { checkPermission } from "lib/helper";

class EditBrand extends Component {
  state = {
    can_edit: checkPermission("product_brands", "can_edit", false)
  };
  componentDidMount() {
    this.props.loadBrand(this.props.match.params.product_brand_id);
  }

  editBrand = async brand => {
    this.props.editBrand(
      this.props.match.params.product_brand_id,
      brand.product_brand_code,
      brand.product_brand
    );
  };

  render() {
    const { brand, alertMessage, errorMessage, loading } = this.props;

    return (
      <BrandForm
        onSubmit={this.editBrand}
        brand={brand}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ brands }) => {
  return {
    brand: brands.brand,
    alertMessage: brands.alertMessage,
    errorMessage: brands.errorMessage,
    loading: brands.loading
  };
};

const mapDispatchToProps = {
  loadBrand: actions.loadBrand.request,
  editBrand: actions.editBrand.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBrand);
