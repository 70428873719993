import { createTypes, createAction } from "lib/action";

const LOAD_CUSTOMER_PACKAGES = createTypes("customerpackages", "list");
const DOWNLOAD_FILE = createTypes("customerpackages", "export");

const loadCustomerPackages = {
  request: (pagination, limit, cond, sort) =>
    createAction(LOAD_CUSTOMER_PACKAGES.REQUEST, { pagination, limit, cond, sort }),
  success: customers => createAction(LOAD_CUSTOMER_PACKAGES.SUCCESS, { customers }),
  failure: error => createAction(LOAD_CUSTOMER_PACKAGES.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: (error) => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

export {
  LOAD_CUSTOMER_PACKAGES,
  loadCustomerPackages,
  DOWNLOAD_FILE,
  download
};
