import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import EmployeeTypeForm from "./EmployeeTypeForm";

class NewEmployeeType extends Component {
  createEmployeeType = async employeeType => {
    this.props.createEmployeeType(employeeType);
  };

  render() {
    const { employeeType, alertMessage, errorMessage, loading } = this.props;

    return (
      <EmployeeTypeForm
        onSubmit={this.createEmployeeType}
        employeeType={employeeType}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ employeeTypes }) => {
  return {
    employeeType: employeeTypes.employeeType,
    alertMessage: employeeTypes.alertMessage,
    errorMessage: employeeTypes.errorMessage,
    loading: employeeTypes.loading
  };
};

const mapDispatchToProps = {
  createEmployeeType: actions.createEmployeeType.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewEmployeeType);
