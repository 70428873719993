import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadNationalities(action) {
  try {
    const { pagination, limit, keyword,  sort } = action.payload;
    const res = yield fetcher.post(`masterdata/nationalities`, {
      pagination,
      limit,
      keyword,
      sort
    });
    yield put(actions.loadNationalities.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadNationality(action) {
  try {
    const { nationalityId } = action.payload;
    const res = yield fetcher.get(`nationalities/${nationalityId}`);
    yield put(actions.loadNationality.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* createNationality(action) {
  try {
    const { nationality } = action.payload;
    const res = yield fetcher.post(`nationalities`, nationality);
    yield put(actions.createNationality.success(res.data));
    yield put(push("/customer/nationalities"));
  } catch (error) {
    handleError(error);
    yield put(actions.createNationality.failure(getErrorMsg(error)));
  }
}

function* editNationality(action) {
  try {
    const { id, nationality } = action.payload;
    const res = yield fetcher.patch(`nationalities/${id}`, nationality);

    yield put(actions.editNationality.success(res.data));
    yield put(push("/customer/nationalities"));
  } catch (error) {
    getErrorMsg(error);
    yield put(actions.editNationality.failure(getErrorMsg(error)));
  }
}
function* deleteNationality(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`nationalities/${id}`);
    yield put(actions.deleteNationality.success(res.data));
  } catch (error) {
    getErrorMsg(error);
    yield put(actions.deleteNationality.failure(getErrorMsg(error)));
  }
}

function* upload(action) {
  try {
    const { file, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", "nationality");
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);

    const res = yield fetcher.post(`import/details`, formData);

    yield put(actions.upload.success(res.data));
  } catch (error) {
    getErrorMsg(error);
    yield put(actions.upload.failure(getErrorMsg(error)));
    yield put(push("/customer/nationalities"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post(`export/export_masterdata`, {
      type: "nationality"
    });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    getErrorMsg(error);
    yield put(actions.upload.failure(getErrorMsg(error).data));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/customer/nationality`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchNationalities() {
  yield all([
    takeEvery(actions.LOAD_NATIONALITIES.REQUEST, loadNationalities),
    takeEvery(actions.LOAD_NATIONALITY.REQUEST, loadNationality),
    takeEvery(actions.CREATE_NATIONALITY.REQUEST, createNationality),
    takeEvery(actions.EDIT_NATIONALITY.REQUEST, editNationality),
    takeEvery(actions.DELETE_NATIONALITY.REQUEST, deleteNationality),
    takeEvery(actions.UPLOAD_FILE.REQUEST, upload),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
