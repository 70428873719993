import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import CategoryForm from "./CategoryForm";

import { checkPermission } from "lib/helper";

class EditCategory extends Component {
  state = {
    can_edit: checkPermission("product_categories", "can_edit", false)
  };

  componentDidMount() {
    this.props.loadCategory(this.props.match.params.category_id);
  }

  editCategory = async category => {
    this.props.editCategory(
      this.props.match.params.category_id,
      category.category_code,
      category.category_name
    );
  };

  render() {
    const { category, alertMessage, errorMessage, loading } = this.props;

    return (
      <CategoryForm
        onSubmit={this.editCategory}
        category={category}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ categories }) => {
  return {
    category: categories.category,
    alertMessage: categories.alertMessage,
    errorMessage: categories.errorMessage,
    loading: categories.loading
  };
};

const mapDispatchToProps = {
  loadCategory: actions.loadCategory.request,
  editCategory: actions.editCategory.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCategory);
