import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadBranchCashPackages(action) {
  try {
    const { branchId, pagination, limit, keyword, sort } = action.payload;
    const res = yield fetcher.post(`masterdata/cashpackages`, {
      branch_id: branchId,
      pagination,
      limit,
      keyword,
      sort
    });
    yield put(actions.loadBranchCashPackages.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* importBranchHasCashPackage(action) {
  try {
    const { file, type, pagination, limit, keyword, branchId } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    formData.append("branch_id", branchId);

    const res = yield fetcher.post(`import/branch_has`, formData);
    yield put(actions.importBranchHasCashPackage.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importBranchHasCashPackage.failure(getErrorMsg(error)));
    yield put(push("/branch/cash-packages"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post(`export/branch_has/cashPackage`, {
      branch_id: action.payload.branch_id
    });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function* editBranchHasCashPackage(action) {
  try {
    const { branch_id, cashpackage } = action.payload;
    const res = yield fetcher.post(`branch_has/create/cashPackage/${branch_id}`,cashpackage);
    yield put(actions.editBranchHasCashPackage.success(res.data));
    yield put(push("/branch/cash-packages"));
  } catch (error) {
    handleError(error);
    yield put(actions.editBranchHasCashPackage.failure(getErrorMsg(error)));
    yield put(push("/branch/cash-packages/new"));
  }
}

function* deleteBranchHasCashPackage(action) {
  try {
    const { id, branch_id } = action.payload;
    const res = yield fetcher.delete(
      `branch_has/delete/cashPackage/${branch_id}/${id}`
    );
    yield put(actions.deleteBranchHasCashPackage.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteBranchHasCashPackage.failure(getErrorMsg(error)));
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/branch_has/cashPackage`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchBranchCashPackages() {
  yield all([
    takeEvery(
      actions.LOAD_BRANCH_CASH_PACKAGES.REQUEST,
      loadBranchCashPackages
    ),
    takeEvery(
      actions.IMPORT_BRANCH_HAS_CASH_PACKAGE.REQUEST,
      importBranchHasCashPackage
    ),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(
      actions.EDIT_BRANCH_HAS_CASH_PACKAGE.REQUEST,
      editBranchHasCashPackage
    ),
    takeEvery(
      actions.DELETE_BRANCH_HAS_CASH_PACKAGE.REQUEST,
      deleteBranchHasCashPackage
    ),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
