import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import { loadBranches } from "lib/select-option";

import TerminalForm from "./TerminalForm";

class NewTerminal extends Component {
  componentDidMount() {
    this.props.loadBranches();
  }

  createTerminal = async terminal => {
    this.props.createTerminal(terminal);
  };

  render() {
    const {
      terminal,
      branches,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <TerminalForm
        onSubmit={this.createTerminal}
        terminal={terminal}
        branches={branches}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({ branches, branchTerminals }) => {
  return {
    branches: branches.data,
    terminal: branchTerminals.terminal,
    alertMessage: branchTerminals.alertMessage,
    errorMessage: branchTerminals.errorMessage,
    loading: branchTerminals.loading
  };
};

const mapDispatchToProps = {
  createTerminal: actions.createTerminal.request,
  loadBranches: loadBranches.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTerminal);
