import React from "react";
import { Switch } from "react-router-dom";

import CashPackageList from "./CashPackageList";
import NewCashPackage from "./NewCashPackage";
import EditCashPackage from "./EditCashPackage";
import { PrivateRoute } from "lib/widget";

export default () => (
  <Switch>
    <PrivateRoute
      path="/cash-package/packages/new"
      component={NewCashPackage}
      perm="cash_package_cash_packages"
      action="can_add"
    />
    <PrivateRoute
      path="/cash-package/packages/edit/:cash_package_id"
      component={EditCashPackage}
      perm="cash_package_cash_packages"
      action="can_edit"
    />
    <PrivateRoute
      path="/cash-package/packages"
      component={CashPackageList}
      perm="cash_package_cash_packages"
    />
  </Switch>
);
