import React, { Component, Fragment } from "react";
import { DrawerFilter } from "lib/widget";
import * as moment from "moment";
import { DatePicker, Col, Row, Input, Typography } from "antd";
import {
  getSearch,
} from "lib/helper";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const initialState = {
  purchase_date: {
    start: null,
    end: null,
  },
  expiration_date: {
    start: null,
    end: null,
  },
  barcode: "",
  service_package_name: "",
};

const { Text } = Typography;

class Filter extends Component {
  state = initialState;

  handleChangeInput = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };

  handleSearch = () => {
    let {
      barcode,
      service_package_name,
      purchase_date,
      expiration_date,
    } = this.state;
    this.props.handleSearch({
      barcode,
      service_package_name,
      purchase_date,
      expiration_date,
    });
  };

  onDateRangePurchaseChange = (date, dateString) => {
    let startDate = moment(dateString[0]).utc().format("YYYY-MM-DD");

    let endDate = moment(dateString[1]).utc().format("YYYY-MM-DD");

    this.setState({ purchase_date: { start: startDate, end: endDate } });
  };

  onDateRangeExpChange = (date, dateString) => {
    let startDate = moment(dateString[0]).utc().format("YYYY-MM-DD");

    let endDate = moment(dateString[1]).utc().format("YYYY-MM-DD");

    this.setState({ expiration_date: { start: startDate, end: endDate } });
  };

  handleClearFilter = () => {
    this.setState(initialState);
  };

  componentDidMount() {
    let search = getSearch()

    if (typeof search === 'object') {
      this.setState(search)
    }
  }

  render() {
    const { visible, handleDrawerClose } = this.props;
    const { purchase_date, expiration_date } = this.state;
    let startPur =
      purchase_date.start !== null
        ? moment(purchase_date.start, dateFormat)
        : purchase_date.start;
    let endPur =
      purchase_date.end !== null
        ? moment(purchase_date.end, dateFormat)
        : purchase_date.end;
    let startExp =
      expiration_date.start !== null
        ? moment(expiration_date.start, dateFormat)
        : expiration_date.start;
    let endExp =
      expiration_date.end !== null
        ? moment(expiration_date.end, dateFormat)
        : expiration_date.end;

    return (
      <DrawerFilter
        onSearch={this.handleSearch}
        visible={visible}
        onClear={this.handleClearFilter}
        onClose={handleDrawerClose}
      >
        <Fragment>
          <Row gutter={[0, 8]}>
            <Col span={22}>
              <Text strong>Purchase Date</Text>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                value={[startPur, endPur]}
                onChange={this.onDateRangePurchaseChange}
              />
            </Col>
          </Row>
        </Fragment>
        <Fragment>
          <Row gutter={[0, 8]}>
            <Col span={22}>
              <Text strong>Expired Date</Text>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                value={[startExp, endExp]}
                onChange={this.onDateRangeExpChange}
              />
            </Col>
          </Row>
        </Fragment>
        <Fragment>
          <Row gutter={[0, 8]}>
            <Col span={22}>
              <Text strong>Barcode</Text>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <Input
                value={this.state.barcode}
                onChange={this.handleChangeInput("barcode")}
              />
            </Col>
          </Row>
        </Fragment>
        <Fragment>
          <Row gutter={[0, 8]}>
            <Col span={22}>
              <Text strong>Package Name</Text>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <Input
                value={this.state.service_package_name}
                onChange={this.handleChangeInput("service_package_name")}
              />
            </Col>
          </Row>
        </Fragment>
      </DrawerFilter>
    );
  }
}

export default Filter;
