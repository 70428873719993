import React from 'react'
import { Col, Row, Typography } from "antd";

const { Text } = Typography

const LabelFilter = ({ text }) => (
    <Row gutter={[0, 8]}>
      <Col span={22}>
        <Text strong>{text}</Text>
      </Col>
    </Row>
  );

export default LabelFilter
