import { createTypes, createAction } from "lib/action";

const LOAD_ACTUAL_SERVICES = createTypes("actualServices", "list");
const LOAD_ACTUAL_SERVICE = createTypes("actualServices", "show");
const CREATE_ACTUAL_SERVICE = createTypes("actualServices", "create");
const EDIT_ACTUAL_SERVICE = createTypes("actualServices", "edit");
const DELETE_ACTUAL_SERVICE = createTypes("actualServices", "delete");
const IMPORT_ACTUAL_SERVICE = createTypes("actualServices", "import");
const DOWNLOAD_FILE = createTypes("actualServices", "export");
const DESTROY_MANY = createTypes("actualServices", "destory");

const loadActualServices = {
  request: (pagination, limit, cond, sort) =>
    createAction(LOAD_ACTUAL_SERVICES.REQUEST, {
      pagination,
      limit,
      cond,
      sort
    }),
  success: actualServices =>
    createAction(LOAD_ACTUAL_SERVICES.SUCCESS, { actualServices }),
  failure: (error) => createAction(LOAD_ACTUAL_SERVICES.FAILURE, { error })
};

const loadActualService = {
  request: actualServiceId =>
    createAction(LOAD_ACTUAL_SERVICE.REQUEST, { actualServiceId }),
  success: actualService =>
    createAction(LOAD_ACTUAL_SERVICE.SUCCESS, { actualService }),
  failure: (error) => createAction(LOAD_ACTUAL_SERVICE.FAILURE, { error })
};

const createActualService = {
  request: (actualService, checkedList) =>
    createAction(CREATE_ACTUAL_SERVICE.REQUEST, { actualService, checkedList }),
  success: actualService =>
    createAction(CREATE_ACTUAL_SERVICE.SUCCESS, { actualService }),
  failure: error => createAction(CREATE_ACTUAL_SERVICE.FAILURE, { error })
};

const editActualService = {
  request: (id, actualService, checkedList) =>
    createAction(EDIT_ACTUAL_SERVICE.REQUEST, {
      id,
      actualService,
      checkedList
    }),
  success: actualService =>
    createAction(EDIT_ACTUAL_SERVICE.SUCCESS, { actualService }),
  failure: error => createAction(EDIT_ACTUAL_SERVICE.FAILURE, { error })
};

const deleteActualService = {
  request: id => createAction(DELETE_ACTUAL_SERVICE.REQUEST, { id }),
  success: actualService =>
    createAction(DELETE_ACTUAL_SERVICE.SUCCESS, { actualService }),
  failure: error => createAction(DELETE_ACTUAL_SERVICE.FAILURE, { error })
};

const importActualService = {
  request: (file, type, pagination, limit, keyword, adjustPrice) =>
    createAction(IMPORT_ACTUAL_SERVICE.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword,
      adjustPrice
    }),
  success: actualService =>
    createAction(IMPORT_ACTUAL_SERVICE.SUCCESS, { actualService }),
  failure: error => createAction(IMPORT_ACTUAL_SERVICE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_ACTUAL_SERVICES,
  loadActualServices,
  LOAD_ACTUAL_SERVICE,
  loadActualService,
  CREATE_ACTUAL_SERVICE,
  createActualService,
  EDIT_ACTUAL_SERVICE,
  editActualService,
  DELETE_ACTUAL_SERVICE,
  deleteActualService,
  IMPORT_ACTUAL_SERVICE,
  importActualService,
  DOWNLOAD_FILE,
  download,
  DESTROY_MANY,
  destroyMany
};
