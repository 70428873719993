import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import NationalityForm from "./NationalityForm";
import { checkPermission } from "lib/helper";

class EditNationality extends Component {
  state = {
    can_edit: checkPermission("customer_nationalities", "can_edit", false)
  };

  componentDidMount() {
    this.props.loadNationality(this.props.match.params.nationality_id);
  }

  editNationality = async nationality => {
    this.props.editNationality(
      this.props.match.params.nationality_id,
      nationality
    );
  };

  render() {
    const { nationality, alertMessage, errorMessage, loading } = this.props;

    return (
      <NationalityForm
        onSubmit={this.editNationality}
        nationality={nationality}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ nationalities }) => {
  return {
    nationality: nationalities.nationality,
    alertMessage: nationalities.alertMessage,
    errorMessage: nationalities.errorMessage,
    loading: nationalities.loading
  };
};

const mapDispatchToProps = {
  loadNationality: actions.loadNationality.request,
  editNationality: actions.editNationality.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNationality);
