import { createTypes, createAction } from "lib/action";

const LOAD_EMPLOYEES = createTypes("employees", "list");
const LOAD_EMPLOYEE = createTypes("employees", "show");
const CREATE_EMPLOYEE = createTypes("employees", "create");
const EDIT_EMPLOYEE = createTypes("employees", "edit");
const DELETE_EMPLOYEE = createTypes("employees", "delete");
const IMPORT_EMPLOYEE = createTypes("employees", "import");
const DOWNLOAD_FILE = createTypes("employees", "export");
const DESTROY_MANY = createTypes("employees", "destory");

const loadEmployees = {
  request: (pagination, limit, cond, sort) =>
    createAction(LOAD_EMPLOYEES.REQUEST, { pagination, limit, cond, sort }),
  success: employees => createAction(LOAD_EMPLOYEES.SUCCESS, { employees }),
  failure: () => createAction(LOAD_EMPLOYEES.FAILURE)
};

const loadEmployee = {
  request: employeeId => createAction(LOAD_EMPLOYEE.REQUEST, { employeeId }),
  success: employee => createAction(LOAD_EMPLOYEE.SUCCESS, { employee }),
  failure: error => createAction(LOAD_EMPLOYEE.FAILURE, { error })
};

const createEmployee = {
  request: (
    employee,
    checkedList
  ) =>
    createAction(CREATE_EMPLOYEE.REQUEST, {
      employee,
      checkedList
    }),
  success: employee => createAction(CREATE_EMPLOYEE.SUCCESS, { employee }),
  failure: error => createAction(CREATE_EMPLOYEE.FAILURE, { error })
};

const editEmployee = {
  request: (
    employeeId,
    employee,
    checkedList
  ) =>
    createAction(EDIT_EMPLOYEE.REQUEST, {
      employeeId,
      employee,
      checkedList
    }),
  success: employee => createAction(EDIT_EMPLOYEE.SUCCESS, { employee }),
  failure: error => createAction(EDIT_EMPLOYEE.FAILURE, { error })
};

const deleteEmployee = {
  request: id => createAction(DELETE_EMPLOYEE.REQUEST, { id }),
  success: employee => createAction(DELETE_EMPLOYEE.SUCCESS, { employee }),
  failure: error => createAction(DELETE_EMPLOYEE.FAILURE, { error })
};

const importEmployee = {
  request: (file, type, pagination, limit, keyword) =>
    createAction(IMPORT_EMPLOYEE.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword
    }),
  success: employee => createAction(IMPORT_EMPLOYEE.SUCCESS, { employee }),
  failure: error => createAction(IMPORT_EMPLOYEE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: () => createAction(DOWNLOAD_FILE.FAILURE)
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}


export {
  LOAD_EMPLOYEES,
  loadEmployees,
  LOAD_EMPLOYEE,
  loadEmployee,
  CREATE_EMPLOYEE,
  createEmployee,
  EDIT_EMPLOYEE,
  editEmployee,
  DELETE_EMPLOYEE,
  deleteEmployee,
  IMPORT_EMPLOYEE,
  importEmployee,
  DOWNLOAD_FILE,
  download,
  DESTROY_MANY,
  destroyMany
};
