import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import { loadActualServiceTypes } from "modules/actual-service/components/actual_service_type/actions";

import ActualServiceForm from "./ActualServiceForm";

class NewActualService extends Component {
  componentDidMount() {
    this.props.loadActualServiceTypes();
  }

  createActualService = async (actualService, checkedList) => {
    this.props.createActualService(actualService, checkedList);
  };

  render() {
    const {
      actualServiceTypes,
      actualService,
      alertMessage,
      errorMessage,
      branches
    } = this.props;

    const data = [];
    branches.map(branch => {
      const obj = {};
      obj.name = branch.branch_id;
      obj.value = true;
      return data.push(obj);
    });

    return (
      <ActualServiceForm
        onSubmit={this.createActualService}
        actualService={actualService}
        actualServiceTypes={actualServiceTypes}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        branches={branches}
        checkedList={data}
        canEdit={true}
      />
    );
  }
}

const mapStateToProps = ({
  actualServiceTypes,
  actualServices,
  branches
}) => {
  return {
    actualServiceTypes: actualServiceTypes.data,
    alertMessage: actualServices.alertMessage,
    errorMessage: actualServices.errorMessage,
    actualService: actualServices.actualService,
    branches: branches.data
  };
};

const mapDispatchToProps = {
  createActualService: actions.createActualService.request,
  loadActualServiceTypes: loadActualServiceTypes.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewActualService);
