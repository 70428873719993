import React from "react";
import { Switch } from "react-router-dom";

import CustomerPackageList from "./CustomerPackageList";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute path="/customer/packages" component={CustomerPackageList} perm="customer_packages" />
  </Switch>
);