import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadCustomers(action) {
  try {
    const { pagination, limit, cond, sort } = action.payload;
    const res = yield fetcher.post(`masterdata/customers`, {
      pagination,
      limit,
      withPackage: true,
      cond,
      sort
    });
    yield put(actions.loadCustomers.success(res.data));
  } catch (error) {
    getErrorMsg(error);
  }
}

function* loadCustomer(action) {
  try {
    const { customerId } = action.payload;
    const res = yield fetcher.get(`customers/${customerId}`);
    yield put(actions.loadCustomer.success(res.data));
  } catch (error) {
    getErrorMsg(error);
  }
}

function* createCustomer(action) {
  try {
    const { customer } = action.payload;
    const res = yield fetcher.post(`customers`, {
      customer_code: customer.customer_code,
      name: customer.name,
      gender: customer.gender,
      nationality_id: customer.nationality_id,
      tel1: customer.tel1,
      tel2: customer.tel2,
      tel3: customer.tel3,
      tel4: customer.tel4,
      tel5: customer.tel5,
      email: customer.email,
      birthday: customer.birthday,
      address: customer.address,
      hair_volumn: customer.hair_volumn,
      hair_size: customer.hair_size,
      notes: customer.notes,
      created_by: "website"
    });
    yield put(actions.createCustomer.success(res.data));
    yield put(push("/customer/customers"));
  } catch (error) {
    getErrorMsg(error);
    yield put(actions.createCustomer.failure(getErrorMsg(error)));
  }
}

function* editCustomer(action) {
  try {
    const { id, customer } = action.payload;

    const res = yield fetcher.patch(`customers/${id}`, {
      customer_code: customer.customer_code,
      name: customer.name,
      gender: customer.gender,
      nationality_id: customer.nationality_id,
      tel1: customer.tel1,
      tel2: customer.tel2,
      tel3: customer.tel3,
      tel4: customer.tel4,
      tel5: customer.tel5,
      email: customer.email,
      birthday: customer.birthday,
      address: customer.address,
      hair_volumn: customer.hair_volumn,
      hair_size: customer.hair_size,
      notes: customer.notes,
      created_by: "website"
    });
    yield put(actions.editCustomer.success(res.data));
    yield put(push("/customer/customers"));
  } catch (error) {
    handleError(error);
    yield put(actions.editCustomer.failure(getErrorMsg(error)));
  }
}

function* deleteCustomer(action) {
  try {
    const { id } = action.payload;
    const res = yield fetcher.delete(`customers/${id}`);
    yield put(actions.deleteCustomer.success(res.data));
    yield put(push("/customer/customers"));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteCustomer.failure(getErrorMsg(error)));
  }
}

function* importCustomer(action) {
  try {
    const { file, type, pagination, limit, keyword } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);

    const res = yield fetcher.post(`import/details`, formData);
    yield put(actions.importCustomer.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importCustomer.failure(getErrorMsg(error)));
    yield put(push("/customer/customers"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/export_masterdata", { type: "customer" });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function* loadTransactions(action) {
  try {
    const { customer_id, pagination, limit, keyword } = action.payload;
    const res = yield fetcher.post(`customers/transactions`, {
      customer_id,
      pagination,
      limit,
      keyword
    });
    yield put(actions.loadTransactions.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/customer`, { id: data })
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchCustomers() {
  yield all([
    takeEvery(actions.LOAD_CUSTOMERS.REQUEST, loadCustomers),
    takeEvery(actions.LOAD_CUSTOMER.REQUEST, loadCustomer),
    takeEvery(actions.CREATE_CUSTOMER.REQUEST, createCustomer),
    takeEvery(actions.EDIT_CUSTOMER.REQUEST, editCustomer),
    takeEvery(actions.DELETE_CUSTOMER.REQUEST, deleteCustomer),
    takeEvery(actions.IMPORT_CUSTOMER.REQUEST, importCustomer),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.LOAD_CUSTOMER_TRANSACTION.REQUEST, loadTransactions),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
