import {
  LOAD_APPOINTMENT,
  LOAD_APPOINTMENT_BRANCH,
} from "./actions";

const initialState = {
  loading_appoint: false,
  appoint_graph: [],
  appoint_compared_graph: [],
  loading_appoint_branch: false,
  appoint_branches_revenue: [],
  appoint_branches_compared: [],
};

export default (state = initialState, action) => {
  switch (action.type) {

    case LOAD_APPOINTMENT.REQUEST:
      return {
        ...state,
        loading_appoint: true,
      };
    case LOAD_APPOINTMENT.SUCCESS:
        return {
          ...state,
          appoint_graph: action.payload.revenue.data,
          summary: action.payload.revenue.summary,
          use_package: action.payload.revenue.use_package,
          loading_appoint: false,
        };
    case LOAD_APPOINTMENT.FAILURE:
      return {
        ...state,
        loading_appoint: false,
      };

    case LOAD_APPOINTMENT_BRANCH.REQUEST:
      return {
        ...state,
        loading_appoint_branch: true,
      };
    case LOAD_APPOINTMENT_BRANCH.SUCCESS:
      return {
        ...state,
        loading_appoint_branch: false,
        appoint_branches_revenue: action.payload.revenue.data,
        appoint_branches_compared: action.payload.compared.data,
      };
    case LOAD_APPOINTMENT_BRANCH.FAILURE:
      return {
        ...state,
        loading_appoint_branch: false,
      };

    default:
      return state;
  }
};
