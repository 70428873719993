import { fork } from "redux-saga/effects";

import watchEmployees from "./components/employee/sagas";
import watchEmployeeTypes from "./components/employee_type/sagas";

const watchEmployee = [
  fork(watchEmployees),
  fork(watchEmployeeTypes)
];

export { watchEmployee };
