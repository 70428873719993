import React, { Component } from "react";
import { Col, Row, Checkbox, Input } from "antd";
import { DrawerFilter, LabelFilter, AutoComplete } from "lib/widget";
import axios from "axios";
import {
  getSearch,
} from "lib/helper";

const initialState = {
  permission_group_id: [],
  permGroup: [],
  keyword: "",
  permission_id: ""
};

const { Search } = Input;

class Filter extends Component {
  state = initialState;

  handleChange = (id, name) => (e) => {
    if (!e.target.checked) {
      this.setState((prevState) => {
        return {
          [name]: prevState[name].filter((x) => parseInt(x) !== parseInt(id)),
        };
      });
      return;
    }

    this.setState({ [name]: [...this.state[name], id] });
  };

  handleShow = (name) => () => {
    let value = this.state[name] === "none" ? "" : "none";
    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const { permission_group_id, keyword, permission_id } = this.state;
    let selected = {
      group: this.state.permGroup.filter((x) =>
        permission_group_id.includes(x.permission_group_id)
      ),
    };
    this.props.handleSearch(
      {
        permission_id,
        permission_group_id,
        keyword,
      },
      selected
    );
  };

  handleClearFilter = () => {
    this.setState({
      permission_group_id: [],
      permission_id: "",
      keyword:"",
      hiddenGroup: "none",
    });
  };

  handleInputChange = (e) => {
    this.setState({ permission_id: "", [e.target.name]: e.target.value });
  };

  handleAutoSelect = (id, data) => {
    this.setState({ permission_id: id, keyword: data });
  };

  searchAuto = (searchText) => {
    this.setState({ permission_id: "", keyword: searchText });
  };

  loadGroup = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/permissions/groups`,
        {
          pagination: null,
          limit: null,
          keyword: null,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.setState({ permGroup: res.data.data });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    let search = getSearch()

    if (typeof search === 'object') {
      this.setState(search)
    }
    this.loadGroup();
  }

  render() {
    const { visible, handleDrawerClose, list } = this.props;
    return (
      <DrawerFilter
        onSearch={this.handleSearch}
        visible={visible}
        onClear={this.handleClearFilter}
        onClose={handleDrawerClose}
      >
        <div className="section-filter">
          <LabelFilter text="Display Name" />
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <AutoComplete
                keyName={["display_name", "display_name"]}
                keyId="permission_id"
                searchCompnent={
                  <Search
                    value={this.state.keyword}
                    name="keyword"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                  />
                }
                type="permission"
                value={this.state.keyword}
                onSelect={this.handleAutoSelect}
                onSearch={this.searchAuto}
                options={list}
              />
            </Col>
          </Row>
        </div>
        <div className="section-filter">
          <LabelFilter text="Permission groups" />
          {this.state.permGroup.map((val, i) => {
            return (
              <Row key={i} gutter={[0, 8]}>
                <Col span={24}>
                  <Checkbox
                    checked={this.state.permission_group_id.includes(val.permission_group_id)}
                    onClick={this.handleChange(
                      val.permission_group_id,
                      "permission_group_id"
                    )}
                  >
                    {val.display_name}
                  </Checkbox>
                </Col>
              </Row>
            );
          })}
        </div>
      </DrawerFilter>
    );
  }
}

export default Filter;
