import {
  LOAD_BRANCH_ACTUAL_SERVICES,
  IMPORT_BRANCH_HAS_ACTUAL_SERVICE,
  DOWNLOAD_FILE,
  EDIT_BRANCH_HAS_ACTUAL_SERVICE,
  DELETE_BRANCH_HAS_SERVICE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  alertMessage: "",
  errorMessage: "",
  data: [],
  totalSize: 0,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BRANCH_ACTUAL_SERVICES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case LOAD_BRANCH_ACTUAL_SERVICES.SUCCESS:
      return {
        ...state,
        data: action.payload.branchActualServices.data,
        totalSize: action.payload.branchActualServices.total_row,
        alertMessage: "",
        errorMessage: "",
        loading: false
      };
    case LOAD_BRANCH_ACTUAL_SERVICES.FAILURE:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "Please select branch",
        loading: false
      };

    case IMPORT_BRANCH_HAS_ACTUAL_SERVICE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case IMPORT_BRANCH_HAS_ACTUAL_SERVICE.SUCCESS:
      return {
        ...state,
        data: action.payload.branchActualService.data,
        totalSize: action.payload.branchActualService.total_row,
        alertMessage: action.payload.branchActualService.message,
        errorMessage: "",
        loading: false
      };
    case IMPORT_BRANCH_HAS_ACTUAL_SERVICE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case EDIT_BRANCH_HAS_ACTUAL_SERVICE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_BRANCH_HAS_ACTUAL_SERVICE.SUCCESS:
      return {
        ...state,
        data: action.payload.services.data,
        alertMessage: action.payload.services.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_BRANCH_HAS_ACTUAL_SERVICE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };

    case DELETE_BRANCH_HAS_SERVICE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_BRANCH_HAS_SERVICE.SUCCESS:
      const newBranches = state.data.filter(
        branch =>
          parseInt(branch.branch_id) !==
          parseInt(action.payload.service.data.branch_id) || 
          parseInt(branch.actual_service_id) !==
          parseInt(action.payload.service.data.id)
      );
      return {
        ...state,
        data: newBranches,
        alertMessage: action.payload.service.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_BRANCH_HAS_SERVICE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.branch_has_actualservice_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
