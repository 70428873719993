import React, { Fragment } from "react";
import * as component from './components' 

export default () => {
    return (
      <Fragment>
        <component.Branches />
        <component.BranchHasCashPackages />
        <component.BranchHasCategories />
        <component.BranchHasEmployees />
        <component.BranchHasProducts />
        <component.BranchHasServices />
        <component.BranchHasServicePackages />
        <component.BranchHasTerminals />
      </Fragment>
    );
  };