import {
  LOAD_CALENDAR_REVENUE_SUMMARIES,
  LOAD_CALENDAR_REVENUE_GRAPH,
  LOAD_CALENDAR_APPOINTMENT,
  LOAD_CALENDAR_PAYMENT,
} from "./actions";

const initialState = {
  revenue_summary: {
    service: { amount: 0, discount: 0, total_amount: 0 },
    product: { amount: 0, discount: 0, total_amount: 0 },
    packages: { amount: 0, discount: 0, total_amount: 0 },
    cashpackage: { amount: 0, discount: 0, total_amount: 0 },
  },
  payment: [],
  revenue: [],
  loading: false,
  loading_revenue: false,
  revenue_graph: [],
  loading_graph: false,
  loading_appoint: false,
  appoint_graph: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CALENDAR_PAYMENT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_CALENDAR_PAYMENT.SUCCESS:
      return {
        ...state,
        loading: false,
        payment: action.payload.payment.data,
      };
    case LOAD_CALENDAR_PAYMENT.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case LOAD_CALENDAR_REVENUE_SUMMARIES.REQUEST:
      return {
        ...state,
        loading_revenue: true,
      };
    case LOAD_CALENDAR_REVENUE_SUMMARIES.SUCCESS:
      return {
        ...state,
        revenue_summary: action.payload.revenue.data,
        loading_revenue: false,
      };
    case LOAD_CALENDAR_REVENUE_SUMMARIES.FAILURE:
      return {
        ...state,
        loading_revenue: false,
      };

    case LOAD_CALENDAR_REVENUE_GRAPH.REQUEST:
      return {
        ...state,
        loading_graph: true,
      };
    case LOAD_CALENDAR_REVENUE_GRAPH.SUCCESS:
      let compared_graph =
        action.payload.compared === null ? [] : action.payload.compared.data;
      return {
        ...state,
        revenue_graph: action.payload.revenue.data,
        compared_graph: compared_graph,
        loading_graph: false,
      };
    case LOAD_CALENDAR_REVENUE_GRAPH.FAILURE:
      return {
        ...state,
        loading_graph: false,
      };

    case LOAD_CALENDAR_APPOINTMENT.REQUEST:
      return {
        ...state,
        loading_appoint: true,
      };
    case LOAD_CALENDAR_APPOINTMENT.SUCCESS:
      return {
        ...state,
        appoint_graph: action.payload.revenue.data,
        loading_appoint: false,
      };
    case LOAD_CALENDAR_APPOINTMENT.FAILURE:
      return {
        ...state,
        loading_appoint: false,
      };

    default:
      return state;
  }
};
