import { createTypes, createAction } from "lib/action";

const LOAD_SALE_DATATABLE = createTypes("sale_analytics", "list");
const LOAD_SALE_GRAPH = createTypes("sale_analytics", "graph");
const LOAD_SALE_WIDGET = createTypes("sale_analytics", "widget");
const LOAD_SALE_GRAPH_DETAIL = createTypes("sale_analytics_detail", "graph");
const LOAD_SALE_WIDGET_DETAIL = createTypes("sale_analytics_detail", "widget");
const LOAD_SALE_DETAIL = createTypes("sale_analytics_detail", "list");

const loadDataTable = {
  request: (type, duration, branch_id, query_type) =>
    createAction(LOAD_SALE_DATATABLE.REQUEST, {
      type,
      duration,
      branch_id,
      query_type
    }),
  success: (revenue_d) => {
    return createAction(LOAD_SALE_DATATABLE.SUCCESS, { revenue_d });
  },
  failure: () => createAction(LOAD_SALE_DATATABLE.FAILURE),
};

const loadGraphData = {
  request: (type, duration, branch_id, query_type) =>
    createAction(LOAD_SALE_GRAPH.REQUEST, {
      type,
      duration,
      branch_id,
      query_type
    }),
  success: (revenue_g, compared_g) =>
    createAction(LOAD_SALE_GRAPH.SUCCESS, { revenue_g, compared_g }),
  failure: () => createAction(LOAD_SALE_GRAPH.FAILURE),
};

const loadWidgetData = {
  request: (type, duration, branch_id, query_type) =>
    createAction(LOAD_SALE_WIDGET.REQUEST, {
      type,
      duration,
      branch_id,
      query_type
    }),
  success: (revenue_w) => {
    return createAction(LOAD_SALE_WIDGET.SUCCESS, { revenue_w })
  },
  failure: () => createAction(LOAD_SALE_WIDGET.FAILURE),
};

const loadDataTableDetail = {
  request: (type, duration, branch_id, sale_id, query_type) =>
    createAction(LOAD_SALE_DETAIL.REQUEST, {
      type,
      duration,
      branch_id,
      sale_id,
      query_type
    }),
  success: (tb_detail) => {
    return createAction(LOAD_SALE_DETAIL.SUCCESS, { tb_detail });
  },
  failure: () => createAction(LOAD_SALE_DETAIL.FAILURE),
};

const loadGraphDetailData = {
  request: (type, duration, branch_id, sale_id, query_type) =>
    createAction(LOAD_SALE_GRAPH_DETAIL.REQUEST, {
      type,
      duration,
      branch_id,
      sale_id,
      query_type
    }),
  success: (graph_detail, graph_detail_c) => {
    return createAction(LOAD_SALE_GRAPH_DETAIL.SUCCESS, { graph_detail, graph_detail_c })
  },
  failure: () => createAction(LOAD_SALE_GRAPH_DETAIL.FAILURE),
};

const loadWidgetDetailData = {
  request: (type, duration, branch_id, sale_id, query_type) =>
    createAction(LOAD_SALE_WIDGET_DETAIL.REQUEST, {
      type,
      duration,
      branch_id,
      sale_id,
      query_type
    }),
  success: (widget_detail) =>
    createAction(LOAD_SALE_WIDGET_DETAIL.SUCCESS, { widget_detail }),
  failure: () => createAction(LOAD_SALE_WIDGET_DETAIL.FAILURE),
};

export {
  LOAD_SALE_DATATABLE,
  loadDataTable,
  LOAD_SALE_GRAPH,
  loadGraphData,
  LOAD_SALE_WIDGET,
  loadWidgetData,
  loadDataTableDetail,
  LOAD_SALE_DETAIL,
  loadGraphDetailData,
  LOAD_SALE_GRAPH_DETAIL,
  loadWidgetDetailData,
  LOAD_SALE_WIDGET_DETAIL,
};
