import {
  LOAD_STYLIST_WIDGET,
  LOAD_STYLIST_DATATABLE,
  LOAD_STYLIST_DETAIL_DATATABLE,
  LOAD_STYLIST_DETAIL_WIDGET,
} from "./actions";

const initialState = {
  loading_datatable: true,
  analytic_datatable: [],
  loading_widget: [],
  analytic_widget: true,

  loading_datatable_detail: true,
  analytic_datatable_detail: [],
  loading_widget_detail: [],
  analytic_widget_detail: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_STYLIST_WIDGET.REQUEST:
      return {
        ...state,
        loading_widget: true,
      };
    case LOAD_STYLIST_WIDGET.SUCCESS:
      return {
        ...state,
        loading_widget: false,
        analytic_widget: action.payload.revenue_w,
      };
    case LOAD_STYLIST_WIDGET.FAILURE:
      return {
        ...state,
        loading_widget: false,
      };

    case LOAD_STYLIST_DATATABLE.REQUEST:
      return {
        ...state,
        loading_datatable: true,
      };
    case LOAD_STYLIST_DATATABLE.SUCCESS:
      return {
        ...state,
        loading_datatable: false,
        analytic_datatable: action.payload.revenue_d.data,
      };
    case LOAD_STYLIST_DATATABLE.FAILURE:
      return {
        ...state,
        loading_datatable: false,
      };

    //////////////////////////////////

    case LOAD_STYLIST_DETAIL_WIDGET.REQUEST:
      return {
        ...state,
        loading_widget_detail: true,
      };
    case LOAD_STYLIST_DETAIL_WIDGET.SUCCESS:
      return {
        ...state,
        loading_widget_detail: false,
        analytic_widget_detail: action.payload.widget_detail,
      };
    case LOAD_STYLIST_DETAIL_WIDGET.FAILURE:
      return {
        ...state,
        loading_widget_detail: false,
      };

    case LOAD_STYLIST_DETAIL_DATATABLE.REQUEST:
      return {
        ...state,
        loading_datatable_detail: true,
      };
    case LOAD_STYLIST_DETAIL_DATATABLE.SUCCESS:
      return {
        ...state,
        loading_datatable_detail: false,
        analytic_datatable_detail: action.payload.tb_detail,
      };
    case LOAD_STYLIST_DETAIL_DATATABLE.FAILURE:
      return {
        ...state,
        loading_datatable_detail: false,
      };

    default:
      return state;
  }
};
