import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { loadBranches } from "lib/select-option";

import TerminalForm from "./TerminalForm";
import { checkPermission } from "lib/helper";

class EditTerminal extends Component {
  state = {
    can_edit: checkPermission("branch_terminals", "can_edit", false)
  };
  componentDidMount() {
    this.props.loadTerminal(this.props.match.params.terminal_id);
    this.props.loadBranches();
  }

  editTerminal = async terminal => {
    this.props.editTerminal(this.props.match.params.terminal_id, terminal);
  };

  render() {
    const {
      terminal,
      branches,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <TerminalForm
        onSubmit={this.editTerminal}
        terminal={terminal}
        branches={branches}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ branchTerminals, branches }) => {
  return {
    terminal: branchTerminals.terminal,
    branches: branches.data,
    alertMessage: branchTerminals.alertMessage,
    errorMessage: branchTerminals.errorMessage,
    loading: branchTerminals.loading
  };
};

const mapDispatchToProps = {
  loadTerminal: actions.loadTerminal.request,
  editTerminal: actions.editTerminal.request,
  loadBranches: loadBranches.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTerminal);
