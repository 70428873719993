import { createTypes, createAction } from "lib/action";

const LOAD_CALENDAR_REVENUE_SUMMARIES = createTypes("summary_revenue_calendar", "list");
const LOAD_CALENDAR_REVENUE_GRAPH = createTypes("graph_revenue_calendar", "list");
const LOAD_CALENDAR_APPOINTMENT = createTypes("appointment_calendar", "list");
const LOAD_CALENDAR_PAYMENT = createTypes("payment_calendar", "list")

const loadPayment = {
  request: (type, duration, branch_id) =>
    createAction(LOAD_CALENDAR_PAYMENT.REQUEST, { type, duration, branch_id }),
  success: (payment) =>
    createAction(LOAD_CALENDAR_PAYMENT.SUCCESS, { payment }),
  failure: () => createAction(LOAD_CALENDAR_PAYMENT.FAILURE),
};

const loadAppointment = {
  request: (type, duration, branch_id) =>
    createAction(LOAD_CALENDAR_APPOINTMENT.REQUEST, { type, duration, branch_id }),
  success: (revenue) =>
    createAction(LOAD_CALENDAR_APPOINTMENT.SUCCESS, { revenue }),
  failure: () => createAction(LOAD_CALENDAR_APPOINTMENT.FAILURE),
};

const loadRevenueSummaries = {
  request: (
    type,
    duration,
    branch_id,
    query_type,
    category_id,
  ) =>
    createAction(LOAD_CALENDAR_REVENUE_SUMMARIES.REQUEST, {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
    }),
  success: (revenue) =>
    createAction(LOAD_CALENDAR_REVENUE_SUMMARIES.SUCCESS, { revenue }),
  failure: () => createAction(LOAD_CALENDAR_REVENUE_SUMMARIES.FAILURE),
};

const loadGraphRevenue = {
  request: (
    type,
    duration,
    branch_id,
    query_type,
    category_id,
    withCompared = true
  ) =>
    createAction(LOAD_CALENDAR_REVENUE_GRAPH.REQUEST, {
      type,
      duration,
      branch_id,
      query_type,
      category_id,
      withCompared
    }),
  success: (revenue, compared) =>
    createAction(LOAD_CALENDAR_REVENUE_GRAPH.SUCCESS, { revenue, compared }),
  failure: () => createAction(LOAD_CALENDAR_REVENUE_GRAPH.FAILURE),
};

export { 
  LOAD_CALENDAR_REVENUE_SUMMARIES, 
  loadRevenueSummaries,
  LOAD_CALENDAR_REVENUE_GRAPH,
  loadGraphRevenue,
  LOAD_CALENDAR_APPOINTMENT,
  loadAppointment,
  LOAD_CALENDAR_PAYMENT,
  loadPayment
};
