import React, { Fragment, useState } from "react";
import { Menu, Button, Dropdown } from "antd";

import {
  DownloadOutlined,
  DownOutlined,
  UploadOutlined
} from "@ant-design/icons";


import { checkPermission } from 'lib/helper'


const ImportExportBtn = ({ download, showModalImport, name, disabledDownload = false, breakpoint=500 }) => {
  const [type, setType] = useState(null)
  let check_import = checkPermission(name, "can_import", false)
  let check_export = checkPermission(name, "can_export", false)

  const handleChange = (val) => () => {
    setType(val);
    if(val === 'import') showModalImport()
    if(val === 'export') download()
  };

  const menu = (
    <Menu>
      { check_import && showModalImport !== false  &&  <Menu.Item key="1"><Button type="link" onClick={handleChange('import')}>Import</Button></Menu.Item>}
      <Menu.Divider />
      { check_export && <Menu.Item key="2"><Button disabled={disabledDownload} type="link" onClick={handleChange('export')}>Export</Button></Menu.Item>}
    </Menu>
  );

  const Desktop = (
    <Fragment>
      { check_import && showModalImport !== false  && <Button icon={<DownloadOutlined />} onClick={handleChange('import')}>Import</Button>}
      { check_export && <Button disabled={disabledDownload} icon={<UploadOutlined />}  onClick={handleChange('export')}>Export</Button>}
    </Fragment>
  )

  const Mobile = (
    <Dropdown overlay={menu}>
      <Button>
        Action <DownOutlined />
      </Button>
    </Dropdown>
  )

  return (
     window.innerWidth <= breakpoint ? Mobile : Desktop 
  );
};

export default ImportExportBtn;
