import {
  LOAD_BRANDS,
  CREATE_BRAND,
  LOAD_BRAND,
  EDIT_BRAND,
  DELETE_BRAND,
  UPLOAD_FILE,
  DOWNLOAD_FILE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  brand: { product_brand_code: "", product_brand: "" },
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,
  loading: false,
  errorImport: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BRANDS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case LOAD_BRANDS.SUCCESS:
      return {
        ...state,
        data: action.payload.brands.data,
        totalSize: action.payload.brands.total_row,
        alertMessage: "",
        errorMessage: "",
        brand: initialState.brand,

        errorImport: "",
        loading: false
      };
    case LOAD_BRAND.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case LOAD_BRAND.SUCCESS:
      return {
        ...state,
        brand: action.payload.brand.data,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_BRAND.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case CREATE_BRAND.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.brand.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_BRAND.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_BRAND.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case EDIT_BRAND.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.brand.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_BRAND.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case DELETE_BRAND.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DELETE_BRAND.SUCCESS:
      const newBrands = state.data.filter(
        brand =>
          brand.product_brand_id !== action.payload.brand.data.product_brand_id
      );
      return {
        ...state,
        data: newBrands,
        alertMessage: action.payload.brand.message,
        totalSize: state.totalSize - 1,
        errorImport: "",
        loading: false
      };
    case DELETE_BRAND.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case UPLOAD_FILE.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        totalSize: action.payload.data.total_row,
        alertMessage: action.payload.data.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",

        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.product_brand_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
