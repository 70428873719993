import {
  LOAD_UNITS,
  LOAD_UNIT,
  CREATE_UNIT,
  EDIT_UNIT,
  DELETE_UNIT,
  UPLOAD_FILE,
  DOWNLOAD_FILE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  unit: { uom_code: "", uom_name: "" },
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_UNITS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_UNITS.SUCCESS:
      return {
        ...state,
        data: action.payload.units.data,
        totalSize: action.payload.units.total_row,
        alertMessage: "",
        errorMessage: "",
        unit: initialState.unit,

        errorImport: "",
        loading: false
      };
    case LOAD_UNIT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_UNIT.SUCCESS:
      return {
        ...state,
        unit: action.payload.unit.data,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_UNIT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case CREATE_UNIT.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.unit.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_UNIT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_UNIT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_UNIT.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.unit.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_UNIT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case DELETE_UNIT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_UNIT.SUCCESS:
      const newUnits = state.data.filter(
        unit => unit.uom_id !== action.payload.unit.data.uom_id
      );
      return {
        ...state,
        data: newUnits,
        alertMessage: action.payload.unit.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_UNIT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case UPLOAD_FILE.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        totalSize: action.payload.data.total_row,
        alertMessage: action.payload.data.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",

        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.uom_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
