import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import EmployeeTypeForm from "./EmployeeTypeForm";
import { checkPermission } from "lib/helper";
class EditEmployeeType extends Component {
  state = {
    can_edit: checkPermission("employee_types", "can_edit", false)
  };

  componentDidMount() {
    this.props.loadEmployeeType(this.props.match.params.employee_type_id);
  }

  editEmployeeType = async employeeType => {
    this.props.editEmployeeType(
      this.props.match.params.employee_type_id,
      employeeType.type_name,
      employeeType.employee_type_code,
      1
    );
  };

  render() {
    const { employeeType, alertMessage, errorMessage, loading } = this.props;

    return (
      <EmployeeTypeForm
        onSubmit={this.editEmployeeType}
        employeeType={employeeType}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ employeeTypes }) => {
  return {
    employeeType: employeeTypes.employeeType,
    alertMessage: employeeTypes.alertMessage,
    errorMessage: employeeTypes.errorMessage,
    loading: employeeTypes.loading
  };
};

const mapDispatchToProps = {
  loadEmployeeType: actions.loadEmployeeType.request,
  editEmployeeType: actions.editEmployeeType.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmployeeType);
