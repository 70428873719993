import service_analytic from "./components/service/reducer";
import sale_analytic from "./components/sales/reducer";
import stylist_analytic from "./components/stylist/reducer";
import customer_analytic from "./components/customer/reducer";

const analytic = {
  service_analytic,
  stylist_analytic,
  sale_analytic,
  customer_analytic
};
export { analytic };
