import React from "react";
import { Row, Col, Card, Spin } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { numberFormat } from "lib/helper";
import styled from "styled-components";

const Number = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const Increase = styled.span`
  color: #00e815;
  padding-right: inherit;
  font-size: 12px;
  font-weight: bold;
`;

const Decrease = styled.span`
  color: #c90400;
  padding-right: inherit;
  font-size: 12px;
  font-weight: bold;
`;

function CardRevenue({
  comparedLabel,
  revenue,
  compared,
  percentCal,
  loading,
}) {
  const handleUndefined = (val) => {
    return typeof val === "undefined" ? 0 : val.amount;
  };

  const cardType = [
    {
      name: "Service",
      value: handleUndefined(revenue.service),
      diff: percentCal(
        handleUndefined(compared.service),
        handleUndefined(revenue.service)
      ),
      classname: "blue-gradient",
    },
    {
      name: "Product",
      value: handleUndefined(revenue.product),
      diff: percentCal(
        handleUndefined(compared.product),
        handleUndefined(revenue.product)
      ),
      classname: "orange-gradient",
    },
    {
      name: "Service Package",
      value: handleUndefined(revenue.packages),
      diff: percentCal(
        handleUndefined(compared.packages),
        handleUndefined(revenue.packages)
      ),
      classname: "pink-gradient",
    },
    {
      name: "Cash Package",
      value: handleUndefined(revenue.cashpackage),
      diff: percentCal(
        handleUndefined(compared.cashpackage),
        handleUndefined(revenue.cashpackage)
      ),
      classname: "purple-gradient",
    },
    {
      name: "Revenue per transaction",
      value: handleUndefined(revenue.pertransaction),
      diff: percentCal(
        handleUndefined(compared.pertransaction),
        handleUndefined(revenue.pertransaction)
      ),
      classname: "purple-gradient",
    },
    {
      name: "Revenue per stylish",
      value: handleUndefined(revenue.perstylish),
      diff: percentCal(
        handleUndefined(compared.perstylish),
        handleUndefined(revenue.perstylish)
      ),
      classname: "purple-gradient",
    },
  ];

  return (
    <Row gutter={[8, 8]} style={{ marginTop: "20px" }} justify="space-between">
      {cardType.map((data) => (
        <Col key={data.name} xs={24} md={12} lg={4}>
          <Card
            style={{
              marginBottom: 10,
            }}
            bodyStyle={{ padding: "14px" }}
            className={data.classname}
          >
            <Spin spinning={loading}>
              <Row gutter={[8, 8]} justify="end">
                <Col span={24}>{data.name}</Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Number>{numberFormat(data.value)}</Number>
                  <br />
                  {data.diff < 0 ? (
                    <Decrease>
                      <ArrowDownOutlined />
                      {numberFormat(data.diff * -1)} %
                    </Decrease>
                  ) : (
                    <Increase>
                      <ArrowUpOutlined /> {numberFormat(data.diff)} %
                    </Increase>
                  )}
                  than last {comparedLabel}
                </Col>
              </Row>
            </Spin>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default CardRevenue;
