import {
  LOAD_PRODUCTS,
  LOAD_PRODUCT,
  CREATE_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  IMPORT_PRODUCT,
  DOWNLOAD_FILE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  product: {
    barcode: "",
    product_name: "",
    product_category_id: "",
    product_brand_id: "",
    uom_id: "",
    size: "",
    distributor_id: "",
    item_code: "",
    is_show: 1,
    retail_cost_before_vat: "",
    retail_cost: "",
    salon_cost_before_vat: "",
    salon_cost: "",

    retail_price_before_vat: "",
    retail_price: "",
    salon_price_before_vat: "",
    salon_price: "",
    pricelist: [
      {
        key: 0,
        bin_id: 1,
        name: "Retail",
        price_before_vat: "",
        price: ""
      },
      {
        key: 1,
        bin_id: 2,
        name: "Salon",
        price_before_vat: "",
        price: ""
      }
    ]
  },
  branch_products: [],
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,
  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PRODUCTS.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case LOAD_PRODUCTS.SUCCESS:
      return {
        ...state,
        data: action.payload.products.data,
        totalSize: action.payload.products.total_row,
        alertMessage: "",
        errorMessage: "",
        product: initialState.product,
        loading: false
      };
    case LOAD_PRODUCTS.FAILURE:
      return {
        ...state,
        alertMessage: "",
        errorMessage: action.payload.error,

        loading: false
      };

    case LOAD_PRODUCT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case LOAD_PRODUCT.SUCCESS:
      return {
        ...state,
        product: action.payload.product.data,
        branch_products: action.payload.product.branch_products,
        alertMessage: "",
        errorMessage: "",

        loading: false
      };
    case LOAD_PRODUCT.FAILURE:
      return {
        ...state,
        alertMessage: "",
        errorMessage: action.payload.error,

        loading: false
      };
    case CREATE_PRODUCT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case CREATE_PRODUCT.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.product.message,
        errorMessage: "",

        loading: false
      };
    case CREATE_PRODUCT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        loading: false
      };
    case EDIT_PRODUCT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case EDIT_PRODUCT.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.product.message,
        errorMessage: "",

        loading: false
      };
    case EDIT_PRODUCT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        loading: false
      };
    case DELETE_PRODUCT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DELETE_PRODUCT.SUCCESS:
      const newProducts = state.data.filter(
        product => product.product_id !== action.payload.product.data.product_id
      );
      return {
        ...state,
        data: newProducts,
        alertMessage: action.payload.product.message,
        totalSize: state.totalSize - 1,
        errorMessage: "",

        loading: false
      };
    case DELETE_PRODUCT.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",
        loading: false
      };
    case IMPORT_PRODUCT.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case IMPORT_PRODUCT.SUCCESS:
      return {
        ...state,
        data: action.payload.product.data,
        totalSize: action.payload.product.total_row,
        alertMessage: action.payload.product.message[0],
        errorMessage: "",
        errorImport: "",
        loading: false
      };
    case IMPORT_PRODUCT.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",

        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.product_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };
    default:
      return state;
  }
};
