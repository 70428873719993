import React, { Fragment } from 'react'
import * as component from './components'

export default () => {
    return (
        <Fragment>
          <component.CustomerPackages />
          <component.Nationalities />
          <component.Customers />
        </Fragment>
      );
}