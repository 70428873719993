import React from 'react'
import { Button } from "antd";
import { checkPermission } from 'lib/helper'

const ArrangementBtn = ({ name, onClick, display, disabled = false }) => {
    let check_auth = checkPermission(name, "can_other_1", false)
    return   check_auth ? <Button
    onClick={() => onClick("arrange")}
    disabled={disabled}
    style={{ display: display, marginRight: '10px' }}
  >
    Arrangement
  </Button> : <div />
}

export default ArrangementBtn