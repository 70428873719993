import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from 'antd'
import * as actions from "../actions";

import BranchHasEmployeeForm from "./BranchHasEmployeeForm";
import { checkPermission } from "lib/helper";

class EditBranchHasEmployee extends Component {
  state = {
    can_edit: checkPermission("branch_employees", "can_add", false)
  };
  
  static getDerivedStateFromProps(nextProps, prevState) {
    const { alertMessage } = nextProps;
    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage } = this.props;

    if (alertMessage !== "" && prevProps.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }


  componentDidMount() {
    let branchId = this.props.match.params.branch_id;
    if (typeof parseInt(branchId) !== "number") return;
    this.props.loadBranchEmployees(branchId, null, null, null);
  }

  editBranchHasEmployee = async (branchEmployee) => {
    this.props.editBranchHasEmployee(
      this.props.match.params.branch_id,
      branchEmployee
    );
  };

  render() {
    const {
      branchEmployee,
      alertMessage,
      errorMessage,
      loading
    } = this.props;

    return (
      <BranchHasEmployeeForm
        onSubmit={this.editBranchHasEmployee}
        branchEmployee={branchEmployee}
        branchId={this.props.match.params.branch_id}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        loading={loading}
        canEdit={this.state.can_edit}
      />
    );
  }
}

const mapStateToProps = ({ branchEmployees, branches }) => {
  return {
    branchEmployee: branchEmployees.data,
    alertMessage: branchEmployees.alertMessage,
    errorMessage: branchEmployees.errorMessage,
    loading: branchEmployees.loading,
    branches
  };
};

const mapDispatchToProps = {
  loadBranchEmployees: actions.loadBranchEmployees.request,
  editBranchHasEmployee: actions.editBranchHasEmployee.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBranchHasEmployee);
