import * as moment from "moment";

const getComparedFilter = (type, value) => {
    if (type == 'date') {
        var changeddate = moment(value, 'YYYY-MM-DD').subtract(7, 'days');
        return moment(changeddate).format('YYYY-MM-DD')
    }
    if (type == 'week') {
        var changeddate = moment(value, 'YYYY-W').subtract(1, 'weeks');
        return moment(changeddate).format('YYYY-W')
    }
    if (type == 'month') {
        var changeddate = moment(`${value}-01`, 'YYYY-MM-DD').subtract(1, 'months');
        return moment(changeddate).format('YYYY-MM')
    }
}

export {
    getComparedFilter  
}