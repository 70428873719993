import {
  LOAD_CATEGORIES,
  CREATE_CATEGORY,
  LOAD_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  UPLOAD_FILE,
  DOWNLOAD_FILE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  category: { category_code: "", category_name: "" },
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CATEGORIES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case LOAD_CATEGORIES.SUCCESS:
      return {
        ...state,
        data: action.payload.categories.data,
        totalSize: action.payload.categories.total_row,
        alertMessage: "",
        errorMessage: "",
        category: initialState.category,

        errorImport: "",
        loading: false
      };
    case LOAD_CATEGORY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case LOAD_CATEGORY.SUCCESS:
      return {
        ...state,
        category: action.payload.category.data,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_CATEGORY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case CREATE_CATEGORY.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.category.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_CATEGORY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_CATEGORY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case EDIT_CATEGORY.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.category.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_CATEGORY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case DELETE_CATEGORY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DELETE_CATEGORY.SUCCESS:
      const newCategories = state.data.filter(
        category =>
          category.product_category_id !==
          action.payload.category.data.product_category_id
      );
      return {
        ...state,
        data: newCategories,
        alertMessage: action.payload.category.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_CATEGORY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case UPLOAD_FILE.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        totalSize: action.payload.data.total_row,
        alertMessage: action.payload.data.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case UPLOAD_FILE.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",

        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.product_category_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
