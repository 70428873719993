import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Row, Col, Card } from "antd";
import { numberFormat } from 'lib/helper'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "01", revenue: 41080.00, profit:16925.40},
  { name: "02", revenue: 69016.00, profit:27650.45},
  { name: "03", revenue: 26750.00, profit:10462.83},
  { name: "04", revenue: 47725.00, profit:15007.96},
  { name: "05", revenue: 25695.00, profit:10177.34},
  { name: "06", revenue: 23222.00, profit:9289.42},
  { name: "07", revenue: 35417.00, profit:13587.08},
  { name: "08", revenue: 89914.00, profit:34104.98},
  { name: "09", revenue: 70495.50, profit:25632.39},
  { name: "10", revenue: 27630.00, profit:11666.80},
  { name: "11", revenue: 34910.00, profit:14059.19},
  { name: "12", revenue: 39497.00, profit:14379.97},
  { name: "13", revenue: 16480.00, profit:7066.59},
  { name: "14", revenue: 61331.00, profit:23844.61},
  { name: "15", revenue: 41403.00, profit:17132.17},
  { name: "16", revenue: 55696.50, profit:23261.57},
  { name: "17", revenue: 41150.00, profit:17242.39},
  { name: "18", revenue: 47489.50, profit:21977.65},
  { name: "20", revenue: 70908.50, profit:28984.54},
  { name: "21", revenue: 39639.00, profit:15262.90},
  { name: "22", revenue: 56984.50, profit:23434.59},
  { name: "23", revenue: 85470.00, profit:36810.23},
  { name: "24", revenue: 22667.50, profit:9263.48},
  { name: "25", revenue: 35186.00, profit:13921.68},
  { name: "26", revenue: 89812.50, profit:35552.53},
  { name: "27", revenue: 41060.00, profit:16167.42},
  { name: "28", revenue: 26150.00, profit:10859.83},
  { name: "29", revenue: 84546.00, profit:33238.00},
];
export class Graph extends Component {
  render() {
    return (
      <div style={{ width: "100%", height: 400 }}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{ top: 20, right: 80, bottom: 20, left: 20 }}
          >
            <XAxis
              dataKey="name"
            />
            <YAxis
              tickFormatter={(value) => numberFormat(value)}
            />
            <Tooltip formatter={(value) => numberFormat(value) }/>
            <Legend />
            <Area type="monotone" dataKey="revenue"  stroke="#413ea0" fill="#413ea0" />
            <Area type="monotone" dataKey="profit" stroke="#ff7300" fill="#ff7300" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Graph);
