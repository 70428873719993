import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  message,
  Spin,
  Divider
} from "antd";
import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { RequriedText, PageTitle, FormBtn } from 'lib/widget'

const FormItem = Form.Item;
const Option = Select.Option;

export default class UserForm extends Component {
  state = {
    checkedList: this.props.checkedList,
    is_manager: false,
    branchStyle: "none"
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { errorMessage, alertMessage } = nextProps;

    if (alertMessage !== "") {
      return { alertMessage: alertMessage };
    }

    if (errorMessage !== "") {
      return { errorMessage: errorMessage };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, alertMessage } = this.props;
    const prevMsg = prevProps;

    if (errorMessage !== "" && prevMsg.errorMessage !== errorMessage) {
      message.error(errorMessage);
    }

    if (alertMessage !== "" && prevMsg.alertMessage !== alertMessage) {
      message.success(alertMessage);
    }
  }

  onChecked = checkedValues => {
    const obj = this.state.checkedList;
    obj.map(check => {
      if (check.name.toString() === checkedValues.target.name.toString())
        check.value = checkedValues.target.checked;
    });
    this.setState({
      checkedList: obj
    });
  };

  onCheckedManager = value => {
    this.setState({ is_manager: value.target.checked });
    if (value.target.checked) {
      this.setState({ branchStyle: "block" });
    } else {
      const obj = this.state.checkedList;
      obj.map(check => {
        check.value = false;
      });
      this.setState({
        checkedList: obj,
        branchStyle: "none"
      });
    }
  };

  componentDidMount() {
    this.nameInput.focus(); 
  }

  render() {
    const { user, onSubmit, usergroups, branches, employees } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      }
    };

    const list = branches.map(branch => {
      const code = branch.branch_id;
      const check = this.state.checkedList.filter(c => {
        if (c.name === code) return c;
      });
      return (
        <Col key={branch.branch_id} span={12}>
          <Checkbox
            name={branch.branch_id.toString()}
            onChange={this.onChecked}
            checked={check[0].value}
          >
            {branch.branch_name}
          </Checkbox>
        </Col>
      );
    });

    return (
      <div>
        <Spin size="large" spinning={this.props.loading}>
        <PageTitle name="system_user_users" component={null} />
          <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .email("Incorect email format")
                .required("email is a required field"),
              name: yup.string().required("Name is a required field"),
              usergroup_id: yup.string().required("User group is a required field"),
              password: yup
                .string()
                .required("Password is a required field")
                .test("len", "Must be at least 6 characters", val =>
                  val ? val.toString().length >= 6 : false
                ),
              confirm_password: yup
                .string()
                .required("Confirm password is a required field")
                .test(
                  "passwords-match",
                  "Password does not match the Confirm password",
                  function(value) {
                    return this.parent.password === value;
                  }
                )
            })}
            onSubmit={values => {
              onSubmit(values, this.state.checkedList, this.state.is_manager);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <Form onFinish={handleSubmit} autoComplete="off">
                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Email" />}
                  validateStatus={touched.email && errors.email && "error"}
                  help={touched.email && errors.email}
                >
                  <Input
                    name="email"
                    ref={(input) => { this.nameInput = input; }} 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    autoComplete="off"
                    disabled={user.email ? true : false}
                  />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Password" />}
                  validateStatus={
                    touched.password && errors.password && "error"
                  }
                  help={touched.password && errors.password}
                >
                  <Input
                    name="password"
                    placeholder="Passwords must be at least 6 characters"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    autoComplete="off"
                    type="password"
                  />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Confirm password" />}
                  validateStatus={
                    touched.confirm_password &&
                    errors.confirm_password &&
                    "error"
                  }
                  help={touched.confirm_password && errors.confirm_password}
                >
                  <Input
                    name="confirm_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    type="password"
                  />
                </FormItem>

                <Divider />

                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="Name" />}
                  validateStatus={touched.name && errors.name && "error"}
                  help={touched.name && errors.name}
                >
                  <Input
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    autoComplete="off"
                  />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label={<RequriedText label="User Group" />}
                  validateStatus={touched.usergroup_id && errors.usergroup_id && "error"}
                  help={touched.usergroup_id && errors.usergroup_id}
                >
                  <Select
                    onChange={role => setFieldValue("usergroup_id", role)}
                    value={values.usergroup_id}
                  >
                    {usergroups.map(role => (
                      <Option key={role.usergroup_id} value={role.usergroup_id}>{role.usergroup_name}</Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Link to Employee"
                  validateStatus={
                    touched.employee_id && errors.employee_id && "error"
                  }
                  help={touched.employee_id && errors.employee_id}
                >
                  <Select
                    placeholder="Select a employee"
                    onChange={employee =>
                      setFieldValue("employee_id", employee)
                    }
                    value={values.employee_id}
                  >
                    <Option value={null}>---- Clear Data ----</Option>
                    {employees.map(employee => (
                      <Option key={employee.employee_id} value={employee.employee_id}>
                        {employee.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem>
                  <Row>
                    <Col
                      xs={{ span: 5 }}
                      sm={{ span: 8 }}
                      style={{
                        paddingRight: "9px",
                        textAlign: window.innerWidth <= 500 ? "left" : "right",
                        color: "rgba(0, 0, 0, 0.85)"
                      }}
                    >
                      Manager :
                    </Col>
                    <Col xs={{ span: 19 }} sm={{ span: 10 }}>
                      <Row>
                        <Checkbox
                          name="is_manager"
                          onChange={(e) => {
                            setFieldValue('is_manager', e.target.checked)
                            this.onCheckedManager(e)
                          }}

                        />
                      </Row>
                    </Col>
                  </Row>
                </FormItem>

                <FormItem style={{ display: values.is_manager ? "block" : "none" }}>
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 8 }}
                      style={{
                        paddingRight: "9px",
                        textAlign: window.innerWidth <= 500 ? "left" : "right",
                        color: "rgba(0, 0, 0, 0.85)",
                        paddingBottom: window.innerWidth <= 500 ? "20px" : "0px",
                      }}
                    >
                      Branch :
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 10 }}>
                      <Row>{list}</Row>
                    </Col>
                  </Row>
                </FormItem>

                <FormBtn url="/system/users" canEdit={true} />
              </Form>
            )}
          />
        </Spin>
      </div>
    );
  }
}
