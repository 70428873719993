import React from "react";
import { Switch } from "react-router-dom";

import BrandList from "./BrandList";
import EditBrand from "./EditBrand";
import NewBrand from "./NewBrand";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute path="/product/brands/new" component={NewBrand} perm="product_brands" action="can_add"/>
    <PrivateRoute path="/product/brands/edit/:product_brand_id" component={EditBrand} perm="product_brands"/>
    <PrivateRoute path="/product/brands" component={BrandList} perm="product_brands"/>
  </Switch>
);
