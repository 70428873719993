import React, { Component } from "react";
import { connect } from "react-redux";

import { Col, Row, Divider, Checkbox, Input, Typography } from "antd";
import { loadNationalities } from "lib/select-option";
import { DrawerFilter, AutoComplete, LabelFilter } from "lib/widget";
import {
  getSearch,
} from "lib/helper";

const { Text } = Typography;

const initialState = {
  nationality_id: [],
  gender: [],
  hiddenNation: "none",
  keyword: "",
  tel1: "",
  customer_id: ""
};

const options = [
  { label: "MALE", value: "MALE" },
  { label: "FEMALE", value: "FEMALE" },
  { label: "N/A", value: "N/A" }
];

const { Search } = Input

class Filter extends Component {
  state = initialState;

  handleChange = (id, name) => () => {
    this.setState({ [name]: [...this.state[name], id] });
  };

  handleGenderChange = e => {
    this.setState({ gender: e.target.value });
  };

  handleShow = name => () => {
    let value = this.state[name] === "none" ? "" : "none";
    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const { gender, nationality_id, keyword, tel1, customer_id } = this.state;
    let selected = {
      nationality: this.props.nationalities.filter(x =>
        nationality_id.includes(x.nationality_id)
      ),
      gender: options.filter(x => gender.includes(x.value))
    };

    let params = {
      gender,
      keyword,
      tel1,
      nationality_id,
      customer_id
    };
    this.props.handleSearch(params, selected);
  };

  handleInputChange = e => {
    this.setState({ customer_id: "", [e.target.name]: e.target.value });
  };

  handleAutoSelect = (id, data) => {
    this.setState({ customer_id : id, keyword: data });
  };

  searchAuto = searchText => {
    this.setState({ customer_id: "", keyword: searchText });
  };

  handleClearFilter = () => {
    this.setState(initialState);
  };

  componentDidMount() {
    let search = getSearch()

    if (typeof search === 'object') {
      this.setState(search)
    }
    this.props.loadNationalities();
  }

  render() {
    const { nationalities, visible, handleDrawerClose, list } = this.props;
    return (
      <DrawerFilter
        onSearch={this.handleSearch}
        visible={visible}
        onClear={this.handleClearFilter}
        onClose={handleDrawerClose}
      >
        <div className="section-filter">
          <LabelFilter text="Code or Name" />
          <Row gutter={[0, 16]}>
            <Col span={22}>
            <AutoComplete
                keyName={['customer_code', 'name']}
                keyId="customer_id"
                searchCompnent={
                  <Search
                    value={this.state.keyword}
                    name="keyword"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                  />
                }
                type="customer"
                value={this.state.keyword}
                onSelect={this.handleAutoSelect}
                onSearch={this.searchAuto}
                options={list}
              /> 
            </Col>
          </Row>
        </div>
        <div className="section-filter">
          <Row gutter={[0, 8]}>
            <Col span={22}>
              <Text strong>Tel no.</Text>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <Input
                name="tel1"
                value={this.state.tel1}
                onBlur={this.handleInputChange}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
        </div>
        <div className="section-filter">
          <LabelFilter text="Gender" />
          {options.map((val, i) => {
            return (
              <Row key={i} gutter={[0, 8]}>
                <Col span={24}>
                  <Checkbox
                    checked={this.state.gender.includes(val.value)}
                    onClick={this.handleChange(val.value, "gender")}
                  >
                    {val.label}
                  </Checkbox>
                </Col>
              </Row>
            );
          })}
        </div>
        <Divider />
        <div className="section-filter">
          <LabelFilter text="Nationality" />
          <div className="checkbox-group-section-long">
            {typeof nationalities !== "undefined" &&
              nationalities.map((val, i) => {
                return (
                  <Row key={i} gutter={[0, 8]}>
                    <Col span={24}>
                      <Checkbox
                        checked={this.state.nationality_id.includes(
                          val.nationality_id
                        )}
                        onClick={this.handleChange(
                          val.nationality_id,
                          "nationality_id"
                        )}
                      >
                        {val.nationality}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
          </div>
        </div>
      </DrawerFilter>
    );
  }
}

const mapStateToProps = ({ nationalities }) => ({
  nationalities: nationalities.data
});

const mapDispatchToProps = {
  loadNationalities: loadNationalities.request
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
