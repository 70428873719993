import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadBranchProducts(action) {
  try {
    let { branchId, pagination, limit, cond, sort } = action.payload;
    let branchesId = typeof branchId !== 'object' ? [branchId] : branchId
    const res = yield fetcher.post("masterdata/stockbalance", {
      branch_id: branchesId,
      pagination,
      limit,
      cond,
      sort
    });
    yield put(actions.loadBranchProducts.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadBranchProducts.failure(getErrorMsg(error)));
    yield put(push("/branch/products"));
  }
}

function* editBranchHasProduct(action) {
  const { branch_id, products } = action.payload;
  try {
    const res = yield fetcher.post(
      `branch_has/create/product/${branch_id}`,
      products
    );
    yield put(actions.editBranchHasProduct.success(res.data));
    yield put(push("/branch/products"));
  } catch (error) {
    handleError(error);
    yield put(actions.editBranchHasProduct.failure(getErrorMsg(error)));
    yield put(push(`/branch/products/edit/${branch_id}`));
  }
}

function* deleteBranchHasProduct(action) {
  try {
    const { id, branch_id } = action.payload;
    const res = yield fetcher.delete(
      `branch_has/delete/product/${branch_id}/${id}`
    );
    yield put(actions.deleteBranchHasProduct.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteBranchHasProduct.failure(getErrorMsg(error)));
  }
}

function* importBranchHasProduct(action) {
  try {
    const { file, type, pagination, limit, keyword, branchId } = action.payload;

    const formData = new FormData();
    formData.append("import_file", file.originFileObj);
    formData.append("dataType", type);
    formData.append("pagination", pagination);
    formData.append("limit", limit);
    if (keyword) formData.append("keyword", keyword);
    formData.append("branch_id", branchId);
    const res = yield fetcher.post("import/branch_has", formData);
    yield put(actions.importBranchHasProduct.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importBranchHasProduct.failure(getErrorMsg(error)));
    yield put(push("/branch/products"));
  }
}

function* download(action) {
  try {
    const res = yield fetcher.post("export/branch_has/product", {
      branch_id: action.payload.branch_id
    });
    yield put(actions.download.success(res.data));
    fetcher.download(res.data);
  } catch (error) {
    handleError(error);
  }
}

function* destroyMany(action) {
  try {
    const { data } = action.payload;
    const res = yield fetcher.post(`destroy_many/branch_has/product`, {
      id: data
    });
    yield put(actions.destroyMany.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.destroyMany.failure(getErrorMsg(error)));
  }
}

export default function* watchBranchProducts() {
  yield all([
    takeEvery(actions.LOAD_BRANCH_PRODUCTS.REQUEST, loadBranchProducts),
    takeEvery(
      actions.IMPORT_BRANCH_HAS_PRODUCT.REQUEST,
      importBranchHasProduct
    ),
    takeEvery(actions.DOWNLOAD_FILE.REQUEST, download),
    takeEvery(actions.EDIT_BRANCH_HAS_PRODUCT.REQUEST, editBranchHasProduct),
    takeEvery(
      actions.DELETE_BRANCH_HAS_PRODUCT.REQUEST,
      deleteBranchHasProduct
    ),
    takeEvery(actions.DESTROY_MANY.REQUEST, destroyMany)
  ]);
}
