import React, { Fragment } from "react";
import { Permission, UserGroup } from "./components";

export default () => {
  return (
    <Fragment>
      <Permission />
      <UserGroup />
    </Fragment>
  );
};
