import React from "react";
import { Spin } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { numberFormat } from "lib/helper";

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const getLabel = (type, value) => {
  switch (type) {
    case "date":
      return `${value}:00`;
    case "month":
      return value;
    case "week":
      return days[value];
  }
  return value;
};

const formatData = (type, revenue, compared) => {
  if (typeof revenue === "undefined" || typeof compared === "undefined")
    return [];
  const main = revenue.length >= compared.length ? revenue : compared;
  let newData = [];

  Object.keys(main).map((key) => {
    let rev = typeof revenue[key] !== "undefined" ? revenue[key]["amount"] : 0;
    let comp =
      typeof compared[key] !== "undefined" ? compared[key]["amount"] : 0;

    return newData.push({
      label: getLabel(type, main[key]["label"]),
      revenue: rev,
      compared: comp,
    });
  });

  return newData;
};

const GraphRevenue = ({ type, revenue, compared, color, loading }) => {
  const data = formatData(type, revenue, compared);
  return (
    <Spin spinning={loading}>
      <div style={{ width: "100%", height: 400 }}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" />
            <YAxis tickFormatter={(value) => numberFormat(value)} />
            <Tooltip formatter={(value) => numberFormat(value)} />
            <Legend />
            <Line
              type="monotone"
              name="Total revenue"
              dataKey="revenue"
              stroke={color[0]}
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="compared"
              name="Compared total revenue"
              stroke={color[1]}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Spin>
  );
};

export default GraphRevenue;
