import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import {
  loadCategories,
  loadUnits,
  loadBrands,
  loadDistributors
} from "lib/select-option";

import ProductForm from "./ProductForm";
import { checkPermission } from "lib/helper";

class EditProduct extends Component {
  state = {
    can_edit : checkPermission("product_products", "can_edit", false)
  }

  componentDidMount() {
    this.props.loadProduct(this.props.match.params.product_id);
    this.props.loadCategories();
    this.props.loadUnits();
    this.props.loadBrands();
    this.props.loadDistributors(null, null, null);
  }

  editProduct = async (product, checkedList) => {
    this.props.editProduct(
      this.props.match.params.product_id,
      product,
      checkedList
    );
  };

  render() {
    const {
      product,
      categories,
      units,
      brands,
      distributors,
      alertMessage,
      errorMessage,
      branches,
      branch_products,
      loading
    } = this.props;
    const data = [];
      branches.map(branch => {
        const obj = {};
        obj.name = branch.branch_id;
  
        const checkBranch = branch_products.filter(b => {
          if (b.branch_id === branch.branch_id) return b;
        });
  
        if (checkBranch.length > 0) obj.value = true;
        else obj.value = false;
  
        data.push(obj);
      });



    return (
      <ProductForm
        onSubmit={this.editProduct}
        product={product}
        categories={categories}
        units={units}
        brands={brands}
        distributors={distributors}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        branches={branches}
        checkedList={data}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({
  products,
  categories,
  units,
  brands,
  branches,
  distributors
}) => {
  return {
    product: products.product,
    categories: categories.data,
    units: units.data,
    brands: brands.data,
    distributors: distributors.data,
    alertMessage: products.alertMessage,
    errorMessage: products.errorMessage,
    branches: branches.data,
    branch_products: products.branch_products,
    loading: products.loading
  };
};

const mapDispatchToProps = {
  loadProduct: actions.loadProduct.request,
  editProduct: actions.editProduct.request,
  loadCategories: loadCategories.request,
  loadUnits: loadUnits.request,
  loadBrands: loadBrands.request,
  loadDistributors: loadDistributors.request
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
