import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Row, Col, Tabs, Card } from "antd";

import Filter from "./Filter";
import CustomerGraphAnalyze from "./CustomerGraphAnalyze";
import * as moment from "moment";

const { TabPane } = Tabs;

export class CustomerGraphLayout extends Component {
  state = {
    type: "month",
    duration: moment().format("YYYY-MM"),
    gender: "all",
    key: 1
  };

  handleSearch = (type, duration, gender) => {
    this.setState({ type, duration, gender }, this.fetchDataByKey)
  };

  fetchDataByKey = () => {
    const { key, type, duration, gender } = this.state

    switch (parseInt(key)) {
      case 1:
        this.props.loadCustomerRevenue(
          type,
          duration,
          gender,
          this.props.branches
        );
        break;
      case 2:
        this.props.loadCustomerQuantity(
          type,
          duration,
          gender,
          this.props.branches
        );
        break;
      case 3:
        this.props.loadCustomerServiceHours(
          type,
          duration,
          gender,
          this.props.branches
        );
        break;
      default:
        break;
    }
  }

  handleTabChange = (key) => {
    this.setState({ key: key }, this.fetchDataByKey)
  };

  componentDidUpdate(prevProps) {
    if (prevProps.branches !== this.props.branches) {
      const { type, duration, gender } = this.state;
      this.props.loadCustomerRevenue(
        type,
        duration,
        gender,
        this.props.branches
      );
    }
  }

  componentDidMount() {
    if (this.props.branches.length > 0) {
      const { type, duration, gender } = this.state;
      this.props.loadCustomerRevenue(
        type,
        duration,
        gender,
        this.props.branches
      );
    }
  }

  render() {
    const {
      loading_graph_revenue,
      revenue_graph,
      loading_quantity_graph,
      quantity_graph,
      loading_service_graph,
      service_graph,
    } = this.props;

    return (
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <Card title="Customer analytics">
            <Row gutter={[8, 16]}>
              <Col span={24}>
                <Filter type="normal" onSearch={this.handleSearch} />
              </Col>
              <Col span={24}>
                <Tabs defaultActiveKey="1" onChange={this.handleTabChange}>
                  <TabPane tab="Revenue" key="1">
                    <CustomerGraphAnalyze
                      data={revenue_graph}
                      loading={loading_graph_revenue}
                      type={this.state.type}
                      graph="revenue"
                    />
                  </TabPane>
                  <TabPane tab="Quantity" key="2">
                    <CustomerGraphAnalyze
                      data={quantity_graph}
                      loading={loading_quantity_graph}
                      type={this.state.type}
                      graph="quantity"
                    />
                  </TabPane>
                  <TabPane tab="Service Hours" key="3">
                    <CustomerGraphAnalyze
                      data={service_graph}
                      loading={loading_service_graph}
                      type={this.state.type}
                      graph="service"
                    />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ customer_analytic }) => ({
  loading_graph_revenue: customer_analytic.loading_graph_revenue,
  revenue_graph: customer_analytic.revenue_graph,
  loading_quantity_graph: customer_analytic.loading_quantity_graph,
  quantity_graph: customer_analytic.quantity_graph,
  loading_service_graph: customer_analytic.loading_service_graph,
  service_graph: customer_analytic.service_graph,
});

const mapDispatchToProps = {
  loadCustomerRevenue: actions.loadCustomerRevenue.request,
  loadCustomerQuantity: actions.loadCustomerQuantity.request,
  loadCustomerServiceHours: actions.loadCustomerServiceHours.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerGraphLayout);
