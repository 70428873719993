import React, { Component } from "react";
import { connect } from "react-redux";

import { Col, Row, Checkbox, Input } from "antd";
import { loadUserGroups } from "lib/select-option";
import { DrawerFilter, LabelFilter, AutoComplete } from "lib/widget";
import {
  getSearch,
} from "lib/helper";

const initialState = {
  keyword: "",
  user_id: "",
  usergroup_id: [],
  hiddenUserGroup: "",
};

const { Search } = Input;

class Filter extends Component {
  state = initialState;

  handleChange = (id, name) => (e) => {
    if (!e.target.checked) {
      this.setState((prevState) => {
        return {
          [name]: prevState[name].filter((x) => parseInt(x) !== parseInt(id)),
        };
      });
      return;
    }

    this.setState({ [name]: [...this.state[name], id] });
  };

  handleShow = (name) => () => {
    let value = this.state[name] === "none" ? "" : "none";
    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const { usergroup_id, keyword, user_id } = this.state;
    let selected = {
      userGroup: this.props.usergroups.filter((x) =>
        usergroup_id.includes(x.usergroup_id)
      ),
    };
    this.props.handleSearch(
      {
        user_id,
        usergroup_id,
        keyword,
      },
      selected
    );
  };

  handleClearFilter = () => {
    this.setState(initialState);
  };

  handleInputChange = (e) => {
    this.setState({ user_id: "",[e.target.name]: e.target.value });
  };

  handleAutoSelect = (id, data) => {
    this.setState({ user_id: id, keyword: data });
  };

  searchAuto = (searchText) => {
    this.setState({ user_id: "", keyword: searchText });
  };

  componentDidMount() {
    let search = getSearch()

    if (typeof search === 'object') {
      this.setState(search)
    }
    this.props.loadUserGroups();
  }

  render() {
    const { usergroups, visible, handleDrawerClose, list } = this.props;
    return (
      <DrawerFilter
        onSearch={this.handleSearch}
        visible={visible}
        onClear={this.handleClearFilter}
        onClose={handleDrawerClose}
      >
        <div className="section-filter">
          <LabelFilter text="Email or name" />
          <Row gutter={[0, 16]}>
            <Col span={22}>
              <AutoComplete
                keyName={["email", "name"]}
                keyId="user_id"
                searchCompnent={
                  <Search
                    value={this.state.keyword}
                    name="keyword"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                  />
                }
                type="user"
                value={this.state.keyword}
                onSelect={this.handleAutoSelect}
                onSearch={this.searchAuto}
                options={list}
              />
            </Col>
          </Row>
        </div>
        <div className="section-filter">
          <LabelFilter text="User groups" />
          {typeof usergroups !== "undefined" &&
            usergroups.map((val, i) => {
              return (
                <Row key={i} gutter={[0, 8]}>
                  <Col span={24}>
                    <Checkbox
                      checked={this.state.usergroup_id.includes(val.usergroup_id)}
                      onClick={this.handleChange(val.usergroup_id, "usergroup_id")}
                    >
                      {val.usergroup_name}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
        </div>
      </DrawerFilter>
    );
  }
}

const mapStateToProps = ({ usergroups }) => ({
  usergroups: usergroups.data,
});

const mapDispatchToProps = {
  loadUserGroups: loadUserGroups.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
