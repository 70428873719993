import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, Form } from "antd";
const FormItem = Form.Item;

const FormBtn = ({ url, canEdit }) => {
  if (canEdit === false) {
    return (
      <Fragment>
        <FormItem style={{ marginTop: '50px' }}>
          <Row gutter={8}>
            <Col xs={{ span: 24 }} md={{ span: 8, offset: 10 }} lg={{ span: 3, offset: 15 }}>
              <Button type="defualt" block>
                <Link to={url}>Cancel</Link>
              </Button>
            </Col>
          </Row>
        </FormItem>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <FormItem style={{ marginTop: '50px' }}>
        <Row gutter={8} justify="center">
          <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 3 }}>
            <Button type="defualt" block>
              <Link to={url}>Cancel</Link>
            </Button>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 3 }}>
            <Button type="primary" htmlType="submit" block>
              Save
            </Button>
          </Col>
        </Row>
      </FormItem>
    </Fragment>
  );
};

export default FormBtn;
