import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import ScheduleReportForm from "./ScheduleReportForm";

class NewScheduleReport extends Component {
  createScheduleReport = async scheduleReport => {
    this.props.createScheduleReport(
      scheduleReport.name,
      scheduleReport.report,
      scheduleReport.time,
      scheduleReport.subject,
      scheduleReport.to,
      scheduleReport.cc,
      scheduleReport.parameter
    );
  };

  render() {
    return (
      <ScheduleReportForm
        onSubmit={this.createScheduleReport}
        scheduleReport={this.props.scheduleReport}
        alertMessage={this.props.alertMessage}
        errorMessage={this.props.errorMessage}
        loading={this.props.loading}
        canEdit={true}
      ></ScheduleReportForm>
    );
  }
}

const mapStateToProps = ({ scheduleReports }) => {
  return {
    scheduleReport: scheduleReports.scheduleReport,
    alertMessage: scheduleReports.alertMessage,
    errorMessage: scheduleReports.errorMessage,
    loading: scheduleReports.loading
  };
};

const mapDispatchToProps = {
  createScheduleReport: actions.createScheduleReport.request
};

export default connect(mapStateToProps, mapDispatchToProps)(NewScheduleReport);
