import { createTypes, createAction } from "lib/action";

const LOAD_PRODUCTS = createTypes("products", "list");
const LOAD_PRODUCT = createTypes("product", "show");
const CREATE_PRODUCT = createTypes("product", "create");
const EDIT_PRODUCT = createTypes("product", "edit");
const DELETE_PRODUCT = createTypes("product", "delete");
const IMPORT_PRODUCT = createTypes("product", "import");
const DOWNLOAD_FILE = createTypes("product", "export");
const DESTROY_MANY = createTypes("product", "destory");

const loadProducts = {
  request: (pagination, limit, cond, sort) =>
    createAction(LOAD_PRODUCTS.REQUEST, {
      pagination,
      limit,
      cond,
      sort
    }),
  success: (products ) => createAction(LOAD_PRODUCTS.SUCCESS, { products }),
  failure: error => createAction(LOAD_PRODUCTS.FAILURE, { error })
};

const loadProduct = {
  request: productId => createAction(LOAD_PRODUCT.REQUEST, { productId }),
  success: product => createAction(LOAD_PRODUCT.SUCCESS, { product }),
  failure: error => createAction(LOAD_PRODUCT.FAILURE, { error })
};

const createProduct = {
  request: (product, checkedList) =>
    createAction(CREATE_PRODUCT.REQUEST, { product, checkedList }),
  success: product => createAction(CREATE_PRODUCT.SUCCESS, { product }),
  failure: error => createAction(CREATE_PRODUCT.FAILURE, { error })
};

const editProduct = {
  request: (id, product, checkedList) =>
    createAction(EDIT_PRODUCT.REQUEST, { id, product, checkedList }),
  success: product => createAction(EDIT_PRODUCT.SUCCESS, { product }),
  failure: error => createAction(EDIT_PRODUCT.FAILURE, { error })
};

const deleteProduct = {
  request: id => createAction(DELETE_PRODUCT.REQUEST, { id }),
  success: product => createAction(DELETE_PRODUCT.SUCCESS, { product }),
  failure: error => createAction(DELETE_PRODUCT.FAILURE, { error })
};

const importProduct = {
  request: (file, type, pagination, limit, keyword, adjustPrice) =>
    createAction(IMPORT_PRODUCT.REQUEST, {
      file,
      type,
      pagination,
      limit,
      keyword,
      adjustPrice
    }),
  success: product => createAction(IMPORT_PRODUCT.SUCCESS, { product }),
  failure: error => createAction(IMPORT_PRODUCT.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: (error) => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_PRODUCTS,
  loadProducts,
  LOAD_PRODUCT,
  loadProduct,
  CREATE_PRODUCT,
  createProduct,
  EDIT_PRODUCT,
  editProduct,
  DELETE_PRODUCT,
  deleteProduct,
  IMPORT_PRODUCT,
  importProduct,
  DOWNLOAD_FILE,
  download,
  DESTROY_MANY,
  destroyMany,
};
