import { createTypes, createAction } from "lib/action";

const LOAD_BRANDS = createTypes("brands", "list");
const CREATE_BRAND = createTypes("brands", "create");
const LOAD_BRAND = createTypes("brands", "show");
const EDIT_BRAND = createTypes("brands", "edit");
const DELETE_BRAND = createTypes("brands", "delete");
const UPLOAD_FILE = createTypes("brands", "import");
const DOWNLOAD_FILE = createTypes("brands", "export");
const DESTROY_MANY = createTypes("brands", "destory");

const loadBrands = {
  request: (pagination, limit, keyword, sort) =>
    createAction(LOAD_BRANDS.REQUEST, { pagination, limit, keyword, sort }),
  success: brands => createAction(LOAD_BRANDS.SUCCESS, { brands }),
  failure: error => createAction(LOAD_BRANDS.FAILURE, { error })
};

const loadBrand = {
  request: brandId => createAction(LOAD_BRAND.REQUEST, { brandId }),
  success: brand => createAction(LOAD_BRAND.SUCCESS, { brand }),
  failure: error => createAction(LOAD_BRAND.FAILURE, { error })
};

const createBrand = {
  request: (code, name) => createAction(CREATE_BRAND.REQUEST, { code, name }),
  success: brand => createAction(CREATE_BRAND.SUCCESS, { brand }),
  failure: error => createAction(CREATE_BRAND.FAILURE, { error })
};

const editBrand = {
  request: (id, code, name) =>
    createAction(EDIT_BRAND.REQUEST, { id, code, name }),
  success: brand => createAction(EDIT_BRAND.SUCCESS, { brand }),
  failure: error => createAction(EDIT_BRAND.FAILURE, { error })
};

const deleteBrand = {
  request: id => createAction(DELETE_BRAND.REQUEST, { id }),
  success: brand => createAction(DELETE_BRAND.SUCCESS, { brand }),
  failure: error => createAction(DELETE_BRAND.FAILURE, { error })
};

const upload = {
  request: (file, pagination, limit, keyword) =>
    createAction(UPLOAD_FILE.REQUEST, {
      file,
      pagination,
      limit,
      keyword
    }),
  success: data => createAction(UPLOAD_FILE.SUCCESS, { data }),
  failure: error => createAction(UPLOAD_FILE.FAILURE, { error })
};

const download = {
  request: () => createAction(DOWNLOAD_FILE.REQUEST),
  success: file => createAction(DOWNLOAD_FILE.SUCCESS, { file }),
  failure: error => createAction(DOWNLOAD_FILE.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}

export {
  LOAD_BRANDS,
  loadBrands,
  CREATE_BRAND,
  createBrand,
  LOAD_BRAND,
  loadBrand,
  EDIT_BRAND,
  editBrand,
  DELETE_BRAND,
  deleteBrand,
  upload,
  UPLOAD_FILE,
  download,
  DOWNLOAD_FILE,
  DESTROY_MANY,
  destroyMany
};
