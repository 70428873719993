import { fork } from "redux-saga/effects";

import watchServicePackages from "./components/service_package/sagas";
import watchServicePackageGroups from "./components/service_package_group/sagas";

const watchServicePackage = [
  fork(watchServicePackages),
  fork(watchServicePackageGroups)
];

export { watchServicePackage };
