import {
  LOAD_EMPLOYEES,
  LOAD_EMPLOYEE,
  CREATE_EMPLOYEE,
  EDIT_EMPLOYEE,
  DELETE_EMPLOYEE,
  IMPORT_EMPLOYEE,
  DOWNLOAD_FILE,
  DESTROY_MANY
} from "./actions";

const initialState = {
  message: "",
  data: [],
  employee: {
    employee_code: "",
    name: "",
    image: "",
    phone: "",
    pin: "",
    status: "",
    type_id: "",
    is_booking: false,
    is_assistant: false,
    is_sales: false
  },
  branch_employees: [],
  alertMessage: "",
  errorMessage: "",
  totalSize: 0,

  errorImport: "",
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EMPLOYEES.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case LOAD_EMPLOYEES.SUCCESS:
      return {
        ...state,
        data: action.payload.employees.data,
        employee: initialState.employee,
        totalSize: action.payload.employees.total_row,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case LOAD_EMPLOYEE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case LOAD_EMPLOYEE.SUCCESS:
      return {
        ...state,
        employee: action.payload.employee.data,
        branch_employees: action.payload.employee.branch_employees,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case LOAD_EMPLOYEE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_EMPLOYEE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case CREATE_EMPLOYEE.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.employee.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case CREATE_EMPLOYEE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_EMPLOYEE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case EDIT_EMPLOYEE.SUCCESS:
      return {
        ...state,
        alertMessage: action.payload.employee.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case EDIT_EMPLOYEE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        alertMessage: "",

        errorImport: "",
        loading: false
      };
    case DELETE_EMPLOYEE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case DELETE_EMPLOYEE.SUCCESS:
      const newEmployees = state.data.filter(
        employees =>
          employees.employee_id !== action.payload.employee.data.employee_id
      );
      return {
        ...state,
        data: newEmployees,
        alertMessage: action.payload.employee.message,
        totalSize: state.totalSize - 1,

        errorImport: "",
        loading: false
      };
    case DELETE_EMPLOYEE.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        errorImport: "",
        loading: false
      };
    case IMPORT_EMPLOYEE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };

    case IMPORT_EMPLOYEE.SUCCESS:
      return {
        ...state,
        data: action.payload.employee.data,
        totalSize: action.payload.employee.total_row,
        alertMessage: action.payload.employee.message,
        errorMessage: "",

        errorImport: "",
        loading: false
      };
    case IMPORT_EMPLOYEE.FAILURE:
      return {
        ...state,
        errorImport: action.payload.error,
        errorMessage: "",
        alertMessage: "",

        loading: false
      };
    case DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",

        errorImport: "",
        loading: true
      };
    case DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loading: false
      };

    case DESTROY_MANY.REQUEST:
      return {
        ...state,
        alertMessage: "",
        errorMessage: "",
        loading: true
      };

    case DESTROY_MANY.SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data: state.data.filter(val => !data.includes(val.employee_id)),
        alertMessage: "success",
        totalSize: state.totalSize - 1,
        loading: false
      };

    case DESTROY_MANY.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
};
