import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import {
  uniqueArray,
  extractKeyFromArray,
  getSearch,
  setSearch,
  getSorter,
  setSorter,
} from "lib/helper";
import {
  ListAction,
  DeleteListModal,
  DeleteBadge,
  AddBtn,
  PageTitle,
} from "lib/widget";

import { Table, Input, Row, Col, message, Spin } from "antd";

const Search = Input.Search;

class UserGroupList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.alertMessage !== "" || nextProps.errorMessage !== "") {
      return {
        alertMessage: nextProps.alertMessage,
        errorMessage: nextProps.errorMessage,
      };
    }
    return null;
  }

  state = {
    page: 1,
    limit: 20,
    sorter: null,
    removeData: [],
    visible: false,
    visibleDelete: false,
    file: null,
    fileList: [],
    visibleImport: false,
    uploading: false,
  };

  fetchData = () => {
    const { page, limit, keyword, sorter } = this.state
    setSearch(keyword)
    setSorter(sorter)
    this.props.loadUserGroups(page, limit, keyword, sorter);
  }

  onSearch(value) {
    this.setState({ keyword: value, page: 1 }, this.fetchData);
  }

  onChangePage = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const { current, pageSize } = pagination;
    this.setState({ page: current, limit: pageSize, sorter: { order, field } }, this.fetchData);
  };

  componentDidUpdate(prevProps, prevState) {
    const { alertMessage, errorMessage } = this.props
    if (alertMessage !== "" && prevProps.alertMessage !== alertMessage) message.success(alertMessage);
    if (errorMessage !== "" && prevProps.errorMessage !== errorMessage) message.error(errorMessage);
  }

  componentDidMount() {
    this.setState({ keyword: getSearch(), sorter: getSorter() }, this.fetchData)
  }

  deleteUserGroup(id) {
    this.props.deleteUserGroup(id);
  }

  onSelectChange = (selectedRowKeys) => {
    let items = selectedRowKeys;

    if (selectedRowKeys.length > 0) {
      let removeData = this.props.usergroups.data.filter((x) =>
        selectedRowKeys.includes(x.usergroup_id)
      );

      let removeState = this.state.removeData.filter((x) =>
        selectedRowKeys.includes(x.usergroup_id)
      );
      items = uniqueArray([...removeState, ...removeData], "usergroup_id");
    }

    this.setState({ selectedRowKeys, removeData: items }, this.showDrawer);
  };

  handleDrawerClose = (name) => () => {
    this.setState({
      [name]: false,
    });
  };

  showDrawer = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleRemove = () => {
    this.props.destroyMany(
      extractKeyFromArray(this.state.removeData, "usergroup_id")
    );
    this.setState({
      selectedRowKeys: [],
      removeData: [],
      visibleDelete: false,
    });
  };

  handleRemoveAllItem = () => {
    this.setState({ selectedRowKeys: [], removeData: [] });
  };

  handleRemoveItem = (id) => {
    let item = this.state.selectedRowKeys.filter((x) => id !== x);
    let removeData = this.state.removeData.filter((x) => x.usergroup_id !== id);
    this.setState({ selectedRowKeys: item, removeData });
  };

  render() {
    const { usergroups } = this.props;
    const { selectedRowKeys, sorter } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const actionCol = [
      {
        title: "",

        key: "usergroup_id",
        className: "column-action",
        width: window.innerWidth <= 500 ? 40 : 105,
        fixed: "left",
        render: (usergroups) => (
          <ListAction
            editUrl={`/auth/usergroups/edit/${usergroups.usergroup_id}`}
            onDelete={this.props.deleteUserGroup}
            id={usergroups.usergroup_id}
            name="authorization_usergroups"
            breakpoint={500}
          />
        ),
      },
    ];

    const numberCol = [
      {
        title: "#",
        key: "index",
        className: "align-center",
        width: 50,
        render: (text, record, index) =>
          this.state.limit * (this.state.page - 1) + index + 1,
      },
    ];

    const mainColumns = [
      {
        title: "Name",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'usergroup_name' ? sorter.order : false,
        dataIndex: "usergroup_name",
      },
      {
        title: "Code",
        sorter: true,
        sortOrder: sorter !== null && sorter.field === 'usergroup_code' ? sorter.order : false,
        dataIndex: "usergroup_code",
      },
    ];

    const columns =
      window.innerWidth <= 500
        ? [...actionCol, ...mainColumns]
        : [...numberCol, ...mainColumns, ...actionCol];

    return (
      <div>
        <Spin size="large" spinning={this.props.usergroups.loading}>
          <div style={{ marginBottom: 16 }}>
            <PageTitle
              title="User Groups"
              name="authorization_usergroups"
              component={
                <AddBtn
                  url="/auth/usergroups/new"
                  name="authorization_usergroups"
                />
              }
            />
            <Row gutter={[16, 16]} justify="space-between">
              <DeleteBadge
                count={this.state.removeData.length}
                onClick={this.showDrawer("visibleDelete")}
              />
              <Col xs={24} sm={24} md={8}>
                <Search
                  defaultValue={getSearch()}
                  placeholder="Search for code or name"
                  onSearch={(value) => this.onSearch(value)}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <DeleteListModal
              onClose={this.handleDrawerClose}
              visible={this.state.visibleDelete}
              data={this.state.removeData}
              onRemove={this.handleRemove}
              removeItem={this.handleRemoveItem}
              removeAllItem={this.handleRemoveAllItem}
              displayKey={["usergroup_id", "usergroup_name", "usergroup_code"]}
            />
          </div>
          <Table
            rowKey={"usergroup_id"}
            bordered={true}
            rowSelection={rowSelection}
            size="small"
            columns={columns}
            dataSource={usergroups.data}
            pagination={{
              pageSize: this.state.limit,
              total: usergroups.totalSize,
              current: this.state.page,
            }}
            onChange={this.onChangePage}
            scroll={window.innerWidth <= 500 ? { x: 500 } : undefined}
          />
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = ({ usergroups }) => ({
  usergroups,
  errorMessage: usergroups.errorMessage,
  alertMessage: usergroups.alertMessage,
});

const mapDispatchToProps = {
  loadUserGroups: actions.loadUserGroups.request,
  deleteUserGroup: actions.deleteUserGroup.request,
  destroyMany: actions.destroyMany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupList);
