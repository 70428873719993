import React, { Fragment } from "react";
import { Button, Row, Col, Form } from "antd";
const FormItem = Form.Item;

const NextPrevBtn = ({ url, canEdit }) => {
  return (
    <Fragment>
      <FormItem>
        <Row gutter={8} justify="end">
          {/* <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 3 }}>
            <Button type="defualt" block>
              Prev
            </Button>
          </Col> */}
          <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 3 }}>
            <Button type="primary" htmlType="submit" block>
              Next
            </Button>
          </Col>
        </Row>
      </FormItem>
    </Fragment>
  );
};

export default NextPrevBtn;
