import React, { Component } from "react";
import { connect } from "react-redux";
import { Drawer, Row, Col, Card } from "antd";
import * as actions from "../actions";
import { fetchGo } from "lib/fetch";

import DetailTable from "./DetailTable";
import Graph from "./Graph"
import { getComparedFilter } from "lib/go-api-helper"

export class DetailModal extends Component {
  state = {
    sale_id: null,
    name: "",
  };

  handleCancel = () => {
    this.props.onCancel(false);
  };

  getEmployeeInfo = () => {
    fetchGo.get(`sale_data/${this.state.sale_id}`).then((res) => {
      this.setState({ name: res.data.name });
    });
  };

  fetchData = () => {
    const { sale_id } = this.state;
    let { type, duration, branch_id, query_type } = this.props;
    if (type == "week") duration = getComparedFilter(type, duration)

    this.props.loadDataTableDetail(
      type,
      duration,
      branch_id,
      sale_id,
      query_type
    );
    this.props.loadWidgetDetailData(
      type,
      duration,
      branch_id,
      sale_id,
      query_type
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.saleId !== this.props.saleId) {
      this.setState({ sale_id: this.props.saleId }, () => {
        if (this.state.sale_id !== null) {
          this.fetchData();
          this.getEmployeeInfo();
        }
      });
    }
  }

  render() {
    const {
      loading_datatable_detail,
      analytic_datatable_detail,
      loading_widget_detail,
      analytic_widget_detail,
    } = this.props;

    return (
      <Drawer
        title={this.state.name}
        placement="right"
        width={800}
        onClose={this.handleCancel}
        visible={this.props.visible}
      >
        <Row>
          <Col lg={24}>
            <Graph 
            loading={loading_widget_detail}
            data={analytic_widget_detail}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DetailTable 
            loading={loading_datatable_detail}
            data={analytic_datatable_detail}
            />
          </Col>
        </Row>
      </Drawer>
    );
  }
}

const mapStateToProps = ({ stylist_analytic }) => ({
  loading_datatable_detail: stylist_analytic.loading_datatable_detail,
  analytic_datatable_detail: stylist_analytic.analytic_datatable_detail,
  loading_widget_detail: stylist_analytic.loading_widget_detail,
  analytic_widget_detail: stylist_analytic.analytic_widget_detail,
});

const mapDispatchToProps = {
  loadDataTableDetail: actions.loadStylistDataTableDetail.request,
  loadWidgetDetailData: actions.loadStylistWidgetDetailData.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailModal);
