import {
  DOWNLOAD_DAILY,
  DOWNLOAD_CURRENT_STOCK,
  DOWNLOAD_EMPLOYEE_REPORT,
  DOWNLOAD_STOCK,
  DOWNLOAD_ERP_TRANSACTION_REPORT,
  DOWLOAD_ERP_CUSTOMER,
  DOWLOAD_GOODS_RECEIVED,
  DOWLOAD_SALON_USED,
  DOWLOAD_PHYSICAL_COUNT,
  DOWLOAD_TRANSFER_REPORT,
  DOWLOAD_STOCK_BENCHMARK_REPORT,
  DOWLOAD_PAYMENT_REPORT,
  DOWLOAD_CUSTOMER_RETENTION_REPORT,
  LOAD_REPORT_BRANCH,
  LOAD_REPORT_ALLBRANCH,
  DOWLOAD_STOCKEXCHANGE_REPORT

} from "./actions";

const initialState = {
  message: "",
  data: [],
  branches: [],
  allbranches: [],
  loading: false,
  current_stock_loading: false,
  daily_loading: false,
  employee_loading: false,
  erp_trans_loading: false,
  erp_cus_loading: false,
  goods_loading: false,
  salon_loading: false,
  physical_loading: false,
  transfer_loading: false,
  stock_benchmark_loading: false,
  retention_loading: false,
  payment_loading: false,
  stockexchange_loading: false,
  alertMessage: "",
  errorMessage: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_DAILY.REQUEST:
      return { ...state, errorMessage: "", daily_loading: true };
    case DOWNLOAD_DAILY.SUCCESS:
      return { ...state, errorMessage: "", daily_loading: false };
    case DOWNLOAD_DAILY.FAILURE:
      return {
        ...state,
        daily_loading: false,
        errorMessage: action.payload.error
      };

    case DOWLOAD_STOCKEXCHANGE_REPORT.REQUEST:
      return { ...state, errorMessage: "", stockexchange_loading: true };
    case DOWLOAD_STOCKEXCHANGE_REPORT.SUCCESS:
      return { ...state, errorMessage: "", stockexchange_loading: false };
    case DOWLOAD_STOCKEXCHANGE_REPORT.FAILURE:
      return {
        ...state,
        stockexchange_loading: false,
        errorMessage: action.payload.error
      };

    case DOWLOAD_CUSTOMER_RETENTION_REPORT.REQUEST:
      return { ...state, errorMessage: "", retention_loading: true };
    case DOWLOAD_CUSTOMER_RETENTION_REPORT.SUCCESS:
      return { ...state, errorMessage: "", retention_loading: false };
    case DOWLOAD_CUSTOMER_RETENTION_REPORT.FAILURE:
      return {
        ...state,
        retention_loading: false,
        errorMessage: action.payload.error
      };

    case LOAD_REPORT_BRANCH.REQUEST:
      return { ...state };
    case LOAD_REPORT_BRANCH.SUCCESS:
      return { ...state, branches: action.payload.data.data };
    case LOAD_REPORT_BRANCH.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error
      };

    case LOAD_REPORT_ALLBRANCH.REQUEST:
      return { ...state };
    case LOAD_REPORT_ALLBRANCH.SUCCESS:
      return { ...state, allbranches: action.payload.data.data };
    case LOAD_REPORT_ALLBRANCH.FAILURE:
      return {
        ...state,
        errorMessage: action.payload.error
      };

    case DOWNLOAD_CURRENT_STOCK.REQUEST:
      return { ...state, current_stock_loading: true };
    case DOWNLOAD_CURRENT_STOCK.SUCCESS:
      return { ...state, current_stock_loading: false };
    case DOWNLOAD_CURRENT_STOCK.FAILURE:
      return {
        ...state,
        current_stock_loading: false,
        errorMessage: action.payload.error
      };

    case DOWNLOAD_EMPLOYEE_REPORT.REQUEST:
      return { ...state, errorMessage: "", employee_loading: true };
    case DOWNLOAD_EMPLOYEE_REPORT.SUCCESS:
      return { ...state, errorMessage: "", employee_loading: false };
    case DOWNLOAD_EMPLOYEE_REPORT.FAILURE:
      return {
        ...state,
        employee_loading: false,
        errorMessage: action.payload.error
      };

    case DOWNLOAD_STOCK.REQUEST:
      return { ...state, loading: true };
    case DOWNLOAD_STOCK.SUCCESS:
      return { ...state, loading: false };
    case DOWNLOAD_STOCK.FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      };

    case DOWNLOAD_ERP_TRANSACTION_REPORT.REQUEST:
      return { ...state, errorMessage: "", erp_trans_loading: true };
    case DOWNLOAD_ERP_TRANSACTION_REPORT.SUCCESS:
      return { ...state, errorMessage: "", erp_trans_loading: false };
    case DOWNLOAD_ERP_TRANSACTION_REPORT.FAILURE:
      return {
        ...state,
        erp_trans_loading: false,
        errorMessage: action.payload.error
      };

    case DOWLOAD_ERP_CUSTOMER.REQUEST:
      return { ...state, errorMessage: "", erp_cus_loading: true };

    case DOWLOAD_ERP_CUSTOMER.SUCCESS:
      return { ...state, errorMessage: "", erp_cus_loading: false };

    case DOWLOAD_ERP_CUSTOMER.FAILURE:
      return {
        ...state,
        erp_cus_loading: false,
        errorMessage: action.payload.error
      };

    case DOWLOAD_GOODS_RECEIVED.REQUEST:
      return { ...state, errorMessage: "", goods_loading: true };

    case DOWLOAD_GOODS_RECEIVED.SUCCESS:
      return { ...state, errorMessage: "", goods_loading: false };

    case DOWLOAD_GOODS_RECEIVED.FAILURE:
      return {
        ...state,
        goods_loading: false,
        errorMessage: action.payload.error
      };

    case DOWLOAD_SALON_USED.REQUEST:
      return { ...state, errorMessage: "", salon_loading: true };

    case DOWLOAD_SALON_USED.SUCCESS:
      return { ...state, errorMessage: "", salon_loading: false };

    case DOWLOAD_SALON_USED.FAILURE:
      return {
        ...state,
        salon_loading: false,
        errorMessage: action.payload.error
      };

    case DOWLOAD_PHYSICAL_COUNT.REQUEST:
      return { ...state, errorMessage: "", physical_loading: true };

    case DOWLOAD_PHYSICAL_COUNT.SUCCESS:
      return { ...state, errorMessage: "", physical_loading: false };

    case DOWLOAD_PHYSICAL_COUNT.FAILURE:
      return {
        ...state,
        physical_loading: false,
        errorMessage: action.payload.error
      };

    case DOWLOAD_TRANSFER_REPORT.REQUEST:
      return { ...state, errorMessage: "", transfer_loading: true };

    case DOWLOAD_TRANSFER_REPORT.SUCCESS:
      return { ...state, errorMessage: "", transfer_loading: false };

    case DOWLOAD_TRANSFER_REPORT.FAILURE:
      return {
        ...state,
        transfer_loading: false,
        errorMessage: action.payload.error
      };

    case DOWLOAD_STOCK_BENCHMARK_REPORT.REQUEST:
      return { ...state, errorMessage: "", stock_benchmark_loading: true };

    case DOWLOAD_STOCK_BENCHMARK_REPORT.SUCCESS:
      return { ...state, errorMessage: "", stock_benchmark_loading: false };

    case DOWLOAD_STOCK_BENCHMARK_REPORT.FAILURE:
      return {
        ...state,
        stock_benchmark_loading: false,
        errorMessage: action.payload.error
      };

    case DOWLOAD_PAYMENT_REPORT.REQUEST:
      return { ...state, errorMessage: "", payment_loading: true };

    case DOWLOAD_PAYMENT_REPORT.SUCCESS:
      return { ...state, errorMessage: "", payment_loading: false };

    case DOWLOAD_PAYMENT_REPORT.FAILURE:
      return {
        ...state,
        payment_loading: false,
        errorMessage: action.payload.error
      };

    default:
      return state;
  }
};
