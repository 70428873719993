import React from "react";
import { Switch } from "react-router-dom";

import UnitList from "./UnitList";
import NewUnit from "./NewUnit";
import EditUnit from "./EditUnit";
import  { PrivateRoute } from 'lib/widget'

export default () => (
  <Switch>
    <PrivateRoute path="/product/units/new" component={NewUnit} perm="product_units" action="can_add"/>
    <PrivateRoute path="/product/units/edit/:unit_id" component={EditUnit} perm="product_units"/>
    <PrivateRoute path="/product/units" component={UnitList} perm="product_units"/>
  </Switch>
);
