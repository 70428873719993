import React, { Component } from 'react'
import {
    Input
} from "antd";
import { TransferCustomer } from "lib/fetch"
import { AutoComplete } from "lib/widget";

const { Search } = Input;

export default class CustomerSelect extends Component {
    state = {
        list: [],
        loading: false,
        customer_id: "",
        keyword: ""
    }

    handleLoadData = (value) => {
        TransferCustomer.getData({ phone_no: value }).then(res => {
            this.setState({
                list: res.data.data
            })
        })
    }

    handleInputChange = e => {
        this.setState({ customer_id: "", [e.target.name]: e.target.value });
        if (e.target.value === "") {
            this.props.onSelect("", "", "", 0)
        }
    };

    handleAutoSelect = (id, data, opt) => {
        this.setState({ customer_id: id, keyword: data })
        this.props.onSelect(id, opt.tel1, opt.name, opt.cash)
    }

    searchAuto = (searchText) => {
        this.setState({ customer_id: "", keyword: searchText })
    }

    componentDidMount() {
        this.handleLoadData("")
    }

    render() {
        return (
            <AutoComplete
                keyName={['tel1', 'name']}
                keyId="customer_id"
                searchCompnent={
                    <Search
                        value={this.state.keyword}
                        name="keyword"
                        onChange={this.handleInputChange}
                        style={{ width: "100%" }}
                    />
                }
                type="transfercustomer"
                value={this.state.keyword}
                onSelect={this.handleAutoSelect}
                onSearch={this.searchAuto}
                options={this.state.list}
            />
        )
    }
}
