import React, { Component } from "react";
import { Row, Col, Select, Button, DatePicker, Input, Form, message } from "antd";

import * as moment from "moment";
import { getIdtoArray, extractKeyFromArray } from "lib/helper";
import { fetchGo } from "lib/fetch"
import { branch } from "modules/branch/reducer";

const FormItem = Form.Item;

const initialState = {
  picker: "date",
  date: moment().format('YYYY-MM-DD'),
  duration: moment().format('YYYY-MM-DD'),
  isAll: null,
  branches: [],
  stylish: [],
  stylishId: [],
  selectId: null
};

class Filter extends Component {
  state = initialState

  handleChange = (value) => {
    let duration = this.getDuration(moment());
    this.setState({ picker: value, date :moment(), duration: duration });
  };

  handleDateChange = (value) => {
    let duration = this.getDuration(value);
    this.setState({ date: moment(value), duration: duration });
  };

  getDuration = (value) => {
    let data = value;
    switch (this.state.picker) {
      case "date":
        data = moment(value).utc().format("YYYY-MM-DD");
        break;
      case "week":
        data = moment(value).format("YYYY-W");
        break;
      case "month":
        data = moment(value).format("YYYY-MM");
        break;
    }
    return data
  }

  onBranchChange = (value, option) => {
    this.setState({ branchId : value, branches: [value] })
    // let branchId = value;
    // let branches = this.props.branches;

    // if (value.includes("all")) {
    //   this.setState({ isAll: false });
    //   branchId = extractKeyFromArray(branches, "value");
    // } else {
    //   this.setState({ isAll: true });
    // }

    // if (value.includes("none")) {
    //   branchId = [];
    //   this.setState({ isAll: null });
    // }

    // this.setState({ branches: branchId });

    // return branchId;
  };

  getAllBranchOpt = () => {
    let branches = [
      {
        value: this.state.isAll === false ? "none" : "all",
        label:
          this.state.isAll === false ? "Clear All Branches" : "All Branches",
      },
      ...this.props.branches,
    ];
    return branches;
  };

  handleClear = () => {
    this.setState(initialState);
  };

  handleSearch = () => {
    const { picker, duration, branches, stylishId } = this.state;
    if (branches.length === 0) {
      message.warning('Please select branch');
      return false
    }
    this.props.onSearch(picker, duration, branches, stylishId);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.branches.length !== prevProps.branches.length) {
      const { branches, picker } = this.props
     let branchId = getIdtoArray(branches, "value")
      this.setState({ branches: branchId[1], picker });

    }
  }

  render() {
    const { picker, stylish, selectId } = this.state;
    const branches = this.props.branches
    //typeof this.props.branches !== "object" ? [] : this.getAllBranchOpt();;

    return (
      <Form style={{ marginTop: "20px" }}>
        <Row gutter={[16, 16]} align="middle">
          <Col span={6}>
            <FormItem label="Branch">
              <Select
                onChange={this.onBranchChange}
                value={this.state.branchId}
              >
                {branches.map((data, i) => (
                  <Select.Option key={i} value={data.value}>
                    {data.label}
                  </Select.Option>
                ))}
              </Select> 
              {/* <Select
                mode="multiple"
                maxTagCount={3}
                onChange={this.onBranchChange}
                value={this.state.branches}
              >
                {branches.map((data, i) => (
                  <Select.Option key={i} value={data.value}>
                    {data.label}
                  </Select.Option>
                ))}
              </Select> */}
            </FormItem>
          </Col>
          {/* <Col span={4}>
            <FormItem label="Stylish">
              <Select
                onChange={this.onStylishChange}
                value={selectId}
                showSearch
              >
                {stylish.map((data, i) => (
                  <Select.Option key={i} value={data.value}>
                    {data.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col> */}
          <Col span={7}>
            <Form.Item
              label="Duration"
              style={{ paddingLeft: "8px", paddingRight: "8px" }}
            >
              <Input.Group compact>
                <Form.Item noStyle>
                  <Select
                    value={picker}
                    onChange={this.handleChange}
                    style={{ width: "40%" }}
                    allowClear
                  >
                    <Select.Option key="date" value="date">Date</Select.Option>
                    <Select.Option key="week" value="week">Week</Select.Option>
                    <Select.Option key="month" value="month">month</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item  noStyle>
                  <div style={{ display : this.state.picker === 'date' ? '' : 'none'  }}>
                    <DatePicker
                      picker="date"
                      value={moment(this.state.date)}
                      onChange={this.handleDateChange}
                    />
                 </div>
                 <div style={{ display : this.state.picker === 'week' ? '' : 'none'  }}>
                    <DatePicker
                      picker="week"
                      value={moment(this.state.date)}
                      onChange={this.handleDateChange}
                    />
                  </div>
                  <div style={{ display : this.state.picker === 'month' ? '' : 'none'  }}>
                    <DatePicker
                      picker="month"
                      value={moment(this.state.date)}
                      onChange={this.handleDateChange}
                    />
                 </div>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                onClick={this.handleSearch}
                style={{ marginRight: "3px" }}
              >
                Submit
              </Button>
              <Button onClick={this.handleClear} htmlType="button">
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

Filter.defaultProps = {
  branches: [],
};

export default Filter;
