import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { checkPermission } from "lib/helper";

import CashPackageForm from "./CashPackageForm";

class EditCashPackage extends Component {
  state = {
    can_edit: checkPermission("cash_package_cash_packages", "can_edit", false)
  };
  componentDidMount() {
    this.props.loadCashPackage(this.props.match.params.cash_package_id);
  }

  editCashPackage = async (cashPackage, checkedList) => {
    this.props.editCashPackage(
      this.props.match.params.cash_package_id,
      cashPackage,
      checkedList
    );
  };

  render() {
    const {
      cashPackage,
      alertMessage,
      errorMessage,
      branches,
      branch_cash_packages,
      loading
    } = this.props;

    const data = [];
    branches.map(branch => {
      const obj = {};
      obj.name = branch.branch_id;
      const checkBranch = branch_cash_packages.filter(b => {
        if (b.branch_id === branch.branch_id) return b;
      });
      if (checkBranch.length > 0) obj.value = true;
      else obj.value = false;
      return data.push(obj);
    });

    return (
      <CashPackageForm
        onSubmit={this.editCashPackage}
        cashPackage={cashPackage}
        alertMessage={alertMessage}
        errorMessage={errorMessage}
        branches={branches}
        checkedList={data}
        loading={loading}
        canEdit={this.state.can_edit}
        editCode={true}
      />
    );
  }
}

const mapStateToProps = ({ cashPackages, branches }) => {
  return {
    cashPackage: cashPackages.cashPackage,
    alertMessage: cashPackages.alertMessage,
    errorMessage: cashPackages.errorMessage,
    branches: branches.data,
    branch_cash_packages: cashPackages.branch_cash_packages,
    loading: cashPackages.loading
  };
};

const mapDispatchToProps = {
  loadCashPackage: actions.loadCashPackage.request,
  editCashPackage: actions.editCashPackage.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCashPackage);
