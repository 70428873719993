import { createTypes, createAction } from "lib/action";

const LOAD_USERGROUPS = createTypes("usergroups", "list");
const LOAD_USERGROUP = createTypes("usergroups", "show");
const CREATE_USERGROUP = createTypes("usergroups", "create");
const EDIT_USERGROUP = createTypes("usergroups", "edit");
const DELETE_USERGROUP = createTypes("usergroups", "delete");
const DESTROY_MANY = createTypes("usergroups", "destory");

const loadUserGroups = {
  request: (pagination, limit, keyword, sorter) =>
    createAction(LOAD_USERGROUPS.REQUEST, { pagination, limit, keyword, sorter}),
  success: usergroups =>
    createAction(LOAD_USERGROUPS.SUCCESS, { usergroups }),
  failure: (error) => createAction(LOAD_USERGROUPS.FAILURE, { error })
};

const loadUserGroup = {
  request: usergroupId =>
    createAction(LOAD_USERGROUP.REQUEST, { usergroupId }),
  success: usergroup =>
    createAction(LOAD_USERGROUP.SUCCESS, { usergroup }),
  failure: error => createAction(LOAD_USERGROUP.FAILURE, { error })
};

const createUserGroup = {
  request: (usergroup) =>
    createAction(CREATE_USERGROUP.REQUEST, { usergroup }),
  success: usergroup =>
    createAction(CREATE_USERGROUP.SUCCESS, { usergroup }),
  failure: error => createAction(CREATE_USERGROUP.FAILURE, { error })
};

const editUserGroup = {
  request: (id, usergroup) =>
    createAction(EDIT_USERGROUP.REQUEST, { id, usergroup }),
  success: usergroup =>
    createAction(EDIT_USERGROUP.SUCCESS, { usergroup }),
  failure: error => createAction(EDIT_USERGROUP.FAILURE, { error })
};

const deleteUserGroup = {
  request: id => createAction(DELETE_USERGROUP.REQUEST, { id }),
  success: usergroup =>
    createAction(DELETE_USERGROUP.SUCCESS, { usergroup }),
  failure: error => createAction(DELETE_USERGROUP.FAILURE, { error })
};

const destroyMany = {
  request: data => createAction(DESTROY_MANY.REQUEST, { data }),
  success: data => createAction(DESTROY_MANY.SUCCESS, { data }),
  failure: error => createAction(DESTROY_MANY.FAILURE, { error })
}


export {
  LOAD_USERGROUPS,
  loadUserGroups,
  LOAD_USERGROUP,
  loadUserGroup,
  CREATE_USERGROUP,
  createUserGroup,
  EDIT_USERGROUP,
  editUserGroup,
  DELETE_USERGROUP,
  deleteUserGroup,
  DESTROY_MANY,
  destroyMany
};
